import * as ACTION_TYPES from './notificationActionTypes';

export const notificationReducerInitialState = {
	//Notification
	notificationList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	notificationByUserList: {
		data: undefined,
		error: undefined
	},
	notificationNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	notificationDetail: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	downloadFileOfNotification: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	notificationToDetails: {}
};

export const notificationReducer = (state=notificationReducerInitialState, action) => {
	switch (action.type) {

		//Notification
		case ACTION_TYPES.LIST_NOTIFICATION_REQUEST: {

			return {
				...state,
				notificationList: {
					...state.notificationList,
					isFetching: true,
					hasError: false,
					error: notificationReducerInitialState.notificationList.error
				}
			};
		}

		case ACTION_TYPES.LIST_NOTIFICATION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				notificationList: {
					...state.notificationList,
					isFetching: false,
					hasError: false,
					error: notificationReducerInitialState.notificationList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_NOTIFICATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				notificationList: {
					...state.notificationList,
					isFetching: false,
					hasError: true,
					data: notificationReducerInitialState.notificationList.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_NOTIFICATION: {
			const { data } = action.payload;
			
			return {
				...state,
				notificationToDetails: data
			};
		}

		case ACTION_TYPES.CLEAR_NOTIFICATION_LIST: {
			return {
				...state,
				notificationList: {
					...notificationReducerInitialState.notificationList
				}
			};
		}

		//Notification by user
		case ACTION_TYPES.LIST_NOTIFICATION_BY_USER_REQUEST: {
			return {
				...state,
				notificationByUserList: {
					...state.notificationByUserList,
					error: notificationReducerInitialState.notificationByUserList.error
				}
			}
		}
		case ACTION_TYPES.CLEAR_LIST_NOTIFICATION_BY_USER: {
			return {
				...state,
				notificationByUserList: notificationReducerInitialState.notificationByUserList
			}
		}
		case ACTION_TYPES.LIST_NOTIFICATION_BY_USER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				notificationByUserList: {
					...state.notificationByUserList,
					error: notificationReducerInitialState.notificationByUserList.error,
					data
				}
			}
		}

		//Detail Notification
		case ACTION_TYPES.DETAIL_NOTIFICATION_REQUEST: {
			return {
				...state,
				notificationDetail: {
					...state.notificationDetail,
					isFetching: true,
					hasError: false,
					error: notificationReducerInitialState.notificationDetail.error
				}
			};
		}

		case ACTION_TYPES.DETAIL_NOTIFICATION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				notificationDetail: {
					...state.notificationDetail,
					isFetching: false,
					hasError: false,
					error: notificationReducerInitialState.notificationDetail.error,
					data
				}
			};
		}

		case ACTION_TYPES.DETAIL_NOTIFICATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				notificationDetail: {
					...state.notificationDetail,
					isFetching: false,
					hasError: true,
					data: notificationReducerInitialState.notificationDetail.data,
					error
				}
			};
		}

		//New Notification
		case ACTION_TYPES.NEW_NOTIFICATION_REQUEST: {
			return {
				...state,
				notificationNew: {
					...state.notificationNew,
					isFetching: true,
					hasError: false,
					error: notificationReducerInitialState.notificationNew.error
				}
			};
		}

		case ACTION_TYPES.NEW_NOTIFICATION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				notificationNew: {
					...state.notificationNew,
					isFetching: false,
					hasError: false,
					error: notificationReducerInitialState.notificationNew.error,
					data
				}
			};
		}

		case ACTION_TYPES.NEW_NOTIFICATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				notificationNew: {
					...state.notificationNew,
					isFetching: false,
					hasError: true,
					data: notificationReducerInitialState.notificationNew.data,
					error
				}
			};
		}

		// Download file of notification

		case ACTION_TYPES.DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST: {
			return {
				...state,
				downloadFileOfNotification: {
					...state.downloadFileOfNotification,
					isFetching: true,
					hasError: false,
					error: notificationReducerInitialState.downloadFileOfNotification.error
				}
			};
		}

		case ACTION_TYPES.DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_SUCCESS: {
			return {
				...state,
				downloadFileOfNotification: {
					...state.downloadFileOfNotification,
					isFetching: false,
					hasError: false,
					error: notificationReducerInitialState.downloadFileOfNotification.error,
				}
			};
		}

		case ACTION_TYPES.DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				downloadFileOfNotification: {
					...state.downloadFileOfNotification,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}

		// END Download file of notification

		default: {
			return state
		}
	}
};

export const getNotificationReducer = state => state.notificationReducer;

// Notification list
export const getNotificationList = state => getNotificationReducer(state)?.notificationList;
export const getNotificationListData = state => getNotificationList(state)?.data;
export const getNotificationListIsFetching = state => getNotificationList(state)?.isFetching;

// Notification Detail
export const getNotificationDetail = state => getNotificationReducer(state)?.notificationDetail;
export const getNotificationDetailData = state => getNotificationDetail(state)?.data;
export const getNotificationDetailIsFetching = state => getNotificationDetail(state)?.isFetching;

// New Notification
export const getNotificationCreate = state => getNotificationReducer(state)?.notificationNew;
export const getNotificationCreateIsFetching = state => getNotificationCreate(state)?.isFetching;

// Notification to details
export const getNotificationToDetail = state => getNotificationReducer(state)?.notificationToDetails;

// Download file of notification
export const getNotificationDownloadFile = state => getNotificationReducer(state)?.downloadFileOfNotification;
export const getNotificationDownloadFileIsFetching = state => getNotificationDownloadFile(state)?.isFetching;
export const getNotificationDownloadFileHasError = state => getNotificationDownloadFile(state)?.hasError;
export const getNotificationDownloadFileError = state => getNotificationDownloadFile(state)?.error;

// Notification by User
export const getNotificationByUserList = state => getNotificationReducer(state)?.notificationByUserList;
export const getNotificationByUserListData = state => getNotificationByUserList(state)?.data;
