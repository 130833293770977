import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Col, Button, Table, Alert, FormControl, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTrash, faEdit, faSearch, faPlus, faFilter, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBroom  } from '@fortawesome/free-solid-svg-icons';
import RCPagination from 'src/components/common/RCPagination';
import { tryListDeliveryOrder, tryDeleteDeliveryOrder } from 'src/redux/deliveryOrder/deliveryOrderActionCreator'
import { getDeliveryOrderListIsFetching, getDeliveryOrderListData } from 'src/redux/deliveryOrder/deliveryOrderReducer'
import { DELIVERY_ORDER_NEW, DELIVERY_ORDER_NEW_MASSIVE, DELIVERY_ORDER_DETAILS, DELIVERY_ORDER_EDIT } from 'src/utils/constants';
import { setDeliveryOrderToEdit, clearDeleteDeliveryOrder } from 'src/redux/deliveryOrder/deliveryOrderActions'
import { formatterPeso, getClearObject, getFundRequestTypeValueToShow } from 'src/utils/utils'
import axios from 'axios';
import { DropdownList } from 'react-widgets';
import { getUserPermissionsDeliveryOrders } from 'src/redux/login/loginReducer';
import AppLoading from 'src/components/common/AppLoading';
import { tryDeliveryOrderById } from 'src/redux/deliveryOrder/deliveryOrderActionCreator';
import { getDeliveryOrderRequestId } from 'src/redux/deliveryOrder/deliveryOrderActions'
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer.js';
import { getServicesByExerciseIdData, getServicesByExerciseIdIsFetching } from 'src/redux/exercise/exerciseReducer.js';
import { tryGetServicesByExerciseId } from 'src/redux/exercise/exerciseActionCreator.js';
import { administrativeService, administrativeServiceClose, AdministrativeServiceData, VALIDATE_ACTION_TYPE } from 'src/utils/administrativeServiceUtils.js';
import { getListIdsServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceReducer';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const DeliveryOrderListPage = props => {
	const dispatch = useDispatch();
	const deliveryOrderssPermissions = useSelector(state => getUserPermissionsDeliveryOrders(state));
    const { handleSubmit, register, reset } = useForm();
	const [servicio, setServicio] = useState();
	const servicesData = useSelector( state => getServicesByExerciseIdData(state) );
	const servicesDataIsFetching = useSelector( state => getServicesByExerciseIdIsFetching(state) );
	const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
	const creditLabelsRefContainer = useRef(null);
	const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;
	const administrativeServiceData = AdministrativeServiceData();

	// Fetch delivery order
	const deliveryOrderList = useSelector(state => getDeliveryOrderListData(state));
	const deliveryOrderListData = deliveryOrderList?.records;
	const deliveryOrderListIsFetching = useSelector(state => getDeliveryOrderListIsFetching(state));
	const listIdsServiceAdministrative = useSelector(state => getListIdsServiceAdministrativeByUser(state));

	// Filter
	let newFilterObject;
	const [filterObject, setFilterObject] = useState({});
	
	// Cancel Request
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();

	// Pagination
	const deliveryOrderListPaginate = deliveryOrderList?.metadata;
	const page = deliveryOrderListPaginate?.page + 1;
	const perPage= deliveryOrderListPaginate?.pageSize;
	const totalItems = deliveryOrderListPaginate?.filteredCount;

	const defaultParams = {
		page: 0,
        size: 10,
        sort: 'number,desc',
        year: selectedPeriod?.year,
		fundRequestAdministrativeService: listIdsServiceAdministrative,
    };

	const getDescriptionToShow = (fundRequest) => {
		let label = '';
		let fundRequestTypeLabel = getFundRequestTypeValueToShow(fundRequest?.fundRequestType);
		if(fundRequest?.description){
			label =  fundRequest?.description + ' - '  + fundRequestTypeLabel ;
		} else {
			label =  fundRequestTypeLabel;
		}
		return label;
	};

	const onClickDetail = (deliveryOrder) => {
		dispatch(tryDeliveryOrderById(deliveryOrder?.id)).then(
			(response) => {			
				dispatch(getDeliveryOrderRequestId(response.data));				
				dispatch(push(DELIVERY_ORDER_DETAILS));	
			}
		);		
	};

	const onClickEdit = (deliveryOrder) => {
		dispatch(tryDeliveryOrderById(deliveryOrder?.id)).then(
			(response) => {			
				dispatch(setDeliveryOrderToEdit(response.data));
				dispatch(push(DELIVERY_ORDER_EDIT));
			}
		);		
	};

	const onClickNew = () => {
		dispatch(push(DELIVERY_ORDER_NEW));
	};

	const onClickNewMassive = () => {
		dispatch(push(DELIVERY_ORDER_NEW_MASSIVE));
	};

	const loadAllData = () => {
		dispatch(tryListDeliveryOrder(defaultParams));
	}

	useEffect(() => {
		loadAllData();
		dispatch(tryGetServicesByExerciseId(selectedPeriod?.id));        
	}, []);

	const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            ...filterObject,
            page: pNumber - 1 
        }
		dispatch(tryListDeliveryOrder(params));
    }

	const onClickDelete = deliveryOrder => {
		swal({
			title: 'Confirmación',
			text: '¿ Realmente desea eliminar la orden de entrega ?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		})
			.then((willDelete) => {
				if (willDelete) {
					dispatch(tryDeleteDeliveryOrder(deliveryOrder?.id)).then(
						response => {
							if (response?.status == 204) {
								dispatch(clearDeleteDeliveryOrder());
								loadAllData();
							}
						}
					);
				}
			});
	};

	const clearFilters = () => {
		setServicio()
		setFilterObject({});
		reset();
		loadAllData();
	};

	const onSubmitForm = (data) => {
		const cleanData = getClearObject(data);
        if (!deliveryOrderListIsFetching) {
            cancelSource.cancel();
            cancelSource = CancelToken.source();
            newFilterObject = { 
                ...defaultParams,           
                ...cleanData,
				fundRequestAdministrativeService: servicio
            };
            setFilterObject(newFilterObject);
			dispatch(tryListDeliveryOrder(newFilterObject));
        }
    }

    const validateActionIcon = (item, type) => {
        let actionIconItem = {
            text: '',
            validation: false
        };
        if(type === VALIDATE_ACTION_TYPE.DELETE) {
            if (administrativeServiceClose(servicesData, item?.fundRequest?.administrativeServiceId)) {
                actionIconItem.text = "No puede anular una Orden de Entrega con Servicio cerrado dentro del ejercicio";
            } else {
                actionIconItem.text = "Anular Orden de Entrega", 
                actionIconItem.validation = true
            }
        };
        if(type === VALIDATE_ACTION_TYPE.UPDATE) {
            if (administrativeServiceClose(servicesData, item?.fundRequest?.administrativeServiceId)) {
                actionIconItem.text = "No puede editar una Orden de Entrega con Servicio cerrado dentro del ejercicio";
            } else {
                actionIconItem.text = "Editar Orden de Entrega", 
                actionIconItem.validation = true
            }
        };
        return actionIconItem
    };	

	return <>
			<Container fluid>
				<Card className='mb-5'>
                    <Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Orden de Entrega</h1>
                        <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-responsable-entrega-fondos/#ejecuci%C3%B3n-financiera--%C3%B3rdenes-de-entrega'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>
					<Card.Body>
						{
							deliveryOrderssPermissions?.canCreate && (
								<Button size='sm' className='mb-3 mr-3' variant='success' onClick={onClickNew}>
									<FontAwesomeIcon icon={faPlus} className='mr-1' />
									Nueva orden de entrega
								</Button>
							)
						}

						{
							deliveryOrderssPermissions?.canCreate && (
								<Button size='sm' className='mb-3' variant='success' onClick={onClickNewMassive}>
									<FontAwesomeIcon icon={faPlus} className='mr-1' />
									Nueva orden de entrega Masiva
								</Button>
							)
						}
						<Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
						<Table striped hover size='sm' responsive>
							<thead>
								<tr ref={creditLabelsRefContainer}>
									<th className='text-center align-middle' width="10%"><small>Nro Orden de Entrega</small></th>
									<th className='text-center align-middle' width="10%"><small>Expediente</small></th>
									<th className='text-center align-middle' width="28%"><small>Servicio Administrativo</small></th>
									<th className='text-center align-middle' width="22%"><small>Descripción</small></th>
									<th className='text-center align-middle' width="10%"><small>Nro Pedido de fondo</small></th>
									<th className='text-center align-middle' width="10%"><small>Importe</small></th>
									<th className='text-center align-middle th-minwidth'><small>Acciones</small></th>
								</tr>
					<tr className='secondary'>
                    <th>
						<div className='align-middle input-group'>
							<FormControl placeholder="Nro" size="sm" className='text-center' type='number' name='number' ref={register}/>
							<FormControl placeholder="Año" size="sm" className='text-center' type='number' name='year' ref={register}/>
						</div>
                    </th>
                    <th>
						<div className='align-middle input-group'>
							<FormControl placeholder="Org" size="sm" className='text-center' name='codeOrganismAdministrativeDocument' ref={register}/>
							<FormControl placeholder="Nro" size="sm" className='text-center' name='numberAdministrativeDocument' ref={register}/>
							<FormControl placeholder="Año" size="sm" className='text-center' name='yearAdministrativeDocument' ref={register}/>
						</div>
                    </th>
					<th>
						<Col className='px-0 d-flex align-items-center justify-content-end' id='administrativeServiceId'>
							<DropdownList
								placeholder='Seleccione un servicio...'
								data={administrativeServiceData}
								allowCreate='onFilter'
								name='administrativeServiceId'
								textField='displayName'
								dataKey='id'
								filter='contains'
								value={servicio}
								className='w-100 text-black-color'
								onChange={value => setServicio(value.id)}
								id='administrativeServiceId'
								messages={messagesDropDown}
								selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
							>
							</DropdownList>
						</Col>
					</th>
                    <th>
                        <FormControl className='text-center' size="sm" name='description' hidden ref={register}/>
                    </th>
                    <th>
						<div className='align-middle input-group'>
							<FormControl placeholder="Nro" className='text-center' size="sm" type='number' name='numberFundRequest' ref={register}/>
							<FormControl placeholder="Año" className='text-center' size="sm" type='number' name='yearFundRequest' ref={register}/>
						</div>
                    </th>
					<th></th>
					<th className='text-center align-middle'>
						<div className='d-flex justify-content-around'>
                            <ActionIcon
                                size="lg"
                                id="search-button"
                                className="btn-primary search-button text-white-color"
                                toolTipText="Filtrar"
                                icon={faFilter}
                                type='submit'
                                onSubmit={onSubmitForm}
                            />
                            <ActionIcon
                                size="lg"
                                id="clean-filter"
                                className="btn-primary clean-filter text-white-color"
                                toolTipText="Limpiar filtros"
                                icon={faBroom}
                                type='reset'
                                onClick={()=> clearFilters()}
                            />
                        </div>
                    </th>
                </tr>
							</thead>
							<tbody className='text-black-color'>
								{
									deliveryOrderListData && deliveryOrderListData != ""
										?
										<>
											{deliveryOrderListData?.map((item) => (
												<tr key={item.id}>
													<td className='text-center align-middle'>
														{`${item?.number}/${item?.year}`}
													</td>
													<td className='text-center align-middle'>
														{item?.administrativeDocument?.codeOrganism}-{item?.administrativeDocument?.number}/{item?.administrativeDocument?.year}
													</td>
													<td className='text-center align-middle truncate maxTruncate'>
														{administrativeService(item?.administrativeServiceId, administrativeServiceData)}
													</td>
													<td className='text-center align-middle maxTruncate' title={getDescriptionToShow(item?.fundRequest)}>
														{getDescriptionToShow(item?.fundRequest)}
													</td>
													<td className='text-center align-middle truncate maxTruncate' title={item?.fundRequest?.description} >
															{item?.fundRequestNumberSlim}/{item?.fundRequestYearSlim}
													</td>
													<td className='text-right align-middle truncate maxTruncate' title='Importe' >
															{formatterPeso.format(item?.amount)}
													</td>
													<td className='text-center align-middle' >
														<ActionIcon 
															size='lg' 
															id='credit-query' 
															className='mx-2'
															toolTipText='Detalle orden entrega' 
															icon={faSearch} 
															onClick={() => onClickDetail(item)} 
														/>
														{/* {
															deliveryOrderssPermissions?.canUpdate && (
																<ActionIcon 
																	size='lg' 
																	id='credit-query' 
																	className={(administrativeService(item?.fundRequest?.administrativeServiceId, administrativeServiceData) ? 'not-allowed' : '') + ' mx-2 '}
                                                                    toolTipText={validateActionIcon(item, VALIDATE_ACTION_TYPE.UPDATE).text} 
                                                                    onClick={() => validateActionIcon(item, VALIDATE_ACTION_TYPE.UPDATE).validation && onClickEdit(item)}
                                                                    icon={faEdit} 
																/>
															)
														} */}
														{
															deliveryOrderssPermissions?.canDelete && (
																<ActionIcon 
																	size='lg' 
																	id='delete' 
																	className={
																		((item?.fundRequest?.state !== 'AUTORIZADO_PENDIENTE_DE_PAGO') && 'not-allowed') +
																		' mx-2 '
																	}
                                                                    toolTipText={validateActionIcon(item, VALIDATE_ACTION_TYPE.DELETE).text} 
                                                                    onClick={() => validateActionIcon(item, VALIDATE_ACTION_TYPE.DELETE).validation && onClickDelete(item)}
																	icon={faTrash} 
																/>
															)
														}
													</td>
												</tr>
											))}
										</>
										:
										<tr>
											<td colSpan={colSpanCellsNumber} className='text-center'>
												{
													!deliveryOrderListIsFetching &&
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                        	No hay registros
                                                        </Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>
						</Form>
						<RCPagination
                            activePage={page}
                            itemsCountPerPage={perPage}
                            totalItemsCount={totalItems}
							totalItems={totalItems}
                            thePage={page}
                            onChange={onPageChange}
                            innerClass="justify-content-center"
                        />
					</Card.Body>
				</Card>
			</Container>
		<AppLoading isLoading={deliveryOrderListIsFetching || servicesDataIsFetching} />
	</>;
};

export default DeliveryOrderListPage;