import * as ACTION_TYPES from './subconceptActionTypes';

export const subconceptReducerInitialState = {
	listSubconcepts: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const subconceptsReducer = (state = subconceptsReducer, action) => {
	switch (action.type) {

		// List order pay actions handlings
		case ACTION_TYPES.LIST_SUBCONCEPT_REQUEST: {
			return {
				...state,
				listSubconcepts: {
					...state.listSubconcepts,
					isFetching: true,
					hasError: false,
					error: subconceptReducerInitialState.listSubconcepts.error,
					data: subconceptReducerInitialState.listSubconcepts.data
				}
			};
		}
		case ACTION_TYPES.LIST_SUBCONCEPT_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listSubconcepts: {
					...state.listSubconcepts,
					isFetching: false,
					hasError: false,
					error: subconceptReducerInitialState.listSubconcepts.error,
					data: data
				}
			};
		}
		case ACTION_TYPES.LIST_SUBCONCEPT_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listSubconcepts: {
					...state.listSubconcepts,
					isFetching: false,
					hasError: true,
					data: subconceptReducerInitialState.listSubconcepts.data,
					error: error
				}
			};
		}
		// case ACTION_TYPES.CLEAR_LIST_FUND_REQUESTS_DATA: {
		// 	return {
		// 		...state,
		// 		listSubconcepts: subconceptReducerInitialState.listSubconcepts
		// 	}
		// }
		//ENDS
		default: {
			return state;
		}
	}
};

export const getSubconceptsReducer = state => state.subconceptsReducer;

// List subconcept List
export const getSubconceptsList = state => getSubconceptsReducer(state)?.listSubconcepts;
export const getSubconceptsListData = state => getSubconceptsList(state)?.data;
export const getSubconceptsListIsFetching = state => getSubconceptsList(state)?.isFetching;
