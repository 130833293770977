import * as ACTIONS from './accountActions';
import * as SERVICES from 'src/services/accountServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetAccountList = ( page=1, filter={}, pageSize=100 ) => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getAccountListRequest() );

		if(accessToken){
			
			const response = await SERVICES.listAccount( accessToken, page, pageSize, filter );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAccountListRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getAccountListRequestFailure(response?.error) );
				showError(`Error al cargar las cuentas especiales. Detalle: ${response?.error}` );
			}
		} 

	};
};