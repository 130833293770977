import * as ACTION_TYPES from './creditActionTypes';

export const creditReducerInitialState = {
	creditListing: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: {}
	},
	creditDetails: {
		creditData: {},
		codeList: {
			data: undefined,
			isFetching: false,
			hasError: false,
			error: undefined
		}
	},
	creditToEdit: undefined,
	postCredit: {
		data: undefined,
		response: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	selectedLegalInstrument: undefined,
	creditProcessInProgress: false,
	totalAmounts: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const creditReducer = ( state=creditReducerInitialState, action ) => {
	switch( action.type ) {
		case ACTION_TYPES.LIST_CREDIT_REQUEST: {
			return {
				...state,
				creditListing: {
					...state.creditListing,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.LIST_CREDIT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			const previousRecords = state.creditListing.data?.data;

			if( previousRecords ) {
				data.data = previousRecords.concat(data.data);
			}

			return {
				...state,
				creditListing: {
					...state.creditListing,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_CREDIT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				creditListing: {
					...state.creditListing,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_LIST_CREDIT_DATA: {
			return {
				...state,
				creditListing: {
					...creditReducerInitialState.creditListing
				}
			};
		}

		case ACTION_TYPES.SET_CREDIT_DETAIL_DATA: {
			const { data } = action.payload;
			return {
				...state,
				creditDetails: {
					...state.creditDetails,
					creditData: data
				}
			};
		}

		case ACTION_TYPES.SET_CREDIT_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				creditToEdit: data
			}
		}

		case ACTION_TYPES.CLEAR_CREDIT_TO_EDIT: {
			return {
				...state,
				creditToEdit: creditReducerInitialState.creditToEdit
			}
		}

		case ACTION_TYPES.GET_CODES_FROM_CREDIT_REQUEST: {
			return {
				...state,
				creditDetails: {
					...state.creditDetails,
					codeList: {
						isFetching: true,
						hasError: false,
						data: creditReducerInitialState.creditDetails.codeList.data,
						error: creditReducerInitialState.creditDetails.codeList.error
					}
				}
			}
		}

		case ACTION_TYPES.GET_CODES_FROM_CREDIT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				creditDetails: {
					...state.creditDetails,
					codeList: {
						isFetching: false,
						hasError: false,
						data,
						error: creditReducerInitialState.creditDetails.codeList.error
					}
				}
			}
		}

		case ACTION_TYPES.GET_CODES_FROM_CREDIT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				creditDetails: {
					...state.creditDetails,
					codeList: {
						isFetching: false,
						hasError: true,
						data: creditReducerInitialState.creditDetails.codeList.data,
						error
					}
				}
			}
		}

		case ACTION_TYPES.CLEAR_CODES_FROM_CREDIT_ID: {
			return {
				...state,
				creditDetails: {
					...state.creditDetails,
					codeList: creditReducerInitialState.creditDetails.codeList
				}
			};
		}

		// Post credit Actions
		case ACTION_TYPES.POST_CREDIT_REQUEST: {
			const { data } = action.payload;
			return {
				...state,
				postCredit: {
					...state.postCredit,
					isFetching: true,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.POST_CREDIT_REQUEST_SUCCESS: {
			const { response } = action.payload;
			return {
				...state,
				postCredit: {
					...state.postCredit,
					isFetching: false,
					hasError: false,
					response
				}
			}
		}
		case ACTION_TYPES.POST_CREDIT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postCredit: {
					...state.postCredit,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		// END Post credit Actions



		// Set selected legal instrument
		case ACTION_TYPES.SET_SELECTED_LEGAL_INSTRUMENT: {
			const { data } = action.payload;
			return {
				...state,
				selectedLegalInstrument: data
			};
		}
		case ACTION_TYPES.CLEAR_SELECTED_LEGAL_INSTRUMENT: {
			return {
				...state,
				selectedLegalInstrument: creditReducerInitialState.selectedLegalInstrument
			}
		}
		// END Set selected legal instrument



		// Set credit process in progress
		case ACTION_TYPES.SET_CREDIT_PROCESS_IN_PROGRESS: {
			const { value } = action.payload;
			return {
				...state,
				creditProcessInProgress: value
			};
		}
		// END Set credit process in progress



		// Get total amounts request actions
		case ACTION_TYPES.GET_TOTAL_AMOUNTS_REQUEST: {
			return {
				...state,
				totalAmounts: {
					...state.totalAmounts,
					isFetching: true,
					hasError: false,
					data: creditReducerInitialState.totalAmounts.data,
					error: creditReducerInitialState.totalAmounts.error
				}
			}
		}
		case ACTION_TYPES.GET_TOTAL_AMOUNTS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				totalAmounts: {
					...state.totalAmounts,
					isFetching: false,
					hasError: false,
					error: creditReducerInitialState.totalAmounts.error,
					data
				}
			}
		}
		case ACTION_TYPES.GET_TOTAL_AMOUNTS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				totalAmounts: {
					...state.totalAmounts,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_TOTAL_AMOUNTS_DATA: {
			return {
				...state,
				totalAmounts: creditReducerInitialState.totalAmounts
			}
		}
		// END Get total amounts request actions



		default: {
			return state;
		}
	}
};

export const getCreditReducer = state => state.creditReducer;

export const getCreditListing = state => getCreditReducer(state).creditListing;

export const getCreditListingData = state => getCreditListing(state).data;
export const getCreditListingIsFetching = state => getCreditListing(state).isFetching;

export const getCreditDetails = state => getCreditReducer(state).creditDetails;

export const getCreditDetailsData = state => getCreditDetails(state).creditData;

export const getCreditToEdit = state => getCreditReducer(state).creditToEdit;

export const getIsEditionMode = state => ( getCreditToEdit(state) !== creditReducerInitialState.creditToEdit);

export const getFormTitle = state => ( getIsEditionMode(state) ? 'Edición de partida' : 'Nueva partida' );

export const getCreditDetailsCodeList = state => getCreditDetails(state)?.codeList;
export const getCreditDetailsCodeListData = state => getCreditDetailsCodeList(state)?.data;
export const getCreditDetailsCodeListIsFetching = state => getCreditDetailsCodeList(state)?.isFetching;
export const getCreditDetailsCodeListHasError = state => getCreditDetailsCodeList(state)?.hasError;
export const getCreditDetailsCodeListError = state => getCreditDetailsCodeList(state)?.error;

// Selected legal instrument
export const getSelectedLegalInstrument = state => getCreditReducer(state)?.selectedLegalInstrument;

// Credit process in progress
export const getCreditProcessInProgress = state => getCreditReducer(state)?.creditProcessInProgress;

// POST Credit
export const getPostCredit = state => getCreditReducer(state)?.postCredit;
export const getPostCreditIsFetching = state => getPostCredit(state)?.isFetching;

// Get total amounts
export const getTotalAmounts = state => getCreditReducer(state)?.totalAmounts;
export const getTotalAmountsData = state => getTotalAmounts(state)?.data;
export const getTotalAmountsIsFetching = state => getTotalAmounts(state)?.isFetching;