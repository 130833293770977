import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpPost, httpPut, httpDelete } from './httpServices';
import { getClearObject } from 'src/utils/utils';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const ORDER_PAY_URI = `${API_BASE_URI}/payment-orders`;
const PAY_ORDER_TYPES = `${API_BASE_URI}/payment-orders/types`;
const PAY_ORDER_LAST = `${API_BASE_URI}/payment-orders/last`;
const PROVISION_ORDER_URI = `${API_BASE_URI}/provision-orders`;

const API_PRESUPUESTO_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const AFFECTATION_URI = `${API_PRESUPUESTO_BASE_URI}/expenses/affectations-movements`;


// List orden pay
export const listOrderPay = async (accessToken, params) => {
	
	const options = buildDefaultOptions(accessToken);
	let paramsToSend = {
		page: params?.page || 0,
		size: params?.size || 10,
		sort: params?.sort || 'id,desc',
		state: params?.state || '',
		type: params?.type || '',
		description: params?.description || '',
		'ad.codeOrganism': params?.codeOrganismAdministrativeDocument || '',
		'ad.number': params?.numberAdministrativeDocument || '',
		'ad.year': params?.yearAdministrativeDocument || '',
		administrativeServiceId: params?.administrativeServiceId || '',
		bankAccountId: params?.bankAccountId || '',
		number: params?.number || '',
		datePaymentOrder: params?.datePaymentOrder || '',
		hasFigurativeExpenditure: params?.hasFigurativeExpenditure || '',
		year: params?.year || ''
	};
	paramsToSend = getClearObject(paramsToSend);
	
	const queryString = buildURLQuery(paramsToSend);
	const URL = `${ORDER_PAY_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);

	}
	catch (error) {
		return error;
	}

	return response;
};

// Detail orden pay
export const detailOrderPay = async (accessToken, ordenPayID) => {
	const options = buildDefaultOptions(accessToken);

	const URL = `${ORDER_PAY_URI}/${ordenPayID}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


//New order pay
export const newOrderPay = async (accessToken, params) => {

	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPost(ORDER_PAY_URI, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};


//Edit order pay
export const editOrderPay = async (accessToken, serviceId, params) => {
	const URL = `${ORDER_PAY_URI}/${serviceId}`
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Delete order pay
export const deleteOrderPay = async (accessToken, id) => {

	const options = buildDefaultOptions(accessToken);
	let response;
	const URL = `${ORDER_PAY_URI}/${id}`;

	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

// Detail orden pay
export const getOrderPayId = async (accessToken, administrativeServiceId) => {
	const URL = `${ORDER_PAY_URI}/${administrativeServiceId}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}


// affectations movements by ID orden pay
export const getAffectationsMovementsByIDOrderPay = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	let url = ''
	
	params.forEach(param => {
		url = `${url}affectationMovementIds[]=${param}&`;
	});
	const URL = `${AFFECTATION_URI}?${url}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// List payment-orders/types
export const listPayOrderTypes = async (accessToken) => {
	const options = buildDefaultOptions(accessToken);
	let response;
	
	try {
		response = await httpGet(PAY_ORDER_TYPES, options);
	
	}
	catch (error) {
		
		return error;
	}

	return response;
};

// List payment-orders/last
export const listPayOrderLast = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);

	const paramsToSend = {
		page: params?.page || 0,
		size: params?.size || 1,
		sort: 'number,desc' || '',
		administrativeServiceId: params?.administrativeServiceId || '',
        year: params?.year || '',
	};

	const queryString = buildURLQuery(paramsToSend);
	const URL = `${PAY_ORDER_LAST}?${queryString}`;
	
	let response;

	try {
		response = await httpGet(URL, options);

	}
	catch (error) {
		
		return error;
	}

	return response;
};

// List provision order
export const getListPovisionOrder = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);

	const queryString = buildURLQuery(params);
	const URL = `${PROVISION_ORDER_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}

	catch (error) {
		return error;
	}

	return response;
};

// List affectations by order pay type
export const listAffectationsByOrderTypeService = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);

	const queryString = buildURLQuery(params);
	const URL = `${ORDER_PAY_URI}/getAffectationByPaymentOrderType?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}

	catch (error) {
		return error;
	}

	return response;
};

export const listFigurativesByOPId = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);

	const queryString = buildURLQuery(params);
	const URL = `${ORDER_PAY_URI}/figurative-balance?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}

	catch (error) {
		return error;
	}

	return response;
};

export const listOrderPayByAdministrativeDocument = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	const queryString = buildURLQuery(params);
	const URL = `${ORDER_PAY_URI}/getAllPaymentOrdersByAdministrativeDocument?${queryString}`;
	let response;
	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}
	return response;
};

export const listWorkCertificateTypes = async (accessToken) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${ORDER_PAY_URI}/getWorkCertificateTypes`;
	let response;
	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}
	return response;
};

// payment order change state
export const fetchChangeState = async (accessToken, id, state) => {
	try {
		const response = await fetch(`${API_BASE_URI}/payment-orders/${id}/change-state?state=${state}`, {
			method: 'PUT',
			headers: {
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error("error", error);
		throw error;
	}
};