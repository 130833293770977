import * as ACTION_TYPES from './figurativeExpedituresTypes';

export const figurativeExpedituresReducerInitialState = {
	figurativeExpedituresList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	figurativeExpedituresNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	figurativeExpedituresEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	figurativeExpedituresToEdit: {},
	// figurativeExpedituresCompleteNumber: undefined,
	// figurativeExpedituresAperturaDescription: undefined,
	figurativeExpedituresVinculation: []
};

export const figurativeExpedituresReducer = ( state=figurativeExpedituresReducerInitialState, action ) => {
	switch( action.type ) {

		// figurative expeditures list actions
		case ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_REQUEST: {
			return {
				...state,
				figurativeExpedituresList: {
					...state.figurativeExpedituresList,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			const previousRecords = state.figurativeExpedituresList.data?.data;

			if( previousRecords ) {
				data.data = previousRecords.concat(data.data);
			}
			return {
				...state,
				figurativeExpedituresList: {
					...state.figurativeExpedituresList,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				figurativeExpedituresList: {
					...state.figurativeExpedituresList,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_FIGURATIVE_EXPEDITURES_REQUEST: {
			return {
				...state,
				figurativeExpedituresList: {
					...figurativeExpedituresReducerInitialState.figurativeExpedituresList
				}
			};
		}
		// END 


		// New figurative expeditures
		case ACTION_TYPES.POST_FIGURATIVE_EXPEDITURES_REQUEST: {
			return {
				...state,
				figurativeExpedituresNew: {
					...state.figurativeExpedituresNew,
					isFetching: true,
					hasError: false,
					error: figurativeExpedituresReducerInitialState.figurativeExpedituresNew.error
				}
			};
		}
		case ACTION_TYPES.POST_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				figurativeExpedituresNew: {
					...state.figurativeExpedituresNew,
					isFetching: false,
					hasError: false,
					error: figurativeExpedituresReducerInitialState.figurativeExpedituresNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.POST_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				figurativeExpedituresNew: {
					...state.figurativeExpedituresNew,
					isFetching: false,
					hasError: true,
					data: figurativeExpedituresReducerInitialState.figurativeExpedituresNew.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_FIGURATIVE_EXPEDITURES_REQUEST: {

			return {
				...state,
				figurativeExpedituresNew: figurativeExpedituresReducerInitialState.figurativeExpedituresNew
			}
		}
		// END


		// PUT figurative expeditures
		case ACTION_TYPES.PUT_FIGURATIVE_EXPEDITURES_REQUEST: {
			return {
				...state,
				figurativeExpedituresEdit: {
					...state.figurativeExpedituresEdit,
					isFetching: true,
					hasError: false,
					error: figurativeExpedituresReducerInitialState.figurativeExpedituresEdit.error
				}
			};
		}
		case ACTION_TYPES.PUT_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				figurativeExpedituresEdit: {
					...state.figurativeExpedituresEdit,
					isFetching: false,
					hasError: false,
					error: figurativeExpedituresReducerInitialState.figurativeExpedituresEdit.error,
					data
				}
			};
		}
		case ACTION_TYPES.PUT_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				figurativeExpedituresEdit: {
					...state.figurativeExpedituresEdit,
					isFetching: false,
					hasError: true,
					data: figurativeExpedituresReducerInitialState.figurativeExpedituresEdit.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_FIGURATIVE_EXPEDITURES_REQUEST: {

			return {
				...state,
				figurativeExpedituresEdit: figurativeExpedituresReducerInitialState.figurativeExpedituresEdit
			}
		}
		// END


		// Set figurative expeditures completeNumber
		case ACTION_TYPES.SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER:{
			const { data } = action.payload;
			return {
				...state,
				figurativeExpedituresCompleteNumber: data
			}
		}
		case ACTION_TYPES.CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER:{
			return{
				...state,
				figurativeExpedituresCompleteNumber: undefined
			}
		}

		case ACTION_TYPES.SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION:{
			const { data } = action.payload;
			return {
				...state,
				figurativeExpedituresAperturaDescription: data
			}
		}
		case ACTION_TYPES.CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION:{
			return{
				...state,
				figurativeExpedituresAperturaDescription: undefined
			}
		}

		case ACTION_TYPES.SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION:{
			const { data } = action.payload;
			return {
				...state,
				figurativeExpedituresVinculation: [...state.figurativeExpedituresVinculation, data]
			}
		}
		case ACTION_TYPES.DELETED_FIGURATIVE_EXPEDITURES_VINCULATION:{
			const { data } = action.payload;
			return {
				...state,
				figurativeExpedituresVinculation: state.figurativeExpedituresVinculation.filter(
					items => items.code !== data
				)
			}
		}
		case ACTION_TYPES.CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION:{
			return{
				...state,
				figurativeExpedituresVinculation: []
			}
		}

		// EDIT figurative expeditures to edit
		case ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				figurativeExpedituresToEdit: data
			}
		}
		case ACTION_TYPES.SET_FIGURATIVE_EXPEDITURES_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				figurativeExpedituresToEdit: data
			}
		}
		case ACTION_TYPES.CLEAR_FIGURATIVE_EXPEDITURES_TO_EDIT:{
			const { data } = action.payload;

			return{
				...state,
				figurativeExpedituresToEdit: data
			}
		}

		default: {
			return state;
		}
	}
};

export const getFigurativeExpedituresReducer = state => state.figurativeExpedituresReducer;

// GET figurative expeditures
export const getFigurativeExpedituresList = state => getFigurativeExpedituresReducer(state)?.figurativeExpedituresList;
export const getFigurativeExpedituresListData = state => getFigurativeExpedituresList(state)?.data;
export const getFigurativeExpedituresListIsFetching = state => getFigurativeExpedituresList(state)?.isFetching;

// POST figurative expeditures
export const getFigurativeExpedituresCreate = state => getFigurativeExpedituresReducer(state)?.FigurativeExpedituresNew;
export const getFigurativeExpedituresCreateIsFetching = state => getFigurativeExpedituresCreate(state)?.isFetching;
export const getFigurativeExpedituresCreateIsError = state => getFigurativeExpedituresCreate(state)?.hasError;

// PUT figurative expeditures
export const getFigurativeExpedituresEdit = state => getFigurativeExpedituresReducer(state)?.FigurativeExpedituresEdit;
export const getFigurativeExpedituresEditIsFetching = state => getFigurativeExpedituresEdit(state)?.isFetching;
export const getFigurativeExpedituresEditIsError = state => getFigurativeExpedituresEdit(state)?.hasError;

// SET figurative expeditures
export const getFigurativeExpedituresCompleteNumber = state => getFigurativeExpedituresReducer(state)?.figurativeExpedituresCompleteNumber;
export const getFigurativeExpedituresAperturaDescription = state => getFigurativeExpedituresReducer(state)?.figurativeExpedituresAperturaDescription;
export const getFigurativeExpedituresVinculation = state => getFigurativeExpedituresReducer(state)?.figurativeExpedituresVinculation;

// EDIT figurative expeditures
export const getFigurativeExpedituresToEdit = state => getFigurativeExpedituresReducer(state)?.figurativeExpedituresToEdit;
