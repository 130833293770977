import * as ACTION_TYPES from './functionalityActionTypes';

export const functionalityReducerInitialState = {
	functionalitiesListByPurposeId: {
		purposeId: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const functionalityReducer = ( state=functionalityReducerInitialState, action ) => {
	switch( action.type ) {

		
		// Functionalities by purposeId actions
		case ACTION_TYPES.LIST_FUNCTIONALITIES_BY_PURPOSE_ID_REQUEST: {
			const { purposeId } = action.payload;
			return {
				...state,
				functionalitiesListByPurposeId: {
					...state.functionalitiesListByPurposeId,
					isFetching: true,
					hasError: false,
					error: functionalityReducerInitialState.functionalitiesListByPurposeId.error,
					data: functionalityReducerInitialState.functionalitiesListByPurposeId.data,
					purposeId
				}
			};
		}

		case ACTION_TYPES.LIST_FUNCTIONALITIES_BY_PURPOSE_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				functionalitiesListByPurposeId: {
					...state.functionalitiesListByPurposeId,
					isFetching: false,
					hasError: false,
					error: functionalityReducerInitialState.functionalitiesListByPurposeId.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_FUNCTIONALITIES_BY_PURPOSE_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				functionalitiesListByPurposeId: {
					...state.functionalitiesListByPurposeId,
					isFetching: false,
					hasError: true,
					data: functionalityReducerInitialState.functionalitiesListByPurposeId.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_FUNCTIONALITIES_LIST_BY_PURPOSE_ID: {
			return {
				...state,
				functionalitiesListByPurposeId: functionalityReducerInitialState.functionalitiesListByPurposeId
			};
		}
		// END Functionalities by purposeId actions




		default: {
			return state;
		}
	}
};

export const getFunctionalityReducer = state => state.functionalityReducer;

export const getFunctionalityListByPurposeId = state => getFunctionalityReducer(state)?.functionalitiesListByPurposeId;
export const getFunctionalityListByPurposeIdData = state => getFunctionalityListByPurposeId(state)?.data;
export const getFunctionalityListByPurposeIdIsFetching = state => getFunctionalityListByPurposeId(state)?.isFetching;
export const getFunctionalityListByPurposeIdHasError = state => getFunctionalityListByPurposeId(state)?.hasError;
export const getFunctionalityListByPurposeIdError = state => getFunctionalityListByPurposeId(state)?.error;