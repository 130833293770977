export const LIST_JURISDICTION_REQUEST = 'LIST_JURISDICTION_REQUEST';
export const LIST_JURISDICTION_REQUEST_SUCCESS = 'LIST_JURISDICTION_REQUEST_SUCCESS';
export const LIST_JURISDICTION_REQUEST_FAILURE = 'LIST_JURISDICTION_REQUEST_FAILURE';

export const CLEAR_JURISDICTION_LIST = 'CLEAR_JURISDICTION_LIST';


// get Jurisdictions by Services and Exercise	
export const JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST = 'JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST';
export const JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS = 'JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS';
export const JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE = 'JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE';

export const CLEAR_JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID = 'CLEAR_JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID';