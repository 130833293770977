// GET wage template request actions
export const GET_WAGE_TEMPLATE_REQUEST = 'GET_WAGE_TEMPLATE_REQUEST';
export const GET_WAGE_TEMPLATE_REQUEST_SUCCESS = 'GET_WAGE_TEMPLATE_REQUEST_SUCCESS';
export const GET_WAGE_TEMPLATE_REQUEST_FAILURE = 'GET_WAGE_TEMPLATE_REQUEST_FAILURE';
export const CLEAR_WAGE_TEMPLATE_REQUEST = 'CLEAR_WAGE_TEMPLATE_REQUEST';

// POST wage template request actions
export const POST_WAGE_TEMPLATE_REQUEST = 'POST_WAGE_TEMPLATE_REQUEST';
export const POST_WAGE_TEMPLATE_REQUEST_SUCCESS = 'POST_WAGE_TEMPLATE_REQUEST_SUCCESS';
export const POST_WAGE_TEMPLATE_REQUEST_FAILURE = 'POST_WAGE_TEMPLATE_REQUEST_FAILURE';
// export const CLEAR_WAGE_TEMPLATE_REQUEST = 'CLEAR_WAGE_TEMPLATE_REQUEST';

// PUT wage template request actions
export const PUT_WAGE_TEMPLATE_REQUEST = 'PUT_WAGE_TEMPLATE_REQUEST';
export const PUT_WAGE_TEMPLATE_REQUEST_SUCCESS = 'PUT_WAGE_TEMPLATE_REQUEST_SUCCESS';
export const PUT_WAGE_TEMPLATE_REQUEST_FAILURE = 'PUT_WAGE_TEMPLATE_REQUEST_FAILURE';

// DELETE wage template request actions
export const DELETE_WAGE_TEMPLATE_REQUEST = 'DELETE_WAGE_TEMPLATE_REQUEST';
export const DELETE_WAGE_TEMPLATE_REQUEST_SUCCESS = 'DELETE_WAGE_TEMPLATE_REQUEST_SUCCESS';
export const DELETE_WAGE_TEMPLATE_REQUEST_FAILURE = 'DELETE_WAGE_TEMPLATE_REQUEST_FAILURE';
export const CLEAR_DELETE_WAGE_TEMPLATE_REQUEST = 'CLEAR_DELETE_WAGE_TEMPLATE_REQUEST';

// SET wage template
export const SELECTED_WAGE_TEMPLATE = 'SELECTED_WAGE_TEMPLATE';
export const DELETED_WAGE_TEMPLATE = 'DELETED_SELECTED_WAGE_TEMPLATE';
export const CLEAR_SELECTED_WAGE_TEMPLATE = 'CLEAR_SELECTED_WAGE_TEMPLATE';

export const SET_WAGE_TEMPLATE_TO_EDIT = 'SET_WAGE_TEMPLATE_TO_EDIT';
export const CLEAR_WAGE_TEMPLATE_TO_EDIT = 'CLEAR_WAGE_TEMPLATE_TO_EDIT';

//Detail 
export const SET_WAGE_TEMPLATE_DETAIL = 'SET_WAGE_TEMPLATE_DETAIL';
export const CLEAR_WAGE_TEMPLATE_DETAIL = 'CLEAR_WAGE_TEMPLATE_DETAIL';

//Get wage template recruitment method
export const LIST_RECRUITMENT_METHOD_REQUESTS = 'LIST_RECRUITMENT_METHOD_REQUESTS';
export const LIST_RECRUITMENT_METHOD_REQUESTS_SUCCESS = 'LIST_RECRUITMENT_METHOD_REQUESTS_SUCCESS';
export const LIST_RECRUITMENT_METHOD_REQUESTS_FAILURE = 'LIST_RECRUITMENT_METHOD_REQUESTS_FAILURE';
export const SET_LIST_RECRUITMENT_METHOD_REQUEST = 'SET_LIST_RECRUITMENT_METHOD_REQUEST';
export const CLEAR_STATES_LIST_RECRUITMENT_METHOD_REQUESTS = 'CLEAR_STATES_LIST_RECRUITMENT_METHOD_REQUESTS';
