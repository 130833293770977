import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk,faTimes } from '@fortawesome/free-solid-svg-icons';
import * as  LABELS from 'src/utils/label';
import ActionIcon from 'src/components/general/ActionIcon';
import { getFigurativeExpedituresCreateIsFetching } from 'src/redux/figurativeExpeditures/figurativeExpedituresReducer.js';
import { FIGURATIVE_EXPEDITURES } from 'src/utils/constants';
import { tryPutFigurativeExpeditures } from 'src/redux/figurativeExpeditures/figurativeExpedituresActionCreator';
import { getFigurativeExpedituresVinculation, getFigurativeExpedituresToEdit } from 'src/redux/figurativeExpeditures/figurativeExpedituresReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import BudgetItems from 'src/components/pages/BudgetItems';
import { setFigurativeExpedituresVinculation, clearFigurativeExpedituresCompleteNumber, clearFigurativeExpedituresVinculation, deletedFigurativeExpedituresVinculation } from 'src/redux/figurativeExpeditures/figurativeExpedituresActions';
import { getSubpartialBudgetOfSelectedCreditsIsFetching } from 'src/redux/partialBudget/partialBudgetReducer';
import { clearNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';

const FigurativeExpedituresEditPage = () => {    
    const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const globalSelectedPeriod = useSelector((state) => getGlobalDataSelectedPeriod(state));
    const figurativeExpedituresCreateIsFetching = useSelector(state => getFigurativeExpedituresCreateIsFetching(state));
    const isFetchingSubpartialBudgetOfSelectedCredits = useSelector(state => getSubpartialBudgetOfSelectedCreditsIsFetching(state));
    const figurativeExpedituresVinculation = useSelector((state) => getFigurativeExpedituresVinculation(state));
    const figurativeExpedituresToEdit = useSelector((state) => getFigurativeExpedituresToEdit(state));

    const handleDeleteApertura = () => {
        dispatch(clearNewAffectationDataSelectedCredits());
        dispatch(clearFigurativeExpedituresCompleteNumber());
    }
    const handleDeleteVinculation = (code) => {
        dispatch(deletedFigurativeExpedituresVinculation(code));
    }
    const onFormSubmitVinculation = () => {
        const paramsToSend = {
            id: figurativeExpedituresToEdit.id,
            budgetTarget: figurativeExpedituresToEdit.budgetTarget,
            budgetTargetVisibleName: figurativeExpedituresToEdit.budgetTargetVisibleName,
            budgetFilters: figurativeExpedituresVinculation,
            year: globalSelectedPeriod.year
        }         
        dispatch(tryPutFigurativeExpeditures(paramsToSend)).then(response => {
            if (response.status == 200) {
                dispatch(push(FIGURATIVE_EXPEDITURES));
                handleDeleteApertura();
                handleDeleteVinculation();
            }
        });
    };
    useEffect(()=>{
        handleDeleteApertura();
        dispatch(clearFigurativeExpedituresVinculation());
        for (let i = 0; i < figurativeExpedituresToEdit.budgetFilters.length; i++) {
            const budgetFilters = {
                id: figurativeExpedituresToEdit?.budgetFilters[i]?.id,
                code: figurativeExpedituresToEdit?.budgetFilters[i]?.code, 
                name: figurativeExpedituresToEdit?.budgetFilters[i]?.name,
                shortName: figurativeExpedituresToEdit?.budgetFilters[i]?.shortName
            };
            dispatch(setFigurativeExpedituresVinculation(budgetFilters));
        }
    }, []);

    return  <Container fluid>
                <Card className='mb-5'>
                    <Card.Header>
                        <h6 className='mb-0'>
                            {LABELS.figurativeExpedituresLabel}
						</h6>
                    </Card.Header>
                    <Card.Body >
                        <Row>
                            <Col xs={8}>
                                <h6 className='mb-4 text-black-color'>
                                   {LABELS.detailOfFigurativeExpeditures}
								</h6>
                            </Col>
                            <Col xs={4} className='text-right font-weight-bold font-italic'>
                                <span className='text-danger d-flex mandatory-label'>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									{LABELS.required}
								</span>
                            </Col>
                        </Row>
                        <FormProvider {...hookFormMethods} >
                            <Form className='mt-4' >
                                <Col><h4 className='mb-2 text-black-color text-center'>Apertura</h4></Col>
                                    <Container className="px-5">
                                        {
                                            figurativeExpedituresToEdit.budgetTarget &&
                                                <Table striped responsive hover size="sm">
                                                    <thead>
                                                        <tr className='text-white-color'>
                                                            <th className='text-center' width="40%">Apertura</th>
                                                            <th className='text-center' width="60%">Descripción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-black-color'>
                                                        <tr >
                                                            <td className='text-center'>
                                                                {figurativeExpedituresToEdit.budgetTarget}
                                                            </td>
                                                            <td className='text-center'>
                                                                {figurativeExpedituresToEdit.budgetTargetVisibleName}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                        }
                                    </Container>
                                {
                                    figurativeExpedituresToEdit.budgetFilters &&
                                    <div>
                                        <Col><h4 className='mt-5 mb-2 text-black-color text-center'>Vinculación</h4></Col>
                                        {
                                            figurativeExpedituresVinculation?.length > 0 &&
                                            <Container className="px-5 mb-5">
                                                <Table striped responsive hover size="sm">
                                                    <thead>
                                                        <tr className='text-white-color'>
                                                            <th className='text-center' width="30%">Vinculación</th>
                                                            <th className='text-center' width="15%">Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-black-color'>
                                                        {
                                                            figurativeExpedituresVinculation?.map((item, idx) => {
                                                                return (
                                                                    <tr key={idx}>
                                                                        <td className='text-center'>
                                                                            {item.code}
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <ActionIcon 
                                                                                size='lg' 
                                                                                toolTipText='Remover Instrumento' 
                                                                                icon={faTimes} 
                                                                                className='text-danger' 
                                                                                onClick={() => handleDeleteVinculation(item.code)} 
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Container>
                                        }
                                        <BudgetItems vinculation={true} isFetchingSubpartialBudgetOfSelectedCredits={isFetchingSubpartialBudgetOfSelectedCredits} />
                                    </div>
                                }
                                <div className='d-flex justify-content-around mt-4 mb-3'>
                                    <Button 
                                        type='button' 
                                        variant='primary' 
                                        size='lg' 
                                        disabled={figurativeExpedituresCreateIsFetching} 
                                        onClick={() => dispatch(push(FIGURATIVE_EXPEDITURES))}
                                    >
                                        {LABELS.cancel}
                                    </Button>
                                    <span className={figurativeExpedituresCreateIsFetching ? '' : 'hidden'}>
                                        <Spinner className='spinner-border text-danger' animation='border' />
                                    </span>
                                    <Button
                                        variant='success'
                                        size='lg'
                                        disabled={figurativeExpedituresCreateIsFetching}
                                        onClick={onFormSubmitVinculation}
                                    >
                                        {LABELS.save}
                                        <FontAwesomeIcon icon={faSave} className='ml-2' />
                                    </Button>
                                </div>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            </Container>
};
export default FigurativeExpedituresEditPage;