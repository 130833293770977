import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, httpPut, buildURLQuery, httpDelete } from './httpServices';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const BANKACCOUNT_URI = `${API_BASE_URI}/bank-accounts`;


export const getListBankAccountsFundRequest = async (accessToken, params = {}) => {
	const queryString = buildURLQuery(params);
	const URL = `${BANKACCOUNT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	};

	return response;
};

// Create exercise service
export const createBankAccount = async (accessToken, data) => {
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpPost(BANKACCOUNT_URI, data, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


//Delete delivery order
export const deleteBankAccount = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	let response;
	const URL = `${BANKACCOUNT_URI}/${id}`;
	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};


//Bank Account Edit

export const editBankAccount = async (accessToken, data) => {
	const URL = `${BANKACCOUNT_URI}/${data?.id}`;
	const options = buildDefaultOptions(accessToken);

	let response;
	try {
		response = await httpPut(URL, data, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Detail BankAccount
export const getBankAccountId = async (accessToken, id) => {
	const URL = `${BANKACCOUNT_URI}/${id}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}