import React from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import { useProfileChecks } from './hooks/useProfileChecks';
import { push } from 'connected-react-router';

const MenuNotification = () => {
	const dispatch = useDispatch();
    const { profileName, paramsToAlerts } = usePermissions();
    const { 
        isProfileNameSA,
        isProfileNameAI,
        isProfileBudgetServiceOperator,
        isProfileTreasuryOperator,
        isProfileDirectorSa,
        isProfileFundsTransferManager,
    } = useProfileChecks();

    // Valido que si no es ninguno de los perfiles permitidos, no muestre el menú
    if ( !(
        isProfileNameSA 
        || isProfileBudgetServiceOperator 
        || isProfileTreasuryOperator 
        || isProfileDirectorSa 
        || isProfileFundsTransferManager 
        || isProfileNameAI 
    )) return null;

    return (
        <Nav.Item className='active' onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}>
            <Nav.Link className='nav-level-1' onClick={() => dispatch(push(ROUTE_PATHS.NOTIFICATION_LIST))}>
                <FontAwesomeIcon icon={faEnvelope} className='mr-1' />
                Notificaciones
            </Nav.Link>
        </Nav.Item>
    );
};

export default MenuNotification;