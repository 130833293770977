import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import 'react-widgets/styles.css';
import { isNotEmptyArray } from 'src/services/validationService';
import { getReportBudgetStatusCreditExecutionGeneratedListData, getReportBudgetStatusCreditExecutionGeneratedListIsFetching, getReportBudgetStatusCreditExecutionGeneratedIsFetching } from 'src/redux/reports/reportsReducer'
import { tryGetReportBudgetStatusCreditExecutionList } from 'src/redux/reports/reportsActionCreactor'
import { clearListReportBudgetGeneratedList } from 'src/redux/reports/reportsActions'

import GeneratedReportsTable from "./GeneratedReportsTable";

import RCPagination from 'src/components/common/RCPagination';
import ActionIcon from "../../general/ActionIcon";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const HistoryGeneratedReports = ({ selectedReport }) => {

    const dispatch = useDispatch();

    const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

    const subcodeDetailData = useSelector(state => getReportBudgetStatusCreditExecutionGeneratedListData(state));
    const tableData = subcodeDetailData?.data;
    const hasSubcodeDetailData = isNotEmptyArray(subcodeDetailData?.data);
    const isFeching = useSelector(state => getReportBudgetStatusCreditExecutionGeneratedListIsFetching(state));
    const reportIsFeching = useSelector(state => getReportBudgetStatusCreditExecutionGeneratedIsFetching(state));

    const defaultParams = {
        page: 2, //comenzando en 1
        size: 10,
        period_id: globalSelectedPeriod?.id
    };

    //Pagination
	const page = subcodeDetailData?.page;
	const totalItems = subcodeDetailData?.totalCount;
	const perPage= subcodeDetailData?.perPage;

    const [pageNumber, setPageNumber] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            page: pNumber
        }
        loadData(params)
    };

    // End Pagination

    const loadData = (params) => {
        if (selectedReport == "historical") {
            dispatch(clearListReportBudgetGeneratedList());
            dispatch(tryGetReportBudgetStatusCreditExecutionList({ ...defaultParams, ...params })).then((response) => {
                setPageNumber(params ? response?.metadata?.page + 1 : 1);
                setTotalCount(response?.data?.length);
            });
        };
    };

    useEffect(() => {
        loadData(defaultParams);
    }, [selectedReport]);

    return <>
        <ActionIcon
            size='lg'
            id='updateId'
            toolTipText='Actualizar'
            className='float-right mr-3'
            icon={faSync}
            onClick={() => loadData()}
        />
        <div className='table-responsive'>
            <GeneratedReportsTable
                hasSubcodeDetailData={hasSubcodeDetailData}
                subcodeDetailData={tableData}
                isFeching={isFeching}
                reportIsFeching={reportIsFeching}
                loadData={loadData}
            />
            
            {
                hasSubcodeDetailData &&
                    <RCPagination
                        activePage={page}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalItems}
                        totalItems={totalItems}
                        thePage={page}
                        onChange={onPageChange}
                        innerClass="justify-content-center"
                    />
            }

        </div>
    </>
};

export default HistoryGeneratedReports;