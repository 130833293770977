export const GET_LIST_CONCEPT_SUBCONCEPT_REQUEST = 'GET_LIST_CONCEPT_SUBCONCEPT_REQUEST';

export const LIST_CONCEPT_REQUEST = 'GET_LIST_CONCEPT_REQUEST';
export const LIST_CONCEPT_SUCCESS = 'LIST_CONCEPT_SUCCESS';
export const LIST_CONCEPT_FAILURE = 'LIST_CONCEPT_FAILURE';
export const CLEAR_LIST_CONCEPT = 'CLEAR_LIST_CONCEPT';

//List Select
export const LIST_SELECT_CONCEPT_REQUEST = 'LIST_SELECT_CONCEPT_REQUEST';
export const LIST_SELECT_CONCEPT_SUCCESS = 'LIST_SELECT_CONCEPT_SUCCESS';
export const LIST_SELECT_CONCEPT_FAILURE = 'LIST_SELECT_CONCEPT_FAILURE';

// Add

//ADD New Bank Account
export const ADD_NEW_CONCEPT_REQUEST_REQUEST = 'ADD_NEW_CONCEPT_REQUEST_REQUEST';
export const ADD_NEW_CONCEPT_REQUEST_SUCCESS = 'ADD_NEW_CONCEPT_REQUEST_SUCCESS';
export const ADD_NEW_CONCEPT_REQUEST_FAILURE = 'ADD_NEW_CONCEPT_REQUEST_FAILURE';

export const ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_REQUEST = 'ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_REQUEST';
export const ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_SUCCESS = 'ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_SUCCESS';
export const ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_FAILURE = 'ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_FAILURE';

//Delete
export const DELETE_CONCEPT_REQUEST = 'DELETE_CONCEPT_REQUEST';
export const DELETE_CONCEPT_SUCCESS = 'DELETE_CONCEPT_SUCCESS';
export const DELETE_CONCEPT_FAILURE = 'DELETE_CONCEPT_FAILURE';

//Update
export const UPDATE_CONCEPT_REQUEST = 'UPDATE_CONCEPT_REQUEST';
export const UPDATE_CONCEPT_SUCCESS = 'UPDATE_CONCEPT_SUCCESS';
export const UPDATE_CONCEPT_FAILURE = 'UPDATE_CONCEPT_FAILURE';
export const SET_CONCEPT_TO_EDIT = 'SET_CONCEPT_TO_EDIT';