import React, { useEffect } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';
import { useForm, FormProvider } from 'react-hook-form';

import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { tryGetAvailablesAffectationStatusOfSelectedCredits, tryGetAffectationBalancesOfSelectedCredits } from 'src/redux/affectation/affectationActionCreator';
import { getSubpartialBudgetsDataOfSelectedCredits } from 'src/redux/partialBudget/partialBudgetActionCreator';
import { getSubpartialBudgetOfSelectedCreditsIsFetching } from 'src/redux/partialBudget/partialBudgetReducer';

import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined } from 'src/utils/utils';
import { BALANCE_TRANSFER_STEP_ONE, BALANCE_TRANSFER_STEP_THREE, CREDIT_EXECUTION } from 'src/utils/constants';
import { clearLatestAffectationTransactionData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import BudgetItems from 'src/components/pages/BudgetItems';
import { impactsBudgetItems } from 'src/utils/label';

import SelectedBudgetItems from 'src/components/pages/CreditExecution/SelectedBudgetItems';

const BalanceTrasnferStepTwo = () => {

	const dispatch = useDispatch();
	const hookFormMethods = useForm();

	const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
	const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
	const administrativeDocumentData = { ...administrativeDocument, organizationCode: parseIntOrUndefined(administrativeDocument?.organizationCode) };
	const showExpedientCheck = true;

	const hasSelectedCredits = isNotEmptyArray(selectedCredits);
	const enableNextStepButton = hasSelectedCredits;
	const isFetchingSubpartialBudgetOfSelectedCredits = useSelector(state => getSubpartialBudgetOfSelectedCreditsIsFetching(state));
	const onClickBackButton = () => { dispatch(push(BALANCE_TRANSFER_STEP_ONE)) };

	const onClickNextStepButton = () => {
		dispatch(push(BALANCE_TRANSFER_STEP_THREE));
		const allPromises = [];
		//allPromises.push(dispatch(getSubpartialBudgetsDataOfSelectedCredits(selectedCredits)));
		//allPromises.push(dispatch(tryGetAvailablesAffectationStatusOfSelectedCredits(selectedCredits, administrativeDocumentData)));
		allPromises.push(dispatch(tryGetAffectationBalancesOfSelectedCredits(selectedCredits, administrativeDocumentData)));
		Promise.all(allPromises)
			.then(() => { dispatch(push(BALANCE_TRANSFER_STEP_THREE)) });
		dispatch(clearLatestAffectationTransactionData());
	};

	useEffect(() => {
        if(!administrativeDocument){
			dispatch(push(CREDIT_EXECUTION));
		}
    }, []);

	return <Container fluid >
		<Card className='mb-5'>
			<PageTitle text={impactsBudgetItems} />
			<Container fluid >
				<StepIndicator steps={config.appSettings.steps.balanceTransfer} current={1} className='mt-2 mb-3' />
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Traspaso de Saldos
					</Card.Header>
					<Card.Body>
						<FormProvider {...hookFormMethods} >
							<Form onSubmit={(e) => {e.preventDefault()}}>
								<BudgetItems isFetchingSubpartialBudgetOfSelectedCredits={isFetchingSubpartialBudgetOfSelectedCredits} showExpedientCheck={showExpedientCheck} passiveRemainingOnly={true} associatedExpedientOnly={true}/>
								<SelectedBudgetItems />
								<Form.Group className='mt-4 d-flex justify-content-around'>
									<Button variant='danger' size='lg' onClick={onClickBackButton} disabled={isFetchingSubpartialBudgetOfSelectedCredits}>
										<FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
										Volver
									</Button>
									<span className={isFetchingSubpartialBudgetOfSelectedCredits ? '' : 'hidden'}>
										<Spinner animation='border' />
									</span>
									<Button variant='success' size='lg' onClick={onClickNextStepButton} disabled={!enableNextStepButton || isFetchingSubpartialBudgetOfSelectedCredits}>
										Siguiente
										<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
									</Button>
								</Form.Group>
							</Form>
						</FormProvider>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default BalanceTrasnferStepTwo;
