import * as ACTION_TYPES from './maintenanceSystemActionTypes';

export const maintenanceSystemReducerInitialState = {
	postMaintenanceSystem: {
		data: undefined,
		response: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const maintenanceSystemReducer = ( state=maintenanceSystemReducerInitialState, action ) => {
	switch( action.type ) {
		// Post maintenanceSystem Actions
		case ACTION_TYPES.POST_MAINTENANCE_SYSTEM_REQUEST: {
			const { data } = action.payload;
			return {
				...state,
				postMaintenanceSystem: {
					...state.postMaintenanceSystem,
					isFetching: true,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.POST_MAINTENANCE_SYSTEM_REQUEST_SUCCESS: {
			const { response } = action.payload;
			return {
				...state,
				postMaintenanceSystem: {
					...state.postMaintenanceSystem,
					isFetching: false,
					hasError: false,
					response
				}
			}
		}
		case ACTION_TYPES.POST_MAINTENANCE_SYSTEM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postMaintenanceSystem: {
					...state.postMaintenanceSystem,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		// END Post maintenanceSystem Actions


		default: {
			return state;
		}
	}
};

export const getMaintenanceSystemReducer = state => state.maintenanceSystemReducer;

// POST MaintenanceSystem
export const postMaintenanceSystem = state => getMaintenanceSystemReducer(state)?.postMaintenanceSystem;
export const postMaintenanceSystemIsFetching = state => postMaintenanceSystem(state)?.isFetching;