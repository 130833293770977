import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal, Container, Button, Card, Spinner, OverlayTrigger, Tooltip, Alert} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { formatterPeso } from 'src/utils/utils';
import { tryGetAdministrativeDocumentSubcodeDetails } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { clearAdministrativeDocumentSubcodeRelatedData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { getAdministrativeDocumentSubcodeData, getAdministrativeDocumentSubcodeIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';

import RCPagination from 'src/components/common/RCPagination';
import { isNotEmptyArray } from 'src/services/validationService';

const ExpedientModalReportDetails = ({show, handleClose, dataModal, filtered}) => {

    const dispatch = useDispatch();

    const metaData = useSelector(state => getAdministrativeDocumentSubcodeData(state));
    const subcodes = useSelector(state => getAdministrativeDocumentSubcodeData(state))?.data;
    const hasSubcodeHistory = isNotEmptyArray(subcodes);
    const isFetching = useSelector(state => getAdministrativeDocumentSubcodeIsFetching(state));

    const defaultFilter = {
        page: 1,
        pageSize: 10,
    };

    // Pagination

    const page = metaData?.page;
    const perPage = metaData?.perPage
	const totalItemsCount = metaData?.totalCount ? metaData?.totalCount : 1;
    const totalItems = metaData?.totalCount ? metaData?.totalCount : '0';

    const fetchList = (params) => {
        dispatch(clearAdministrativeDocumentSubcodeRelatedData());
        const filter = {
            administrative_document_organism_code: parseInt(dataModal?.organizationCode),
            administrative_document_number: parseInt(dataModal?.number),
            administrative_document_year: parseInt(dataModal?.year),
            affectation_status_id: 1,
            period_id: parseInt(filtered?.availableRpByserviceId[1]),
            service_id: parseInt(filtered?.availableRpByserviceId[0]),
            exclude_deprecated: 1,
            without_passive_remaining: 1,
            exclude_zero_amount: true,
            available_for_rp: true,
        };
        const paramsToSend={
            ...params,
            filter
        }
        dispatch(tryGetAdministrativeDocumentSubcodeDetails(paramsToSend))
    };

    const onPageChange = (pNumber) => {

        const params = {
            ...defaultFilter,
            page: pNumber
        };

        fetchList(params);
    };

    useEffect(() => {
        fetchList(defaultFilter);
    }, [dataModal])

    return (
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xxl" className='mt-5'>
                <Modal.Header>
                    <Modal.Title>Detalle de Partidas del Expediente</Modal.Title>
                </Modal.Header>
                <Container fluid className='mt-4'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center align-middle'>Expediente</th>
                                    <th className='text-center align-middle'>Acumulador Preventiva</th>
                                    <th className='text-center align-middle'>Acumulador Definitiva</th>
                                    <th className='text-center align-middle'>Acumulador Obl. de pago</th>
                                    <th className='text-center align-middle'>Acumulador Pago</th>
                                </tr>
                            </thead>
                            <tbody className='text-black-color'>
                                    <tr>
                                        <td className='text-center align-middle'>{dataModal?.completeLabel}</td>
                                        <td className='text-right'>{formatterPeso.format(dataModal?.total?.preventiveTotal)}</td>
                                        <td className='text-right'>{formatterPeso.format(dataModal?.total?.definitiveTotal)}</td>
                                        <td className='text-right'>{formatterPeso.format(dataModal?.total?.paymentObligationTotal)}</td>
                                        <td className='text-right'>{formatterPeso.format(dataModal?.total?.paymentTotal)}</td>
                                    </tr>
                            </tbody>
                        </Table>
                        <Card variant='success' className='my-3'>
                            <Card.Header>
                                <h5 className='mb-0'>
                                    Partidas y Saldos Incluidos dentro del Expediente
                                </h5>
                            </Card.Header>
                        </Card>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center align-middle'>Partida</th>
                                    <th className='text-center align-middle'>Tipo</th>
                                    <th className='text-center align-middle'>Denominación</th>
                                    <th className='text-center align-middle'>Acumulador Preventiva</th>
                                    <th className='text-center align-middle'>Acumulador Definitiva</th>
                                    <th className='text-center align-middle'>Acumulador Obl. de pago</th>
                                    <th className='text-center align-middle'>Acumulador Pago</th>
                                    <th className='text-center align-middle'>Saldo a Convertir</th>
                                    <th className='text-center align-middle'>Estado</th>
                                </tr>
                            </thead>
                            <tbody className='text-black-color'>
                            { (!isFetching && hasSubcodeHistory) ? subcodes?.map((item) =>
                                    <tr key={item.id}>
                                        <td className='text-center align-middle'>{item?.subCodeCompleteNumber}</td>
                                        <td className='text-center align-middle'>{item?.budgetType}</td>
                                        <td className='text-center align-middle'>{item?.subCodeVisibleName}</td>
                                        <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.preventive)}</td>
                                        <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.definitive)}</td>
                                        <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.payment_obligation)}</td>
                                        <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.payment)}</td>
                                        <td className='text-right'>{formatterPeso.format(item?.balance?.totals?.passiveRemaining)}</td>
                                        <td className='text-center align-middle'>
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={
                                                    <Tooltip>
                                                        {item?.availableForConvertToRp ? "Disponible para convertir a Residuos Pasivos" : "No disponible para convertir a Residuos Pasivos" }
                                                    </Tooltip>
                                                }>
                                                <FontAwesomeIcon size="lg" className={`action-icon ${item?.availableForConvertToRp ? "text-success" : "text-danger"}`} icon={item?.availableForConvertToRp ? faCheck : faTimes } />
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )
                                :
                                    <tr>
                                        <td colSpan='7' className='text-center'>
                                            {
                                                isFetching ? 
                                                    <Spinner animation='border' size='sm' />
                                                :
                                                    <Alert variant='info' className='mb-0'>
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                        No hay registros para mostrar
                                                    </Alert>
                                            }
                                        </td>
                                    </tr>
                                    
                            }
                            </tbody>
                        </Table>
                        {hasSubcodeHistory &&
							<RCPagination
				    			activePage={page}
								itemsCountPerPage={perPage}
								totalItemsCount={totalItemsCount}
								pageRangeDisplayed={7}
								thePage={page}
								totalItems={totalItems}
								innerClass="justify-content-center"
								onChange={onPageChange}
							/> 
						}    
                        <div className='d-flex justify-content-end'>
                            <Button variant='danger' size='lg' className='my-3' onClick={()=>handleClose()}>
                                <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
                                Volver
                            </Button>
                        </div>
                </Container>
            </Modal>
        );
    };

export default ExpedientModalReportDetails;