import * as ACTIONS from './conceptRequestActions';
import * as SERVICES from 'src/services/conceptsServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';

// Try list
export const tryListConcepts = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listConceptRequest());

		if (accessToken) {

			const response = await SERVICES.getConcepts(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listConceptSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listConceptFailure(response?.response?.data));
				showError(`Error al obtener los conceptos. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response;
		}

	};
}

// Try list for select
export const tryListConceptsSelect = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listConceptSelectRequest());

		if (accessToken) {
			const response = await SERVICES.getConceptsList(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listConceptSelectSuccess(response?.data));		
			}
			else {
				dispatch(ACTIONS.listConceptSelectFailure(response?.response?.data));
				showError(`Error al obtener los conceptos. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response;
		}

	};
}

export const tryAddNewConcept = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.addConceptRequest());

		if (accessToken) {

			const response = await SERVICES.createConcept(accessToken, params);
			if (response?.status == 201) {
				dispatch(ACTIONS.addConceptRequestSuccess(response?.data));
				showSuccess('Se agrego correctamente el registro');
			}
			else {
				dispatch(ACTIONS.addConceptRequestFailure(response?.data?.error));
				showError(`Error al crear el registro. Detalles: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};

export const tryDeleteConcept = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getConceptDeleteRequest());

		if (accessToken) {

			const response = await SERVICES.deleteConcept(accessToken, params);
			if (response?.status == 204) {
				dispatch(ACTIONS.getConceptDeleteSuccess(response?.data));
				showSuccess('Se elimino correctamente el registro');
			}
			else {
				dispatch(ACTIONS.getConceptDeleteFailure(response?.data?.error));
				showError(`Error al eliminar el registro. Detalles: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};