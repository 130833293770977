import * as ACTION_TYPES from './exerciseActionTypes';

export const exerciseReducerInitialState = {
	listExercises: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	createExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	editExercise: {
		selectedLegalInstrument: undefined,
		exerciseToEdit: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	exerciseDetailsData: undefined,
	servicesByExerciseId: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	serviceManagement: {
		selectedExercise: undefined
	},
	addServiceToExercise: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	deleteServiceFromExercise: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	activeServiceFromExercise: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	closedExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listLegalInstrumentExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	editLegalInstrumentExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	enableExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listExercisesById: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listPeriodsMonthly: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	enableDateMonthlyPeriodCreditExecution: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	getActiveAdmnistrativeServiceFromExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const exerciseReducer = (state = exerciseReducerInitialState, action) => {
	switch (action.type) {

		// List exercise actions handling
		case ACTION_TYPES.LIST_EXERCISES_REQUEST: {
			return {
				...state,
				listExercises: {
					...state.listExercises,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.listExercises.error,
					data: exerciseReducerInitialState.listExercises.data
				}
			};
		}
		case ACTION_TYPES.LIST_EXERCISES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listExercises: {
					...state.listExercises,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.listExercises.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_EXERCISES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listExercises: {
					...state.listExercises,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.listExercises.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_EXERCISES_DATA: {
			return {
				...state,
				listExercises: exerciseReducerInitialState.listExercises
			};
		}
		// END List exercise actions handling



		// Create exercise actions handling
		case ACTION_TYPES.CREATE_EXERCISE_REQUEST: {
			return {
				...state,
				createExercise: {
					...state.createExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.createExercise.error,
					data: exerciseReducerInitialState.createExercise.data
				}
			};
		}
		case ACTION_TYPES.CREATE_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				createExercise: {
					...state.createExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.createExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.CREATE_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				createExercise: {
					...state.createExercise,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.createExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_CREATE_EXERCISE_DATA: {
			return {
				...state,
				createExercise: exerciseReducerInitialState.createExercise
			};
		}
		// END Create exercise actions handling



		// Edit exercise actions handling
		case ACTION_TYPES.SET_SELECTED_LEGAL_INSTRUMENT: {
			const { data } = action.payload;
			return {
				...state,
				editExercise: {
					...state.editExercise,
					selectedLegalInstrument: data
				}
			};
		}
		case ACTION_TYPES.SET_EXERCISE_TO_EDIT_DATA: {
			const { data } = action.payload;
			return {
				...state,
				editExercise: {
					...state.editExercise,
					exerciseToEdit: data
				}
			}; p
		}
		case ACTION_TYPES.EDIT_EXERCISE_REQUEST: {
			return {
				...state,
				editExercise: {
					...state.editExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.editExercise.error
				}
			};
		}
		case ACTION_TYPES.EDIT_EXERCISE_REQUEST_SUCCESS: {
			return {
				...state,
				editExercise: {
					...state.editExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.editExercise.error
				}
			};
		}
		case ACTION_TYPES.EDIT_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				editExercise: {
					...state.editExercise,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_EDIT_EXERCISE_DATA: {
			return {
				...state,
				editExercise: exerciseReducerInitialState.editExercise
			};
		}
		// END Edit exercise actions handling



		// Set exercise details data
		case ACTION_TYPES.SET_EXERCISE_DETAILS_DATA: {
			const { data } = action.payload;
			return {
				...state,
				exerciseDetailsData: data
			};
		}
		// END Set exercise details data



		// Get services by exercise ID
		case ACTION_TYPES.GET_SERVICES_BY_EXERCISE_ID_REQUEST: {
			return {
				...state,
				servicesByExerciseId: {
					...state.servicesByExerciseId,
					isFetching: true,
					hasError: false,
					data: exerciseReducerInitialState.servicesByExerciseId.data,
					error: exerciseReducerInitialState.servicesByExerciseId.error
				}
			};
		}
		case ACTION_TYPES.GET_SERVICES_BY_EXERCISE_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				servicesByExerciseId: {
					...state.servicesByExerciseId,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.servicesByExerciseId.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_SERVICES_BY_EXERCISE_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				servicesByExerciseId: {
					...state.servicesByExerciseId,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.servicesByExerciseId.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SERVICES_BY_EXERCISE_ID_DATA: {
			return {
				...state,
				servicesByExerciseId: exerciseReducerInitialState.servicesByExerciseId
			};
		}
		// END Get services by exercise ID



		// Services management actions handling
		case ACTION_TYPES.SET_SERVICE_MANAGEMENT_SELECTED_EXERCISE: {
			const { data } = action.payload;
			return {
				...state,
				serviceManagement: {
					...state.serviceManagement,
					selectedExercise: data
				}
			};
		}
		// END Services management actions handling



		// Add service to exercise actions handling
		case ACTION_TYPES.ADD_SERVICE_TO_EXERCISE_REQUEST: {
			return {
				...state,
				addServiceToExercise: {
					...state.addServiceToExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.addServiceToExercise.error
				}
			};
		}
		case ACTION_TYPES.ADD_SERVICE_TO_EXERCISE_REQUEST_SUCCESS: {
			return {
				...state,
				addServiceToExercise: {
					...state.addServiceToExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.addServiceToExercise.error
				}
			};
		}
		case ACTION_TYPES.ADD_SERVICE_TO_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				addServiceToExercise: {
					...state.addServiceToExercise,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_ADD_SERVICE_TO_EXERCISE_DATA: {
			return {
				...state,
				addServiceToExercise: exerciseReducerInitialState.addServiceToExercise
			};
		}
		// END Add service to exercise actions handling



		// Delete service from exercise actions handling
		case ACTION_TYPES.DELETE_SERVICE_FROM_EXERCISE_REQUEST: {
			return {
				...state,
				deleteServiceFromExercise: {
					...state.deleteServiceFromExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.deleteServiceFromExercise.error
				}
			};
		}
		case ACTION_TYPES.DELETE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS: {
			return {
				...state,
				deleteServiceFromExercise: {
					...state.deleteServiceFromExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.deleteServiceFromExercise.error
				}
			};
		}
		case ACTION_TYPES.DELETE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				deleteServiceFromExercise: {
					...state.deleteServiceFromExercise,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_DELETE_SERVICE_FROM_EXERCISE_DATA: {
			return {
				...state,
				deleteServiceFromExercise: exerciseReducerInitialState.deleteServiceFromExercise
			};
		}
		// END Delete service from exercise actions handling


		// Active service from exercise actions handling
		case ACTION_TYPES.ACTIVE_SERVICE_FROM_EXERCISE_REQUEST: {
			return {
				...state,
				activeServiceFromExercise: {
					...state.activeServiceFromExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.activeServiceFromExercise.error
				}
			};
		}
		case ACTION_TYPES.ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS: {
			return {
				...state,
				activeServiceFromExercise: {
					...state.activeServiceFromExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.activeServiceFromExercise.error
				}
			};
		}
		case ACTION_TYPES.ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				activeServiceFromExercise: {
					...state.activeServiceFromExercise,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_ACTIVE_SERVICE_FROM_EXERCISE_DATA: {
			return {
				...state,
				activeServiceFromExercise: exerciseReducerInitialState.activeServiceFromExercise
			};
		}
		// END Active service from exercise actions handling


		// Closed exercise actions handling
		case ACTION_TYPES.CLOSED_EXERCISE_REQUEST: {
			return {
				...state,
				closedExercise: {
					...state.closedExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.closedExercise.error,
					data: exerciseReducerInitialState.closedExercise.data
				}
			};
		}
		case ACTION_TYPES.CLOSED_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				closedExercise: {
					...state.closedExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.closedExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.CLOSED_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				closedExercise: {
					...state.closedExercise,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.closedExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_CLOSED_EXERCISE_DATA: {
			return {
				...state,
				closedExercise: exerciseReducerInitialState.closedExercise
			};
		}
		// END Closed exercise actions handling

		// search legal Instrument exercise
		case ACTION_TYPES.LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST: {
			return {
				...state,
				listLegalInstrumentExercise: {
					...state.listLegalInstrumentExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.listLegalInstrumentExercise.error,
					data: exerciseReducerInitialState.listLegalInstrumentExercise.data
				}
			};
		}
		case ACTION_TYPES.LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listLegalInstrumentExercise: {
					...state.listLegalInstrumentExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.listLegalInstrumentExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listLegalInstrumentExercise: {
					...state.listLegalInstrumentExercise,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.listLegalInstrumentExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LEGAL_INSTRUMENT_EXERCISE_DATA: {
			return {
				...state,
				listLegalInstrumentExercise: exerciseReducerInitialState.listLegalInstrumentExercise
			};
		}

		// END Closed exercise actions handling


		// Edit Legal Instrument Exercise types
		case ACTION_TYPES.EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST: {
			return {
				...state,
				editLegalInstrumentExercise: {
					...state.editLegalInstrumentExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.editLegalInstrumentExercise.error,
					data: exerciseReducerInitialState.editLegalInstrumentExercise.data
				}
			};
		}
		case ACTION_TYPES.EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				editLegalInstrumentExercise: {
					...state.editLegalInstrumentExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.editLegalInstrumentExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				editLegalInstrumentExercise: {
					...state.editLegalInstrumentExercise,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.editLegalInstrumentExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_EDIT_LEGAL_INSTRUMENT_EXERCISE_DATA: {
			return {
				...state,
				editLegalInstrumentExercise: exerciseReducerInitialState.editLegalInstrumentExercise
			};
		}

		// END Edit Legal Instrument Exercise types actions handling

		// Enable exercise actions handling
		case ACTION_TYPES.ENABLE_EXERCISE_REQUEST: {
			return {
				...state,
				enableExercise: {
					...state.enableExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.enableExercise.error,
					data: exerciseReducerInitialState.enableExercise.data
				}
			};
		}
		case ACTION_TYPES.ENABLE_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				enableExercise: {
					...state.enableExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.enableExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.ENABLE_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				enableExercise: {
					...state.enableExercise,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.enableExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_ENABLE_EXERCISE_DATA: {
			return {
				...state,
				enableExercise: exerciseReducerInitialState.enableExercise
			};
		}
		// END Enable exercise actions handling

		// List exercise by Id actions handling
		case ACTION_TYPES.LIST_EXERCISES_BY_ID_REQUEST: {
			return {
				...state,
				listExercisesById: {
					...state.listExercisesById,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.listExercisesById.error,
					data: exerciseReducerInitialState.listExercisesById.data
				}
			};
		}
		case ACTION_TYPES.LIST_EXERCISES_BY_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listExercisesById: {
					...state.listExercisesById,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.listExercisesById.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_EXERCISES_BY_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listExercisesById: {
					...state.listExercisesById,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.listExercisesById.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_EXERCISES_BY_ID_DATA: {
			return {
				...state,
				listExercisesById: exerciseReducerInitialState.listExercisesById
			};
		}
		// END List exercise by Id actions handling

		// List Periods Monthly actions handling
		case ACTION_TYPES.LIST_PERIODS_MONTHLY_REQUEST: {
			return {
				...state,
				listPeriodsMonthly: {
					...state.listPeriodsMonthly,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.listPeriodsMonthly.error,
					data: exerciseReducerInitialState.listPeriodsMonthly.data
				}
			};
		}
		case ACTION_TYPES.LIST_PERIODS_MONTHLY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listPeriodsMonthly: {
					...state.listPeriodsMonthly,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.listPeriodsMonthly.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_PERIODS_MONTHLY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listPeriodsMonthly: {
					...state.listPeriodsMonthly,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.listPeriodsMonthly.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_PERIODS_MONTHLY_DATA: {
			return {
				...state,
				listPeriodsMonthly: exerciseReducerInitialState.listPeriodsMonthly
			};
		}
		// END List Periods Monthly actions handling


		// Report enabled date monthly period - Report Credit Execution 
		case ACTION_TYPES.REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				enableDateMonthlyPeriodCreditExecution: {
					...state.enableDateMonthlyPeriodCreditExecution,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.enableDateMonthlyPeriodCreditExecution.error,
					data: exerciseReducerInitialState.enableDateMonthlyPeriodCreditExecution.data
				}
			};
		}
		case ACTION_TYPES.REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				enableDateMonthlyPeriodCreditExecution: {
					...state.enableDateMonthlyPeriodCreditExecution,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.enableDateMonthlyPeriodCreditExecution.error,
					data
				}
			};
		}
		case ACTION_TYPES.REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				enableDateMonthlyPeriodCreditExecution: {
					...state.enableDateMonthlyPeriodCreditExecution,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.enableDateMonthlyPeriodCreditExecution.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION: {
			return {
				...state,
				enableDateMonthlyPeriodCreditExecution: exerciseReducerInitialState.enableDateMonthlyPeriodCreditExecution
			};
		}
		// END Report enabled date monthly period - Report Credit Execution 

		// Get Active administrative service from exercise actions handling
		case ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST: {
			return {
				...state,
				getActiveAdmnistrativeServiceFromExercise: {
					...state.getActiveAdmnistrativeServiceFromExercise,
					isFetching: true,
					hasError: false,
					error: exerciseReducerInitialState.getActiveAdmnistrativeServiceFromExercise.error,
					data: exerciseReducerInitialState.getActiveAdmnistrativeServiceFromExercise.data
				}
			};
		}
		case ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getActiveAdmnistrativeServiceFromExercise: {
					...state.getActiveAdmnistrativeServiceFromExercise,
					isFetching: false,
					hasError: false,
					error: exerciseReducerInitialState.getActiveAdmnistrativeServiceFromExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getActiveAdmnistrativeServiceFromExercise: {
					...state.getActiveAdmnistrativeServiceFromExercise,
					isFetching: false,
					hasError: true,
					data: exerciseReducerInitialState.getActiveAdmnistrativeServiceFromExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_DATA: {
			return {
				...state,
				getActiveAdmnistrativeServiceFromExercise: exerciseReducerInitialState.getActiveAdmnistrativeServiceFromExercise
			};
		}
		// END Get Active administrative service from exercise actions handling





		default: {
			return state;
		}
	}
};

export const getExerciseReducer = state => state.exerciseReducer;

// Get exercise list data
export const getExercisesList = state => getExerciseReducer(state)?.listExercises;
export const getExercisesListData = state => getExercisesList(state)?.data;
export const getExercisesListIsFetching = state => getExercisesList(state)?.isFetching;

// Get exercise create data
export const getExerciseCreate = state => getExerciseReducer(state)?.createExercise;
export const getExerciseCreateData = state => getExerciseCreate(state)?.data;
export const getExerciseCreateIsFetching = state => getExerciseCreate(state)?.isFetching;

// Get exercise edition data
export const getExerciseEdit = state => getExerciseReducer(state)?.editExercise;
export const getExerciseEditSelectedLegalInstrument = state => getExerciseEdit(state)?.selectedLegalInstrument;
export const getExerciseEditIsFetching = state => getExerciseEdit(state)?.isFetching;
export const getExerciseToEdit = state => getExerciseEdit(state)?.exerciseToEdit;

// Get exercise details data
export const getExerciseDetailsData = state => getExerciseReducer(state)?.exerciseDetailsData;

// Get services by exercise ID
export const getServicesByExerciseId = state => getExerciseReducer(state)?.servicesByExerciseId;
export const getServicesByExerciseIdData = state => getServicesByExerciseId(state)?.data;
export const getServicesByExerciseIdIsFetching = state => getServicesByExerciseId(state)?.isFetching;

// Get services management
export const getServicesManagement = state => getExerciseReducer(state)?.serviceManagement;
export const getServicesManagementSelectedExercise = state => getServicesManagement(state)?.selectedExercise;

// Get add service to exercise data
export const getAddServiceToExercise = state => getExerciseReducer(state)?.addServiceToExercise;
export const getAddServiceToExerciseIsFetching = state => getAddServiceToExercise(state)?.isFetching;

// Get delete service from exercise data
export const getDeleteServiceFromExercise = state => getExerciseReducer(state)?.deleteServiceFromExercise;
export const getDeleteServiceFromExerciseIsFetching = state => getDeleteServiceFromExercise(state)?.isFetching;

// Get active service from exercise data
export const getActiveServiceFromExercise = state => getExerciseReducer(state)?.activeServiceFromExercise;
export const getActiveServiceFromExerciseIsFetching = state => getActiveServiceFromExercise(state)?.isFetching;

// Get exercise closed 
export const getExerciseClosed = state => getExerciseReducer(state)?.closedExercise;
export const getExerciseClosedData = state => getExerciseClosed(state)?.data;
export const getExerciseClosedIsFetching = state => getExerciseClosed(state)?.isFetching;

//Search Legal Instrument Exercise
export const getLegalInstrumentExercise = state => getExerciseReducer(state)?.listLegalInstrumentExercise;
export const getLegalInstrumentExerciseData= state => getLegalInstrumentExercise(state)?.data;
export const getLegalInstrumentExerciseIsFetching = state => getLegalInstrumentExercise(state)?.isFetching;

//Edit Legal Instrument Exercise types
export const getEditLegalInstrumentExercise = state => getExerciseReducer(state)?.editLegalInstrumentExercise;
export const getEditLegalInstrumentExerciseData= state => getEditLegalInstrumentExercise(state)?.data;
export const getEditLegalInstrumentExerciseIsFetching = state => getEditLegalInstrumentExercise(state)?.isFetching;

// Get exercise enable 
export const getExerciseEnable = state => getExerciseReducer(state)?.enableExercise;
export const getExerciseEnableData = state => getExerciseEnable(state)?.data;
export const getExerciseEnableIsFetching = state => getExerciseEnable(state)?.isFetching;

// Get exercise list by ID
export const getExercisesListById = state => getExerciseReducer(state)?.listExercisesById;
export const getExercisesListByIdData = state => getExercisesListById(state)?.data;
export const getExercisesListByIdIsFetching = state => getExercisesListById(state)?.isFetching;

// Get List Periods Monthly
export const getlistPeriodsMonthly = state => getExerciseReducer(state)?.listPeriodsMonthly;
export const getlistPeriodsMonthlyData = state => getlistPeriodsMonthly(state)?.data;
export const getlistPeriodsMonthlyIsFetching = state => getlistPeriodsMonthly(state)?.isFetching;

// Get Report enabled date monthly period - Report Credit Execution 
export const getEnableDateMonthlyPeriodCreditExecution = state => getExerciseReducer(state)?.enableDateMonthlyPeriodCreditExecution;
export const getEnableDateMonthlyPeriodCreditExecutionData = state => getEnableDateMonthlyPeriodCreditExecution(state)?.data;
export const getEnableDateMonthlyPeriodCreditExecutionIsFetching = state => getEnableDateMonthlyPeriodCreditExecution(state)?.isFetching;

// Get active service from exercise data
export const getActiveAdministrativeServiceFromExercise = state => getExerciseReducer(state)?.getActiveAdmnistrativeServiceFromExercise;
export const getActiveAdministrativeServiceFromExerciseData = state => getActiveAdministrativeServiceFromExercise(state)?.data;
export const getActiveAdministrativeServiceFromExerciseIsFetching = state => getActiveAdministrativeServiceFromExercise(state)?.isFetching;