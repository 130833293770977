import * as ACTIONS from './wageTemplateActions';
import * as SERVICES from 'src/services/wagesTemplateServices';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';

// GET plantilla de haberes
export const tryGetWagesTemplateDetails = (params) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );
		dispatch( ACTIONS.getWageTemplateListRequest() );

		if(accessToken){
			const response = await SERVICES.getWagesTemplates( accessToken, params );
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getWagesTemplateListRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getWagesTemplateListRequestFailure(response?.error||response) );
				showError(`Error al cargar los detalles de las plantillas de haberes. Detalle: ${response?.error||response}` );
			}
		}
	};
};

// POST plantilla de haberes
export const tryPostWagesTemplate = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.postWagesTemplateRequest());

		if(accessToken){
			const response = await SERVICES.newWagesTemplates(accessToken, params);
			if (response?.status == 201) {
				dispatch(ACTIONS.postWagesTemplateRequestSuccess(response?.data));
				showSuccess('La plantilla de haberes se registró correctamente.');
			}
			else {
				dispatch(ACTIONS.postWageTemplateRequestFailure(response?.data?.error));
				showError(`Error al crear la plantilla de haberes. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};

// PUT plantilla de haberes
export const tryPutWagesTemplate = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.putWagesTemplateRequest());

		if(accessToken){
			const response = await SERVICES.editWagesTemplates(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.putWagesTemplateRequestSuccess(response?.data));
				showSuccess('La plantilla de haberes se registró correctamente.');
			}
			else {
				dispatch(ACTIONS.putWageTemplateRequestFailure(response?.data?.error));
				showError(`Error al crear la plantilla de haberes. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};

// DELETE plantilla de haberes
export const tryDeleteWagesTemplate = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.deleteWagesTemplateRequest());
		
		if (accessToken) {
			const response = await SERVICES.deleteWagesTemplates(accessToken, params);
			if (response?.status == 204) {
				dispatch(ACTIONS.deleteWagesTemplateRequestSuccess(response?.data));
				showSuccess('Plantilla de haberes eliminada correctamente.');
			}
			else {
				dispatch(ACTIONS.deleteWagesTemplateRequestFailure(response?.data?.error));
				showError(`Error al eliminar la plantilla de haberes. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};

//List Recruitment methods
export const tryGetRecruitmentMethod = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getRecruitmentMethod());
		if (accessToken) {

			const response = await SERVICES.getRecruitmentMethods(accessToken);
			if (response?.status === 200) {
				dispatch(ACTIONS.getRecruitmentMethodSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getRecruitmentMethodFailure(response?.error || response));
				showError(`Error al cargar los tipos de Pedido de Fondos. Detalle: ${response?.error || response}`);
			}
		}

	};
};