import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import { push } from 'connected-react-router';
import { Container, Card, Form, Spinner, Col, Row, Button, FormGroup, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import OPFormFactory from 'src/components/forms/paymentOrder/OPFormFactory';
import PageTitle from 'src/components/general/PageTitle';
import { getListServicesByUserAndExerciseData } from "src/redux/administrativeService/administrativeServiceReducer";
import { tryListServicesByUserAndExercise } from "src/redux/administrativeService/administrativeServiceActionCreator";
import { clearDTOFromEmptyData, getOrderPayTypeValueToShow, orderFormsReadyToDisplay } from 'src/utils/utils';
import { tryPostOrderPay, tryListPayOrderTypes } from 'src/redux/orderPay/orderPayActionCreator';
import { setSelectedTypeOrderPay, clearSelectedTypeOrderPay } from 'src/redux/orderPay/orderPayActions'
import { getOrderPayCreateIsFetching, getOrderType, getPayOrderTypeListData, getOrderPayListIsFetching } from 'src/redux/orderPay/orderPayReducer';
import { ORDER_PAY_LIST } from 'src/utils/constants';
import { isNotEmptyArray } from 'src/services/validationService';
import { clearOneListAdministrativeDocumentData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const OrderPayNewPage = () => {
    const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;
    const [formValuesState, setFormValuesState] = useState({ legalInstruments: [], legalInstrument: {} });
    const [admServiceId, setAdmServiceId] = useState('');
    const [admServiceCode, setAdmServiceCode] = useState('');
    const [expedientesSeleccionados, setExpedientesSeleccionados] = useState([]);
    const [settlementOfAssets, setSettlementOfAssets] = useState({});
    const [proveedorSelected, setProveedorSelected] = useState('');
    const [importe, setImporte] = useState(0);
    const [existingPOrderNumber, setExistingPOrderNumber] = useState(false);
    const [admCode, setAdmCode] = useState('');
    const [admNumber, setAdmNumber] = useState('');
    const [admYear, setAdmYear] = useState('');
    const isFetching = useSelector(state => getOrderPayCreateIsFetching(state));
    let orderType = useSelector(state => getOrderType(state));
    const payOrderTypeList = useSelector(state => getPayOrderTypeListData(state)) || [];
    const orderPayListIsFetching = useSelector(state => getOrderPayListIsFetching(state));

    const [allowSaveAmountValidation, setAllowSaveAmountValidation] = useState('');
    const allowSaveByOrderType = orderType === 'ORDEN_DE_PAGO_DE_PROVEEDORES';
    const hasReceipts = isNotEmptyArray(formValuesState?.beneficiaries?.[0]?.receipts);
    const allowSaveButton = isFetching || (allowSaveByOrderType && (isNotEmptyArray(expedientesSeleccionados) && hasReceipts ? (!hasReceipts || !allowSaveAmountValidation) : false));
    const validationSettlementOfAssets = ((orderType === 'ORDEN_DE_PAGO_DE_HABERES') && !allowSaveAmountValidation) ? false : true;
    const isFetchingAdministrativeDocument = useSelector(state => getAdministrativeDocumentIsFetching(state));
    const email = useSelector(state => getEmail(state));
    const listUserData = useSelector(state => getUserListData(state));
    const userFound = listUserData?.records?.find(item => item.email == email);
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

    const amountsShowValidation = (totalReceiptAmount, importe) => {
        if (totalReceiptAmount) {
            const resultCondition = totalReceiptAmount?.toFixed(2) === importe?.toFixed(2)
            setAllowSaveAmountValidation(resultCondition);
            return resultCondition;
        }
        return false;
    };

    const administrativeServiceData = useSelector(state => getListServicesByUserAndExerciseData(state))
        ?.sort((a, b) => a.code > b.code ? 1 : -1)
        ?.map(item => ({
            displayName: `${item?.code} - ${item?.name} `,
            id: item?.id,
            name: item?.name,
            code: item?.code
        }));

    const validateOrderPay = () => {
        const receiptType = formValuesState?.beneficiaries?.[0]?.receipts?.[0]?.type;
        const validate = orderType == "ORDEN_DE_PAGO_DE_PROVEEDORES" && receiptType == "CERTIFICADO_DE_OBRAS_PUBLICAS";
    
        if (validate){
            const isValid = isNotEmptyArray(expedientesSeleccionados?.filter(item => item?.mappedCompleteNumber?.partidaPrincipal == "052"));
            if (!isValid) showWarning(`No se puede generar la orden de pago. Existe un certificado de obra sin una Partida Principal 052 seleccionada.` );
            return isValid;
        }else{
            return true;
        }
    };

    const onSubmitForm = (data) => {

        if (data?.codigo?.length !== 4) return false;

        let params;

        let wageManager;

        if (orderType == 'ORDEN_DE_PAGO_DE_HABERES') {
            wageManager = {
                name: settlementOfAssets?.name,
                wageLiquidationType: settlementOfAssets?.wageLiquidationType,
                netAmount: settlementOfAssets?.netAmount,
                wageManagerDetails: settlementOfAssets?.wageManagerDetails?.map(item => {
                    return {
                        concept: item?.concept,
                        subConcept: item?.subConcept,
                        item: item?.item,
                        code: item?.code,
                        periodAmount: item?.periodAmount,
                        changeAmount: item?.changeAmount
                    };
                })
            };
        };

        if (orderType == 'ORDEN_DE_PAGO_DEPOSITO_INDEBIDO') {
            params = [{
                "amount": data.importe,
            }]
        } else if (orderType == 'ORDEN_DE_PAGO_DE_HABERES') {
            params = expedientesSeleccionados?.map((item) => ({
                'presupuestoResponse': {
                    "amount": item.amount,
                    "subCodeId": item.subCodeId || item.id,
                    "subPartialBudget": {
                        "id": item?.subPartialBudgetSelected?.id
                    }
                }
            }))
        } else {
            params = expedientesSeleccionados?.map((item) => ({
                'presupuestoResponse': {
                    "amount": item.amount,
                    "subCodeId": item.subCodeId || item.id
                }
            }))
        };

        const details = params;

        const administrativeDocument = {
            year: data.anio,
            number: data.correlativo,
            codeOrganism: data.codigo,
            title: data.title || "Orden de pago"
        }

        const legalInstrument = [...formValuesState.legalInstruments].shift();
        const legalInstruments = [...formValuesState.legalInstruments];
        legalInstruments.shift();

        
        let newFormValues = {};

        if(orderType == "ORDEN_DE_PAGO_DE_PROVEEDORES"){
            const type = formValuesState?.beneficiaries?.[0]?.type;
            newFormValues = {
                legalInstrument,
                legalInstruments,
                beneficiaries: formValuesState?.beneficiaries?.[0]?.receipts?.map(receiptObj => {
                    let beneficiarieFormat = {
                        type: type,
                        cuit: receiptObj?.cuit,
                        persona: {companyName: receiptObj?.companyName},
                        receipts: [
                            {...receiptObj}
                        ]
                    };
                    delete beneficiarieFormat?.receipts[0]?.cuit;
                    delete beneficiarieFormat?.receipts[0]?.companyName;
                    delete beneficiarieFormat?.receipts[0]?.beneficiaryValidator;
                    delete beneficiarieFormat?.receipts[0]?.validatedCuit;
                    return beneficiarieFormat;
                })
            }
        }else {
            newFormValues = {
                legalInstrument,
                legalInstruments,
                beneficiaries: formValuesState.beneficiaries
            }
        };
        
        const clearInstruments = clearDTOFromEmptyData(newFormValues);

        const paymentOrderDto = {
            administrativeServiceId: admServiceId,
            administrativeDocument,
            datePaymentOrder: data.date,
            description: data.description,
            observations: data?.observations?.trim(),
            generalDescription: data?.generalDescription?.trim(),
            details,
            year: data.fiscalYearId,
            number: data.number,
            type: orderType,
            wageManager,
            ...clearInstruments
        };

        if (validateOrderPay()){
            dispatch(tryPostOrderPay(paymentOrderDto)).then(response => {
                if (!(response?.data?.hasError)) {
                    dispatch(push(ORDER_PAY_LIST));
                }
            });
        }
    }

    const onSelectOrderType = v => {
        const params = v.target.value;
        setFormValuesState({ legalInstruments: [], legalInstrument: {} })
        dispatch(setSelectedTypeOrderPay(params));
        setExistingPOrderNumber(false);
    };

    Object.assign(hookFormMethods, {
        admServiceId, setAdmServiceId, administrativeServiceData,
        expedientesSeleccionados, setExpedientesSeleccionados,
        settlementOfAssets, setSettlementOfAssets,
        setProveedorSelected, importe, setImporte,
        formValuesState, setFormValuesState, amountsShowValidation, setAllowSaveAmountValidation,
        orderType, existingPOrderNumber, setExistingPOrderNumber, admServiceCode, setAdmServiceCode,
        admCode, admNumber, admYear, setAdmCode, setAdmNumber, setAdmYear
    });

    useEffect(() => {
        dispatch(clearSelectedTypeOrderPay());
        dispatch(tryListPayOrderTypes());
        dispatch(clearOneListAdministrativeDocumentData());
        dispatch(tryListServicesByUserAndExercise(userFound?.id, globalSelectedPeriod?.id));
    }, []);

    return <>
        <Container fluid>
            <Card className='mb-5'>
                <Card.Header className='d-flex justify-content-between'>
                    <h1 className="h6 mt-1 mb-0">Nueva Orden de Pago</h1>
                    <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#ejecuci%C3%B3n-de-presupuesto---orden-de-pago'>
                        <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                        <small>Ver manual de uso</small> 
                    </a>
                </Card.Header>
                <Card.Body >
                    <span className='text-danger d-flex mandatory-label font-weight-bold font-italic'>
                        <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                        Obligatorio
                    </span>

                    {/* <useFormContext {...hookFormMethods} > */}

                    <FormProvider {...hookFormMethods}>
                        <Form className='mt-4 text-black-color' onSubmit={handleSubmit(onSubmitForm)}>

                            <FormGroup as={Row}>
                                <FormLabel className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                                    Tipo de Orden de Pago
                                </FormLabel>
                                <Col sm={4}>
                                    <Form.Control
                                        as="select"
                                        name="payOrderType"
                                        onChange={onSelectOrderType}
                                        className={"text-black-color"}
                                        disabled={orderPayListIsFetching}
                                        required>
                                        <option value={''}>Seleccione una opcion...</option>
                                        {
                                            orderFormsReadyToDisplay(payOrderTypeList)?.map((item, index) => (
                                                <option className='text-black-color' value={item} key={index}>
                                                    {getOrderPayTypeValueToShow(item)}
                                                </option>
                                            ))
                                        }
                                    </Form.Control>
                                </Col>
                            </FormGroup>

                            <OPFormFactory formName={orderType} />

                            <Col md={4} className='offset-md-4 d-flex justify-content-around my-5'>
                                <Button type='button' variant='primary' size='lg' onClick={() => dispatch(push(ORDER_PAY_LIST))}>
                                    Cancelar
                                </Button>
                                <span className={isFetching ? '' : 'hidden'}>
                                    <Spinner animation='border' />
                                </span>
                                <Button
                                    type='submit'
                                    variant='success'
                                    size='lg'
                                    disabled={allowSaveButton || isFetchingAdministrativeDocument || !validationSettlementOfAssets || existingPOrderNumber || orderPayListIsFetching || !isNotEmptyArray(expedientesSeleccionados)}
                                    >
                                    Guardar
                                    <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
                                </Button>
                            </Col>
                        </Form>
                    {/* </useFormContext> */}
                    </FormProvider>
                </Card.Body>
            </Card>
        </Container>
    </>

};

export default OrderPayNewPage;