import * as ACTION_TYPES from './characterActionTypes';

export const getCharacterListRequest = () => ({
	type: ACTION_TYPES.LIST_CHARACTER_REQUEST
});

export const getCharacterListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_CHARACTER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getCharacterListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_CHARACTER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCharacterList = () => ({
	type: ACTION_TYPES.CLEAR_CHARACTER_LIST
});