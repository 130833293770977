import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { tryAddNewBankAccount } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestActionCreator';
import { getBankAccountAddNewIsFetching } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer';
import BankAccountFormAM from 'src/components/pages/BankAccounts/BankAccountFormAM';

const CloneBankAccountModal = (props) => {
    const dispatch = useDispatch();
    const {show, handleClose, dataModal: bankAccountToEdit } = props;
    const isFetchingCreateBankAccount = useSelector(state => getBankAccountAddNewIsFetching(state));

    const onFormSubmit = data => {
        dispatch(tryAddNewBankAccount(data)).then(() => handleClose());
    };

    return <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
        
        <Modal.Header closeButton>
            <Modal.Title>Nueva Cuenta Bancaria Clonada</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <BankAccountFormAM
                bankAccountToEdit={bankAccountToEdit}
                onFormSubmit={onFormSubmit}
                isFetching={isFetchingCreateBankAccount}
            />
        </Modal.Body>

    </Modal>
}

export default CloneBankAccountModal;