import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Container, Button, Table, Card } from 'react-bootstrap';
import { formatterPeso } from 'src/utils/utils';
import { accept, cancel } from 'src/utils/label';

import { tryDeletePreload } from 'src/redux/preload/preloadActionCreator';
import { getPreloadDeleteIsFetching } from 'src/redux/preload/preloadReducer';
import { tryGetPreloadData } from 'src/redux/preload/preloadActionCreator';

import AppLoading from 'src/components/common/AppLoading';

const RemovePreloadModal = (props) => {

    const { show, handleClose, dataModal, filterObject, defaultParams, affectationsSelect, setAffectationsSelect } = props;
    
    const dispatch = useDispatch();

    const preloadDeleteisFetching = useSelector(state => getPreloadDeleteIsFetching(state));

    const { subCodeData, expedientData } = dataModal;

    const updateAffectationsSelect = () => {

        const preloadId = subCodeData?.id;
        const expedient = affectationsSelect?.expedient;

        if(affectationsSelect?.subCodePreload?.some(obj => obj?.id === preloadId)){
            setAffectationsSelect({
                expedient,
                subCodePreload: affectationsSelect?.subCodePreload?.filter(obj => obj.id !== preloadId)
            });
        }

    };
    
    let isEmpty = filterObject && Object.entries(filterObject).length === 0;
    
    const updatePreloadListData = (params) => {
        dispatch(tryGetPreloadData(params)).then(
            () => {
                updateAffectationsSelect();
            }
        );
    };

    // Delete
	const onClickDelete = id => {
        
        dispatch(tryDeletePreload(id)).then(
            response => {
                if (response?.status == 200) {
                    if(filterObject == undefined || isEmpty){
                        updatePreloadListData(defaultParams);
                    } else {
                        updatePreloadListData({
                        ...defaultParams,
                        filter: { ...defaultParams.filter, ...filterObject }
                    });
                    };
                    handleClose();
                }
            }
        );
		
	};

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" className='mt-2 mb-4'>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Importe de Pre-Carga</Modal.Title>
            </Modal.Header>
            <Container fluid className='m-1 px-5'>
                <Card className='mb-3'>
                    <Card.Body>
                        <p className='text-black-color mb-3 text-center' >
                             ¿Está seguro que desea eliminar la Partida <b>{subCodeData?.subCode?.completeNumber}</b> del expediente <b>{expedientData?.completeLabel}</b> ?
                        </p>
                        <Card className='mt-4'>
                            <Card.Header className='h6'>
                                <h5 className='mb-0'>
                                    Expediente:
                                </h5>
                            </Card.Header>
                        </Card>
                        <Table striped bordered hover>
                            <tbody className='text-black-color'>
                                <tr>
                                    <td>{expedientData?.completeLabel}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Container fluid className='mt-3 px-0'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className='text-center align-middle'>Partida</th>
                                        <th className='text-center align-middle'>Importe</th>
                                    </tr>
                                </thead>
                                <tbody className='text-black-color'>
                                    <tr>
                                        <td className='text-center align-middle'>{subCodeData?.subCode?.completeNumber}</td>
                                        <td className='text-right'>{formatterPeso.format(subCodeData?.amount)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Container>

                        <div className='d-flex justify-content-around mt-4 mb-3'>
                            <Button variant='danger' size='lg' onClick={handleClose}>
                                {cancel}
                            </Button>


                            <Button type='submit' variant='success' size='lg' onClick={() => onClickDelete(subCodeData?.id)} >
                                Confirmar
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
            <AppLoading isLoading={preloadDeleteisFetching} />
        </Modal>
    )
};

export default RemovePreloadModal;