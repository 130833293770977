import { push } from 'connected-react-router';

import * as ACTIONS from './creditActions';
import * as SERVICES from 'src/services/creditServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

export const tryGetCreditList = ( page=1, filter={}, pageSize=10 ) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getCreditListRequest() );

		if(accessToken){

		let filterObject = {
			period_id: getGlobalDataSelectedPeriod( state )?.id,
			...filter
		};

			const response = await SERVICES.listCredits( accessToken, page, pageSize, filterObject );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getCreditListRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
	
				dispatch( ACTIONS.getCreditListRequestFailure(response?.error||response) );
				showError(`Error al cargar las partidas presupuestarias. Detalle: ${response?.message || response}` );
			}
		}

	};
};

export const tryGetCodesFromCredit = creditId => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getCodesFromCreditRequest() );

		if(accessToken){

			const response = await SERVICES.getCodesFromCreditId( accessToken, creditId );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getCodesFromCreditRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
	
				dispatch( ACTIONS.getCodesFromCreditRequestFailure(response?.error||response) );
				showError(`Error al cargar los códigos. Detalle: ${response?.error||response}` );
			}
		}

	};
};

export const tryPostCredit = (creditData, isEditionMode) => {
	return async( dispatch, getState ) => {

		const successMsg = isEditionMode ? 'La partida presupuestaría se editó correctamente.' : 'La partida presupuestaría se creó correctamente.';
		const errorMsg = isEditionMode ? 'Error al editar la partida presupuestaría.' : 'Error al crear la partida presupuestaría.';

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.postCreditRequest(creditData) );

		if(accessToken){

			const response = await SERVICES.postCredit( accessToken, creditData );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.postCreditRequestSuccess(response?.data) );
				showSuccess(successMsg );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.postCreditRequestFailure(response?.error||response) );
				showError(`${errorMsg} Detalle: ${response?.response?.data?.message||response}` );
			}
		}

	};
};



export const tryGetTotalAmounts = (filter={}, cancelToken) => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		if(accessToken){

			let filterToSend = {
				period_id: getGlobalDataSelectedPeriod( state )?.id,
				...filter
			};
			
			dispatch( ACTIONS.getTotalAmountsRequest() )
			const response = await SERVICES.getTotalAmounts( accessToken, filterToSend, cancelToken );
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getTotalAmountsRequestSuccess(response?.data) )
			}
			else if(response?.status == 204){//Cancel Request
				dispatch( ACTIONS.getTotalAmountsRequestFailure() );
			}
			else {
				dispatch( ACTIONS.getTotalAmountsRequestFailure(response?.error||response) );
				showError(`Ocurrió un error al obtener el monto total. Detalle: ${response?.response?.data.message||response}` );
			}
		}

	};
};