import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';

import { isNotEmptyArray } from 'src/services/validationService';
import { USER_LIST } from 'src/utils/constants';
import { lastNameName, email, group } from 'src/utils/label';
import { buildFullName } from 'src/utils/utils';

import { tryGetUserDetail, tryGetListUserXApplicationXProfile, tryGetListUserProfile } from 'src/redux/user/userActionCreator';
import { getUserXApplicationXProfileByUserId, getUserToDetail, getUserProfileListData } from 'src/redux/user/userReducer';

import {
	getListAllServiceAdministrativeByUserData,
	getAdministrativeServiceListIsFetching,
} from "src/redux/administrativeService/administrativeServiceReducer";

import {
	tryListAllServiceAdministrativeByUser
} from "src/redux/administrativeService/administrativeServiceActionCreator";
import { clearListAllServiceAdministrativeByUser } from "src/redux/administrativeService/administrativeServiceActions";


const UserDetailsPage = props => {

	const dispatch = useDispatch();

	// User to edit data
	const userData = useSelector(state => getUserToDetail(state));
	const userProfileList = useSelector(state => getUserXApplicationXProfileByUserId(state, userData?.id));
	const listProfileData = useSelector(state => getUserProfileListData(state, userProfileList?.profileId));
	const userProfile = listProfileData?.find(item => item.id == userProfileList?.profileId)

	// Back button
	const onClickBackButton = () => {
		dispatch(push(USER_LIST));
	};

	useEffect(() => {
		dispatch(tryGetUserDetail(userData.id));
		dispatch(tryGetListUserXApplicationXProfile());
		dispatch(tryGetListUserProfile());
		dispatch(clearListAllServiceAdministrativeByUser());
		dispatch(tryListAllServiceAdministrativeByUser(userData?.id));
	}, []);

	// Services
	const servicesList = useSelector(
		(state) => getListAllServiceAdministrativeByUserData(state)
	)?.sort( (a,b) => a.code>b.code ? 1 : -1 );

	const servicesListIsFetching = useSelector((state) =>
		getAdministrativeServiceListIsFetching(state)
	);
	const hasServicesList = isNotEmptyArray(servicesList);

	useEffect(() => {
		dispatch(tryListAllServiceAdministrativeByUser(userData?.id));
	}, []);



	return <Container fluid className='mb-5'>
		<Card>
			<PageTitle text='Usuarios' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Detalle del usuario
					</Card.Header>
					<>
						<Table className='text-black-color' striped borderless hover>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold'>
										{lastNameName}:
									</td>
									<td className='text-center'>
										{buildFullName(userData?.name)}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										{email}:
									</td>
									<td className='text-center'>
										{userData?.email}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										{group}:
									</td>
									<td className='text-center'>
										{userProfile?.name}
									</td>
								</tr>

							</tbody>
						</Table>


						<Card.Header className='h6'>
							Servicios asociados al usuario
						</Card.Header>
						<Card.Body>
							<Table striped bordered hover size='sm'>
								<thead>
									<tr>
										<th className="text-center align-middle">Código</th>
										<th className="text-center align-middle">Descripción</th>
										<th className="text-center align-middle">Tipo</th>
									</tr>
								</thead>
								<tbody className="text-black-color">
									{hasServicesList ? (
										<>
											{servicesList?.map((service) => (
												<tr key={service?.id}>
													<td className="text-center align-middle">
														{service?.code}
													</td>
													<td className="text-center align-middle">
														{service?.name}
													</td>
													<td className="text-center align-middle">
														{service?.administrativeServiceType?.name}
													</td>
												</tr>
											))}
										</>
									) : (
											<tr>
												<td colSpan="13" className="text-center">
													{servicesListIsFetching ? (
														<Spinner
															animation="border"
															variant="success"
															className="my-3"
														/>
													) : (
															<Alert variant="info" className="mb-0">
																<FontAwesomeIcon
																	icon={faExclamationTriangle}
																	className="text-black-color mr-3"
																/>
                            									No hay registros
															</Alert>
														)}
												</td>
											</tr>
										)}
								</tbody>
							</Table>

							<hr></hr>

							<div className='text-center'>
								<Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
									<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
							</div>

						</Card.Body>
					</>
				</Card>

			</Container>
		</Card>
	</Container>;
};

export default UserDetailsPage;