import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSave } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { getCurrentYear, parseIntOrUndefined } from 'src/utils/utils';
import { tryCreateExercise } from 'src/redux/exercise/exerciseActionCreator';
import { getExerciseCreateIsFetching } from 'src/redux/exercise/exerciseReducer';
import { clearCreateExerciseData } from 'src/redux/exercise/exerciseActions';
import { EXERCISES_LIST } from 'src/utils/constants';

const ExerciseNewPage = props => {

	const dispatch = useDispatch();

	// Year dropdown options
	const currentYear = getCurrentYear()+1;
	const yearsOptions = Array.from(new Array(20), (val, index) => currentYear - index);
	const [selectedYear, setSelectedYear] = useState();
	const onChangeYearDropdown = event => {
		const value = event.target.value;
		setSelectedYear(value);

	};

	// Start date
	let startDateDefault = selectedYear + '-01-01'
	const [startDate, setStartDate] = useState();

	const onChangeStartDate = event => {
		const value = event.target.value;
		setStartDate(startDateDefault);
	};
	
	// End date
	const [endDate, setEndDate] = useState();
	const onChangeEndDate = event => {
		const value = event.target.value;
		setEndDate(value);
	};

	// Complementary date
	const [complementaryDate, setComplementaryDate] = useState();
	const onChangeComplementaryDate = event => {
		const value = event.target.value;
		setComplementaryDate(value);
	};

	// Create exercise
	const exerciseCreateIsFetching = useSelector( state => getExerciseCreateIsFetching(state) );
	const enableCreateButton = (selectedYear && startDateDefault && endDate && complementaryDate);
	const createExercise = () => {
		const dataToSend = {
			complementaryDate,
			endDate,
			startDate: startDateDefault,
			year: parseIntOrUndefined(selectedYear),
			exerciseState: 'FORMULATION'
		}

		return dispatch( tryCreateExercise(dataToSend) )
			
	};
	const onClickCreateExerciseButton = () => {
		createExercise().then( response => {
			if( response?.status == 200 ) {
				redirectToExercisesList();
			}
		});
	};
	const onClickCreateExerciseAndLoadAnotherButton = () => {
		createExercise().then( response => {
			if( response?.status == 200 ) {
				resetForm();
			}
		});
	};

	// Cancel button
	const onClickCancelButton = () => {
		redirectToExercisesList();
	};

	// Reset form
	const resetForm = () => {
		setSelectedYear(-1);
		setStartDate(-1);
		setEndDate(-1);
		setComplementaryDate(-1);
	};

	// Redirect to exercises list page
	const redirectToExercisesList = () => { dispatch(push(EXERCISES_LIST)) };

	useEffect( () => {
		dispatch( clearCreateExerciseData() );
	}, []);

	return <Container className='mb-5'>
		<Card>
			<PageTitle text='Ejercicios' />

			<Container fluid>
				<Card className='mb-3'>

					<Card.Header className='h6'>
						Nuevo ejercicio
					</Card.Header>
					<Card.Body>

						<Row>
							<Col sm='8'></Col>
							<Col sm='4' className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Obligatorio
								</span>
							</Col>
						</Row>

						<Form>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Año
								</Form.Label>
								<Col sm='4'>
									<Form.Control as='select' onChange={onChangeYearDropdown} value={selectedYear} defaultValue={-1} disabled={exerciseCreateIsFetching}>
										<option disabled value={-1} key={-1}>Seleccione año...</option>
										{
											yearsOptions.map((year, index) => {
												return <option key={`year${index}`} value={year}>{year}</option>
											})
										}
									</Form.Control>
								</Col>
							</Form.Group>

							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Fecha inicio
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										type='date'
										name='startDate'
										value={startDateDefault}
										onChange={onChangeStartDate}
										disabled={true}/>
								</Col>
							</Form.Group>

							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Fecha fin
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										type='date'
										name='endDate'
										value={endDate}
										onChange={onChangeEndDate}
										disabled={exerciseCreateIsFetching}/>
								</Col>
							</Form.Group>

							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Fecha complementaria
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										type='date'
										name='complementaryDate'
										value={complementaryDate}
										onChange={onChangeComplementaryDate}
										disabled={exerciseCreateIsFetching}/>
								</Col>
							</Form.Group>

							<div className='d-flex justify-content-around mt-4'>
								<Button type='button' variant='danger' size='lg' onClick={onClickCancelButton}>
									Cancelar
								</Button>

								{
									exerciseCreateIsFetching
									?
										<Spinner animation='border'/>
									:
									null
								}


								<Button type='button' variant='success' size='lg' onClick={onClickCreateExerciseButton} disabled={!enableCreateButton||exerciseCreateIsFetching} >
									Guardar 
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ExerciseNewPage;