import React, { useEffect } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { formatterPeso, getFundRequestTypeValueToShow, mFormat} from 'src/utils/utils';
import { getAdministrativeServiceByIdData } from '../../../redux/administrativeService/administrativeServiceReducer';
import { trySearchAdministrativeServiceById } from '../../../redux/administrativeService/administrativeServiceActionCreator';
import { useDispatch, useSelector } from 'react-redux';

const FundRequestDetailModal = (props) => {
    const dispatch = useDispatch();

    const { showFundRequestDetailModal, setShowFundRequestDetailModal, activeFundRequest } = props;

    const administrativeService = useSelector(state => getAdministrativeServiceByIdData(state));

    useEffect(() => {
        activeFundRequest?.administrativeServiceId && dispatch(trySearchAdministrativeServiceById(activeFundRequest.administrativeServiceId))
    }, [activeFundRequest]);

    return <>
        <Modal
            show={showFundRequestDetailModal}
            onHide={() => setShowFundRequestDetailModal(false)}
            dialogClassName="modal-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-uppercase'>{`Detalle del Pedido de Fondos`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped borderless hover className="shadow-sm p-2 mb-1 bg-white rounded text-black-color" >
                    <tbody>
                        <tr>
                            <td className='font-weight-bold text-left'>Tipo:</td>
                            <td className='text-left'>
                                {getFundRequestTypeValueToShow(activeFundRequest?.fundRequestType)}
                            </td>
                        </tr>
                        <tr>
                            <td className='text-left font-weight-bold'>
                                Servicio:
                            </td>
                            <td className='text-left' style={{ overflowWrap: 'anywhere' }}>
                                {`${administrativeService?.code} - ${administrativeService?.name}`}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Expediente:</td>
                            <td className='text-left'>
                                {activeFundRequest?.administrativeDocument?.codeOrganism}-{activeFundRequest?.administrativeDocument?.number}/{activeFundRequest?.administrativeDocument?.year}
                            </td>
                        </tr>  
                        <tr>
                            <td className='font-weight-bold text-left'>Numero:</td>
                            <td className='text-left'>
                                {activeFundRequest?.number}    -    {activeFundRequest?.year}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Fecha:</td>
                            <td className='text-left'>
                                {mFormat(activeFundRequest?.fundRequestDate)}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Descripción:</td>
                            <td className='text-left'>
                                {activeFundRequest?.description}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Importe:</td>
                            <td className='text-left'>
                                {formatterPeso.format(activeFundRequest?.amount)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <hr />
                <p className='text-black-color h6'>Detalle de Cuenta Bancaria:</p>
                <Table striped borderless hover>
                    <thead>
                        <tr>
                            <th className='text-center font-weight-bold'>Número</th>
                            <th className='text-center font-weight-bold'>Descripción de la cuenta</th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        <tr key={activeFundRequest?.bankAccount?.id}> 
                            <td className='text-center py-1'>
                                {activeFundRequest?.bankAccount?.number}
                            </td>
                            <td className='text-center py-1'>
                                {activeFundRequest?.bankAccount?.description}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowFundRequestDetailModal(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default FundRequestDetailModal