import * as ACTION_TYPES from './auditActionTypes';

// Actions list actions
export const getAuditListRequest = () => ({
	type: ACTION_TYPES.LIST_AUDIT_REQUEST
});

export const getAuditListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_AUDIT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAuditListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_AUDIT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearAuditList = () => ({
	type: ACTION_TYPES.CLEAR_AUDIT_LIST
});
// END Actions list actions

// list audits users actions
export const getAuditsUsersListRequest = () => ({
	type: ACTION_TYPES.LIST_AUDITS_USERS_REQUEST
});

export const getAuditsUsersListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_AUDITS_USERS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAuditsUsersListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_AUDITS_USERS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearAuditsUsersList = () => ({
	type: ACTION_TYPES.CLEAR_AUDITS_USERS_LIST
});
// END list audits users actions