import React from 'react';
import { Container, Card, Table, Modal, Button, Spinner } from 'react-bootstrap';


const AboutModalComponent = props => {

	const { show, handleClose } = props;

	const data = props?.data;

	//version Funds Requests
	 // TODO: Se habilitará cuando oficialmente se ponga en Producción Pedidos de Fondos
	const versionFundsRequestsAPIData = data?.versionFundsRequestsAPIData;
	const versionFundsRequestsAPIIsFetching = data?.versionFundsRequestsAPIIsFetching; 
	//version Core
	const versionCoreAPIData = data?.versionCoreAPIData;
	const versionCoreAPIIsFetching = data?.versionCoreAPIIsFetching;
	//version Core
	const versionPresupuestoAPIData = data?.versionPresupuestoAPIData;
	const versionPresupuestoAPIIsFetching = data?.versionPresupuestoAPIIsFetching;
	//Version AuthAPI
	const versionAuthAPIData = data?.versionAuthAPIData;
	const versionAuthAPIIsFetching = data?.versionAuthAPIIsFetching;
	//FrontEnd
	const frontEnd = data?.frontEnd;

	return <Container fluid>
		<Modal show={show} onHide={handleClose} >

			<Modal.Header closeButton>
				<Modal.Title >{props?.title}</Modal.Title>
			</Modal.Header>

			<Modal.Body >

				<Card>
					<Card.Header>
						<h5 className='text-center mb-0'>{props?.titleCard}</h5>
					</Card.Header>
					<Card.Body>
						<Table striped borderless hover className='text-black-color' >
							<tbody>
								<tr>
									{
										versionPresupuestoAPIIsFetching
										?
											<div className='d-flex justify-content-center'>
												<Spinner animation='border' className='my-2 spinner-border text-danger' />
											</div>
										:
										<>
											<td className='text-left' md={4}>
												API de Presupuesto:
											</td>
											<td className='text-right' md={4} md-offset={8}>
												{ versionPresupuestoAPIData?.version ? versionPresupuestoAPIData?.version : 'Sin Datos' }
											</td>
										</>
									}
								</tr>
								<tr>
									{
										versionFundsRequestsAPIIsFetching
										?
											<div className='d-flex justify-content-center'>
												<Spinner animation='border' className='my-2 spinner-border text-danger' />
											</div>
										:
										<>
											<td className='text-left' md={4}>
												{ versionFundsRequestsAPIData?.Description ? versionFundsRequestsAPIData?.Description : 'Sin Datos' }:
											</td>
											<td className='text-right' md={4} md-offset={8}>
												{ versionFundsRequestsAPIData?.version ? `v${versionFundsRequestsAPIData?.version}`  : 'Sin Datos' }
											</td>
										</>
									}
								</tr>
								<tr>
									{
										versionAuthAPIIsFetching
										?
											<div className='d-flex justify-content-center'>
												<Spinner animation='border' className='my-2 spinner-border text-danger' />
											</div>
										:
										<>
											<td className='text-left' md={4}>
												AuthAPI:
											</td>
											<td className='text-right' md={4} md-offset={8}>
												{ versionAuthAPIData ? `v${versionAuthAPIData}` : 'Sin Datos' }
											</td>
										</>
									}
								</tr>
								<tr>
									{
										versionCoreAPIIsFetching
										?
											<div className='d-flex justify-content-center'>
												<Spinner animation='border' className='my-2 spinner-border text-danger' />
											</div>
										:
										<>
											<td className='text-left' md={4}>
												{ versionCoreAPIData?.Description ? versionCoreAPIData?.Description : 'Sin Datos' }:
											</td>
											<td className='text-right' md={4} md-offset={8}>
												{ versionCoreAPIData?.version ? `v${versionCoreAPIData?.version}` : 'Sin Datos' }
											</td>
										</>
									}
								</tr>
								<tr>
									<td className='text-left' md={4}>
										FrontEnd:
									</td>
									<td className='text-right' md={4} md-offset={8}>
										{ frontEnd ? frontEnd : 'Sin Datos' }
									</td>
								</tr>
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cerrar
				</Button>
			</Modal.Footer>

		</Modal>
	</Container>;
};

export default AboutModalComponent;