import React, { useState, useEffect } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { impactsBudgetItems } from 'src/utils/label';
import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { CREDIT_EXECUTION, BALANCE_TRANSFER_STEP_TWO } from 'src/utils/constants';
import AssociatedExpedientComponent from 'src/components/forms/balanceTransfer/AssociatedExpedientComponent';
import { clearNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';

const BalanceTransferStepOne = () => {

    const dispatch = useDispatch();

    const hookFormMethods = useForm();

    const administrativeDocumentDataFromEndpointIsFetching = useSelector(state => getAdministrativeDocumentIsFetching(state));
    const selectedAdministrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

    const [dataLengthCharacter, setDataLengthCharacter] = useState(false);
    const [dataYearSelected, setDataYearSelected] = useState(false);
    const [dataNumberSelected, setDataNumberSelected] = useState(false);
    const [isNewAdministrativeDocument, setIsNewAdministrativeDocument] = useState(true);

    const onClickNextStepButton = () => {
        dispatch(push(BALANCE_TRANSFER_STEP_TWO))
    };

    const onClickCancelButton = () => {
        dispatch(push(CREDIT_EXECUTION))
    };

    const enableButton = (selectedAdministrativeDocument && !administrativeDocumentDataFromEndpointIsFetching);

    useEffect(() => {
        dispatch(clearNewAffectationDataSelectedCredits());
    }, []);

    return <Container fluid >
        <Card className='mb-5'>
            <PageTitle text={impactsBudgetItems} />
            <Container fluid >
                <StepIndicator steps={config.appSettings.steps.balanceTransfer} current={0} className='mt-2 mb-3' />
                <Card className='mb-3'>
                    <Card.Header className='h6'>
                        Traspaso de Saldos
                    </Card.Header>
                    <Card.Body>
                        <h6 className='text-black-color mb-3'>Datos del expediente asociado</h6>
                        <span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
                            <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                            Obligatorio
                        </span>
                        <FormProvider {...hookFormMethods} >
                            <Form className='text-black-color mt-5 mb-5'>
                                <AssociatedExpedientComponent
                                    dataLengthCharacter={dataLengthCharacter}
                                    setDataLengthCharacter={setDataLengthCharacter}
                                    dataYearSelected={dataYearSelected}
                                    setDataYearSelected={setDataYearSelected}
                                    dataNumberSelected={dataNumberSelected}
                                    setDataNumberSelected={setDataNumberSelected}
                                    isNewAdministrativeDocument={isNewAdministrativeDocument}
                                    setIsNewAdministrativeDocument={setIsNewAdministrativeDocument}
                                />
                                <Form.Group className='mt-4 d-flex justify-content-around text-white-color'>
                                    <Button className='text-white-color' variant='danger' size='lg' onClick={onClickCancelButton} tabIndex='6'>
                                        Cancelar
                                    </Button>

                                    <Spinner
                                        animation='border'
                                        className={administrativeDocumentDataFromEndpointIsFetching ? '' : 'hidden'}
                                    />

                                    <Button className='text-white-color' variant='success' size='lg' tabIndex='5' disabled={!enableButton} onClick={onClickNextStepButton}>
                                        Siguiente
                                        <FontAwesomeIcon className='ml-3' icon={faArrowRight} />
                                    </Button>
                                </Form.Group>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    </Container>;
};

export default BalanceTransferStepOne;