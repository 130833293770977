import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Table, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { tryGetListUserProfile } from 'src/redux/user/userActionCreator';
import { isNotEmptyArray } from 'src/services/validationService';
import { getUserProfileListData, getUserProfileListIsFetching } from 'src/redux/user/userReducer';
import { emptyCredit, name } from 'src/utils/label';

const UsersListingPage = () => {
	const dispatch = useDispatch();
	const listProfileData = useSelector( state => getUserProfileListData(state) )?.sort((a, b) => a.name > b.name ? 1 : -1);
	const hasListProfile = isNotEmptyArray(listProfileData);
	const listProfileIsFetching = useSelector( state => getUserProfileListIsFetching(state) );

	useEffect( () => {
		dispatch( tryGetListUserProfile() );
	}, [] );
	
	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
						<h1 className="h6 mt-1 mb-0">Grupos</h1>
					</Card.Header>
					<Card.Body>
						<Table striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>{name}</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{
									hasListProfile ?
										listProfileData?.map( (item, index) => <tr key={index} >
											<td className='text-center'>
												{item.name}
											</td>
										</tr> )
									:
										<tr>
											<td className='text-center'>
												{
													listProfileIsFetching ?
														<Spinner animation='border' variant='success' className='my-3'/>
													:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3'/>										
															{emptyCredit}
														</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default UsersListingPage;