import React, { useState } from 'react';
import {  useSelector } from 'react-redux';
import { Container, Card, Table, Button, Alert} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PageTitle from 'src/components/general/PageTitle';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { getReportByURL } from 'src/services/reportsServices';
import { formatterPeso, downloadStreamFile, numberNegativeRed} from 'src/utils/utils';
import { seatNumber, numberCredit, typesOfAffectation, dateImpact, subpartialDeparture, detail, amountNumber, quotaNumber } from 'src/utils/label.js';
import { impactsBudgetItems } from 'src/utils/label';
import { setRevertAffectationData } from 'src/redux/affectation/affectationReducer';
import AppLoading from 'src/components/common/AppLoading';

const CreditExecutionNewAffectationStepFourPage = () => {

	const accessToken = useSelector( state => getAccessToken(state) );

	const revertAffectattionData = useSelector(state => setRevertAffectationData(state))?.data;

	const amountRevertValue = revertAffectattionData?.affectationMovements[0]?.amount;

	// POST Response data
	const reportUrl = revertAffectattionData?.reportUrl;
	const hasReportUrl = !!reportUrl;

	const onClickGoToCreditExecution = () => { window.history.back(); };

	// Download report
	const [isLoading, setIsLoading] = useState(false);
	const onClickDownloadReport = () => {
		setIsLoading(true);
		getReportByURL( accessToken, revertAffectattionData?.reportUrl )
			.then( response => {
				if( response?.status == 200 ) {
					downloadStreamFile( response, 'reporte_nueva_afectacion' );
					setIsLoading(false);
				} else {
					setIsLoading(false);
				}
			});
	};

	return <Container fluid >
		<Card className='mb-5'>

			<PageTitle text={impactsBudgetItems}/>

			<Container fluid >

				<Card className='mb-3'>
					<Card.Header className='h6'>
						Reversión de afectación
					</Card.Header>
					<Card.Body>
						
						<Alert variant='info' className='text-main-color text-center mt-4 mb-5'>
							¡Reversión de la afectación de la partida generada correctamente!
						</Alert>

						<div className='text-center'>
							<Button variant='success' className='mb-4' onClick={onClickDownloadReport} disabled={!hasReportUrl}>
								<FontAwesomeIcon icon={faDownload} className='mr-2'/>
								Descargar comprobante
							</Button>
						</div>

						<hr/>

						{
							revertAffectattionData
							?
								<>
									<h6 className='text-black-color mt-4 mb-3'>
										Partida afectada:
									</h6>
											
									<Table striped bordered hover size='sm'>
										<thead>
											<tr>
												<th className='text-center align-middle'>{seatNumber}</th>
												<th className='text-center align-middle'>{numberCredit}</th>
												<th className='text-center align-middle'>{subpartialDeparture}</th>
												<th className='text-center align-middle'>{quotaNumber}</th>
												<th className='text-center align-middle'>{typesOfAffectation}</th>
												<th className='text-center align-middle'>{dateImpact}</th>
												<th className='text-center align-middle'>{detail}</th>
												<th className='text-center align-middle'>{amountNumber}</th>
												{/* <th></th> */}
											</tr>
										</thead>
										<tbody className='text-black-color'>

											<tr key={revertAffectattionData?.id}>

												<td className='text-center align-middle'>
													{revertAffectattionData?.affectationMovements[0]?.accountingNumber}
												</td>
												<td className='text-center align-middle'>
													{revertAffectattionData?.affectationMovements[0]?.budgetNumber}
												</td>
												<td className='text-center align-middle'>
													{revertAffectattionData?.affectationMovements[0]?.subPartialBudget ? revertAffectattionData?.affectationMovements[0]?.subPartialBudget?.number : "-"}
												</td>
												<td className='text-center align-middle'>
													{revertAffectattionData?.affectationMovements[0]?.feeNumber ? revertAffectattionData?.affectationMovements[0]?.feeNumber : "-"}
												</td>
												<td className='text-center align-middle'>
													{revertAffectattionData?.affectationMovements[0]?.affectationStatus?.name}
												</td>
												<td className='text-center align-middle'>
													{moment(revertAffectattionData?.affectationMovements[0]?.dateUpload).format('DD/MM/YYYY')}
												</td>
												<td className='text-center align-middle'>
													{revertAffectattionData?.affectationMovements[0]?.description}
												</td>
												<td className={`${numberNegativeRed(revertAffectattionData?.affectationMovements[0]?.amount)} + text-right align-middle`}>
													{formatterPeso.format(amountRevertValue)}
												</td>
												
											</tr>
										</tbody>
									</Table>
								</>
							:
							null
						}
																		
						<div className='mt-3 mb-3 d-flex justify-content-around'>
							<Button variant='danger' onClick={onClickGoToCreditExecution}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2'/>
								Volver al detalle de partida presupuestaria
							</Button>
						</div>
					</Card.Body>
				</Card>
			</Container>
		</Card>

		<AppLoading isLoading={isLoading} />
		
	</Container>;
};

export default  CreditExecutionNewAffectationStepFourPage;