import * as ACTIONS from './functionalityActions';
import * as PURPOSE_SERVICES from 'src/services/purposeServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetFunctionalitiesListByPurposeId = purposeId => {
	return  async ( dispatch, getState ) => {
		if( !purposeId ) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getFunctionalitiesListByPurposeIdRequest() );

		if(accessToken){

			const response = await PURPOSE_SERVICES.listFunctionalitiesByPurposeId( accessToken, purposeId );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getFunctionalitiesListByPurposeIdRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getFunctionalitiesListByPurposeIdRequestFailure(response?.error) );
				showError(`Error al cargar las funciones. Detalle: ${response?.error}` );
			}
		}

	};
};