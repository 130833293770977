import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import { useProfileChecks } from './hooks/useProfileChecks';

const MenuSupplyDepartment = () => {
	const dispatch = useDispatch();
    const { profileName, paramsToAlerts } = usePermissions();
    const { 
        isProfileNameSA,
        isSupplyOperator,
    } = useProfileChecks();

    if (!(isProfileNameSA || isSupplyOperator)) return null;

    return (
        <NavDropdown 
            className='active' 
            title={<><FontAwesomeIcon icon={faShoppingCart} className='mr-1'/>Dpto. Suministros</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {(isProfileNameSA || isSupplyOperator) && 
                <NavDropdownItem route={ROUTE_PATHS.PROVISION_ORDER_LIST} title="Orden de provisión" />
            }                
        </NavDropdown>
    );
};

export default MenuSupplyDepartment;