import * as ACTIONS from './figurativeExpedituresActions';
import * as SERVICES from 'src/services/figurativeExpedituresServices';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';

// GET
export const tryGetFigurativeExpedituresDetails = (params) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );
		dispatch( ACTIONS.getFigurativeExpedituresListRequest() );

		if(accessToken){
			const response = await SERVICES.getFigurativeExpeditures( accessToken, params );
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getFigurativeExpedituresListRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getFigurativeExpedituresListRequestFailure(response?.error||response) );
				showError(`Error al cargar los detalles de las erogaciones figurativas. Detalle: ${response?.error||response}` );
			}
		}
	};
};



// POST
export const tryPostFigurativeExpeditures = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.postFigurativeExpedituresRequest());

		if(accessToken){
			const response = await SERVICES.newFigurativeExpeditures(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.postFigurativeExpedituresRequestSuccess(response?.data));
				showSuccess('La vinculación de erogaciones figurativas se a registrado correctamente.');
			}
			else {
				dispatch(ACTIONS.postFigurativeExpedituresRequestFailure(response?.data?.error));
				showError(`Error al crear la erogación figurativa. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};


// PUT
export const tryPutFigurativeExpeditures = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.postFigurativeExpedituresRequest());

		if(accessToken){
			const response = await SERVICES.editFigurativeExpeditures(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.postFigurativeExpedituresRequestSuccess(response?.data));
				showSuccess('La vinculación de erogaciones figurativas se a editado correctamente.');
			}
			else {
				dispatch(ACTIONS.postFigurativeExpedituresRequestFailure(response?.data?.error));
				showError(`Error al editar la erogación figurativa. Detalle: ${response?.data?.message}`);
			}
			return response;
		}
	};
};
