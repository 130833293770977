import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { config } from 'src/env.js';
import { Container } from 'react-bootstrap';
import AboutModalComponent from 'src/components/general/AboutModalComponent';
import { tryGetVersionFundsRequestsApi, tryGetVersionCoreApi, tryGetVersionPresupuestoApi, tryGetVersionAuthApi } from 'src/redux/version/versionActionCreator';
import { getVersionFundsRequestsAPIData, getVersionFundsRequestsAPIIsFetching, getVersionCoreAPIData, getVersionCoreAPIIsFetching, getVersionPresupuestoAPIData, getVersionPresupuestoAPIIsFetching, getVersionAuthAPIData, getVersionAuthAPIIsFetching  } from 'src/redux/version/versionReducer';
import { logout } from 'src/redux/login/loginActions';
import { push } from 'connected-react-router';
import { LOGIN_URI } from 'src/utils/constants';

const Footer = () => {
	const dispatch = useDispatch();
	const yearCurrent = new Date();
	const versionFundsRequestsAPIData = useSelector( state => getVersionFundsRequestsAPIData(state) );
	const versionFundsRequestsAPIIsFetching = useSelector( state => getVersionFundsRequestsAPIIsFetching(state) );
	const versionCoreAPIData = useSelector( state => getVersionCoreAPIData(state) );
	const versionCoreAPIIsFetching = useSelector( state => getVersionCoreAPIIsFetching(state) );
	const versionPresupuestoAPIData = useSelector( state => getVersionPresupuestoAPIData(state) );
	const versionPresupuestoAPIIsFetching = useSelector( state => getVersionPresupuestoAPIIsFetching(state) );
	const versionAuthAPIData = useSelector( state => getVersionAuthAPIData(state) );
	const versionAuthAPIIsFetching = useSelector( state => getVersionAuthAPIIsFetching(state) );
	const frontEnd = config?.appSettings?.version;
	const dataModal = {
		versionFundsRequestsAPIData : versionFundsRequestsAPIData,
		versionFundsRequestsAPIIsFetching : versionFundsRequestsAPIIsFetching, 
		versionCoreAPIData : versionCoreAPIData,
		versionCoreAPIIsFetching : versionCoreAPIIsFetching,
		versionPresupuestoAPIData : versionPresupuestoAPIData,
		versionPresupuestoAPIIsFetching : versionPresupuestoAPIIsFetching,
		versionAuthAPIData : versionAuthAPIData,
		versionAuthAPIIsFetching : versionAuthAPIIsFetching,
		frontEnd : frontEnd
	};

	const GET_STATUS_API = `${config.apis.coreApi.URL}/system-status-log`;

	const onChangeVersionApi = actualApiVersion => {

		if (frontEnd !== actualApiVersion) {
			swal({
				title: 'Actualización',
				text: 'Cambio la versión de la aplicación, debera reiniciar la sesión para ver los cambios',
				icon: 'warning'
			}).then(() => {
				dispatch(logout());
				dispatch(push(LOGIN_URI));
			});
		}
	}

	useEffect(() => {
        const fetchStatusApi = async () => {
            try {
                const response = await fetch(GET_STATUS_API);
                const data = await response.json();
                const version = data.records[0].frontendVersion;
                onChangeVersionApi(version);
            } catch (error) {
                console.error("Error fetching frontStatus:", error);
            }
        };
        fetchStatusApi();
        const intervalId = setInterval(() => {
            fetchStatusApi();
        }, 6000);
        return () => clearInterval(intervalId);
    }, []); 
	
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
		
	const onClickSeeAboutModal = () => {
		handleShow(true);
		dispatch( tryGetVersionFundsRequestsApi() ); 
		dispatch( tryGetVersionCoreApi() );
		dispatch( tryGetVersionPresupuestoApi() );
		dispatch( tryGetVersionAuthApi() );
	};

	return( <Container>
		<footer id='footer' className='row page-footer font-weight-light footer-container fixed-bottom'>
			<div className='col-md-12'>
				<div className='text-right py-1 px-3 text-white'>
					© {yearCurrent.getFullYear()} Copyright: {config.appSettings.name} - <a className='font-italic text-white cursor-pointer font-weight-bold' onClick={() => onClickSeeAboutModal()} >Acerca de...</a>
				</div>
			</div>
		</footer>

		<AboutModalComponent
			data={dataModal}
			title={'Acerca de...'}
			titleCard={config.appSettings.name}
			show={show}
			handleClose={handleClose}
		/>

	</Container>
	);
};

export default Footer;