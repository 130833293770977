
// List delivery order
export const LIST_DELIVERY_ORDER_REQUEST = 'LIST_DELIVERY_ORDER_REQUEST';
export const LIST_DELIVERY_ORDER_REQUEST_SUCCESS = 'LIST_DELIVERY_ORDER_REQUEST_SUCCESS';
export const LIST_DELIVERY_ORDER_REQUEST_FAILURE = 'LIST_DELIVERY_ORDER_REQUEST_FAILURE';
export const CLEAR_LIST_DELIVERY_ORDER_DATA = 'CLEAR_LIST_DELIVERY_ORDER_DATA';

////
export const GET_DETAIL_DELIVERY_ORDER_REQUEST_ID = 'GET_DETAIL_DELIVERY_ORDER_REQUEST_ID';
export const GET_DELIVERY_ORDER_ID_REQUEST_SUCCESS = 'GET_DELIVERY_ORDER_ID_REQUEST_SUCCESS';
export const GET_DELIVERY_ORDER_ID_REQUEST_FAILURE = 'GET_DELIVERY_ORDER_ID_REQUEST_FAILURE';
//

// Set delivery order
export const SET_DETAIL_DELIVERY_ORDER_DATA = 'SET_DETAIL_DELIVERY_ORDER_DATA';
export const CLEAR_DETAIL_DELIVERY_ORDER_DATA = 'CLEAR_DETAIL_DELIVERY_ORDER_DATA';
export const DETAIL_DELIVERY_ORDER_REQUEST = 'DETAIL_DELIVERY_ORDER_REQUEST';
export const DETAIL_DELIVERY_ORDER_REQUEST_SUCCESS = 'DETAIL_DELIVERY_ORDER_REQUEST_SUCCESS';
export const DETAIL_DELIVERY_ORDER_REQUEST_FAILURE = 'DETAIL_DELIVERY_ORDER_REQUEST_FAILURE';

//New
export const CREATE_DELIVERY_ORDER_REQUEST = 'CREATE_DELIVERY_ORDER_REQUEST'
export const CREATE_DELIVERY_ORDER_REQUEST_SUCCESS = 'CREATE_DELIVERY_ORDER_REQUEST_SUCCESS'
export const CREATE_DELIVERY_ORDER_REQUEST_FAILURE = 'CREATE_DELIVERY_ORDER_REQUEST_FAILURE'
export const CLEAR_CREATE_DELIVERY_ORDER = 'CLEAR_CREATE_DELIVERY_ORDER'

//NewMassive 
export const CREATE_DELIVERY_ORDER_MASSIVE_REQUEST = 'CREATE_DELIVERY_ORDER_MASSIVE_REQUEST'
export const CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_SUCCESS = 'CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_SUCCESS'
export const CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_FAILURE = 'CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_FAILURE'
export const CLEAR_CREATE_DELIVERY_MASSIVE_ORDER = 'CLEAR_CREATE_DELIVERY_ORDER_MASSIVE'

//Edit
export const EDIT_DELIVERY_ORDER_REQUEST = 'EDIT_DELIVERY_ORDER_REQUEST'
export const EDIT_DELIVERY_ORDER_REQUEST_SUCCESS = 'EDIT_DELIVERY_ORDER_REQUEST_SUCCESS'
export const EDIT_DELIVERY_ORDER_REQUEST_FAILURE = 'EDIT_DELIVERY_ORDER_REQUEST_FAILURE'
export const CLEAR_EDIT_DELIVERY_ORDER = 'CLEAR_EDIT_DELIVERY_ORDER'
export const SET_DELIVERY_ORDER_TO_EDIT = 'SET_DELIVERY_ORDER_TO_EDIT';

//Delete
export const DELETE_DELIVERY_ORDER_REQUEST = 'DELETE_DELIVERY_ORDER_REQUEST'
export const DELETE_DELIVERY_ORDER_REQUEST_SUCCESS = 'DELETE_DELIVERY_ORDER_REQUEST_SUCCESS'
export const DELETE_DELIVERY_ORDER_REQUEST_FAILURE = 'DELETE_DELIVERY_ORDER_REQUEST_FAILURE'
export const CLEAR_DELETE_DELIVERY_ORDER = 'CLEAR_DELETE_DELIVERY_ORDER'
