// Version Funds Requests Api
export const GET_VERSION_FUNDS_REQUESTS_API_REQUEST = 'GET_VERSION_FUNDS_REQUESTS_API_REQUEST';
export const GET_VERSION_FUNDS_REQUESTS_API_REQUEST_SUCCESS = 'GET_VERSION_FUNDS_REQUESTS_API_REQUEST_SUCCESS';
export const GET_VERSION_FUNDS_REQUESTS_API_REQUEST_FAILURE = 'GET_VERSION_FUNDS_REQUESTS_API_REQUEST_FAILURE';

// Version Core Api
export const GET_VERSION_CORE_API_REQUEST = 'GET_VERSION_CORE_API_REQUEST';
export const GET_VERSION_CORE_API_REQUEST_SUCCESS = 'GET_VERSION_CORE_API_REQUEST_SUCCESS';
export const GET_VERSION_CORE_API_REQUEST_FAILURE = 'GET_VERSION_CORE_API_REQUEST_FAILURE';

// Version Presupuesto Api
export const GET_VERSION_PRESUPUESTO_API_REQUEST = 'GET_VERSION_PRESUPUESTO_API_REQUEST';
export const GET_VERSION_PRESUPUESTO_API_REQUEST_SUCCESS = 'GET_VERSION_PRESUPUESTO_API_REQUEST_SUCCESS';
export const GET_VERSION_PRESUPUESTO_API_REQUEST_FAILURE = 'GET_VERSION_PRESUPUESTO_API_REQUEST_FAILURE';

// Version Auth Api
export const GET_VERSION_AUTH_API_REQUEST = 'GET_VERSION_AUTH_API_REQUEST';
export const GET_VERSION_AUTH_API_REQUEST_SUCCESS = 'GET_VERSION_AUTH_API_REQUEST_SUCCESS';
export const GET_VERSION_AUTH_API_REQUEST_FAILURE = 'GET_VERSION_AUTH_API_REQUEST_FAILURE';