import React from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';

import { getIsLoggedIn } from 'src/redux/login/loginReducer';
import { INITIAL_URI } from 'src/utils/constants';

const LoggedOutCard = props => {
	const dispatch = useDispatch();
	const { cardContent } = props;

	const isLoggedIn = useSelector( state => getIsLoggedIn(state) );

	if( isLoggedIn ) {
		// dispatch( push(INITIAL_URI) );
	}

	return (
		<Container fluid>
			<Row>
				<Col className='d-flex justify-content-center'>
					<div className='col-lg-4 col-md-6 col-sm-10'>
						<div className='LoggerOutCard__container'>
							{cardContent}
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default LoggedOutCard;