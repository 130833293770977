import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFormContext } from 'react-hook-form';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Form, Row, Col, Button } from 'react-bootstrap';
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import AppLoading from 'src/components/common/AppLoading';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getListAllServiceAdministrativeByUserIsFetching, getListAllServiceAdministrativeByUserData } from 'src/redux/administrativeService/administrativeServiceReducer';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import moment from 'moment';
import { tryRevertPassiveRemainingByService } from 'src/redux/affectation/affectationActionCreator';
import { getRevertPassiveRemainingByServcieIsFetching } from 'src/redux/affectation/affectationReducer';
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const RevertPassiveRemainingByServicePage = () => {
    const dispatch = useDispatch();
    const [serviceCode, setServiceCode] = useState();
    const isFetching = useSelector(state => getRevertPassiveRemainingByServcieIsFetching(state));

    //Form
    const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

    // Period
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

    // User data
    const email = useSelector(state => getEmail(state));
    const listUserData = useSelector(state => getUserListData(state));
    const userId = listUserData?.records?.find(item => item.email == email);

    // Administrative services
    const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));
    const administrativeServiceData = AdministrativeServiceData();

    // Date
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [messageError, setMessageError] = useState(false);

    const buttonEnabled = (serviceCode && dateFrom && dateTo && !messageError);

    const validateDatePeriod = () => {
        setMessageError((dateFrom && dateTo && dateFrom > dateTo) ? true : false);
    };

    // Submit
    const onClickSubmit = () => {

        const params = {
            date_from: dateFrom,
            date_to: dateTo,
            service_id: serviceCode?.id,
            period_id: selectedPeriod?.id
        };

        const dateFromArgFormat = moment(dateFrom).format('DD/MM/YY');
        const dateToArgFormat = moment(dateTo).format('DD/MM/YY');
        const confirm = `¿Está seguro que desea revertir los Residuos Pasivos del servicio "${serviceCode?.displayName}" generados desde la fecha ${dateFromArgFormat} hasta la fecha ${dateToArgFormat} ?`;

        swal({
            title: 'Confirmación',
            text: confirm,
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(tryRevertPassiveRemainingByService(params));
                };
            });
    };

    // Use Effect
    useEffect(() => {
        dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
    }, []);

    return (
        <Container fluid>
            <PageTitle text='Reversión de residuos pasivos' />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Body>
                        <useFormContext {...hookFormMethods}>
                            <Form onSubmit={handleSubmit(onClickSubmit)} autoComplete='off'>
                                <Row className='justify-content-center'>
                                    <Col sm={8}>
                                        <Form.Group className="mb-2">

                                            <Form.Label className='text-black-color'>
                                                Servicio
                                            </Form.Label>

                                            <DropdownList
                                                placeholder='Seleccione un servicio...'
                                                data={administrativeServiceData}
                                                textField='displayName'
                                                filter='contains'
                                                className='text-black-color mb-3'
                                                onChange={service => setServiceCode(service)}
                                                selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
                                                searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />} >
                                            </DropdownList>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label className='text-black-color'>
                                                Fecha desde:
                                            </Form.Label>
                                            <Form.Control
                                                name='dateFrom'
                                                type='date'
                                                required
                                                max={todayDate}
                                                onChange={event => setDateFrom(event?.target?.value)}
                                                onBlur={event => validateDatePeriod(event)}
                                            />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label className='text-black-color'>
                                                Fecha hasta:
                                            </Form.Label>
                                            <Form.Control
                                                name='dateTo'
                                                type='date'
                                                required
                                                max={todayDate}
                                                onChange={event => setDateTo(event?.target?.value)}
                                                onBlur={event => validateDatePeriod(event)}
                                            />
                                        </Form.Group>

                                        {messageError &&
                                            <div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
                                                {'Error: La fecha desde es superior a la fecha hasta'}
                                            </div>
                                        }

                                        <Form.Group className='d-flex justify-content-end mt-5 mr-0'>
                                            <Button size='lg' type="submit" variant='success' disabled={!buttonEnabled}>
                                                Revertir Residuos Pasivos
                                            </Button>
                                            <AppLoading
                                                isLoading={false}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </useFormContext>
                    </Card.Body>
                </Card>
            </Container>
            <AppLoading isLoading={isFetching || administrativeServiceIsFetching} />
        </Container>
    )
}

export default RevertPassiveRemainingByServicePage;