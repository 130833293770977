import * as ACTIONS from './expedientActions';
import * as SERVICES from 'src/services/expedientsServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';
import { push } from 'connected-react-router';
import { EXPEDIENT_LIST } from 'src/utils/constants';

// expedient api system
export const tryGetExpedientList = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getExpedientListRequest() );

		if(accessToken){

			const response = await SERVICES.listExpedient( accessToken, params );

			if( response?.status == 200 ) {
				dispatch( ACTIONS.getExpedientListRequestSuccess(response?.data) );
				return response;
			}
			else {
				dispatch( ACTIONS.getExpedientListRequestFailure(response?.error || response) );
				showError(`Error al cargar los expedientes. Detalle: ${response?.response?.data?.message || response}` );
			}

			return response;
		}

	};
};

//Edit Expedient
export const tryEditExpedient = captionData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editExpedientRequest());

		if (accessToken) {

			const response = await SERVICES.editExpedient(accessToken, captionData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editExpedientRequestSuccess(response?.data));
				showSuccess('Expediente editado correctamente.');
				dispatch(push( EXPEDIENT_LIST ));
			}
			else {
				dispatch(ACTIONS.editExpedientRequestFailure(response?.error || response));
				showError(`Error al editar el expediente. Detalle: ${response?.response?.data?.message || response?.error}`);
			}
			return response;
		}

	};

};