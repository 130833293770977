import React from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { mFormat} from 'src/utils/utils';

const DeliveryOrderDetailModal = (props) => {
    const { showDeliveryOrderDetailModal, setShowDeliveryOrderDetailModal, activeDeliveryOrder } = props;

    return <>
        <Modal
            show={showDeliveryOrderDetailModal}
            onHide={() => setShowDeliveryOrderDetailModal(false)}
            dialogClassName="modal-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-uppercase'>{`Detalle de la Orden de Entrega`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped borderless hover className="shadow-sm p-2 mb-1 bg-white rounded text-black-color" >
                    <tbody>
                         <tr>
                            <td className='font-weight-bold text-left'>Numero:</td>
                            <td className='text-left'>
                                {activeDeliveryOrder?.number}    -    {activeDeliveryOrder?.year}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Expediente:</td>
                            <td className='text-left'>
                                {activeDeliveryOrder?.administrativeDocument?.codeOrganism}-{activeDeliveryOrder?.administrativeDocument?.number}/{activeDeliveryOrder?.administrativeDocument?.year}
                            </td>
                        </tr>  
                        <tr>
                            <td className='font-weight-bold text-left'>Fecha:</td>
                            <td className='text-left'>
                                {mFormat(activeDeliveryOrder?.deliveryOrderDate)}
                            </td>
                        </tr>
                        <tr>
                            <td className='font-weight-bold text-left'>Descripción:</td>
                            <td className='text-left'>
                                {activeDeliveryOrder?.description}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeliveryOrderDetailModal(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default DeliveryOrderDetailModal