import * as ACTION_TYPES from './organizationActionTypes';

export const organizationReducerInitialState = {
	organizationListByJurisdictionId: {
		jurisdictionId: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	organizationTypesList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	organizationList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	organismsByJurisdictionsServicesAndExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const organizationReducer = ( state=organizationReducerInitialState, action ) => {
	switch( action.type ) {

		// Organization by jurisdiction ID list actions
		case ACTION_TYPES.LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST: {
			const { jurisdictionId } = action.payload;
			return {
				...state,
				organizationListByJurisdictionId: {
					...state.organizationListByJurisdictionId,
					isFetching: true,
					hasError: false,
					error: organizationReducerInitialState.organizationListByJurisdictionId.error,
					data: organizationReducerInitialState.organizationListByJurisdictionId.data,
					jurisdictionId
				}
			};
		}
		case ACTION_TYPES.LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				organizationListByJurisdictionId: {
					...state.organizationListByJurisdictionId,
					isFetching: false,
					hasError: false,
					error: organizationReducerInitialState.organizationListByJurisdictionId.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				organizationListByJurisdictionId: {
					...state.organizationListByJurisdictionId,
					isFetching: false,
					hasError: true,
					jurisdictionId: organizationReducerInitialState.organizationListByJurisdictionId.jurisdictionId,
					data: organizationReducerInitialState.organizationListByJurisdictionId.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_ORGANIZATIONS_BY_JURISDICTION_ID_LIST: {
			return {
				...state,
				organizationListByJurisdictionId: organizationReducerInitialState.organizationListByJurisdictionId
			};
		}
		// END Organization by jurisdiction ID list actions



		// Organization type list actions
		case ACTION_TYPES.LIST_ORGANIZATION_TYPES_REQUEST: {
			return {
				...state,
				organizationTypesList: {
					...state.organizationTypesList,
					isFetching: true,
					hasError: false,
					data: organizationReducerInitialState.organizationTypesList.data,
					error: organizationReducerInitialState.organizationTypesList.error
				}
			};
		}
		case ACTION_TYPES.LIST_ORGANIZATION_TYPES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				organizationTypesList: {
					...state.organizationTypesList,
					isFetching: false,
					hasError: false,
					error: organizationReducerInitialState.organizationTypesList.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ORGANIZATION_TYPES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				organizationTypesList: {
					...state.organizationTypesList,
					isFetching: false,
					hasError: true,
					data: organizationReducerInitialState.organizationTypesList.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ORGANIZATION_TYPES: {
			return {
				...state,
				organizationTypesList: organizationReducerInitialState.organizationTypesList
			};
		}
		// END Organization type list actions

		// Organization list actions
		case ACTION_TYPES.LIST_ORGANIZATION_REQUEST: {
			return {
				...state,
				organizationList: {
					...state.organizationList,
					isFetching: true,
					hasError: false,
					data: organizationReducerInitialState.organizationList.data,
					error: organizationReducerInitialState.organizationList.error
				}
			};
		}
		case ACTION_TYPES.LIST_ORGANIZATION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				organizationList: {
					...state.organizationList,
					isFetching: false,
					hasError: false,
					error: organizationReducerInitialState.organizationList.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ORGANIZATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				organizationList: {
					...state.organizationList,
					isFetching: false,
					hasError: true,
					data: organizationReducerInitialState.organizationList.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ORGANIZATION: {
			return {
				...state,
				organizationList: organizationReducerInitialState.organizationList
			};
		}
		// END Organization list actions

		// Organisms By Jurisdictions, Services and Exercise actions
		case ACTION_TYPES.ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST: {
			return {
				...state,
				organismsByJurisdictionsServicesAndExercise: {
					...state.organismsByJurisdictionsServicesAndExercise,
					isFetching: true,
					hasError: false,
					data: organizationReducerInitialState.organismsByJurisdictionsServicesAndExercise.data,
					error: organizationReducerInitialState.organismsByJurisdictionsServicesAndExercise.error
				}
			};
		}
		case ACTION_TYPES.ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				organismsByJurisdictionsServicesAndExercise: {
					...state.organismsByJurisdictionsServicesAndExercise,
					isFetching: false,
					hasError: false,
					error: organizationReducerInitialState.organismsByJurisdictionsServicesAndExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				organismsByJurisdictionsServicesAndExercise: {
					...state.organismsByJurisdictionsServicesAndExercise,
					isFetching: false,
					hasError: true,
					data: organizationReducerInitialState.organismsByJurisdictionsServicesAndExercise.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID: {
			return {
				...state,
				organismsByJurisdictionsServicesAndExercise: organizationReducerInitialState.organismsByJurisdictionsServicesAndExercise
			};
		}
		// END Organisms By Jurisdictions, Services and Exercise actions


		default: {
			return state;
		}
	}
};

export const getOrganizationReducer = state => state.organizationReducer;

export const getOrganizationListByJurisdictionId = state => getOrganizationReducer(state)?.organizationListByJurisdictionId;
export const getOrganizationListByJurisdictionIdData = state => getOrganizationListByJurisdictionId(state)?.data;
export const getOrganizationListByJurisdictionIdError = state => getOrganizationListByJurisdictionId(state)?.error;
export const getOrganizationListByJurisdictionIdIsFetching = state => getOrganizationListByJurisdictionId(state)?.isFetching;
export const getOrganizationListByJurisdictionIdHasError = state => getOrganizationListByJurisdictionId(state)?.hasError;

export const getOrganizationTypesList = state => getOrganizationReducer(state)?.organizationTypesList;
export const getOrganizationTypesListData = state => getOrganizationTypesList(state)?.data;
export const getOrganizationTypesListIsFetching = state => getOrganizationTypesList(state)?.isFetching;
export const getOrganizationTypesListHasError = state => getOrganizationTypesList(state)?.hasError;
export const getOrganizationTypesListError = state => getOrganizationTypesList(state)?.error;

//Organization
export const getOrganizationList = state => getOrganizationReducer(state)?.organizationList;
export const getOrganizationListData = state => getOrganizationList(state)?.data;
export const getOrganizationListIsFetching = state => getOrganizationList(state)?.isFetching;
export const getOrganizationListHasError = state => getOrganizationList(state)?.hasError;
export const getOrganizationListError = state => getOrganizationList(state)?.error;

// Organisms By Jurisdictions, Services and Exercise
export const getOrganismsByJurisdictionsServicesAndExercise = state => getOrganizationReducer(state)?.organismsByJurisdictionsServicesAndExercise;
export const getOrganismsByJurisdictionsServicesAndExerciseData = state => getOrganismsByJurisdictionsServicesAndExercise(state)?.data;
export const getOrganismsByJurisdictionsServicesAndExerciseIsFetching = state => getOrganismsByJurisdictionsServicesAndExercise(state)?.isFetching;
export const getOrganismsByJurisdictionsServicesAndExerciseHasError = state => getOrganismsByJurisdictionsServicesAndExercise(state)?.hasError;
export const getOrganismsByJurisdictionsServicesAndExerciseError = state => getOrganismsByJurisdictionsServicesAndExercise(state)?.error;

export const getOrganismsByJurisdictionsServicesAndExerciseById = (state, jurisdictionId) => getOrganismsByJurisdictionsServicesAndExerciseData(state)?.records?.find(item => item.id == jurisdictionId);