import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, FormControl, Row, Col, Alert, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faExclamationTriangle, faSearch, faRecycle, faBroom, faEnvelope, faCheck, faClock, faFilter } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import axios from "axios";
import ActionIcon from 'src/components/general/ActionIcon';
import PageTitle from 'src/components/general/PageTitle';
import { setAssignProfiles } from 'src/utils/usersProfiles';
import { useForm } from 'react-hook-form';
import { getUserPermissionsUsers } from 'src/redux/login/loginReducer';
import { trySendRestorePasswordEmail } from 'src/redux/login/loginActionCreator';
import { buildFullName } from 'src/utils/utils';
import { tryGetUserPaginatedList, tryDeleteUser, tryGetListUserProfile, tryGetListUserXApplicationXProfile, trySendValidationEmail } from 'src/redux/user/userActionCreator';
import { isNotEmptyArray } from 'src/services/validationService';
import { getUserPaginatedListData, getUserPaginatedListIsFetching, getUserProfileListData, getListUsersXApplicationXProfileData } from 'src/redux/user/userReducer';
import { USER_NEW, USER_EDIT, USER_DETAIL } from 'src/utils/constants';
import { setUserToEdit, setUserToDetails } from 'src/redux/user/userActions';
import { filterUser, questionDeleteUser, alert, noUser, actions, userEmail, users, newUser, lastNameName, accountValidated, group } from 'src/utils/label';
import AppLoading from '../common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';

const UsersListingPage = () => {

	const dispatch = useDispatch();
	const { handleSubmit, register, reset } = useForm();

	const creditLabelsRefContainer = useRef(null);
	const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;

	// Permissions
	const usersPermissions = useSelector(state => getUserPermissionsUsers(state));

	const listUserData = useSelector(state => getUserPaginatedListData(state));
	const listUserMetadata = listUserData?.metadata;

	// Set config Users By Profile
	const profileNameArray = setAssignProfiles();
	const listUserIsFetching = useSelector(state => getUserPaginatedListIsFetching(state));

	//Profile
	const listProfileData = useSelector(state => getUserProfileListData(state))?.sort((profileValue1, profileValue2) => {
		return (profileValue1.name?.toUpperCase() < profileValue2.name?.toUpperCase()) ? -1 : 1;
	});

	// Filter for type profile
	const profileNameArrayRecords = listProfileData?.filter(item => profileNameArray?.includes(item?.name));

	let profileUserIdArray = [];
	let i = 0;
	for (i in profileNameArrayRecords) {
		profileUserIdArray.push(profileNameArrayRecords[i]?.id)
	}

	// Filter for type profile
	let profileUserId = profileUserIdArray;
	const listUsersXApplicationXProfileData = useSelector(state => getListUsersXApplicationXProfileData(state));

	const listUsersXApplicationXProfileDataRecords = listUsersXApplicationXProfileData?.filter(item => profileUserId?.includes(item?.profileId));
	let UserIdArray = [];
	let item = 0;
	for (item in listUsersXApplicationXProfileDataRecords) {
		UserIdArray.push(listUsersXApplicationXProfileDataRecords[item]?.userId)
	}

	// Filter for type Profile of User
	const UserID = UserIdArray;
	const userListArrayRecords = listUserData?.records?.filter(item => UserID?.includes(item?.id)).sort((a, b) => a.name > b.name ? 1 : -1);
	const hasuserListArrayRecords = isNotEmptyArray(userListArrayRecords);

	//List Users Order
	const userListOrder = userListArrayRecords?.sort((a, b) => a.name > b.name ? 1 : -1);

	//Pagination
	const defaultParams = {
		page: 1,
		pageSize: 10
	};

	const [filterObject, setFilterObject] = useState({});

	const page = listUserMetadata?.page
	const totalItems = listUserMetadata?.totalCount;
	const perPage = listUserMetadata?.perPage;

	const onPageChange = (pNumber) => {

		const params = {
			...defaultParams,
			filter: filterObject,
			page: pNumber
		};

		loadAllData(params);
	};

	const { cancel, token } = axios.CancelToken.source();

	const loadAllData = (params) => {
		setFilterObject(params?.filter) || {};
		dispatch(tryGetUserPaginatedList(params?.page, params?.filter?.filterText || '', params?.filter?.profileId || '', params?.pageSize, token));
	};

	const onClickSeeDetails = user => {
		dispatch(setUserToDetails(user));
		dispatch(push(USER_DETAIL));
	};

	const onClickEdit = user => {
		dispatch(setUserToEdit(user));
		dispatch(push(USER_EDIT));
	};

	const onClickDelete = user => {
		let nameUser = buildFullName(user.name);
		swal({
			title: alert,
			text: '¿ ' + questionDeleteUser + nameUser + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					dispatch(tryDeleteUser(user.id));
					loadAllData();

					/* 	swal('Exito. Usuario ' + nameUser + ' eliminado correctamente.', {
						icon: 'success',
						}); */
				} else {
					/*   swal('El usuario esta a salvo!'); */
				}
			});
	};

	const [selectedProfile, setSelectedProfile] = useState();

	useEffect(() => {
		dispatch(tryGetListUserXApplicationXProfile());
		dispatch(tryGetListUserProfile());
		loadAllData({ ...defaultParams });
		return () => cancel("No longer latest query");
	}, [])

	const onClickReset = user => {
		let emailUser = user.email;
		swal({
			title: 'Confirmación',
			text: '¿Realmente desea resetear la contraseña de: ' + emailUser + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		})
			.then((willDelete) => {
				if (willDelete) {
					dispatch(trySendRestorePasswordEmail(user?.email)).then(
						response => {
							if (response?.status == 200) {
								loadAllData();
							}
						}
					);
				}
			});
	};

	const onClickResend = user => {

		let emailId = user.id;
		let emailUser = user.email;

		swal({
			title: 'Confirmación',
			text: '¿Realmente desea reenviar el correo de validación a: ' + emailUser + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		})
			.then((willResend) => {
				if (willResend) {
					dispatch(trySendValidationEmail(emailId)).then(
						response => {
							if (response?.status == 200) {
								loadAllData();
							}
						}
					);
				}
			});
	};

	const onSubmitForm = ({ userQuery, profileQuery }) => {
		const params = {
			...defaultParams,
			filter: {
				filterText: userQuery,
				profileId: profileQuery,
			}
		};
		if (!listUserIsFetching) {
			loadAllData(params);
		}
	}

	const clearFilters = () => {
		reset();
		loadAllData();
		setSelectedProfile('selected');
	};

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
						<h1 className="h6 mt-1 mb-0">Usuarios</h1>
					</Card.Header>
					<Card.Body>
						{
							usersPermissions?.canCreate
								?
								<Button size='sm' className='mb-3 btn-success' variant='secondary' onClick={() => dispatch(push(USER_NEW))} >
									<FontAwesomeIcon icon={faPlus} className='mr-1' />
									{newUser}
								</Button>
								:
								null
						}
						<Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
							<Table striped hover size='sm'>
								<thead>
									<tr ref={creditLabelsRefContainer}>
										<th className='text-center' width="30%">{lastNameName}</th>
										<th className='text-center' width="30%">{userEmail}</th>
										<th className='text-center' width="20%">{group}</th>
										<th className='text-center' width="10%">{accountValidated}</th>
										<th className='text-center' width="10%">{actions}</th>
									</tr>
									<tr className='secondary'>
										<th className='text-center' colSpan='2'>
											<FormControl
												size='sm'
												type='text'
												name='userQuery'
												placeholder={filterUser}
												ref={register}
											/>
										</th>

										<th className='text-center align-middle'>
											<FormControl
												as="select"
												name='profileQuery'
												className="text-black-color"
												size='sm'
												ref={register}
												title="Filtrar grupo"
												value={selectedProfile}
												onChange={(e) => setSelectedProfile(e.target.value)}
											>
												<option className='text-black-color' value={''}>
													Filtrar...
												</option>
												{profileNameArrayRecords?.map(profile =>
													<option key={profile.id} value={profile.id}>
														{profile.name}
													</option>)
												}
											</FormControl>
										</th>

										<th></th>

										<th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color"
													toolTipText="Filtrar"
													icon={faFilter}
													type='submit'
												/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													type='reset'
													onClick={() => clearFilters()}
												/>
											</div>
										</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										hasuserListArrayRecords ?
											userListOrder?.map(user => <tr key={user.id}>
												<td className='text-center'>
													{buildFullName(user.name)}
												</td>
												<td className='text-center'>
													{user.email}
												</td>
												<td className='text-center'>
													{user.profileName}
												</td>
												<td className='text-center'>
													{
														user.valid
															?
															<OverlayTrigger
																placement='top'
																overlay={
																	<Tooltip>
																		Validada
																	</Tooltip>
																}>
																<FontAwesomeIcon className='text-success' size='lg' icon={faCheck} />
															</OverlayTrigger>
															:
															<OverlayTrigger
																placement='top'
																overlay={
																	<Tooltip>
																		Pendiente
																	</Tooltip>
																}>
																<FontAwesomeIcon className='text-warning' size='lg' icon={faClock} />
															</OverlayTrigger>
													}
												</td>
												<td className='d-flex justify-content-around'>
													{
														usersPermissions?.canView
															?
															<ActionIcon size='lg' id='details' toolTipText='Ver detalles' icon={faSearch} onClick={() => onClickSeeDetails(user)} />
															:
															null
													}
													{
														usersPermissions?.canEdit
															?
															<ActionIcon size='lg' id='search' toolTipText='Editar' icon={faEdit} onClick={() => onClickEdit(user)} />
															:
															null
													}
													{
														usersPermissions?.canEdit
															?
															<ActionIcon size='lg' id='reset' toolTipText='Resetear contraseña' icon={faRecycle} onClick={() => onClickReset(user)} />
															:
															null
													}
													{
														usersPermissions?.canEdit && !user.valid
															?
															<ActionIcon size='lg' id='resend' toolTipText='Reenviar e-mail de validación' icon={faEnvelope} onClick={() => onClickResend(user)} />
															:
															null
													}
													{
														usersPermissions?.canDelete
															?
															<ActionIcon size='lg' id='delete' toolTipText='Borrar' icon={faTrash} onClick={() => onClickDelete(user)} />
															:
															null
													}
												</td>
											</tr>)
											:
											<tr>
												<td colSpan={colSpanCellsNumber} className='text-center'>
													{
														!listUserIsFetching &&
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															{noUser}
														</Alert>
													}
												</td>
											</tr>
									}

								</tbody>
							</Table>
						</Form>
						{
							(hasuserListArrayRecords && !listUserIsFetching) &&
							<RCPagination
								activePage={page}
								itemsCountPerPage={perPage}
								totalItemsCount={totalItems}
								totalItems={totalItems}
								thePage={page}
								onChange={onPageChange}
								innerClass="justify-content-center"
							/>
						}
					</Card.Body>
				</Card>
			</Container>
			<AppLoading isLoading={listUserIsFetching} />
	</>;
};

export default UsersListingPage;