import React from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import StepIndicator from 'src/components/common/StepIndicator';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { parseIntOrUndefined } from 'src/utils/utils';
import { DISAFFECTATIONS_STEP_ONE, DISAFFECTATIONS_STEP_THREE } from 'src/utils/constants';

const BalanceIncreaseStepTwo = () => {
	const dispatch = useDispatch();
	const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
	const administrativeDocumentData = { ...administrativeDocument, organizationCode: parseIntOrUndefined(administrativeDocument?.organizationCode) };

	const onClickBackButton = () => {
		dispatch(push(DISAFFECTATIONS_STEP_ONE));
	};

	const onClickNextStepButton = () => {
		dispatch(push(DISAFFECTATIONS_STEP_THREE));
	};

	return <Container fluid >
		<Card className='mb-5'>
			<Card.Header className='d-flex justify-content-between'>
                <h1 className="h6 mt-1 mb-0">Desafectacion o incrementos de Saldos</h1>
            </Card.Header>
			<StepIndicator steps={config.appSettings.steps.balanceTransfer} current={1} className='mt-2 mb-3' />
			<Card.Header className='h6 mx-3'>
				Desafectacion o incrementos de Saldos
			</Card.Header>
			<Card.Body className="mx-3">
				<p>{administrativeDocumentData?.organizationCode}/{administrativeDocumentData?.number} - {administrativeDocumentData?.year}</p>
				<div className='container-fluid py-4 d-flex justify-content-around'>
					<Button
						className='text-white-color btn-primary btn-lg'
						onClick={onClickBackButton}
					>
						<FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
						Volver
					</Button>
					<Button
						className='text-white-color btn-success btn-lg'
						onClick={onClickNextStepButton}
					>
						Siguiente
						<FontAwesomeIcon className='ml-3' icon={faArrowRight} />
					</Button>
				</div>
			</Card.Body>
		</Card>
	</Container>;
};

export default BalanceIncreaseStepTwo;
