import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { getListAllServiceAdministrativeByUserData } from "src/redux/administrativeService/administrativeServiceReducer";
import { getSelectedFundRequestType } from 'src/redux/fundRequests/fundRequestsReducer';
import { getOrderPayListIsFetching, getOrderPayListData } from 'src/redux/orderPay/orderPayReducer';
import { tryListOrderPay } from 'src/redux/orderPay/orderPayActionCreator';
import { clearListOrderPayData } from 'src/redux/orderPay/orderPayActions'
import PayOrderListBox from 'src/components/forms/fundRequest/payOrderListBox';
import PFCommons from 'src/components/forms/fundRequest/PDForms/controls/PFCommons';
import AppLoading from 'src/components/common/AppLoading';
import { tryOrderPayById } from 'src/redux/orderPay/orderPayActionCreator';
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const NewHaberesFundRequestForm = () => {
	const dispatch = useDispatch();

	//Parent Form Controller
    const { admServiceId } = useFormContext();

	//Data getter to send by props
	let dataRecords = useSelector(state => getOrderPayListData(state))?.records;
	const metaData = useSelector(state => getOrderPayListData(state))?.metadata;
	const [ordenes, setOrdenes] = useState(dataRecords); 

	const selectedFundRequestType = useSelector(state => getSelectedFundRequestType(state));
	const checkToVisualize = selectedFundRequestType === 'PEDIDO_DE_FONDOS';
	const isFetching = useSelector(state => getOrderPayListIsFetching(state));

	const administrativeServiceData = AdministrativeServiceData();

	useEffect(() => {
		dispatch(clearListAllAdministrativeServicesData());
	}, [admServiceId]);

	useEffect(() => {
		if (!dataRecords?.length) {
			setOrdenes([]);
			return;
		};
		//Bulk request para obtener los budgetTypes de las OPs necesario para habilitar las acciones
		//Pendiente mejora en BE para evitar esta practica
		const allPromises = [];
		dataRecords.forEach( op => {
			allPromises.push( dispatch( tryOrderPayById(op.id) ) );
		});
		Promise.all( allPromises ).then((values) => {
		  setOrdenes(values);
		  dataRecords = [];
		});

	}, [dataRecords]);

	return <>
		<PFCommons
			administrativeServiceData={administrativeServiceData}
			admServiceId={admServiceId}
			checkToVisualize={checkToVisualize}
			isFetching={isFetching}
		/>

		<PayOrderListBox
			selectedFundRequestType={selectedFundRequestType}
			isFetching={isFetching}
			tryMethod={tryListOrderPay}
			clearMethod={clearListOrderPayData}
			metaData={metaData}
			ordenes={ordenes}
			dataRecords={dataRecords}
		/>

		<AppLoading isLoading={isFetching} />
	</>

};

export default NewHaberesFundRequestForm;