import React from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { serviceLabel, jurisdiction, unidOrgAbbreviation, characterLabel, account, purpose, functio, creditInitial, creditPartial, subcode, creditBalance, preventiveBalance, finalBalance, saldoObligaciondePago, paymentBalance, creditCurrent, code } from 'src/utils/label';
import { formatterPeso, numberNegativeRed } from 'src/utils/utils';

const DetailModal = (props) => {

    const {show, handleClose, dataModal } = props;

    return <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">

        <Modal.Header closeButton>
			<Modal.Title>DETALLE DE LA PARTIDA PRESUPUESTARIA</Modal.Title>
		</Modal.Header>

		<Modal.Body>
			<Table striped borderless hover className="shadow-sm p-2 mb-1 bg-white rounded text-black-color" >
				<tbody>
					<tr>
						<td className='font-weight-bold text-left'>
							{serviceLabel}:
						</td>
						<td className='text-left truncate maxTruncate' title={dataModal?.code?.credit?.service?.name}>
							{dataModal?.code?.credit?.service?.number} - {dataModal?.code?.credit?.service?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{jurisdiction}:
						</td>
							<td className='text-left'>
							{dataModal?.code?.credit?.organization?.jurisdiction?.number} - {dataModal?.code?.credit?.organization?.jurisdiction?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{unidOrgAbbreviation}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.credit?.organization?.number} - {dataModal?.code?.credit?.organization?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{characterLabel}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.credit?.character?.code} - {dataModal?.code?.credit?.character?.name}
						</td>
					</tr>
					{
						dataModal?.code?.credit?.account?.number > 0
							?
							<tr className='text-left'>
								<td className='font-weight-bold'>
									{account}:
								</td>
								<td sm={8}>
									{`${dataModal?.code?.credit?.account?.number}  - ${dataModal?.code?.credit?.account?.name}`}
								</td>
							</tr>
							:
							null
					}
					<tr>
						<td className='font-weight-bold text-left'>
							{purpose}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.credit?.functionality?.purpose?.number} - {dataModal?.code?.credit?.functionality?.purpose?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{functio}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.credit?.functionality?.number} - {dataModal?.code?.credit?.functionality?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{creditInitial}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.credit?.partialBudget?.principalBudget?.number} - {dataModal?.code?.credit?.partialBudget?.principalBudget?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{creditPartial}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.credit?.partialBudget?.number} - {dataModal?.code?.credit?.partialBudget?.name}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{code}:
						</td>
						<td className='text-left'>
							{dataModal?.code?.number ? (
								`${dataModal?.code?.number} - ${dataModal?.code?.name}`
							) :	(
								<>
									-
								</>
							)
							}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{subcode}:
						</td>
						<td className='text-left'>
							{dataModal?.number ? (
								dataModal?.number == '00' ? (
								`${dataModal?.number} - ${dataModal?.visibleName}`
							) : (
								`${dataModal?.number} - ${dataModal?.name}` )
							) :	(
								<>
									-
								</>
							)
							}
						</td>
					</tr>
						<tr>
							<td className='font-weight-bold text-left'>
								{creditCurrent}:
						</td>
							<td className={`${numberNegativeRed(dataModal?.credit?.currentCredit)} text-right`} sm={4}>
								{formatterPeso.format(dataModal?.credit?.currentCredit)}
							</td>
						</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{creditBalance}:
						</td>
						<td className={`${numberNegativeRed(dataModal?.balances?.currentBalance || dataModal?.balance?.currentBalance)} text-right`}>
							{formatterPeso.format(dataModal?.balances?.currentBalance || dataModal?.balance?.currentBalance) }
							{/*balances recupera los datos actualizados de la partida, es utilizada en los detalles en paso 4*/}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{preventiveBalance}:
						</td>
						<td className={`${numberNegativeRed(dataModal?.balances?.totals?.preventive)} text-right`}>
							{
								!isNaN(dataModal?.balances?.totals?.preventive)
									?
										formatterPeso.format(dataModal?.balances?.totals?.preventive)
									:
										'$ 0,00'
							}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{finalBalance}:
						</td>
						<td className={`${numberNegativeRed(dataModal?.balances?.totals?.definitive)} text-right`}>
							{
								!isNaN(dataModal?.balances?.totals?.definitive)
									?
										formatterPeso.format(dataModal?.balances?.totals?.definitive)
									:
										'$ 0,00'
							}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{saldoObligaciondePago}:
						</td>
						<td className={`${numberNegativeRed(dataModal?.balances?.totals?.payment_obligation)} text-right`}>
							{
								!isNaN(dataModal?.balances?.totals?.payment_obligation)
									?
										formatterPeso.format(dataModal?.balances?.totals?.payment_obligation)
									:
										'$ 0,00'
							}
						</td>
					</tr>
					<tr>
						<td className='font-weight-bold text-left'>
							{paymentBalance}:
						</td>
						<td className={`${numberNegativeRed(dataModal?.balances?.totals?.payment)} text-right`}>
							{
								!isNaN(dataModal?.balances?.totals?.payment)
									?
										formatterPeso.format(dataModal?.balances?.totals?.payment)
									:
										'$ 0,00'
							}
						</td>
					</tr>
				</tbody>
			</Table>
		</Modal.Body>
        
		<Modal.Footer>
			<Button variant="secondary" onClick={handleClose}>
				Cerrar
            </Button>
		</Modal.Footer>

    </Modal>
}

export default DetailModal;