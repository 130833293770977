import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { required, editExpedient, expedient} from 'src/utils/label';

import { tryEditExpedient } from 'src/redux/expedient/expedientActionCreator';
import { getExpedientToEdit } from 'src/redux/expedient/expedientReducer';
import ExpedientForm  from 'src/components/forms/expedient/ExpedientForm';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const ExpedientEditPage = props => {

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

	// Period
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const periodId = periodSelected?.id;

	// Expedient to edit data
	const expedientToEdit = useSelector(state => getExpedientToEdit(state));
	Object.assign(hookFormMethods, {expedientToEdit});

	const onFormSubmit = data => {
		
		let title = data?.title.trim();
		let id = parseInt(data?.id);

		const params = {
			title: title,
			id: id,
			period_id: periodId
		}

		dispatch(tryEditExpedient(params));
	};

	return <Container fluid className='mb-5'>
		<Card>

			<PageTitle text={expedient} />

			<Container fluid>
				<Card className='mb-3'>

					<Card.Header>
						<h6 className='mb-0'>{editExpedient}</h6>
					</Card.Header>

					<Card.Body >

						<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>
								</h6>
							</Col>

							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
						</Row>

						<FormProvider {...hookFormMethods} >
							<Form onSubmit={handleSubmit(onFormSubmit)} className='text-black-color mt-5 mb-5'>
								
								<ExpedientForm />

							</Form>
						</FormProvider>
						
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ExpedientEditPage;