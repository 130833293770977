import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';
import { EXPEDIENT_LIST } from 'src/utils/constants';

import FormFieldError from 'src/components/general/FormFieldError';
import { expedient, cancel, save, business, } from 'src/utils/label';
import { getExpedientEditIsFetching } from 'src/redux/expedient/expedientReducer';

const ExpedientForm = props => {

	const dispatch = useDispatch();
	const { register, errors, expedientToEdit } = useFormContext();

	const expedientEditIsFetching = useSelector(state => getExpedientEditIsFetching(state));

	//validations
	const messageExpedientRequiredMsg = 'Debe ingresar el asunto.';

	let maxCharacteres = 255;
	let minCharacteres = 3;
	const messageExpedientValidationObj = {
		required: messageExpedientRequiredMsg,
		maxLength: {
			value: maxCharacteres,
			message: `El asunto no debe superar el máximo de ${maxCharacteres} letras.`
		},
		minLength: {
			value: minCharacteres,
			message: `El asunto debe tener un mínimo de ${minCharacteres} letras.`
		}
	};

	return <>


		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
				{expedient}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					ref={register()}
					type='text'
					placeholder={expedient}
					name='expedient'
					id='expedient'
					disabled={true}
					defaultValue={expedientToEdit?.completeLabel}
				/>
			</Col>
		</Form.Group>

		<Form.Group as={Row} controlId='alias'>
			<Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
				<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
				{business}:
			</Form.Label>
			<Col sm={4}>
				<Form.Control
					ref={register(messageExpedientValidationObj)}
					as='textarea'
					placeholder={business}
					name='title'
					disabled={false}
					defaultValue={expedientToEdit?.title}
					maxLength={maxCharacteres + 1}
					rows={6}
				/>
				<FormFieldError errors={errors?.title} />
			</Col>
		</Form.Group>

		<Form.Control
			ref={register()}
			type='hidden'
			name='id'
			disabled={false}
			defaultValue={expedientToEdit?.id}
		/>

		<div className='d-flex justify-content-around mt-4 mb-3'>
			<Button
				variant='danger'
				size='lg'
				disabled={expedientEditIsFetching}
				onClick={() => dispatch(push(EXPEDIENT_LIST))}
			>
				{cancel}
			</Button>

			<span className={((expedientEditIsFetching) ? '' : 'hidden')}>
				<Spinner className='spinner-border text-danger' animation='border' />
			</span>

			<Button
				type='submit'
				variant='success'
				size='lg'
				disabled={ expedientEditIsFetching }
			>
				{save}
			</Button>
		</div>

	</>
};

export default ExpedientForm;