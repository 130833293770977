import * as ACTIONS from './bankAccountsFundRequestActions';
import * as SERVICES from 'src/services/bankAccountsFundRequestServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetListBankAccountFundRequest = (params = {}) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getListBankAccountsFundRequestRequest(params));
		if (accessToken) {
			const response = await SERVICES.getListBankAccountsFundRequest(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getListBankAccountsFundRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getListBankAccountsFundRequestFailure(response?.error || response));
				showError(`Error al cargar los datos de cuenta bancaria de pedido de fondo. Detalle: ${response?.response?.data?.message || response?.error || response}`);
			}
			return response
		}

	};

};

//Add New Bank account
export const tryAddNewBankAccount = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.addBankAccountsRequest());

		if (accessToken) {

			const response = await SERVICES.createBankAccount(accessToken, params);
			if (response?.status == 201) {
				dispatch(ACTIONS.addBankAccountsRequestSuccess(response?.data));
				showSuccess('Se agrego correctamente la cuenta bancaria');
			}
			else {
				dispatch(ACTIONS.addBankAccountsRequestFailure(response?.data?.error));
				showError(`Error al crear la cuenta bancaria. Detalles: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};


//Delete Bank Account
export const tryDeleteBankAccount = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getBankAccountDeleteRequest());

		if (accessToken) {

			const response = await SERVICES.deleteBankAccount(accessToken, params);
			if (response?.status == 204) {
				dispatch(ACTIONS.getBankAccountDeleteRequestSuccess(response?.data));
				showSuccess('Cuenta Bancaria eliminada correctamente.');
			}
			else {
				dispatch(ACTIONS.getBankAccountDeleteRequestFailure(response?.data?.error));
				showError(`Error al eliminar la cuenta bancaria. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};

//detail Bank Account By id
export const tryBankAccountDetailByid = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getBankAccountDetailRequestbyid());

		if (accessToken) {

			const response = await SERVICES.getBankAccountId(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getBankAccountDetailRequestbyidSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getBankAccountDetailRequestbyidFailure(response?.data?.error));
				showError(`Error al traer detalle de la cuenta bancaria. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};


//Edit Bank Account
export const tryEditBankAccount = userData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editBankAccountRequest());

		if (accessToken) {

			const response = await SERVICES.editBankAccount(accessToken, userData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editBankAccountRequestSuccess(response?.data));
				showSuccess('Cuenta Bancaria editada correctamente.');
			}
			else {
				dispatch(ACTIONS.editBankAccountRequestFailure(response?.error || response));
				showError(`Error al editar la cuenta bancaria. Detalle: ${response?.error || response}`);
			}
			return response;
		}

	};

};