import * as ACTION_TYPES from './characterActionTypes';

export const characterReducerInitialState = {
	characterList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const characterReducer = ( state=characterReducerInitialState, action ) => {
	switch( action.type ) {

		case ACTION_TYPES.LIST_CHARACTER_REQUEST: {
			return {
				...state,
				characterList: {
					...state.characterList,
					isFetching: true,
					hasError: false,
					data: characterReducerInitialState.characterList.data,
					error: characterReducerInitialState.characterList.error
				}
			};
		}

		case ACTION_TYPES.LIST_CHARACTER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				characterList: {
					...state.characterList,
					isFetching: false,
					hasError: false,
					error: characterReducerInitialState.characterList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_CHARACTER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				characterList: {
					...state.characterList,
					isFetching: false,
					hasError: true,
					data: characterReducerInitialState.characterList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_CHARACTER_LIST: {
			return {
				...state,
				characterList: characterReducerInitialState.characterList
			};
		}

		default: {
			return state;
		}
	}
};

export const getCharacterReducer = state => state.characterReducer;

export const getCharacterList = state => getCharacterReducer(state)?.characterList;
export const getCharacterListData = state => getCharacterList(state)?.data;
export const getCharacterListIsFetching = state => getCharacterList(state)?.isFetching;
export const getCharacterListHasError = state => getCharacterList(state)?.hasError;
export const getCharacterListError = state => getCharacterList(state)?.error;
