import * as ACTION_TYPES from './sectionActionTypes';

export const sectionReducerInitialState = {
	sectionList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const sectionReducer = ( state=sectionReducerInitialState, action ) => {
	switch( action.type ) {

		case ACTION_TYPES.LIST_SECTION_REQUEST: {
			return {
				...state,
				sectionList: {
					...state.sectionList,
					isFetching: true,
					hasError: false,
					data: sectionReducerInitialState.sectionList.data,
					error: sectionReducerInitialState.sectionList.error
				}
			};
		}

		case ACTION_TYPES.LIST_SECTION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				sectionList: {
					...state.sectionList,
					isFetching: false,
					hasError: false,
					error: sectionReducerInitialState.sectionList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_SECTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				sectionList: {
					...state.sectionList,
					isFetching: false,
					hasError: true,
					data: sectionReducerInitialState.sectionList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_SECTION_LIST: {
			return {
				...state,
				sectionList: sectionReducerInitialState.sectionList
			};
		}

		default: {
			return state;
		}
	}
};

export const getSectionReducer = state => state.sectionReducer;

export const getSectionList = state => getSectionReducer(state)?.sectionList;
export const getSectionListData = state => getSectionList(state)?.data;
export const getSectionListIsFetching = state => getSectionList(state)?.isFetching;
export const getSectionListHasError = state => getSectionList(state)?.hasError;
export const getSectionListError = state => getSectionList(state)?.error;