import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';

import PageTitle from 'src/components/general/PageTitle';
import { SERVICE_ADMINISTRATIVE_LIST } from 'src/utils/constants';
import { isNotEmptyArray } from 'src/services/validationService';

import { getAdministrativeServiceOrganism, getAdministrativeServiceOrganismListData, getAdministrativeServiceOrganismListIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryGetOrganismAdministrativeServices, tryAddOrganismAdministrativeService, tryRemoveOrganismAdministrativeService } from 'src/redux/administrativeService/administrativeServiceActionCreator';

import { tryListAdministrativeOrganism } from 'src/redux/AdministrativeOrganism/administrativeOrganismActionCreator';
import { getAdministrativeOrganismListData, getAdministrativeOrganismListIsFetching } from 'src/redux/AdministrativeOrganism/administrativeOrganismReducer';
import { clearAdministrativeOrganismList } from 'src/redux/AdministrativeOrganism/administrativeOrganismActions';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const ServiceAdministrativeOrganismPage = props => {

	const dispatch = useDispatch();

	// Exercise
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	//OrganismAdminstrativeServices
	const serviceAdministrativeOrganism = useSelector(state => getAdministrativeServiceOrganism(state));

	// Organism All
	const organismAllList = useSelector(state => getAdministrativeOrganismListData(state));
	const hasOrganismAllList = useSelector(state => getAdministrativeOrganismListData(state))?.records;
	const organismListMetadata = organismAllList?.metadata;
	const totalListRecords = organismListMetadata?.total;
	const hasMoreorganism = ( organismListMetadata?.total > ( (organismListMetadata?.page+1) * organismListMetadata?.pageSize ) );


	// List Organism
	const servicesOrganismList = useSelector(state => getAdministrativeServiceOrganismListData(state));
	const hasServicesOrganismList = isNotEmptyArray(servicesOrganismList);
	const servicesOrganismListIsFetching = useSelector(state => getAdministrativeServiceOrganismListIsFetching(state));

	// Find List Users Total Not Asignated
	const listOrganismRecords = organismAllList?.records?.filter(value => {
		return !servicesOrganismList?.find(value2 => value.id == value2.id);
	});

	const haslistOrganismRecords = isNotEmptyArray(hasOrganismAllList);
	const listOrganismRecordsIsFetching = useSelector(state => getAdministrativeOrganismListIsFetching(state));

	const loadAllData = () => {
		dispatch(tryGetOrganismAdministrativeServices(serviceAdministrativeOrganism.id, globalSelectedPeriod?.id)).then(
			response => { 
				const params = {
					page: 0,
					size: 10
				};
				dispatch(tryListAdministrativeOrganism(params)); 
			}
		);
		dispatch(clearAdministrativeOrganismList());
	}

	useEffect(loadAllData, []);

	const onClickLoadMore = () => {
		const params = {
			page: organismListMetadata?.page,
			size: totalListRecords  
		};
		dispatch( tryListAdministrativeOrganism(params) );
	};
	
	const onClickLoadLess = () => {
		const params = {
			page: organismListMetadata?.page,
			size: 10
		};
		dispatch( tryListAdministrativeOrganism(params) );
	};

	const onClickBackButton = () => {
		dispatch(push(SERVICE_ADMINISTRATIVE_LIST));
	};

	const onClickAddOrganismAdministrativeService = value => {
		const params = [value.id]
		dispatch(tryAddOrganismAdministrativeService(serviceAdministrativeOrganism?.id, globalSelectedPeriod?.id, params)).then(response => {
			if (response.status == 201) {
				loadAllData();
			}
		});
	};

	const onClickRemoveOrganismAdministrativeService = item => {
		const params = [item.id]
		dispatch(tryRemoveOrganismAdministrativeService(serviceAdministrativeOrganism.id, globalSelectedPeriod?.id, params)).then(response => {
			loadAllData();
		});
	};

	return <Container className='mb-5'>
		<Card>
			<PageTitle text='Servicios' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Gestión de organismos de: {serviceAdministrativeOrganism.name}
					</Card.Header>
					<Card.Body>
						<p className='text-black-color h6' >
							Organismos disponibles:
						</p>
						<Table className='mb-0' striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Código</th>
									<th className='text-center'>Nombre</th>
									<th className='text-center'>Acciones</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{
									haslistOrganismRecords
										?
										listOrganismRecords?.map(item =>
											<>
												<tr>
													<td className='text-center'>{item?.jurisdiction?.code} - {item?.codeBudget}</td>
													<td className='text-center'>{`${item?.name} (${item?.jurisdiction?.name}) (${item?.organismClassifier?.shortName})`}</td>
													<td className='text-center'> <ActionIcon size='lg' icon={faPlusCircle} id='addorganism' toolTipText='Asignar organismo' className='mr-2' onClick={() => onClickAddOrganismAdministrativeService(item)} /></td>
												</tr>
											</>)
										:
										<tr>
											<td colSpan='6' className='text-center'>
												{
													listOrganismRecordsIsFetching ?
														<Spinner animation='border' variant='success' className='my-3' />
														:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
												No hay registros
											</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>

						{
							hasMoreorganism ?
							<Row className='mt-2'>
								<Col className='text-center'>
											<Button size='sm' variant="danger" className='px-5' disable={listOrganismRecordsIsFetching} onClick={onClickLoadMore}>
												{
													listOrganismRecordsIsFetching ?
														<Spinner animation='border' size='sm'/>
														:
														<>{'Ver más'}</>
												}
											</Button>
								</Col>
							</Row>
								:
                            <Row className='mt-2' >
								<Col className='text-center'>
											<Button size='sm' variant="danger" className='px-5' disable={listOrganismRecordsIsFetching} onClick={onClickLoadLess}>
												{
													listOrganismRecordsIsFetching ?
														<Spinner animation='border' size='sm'/>
														:
														<>{'Ver menos'}</>
												}
											</Button>
								</Col>
							</Row>
						}

						<hr />

						<p className='text-black-color h6' >
							Organismos asignados:
						</p>
						<Table className='mb-0' striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Código</th>
									<th className='text-center'>Nombre</th>
									<th className='text-center'>Acciones</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{
									hasServicesOrganismList ?
										servicesOrganismList?.map(item =>
											<>
												<tr>
													<td className='text-center'>{item?.jurisdiction?.code} - {item?.codeBudget}</td>
													<td className='text-center'>{`${item?.name} (${item?.jurisdiction?.name}) (${item?.organismClassifier?.shortName})`}</td>
													<td className='text-center'> <ActionIcon size='lg' icon={faTimesCircle} id='removeorganism' toolTipText='Quitar organismo' className='mr-2 text-danger' onClick={() => onClickRemoveOrganismAdministrativeService(item)} /></td>
												</tr>
											</>)
										:
										<tr>
											<td colSpan='6' className='text-center'>
												{
													servicesOrganismListIsFetching ?
														<Spinner animation='border' variant='success' className='my-3' />
														:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
												No hay registros
											</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>

						<div className='text-center'>
							<Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>

					</Card.Body>
				</Card>

			</Container>
		</Card>
	</Container>;
};

export default ServiceAdministrativeOrganismPage;