import * as ACTION_TYPES from './bankAccountActionTypes';

export const bankAccountReducerInitialState = {
	list: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};

export const bankAccountReducer = ( state=bankAccountReducerInitialState, action ) => {
	switch( action.type ) {
		//User
		case ACTION_TYPES.BANK_ACCOUNT_LIST_REQUEST: {
			return {
				...state,
				list: {
					...state.list,
					isFetching: true,
					hasError: false,
					error: bankAccountReducerInitialState.list.error
				}
			};
		}

		case ACTION_TYPES.BANK_ACCOUNT_LIST_REQUEST_SUCCESS: {
			const { data } = action.payload;

			const previousRecords = state.list.data?.records;

			if( previousRecords ) {
				data.records = previousRecords.concat( data.records );
			}
		
			return {
				...state,
				list: {
					...state.list,
					isFetching: false,
					hasError: false,
					error: bankAccountReducerInitialState.list.error,
					data
				}
			};
		}

		case ACTION_TYPES.BANK_ACCOUNT_LIST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				list: {
					...state.list,
					isFetching: false,
					hasError: true,
					data: bankAccountReducerInitialState.list.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_BANK_ACCOUNT_LIST_DATA: {
			return {
				...state,
				list: {
					...bankAccountReducerInitialState.list
				}
			};
		}

		default: {
			return state
		}
	}
};

export const getBankAccountReducer = state => state.bankAccountReducer;


// User list
export const getBankAccountList = state => getBankAccountReducer(state)?.list;
export const getBankAccountListData = state => getBankAccountList(state)?.data;
