import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Col, Row, Button, Modal, Table } from 'react-bootstrap';
import { selectOption } from 'src/utils/label';
import { NumericFormat } from 'react-number-format';
import { getRecruitmentMethodsData } from 'src/redux/wageTemplate/wageTemplateReducer';
import { tryGetRecruitmentMethod } from 'src/redux/wageTemplate/wageTemplateActionCreator';

const DetailModal = (props) => {

    const dispatch = useDispatch();

    const { showPayOrderDetailModal, setShowPayOrderDetailModal, activePayOrder, selectedOrderPay } = props;

    const handleCloseModal = (showModalValue) => {
        return setShowPayOrderDetailModal === showModalValue;
    };

    const total = selectedOrderPay?.wageManager?.wageManagerDetails.reduce((accum, item) => accum + item?.subtotalAmount, 0);

	const recruitmentMethods = useSelector(state => getRecruitmentMethodsData(state));

    useEffect(() => {
        dispatch(tryGetRecruitmentMethod());
    }, []);

    return <>
        <Modal
            show={showPayOrderDetailModal}
            onHide={handleCloseModal}
            scrollable={true}
            dialogClassName="modal-xl"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>Detalle de Liquidación de Haberes</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form autoComplete='off'>
                    <Form.Group as={Row}>
                        <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                            Método de contratación:
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                as="select"
                                name="methodId"
                                defaultValue={selectedOrderPay?.wageManager?.wageLiquidationType}
                                className={"text-black-color"}
                                disabled={true}
                            >
                                <option value={''}>{selectOption}</option>
                                {
                                    recruitmentMethods?.map((item, idx) => (
                                        <option value={item} key={idx}>
                                            {item.replace(/_/g, ' ')}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                            Plantilla de Haberes:
                        </Form.Label>
                        <Col sm={4}>
                            <Form.Control
                                as="select"
                                name="wageTemplate"
                                defaultValue={selectedOrderPay?.wageManager?.name}
                                className={"text-black-color"}
                                disabled={true}
                            >
                                <option value={'selectedOrderPay?.wageManager?.name'}>{selectedOrderPay?.wageManager?.name}</option>
                            </Form.Control>

                        </Col>
                    </Form.Group>

                    <hr color="black" className='' />
                    {
                        <>
                            <Table striped bordered hover size='sm'>
                                <thead>
                                    <tr>
                                        <th className='text-center' style={{ "width": "30%" }}>Concepto</th>
                                        <th className='text-center' style={{ "width": "10%" }}>Código</th>
                                        <th className='text-center' style={{ "width": "15%" }}>Importe Período</th>
                                        <th className='text-center' style={{ "width": "20%" }}>Modificaciones</th>
                                        <th className='text-center' style={{ "width": "20%" }}>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody className='text-black-color'>
                                    {selectedOrderPay?.wageManager?.wageManagerDetails.map((item, index) =>
                                    (
                                        <tr key={item?.id}>
                                            <td className='text-center'>
                                                {item?.concept + "-" + item?.subConcept + "-" + item?.item}
                                            </td>
                                            <td className='text-center'>
                                                {item?.code}
                                            </td>
                                            <td className='text-center'>
                                                <div className='input-group mb-2'>
                                                    <NumericFormat
                                                        prefix={'$'}
                                                        className='form-control text-right'
                                                        decimalScale={2}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        inputMode='numeric'
                                                        value={item?.periodAmount}
                                                        defaultValue={item?.periodAmount}
                                                        allowLeadingZeros={false}
                                                        isAllowed={(inputObj) => withValueLimitImports(inputObj)}
                                                        onValueChange={(inputObject) => onChangeImportAmount(inputObject, item?.id)}
                                                        readOnly
                                                    />
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                <div className='input-group mb-2'>
                                                    <NumericFormat
                                                        prefix={'$'}
                                                        className='form-control text-right'
                                                        decimalScale={2}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        inputMode='numeric'
                                                        value={item?.changeAmount}
                                                        defaultValue={item?.changeAmount}
                                                        allowLeadingZeros={false}
                                                        readOnly
                                                    />
                                                </div>
                                            </td>
                                            <td className={`text-right`}>
                                                <div className='input-group mb-2'>
                                                    <NumericFormat
                                                        prefix={'$'}
                                                        className='form-control text-right'
                                                        decimalScale={2}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}
                                                        inputMode='numeric'
                                                        value={item?.subtotalAmount}
                                                        defaultValue={item?.subtotalAmount}
                                                        allowLeadingZeros={false}
                                                        readOnly
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    )
                                    }
                                </tbody>
                            </Table>
                        </>

                    }
                    {
                        <div className='d-flex align-items-center mb-3'>
                            <p className='h6 text-black-color'>

                            </p>
                            <span className='ml-auto mr-0'>
                                <span className='text-black-color mr-3'>Total: </span>
                                <NumericFormat
                                    prefix={'$'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={2}
                                    value={total}
                                    defaultValue={0}
                                    className={'text-black-color text-right'}
                                    readOnly
                                />
                            </span>
                        </div>
                    }
                    <hr color="black" className='' />

                    <Form.Group as={Row}>
                        <Form.Label className='text-black-color pr-0 d-flex mandatory-label' column sm={3}>
                            <b>Importe Líquido:</b>
                        </Form.Label>
                        <Col sm={4} className='ml-0'>
                            <div className='input-group mb-2'>
                                <NumericFormat
                                    prefix={'$'}
                                    className='form-control text-right'
                                    decimalScale={2}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    inputMode='numeric'
                                    allowLeadingZeros={false}
                                    allowNegative={false}
                                    value={selectedOrderPay?.wageManager?.netAmount}
                                    readOnly
                                />
                            </div>

                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col sm={3} className='d-flex mandatory-label'>
                            <span className='text-black-color'><b>Total General:</b></span>
                        </Col>
                        <Col sm={4} className='ml-0'>
                            <NumericFormat
                                prefix={'$'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                allowLeadingZeros={false}
                                decimalScale={2}
                                value={selectedOrderPay?.wageManager?.generalAmount}
                                className={'form-control text-right'}
                                readOnly
                            />
                        </Col>
                    </Form.Group>
                    <div className='d-flex justify-content-around mt-4 mb-3'>
                        <Button type='button' variant='primary' size='lg' onClick={() => setShowPayOrderDetailModal(false)}>
                            Cerrar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    </>
}

export default DetailModal