import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from "react-multi-select-component";
import 'react-widgets/styles.css';
import { config } from 'src/env.js';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';

import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportWorksBudgetAnalyticalRecordCreditExecution } from 'src/redux/reports/reportsActionCreactor';
import { getReportWorksBudgetAnalyticalRecordCreditExecutionIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportWorksBudgetAnalyticalRecordCreditExecution } from 'src/redux/reports/reportsActions';
import { getListAllServiceAdministrativeByUserData, getListAllServiceAdministrativeByUserIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getEmail } from 'src/redux/login/loginReducer';
import { push } from 'connected-react-router';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import { clearListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActions';
import RangeDates from 'src/components/common/RangeDates';
import { shortlistedService } from 'src/utils/utils';

const ReportWorksBudgetAnalyticalRecordCreditExecutionPage = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	//User Id
	const email = useSelector( state => getEmail(state) );
	const listUserData = useSelector(state => getUserListData (state));
	const userId = listUserData?.records?.find(item => item.email == email);
	const [showMsgReportInProcess, setShowMsgReportInProcess] = useState(false);
	const reportDates = useSelector( state => getReportDatesData(state) );
	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	//Services Administrative
	const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

	const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);	
	const [dateTo, setDateTo] = useState(reportDates?.dateTo);

	const [dateHasErrors, setDateHasErrors] = useState(false);

	useEffect(() => {
		dispatch(tryGetUserList());
		dispatch(clearReportWorksBudgetAnalyticalRecordCreditExecution());
		dispatch(clearListAllServiceAdministrativeByUser());
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
	}, [globalSelectedPeriod]);

	const getReport = outputFormat => {

		let subCodeType = config?.appSettings?.SETTINGS_REPORTS?.subCodeType;
		
		if (globalSelectedPeriod) {
			setShowMsgReportInProcess(false);
			const params = {
				outputFormat,
				exerciseId: globalSelectedPeriod?.id,
				serviceIds: selected?.map(administrativeService => administrativeService?.value),
				dateFrom,
				dateTo,
				subCodeType
			};
			dispatch(tryGetReportWorksBudgetAnalyticalRecordCreditExecution(params)).then( response => {
				if (response?.status == 200 && outputFormat === 1) {
					setShowMsgReportInProcess(true);
				}
			});
		}

	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	//// Multiselect
	// View items selector
	const [options, setOptions] = useState([]);

	useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

	useEffect(() => {
		const uniqueService = shortlistedService(options);
		if(uniqueService){
			setSelected(options);
		}
	}, [options]);

	// Add options
	const [selected, setSelected] = useState([]);
	
	// Idiom Spanish
	const idiom = 	{
					"selectSomeItems": "Seleccionar una o más opciones...",
					"allItemsAreSelected": "Todos los elementos están seleccionados.",
					"selectAll": "Seleccionar todo",
					"search": "Buscar",
					"clearSearch": "Limpiar búsqueda."
					}

	// Load data 	
	const customValueRenderer = (selected) => {
		return selected.length
			? selected.map(({ label }) => " ✔️" + label)
			: "No hay ítems seleccionados...";
		};
	const hasValueCustomValueRenderer = (selected.length === 0);		
	//// END Multiselect	
	
	// Reports redux
	const reportIsFetching = useSelector(state => getReportWorksBudgetAnalyticalRecordCreditExecutionIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && dateFrom && dateTo && !hasValueCustomValueRenderer && !dateHasErrors );

	return <Container fluid>
		<Card className='mb-5'>
			<PageTitle text='Reporte de registro analítico de presupuesto de obras' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>

										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
											
										/>
									</Form.Group>

									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<MultiSelect
											className='text-black-color'
											options={options}
											value={selected}
											onChange={setSelected}
											labelledBy={"Select"}
											overrideStrings={idiom}
											valueRenderer={customValueRenderer}
											hasSelectAll={true}
											isLoading={administrativeServiceIsFetching}
											ClearSelectedIcon={"🧹Limpiar"}
										/>
									
									</Form.Group>

									<FormProvider {...hookFormMethods}>
										<RangeDates {...{dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors}} />
									</FormProvider>

									{
										showMsgReportInProcess &&
										<Alert key={'1'} variant={'primary'} className='mt-4'>
											<b className='text-black-color' >Aviso:</b> El reporte se esta generando en segundo plano y estará disponible en el módulo de 
											<a  className='font-italic text-info cursor-pointer' 
												onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
												&nbsp;reportes generados.
											</a>
										</Alert>
									}
									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Generar PDF
										</Button>
										{
											reportIsFetching
											&&
											<Spinner animation='border' />
										}
										<div></div>
										{/* TODO: se descomentara esta linea para hacer efectiva la funcionalidad en la release v1.1.0
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Ver XLS
										</Button> */}
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>

						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ReportWorksBudgetAnalyticalRecordCreditExecutionPage;