import * as ACTIONS from './characterActions';
import * as SERVICES from 'src/services/characterServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetCharacterList = ( page=1, filter={}, pageSize=100 ) => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getCharacterListRequest() );

		if(accessToken){

			const response = await SERVICES.listCharacter( accessToken, page, pageSize, filter );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getCharacterListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getCharacterListRequestFailure(response?.error) );
				showError(`Error al cargar los caracteres. Detalle: ${response.error}` );
			}
		}

	};
};