import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';

const MenuAudit = () => {
	const dispatch = useDispatch();
    const { profileName, auditsPermissions, paramsToAlerts } = usePermissions();

    if (!auditsPermissions?.canView) return null;

    return (
        <NavDropdown 
            className='active' 
            title={<><FontAwesomeIcon icon={faCode} className='mr-1'/>Auditoría</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            <NavDropdownItem route={ROUTE_PATHS.AUDITS_LIST} title="Presupuesto" />
            <NavDropdownItem route={ROUTE_PATHS.AUDITS_USERS} title="Usuarios" />
        </NavDropdown>
    );
};

export default MenuAudit;