import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import { serviceLabel } from 'src/utils/label';
import { getListServicesByUserAndExerciseIsFetching } from "src/redux/administrativeService/administrativeServiceReducer";
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const InputServiceForm = ({ formData, handleAdministrativeService }) => {
    const { register, errors } = useFormContext();
    const administrativeServiceData = AdministrativeServiceData()
    const administrativeServiceIsFetching = useSelector(state => getListServicesByUserAndExerciseIsFetching(state))
    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' }
    const serviceRequiredMsg = 'Debe seleccionar un servicio.'
    const serviceValidationObj = { required: serviceRequiredMsg }

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                {serviceLabel}:
            </Form.Label>
            <Col sm='4'>
                <DropdownList
                    filter
                    placeholder='Seleccionar servicio...'
                    data={administrativeServiceData}
                    dataKey='id'
                    allowCreate='onFilter'
                    textField='displayName'
                    value={formData.administrativeServiceId}
                    as='select'
                    className='w-100 text-black-color'
                    onChange={value => handleAdministrativeService(value.id)}
                    messages={messagesDropDown}
                    selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
                    /*searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}*/
                    disabled={administrativeServiceIsFetching}
                >
                </DropdownList>
                <FormFieldError errors={errors?.administrativeServiceId} />
                <Form.Control hidden readOnly ref={register(serviceValidationObj)} name='administrativeServiceId' value={formData.administrativeServiceId} />
            </Col>
        </Form.Group>
    </>
}

export default InputServiceForm