import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAccessToken } from '../../../../redux/login/loginReducer';
import { config } from 'src/env.js';

const API_PRESUPUESTO_BASE_URL_V2 = `${config.apis.presupuestoApi.URL_V2}`;

export const useFetchPeriods = (serviceId, profileNameHTC, globalSelectedPeriod) => {
	const [periods, setPeriods] = useState([]);
	const accessToken = useSelector(getAccessToken);
	useEffect(() => {
		if (profileNameHTC && serviceId) {
			listPeriodsMonthlyHTC(globalSelectedPeriod?.id, serviceId, accessToken)
				.then(data => setPeriods(data.data))
				.catch(error => console.error(error));
		}
	}, [serviceId, profileNameHTC, globalSelectedPeriod, accessToken]);
	return periods;
};

export const listPeriodsMonthlyHTC = async (periodId, serviceId, accessToken) => {
    const URL = `${API_PRESUPUESTO_BASE_URL_V2}/expenses/monthly-periods?periodId=${periodId}&serviceId=${serviceId}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    });
    try {
        const response = await fetch(URL, {
            method: 'GET',
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        };
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};