import * as ACTION_TYPES from './sectorActionTypes';

// Sector list by sectionId actions
export const getSectorsListBySectionIdRequest = sectionId => ({
	type: ACTION_TYPES.LIST_SECTORS_BY_SECTION_ID_REQUEST,
	payload: {
		sectionId
	}
});

export const getSectorsListBySectionIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_SECTORS_BY_SECTION_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getSectorsListBySectionIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SECTORS_BY_SECTION_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearSectorsListBySectionId = () => ({
	type: ACTION_TYPES.CLEAR_LIST_SECTORS_BY_SECTION_ID
});
// END Sector list by sectionId actions