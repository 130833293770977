// GET figurative expeditures request actions
export const GET_FIGURATIVE_EXPEDITURES_REQUEST = 'GET_FIGURATIVE_EXPEDITURES_REQUEST';
export const GET_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS = 'GET_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS';
export const GET_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE = 'GET_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE';
export const CLEAR_FIGURATIVE_EXPEDITURES_REQUEST = 'CLEAR_FIGURATIVE_EXPEDITURES_REQUEST';

// POST figurative expeditures request actions
export const POST_FIGURATIVE_EXPEDITURES_REQUEST = 'POST_FIGURATIVE_EXPEDITURES_REQUEST';
export const POST_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS = 'POST_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS';
export const POST_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE = 'POST_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE';
// export const CLEAR_FIGURATIVE_EXPEDITURES_REQUEST = 'CLEAR_FIGURATIVE_EXPEDITURES_REQUEST';

// POST figurative expeditures request actions
export const PUT_FIGURATIVE_EXPEDITURES_REQUEST = 'PUT_FIGURATIVE_EXPEDITURES_REQUEST';
export const PUT_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS = 'PUT_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS';
export const PUT_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE = 'PUT_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE';

// Set figurative expeditures completeNumber
export const SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER = 'SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER';
export const CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER = 'CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER';

export const SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION = 'SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION';
export const CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION = 'CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION';

export const SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION = 'SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION';
export const DELETED_FIGURATIVE_EXPEDITURES_VINCULATION = 'DELETED_FIGURATIVE_EXPEDITURES_VINCULATION';
export const CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION = 'CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION';

// Edit, figurative expeditures to edit
export const GET_FIGURATIVE_EXPEDITURES_TO_EDIT = 'GET_FIGURATIVE_EXPEDITURES_TO_EDIT';
export const SET_FIGURATIVE_EXPEDITURES_TO_EDIT = 'SET_FIGURATIVE_EXPEDITURES_TO_EDIT';
export const CLEAR_FIGURATIVE_EXPEDITURES_TO_EDIT = 'CLEAR_FIGURATIVE_EXPEDITURES_TO_EDIT';