
// List administrative services
export const LIST_ADMINISTRATIVE_SERVICES_REQUEST = 'LIST_ADMINISTRATIVE_SERVICES_REQUEST';
export const LIST_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'LIST_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const LIST_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'LIST_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';
export const CLEAR_LIST_ADMINISTRATIVE_SERVICES_DATA = 'CLEAR_LIST_ADMINISTRATIVE_SERVICES_DATA';

// List All administrative services
export const LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST = 'LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST';
export const LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';
export const CLEAR_LIST_ALL_ADMINISTRATIVE_SERVICES_DATA = 'CLEAR_LIST_ALL_ADMINISTRATIVE_SERVICES_DATA';

// List administrative service types
export const LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST = 'LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST';
export const LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_SUCCESS = 'LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_SUCCESS';
export const LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_FAILURE = 'LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_FAILURE';
export const CLEAR_LIST_ADMINISTRATIVE_SERVICE_TYPES_DATA = 'CLEAR_LIST_ADMINISTRATIVE_SERVICE_TYPES_DATA';

// Set administrative services
export const SET_DETAIL_ADMINISTRATIVE_SERVICES_DATA = 'SET_DETAIL_ADMINISTRATIVE_SERVICES_DATA';
export const CLEAR_DETAIL_ADMINISTRATIVE_SERVICES_DATA = 'CLEAR_DETAIL_ADMINISTRATIVE_SERVICES_DATA';
export const DETAIL_ADMINISTRATIVE_SERVICES_REQUEST = 'DETAIL_ADMINISTRATIVE_SERVICES_REQUEST';
export const DETAIL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'DETAIL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const DETAIL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'DETAIL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// Set Organism administrative services
export const SET_ORGANISM_ADMINISTRATIVE_SERVICES_DATA = 'SET_ORGANISM_ADMINISTRATIVE_SERVICES_DATA';
export const CLEAR_ORGANISM_ADMINISTRATIVE_SERVICES_DATA = 'CLEAR_ORGANISM_ADMINISTRATIVE_SERVICES_DATA';
export const ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST = 'ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST';
export const ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// List Organism administrative services
export const LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST = 'LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST';
export const LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';
export const CLEAR_LIST_ORGANISM_ADMINISTRATIVE_SERVICES_DATA = 'CLEAR_LIST_ORGANISM_ADMINISTRATIVE_SERVICES_DATA';

// Add Organism administrative services
export const ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST = 'ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST';
export const ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// Remove Organism administrative services
export const REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST = 'REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST';
export const REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';


// Set Users administrative services
export const SET_USERS_ADMINISTRATIVE_SERVICES_DATA = 'SET_USERS_ADMINISTRATIVE_SERVICES_DATA';
export const CLEAR_USERS_ADMINISTRATIVE_SERVICES_DATA = 'CLEAR_USERS_ADMINISTRATIVE_SERVICES_DATA';
export const USERS_ADMINISTRATIVE_SERVICES_REQUEST = 'USERS_ADMINISTRATIVE_SERVICES_REQUEST';
export const USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// Add Users administrative services
export const ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST = 'ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST';
export const ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// Remove Users administrative services
export const REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST = 'REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST';
export const REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

//New
export const CREATE_ADMINISTRATIVE_SERVICE_REQUEST = 'CREATE_ADMINISTRATIVE_SERVICE_REQUEST'
export const CREATE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS = 'CREATE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS'
export const CREATE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE = 'CREATE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE'
export const CLEAR_CREATE_ADMINISTRATIVE_SERVICE = 'CLEAR_CREATE_ADMINISTRATIVE_SERVICE'

//Edit
export const EDIT_ADMINISTRATIVE_SERVICE_REQUEST = 'EDIT_ADMINISTRATIVE_SERVICE_REQUEST'
export const EDIT_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS = 'EDIT_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS'
export const EDIT_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE = 'EDIT_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE'
export const CLEAR_EDIT_ADMINISTRATIVE_SERVICE = 'CLEAR_EDIT_ADMINISTRATIVE_SERVICE'
export const SET_ADMINISTRATIVE_SERVICE_TO_EDIT = 'SET_ADMINISTRATIVE_SERVICE_TO_EDIT';

//Delete
export const DELETE_ADMINISTRATIVE_SERVICE_REQUEST = 'DELETE_ADMINISTRATIVE_SERVICE_REQUEST'
export const DELETE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS = 'DELETE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS'
export const DELETE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE = 'DELETE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE'
export const CLEAR_DELETE_ADMINISTRATIVE_SERVICE = 'CLEAR_DELETE_ADMINISTRATIVE_SERVICE'

// Add all User administrative services
export const ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST = 'ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST';
export const ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// Remove all User administrative services
export const REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST = 'REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST';
export const REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';

// List all administrative services by User 
export const LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST = 'LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST';
export const LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_SUCCESS = 'LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_SUCCESS';
export const LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_FAILURE = 'LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_FAILURE';
export const SET_LIST_ADMINISTRATIVE_SERVICES_BY_USER = 'SET_LIST_ADMINISTRATIVE_SERVICES_BY_USER';
export const CLEAR_LIST_ALL_SERVICE_ADMINISTRATIVE_BY_USER = 'CLEAR_LIST_ALL_SERVICE_ADMINISTRATIVE_BY_USER';

export const LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST = 'LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST';
export const LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_SUCCESS = 'LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_SUCCESS';
export const LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_FAILURE = 'LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_FAILURE';

// Add New Users administrative services 
export const ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST = 'ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST';
export const ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS = 'ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS';
export const ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE = 'ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE';


// search service administrative
export const SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_REQUEST = 'SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_REQUEST';
export const SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_SUCCESS = 'SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_SUCCESS';
export const SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_FAILURE = 'SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_FAILURE';

// Get Active administrative service from exercise and user
export const GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST = 'GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST';
export const GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_SUCCESS = 'GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_SUCCESS';
export const GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_FAILURE = 'GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE';
export const CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_DATA = 'CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_DATA';