import React from 'react';
import { Table, Container } from 'react-bootstrap';
import ActionIcon from 'src/components/general/ActionIcon';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatterPeso } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService';

const TicketTable = (props) => {
	const { ticketObj, data, onlyRead } = props;

	const arrToMap = onlyRead ? data?.tickets : ticketObj?.ticketData;
	const totalAmount = isNotEmptyArray(arrToMap) &&
		formatterPeso.format(arrToMap?.map((v) => v.amount)?.reduce((prev, next) => Number(prev) + Number(next)));

	const onClickRemove = index => {
		ticketObj?.setTicketData(ticketObj?.ticketData?.filter((item, i) => i !== index));
	};

	return <>
		<Container className='px-5 mb-5'>
			<Table striped bordered hover size='sm' responsive>
				<thead className='text-white-color'>
					<tr>
						<th className='text-center align-middle' width="60%">Factura</th>
						<th className='text-center align-middle' width='40%'>Importe</th>
						<th className='text-center align-middle th-minwidth' hidden={onlyRead} >Acciones</th>
					</tr>
				</thead>
				<tbody className='text-black-color'>{
					arrToMap?.map((item, i) =>
						<tr key={i}>
							<td className='text-center align-middle tipeTableFormat tbTipo'>
								{item?.ticket}
							</td>
							<td className='text-right align-middle tipeTableFormat tbTipo'>
								{formatterPeso.format(item?.amount)}
							</td>
							<td className='text-center align-middle px-2 m-0 table-responsive btn-group' hidden={onlyRead}>
								<ActionIcon size='lg' toolTipText='Remover' icon={faTimes} className='text-danger' onClick={() => onClickRemove(i)} />
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<h6 className="text-right">Importe Total de Facturas: {totalAmount} </h6>
		</Container>
	</>
};

export default TicketTable;