import * as ACTION_TYPES from './expedientActionTypes';

// Expedient list actions
export const getExpedientListRequest = () => ({
	type: ACTION_TYPES.LIST_EXPEDIENT_REQUEST
});

export const getExpedientListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_EXPEDIENT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getExpedientListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_EXPEDIENT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearExpedientList = () => ({
	type: ACTION_TYPES.CLEAR_EXPEDIENT_LIST
});
// END Expedient list actions

//Edit Expedient
export const editExpedientRequest = () => ({
	type: ACTION_TYPES.EDIT_EXPEDIENT_REQUEST
});

export const editExpedientRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_EXPEDIENT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editExpedientRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_EXPEDIENT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setExpedientToEdit = data => ({
	type: ACTION_TYPES.SET_EXPEDIENT_TO_EDIT,
	payload: {
		data
	}
});