import * as ACTION_TYPES from './versionActionTypes';

export const versionReducerInitialState = {
	versionFundsRequestsAPI: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	versionCoreAPI: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	versionPresupuestoAPI: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	versionAuthAPI: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const versionReducer = ( state=versionReducerInitialState, action ) => {
	switch( action.type ) {

		// Version Funds Requests Api
		case ACTION_TYPES.GET_VERSION_FUNDS_REQUESTS_API_REQUEST: {
			return {
				...state,
				versionFundsRequestsAPI: {
					...state.versionFundsRequestsAPI,
					isFetching: true,
					hasError: false,
					data: versionReducerInitialState.versionFundsRequestsAPI.data,
					error: versionReducerInitialState.versionFundsRequestsAPI.error
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_FUNDS_REQUESTS_API_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				versionFundsRequestsAPI: {
					...state.versionFundsRequestsAPI,
					isFetching: false,
					hasError: false,
					error: versionReducerInitialState.versionFundsRequestsAPI.error,
					data
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_FUNDS_REQUESTS_API_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				versionFundsRequestsAPI: {
					...state.versionFundsRequestsAPI,
					isFetching: false,
					hasError: true,
					data: versionReducerInitialState.versionFundsRequestsAPI.data,
					error
				}
			};
		}
		// END Version Funds Requests Api

		// Version Core Api
		case ACTION_TYPES.GET_VERSION_CORE_API_REQUEST: {
			return {
				...state,
				versionCoreAPI: {
					...state.versionCoreAPI,
					isFetching: true,
					hasError: false,
					data: versionReducerInitialState.versionCoreAPI.data,
					error: versionReducerInitialState.versionCoreAPI.error
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_CORE_API_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				versionCoreAPI: {
					...state.versionCoreAPI,
					isFetching: false,
					hasError: false,
					error: versionReducerInitialState.versionCoreAPI.error,
					data
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_CORE_API_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				versionCoreAPI: {
					...state.versionCoreAPI,
					isFetching: false,
					hasError: true,
					data: versionReducerInitialState.versionCoreAPI.data,
					error
				}
			};
		}
		// END Version Core Api

		// Version Presupuesto Api
		case ACTION_TYPES.GET_VERSION_PRESUPUESTO_API_REQUEST: {
			return {
				...state,
				versionPresupuestoAPI: {
					...state.versionPresupuestoAPI,
					isFetching: true,
					hasError: false,
					data: versionReducerInitialState.versionPresupuestoAPI.data,
					error: versionReducerInitialState.versionPresupuestoAPI.error
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_PRESUPUESTO_API_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				versionPresupuestoAPI: {
					...state.versionPresupuestoAPI,
					isFetching: false,
					hasError: false,
					error: versionReducerInitialState.versionPresupuestoAPI.error,
					data
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_PRESUPUESTO_API_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				versionPresupuestoAPI: {
					...state.versionPresupuestoAPI,
					isFetching: false,
					hasError: true,
					data: versionReducerInitialState.versionPresupuestoAPI.data,
					error
				}
			};
		}
		// END Version Presupuesto Api

		// Version Auth Api
		case ACTION_TYPES.GET_VERSION_AUTH_API_REQUEST: {
			return {
				...state,
				versionAuthAPI: {
					...state.versionAuthAPI,
					isFetching: true,
					hasError: false,
					data: versionReducerInitialState.versionAuthAPI.data,
					error: versionReducerInitialState.versionAuthAPI.error
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_AUTH_API_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				versionAuthAPI: {
					...state.versionAuthAPI,
					isFetching: false,
					hasError: false,
					error: versionReducerInitialState.versionAuthAPI.error,
					data
				}
			};
		}

		case ACTION_TYPES.GET_VERSION_AUTH_API_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				versionAuthAPI: {
					...state.versionAuthAPI,
					isFetching: false,
					hasError: true,
					data: versionReducerInitialState.versionAuthAPI.data,
					error
				}
			};
		}
		// END Version Auth Api

		default: {
			return state;
		}
	}
};

export const getVersionReducer = state => state.versionReducer;

// Getter Version Funds Requests Api
export const getVersionFundsRequestsAPI = state => getVersionReducer(state)?.versionFundsRequestsAPI;
export const getVersionFundsRequestsAPIData = state => getVersionFundsRequestsAPI(state)?.data;
export const getVersionFundsRequestsAPIIsFetching = state => getVersionFundsRequestsAPI(state)?.isFetching;
export const getVersionFundsRequestsAPIHasError = state => getVersionFundsRequestsAPI(state)?.hasError;
export const getVersionFundsRequestsAPIError = state => getVersionFundsRequestsAPI(state)?.error;

// Getter Version Core Api
export const getVersionCoreAPI = state => getVersionReducer(state)?.versionCoreAPI;
export const getVersionCoreAPIData = state => getVersionCoreAPI(state)?.data;
export const getVersionCoreAPIIsFetching = state => getVersionCoreAPI(state)?.isFetching;
export const getVersionCoreAPIHasError = state => getVersionCoreAPI(state)?.hasError;
export const getVersionCoreAPIError = state => getVersionCoreAPI(state)?.error;

// Getter Version Presupuesto Api
export const getVersionPresupuestoAPI = state => getVersionReducer(state)?.versionPresupuestoAPI;
export const getVersionPresupuestoAPIData = state => getVersionPresupuestoAPI(state)?.data;
export const getVersionPresupuestoAPIIsFetching = state => getVersionPresupuestoAPI(state)?.isFetching;
export const getVersionPresupuestoAPIHasError = state => getVersionPresupuestoAPI(state)?.hasError;
export const getVersionPresupuestoAPIError = state => getVersionPresupuestoAPI(state)?.error;

// Getter Version Auth Api
export const getVersionAuthAPI = state => getVersionReducer(state)?.versionAuthAPI;
export const getVersionAuthAPIData = state => getVersionAuthAPI(state)?.data;
export const getVersionAuthAPIIsFetching = state => getVersionAuthAPI(state)?.isFetching;
export const getVersionAuthAPIHasError = state => getVersionAuthAPI(state)?.hasError;
export const getVersionAuthAPIError = state => getVersionAuthAPI(state)?.error;