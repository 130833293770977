import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';

const MenuConfig = () => {
	const dispatch = useDispatch();
    const {
        profileName,
        periodsPermissions,
        servicesPermissions,
        organismsPermissions,
        paramsToAlerts
    } = usePermissions();

    if (!(periodsPermissions?.canUpdate || servicesPermissions?.canUpdate || organismsPermissions?.canUpdate)) return null;

    return (
        <NavDropdown 
            className='active' 
            title={<><FontAwesomeIcon icon={faCog} className='mr-1'/>Configuración</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {(profileName == 'Super Admin') && 
                <NavDropdownItem route={ROUTE_PATHS.ALERT_LIST} title="Alertas" />
            }
            {periodsPermissions?.canUpdate && 
                <NavDropdownItem route={ROUTE_PATHS.EXERCISES_LIST} title="Ejercicios" />
            }
            {periodsPermissions?.canUpdate && 
                <NavDropdownItem route={ROUTE_PATHS.EXPEDIENT_LIST} title="Expedientes" />
            }
            {servicesPermissions?.canUpdate && 
                <NavDropdownItem route={ROUTE_PATHS.SERVICE_ADMINISTRATIVE_LIST} title="Servicios" />
            }
            {organismsPermissions?.canUpdate && 
                <NavDropdownItem route={ROUTE_PATHS.ADMINISTRATIVE_ORGANISM_LIST} title="Organismos" />
            }
            {(profileName == 'Super Admin' || profileName =='Dirección de Análisis e Informática') && 
                <NavDropdownItem route={ROUTE_PATHS.CAPTION_LIST} title="Leyendas" />
            }
            {(profileName == 'Super Admin') && 
                <NavDropdownItem route={ROUTE_PATHS.MAINTENANCE_SYSTEM_LIST} title="Sistema en Mantenimiento" />
            }
        </NavDropdown>
    );
};

export default MenuConfig;