import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'react-bootstrap';

import ActionIcon from 'src/components/general/ActionIcon';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectedBudgetCredits, numberCredit, creditBalance } from 'src/utils/label';

import { isNotEmptyArray } from 'src/services/validationService';

import { formatterPeso, numberNegativeRed } from 'src/utils/utils';

import { getNewAffectationSelectedCredits } from 'src/redux/affectation/affectationReducer';
import { setNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';

const SelectedBudgetItems = () => {

    const dispatch = useDispatch();

    const selectedCredits = useSelector(state => getNewAffectationSelectedCredits(state));
    const hasSelectedCredits = isNotEmptyArray(selectedCredits);

    const onClickRemoveCredit = creditData => {
		const newSelectedCredits = (selectedCredits || []).filter(item => item?.id != creditData?.id);
		dispatch(setNewAffectationDataSelectedCredits(newSelectedCredits));
	};

    return <>
        {
            hasSelectedCredits ?
            <>
                <hr />
                <p className='h6 text-black-color'>{selectedBudgetCredits}:</p>
                <Table striped bordered hover size='sm'>
                    <thead>
                        <tr>
                            <th className='text-center'>Tipo de partida</th>
                            <th className='text-center'>{numberCredit}</th>
                            <th className='text-center'>{creditBalance}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        {selectedCredits.map(item => (
                            <tr key={item?.id}>
                                <td className='text-center'>
                                    {item?.budgetItemType?.label}
                                </td>
                                <td className='text-center'>
                                    {(item?.balance?.currentBalance == 0) ?
                                        <ActionIcon size='lg' id='balance-warning' toolTipText='El saldo de la partida es $0,00.' icon={faExclamationTriangle} className='text-warning mr-3' />
                                        :
                                        undefined
                                    }
                                    {item?.completeNumber}
                                </td>
                                <td className={`${numberNegativeRed(item?.balance?.currentBalance)} text-right`}>
                                    {formatterPeso.format(item?.balance?.currentBalance)}
                                </td>
                                <td className='text-center'>
                                    <ActionIcon size='lg' id='remove-credit' toolTipText='Quitar de la selección' icon={faTimes} className='text-danger' onClick={() => onClickRemoveCredit(item)} />
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </Table>
            </> : null
        }
    </>
    
};

export default SelectedBudgetItems;