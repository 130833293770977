import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faKey } from '@fortawesome/free-solid-svg-icons';
import { push } from 'connected-react-router';

import { getAlias, getProfileName, getEmail } from 'src/redux/login/loginReducer';
import { EDIT_PASSWORD } from 'src/utils/constants';

import { setUserGlobal, setUserToEdit } from 'src/redux/user/userActions';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import { getUserListData } from 'src/redux/user/userReducer';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';

const DropdownUserControl = (props) => {
	const dispatch = useDispatch();

	const { handleClickLogout } = props;

	const alias = useSelector(state => getAlias(state));
	const profileName = useSelector(state => getProfileName(state));
	const email = useSelector(state => getEmail(state));
	const listUserData = useSelector(state => getUserListData(state));
	const userFound = listUserData?.records?.find(item => item.email == email);

	const onClickChangePassword = email => {
		dispatch(setUserToEdit(email));
		dispatch(push(EDIT_PASSWORD));
	}

	const loadAlerts = (value) => {
		const paramsToAlerts = {
			profile: profileName,
			/* userId: value[0]?.id */
			active: Boolean(true)
		};
		dispatch(tryGetActiveAlert(paramsToAlerts))
	}

	const loadAllData = () => {
		dispatch(getStatusApi(profileName))
		dispatch(tryGetUserList(1, email))?.then((response) => {
			if (response?.status == 200) {
				loadAlerts(response?.data?.records)
			}
		})
	};

	useEffect(() => {
		loadAllData();
		const userGlobalData = userFound;
		if (userGlobalData) {
			dispatch(setUserGlobal(userGlobalData));
		}
	}, [dispatch, userFound]);

	return (
		<Dropdown alignRight>
			<Dropdown.Toggle id='user-menu'>
				<FontAwesomeIcon icon={faUser} />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<div className='text-center'>
					<span className='text-black-color font-weight-bold my-2'>
						{alias}
					</span>
					<br />
					<div>
						<small className='text-muted'>
							{profileName}
						</small>
					</div>
					<div>
						<small className='text-muted mx-3'>
							{email}
						</small>
					</div>
				</div>
				<Dropdown.Divider />
				<Dropdown.Item className='text-black-color justify-content-start' onClick={() => onClickChangePassword(email)} >
					<FontAwesomeIcon className='mr-3' icon={faKey} />
					Cambiar contraseña
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item className='text-black-color justify-content-start' onClick={handleClickLogout}>
					<FontAwesomeIcon className='mr-3' icon={faSignOutAlt} />
					Cerrar sesión
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default DropdownUserControl;