import * as ACTION_TYPES from './fundRequestsActionTypes';

export const fundRequestsReducerInitialState = {
	listFundRequests: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	fundRequestsData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	fundRequestsNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	fundRequestsEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	fundRequestsDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	fundRequestsToEdit: {},

	searchFundRequetsId: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

	fundRequestTypes: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		type:undefined
	},

	fundRequestStates: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		type:undefined
	},

	budgetFiguratives: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		type:undefined
	},
	setFundRequestsToDetail: undefined,
	fundRequestManualItems: []
};

export const fundRequestsReducer = (state = fundRequestsReducer, action) => {
	switch (action.type) {

		// List order pay actions handlings
		case ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				listFundRequests: {
					...state.listFundRequests,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.listFundRequests.error,
					data: fundRequestsReducerInitialState.listFundRequests.data
				}
			};
		}
		case ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listFundRequests: {
					...state.listFundRequests,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.listFundRequests.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listFundRequests: {
					...state.listFundRequests,
					isFetching: false,
					hasError: true,
					data: fundRequestsReducerInitialState.listFundRequests.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_FUND_REQUESTS_DATA: {
			return {
				...state,
				listFundRequests: fundRequestsReducerInitialState.listFundRequests
			}
		}
		// List order pay actions handlings


		// Detail fund requests
		case ACTION_TYPES.DETAIL_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				fundRequestsData: {
					...state.fundRequestsData,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsData.error,
					data: fundRequestsReducerInitialState.fundRequestsData.data
				}
			};
		}
		case ACTION_TYPES.DETAIL_FUND_REQUESTS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestsData: {
					...state.fundRequestsData,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsData.error,
					data
				}
			};
		}
		// END List fund requests types actions handlings

		//New Fund Requests 
		case ACTION_TYPES.CREATE_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				fundRequestsNew: {
					...state.fundRequestsNew,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsNew.error
				}
			};
		}

		case ACTION_TYPES.CREATE_FUND_REQUESTS_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				fundRequestsNew: {
					...state.fundRequestsNew,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsNew.error,
					data
				}
			};
		}

		case ACTION_TYPES.CREATE_FUND_REQUESTS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				fundRequestsNew: {
					...state.fundRequestsNew,
					isFetching: false,
					hasError: true,
					data: fundRequestsReducerInitialState.fundRequestsNew.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_CREATE_FUND_REQUESTS: {

			return {
				...state,
				fundRequestsNew: fundRequestsReducerInitialState.fundRequestsNew
			}
		}
		//END

		//Edit Fund Requests 
		case ACTION_TYPES.EDIT_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				fundRequestsEdit: {
					...state.fundRequestsEdit,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_FUND_REQUESTS_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				fundRequestsEdit: {
					...state.fundRequestsEdit,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_FUND_REQUESTS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				fundRequestsEdit: {
					...state.fundRequestsEdit,
					isFetching: false,
					hasError: true,
					data: fundRequestsReducerInitialState.fundRequestsEdit.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_EDIT_FUND_REQUESTS: {

			return {
				...state,
				fundRequestsEdit: fundRequestsReducerInitialState.fundRequestsEdit
			}
		}

		case ACTION_TYPES.SET_FUND_REQUESTS_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestsToEdit: data
			}
		}


		//END

		//Delete Fund Requests 
		case ACTION_TYPES.DELETE_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				fundRequestsDelete: {
					...state.fundRequestsDelete,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsDelete.error
				}
			};
		}

		case ACTION_TYPES.DELETE_FUND_REQUESTS_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				fundRequestsDelete: {
					...state.fundRequestsDelete,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestsDelete.error,
					data
				}
			};
		}

		case ACTION_TYPES.DELETE_FUND_REQUESTS_REQUEST_FAILURE: {
			const { error } = action.payload;

			return {
				...state,
				fundRequestsDelete: {
					...state.fundRequestsDelete,
					isFetching: false,
					hasError: false,
					data: fundRequestsReducerInitialState.fundRequestsDelete.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_DELETE_FUND_REQUESTS: {

			return {
				...state,
				fundRequestsDelete: fundRequestsReducerInitialState.fundRequestsDelete
			}
		}
		//END

		case ACTION_TYPES.CLEAR_DETAIL_FUND_REQUESTS_DATA: {
			return {
				...state,
				fundRequestsData: fundRequestsReducerInitialState.fundRequestsData
			}
		}
		case ACTION_TYPES.SET_DETAIL_FUND_REQUESTS_DATA: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestsData: data
			}
		}
		// END

		/* BEGIN fund Request detail for ID */
		case ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST_ID: {
			return {
				...state,
				searchFundRequetsId: {
					...state.searchFundRequetsId,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.searchFundRequetsId.error
				}
			};
		}

		case ACTION_TYPES.LIST_FUND_REQUESTS_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				searchFundRequetsId: {
					...state.searchFundRequetsId,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.searchFundRequetsId.error,
					data
				}
			};
		}


		case ACTION_TYPES.LIST_FUND_REQUESTS_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				searchFundRequetsId: {
					...state.searchFundRequetsId,
					isFetching: false,
					hasError: false,
					data: fundRequestsReducerInitialState.searchFundRequetsId.data,
					error
				}
			};
		}
		/* END fund Request detail for ID */

		/* BEGIN fund Request type list */
		case ACTION_TYPES.LIST_TYPES_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				fundRequestTypes: {
					...state.fundRequestTypes,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestTypes.error
				}
			};
		}

		case ACTION_TYPES.LIST_TYPES_FUND_REQUESTS_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				fundRequestTypes: {
					...state.fundRequestTypes,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestTypes.error,
					data
				}
			};
		}


		case ACTION_TYPES.LIST_TYPES_FUND_REQUESTS_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				fundRequestTypes: {
					...state.fundRequestTypes,
					isFetching: false,
					hasError: false,
					data: fundRequestsReducerInitialState.fundRequestTypes.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_TYPE_LIST_FUND_REQUEST: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestTypes: {
					...state.fundRequestTypes,
					type: data
				}
			}
		}
		case ACTION_TYPES.CLEAR_TYPE_LIST_FUND_REQUEST: {
			return {
				...state,
				fundRequestTypes: {
					...state.fundRequestTypes,
					type: undefined
				}
			}
		}
		/* END fund Request type list */

		/* BEGIN fund Request State list */
		case ACTION_TYPES.LIST_STATES_FUND_REQUESTS_REQUEST: {
			return {
				...state,
				fundRequestStates: {
					...state.fundRequestStates,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestStates.error
				}
			};
		}

		case ACTION_TYPES.LIST_STATES_FUND_REQUESTS_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				fundRequestStates: {
					...state.fundRequestStates,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.fundRequestStates.error,
					data
				}
			};
		}


		case ACTION_TYPES.LIST_STATES_FUND_REQUESTS_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				fundRequestStates: {
					...state.fundRequestStates,
					isFetching: false,
					hasError: false,
					data: fundRequestsReducerInitialState.fundRequestStates.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_STATES_LIST_FUND_REQUEST: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestStates: {
					...state.fundRequestStates,
					type: data
				}
			}
		}
		case ACTION_TYPES.CLEAR_STATES_LIST_FUND_REQUEST: {
			return {
				...state,
				fundRequestStates: {
					...state.fundRequestStates,
					type: undefined
				}
			}
		}
		/* END fund Request states list */

		/* BEGIN budget figuratives */
		case ACTION_TYPES.BUDGET_FIGURATIVE_REQUESTS_REQUEST: {
			return {
				...state,
				budgetFiguratives: {
					...state.budgetFiguratives,
					isFetching: true,
					hasError: false,
					error: fundRequestsReducerInitialState.budgetFiguratives.error
				}
			};
		}

		case ACTION_TYPES.BUDGET_FIGURATIVE_REQUESTS_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				budgetFiguratives: {
					...state.budgetFiguratives,
					isFetching: false,
					hasError: false,
					error: fundRequestsReducerInitialState.budgetFiguratives.error,
					data
				}
			};
		}

		case ACTION_TYPES.BUDGET_FIGURATIVE_REQUESTS_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				budgetFiguratives: {
					...state.budgetFiguratives,
					isFetching: false,
					hasError: false,
					data: fundRequestsReducerInitialState.budgetFiguratives.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_BUDGET_FIGURATIVE_REQUEST: {
			return {
				...state,
				budgetFiguratives: fundRequestsReducerInitialState.budgetFiguratives
			}
		}
		/* END budget figuratives */

		// Fund Request Manual Items
		case ACTION_TYPES.SELECTED_FUND_REQUEST_MANUAL_ITEM: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestManualItems: [...state.fundRequestManualItems, data]
			}
		};
		case ACTION_TYPES.DELETED_FUND_REQUEST_MANUAL_ITEM: {
			const { data } = action.payload;
			return {
				...state,
				fundRequestManualItems: state.fundRequestManualItems.filter(
					items => items.idItem !== data
				)
			}
		};
		case ACTION_TYPES.CLEAR_FUND_REQUEST_MANUAL_ITEM: {
			return {
				...state,
				fundRequestManualItems: fundRequestsReducerInitialState.fundRequestManualItems
			}
		};
		// END Fund Request Manual Items

		default: {
			return state;
		}
	}
};





export const getFundRequestsReducer = state => state.fundRequestsReducer;

// List fund requests
export const getFundRequestsList = state => getFundRequestsReducer(state)?.listFundRequests;
export const getFundRequestsListData = state => getFundRequestsList(state)?.data;
export const getFundRequestsListIsFetching = state => getFundRequestsList(state)?.isFetching;

// New fund requests 
export const getFundRequestsCreate = state => getFundRequestsReducer(state)?.fundRequestsNew;
export const getFundRequestsCreateIsFetching = state => getFundRequestsCreate(state)?.isFetching;
export const getFundRequestsCreateIsError = state => getFundRequestsCreate(state)?.hasError;

// Edit fund requests 
export const getFundRequestsEdit = state => getFundRequestsReducer(state)?.fundRequestsEdit;
export const getFundRequestsEditIsFetching = state => getFundRequestsEdit(state)?.isFetching;
export const getFundRequestsEditIsError = state => getFundRequestsEdit(state)?.hasError;
export const getFundRequestsToEdit = state => getFundRequestsReducer(state)?.fundRequestsToEdit;

// Delete fund requests 
export const getFundRequestsDelete = state => getFundRequestsReducer(state)?.fundRequestsDelete;
export const getFundRequestsDeleteIsFetching = state => getFundRequestsDelete(state)?.isFetching;
export const getFundRequestsDeleteIsError = state => getFundRequestsDelete(state)?.hasError;

// Detail fund requests
export const getFundRequestsDetail = state => getFundRequestsReducer(state)?.fundRequestsData;

//Search fund Request for ID
export const getFundRequestId = state => getFundRequestsReducer(state)?.searchFundRequetsId;
export const getIsFetchingFundRequestId = state => getFundRequestId(state)?.isFetching

export const getFundRequestsToDetail = state => getFundRequestsReducer(state)?.fundRequestsData

//Search fund Request types
export const getFundRequestTypes = state => getFundRequestsReducer(state)?.fundRequestTypes;
export const getFundRequestTypesData = state => getFundRequestTypes(state)?.data;
export const getFundRequestTypesIsFetching = state => getFundRequestTypes(state)?.isFetching;
export const getSelectedFundRequestType = state => getFundRequestTypes(state)?.type;

//Search fund Request states
export const getFundRequestStates = state => getFundRequestsReducer(state)?.fundRequestStates;
export const getFundRequestStatesData = state => getFundRequestStates(state)?.data;
export const getFundRequestStatesIsFetching = state => getFundRequestStates(state)?.isFetching;
export const getSelectedFundRequestState = state => getFundRequestStates(state)?.type;

//Budget figurative request
export const getBudgetFiguratives = state => getFundRequestsReducer(state)?.budgetFiguratives;
export const getBudgetFigurativesData = state => getBudgetFiguratives(state)?.data;
export const getBudgetFigurativesIsFetching = state => getBudgetFiguratives(state)?.isFetching;

// Fund Request Manual Items
export const getFundRequestManualItems = state => getFundRequestsReducer(state)?.fundRequestManualItems;
