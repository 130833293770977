import * as ACTION_TYPES from './preloadActionTypes';

export const preloadReducerInitialState = {

	listPreload: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

	preloadDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

	preloadAttachLegalInstrument: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	preloadEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	preloadXLSDownload: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	preloadReport: {
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const preloadReducer = (state=preloadReducerInitialState, action) => {
	switch (action.type) {

		// List Preload
		case ACTION_TYPES.GET_PRELOAD_DATA_REQUEST: {
			return {
				...state,
				listPreload: {
					...state.listPreload,
					isFetching: true,
					hasError: false
				}
			};
		}

		case ACTION_TYPES.GET_PRELOAD_DATA_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listPreload: {
					...state.listPreload,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}

		case ACTION_TYPES.GET_PRELOAD_DATA_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listPreload: {
					...state.listPreload,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_PRELOAD_DATA: {
			return {
				...state,
				listPreload: {
					...preloadReducerInitialState.listPreload
				}
			};
		}

		//Delete Preload
		case ACTION_TYPES.DELETE_PRELOAD_REQUEST: {
			return {
				...state,
				preloadDelete: {
					...state.preloadDelete,
					isFetching: true,
					hasError: false,
					error: preloadReducerInitialState.preloadDelete.error
				}
			};
		}

		case ACTION_TYPES.DELETE_PRELOAD_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				preloadDelete: {
					...state.preloadDelete,
					isFetching: false,
					hasError: false,
					error: preloadReducerInitialState.preloadDelete.error,
					data
				}
			};
		}

		case ACTION_TYPES.DELETE_PRELOAD_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				preloadDelete: {
					...state.preloadDelete,
					isFetching: false,
					hasError: true,
					data: preloadReducerInitialState.preloadDelete.data,
					error
				}
			};
		}

		// Edit Preload
		case ACTION_TYPES.EDIT_PRELOAD_REQUEST: {
			return {
				...state,
				preloadEdit: {
					...state.preloadEdit,
					isFetching: true,
					hasError: false,
					error: preloadReducerInitialState.preloadEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_PRELOAD_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				preloadEdit: {
					...state.preloadEdit,
					isFetching: false,
					hasError: false,
					error: preloadReducerInitialState.preloadEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_PRELOAD_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				preloadEdit: {
					...state.preloadEdit,
					isFetching: false,
					hasError: true,
					data: preloadReducerInitialState.preloadEdit.data,
					error
				}
			};
		}

		//Attach legal instrument to preload
		case ACTION_TYPES.ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST: {
			return {
				...state,
				preloadAttachLegalInstrument: {
					...state.preloadAttachLegalInstrument,
					isFetching: true,
					hasError: false,
					error: preloadReducerInitialState.preloadAttachLegalInstrument.error
				}
			};
		}

		case ACTION_TYPES.ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				preloadAttachLegalInstrument: {
					...state.preloadAttachLegalInstrument,
					isFetching: false,
					hasError: false,
					error: preloadReducerInitialState.preloadAttachLegalInstrument.error,
					data
				}
			};
		}

		case ACTION_TYPES.ATTACH_LEGAL_INSTRUMENT_REQUESTS_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				preloadAttachLegalInstrument: {
					...state.preloadAttachLegalInstrument,
					isFetching: false,
					hasError: true,
					data: preloadReducerInitialState.preloadAttachLegalInstrument.data,
					error
				}
			};
		}

		//Get preload XLS file actions
		case ACTION_TYPES.GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST: {
			return {
				...state,
				preloadXLSDownload: {
					...state.preloadXLSDownload,
					isFetching: true,
					hasError: false,
					error: preloadReducerInitialState.preloadXLSDownload.error
				}
			};
		}

		case ACTION_TYPES.GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				preloadXLSDownload: {
					...state.preloadXLSDownload,
					isFetching: false,
					hasError: false,
					error: preloadReducerInitialState.preloadXLSDownload.error,
					data
				}
			};
		}

		case ACTION_TYPES.GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				preloadXLSDownload: {
					...state.preloadXLSDownload,
					isFetching: false,
					hasError: true,
					data: preloadReducerInitialState.preloadXLSDownload.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_PRELOAD_DOWNLOAD_XLS_FILE_DATA: {
			return {
				...state,
				preloadXLSDownload: {
					...preloadReducerInitialState.preloadXLSDownload
				}
			};
		}

		//Get preload report
		case ACTION_TYPES.GET_PRELOAD_REPORT_REQUEST: {
			return {
				...state,
				preloadReport: {
					...state.preloadReport,
					isFetching: true,
					hasError: false,
					error: preloadReducerInitialState.preloadReport.error
				}
			};
		}

		case ACTION_TYPES.GET_PRELOAD_REPORT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				preloadReport: {
					...state.preloadReport,
					isFetching: false,
					hasError: false,
					error: preloadReducerInitialState.preloadReport.error
				}
			};
		}

		case ACTION_TYPES.GET_PRELOAD_REPORT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				preloadReport: {
					...state.preloadReport,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}

		default: {
			return state
		}
	}
};

export const getPreloadReducer = state => state.preloadReducer;

//Preload getters
export const getPreload = state => getPreloadReducer(state)?.listPreload;
export const getPreloadData = state => getPreload(state)?.data;
export const getPreloadIsFetching = state => getPreload(state)?.isFetching;

// Delete preload
export const getPreloadDelete = state => getPreloadReducer(state)?.preloadDelete;
export const getPreloadDeleteIsFetching = state => getPreloadDelete(state)?.isFetching;
export const getPreloadDeleteIsError = state => getPreloadDelete(state)?.hasError;

// Edit preloads
export const getPreloadEdit = state => getPreloadReducer(state)?.preloadEdit;
export const getPreloadEditIsFetching = state => getPreloadEdit(state)?.isFetching;

// Attach instrument legal preload
export const getAttachLegalInstrumentPreload = state => getPreloadReducer(state)?.preloadAttachLegalInstrument;
export const getAttachLegalInstrumentPreloadIsFetching = state => getAttachLegalInstrumentPreload(state)?.isFetching;
export const getAttachLegalInstrumentPreloadHasError = state => getAttachLegalInstrumentPreload(state)?.hasError;

// Get preload xls data
export const getPreloadXLS= state => getPreloadReducer(state)?.preloadXLSDownload;
export const getPreloadXLSData = state => getPreloadXLS(state)?.data;
export const getPreloadXLSIsFetching = state => getPreloadXLS(state)?.isFetching;

// Get preload report
export const getPreloadReport = state => getPreloadReducer(state)?.preloadReport;
export const getPreloadReportIsFetching = state => getPreloadReport(state)?.isFetching;