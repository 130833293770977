import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Table, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFilter, faBroom, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import ActionIcon from 'src/components/general/ActionIcon';
import AppLoading from 'src/components/common/AppLoading';

import { getClearObject } from 'src/utils/utils';

import { isNotEmptyArray } from 'src/services/validationService';

import {clearAuditList} from 'src/redux/audit/auditActions'
import {getAuditListIsFetching } from 'src/redux/audit/auditReducer';

import { tryGetAuditList } from 'src/redux/audit/auditActionCreator';
import { getAuditListData } from 'src/redux/audit/auditReducer';

import { dateToStringFormatedToShowUSA } from 'src/utils/utils';

import RCPagination from 'src/components/common/RCPagination';

import AuditDetailModal from 'src/components/pages/Audits/AuditDetailModal';

const AuditsListPage = () => {

    const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useForm();
  
    // Organism
    const metaData = useSelector( state => getAuditListData(state));
    const auditData = useSelector( state => getAuditListData(state) )?.data;
	const auditDataIsFetching = useSelector(state => getAuditListIsFetching(state));
    const hasOrganismList = isNotEmptyArray(auditData);

    const auditDataPaginate = useSelector(state => getAuditListData (state));

    const sizeRecords = parseInt(auditDataPaginate?.perPage);

    const todayDate = dateToStringFormatedToShowUSA(new Date());

    const defaultParams = {
        size: 10,
        page: 1
    };

     // Action Types
     const actionTypes = [
        "Creación de afectaciones",
        "Modificación de crédito",
        "Creación de afectaciones de residuo pasivo",
        "Creación de crédito original de residuo pasivo",
        "Creación de crédito original",
        "Alta de Pre-Carga de Expediente de Modificación de Crédito",
        "Edicion de Importe de Pre-Carga de Expediente de Modificación de Crédito",
        "Eliminación de Pre-Carga de Expediente de Modificación de Crédito",
        "Conversión de Pre-Carga de Expediente a una nueva Modificación de Crédito",
        "Generación de reporte",
        "Generación de reporte Excel de Precarga",
        "Reporte de comprobantes por expediente"
    ];

    // Reference

    const referenceText = (budgetNumber) => {
        const referenceText =  `Nro. de partida: ${budgetNumber}`;
        return referenceText;
    };

    const getReference = (item) => {

        const action = item?.action;
        let reference = '';
        const formattedData = item != undefined ? JSON.parse(item?.references) : null;

        switch (action) {
            case actionTypes[0]: {
                const list = formattedData?.data;
                const listValidation = list?.length > 0 ? true : false;
                reference = listValidation ? referenceText(list?.[0]?.['Nro. de partida']) : '';
                break;
            }
            case actionTypes[1]: {
                reference =   referenceText(formattedData?.['Número de partida']); 
                break;
            }
            case actionTypes[2]: {
                const list = formattedData?.data;
                const listValidation = list?.length > 0 ? true : false;
                reference = listValidation ?  referenceText(list?.[0]?.['Nro. de partida']) : '';
                break;
            }
            case actionTypes[3]: {
                reference = referenceText(formattedData?.['Número de partida']);
                break;
            }
            case actionTypes[4]: {
                reference = referenceText(formattedData?.['Número de partida']);
                break;
            }
            case actionTypes[5]: {
                reference = referenceText(formattedData?.['Número de partida']);
                break;
            }
            case actionTypes[6]: {
                reference = referenceText(formattedData?.['Número de partida']);
                break;
            }
            case actionTypes[7]: {
                reference =   referenceText(formattedData?.['Número de partida']);
                break;
            }
            case actionTypes[8]: {
                reference = referenceText(formattedData?.['Número de partida']);
                break;
            }
            case actionTypes[9]: {
                reference = formattedData?.['Reporte']; 
                break;
            }
            case actionTypes[10]: {
                reference = formattedData?.['Reporte']; 
                break;
            }
            case actionTypes[11]: {
                const data = formattedData[0];
                reference =  `Fecha de Transacción: ${data?.['Fecha de Transacción']} - Número de Expediente ${data?.['Número de Expediente']}`; 
                break;
            }
            default: {
                reference = '-';
            }
        }

        return reference
    };

    //Audit detail modal
    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onClickAuditDetailModal = (auditData) => {
        setDataModal(auditData);
        handleShow(true);
    };

    // Pagination
    const [auditFilter, setAuditFilter] = useState({});

    const page = metaData?.page;
    const perPage = metaData?.perPage
	const totalItemsCount = metaData?.totalCount ? metaData?.totalCount : 1;
    const totalItems = metaData?.totalCount ? metaData?.totalCount : '0';

    const onPageChange = (pNumber) => {

        const params = {
            ...defaultParams,
            filter: auditFilter,
            page: pNumber
        };

        loadAllData(params);
    };

    const clearFilters = () => {
        setAuditFilter({});
        reset();
        loadAllData(defaultParams);
    };

   const fetchList = (params) => {
        dispatch(clearAuditList());
        dispatch(tryGetAuditList(params));
    };

    const handleSearch = (data) => {

        const temp = getClearObject(data);
        const filter= {
            ...temp
        };
        setAuditFilter(temp);
        loadAllData({...defaultParams, filter});

    };

    const loadAllData = (params) => {
        fetchList(params);
	}

    useEffect( () => {
        loadAllData(defaultParams)
    }, []);


    return <>
            <Container fluid>
                <Card className='my-3'>
                    <Card.Header className='h6'>
                       Auditorías
                    </Card.Header>
                    <Card.Body>
                    <Form onSubmit={handleSubmit(handleSearch)} autoComplete='off'>
                        <Table striped hover size='sm' >
                            <thead>
                                <tr>
                                    <th className='text-center align-middle tipeTableFormat tbTipo' width="10%">Fecha y Hora</th>
                                    <th className='text-center align-middle' width="15%">Usuario/Perfil</th>
                                    <th className='text-center align-middle' width='25%'>Acción</th>
                                    <th className='text-center align-middle' width='40%'>Referencias</th>
                                    <th className='text-center align-middle' width='10%'>Detalle</th>
                                </tr>
                                <tr className='secondary'>
                                    <th className='text-center align-middle'>
                                        <Form.Control 
                                            type='date'
                                            name='dateFrom'
                                            ref={register}
                                            size="sm" 
                                            max={todayDate} 
                                            className='text-center mb-1 mr-4' 
                                        />
                                        <Form.Control 
                                            type='date' 
                                            ref={register}
                                            name='dateTo'
                                            size="sm"
                                            max={todayDate} 
                                            className='text-center' 
                                        />
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control 
                                            type='text' 
                                            size="sm" 
                                            className='text-left' 
                                            ref={register}
                                            name='user'
                                            placeholder="Filtrar usuario" 
                                        />
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control 
                                            className='text-left' 
                                            size='sm' 
                                            type='text' 
                                            ref={register}
                                            name='action'
                                            placeholder="Filtrar acción" 
                                        />
                                    </th>
                                    <th className='text-center align-middle'>
                                    </th>
                                    <th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color px-4 py-1 mx-2"
													toolTipText="Filtrar"
													icon={faFilter}
													type='submit'
													onSubmit={handleSearch}
													/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color px-4 py-1 mx-2"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													type='reset'
													onClick={()=> clearFilters()}
													/>
											</div>
                                    </th>
                                </tr>

                            </thead>
                            <tbody className='text-black-color'>
                                {
                                    hasOrganismList
                                        ?
                                        <>
                                            {
                                                auditData?.map((item) => (
                                                    <tr key={item.id}>
                                                        
                                                        <td className='text-center align-middle tipeTableFormat tbTipo'>
                                                        {item?.created_at}
                                                        </td>
                                                        <td className='text-center align-middle actionTableFormat tbAction'>
                                                        {item?.user_name}/{item?.profile_name} 
                                                        </td>
														<td className='text-center align-middle '>
                                                            { item?.action}
                                                        </td>
                                                        <td className='text-center align-middle'>
                                                            {getReference(item)}
                                                        </td>
                                                        <td className='text-center align-middle p-0 m-0'>
                                                            <ActionIcon size='lg' toolTipText='Detalle de la auditoría' icon={faSearch} onClick={() => onClickAuditDetailModal(item)} />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td colSpan='13' className='text-center'>
                                                {
                                                    !auditDataIsFetching &&
                                                        <Alert variant='info' className='mb-0'>
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                            No hay registros
                                                        </Alert>
                                                }
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                        {hasOrganismList &&
							<RCPagination
				    			activePage={page}
								itemsCountPerPage={perPage}
								totalItemsCount={totalItemsCount}
								pageRangeDisplayed={7}
								thePage={page}
								totalItems={totalItems}
								innerClass="justify-content-center"
								onChange={onPageChange}
							/> 
						}   
                        </Form>

                    </Card.Body>
                </Card>
            </Container>
            <AuditDetailModal
                show = {show}
                handleClose = {handleClose}
                dataModal = { dataModal }
            />
            <AppLoading isLoading={auditDataIsFetching} />
    </>;

}
export default AuditsListPage; 