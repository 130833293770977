import * as ACTION_TYPES from './partialBudgetActionTypes';

export const partialBudgetReducerInitialState = {
	partialBudgetByPrincipalBudgetId: {
		principalBudgetId: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	subPartialBudgetList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	subPartialBudgetOfSelectedCredits: {
		isFetching: false,
	}
};

export const partialBudgetReducer = ( state=partialBudgetReducerInitialState, action ) => {
	switch( action.type ) {

		// List partial budget by principal budget id request actions
		case ACTION_TYPES.LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST: {
			const { principalBudgetId } = action.payload;
			return {
				...state,
				partialBudgetByPrincipalBudgetId: {
					...state.partialBudgetByPrincipalBudgetId,
					principalBudgetId,
					isFetching: true,
					hasError: false,
					data: partialBudgetReducerInitialState.partialBudgetByPrincipalBudgetId.data,
					error: partialBudgetReducerInitialState.partialBudgetByPrincipalBudgetId.error
				}
			};
		}

		case ACTION_TYPES.LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				partialBudgetByPrincipalBudgetId: {
					...state.partialBudgetByPrincipalBudgetId,
					isFetching: false,
					hasError: false,
					error: partialBudgetReducerInitialState.partialBudgetByPrincipalBudgetId.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				partialBudgetByPrincipalBudgetId: {
					...state.partialBudgetByPrincipalBudgetId,
					isFetching: false,
					hasError: true,
					principalBudgetId: partialBudgetReducerInitialState.partialBudgetByPrincipalBudgetId.principalBudgetId,
					data: partialBudgetReducerInitialState.partialBudgetByPrincipalBudgetId.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_LIST: {
			return {
				...state,
				partialBudgetByPrincipalBudgetId: partialBudgetReducerInitialState.partialBudgetByPrincipalBudgetId
			};
		}
		// List partial budget by principal budget id request actions



		// List sub partial budget actions
		case ACTION_TYPES.LIST_SUB_PARTIAL_BUDGET_REQUEST: {
			return {
				...state,
				subPartialBudgetList: {
					...state.subPartialBudgetList,
					isFetching: true,
					hasError: false,
					error: partialBudgetReducerInitialState.subPartialBudgetList.error,
					data: partialBudgetReducerInitialState.subPartialBudgetList.data
				}
			}
		}
		case ACTION_TYPES.LIST_SUB_PARTIAL_BUDGET_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				subPartialBudgetList: {
					...state.subPartialBudgetList,
					isFetching: false,
					hasError: false,
					error: partialBudgetReducerInitialState.subPartialBudgetList.error,
					data
				}
			}
		}
		case ACTION_TYPES.LIST_SUB_PARTIAL_BUDGET_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				subPartialBudgetList: {
					...state.subPartialBudgetList,
					isFetching: false,
					hasError: true,
					data: partialBudgetReducerInitialState.subPartialBudgetList.data,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_LIST_SUB_PARTIAL_BUDGET: {
			return {
				...state,
				subPartialBudgetList: partialBudgetReducerInitialState.subPartialBudgetList
			}
		}
		// List sub partial budget actions



		// Get subpartial budget of selected credits actions
		case ACTION_TYPES.GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS: {
			return {
				...state,
				subPartialBudgetOfSelectedCredits: {
					...state.subPartialBudgetOfSelectedCredits,
					isFetching: true
				}
			}
		}
		case ACTION_TYPES.GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_SUCCESS: {
			return {
				...state,
				subPartialBudgetOfSelectedCredits: {
					...state.subPartialBudgetOfSelectedCredits,
					isFetching: false
				}
			}
		}
		case ACTION_TYPES.GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_FAILURE: {
			return {
				...state,
				subPartialBudgetOfSelectedCredits: {
					...state.subPartialBudgetOfSelectedCredits,
					isFetching: false
				}
			}
		}
		// END Get subpartial budget of selected credits actions

		

		default: {
			return state;
		}
	}
};

export const getPartialBudgetReducer = state => state.partialBudgetReducer;

export const getPartialBudgetListByPrincipalBudgetId = state => getPartialBudgetReducer(state)?.partialBudgetByPrincipalBudgetId;
export const getPartialBudgetListByPrincipalBudgetIdData = state => getPartialBudgetListByPrincipalBudgetId(state)?.data;
export const getPartialBudgetListByPrincipalBudgetIdIsFetching = state => getPartialBudgetListByPrincipalBudgetId(state)?.isFetching;
export const getPartialBudgetListByPrincipalBudgetIdHasError = state => getPartialBudgetListByPrincipalBudgetId(state)?.hasError;
export const getPartialBudgetListByPrincipalBudgetIdError = state => getPartialBudgetListByPrincipalBudgetId(state)?.error;

export const getSubpartialBudgetOfSelectedCredits = state => getPartialBudgetReducer(state)?.subPartialBudgetOfSelectedCredits;
export const getSubpartialBudgetOfSelectedCreditsIsFetching = state => getSubpartialBudgetOfSelectedCredits(state)?.isFetching;