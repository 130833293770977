import * as SERVICES from 'src/services/reportsServices';
import * as ACTIONS from './reportsActions';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError, showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { downloadStreamFile } from 'src/utils/utils';

export const tryGetReportConsolidatedByPurpose = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportConsolidated());

		if (accessToken) {

			const response = await SERVICES.getReportConsolidatedByPurpose(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_consolidado_finalidad_y_función');
				dispatch(ACTIONS.getReportConsolidatedSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportConsolidatedSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportConsolidatedFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

export const tryGetReportConsolidatedEconomic = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportConsolidated());

		if (accessToken) {

			const response = await SERVICES.getReportConsolidatedEconomic(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportConsolidatedSuccess());
				showSuccess('El reporte se descargó correctamente.');
				downloadStreamFile(response, 'reporte_consolidado_objeto_del_gasto');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportConsolidatedSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportConsolidatedFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

//Jurisdiction Analytic
export const tryGetReportJurisdictionAnalytic = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportJurisdictionAnalytic());

		if (accessToken) {

			const response = await SERVICES.getReportJurisdictionAnalytic(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_analítico_por_jurisdicción');
				dispatch(ACTIONS.getReportJurisdictionAnalyticSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportJurisdictionAnalyticSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportJurisdictionAnalyticFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

//Organism Analytic
export const tryGetReportOrganismAnalytic = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportOrganismAnalytic());

		if (accessToken) {

			const response = await SERVICES.getReportOrganismAnalytic(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_analítico_por_organismo');
				dispatch(ACTIONS.getReportOrganismAnalyticSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportOrganismAnalyticSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportOrganismAnalyticFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

//Jurisdiction Total
export const tryGetReportJurisdictionTotalByPurpose = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportJurisdictionTotal());

		if (accessToken) {

			const response = await SERVICES.getReportJurisdictionTotalByPurpose(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_total_jurisdicción_finalidad_y_función');
				dispatch(ACTIONS.getReportJurisdictionTotalSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportJurisdictionTotalSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportJurisdictionTotalFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

export const tryGetReportJurisdictionTotalEconomic = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportJurisdictionTotal());

		if (accessToken) {

			const response = await SERVICES.getReportJurisdictionTotalEconomic(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_total_jurisdicción_objeto_del_gasto');
				dispatch(ACTIONS.getReportJurisdictionTotalSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportJurisdictionTotalSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportJurisdictionTotalFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};



// Total report by organization (Purpose)
export const tryGetReportTotalOrganizationByPurpose = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportOrganizationTotal());

		if (accessToken) {

			const response = await SERVICES.getReportOrganizationTotalByPurpose(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_total_organismo_finalidad_y_función');
				dispatch(ACTIONS.getReportOrganizationTotalSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportOrganizationTotalSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportOrganizationTotalFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};



// Total report by organization (Economic)
export const tryGetReportTotalOrganizationByEconomic = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportOrganizationTotal());

		if (accessToken) {

			const response = await SERVICES.getReportOrganizationTotalByEconomic(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_total_organismo_objeto_del_gasto');
				dispatch(ACTIONS.getReportOrganizationTotalSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportOrganizationTotalSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportOrganizationTotalFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};



// Report Crossed
export const tryGetReportCrossed = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportCrossedRequest());

		if (accessToken) {

			const response = await SERVICES.getReportCrossed(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_total_cruzado');
				dispatch(ACTIONS.getReportCrossedRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportCrossedRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportCrossedRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

// Report Instrument Legal Modify
export const tryGetReportInstrumentLegalModify = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportInstrumentLegalModifyRequest());

		if (accessToken) {

			const response = await SERVICES.getReportInstrumentLegalModify(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getReportInstrumentLegalModifyRequestSuccess());
				showSuccess('El reporte se generó correctamente.');	
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportInstrumentLegalModifyRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportInstrumentLegalModifyRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al generar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}

	};
};

// Direct download Report Instrument Legal Modify
export const tryGetReportInstrumentLegalModifyDirectDownload = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportInstrumentLegalModifyDirectDownloadRequest());

		if (accessToken) {

			const response = await SERVICES.getReportInstrumentLegalModifyDirectDownload(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getReportInstrumentLegalModifyDirectDownloadRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportInstrumentLegalModifyDirectDownloadRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportInstrumentLegalModifyDirectDownloadRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}

	};
};

// Report affectations by administrative document
export const tryGetReportAffectationsByAdministrativeDocument = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentRequest());

		if (accessToken) {

			const response = await SERVICES.getReportAffectationsByAdministrativeDocument(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentRequestSuccess());
				showSuccess('El reporte se generó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al generar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}

	};
};

// Report affectations by administrative document direct download
export const tryGetReportAffectationsByAdministrativeDocumentDirectDownload = id => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentDownloadRequest());

		if (accessToken) {

			const response = await SERVICES.getReportAffectationsByAdministrativeDocumentDownload(accessToken, id);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentDownloadRequestSuccess());
				downloadStreamFile(response, 'reporte_de_comprobantes_por_expediente');
				showSuccess('El reporte se descargó correctamente.');
			}
			else {
				dispatch(ACTIONS.getReportAffectationsByAdministrativeDocumentDownloadRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

// Report movement
export const tryGetReportMovement = (movementId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportMovementRequest());

		if (accessToken) {

			const response = await SERVICES.getReportMovement(accessToken, movementId, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'comprobante_movimiento');
				dispatch(ACTIONS.getReportMovementRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportMovementRequestSuccess());
				// showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportMovementRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

// Report movement by Url
export const tryGetReportMovementByUrl = (reportUrl) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportMovementRequest());
		
		if (accessToken) {

			const response = await SERVICES.getReportByURL(accessToken, reportUrl);

			if (response?.status == 200) {
				downloadStreamFile(response, 'comprobante_movimiento');
				dispatch(ACTIONS.getReportMovementRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else {
				dispatch(ACTIONS.getReportMovementRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

//Report Credit Modify - Report Credit Execution
export const tryGetReportCreditModifyCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportCreditModifyCreditExecution(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequestSuccess());
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
				showSuccess('El reporte se descargó correctamente en el Módulo de Reportes Generados.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}

	};
};

//Report Credit Modify - Report Credit Execution V2
export const tryGetReportCreditModifyCreditExecutionV2 = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportCreditModifyCreditExecutionV2(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequestSuccess());
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
				showSuccess('El reporte se descargó correctamente en el Módulo de Reportes Generados.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportCreditModifyCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}

	};
};

//Report Table Totals - Report Credit Execution
export const tryGetReportTableTotalsCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportTableTotalsCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportTableTotalsCreditExecution(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'cuadro_totales');
				dispatch(ACTIONS.getReportTableTotalsCreditExecutionRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportTableTotalsCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportTableTotalsCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

//Report Budget status - Report Credit Execution
export const tryGetReportBudgetStatusCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBudgetStatusCreditExecution(accessToken, params);

			if (response?.status == 200) {
					dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
					showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}


	};
};

//Report Budget status - Report Credit Execution v2
export const tryGetReportBudgetStatusCreditExecutionV2 = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBudgetStatusCreditExecutionV2(accessToken, params);

			if (response?.status == 200) {
					dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
					showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}


	};
};

//Report Works Budget Analytical Record - Report Credit Execution
export const tryGetReportWorksBudgetAnalyticalRecordCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportWorksBudgetAnalyticalRecordCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportWorksBudgetAnalyticalRecordCreditExecution(accessToken, params);

			if (response?.status == 200) {
				if (params?.outputFormat == 2) {
					downloadStreamFile(response, 'registro_analítico_de_presupuesto_obras');
					dispatch(ACTIONS.getReportWorksBudgetAnalyticalRecordCreditExecutionRequestSuccess());
					showSuccess('El reporte se descargó correctamente.');
				} else {
					dispatch(ACTIONS.getReportWorksBudgetAnalyticalRecordCreditExecutionRequestSuccess());
				}
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportWorksBudgetAnalyticalRecordCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportWorksBudgetAnalyticalRecordCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}
	};
};


//Report Movements Status - Report Credit Execution
export const tryGetReportMovementsStatusCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportMovementsStatusCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportMovementsStatusCreditExecution(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportMovementsStatusCreditExecutionRequestSuccess());
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportMovementsStatusCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportMovementsStatusCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};


//Report Delayed Files - Report Credit Execution
export const tryGetReportDelayedFilesCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportDelayedFilesCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportDelayedFilesCreditExecution(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'expedientes_demorados');
				dispatch(ACTIONS.getReportDelayedFilesCreditExecutionRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportDelayedFilesCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportDelayedFilesCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

// Report balance status payment list - Report Credit Execution
export const tryGetReportBalanceStatusPaymentListCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBalanceStatusPaymentListCreditExecution(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequestSuccess());
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;

		}

	};
};

// Report balance status payment list - Report Credit Execution V2
export const tryGetReportBalanceStatusPaymentListCreditExecutionV2 = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBalanceStatusPaymentListCreditExecutionV2(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequestSuccess());
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBalanceStatusPaymentListCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;

		}

	};
};

//Report Budget Analytical Accounting - Report Credit Execution
export const tryGetReportBudgetAnalyticalAccountingCreditExecution = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBudgetAnalyticalAccountingCreditExecution(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};

//Report Budget Analytical Accounting - Report Credit Execution V2
export const tryGetReportBudgetAnalyticalAccountingCreditExecutionV2 = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBudgetAnalyticalAccountingCreditExecutionV2(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBudgetAnalyticalAccountingCreditExecutionRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};

//Report Balance status
export const tryGetReportBalanceStatus = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBalanceStatusRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBalanceStatusExecution(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportBalanceStatusSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBalanceStatusSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBalanceStatusFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};

//Report Balance status V2
export const tryGetReportBalanceStatusV2 = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBalanceStatusRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBalanceStatusExecutionV2(accessToken, params);

			if (response?.status == 200) {
				if ( params?.outputFormat == 2 ){
					downloadStreamFile(response, 'balance_status');
					dispatch(ACTIONS.getReportBalanceStatusSuccess());
					showSuccess('El reporte se descargó correctamente.');
				} else {
					dispatch(ACTIONS.getReportBalanceStatusSuccess());					
				}
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBalanceStatusSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBalanceStatusFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};



//Report Budget status Generated- Report Credit Execution
export const tryGetReportBudgetStatusCreditExecutionGenerated = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportBudgetStatusCreditExecutionGeneratedRequest());

		if (accessToken) {
			
			const response = await SERVICES.getReportBudgetStatusCreditExecutionGenerated(accessToken, params);
			if (response?.status == 200) {
				downloadStreamFile(response, params?.fileName);
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionGeneratedRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionGeneratedRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportBudgetStatusCreditExecutionGeneratedRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			
			return response;
		}


	};
};

export const tryGetReportBudgetStatusCreditExecutionList = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getListReportBudgetGeneratedListRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBudgetGenerateList(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getListReportBudgetGeneratedListRequestSucces(response?.data));
				//showSuccess('Se Cargo con éxito la lista de Reportes.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getListReportBudgetGeneratedListRequestSucces(response?.data));
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getListReportBudgetGeneratedListRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.error || response}`)
			}
			return response
		}

	};
};


//Report delivery orders - Report Request Fund
export const tryGetReportDeliveryOrdersFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportDeliveryOrdersFundRequestRequest());

		if (accessToken) {

			const response = await SERVICES.getReportDeliveryOrdersFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'orden_de_entrega');
				dispatch(ACTIONS.getReportDeliveryOrdersFundRequestRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportDeliveryOrdersFundRequestRequestSuccess());
				showWarning('No existen reportes para los parametros ingresados.');	
			}
			else {								
				dispatch(ACTIONS.getReportDeliveryOrdersFundRequestRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}
	};
};

//Report payment order administrative service fund request - Report Request Fund
export const tryGetPaymentOrderAdministrativeServiceFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportPaymentOrderAdministrativeServiceFundRequestRequest());

		if (accessToken) {

			const response = await SERVICES.getReportPaymentOrderAdministrativeServiceFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'ordenes_de_pago_por_servicio_administrativo');
				dispatch(ACTIONS.getReportPaymentOrderAdministrativeServiceFundRequestRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportPaymentOrderAdministrativeServiceFundRequestRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportPaymentOrderAdministrativeServiceFundRequestRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}


	};
};

//Report service direction - Report Request Fund
export const tryGetReportServiceDirectionFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportServiceDirectionFundRequest());

		if (accessToken) {

			const response = await SERVICES.getReportReportServiceDirectionFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'pedidos_de_fondos_por_servicio_administrativo_o_direccion');
				dispatch(ACTIONS.getReportServiceDirectionFundRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportServiceDirectionFundRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportServiceDirectionFundRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}


	};
};

//Report detail service direction - Report Request Fund
export const tryGetReportDetailServiceDirectionFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportDetailServiceDirectionFundRequest());

		if (accessToken) {

			const response = await SERVICES.getReportDetailServiceDirectionFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'pedidos_de_fondos_por_servicio_administrativo_o_direccion');
				dispatch(ACTIONS.getReportDetailServiceDirectionFundRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportDetailServiceDirectionFundRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportDetailServiceDirectionFundRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}


	};
}


//Report Request Fund - Report Request Fund
export const tryGetReportFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportFundRequest());

		if (accessToken) {

			const response = await SERVICES.getReportReportFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'pedidos_de_fondos');
				dispatch(ACTIONS.getReportFundRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportFundRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportFundRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}


	};
};

//Report provider - Report Request Fund
export const tryGetReportProviderFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportProviderFundRequest());

		if (accessToken) {

			const response = await SERVICES.getReportProviderFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'pedidos_de_fondos_por_proveedor');
				dispatch(ACTIONS.getReportProviderFundRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportProviderFundRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportProviderFundRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}


	};
};


//Report fund request bank account
export const tryGetReportFundRequestBankAccount = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportFundRequestBankAccountRequest());

		if (accessToken) {

			const response = await SERVICES.getReportFundRequestBankAccount(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'pedido_fondo_por_banco');
				dispatch(ACTIONS.getReportFundRequestBankAccountSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportFundRequestBankAccountSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportFundRequestBankAccountFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};


//Report Delete
export const tryGetReportDelete = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportDeleteRequest());

		if (accessToken) {

			const response = await SERVICES.deleteReport(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportDeleteSuccess());
				showSuccess('El reporte eliminado correctamente.');
				return response;
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportDeleteSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportDeleteFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al eliminar el reporte. Detalle: ${response?.error || response}`)
			}
		}

	};
};

//Report New Request Fund - Report Request Fund
export const tryGetReportNewFundRequest = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportNewFundRequest());

		if (accessToken) {

			const response = await SERVICES.getReportReportNewFundRequest(accessToken, params);

			if (response?.status == 200) {
				downloadStreamFile(response, 'resumen_pedidos_de_fondos');
				dispatch(ACTIONS.getReportNewFundRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportNewFundRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportNewFundRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}


	};
};

export const reportImport = (params) => {
	const messageError = "Ocurrió un error durante la importación del servicio, vuelva a intentarlo.";
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.startLoading());

		if (accessToken) {
			const response = await SERVICES.reportImport(accessToken, params);

			dispatch(ACTIONS.stopLoading());

			if (response?.data?.status != undefined) {
				switch (response.data.status) {
					case 200:
						showSuccess(response.data.message);
						return {
							message: response.data.message,
							success: true
						};
					case 400:
						showWarning(response.data.message);
						return {
							message: response.data.message,
							success: false
						};
					default:
						showError(messageError);
						return {
							message: messageError,
							success: false
						};
						
				}
			}
			else {
				showError(messageError);
				return {
					message: messageError,
					success: false
				};
			}
		}
	}
}

export const reportRevert = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.startLoading());
		
		if (accessToken) {
			const response = await SERVICES.reportRevert(accessToken, params);

			dispatch(ACTIONS.stopLoading());

			if (response?.data?.status != undefined) {
				switch (response.data.status) {
					case 200:
						showSuccess(response.data.message);
						break;
					case 500:
						showError(response.data.message);
						break;
					default:
						showError("Ocurrió un error durante la reversión del servicio");
						break;
				}
			}
			else {
				showError("Ocurrió un error durante la reversión del servicio");
			}
		}
	}
}

export const tryGetCCPMConnectivityStatus = () => { 

	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.connectivityCCPMStatusRequest());

		const response = await SERVICES.getConnectivityCCPMStatusService(accessToken);

		if(response?.status != undefined ) {
			switch (response?.status) {
				case 200: 
					dispatch( ACTIONS.connectivityCCPMStatusRequestSuccess(response?.data));
					return response?.data;
					break;
				case 400:
					dispatch( ACTIONS.connectivityCCPMStatusRequestFailure(response?.data) );
					return response?.data;
					break;
				case 500:
					dispatch( ACTIONS.connectivityCCPMStatusRequestFailure(response?.data) );
					return response?.data;
					break;
				default:
					dispatch( ACTIONS.connectivityCCPMStatusRequestFailure(response?.data) );
					showError(`Ha ocurrido un error al comunicarse con las fuentes de información externas. Por favor intente nuevamente más tarde.` );
					return response;
					break;
				} 
			}  else {
				dispatch( ACTIONS.connectivityCCPMStatusRequestFailure(response?.data) );
				showError(`Ha ocurrido un error al comunicarse con las fuentes de información externas. Por favor intente nuevamente más tarde.` );
				return response;
			}
	}
}

export const tryGetReportPaymentOrderById = id => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.startLoading());

		dispatch(ACTIONS.getReportConsolidated());

		if (accessToken) {

			const response = await SERVICES.getReportPaymentOrderById(accessToken, id);
			dispatch(ACTIONS.stopLoading());
			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_orden_de_pago');
				dispatch(ACTIONS.getReportConsolidatedSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else {
				dispatch(ACTIONS.getReportConsolidatedFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

export const tryGetReportFundRequestById = (id) => {
	return async (dispatch, getState) => {
	const state = getState();
	const accessToken = getAccessToken(state);
	dispatch(ACTIONS.startLoading());

	dispatch(ACTIONS.getReportConsolidated());

	if (accessToken) {
		const response = await SERVICES.getReportFundRequestById(accessToken, id);
		dispatch(ACTIONS.stopLoading());
		if (response?.status == 200) {
		downloadStreamFile(response, "reporte_pedido_de_fondos");
		dispatch(ACTIONS.getReportConsolidatedSuccess());
		showSuccess("El reporte se descargó correctamente.");
		} else {
		dispatch(
			ACTIONS.getReportConsolidatedFailure(
			response?.data?.error || response?.error
			)
		);
		showError(
			dispatch,
			`Ocurrió un error al descargar el reporte. Detalle: ${
			response?.response?.data?.message || response
			}`
		);
		}
	}
	};
};

//Report Determination Passive Residues
export const tryGetReportDeterminationPassiveResidues= (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportDeterminationPassiveResiduesRequest());

		if (accessToken) {

			const response = await SERVICES.getReportDeterminationPassiveResidues(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getReportDeterminationPassiveResiduesRequestSuccess());					
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportDeterminationPassiveResiduesRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportDeterminationPassiveResiduesRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};

//Report Pending Resolution Passive Residues 
export const tryGetReportPendingResolutionPassiveResidues = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportPendingResolutionPassiveResiduesRequest());

		if (accessToken) {

			const response = await SERVICES.getReportBalanceStatusExecution(accessToken, params);

			if (response?.status == 200) {
					dispatch(ACTIONS.getReportPendingResolutionPassiveResiduesRequestSuccess());
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportPendingResolutionPassiveResiduesRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportPendingResolutionPassiveResiduesRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
			return response;
		}

	};
};

//Report Accrual basis
export const tryGetAccrualBasisReport = (params) => {
	
	return async (dispatch, getState) => {
		
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAccrualBasisReportRequest());
		
		if (accessToken) {

			const response = await SERVICES.tryGetAccrualBasisReport(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.getAccrualBasisReportRequestSuccess());
			}
			else {
				dispatch(ACTIONS.getAccrualBasisReportRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al generar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}
	};
};

// Direct download Report Compensation
export const tryGetReportCompensationDirectDownload = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportCompensationDirectDownloadRequest());

		if (accessToken) {

			const response = await SERVICES.getReportCompensationDirectDownload(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getReportCompensationDirectDownloadRequestSuccess());
				downloadStreamFile(response, 'reporte_de_compensacion_de_partidas');
				showSuccess('El reporte se descargó correctamente.');
			}
			else if (response?.status == 204) {
				dispatch(ACTIONS.getReportCompensationDirectDownloadRequestSuccess());
				showWarning('No existen reportes del período seleccionado.');	
			}
			else {
				dispatch(ACTIONS.getReportCompensationDirectDownloadRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}

			return response;
		}

	};
};

export const tryGetReportProvisionOrderById = id => {
	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getReportProvisionOrderRequest());

		if (accessToken) {

			const response = await SERVICES.getReportProvisionOrderById(accessToken, id);

			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_orden_de_provision');
				dispatch(ACTIONS.getReportProvisionOrderRequestSuccess());
				showSuccess('El reporte se descargó correctamente.');
			}
			else {
				dispatch(ACTIONS.getReportProvisionOrderRequestFailure(response?.data?.error || response?.error));
				showError(`Ocurrió un error al descargar el reporte. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};