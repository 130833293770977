import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Button, Table, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faEdit, faExclamationTriangle, faBuilding, faCalendarTimes, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

import PageTitle from 'src/components/general/PageTitle';
import ActionIcon from 'src/components/general/ActionIcon';
import { isNotEmptyArray } from 'src/services/validationService';
import { getExerciseTypeValueToShow, dateNeutralFormatedToShowARG } from 'src/utils/utils';

import { EXERCISE_NEW, EXERCISE_EDIT_STEP_ONE, EXERCISE_DETAILS, EXERCISE_SERVICE_ASOCIATION } from 'src/utils/constants';

import { getUserPermissionsPeriods } from 'src/redux/login/loginReducer';

import { tryListExercises, tryClosedExercise, tryEnableExercise } from 'src/redux/exercise/exerciseActionCreator';
import { getExercisesListData, getExercisesListIsFetching } from 'src/redux/exercise/exerciseReducer';
import { setExerciseDetailsData, setExerciseToEditData, setSelectedLegalInstrument, setServicesManagementSelectedService, clearListExercisesData } from 'src/redux/exercise/exerciseActions';


const ExercisesListPage = () => {

	const dispatch = useDispatch();

	// Permissions
	const periodsPermissions = useSelector(state => getUserPermissionsPeriods(state));

	// Exercise list
	const exercisesListData = useSelector(state => getExercisesListData(state));
	const exercisesListRecords = exercisesListData?.records?.sort((a, b) => a.year < b.year ? 1 : -1);

	const exercisesListMetadata = exercisesListData?.metadata;
	const totalListRecords = exercisesListMetadata?.total;
	const hasMoreExercises = (exercisesListMetadata?.total > ((exercisesListMetadata?.page + 1) * exercisesListMetadata?.pageSize));

	const exercisesListIsFetching = useSelector(state => getExercisesListIsFetching(state));
	const hasExercisesList = isNotEmptyArray(exercisesListRecords);

	const onClickNewPeriodButton = () => {
		dispatch(push(EXERCISE_NEW));
	};

	const onClickServicesManagement = exerciseData => {
		dispatch(setServicesManagementSelectedService(exerciseData));
		dispatch(push(EXERCISE_SERVICE_ASOCIATION));
	};

	const onClickSeeDetails = exerciseData => {
		dispatch(setExerciseDetailsData(exerciseData));
		dispatch(push(EXERCISE_DETAILS));
	};

	const onClickEdit = exerciseData => {
		dispatch(setExerciseToEditData(exerciseData));
		dispatch(push(EXERCISE_EDIT_STEP_ONE));
	};

	const onClickCloseExercise = exerciseData => {
		swal({
			title: 'Confirmación',
			text: '¿Seguro desea cerrar el ejercicio: ' + exerciseData?.year +'?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((userAccept) => {
			if (userAccept) {
				let paramsToSend = {
					exerciseState: "CLOSED"
				}
				dispatch(tryClosedExercise(exerciseData.id, paramsToSend));
				dispatch(clearListExercisesData());
				dispatch(tryListExercises());

			}
		});
	};

	const onClickEnableExercise = exerciseData => {
		swal({
			title: 'Confirmación',
			text: '¿Seguro desea habilitar la ejecución del ejercicio: ' + exerciseData?.year +'?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((userAccept) => {
			if (userAccept) {
				let paramsToSend = {
					exerciseState: "EXECUTION"
				}
				dispatch(tryEnableExercise(exerciseData.id, paramsToSend));
				dispatch(clearListExercisesData());
				dispatch(tryListExercises());

			}
		});
	};

	useEffect(() => {
		const params = {
			page: 0,
			size: 10,
			sort: 'year,desc'
		};
		dispatch(tryListExercises(params));
		dispatch(setSelectedLegalInstrument(undefined));
		dispatch(setExerciseToEditData(undefined));
		dispatch(setExerciseDetailsData(undefined));
		dispatch(setServicesManagementSelectedService(undefined));
	}, []);

	const onClickLoadMore = () => {
		const params = {
			page: exercisesListMetadata?.page,
			size: totalListRecords,
			sort: exercisesListMetadata?.sort || 'year,desc'
		};
		dispatch(tryListExercises(params));
	};

	const onClickLoadLess = () => {
		const params = {
			page: exercisesListMetadata?.page,
			size: 10,
			sort: exercisesListMetadata?.sort || 'year,desc'
		};
		dispatch(tryListExercises(params));
	};

	return <Container fluid className='mb-5'>
		<Card>
			<PageTitle text='Ejercicios' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
						{
							periodsPermissions?.canCreate
								?
								<Button size='sm' className='mb-3' variant='success' onClick={onClickNewPeriodButton}>
									<FontAwesomeIcon icon={faPlus} className='mr-1' />
									Nuevo ejercicio
								</Button>
								:
								null
						}

						<Table striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center align-middle'>Año</th>
									<th className='text-center align-middle'>Fecha de inicio</th>
									<th className='text-center align-middle'>Fecha de fin</th>
									<th className='text-center align-middle'>Fecha complementaria</th>
									<th className='text-center align-middle'>Estado</th>
									<th className='text-center align-middle'>Acciones</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
								{
									hasExercisesList
										?
										<>
											{
												exercisesListRecords.map((exercise, index) => (
													<tr key={index}>
														<td className='text-center align-middle'>
															{exercise?.year}
														</td>
														<td className='text-center align-middle'>
															{dateNeutralFormatedToShowARG(exercise?.startDate)}
														</td>
														<td className='text-center align-middle'>
															{dateNeutralFormatedToShowARG(exercise?.endDate)}
														</td>
														<td className='text-center align-middle'>
															{dateNeutralFormatedToShowARG(exercise?.complementaryDate)}
														</td>
														<td className='text-center align-middle'>
															{getExerciseTypeValueToShow(exercise?.exerciseState)}
														</td>

														<td className='text-center align-middle'>
															{
																periodsPermissions?.canUpdate
																	?
																	<ActionIcon size='lg' id='service' toolTipText='Gestión de servicios' icon={faBuilding} mr='2' onClick={() => onClickServicesManagement(exercise)} />
																	:
																	null
															}
															{
																periodsPermissions?.canView
																	?
																	<ActionIcon size='lg' id='details' toolTipText='Ver detalles' icon={faSearch} mr='2' onClick={() => onClickSeeDetails(exercise)} />
																	:
																	null
															}
															{
																periodsPermissions?.canUpdate
																	?
																	<ActionIcon size='lg' id='edit' disabled={exercise?.exerciseState == 'CLOSED' ? true : false} toolTipText='Editar el período complementario' icon={faEdit} mr='2' onClick={() => onClickEdit(exercise)} />
																	:
																	null
															}
															{
																periodsPermissions?.canUpdate
																	?
																	<ActionIcon size='lg' id='close' disabled={exercise?.exerciseState == 'CLOSED' ? true : false} toolTipText='Cerrar ejercicio' icon={faCalendarTimes} mr='2' onClick={() => onClickCloseExercise(exercise)} />
																	:
																	null
															}
															{
																periodsPermissions?.canUpdate
																	?
																	<ActionIcon size='lg' id='enableexecution' disabled={exercise?.exerciseState == 'FORMULATION' ? false : true} toolTipText='Habilitar ejecución del ejercicio' icon={faClipboardCheck} mr='2' onClick={() => onClickEnableExercise(exercise)} />
																	:
																	null
															}

														</td>

													</tr>))
											}
										</>
										:
										<tr>
											<td colSpan='6' className='text-center'>
												{
													exercisesListIsFetching
														?
														<Spinner animation='border' variant='success' className='my-3' />
														:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															No hay partidas
														</Alert>
												}
											</td>
										</tr>
								}
							</tbody>
						</Table>

						{
							hasMoreExercises
								?
								<Row>
									<Col className='text-center'>
										<Button size='sm' variant="danger" className='px-5' disabled={exercisesListIsFetching} onClick={onClickLoadMore} >
											{
												exercisesListIsFetching
													?
													<Spinner animation='border' size='sm' />
													:
													<>Ver más</>
											}
										</Button>
									</Col>
								</Row>
								:
								<Row>
									<Col className='text-center'>
										<Button size='sm' variant="danger" className='px-5' disabled={exercisesListIsFetching} onClick={onClickLoadLess}>
											{
												exercisesListIsFetching ?
													<Spinner animation='border' size='sm' />
													:
													<>{'Ver menos'}</>
											}
										</Button>
									</Col>
								</Row>
						}
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ExercisesListPage;