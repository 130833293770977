export const LIST_USER_REQUEST = 'LIST_USER_REQUEST';
export const LIST_USER_REQUEST_SUCCESS = 'LIST_USER_REQUEST_SUCCESS';
export const LIST_USER_REQUEST_FAILURE = 'LIST_USER_REQUEST_FAILURE';

export const CLEAR_LIST_USER = 'CLEAR_LIST_USER';

// User Detail
export const DETAIL_USER_REQUEST = 'DETAIL_USER_REQUEST';
export const DETAIL_USER_REQUEST_SUCCESS = 'DETAIL_USER_REQUEST_SUCCESS';
export const DETAIL_USER_REQUEST_FAILURE = 'DETAIL_USER_REQUEST_FAILURE';
export const SET_USER = 'SET_USER';

// User paginated list
export const USER_PAGINATED_LIST_REQUEST = 'USER_PAGINATED_LIST_REQUEST';
export const USER_PAGINATED_LIST_REQUEST_SUCCESS = 'USER_PAGINATED_LIST_REQUEST_SUCCESS';
export const USER_PAGINATED_LIST_REQUEST_FAILURE = 'USER_PAGINATED_LIST_REQUEST_FAILURE';

//User New
export const NEW_USER_REQUEST = 'NEW_USER_REQUEST';
export const NEW_USER_REQUEST_SUCCESS = 'NEW_USER_REQUEST_SUCCESS';
export const NEW_USER_REQUEST_FAILURE = 'NEW_USER_REQUEST_FAILURE';

//User Edit
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_REQUEST_SUCCESS = 'EDIT_USER_REQUEST_SUCCESS';
export const EDIT_USER_REQUEST_FAILURE = 'EDIT_USER_REQUEST_FAILURE';
export const SET_USER_TO_EDIT = 'SET_USER_TO_EDIT';

//Delete User
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_REQUEST_SUCCESS = 'DELETE_USER_REQUEST_SUCCESS';
export const DELETE_USER_REQUEST_FAILURE = 'DELETE_USER_REQUEST_FAILURE';

//Profiles
export const LIST_PROFILE_REQUEST = 'LIST_PROFILE_REQUEST';
export const LIST_PROFILE_REQUEST_SUCCESS = 'LIST_PROFILE_REQUEST_SUCCESS';
export const LIST_PROFILE_REQUEST_FAILURE = 'LIST_PROFILE_REQUEST_FAILURE';

export const CLEAR_LIST_PROFILE = 'CLEAR_LIST_PROFILE';
export const CLEAR_PROFILE_TO_EDIT = 'CLEAR_PROFILE_TO_EDIT';

// Add User X Application X Profile
export const CREATE_USER_X_APPLICATION_PROFILE_REQUEST = 'CREATE_USER_X_APPLICATION_PROFILE_REQUEST';
export const CREATE_USER_X_APPLICATION_PROFILE_REQUEST_SUCCESS = 'CREATE_USER_X_APPLICATION_PROFILE_REQUEST_SUCCESS';
export const CREATE_USER_X_APPLICATION_PROFILE_REQUEST_FAILURE = 'CREATE_USER_X_APPLICATION_PROFILE_REQUEST_FAILURE';

// Edit User X Application X Profile
export const EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST = 'EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST';
export const EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST_SUCCESS = 'EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST_SUCCESS';
export const EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST_FAILURE = 'EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST_FAILURE';

// Get Users X Application X Profile
export const LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST = 'LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST';
export const LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST_SUCCESS = 'LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST_SUCCESS';
export const LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST_FAILURE = 'LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST_FAILURE';

//Edit User Change Password
export const EDIT_USER_CHANGE_PASSWORD_REQUEST = 'EDIT_USER_CHANGE_PASSWORD_REQUEST';
export const EDIT_USER_CHANGE_PASSWORD_REQUEST_SUCCESS = 'EDIT_USER_CHANGE_PASSWORD_REQUEST_SUCCESS';
export const EDIT_USER_CHANGE_PASSWORD_REQUEST_FAILURE = 'EDIT_USER_CHANGE_PASSWORD_REQUEST_FAILURE';

//send validation email
export const SEND_VALIDATION_EMAIL_REQUEST = 'SEND_VALIDATION_EMAIL_REQUEST';
export const SEND_VALIDATION_EMAIL_REQUEST_SUCCESS = 'SEND_VALIDATION_EMAIL_REQUEST_SUCCESS';
export const SEND_VALIDATION_EMAIL_REQUEST_FAILURE = 'SEND_VALIDATION_EMAIL_REQUEST_FAILURE';

// Set User Global
export const SET_USER_GLOBAL = 'SET_USER_GLOBAL';

// Set User New  Jurisdiction
export const SET_USER_NEW_JURISDICTION = 'SET_USER_NEW_JURISDICTION';

//Get persona
export const GET_PERSONA_BY_CUIT_REQUEST = 'GET_PERSONA_BY_CUIT_REQUEST';
export const GET_PERSONA_BY_CUIT_SUCCESS = 'GET_PERSONA_BY_CUIT_SUCCESS';
export const GET_PERSONA_BY_CUIT_FAILURE = 'GET_PERSONA_BY_CUIT_FAILURE';
export const CLEAR_PERSONAL_NAME_BY_CUIT = 'CLEAR_PERSONAL_NAME_BY_CUIT';