import * as ACTION_TYPES from './alertActionTypes';

export const alertReducerInitialState = {
	alertList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	alertNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	alertDetail: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	alertEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	alertDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	alertActive: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	alertToEdit: {},
	alertToDetails: {},
	alertActiveToSet: {}
};

export const alertReducer = ( state=alertReducerInitialState, action ) => {
	switch( action.type ) {

		// Alert List 
		case ACTION_TYPES.LIST_ALERT_REQUEST: {
			return {
				...state,
				alertList: {
					...state.alertList,
					isFetching: true,
					hasError: false,
					error: alertReducerInitialState.alertList.error,
					data: alertReducerInitialState.alertList.data,
				}
			};
		}

		case ACTION_TYPES.LIST_ALERT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				alertList: {
					...state.alertList,
					isFetching: false,
					hasError: false,
					error: alertReducerInitialState.alertList.error,
					data
				}
			};
		}
		
		case ACTION_TYPES.LIST_ALERT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				alertList: {
					...state.alertList,
					isFetching: false,
					hasError: true,
					data: alertReducerInitialState.alertList.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_ALERT: {
			const { data } = action.payload;
			return {
				...state,
				alertToDetails: data
			}
		}

		case ACTION_TYPES.CLEAR_ALERT_LIST: {
			return {
				...state,
				alertList: alertReducerInitialState.alertList
			};
		}
		// END // Alert List 

		//Detail Alert
		case ACTION_TYPES.DETAIL_ALERT_REQUEST: {
			return {
				...state,
				alertDetail: {
					...state.alertDetail,
					isFetching: true,
					hasError: false,
					error: alertReducerInitialState.alertDetail.error
				}
			};
		}

		case ACTION_TYPES.DETAIL_ALERT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				alertDetail: {
					...state.alertDetail,
					isFetching: false,
					hasError: false,
					error: alertReducerInitialState.alertDetail.error,
					data
				}
			};
		}

		case ACTION_TYPES.DETAIL_ALERT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				alertDetail: {
					...state.alertDetail,
					isFetching: false,
					hasError: true,
					data: alertReducerInitialState.alertDetail.data,
					error
				}
			};
		}

		//New Alert
		case ACTION_TYPES.NEW_ALERT_REQUEST: {
			return {
				...state,
				alertNew: {
					...state.alertNew,
					isFetching: true,
					hasError: false,
					error: alertReducerInitialState.alertNew.error
				}
			};
		}

		case ACTION_TYPES.NEW_ALERT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				alertNew: {
					...state.alertNew,
					isFetching: false,
					hasError: false,
					error: alertReducerInitialState.alertNew.error,
					data
				}
			};
		}

		case ACTION_TYPES.NEW_ALERT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				alertNew: {
					...state.alertNew,
					isFetching: false,
					hasError: true,
					data: alertReducerInitialState.alertNew.data,
					error
				}
			};
		}

		//Edit Alert
		case ACTION_TYPES.EDIT_ALERT_REQUEST: {
			return {
				...state,
				alertEdit: {
					...state.alertEdit,
					isFetching: true,
					hasError: false,
					error: alertReducerInitialState.alertEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_ALERT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				alertEdit: {
					...state.alertEdit,
					isFetching: false,
					hasError: false,
					error: alertReducerInitialState.alertEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_ALERT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				alertEdit: {
					...state.alertEdit,
					isFetching: false,
					hasError: true,
					data: alertReducerInitialState.alertEdit.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_ALERT_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				alertToEdit: data
			}
		}

		//Delete Alert
		case ACTION_TYPES.DELETE_ALERT_REQUEST: {
			return {
				...state,
				alertDelete: {
					...state.alertDelete,
					isFetching: true,
					hasError: false,
					error: alertReducerInitialState.alertDelete.error
				}
			};
		}

		case ACTION_TYPES.DELETE_ALERT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				alertDelete: {
					...state.alertDelete,
					isFetching: false,
					hasError: false,
					error: alertReducerInitialState.alertDelete.error,
					data
				}
			};
		}

		case ACTION_TYPES.DELETE_ALERT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				alertDelete: {
					...state.alertDelete,
					isFetching: false,
					hasError: true,
					data: alertReducerInitialState.alertDelete.data,
					error
				}
			};
		}

		// Alert Active 
		case ACTION_TYPES.ACTIVE_ALERT_REQUEST: {
			return {
				...state,
				alertActive: {
					...state.alertActive,
					isFetching: true,
					hasError: false,
					error: alertReducerInitialState.alertActive.error,
					data: alertReducerInitialState.alertActive.data,
				}
			};
		}

		case ACTION_TYPES.ACTIVE_ALERT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				alertActive: {
					...state.alertActive,
					isFetching: false,
					hasError: false,
					error: alertReducerInitialState.alertActive.error,
					data
				}
			};
		}
		
		case ACTION_TYPES.ACTIVE_ALERT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				alertActive: {
					...state.alertActive,
					isFetching: false,
					hasError: true,
					data: alertReducerInitialState.alertActive.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_ALERT_ACTIVE: {
			const { data } = action.payload;
			return {
				...state,
				alertActiveToSet: data
			}
		}



		default: {
			return state;
		}
	}
};

export const getAlertReducer = state => state.alertReducer;

export const getAlertList = state => getAlertReducer(state)?.alertList;
export const getAlertListData = state => getAlertList(state)?.data;
export const getAlertListError = state => getAlertList(state)?.error;
export const getAlertListIsFetching = state => getAlertList(state)?.isFetching;
export const getAlertListHasError = state => getAlertList(state)?.hasError;

// Alert Detail
export const getAlertDetail = state => getAlertReducer(state)?.alertDetail;
export const getAlertDetailData = state => getAlertDetail(state)?.data;
export const getAlertDetailIsFetching = state => getAlertDetail(state)?.isFetching;

// New Alert
export const getAlertCreate = state => getAlertReducer(state)?.alertNew;
export const getAlertCreateIsFetching = state => getAlertCreate(state)?.isFetching;

// Alert to edit
export const getAlertToEdit = state => getAlertReducer(state)?.alertToEdit;
export const getAlertEdit = state => getAlertReducer(state)?.alertEdit;
export const getAlertEditIsFetching = state => getAlertEdit(state)?.isFetching;

// Alert to details
export const getAlertToDetail = state => getAlertReducer(state)?.alertToDetails;

// Alert Active
export const getActiveAlert = state => getAlertReducer(state)?.alertActive;
export const getActiveAlertData = state => getActiveAlert(state)?.data;
export const getActiveAlertError = state => getActiveAlert(state)?.error;
export const getActiveAlertIsFetching = state => getActiveAlert(state)?.isFetching;
export const getActiveAlertHasError = state => getActiveAlert(state)?.hasError;

export const getAlertActiveSet = state => getAlertReducer(state)?.alertActiveToSet;
