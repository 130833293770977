import * as ACTION_TYPES from './bankAccountActionTypes';

export const getBankAccountListRequest = () => ({
	type: ACTION_TYPES.BANK_ACCOUNT_LIST_REQUEST
});

export const getBankAccountListRequestSuccess = data => ({
	type: ACTION_TYPES.BANK_ACCOUNT_LIST_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getBankAccountListRequestFailure = error => ({
	type: ACTION_TYPES.BANK_ACCOUNT_LIST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearBankAccountListData = () => ({
	type: ACTION_TYPES.CLEAR_BANK_ACCOUNT_LIST_DATA
})
// export const clearUserList = () => ({
// 	type: ACTION_TYPES.CLEAR_LIST_USER
// });
