import * as ACTION_TYPES from './notificationActionTypes';

export const getNotificationListRequest = () => ({
	type: ACTION_TYPES.LIST_NOTIFICATION_REQUEST
});

export const getNotificationListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_NOTIFICATION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getNotificationListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_NOTIFICATION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearNotificationList = () => ({
	type: ACTION_TYPES.CLEAR_NOTIFICATION_LIST
});

// Notification by user
export const getNotificationByUserListRequest = () => ({
	type: ACTION_TYPES.LIST_NOTIFICATION_BY_USER_REQUEST
})
export const getNotificationByUserListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_NOTIFICATION_BY_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
})
export const clearNotificationByUserList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_NOTIFICATION_BY_USER
})
export const postNotificationByUserRequest = () => ({
	type: ACTION_TYPES.DELETE_NOTIFICATION_BY_USER_REQUEST
})

// Add notification actions
export const addNotificationRequest = params => ({
    type: ACTION_TYPES.NEW_NOTIFICATION_REQUEST,
    payload: {
        params
    }
});
export const addNotificationRequestSuccess = data => ({
    type: ACTION_TYPES.NEW_NOTIFICATION_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const addNotificationRequestFailure = error => ({
    type: ACTION_TYPES.NEW_NOTIFICATION_REQUEST_FAILURE,
    payload: {
        error
    }
});

// Detail Notification
export const getNotificationDetailRequest = () => ({
	type: ACTION_TYPES.DETAIL_NOTIFICATION_REQUEST
});

export const getNotificationDetailRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_NOTIFICATION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getNotificationDetailRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_NOTIFICATION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setNotificationToDetails = data => ({
	type: ACTION_TYPES.SET_NOTIFICATION,
	payload: {
		data
	}
});

// Download file of notification

export const downloadFileOfNotificationRequest = () => ({
	type: ACTION_TYPES.DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST
});
export const downloadFileOfNotificationRequestSuccess = () => ({
	type: ACTION_TYPES.DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_SUCCESS
});
export const downloadFileOfNotificationRequestFailure = error => ({
	type: ACTION_TYPES.DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_FAILURE,
	payload: {
		error
	}
});

// END Download file of notification
