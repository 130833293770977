import * as ACTIONS from './jurisdictionActions';
import * as SERVICES from 'src/services/jurisdictionServices';
import * as ADMINISTRATIVE_SERVICE_SERVICES from 'src/services/administrativeServiceServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetJurisdictionList = ( page=0, size=1000 ) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getJurisdictionListRequest() );

		if(accessToken){

			const response = await SERVICES.listJurisdiction( accessToken, page, size );
			
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getJurisdictionListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getJurisdictionListRequestFailure(response?.error||response) );
				showError(`Error al cargar las jurisdicciones. Detalle: ${response?.error||response}` );
			}
		}

	};
};

// Jurisdictions by Services and Exercise 
export const tryGetJurisdictionsByServicesAndExercise = ( servicesId, exerciseId ) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getJurisdictionsByServicesAndExerciseRequest() );

		if(accessToken){

			const response = await ADMINISTRATIVE_SERVICE_SERVICES.getJurisdictionsByServicesIdAndExerciseId( accessToken, servicesId, exerciseId );
			
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getJurisdictionsByServicesAndExerciseRequestSuccess(response?.data) );
				return response?.data[0];
			}
			else {
				dispatch( ACTIONS.getJurisdictionsByServicesAndExerciseRequestFailure(response?.error||response) );
				showError(`Error al cargar las jurisdicciones por servicio y ejercicio. Detalle: ${response?.error||response}` );
			}
		}

	
	};
};