import * as ACTIONS from './orderPayActions';
import * as SERVICES from 'src/services/orderPayServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

// Try list Order Pay
export const tryListOrderPay = params => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listOrderPayRequest());

		if (accessToken) {

			const response = await SERVICES.listOrderPay(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listOrderPayRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listOrderPayRequestFailure(response?.response?.data));
				showError(`Error al obtener las ordenes de pagos. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}

	}
};

// Try Set  Fund Requests
export const tryOrderPayById = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getOrderPayByIdRequest());

		if (accessToken) {

			const response = await SERVICES.getOrderPayId(accessToken, id);

			if (response?.status === 200) {
				dispatch(ACTIONS.getOrderPayByIdRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getOrderPayByIdRequestFailure(response?.error || response));
				showError(`Error al cargar datos. Detalle: ${response?.error || response}`);
			}
		}

	};
};

// Try Detail Order Pay
export const tryDetailOrderPay = ordenPayID => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.detailOrderPayRequest());

		if (accessToken) {

			const response = await SERVICES.detailOrderPay(accessToken, ordenPayID);

			if (response?.status == 200) {
				dispatch(ACTIONS.detailOrderPayRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.detailOrderPayRequestFailure(response?.response?.data));
				showError(`Error al obtener el detalle de la orden de pago. Detalle: ${response?.response?.data?.message || response}`);
			}
		}

	}
};

//Create
export const tryPostOrderPay = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAddOrderPayRequest());

		if (accessToken) {

			const response = await SERVICES.newOrderPay(accessToken, params);
			if (response?.status> 199 && response?.status < 300){
				dispatch(ACTIONS.getAddOrderPayRequestSuccess(response?.data));
				showSuccess('Orden de pago registrado correctamente.');
			}
			else {
				dispatch(ACTIONS.getAddOrderPayRequestFailure(response?.data?.error));
				showError(`Error al crear la orden de pago. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Edit
export const tryPutOrderPay = (serviceId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getEditOrderPayRequest());

		if (accessToken) {

			const response = await SERVICES.editOrderPay(accessToken, serviceId, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getEditOrderPayRequestSucces(response?.data));
				showSuccess('Orden de pago editado correctamente.');
			}
			else {
				dispatch(ACTIONS.getEditOrderPayRequestFailure(response?.data?.error));
				showError(`Error al editar la orden de pago. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Delete
export const tryDeleteOrderPay = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getDeleteOrderPayRequest());

		if (accessToken) {
			const response = await SERVICES.deleteOrderPay(accessToken, params);
			if (response?.status == 204) {
				dispatch(ACTIONS.getDeleteOrderPayRequestSuccess(response?.data));
				showSuccess('Orden de pago eliminado correctamente.');
			}
			else {
				dispatch(ACTIONS.getDeleteOrderPayRequestFailure(response?.data?.error));
				showError(`Error al eliminar la orden de pago. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

// Try affectations movements by ID
export const tryGetAffectationsMovementsByIDOrderPay = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAffectationsMovementsByIDOrderPayRequest());

		if (accessToken) {

			const response = await SERVICES.getAffectationsMovementsByIDOrderPay(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getAffectationsMovementsByIDOrderPayRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getAffectationsMovementsByIDOrderPayRequestFailure(response?.response?.data));
				showError(`Error al obtener los movimientos de las afectacciones. Detalle: ${response?.response?.data?.message || response}`);
			}
		}

	}
};


// Try list Order Pay
export const tryListPayOrderTypes = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listPayOrderTypesRequest());

		if (accessToken) {

			const response = await SERVICES.listPayOrderTypes(accessToken);

			if (response?.status == 200) {
				dispatch(ACTIONS.listPayOrderTypesRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listPayOrderTypesRequestFailure(response?.response?.data));
				showError(`Error al obtener los tipos de ordenes de pagos. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}
	}
};


// Try list last Order Pay
export const tryListPayOrderLast = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listPayOrderLastRequest());

		if (accessToken) {

			const response = await SERVICES.listPayOrderLast(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listPayOrderLastRequestSuccess(response?.data)); 
				return response
			}
			else {
				dispatch(ACTIONS.listPayOrderLastRequestFailure(response?.response?.data));
				showError(`Error al obtener la ultimo ordene de pago. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}
	}
};

// Try list Provision Order
export const tryListPovisionOrder = params => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listPovisionOrderRequest());

		if (accessToken) {

			const response = await SERVICES.getListPovisionOrder(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listPovisionOrderRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listPovisionOrderRequestFailure(response?.response?.data));
				showError(`Error al obtener las ordenes de provisión. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}

	}
};

// Try list affectations by order pay type
export const tryListAffectationsByOrderType = params => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listAffectationsByOrderTypeRequest());

		if (accessToken) {

			const response = await SERVICES.listAffectationsByOrderTypeService(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listAffectationsByOrderTypeRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listAffectationsByOrderTypeRequestFailure(response?.response?.data));
				showError(`Error al obtener las afectaciones. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}

	}
};

export const tryListFigurativesByOPId = params => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listFigurativesByOPIdRequest());

		if (accessToken) {

			const response = await SERVICES.listFigurativesByOPId(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listFigurativesByOPIdRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listFigurativesByOPIdRequestFailure(response?.response?.data));
				showError(`Error al obtener las afectaciones. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}

	}
};

export const tryListOrderPayByAdministrativeDocument = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getOrderPayListByAdministrativeDocumentRequest());
		if (accessToken) {
			const response = await SERVICES.listOrderPayByAdministrativeDocument(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getOrderPayListByAdministrativeDocumentRequestSuccess(response?.data));
			} else {
				dispatch(ACTIONS.getOrderPayListByAdministrativeDocumentRequestFailure(response?.response?.data));
				showError(`Error al obtener las ordenes de pagos. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}
	}
}

export const tryListWorkCertificateTypes = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.listWorkCertificateTypesRequest());
		if (accessToken) {
			const response = await SERVICES.listWorkCertificateTypes(accessToken);
			if (response?.status == 200) {
				dispatch(ACTIONS.listWorkCertificateTypesRequestSuccess(response?.data));
			} else {
				dispatch(ACTIONS.listWorkCertificateTypesRequestFailure(response?.response?.data));
				showError(`Error al obtener los tipos de Certificados. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response
		}
	}
}