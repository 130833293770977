import { push } from 'connected-react-router';

import * as ACTIONS from './legalInstrumentActions';
import * as SERVICES from 'src/services/legalInstrumentServices';
import * as CREDIT_ACTIONS from 'src/redux/credit/creditActions';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';
import { downloadStreamFile } from 'src/utils/utils';

// List legal instrument
export const tryListLegalInstrument = params => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.listLegalInstrumentRequest() );

		if(accessToken){

			const response = await SERVICES.getLegalInstruments( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.listLegalInstrumentRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.listLegalInstrumentRequestFailure(response?.error||response) );
			}
		}

	};
};

// Create legal instrument
export const tryCreateLegalInstrument = params => {
	return async( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.createLegalInstrumentRequest() );

		if(accessToken){
			
			const response = await SERVICES.postLegalInstrument( accessToken, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.createLegalInstrumentRequestSuccess() );
			}
			else {
				dispatch( ACTIONS.createLegalInstrumentRequestFailure(response?.data?.message||response) );
				showError(`Error al crear el instrumento legal. Detalle: ${response?.data?.message||response}` );
			}
	
			return response;
		}

	};
};

// Download file of legal instrument
export const tryDownloadFileOfLegalInstrument = legalInstrument => {
	return async( dispatch, getState ) => {
		if( !legalInstrument ) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.downloadFileOfLegalInstrumentRequest() );

		if(accessToken){

			const response = await SERVICES.getFileOfLegalInstruments( accessToken, legalInstrument?.id );
			
			if( response?.status == 200 ) {
				dispatch( ACTIONS.downloadFileOfLegalInstrumentRequestSuccess() );
				showSuccess('El archivo fue descargado correctamente.' );
				downloadStreamFile( response, 'instrumento_legal' );
			}
			else {
				dispatch( ACTIONS.downloadFileOfLegalInstrumentRequestFailure(response?.error||response) );
				showError(`Error al descargar el archivo. Detalle: ${response?.error||response}` );
			}
		}

	};
};

// Upload file to legal instrument
export const tryUploadFileToLegalInstrument = ( legalInstrumentId, params ) => {
	return async( dispatch, getState ) => {

		if( !legalInstrumentId ) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.uploadFileToLegalInstrumentRequest() );

		if(accessToken){

			const response = await SERVICES.postFileToLegalInstruments( accessToken, legalInstrumentId, params );
	
			if( response?.status == 200 ) {
				dispatch( ACTIONS.uploadFileToLegalInstrumentRequestSuccess() );
				showSuccess('El archivo fue subido correctamente.' );
			}
			else {
				dispatch( ACTIONS.uploadFileToLegalInstrumentRequestFailure(response?.error||response) );
				showError(`Error al cargar el archivo. Detalle: ${response?.error||response}` );
			}
		}

	};
};