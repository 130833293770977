import * as ACTION_TYPES from './jurisdictionActionTypes';

export const jurisdictionReducerInitialState = {
	jurisdictionList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	jurisdictionsByServicesAndExercise: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const jurisdictionReducer = ( state=jurisdictionReducerInitialState, action ) => {
	switch( action.type ) {

		case ACTION_TYPES.LIST_JURISDICTION_REQUEST: {
			return {
				...state,
				jurisdictionList: {
					...state.jurisdictionList,
					isFetching: true,
					hasError: false,
					error: jurisdictionReducerInitialState.jurisdictionList.error,
					data: jurisdictionReducerInitialState.jurisdictionList.data,
				}
			};
		}

		case ACTION_TYPES.LIST_JURISDICTION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				jurisdictionList: {
					...state.jurisdictionList,
					isFetching: false,
					hasError: false,
					error: jurisdictionReducerInitialState.jurisdictionList.error,
					data
				}
			};
		}
		
		case ACTION_TYPES.LIST_JURISDICTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				jurisdictionList: {
					...state.jurisdictionList,
					isFetching: false,
					hasError: true,
					data: jurisdictionReducerInitialState.jurisdictionList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_JURISDICTION_LIST: {
			return {
				...state,
				jurisdictionList: jurisdictionReducerInitialState.jurisdictionList
			};
		}


		// Jurisdictions by Services and Exercise 
		case ACTION_TYPES.JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST: {
			return {
				...state,
				jurisdictionsByServicesAndExercise: {
					...state.jurisdictionsByServicesAndExercise,
					isFetching: true,
					hasError: false,
					error: jurisdictionReducerInitialState.jurisdictionsByServicesAndExercise.error,
					data: jurisdictionReducerInitialState.jurisdictionsByServicesAndExercise.data,
				}
			};
		}

		case ACTION_TYPES.JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				jurisdictionsByServicesAndExercise: {
					...state.jurisdictionsByServicesAndExercise,
					isFetching: false,
					hasError: false,
					error: jurisdictionReducerInitialState.jurisdictionsByServicesAndExercise.error,
					data
				}
			};
		}
		
		case ACTION_TYPES.JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				jurisdictionsByServicesAndExercise: {
					...state.jurisdictionsByServicesAndExercise,
					isFetching: false,
					hasError: true,
					data: jurisdictionReducerInitialState.jurisdictionsByServicesAndExercise.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID: {
			return {
				...state,
				jurisdictionsByServicesAndExercise: jurisdictionReducerInitialState.jurisdictionsByServicesAndExercise
			};
		}
		// END Jurisdictions by Services and Exercise 


		default: {
			return state;
		}
	}
};

export const getJurisdictionReducer = state => state.jurisdictionReducer;

export const getJurisdictionList = state => getJurisdictionReducer(state)?.jurisdictionList;
export const getJurisdictionListData = state => getJurisdictionList(state)?.data;
export const getJurisdictionListError = state => getJurisdictionList(state)?.error;
export const getJurisdictionListIsFetching = state => getJurisdictionList(state)?.isFetching;
export const getJurisdictionListHasError = state => getJurisdictionList(state)?.hasError;

export const getJurisdictionListById = (state, jurisdictionId) => getJurisdictionListData(state)?.data?.find(item => item.id == jurisdictionId);


// Jurisdictions by Services and Exercise 
export const getJurisdictionsByServicesAndExercise = state => getJurisdictionReducer(state)?.jurisdictionsByServicesAndExercise;
export const getJurisdictionsByServicesAndExerciseData = state => getJurisdictionsByServicesAndExercise(state)?.data;
export const getJurisdictionsByServicesAndExerciseError = state => getJurisdictionsByServicesAndExercise(state)?.error;
export const getJurisdictionsByServicesAndExerciseIsFetching = state => getJurisdictionsByServicesAndExercise(state)?.isFetching;
export const getJurisdictionsByServicesAndExerciseHasError = state => getJurisdictionsByServicesAndExercise(state)?.hasError;

