import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const BANK_ACCOUNT_ENDPOINT = '/bank-accounts';

export const getAll = async ( accessToken, params) => {

	const setbuildURLQuery = {
		...params
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${BANK_ACCOUNT_ENDPOINT}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};