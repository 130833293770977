import * as ACTION_TYPES from './proveedoresActionTypes';

export const proveedoresReducerInitialState = {
	listProveedores: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const proveedoresReducer = (state = proveedoresReducerInitialState, action) => {
	switch (action.type) {

		case ACTION_TYPES.LIST_PROVEEDORES_REQUEST: {
			return {
				...state,
				listProveedores: {
					...state.listProveedores,
					isFetching: true,
					hasError: false,
					error: proveedoresReducerInitialState.listProveedores.error,
					data: proveedoresReducerInitialState.listProveedores.data
				}
			};
		}
		case ACTION_TYPES.LIST_PROVEEDORES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listProveedores: {
					...state.listProveedores,
					isFetching: false,
					hasError: false,
					error: proveedoresReducerInitialState.listProveedores.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_PROVEEDORES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listProveedores: {
					...state.listProveedores,
					isFetching: false,
					hasError: true,
					data: proveedoresReducerInitialState.listProveedores.data,
					error
				}
			};
		}
		
		default: {
			return state;
		}
	}
};

export const getProveedoresReducer = state => state.proveedoresReducer;

export const getProveedoresList = state => getProveedoresReducer(state)?.listProveedores;
export const getProveedoresListData = state => getProveedoresList(state)?.data;
export const getProveedoresListFetching = state => getProveedoresList(state)?.isFetching;
