import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import 'react-widgets/styles.css';
import AppLoading from 'src/components/common/AppLoading';

import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';

import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportBalanceStatus } from 'src/redux/reports/reportsActionCreactor';
import { getReportBalanceStatusExecutionIsFetching } from 'src/redux/reports/reportsReducer';
import { getListAllServiceAdministrativeByUserData, getListAllServiceAdministrativeByUserIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import { clearListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActions';
import ExerciseServicesDateTo from 'src/components/pages/Reports/partials/ExerciseServicesDateTo';
import { shortlistedService } from 'src/utils/utils';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const ReportBalanceStatusPage = () => {
	
	const dispatch = useDispatch();
	const hookFormMethods = useForm();

	//User Id
	const email = useSelector( state => getEmail(state) );
	const listUserData = useSelector(state => getUserListData (state));
	const userId = listUserData?.records?.find(item => item.email == email);

	const reportDates = useSelector( state => getReportDatesData(state) );

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	//Services Administrative
	const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

	const [dateTo, setDateTo] = useState(reportDates?.dateTo);

	const [dateHasErrors, setDateHasErrors] = useState(false);
	const [showMsgReportInProcess, setShowMsgReportInProcess] = useState(false);
	
	const [checkWithoutBalance, setCheckWithoutBalance] = useState(true);
	
	const checkWithoutBalanceLabel = 'Mostrar solo los  Expedientes con las etapas del gasto no igualadas';

	useEffect(() => {
		dispatch(clearListAllServiceAdministrativeByUser());
		dispatch(tryGetUserList());
		// dispatch(clearReportBudgetAnalyticalAccountingCreditExecution());

		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));

	}, []);

	const getReport = outputFormat => {
		if (globalSelectedPeriod) {
			setShowMsgReportInProcess(false);
			const params = {
				outputFormat,
				exerciseId: globalSelectedPeriod?.id,
				serviceIds: selected?.map(administrativeService => administrativeService?.value),
				dateTo,
				withoutBalance: checkWithoutBalance ? 1 : 0,
			};
			dispatch(tryGetReportBalanceStatus(params)).then( response => {
				if(response?.status == 200){
					setShowMsgReportInProcess(true);
				}
			});
		}

	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	//// Multiselect
	// View items selector
	const [options, setOptions] = useState([]);

	useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

	useEffect(() => {
		const uniqueService = shortlistedService(options);
		if(uniqueService){
			setSelected(options);
		}
	}, [options]);

	// Add options
	const [selected, setSelected] = useState([]);
	
	const hasValueCustomValueRenderer = (selected.length === 0);		
	
	// Reports redux
	const reportIsFetching = useSelector(state => getReportBalanceStatusExecutionIsFetching(state));
	const allowGetReport = (hasPeriodList && !reportIsFetching && dateTo && !hasValueCustomValueRenderer && !dateHasErrors );

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Reporte de estado de saldos</h1>
                        <a 	className='text-white'
							target="_blank"
							href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#estado-de-saldos'
						>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header> 
					<Card.Body>
						<Form>
							<Row>
								<Col sm={2}></Col>
								<Col sm={8}>

									<ExerciseServicesDateTo 
										globalSelectedPeriodYear={globalSelectedPeriod?.year}
										{...{dateTo, setDateTo, setDateHasErrors, selected, setSelected, options, administrativeServiceIsFetching, hookFormMethods}} />

									<Form.Group>																										
										<Form.Check 
											type="checkbox"
											id="totalCheckAllow"
											label={checkWithoutBalanceLabel}
											className="text-black-color"
											checked={checkWithoutBalance}
											onChange={()=> {
												setCheckWithoutBalance(!checkWithoutBalance)
											}}
										/>
									</Form.Group>

									{
										showMsgReportInProcess &&
										<Alert key={'1'} variant={'primary'} className='mt-4'>
											<b className='text-black-color' >Aviso:</b> El reporte se esta generando en segundo plano y estará disponible en el módulo de 
											<a  className='font-italic text-info cursor-pointer' 
												onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
												&nbsp;reportes generados.
											</a>
										</Alert>
									}

									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Generar PDF
										</Button>
										<AppLoading isLoading={reportIsFetching} />
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Generar XLS
										</Button>
									</Form.Group>
								</Col>
								<Col sm={3}></Col>
							</Row>

						</Form>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default ReportBalanceStatusPage;

