import React from 'react'
import {
  Redirect, Route
} from "react-router-dom";
import {useSelector} from 'react-redux';
import { getExpiresDate } from '../redux/login/loginReducer';

// console.log('PublicRoute Load: ', window.location.href);

export const PublicRoute = ({
	isAuthenticated,
	component: Component,
	...rest}) => {
	// console.log('PublicRoute: ', location.href);
	const expDate = useSelector(state => getExpiresDate(state));
	// console.log(expDate);

	return <>
		<Route {...rest}
			component={(props)=>(
				(isAuthenticated)
					? (<Redirect to="/" />)
					: (<Component {...props} />)
			)}

		/>		
	</>
}