import React from 'react';
import {
  Redirect, Route
} from "react-router-dom";

export const PrivateRoute = ({
	isAuthenticated,
	component: Component,
	...rest
}) => {
// console.log('PrivateRoute: ', window.location.href);
// console.log('isAuthenticated', isAuthenticated);
	return <Route {...rest}
			component={(props)=>(
				(isAuthenticated)
					? (<Component {...props} />)
					: (<Redirect to="/login" />)
			)}
		/>		
}