import * as ACTION_TYPES from './deliveryOrderActionTypes';

export const deliveryOrderReducerInitialState = {
	listDeliveryOrder: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	deliveryOrderData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	deliveryOrderNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	deliveryOrderEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	deliveryOrderDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	deliveryOrderToEdit: {},
	searchDeliveryOrderId: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};

export const deliveryOrderReducer = (state = deliveryOrderReducer, action) => {
	switch (action.type) {

		// List delivery order actions handlings
		case ACTION_TYPES.LIST_DELIVERY_ORDER_REQUEST: {
			return {
				...state,
				listDeliveryOrder: {
					...state.listDeliveryOrder,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.listDeliveryOrder.error,
					data: deliveryOrderReducerInitialState.listDeliveryOrder.data
				}
			};
		}
		case ACTION_TYPES.LIST_DELIVERY_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listDeliveryOrder: {
					...state.listDeliveryOrder,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.listDeliveryOrder.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_DELIVERY_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listDeliveryOrder: {
					...state.listDeliveryOrder,
					isFetching: false,
					hasError: true,
					data: deliveryOrderReducerInitialState.listDeliveryOrder.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_DELIVERY_ORDER_DATA: {
			return {
				...state,
				listDeliveryOrder: deliveryOrderReducerInitialState.listDeliveryOrder
			}
		}
		// List delivery order actions handlings


		case ACTION_TYPES.GET_DETAIL_DELIVERY_ORDER_REQUEST_ID: {
			const { data } = action.payload;
			return {
				...state,
				searchDeliveryOrderId: {
					...state.searchDeliveryOrderId,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.searchDeliveryOrderId.error,
					data
				}
			};
		}
		
		case ACTION_TYPES.GET_DELIVERY_ORDER_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				searchDeliveryOrderId: {
					...state.searchDeliveryOrderId,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.searchDeliveryOrderId.error,
					data
				}
			};
		}


		case ACTION_TYPES.GET_DELIVERY_ORDER_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				searchDeliveryOrderId: {
					...state.searchDeliveryOrderId,
					isFetching: false,
					hasError: false,
					data: deliveryOrderReducerInitialState.searchDeliveryOrderId.data,
					error
				}
			};
		}

		// Detail delivery order
		case ACTION_TYPES.DETAIL_DELIVERY_ORDER_REQUEST: {
			return {
				...state,
				deliveryOrderData: {
					...state.deliveryOrderData,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderData.error,
					data: deliveryOrderReducerInitialState.deliveryOrderData.data
				}
			};
		}
		case ACTION_TYPES.DETAIL_DELIVERY_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				deliveryOrderData: {
					...state.deliveryOrderData,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderData.error,
					data
				}
			};
		}
		// END List delivery order types actions handlings

		//New delivery order 
		case ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST: {
			return {
				...state,
				deliveryOrderNew: {
					...state.deliveryOrderNew,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderNew.error
				}
			};
		}

		case ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				deliveryOrderNew: {
					...state.deliveryOrderNew,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				deliveryOrderNew: {
					...state.deliveryOrderNew,
					isFetching: false,
					hasError: true,
					data: deliveryOrderReducerInitialState.deliveryOrderNew.data,
					error
				}
			};
		}



		case ACTION_TYPES.CLEAR_CREATE_DELIVERY_ORDER: {

			return {
				...state,
				deliveryOrderNew: deliveryOrderReducerInitialState.deliveryOrderNew
			}
		}
		//END

		//New delivery order Massive
		case ACTION_TYPES.CREATE_DELIVERY_ORDER_MASSIVE_REQUEST: {
			return {
				...state,
				deliveryOrderNew: {
					...state.deliveryOrderNew,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderNew.error
				}
			};
		}

		case ACTION_TYPES.CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				deliveryOrderNew: {
					...state.deliveryOrderNew,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				deliveryOrderNew: {
					...state.deliveryOrderNew,
					isFetching: false,
					hasError: true,
					data: deliveryOrderReducerInitialState.deliveryOrderNew.data,
					error
				}
			};
		}



		case ACTION_TYPES.CLEAR_CREATE_DELIVERY_MASSIVE_ORDER: {

			return {
				...state,
				deliveryOrderNew: deliveryOrderReducerInitialState.deliveryOrderNew
			}
		}
		//END

		//Edit delivery order 
		case ACTION_TYPES.EDIT_DELIVERY_ORDER_REQUEST: {
			return {
				...state,
				deliveryOrderEdit: {
					...state.deliveryOrderEdit,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_DELIVERY_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				deliveryOrderEdit: {
					...state.deliveryOrderEdit,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_DELIVERY_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				deliveryOrderEdit: {
					...state.deliveryOrderEdit,
					isFetching: false,
					hasError: true,
					data: deliveryOrderReducerInitialState.deliveryOrderEdit.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_EDIT_DELIVERY_ORDER: {

			return {
				...state,
				deliveryOrderEdit: deliveryOrderReducerInitialState.deliveryOrderEdit
			}
		}

		case ACTION_TYPES.SET_DELIVERY_ORDER_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				deliveryOrderToEdit: data
			}
		}


		//END

		//Delete delivery order 
		case ACTION_TYPES.DELETE_DELIVERY_ORDER_REQUEST: {
			return {
				...state,
				deliveryOrderDelete: {
					...state.deliveryOrderDelete,
					isFetching: true,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderDelete.error
				}
			};
		}

		case ACTION_TYPES.DELETE_DELIVERY_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				deliveryOrderDelete: {
					...state.deliveryOrderDelete,
					isFetching: false,
					hasError: false,
					error: deliveryOrderReducerInitialState.deliveryOrderDelete.error,
					data
				}
			};
		}

		case ACTION_TYPES.DELETE_DELIVERY_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;

			return {
				...state,
				deliveryOrderDelete: {
					...state.deliveryOrderDelete,
					isFetching: false,
					hasError: false,
					data: deliveryOrderReducerInitialState.deliveryOrderDelete.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_DELETE_DELIVERY_ORDER: {

			return {
				...state,
				deliveryOrderDelete: deliveryOrderReducerInitialState.deliveryOrderDelete
			}
		}
		//END

		case ACTION_TYPES.CLEAR_DETAIL_DELIVERY_ORDER_DATA: {
			return {
				...state,
				deliveryOrderData: deliveryOrderReducerInitialState.deliveryOrderData
			}
		}
		case ACTION_TYPES.SET_DETAIL_DELIVERY_ORDER_DATA: {
			const { data } = action.payload;
			return {
				...state,
				deliveryOrderData: data
			}
		}
		// END

		default: {
			return state;
		}
	}
};

export const getDeliveryOrderReducer = state => state.deliveryOrderReducer;

// List delivery order
export const getDeliveryOrderList = state => getDeliveryOrderReducer(state)?.listDeliveryOrder;
export const getDeliveryOrderListData = state => getDeliveryOrderList(state)?.data;
export const getDeliveryOrderListIsFetching = state => getDeliveryOrderList(state)?.isFetching;

// New delivery order 
export const getDeliveryOrderCreate = state => getDeliveryOrderReducer(state)?.deliveryOrderNew;
export const getDeliveryOrderCreateIsFetching = state => getDeliveryOrderCreate(state)?.isFetching;
export const getDeliveryOrderCreateIsError = state => getDeliveryOrderCreate(state)?.hasError;

// New delivery order Massive
export const getDeliveryOrderMassiveCreate = state => getDeliveryOrderReducer(state)?.deliveryOrderNew;
export const getDeliveryOrderMassiveCreateIsFetching = state => getDeliveryOrderMassiveCreate(state)?.isFetching;
export const getDeliveryOrderMassiveCreateIsError = state => getDeliveryOrderMassiveCreate(state)?.hasError;

// Edit delivery order 
export const getDeliveryOrderEdit = state => getDeliveryOrderReducer(state)?.deliveryOrderEdit;
export const getDeliveryOrderEditIsFetching = state => getDeliveryOrderEdit(state)?.isFetching;
export const getDeliveryOrderEditIsError = state => getDeliveryOrderEdit(state)?.hasError;
export const getDeliveryOrderToEdit = state => getDeliveryOrderReducer(state)?.deliveryOrderToEdit;

// Delete delivery order 
export const getDeliveryOrderDelete = state => getDeliveryOrderReducer(state)?.deliveryOrderDelete; 
export const getDeliveryOrderDeleteIsFetching = state => getDeliveryOrderDelete(state)?.isFetching;
export const getDeliveryOrderDeleteIsError = state => getDeliveryOrderDelete(state)?.hasError;

// Detail delivery order
export const getDeliveryOrderDetail = state => getDeliveryOrderReducer(state)?.deliveryOrderData;
export const getDeliveryOrderDetailById = state => getDeliveryOrderReducer(state)?.deliveryOrderData;
export const getIsFetchingDeliveryOrderDetailById = state => getDeliveryOrderDetailById(state)?.isFetching;

// Delivery order by ID
export const getDeliveryOrderById = state => getDeliveryOrderReducer(state)?.searchDeliveryOrderId;
export const getDeliveryOrderByIdIsFetching = state => getDeliveryOrderById(state)?.isFetching;