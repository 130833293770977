import * as ACTION_TYPES from './principalBudgetActionTypes';

export const principalBudgetReducerInitialState = {
	principalBudgetListBySectorId: {
		sectorId: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const principalBudgetReducer = ( state=principalBudgetReducerInitialState, action ) => {
	switch( action.type ) {

		case ACTION_TYPES.LIST_PRINCIPAL_BUDGET_BY_SECTOR_ID_REQUEST: {
			const { sectorId } = action.payload;
			return {
				...state,
				principalBudgetListBySectorId: {
					...state.principalBudgetListBySectorId,
					isFetching: true,
					hasError: false,
					data: principalBudgetReducerInitialState.principalBudgetListBySectorId.data,
					error: principalBudgetReducerInitialState.principalBudgetListBySectorId.error,
					sectorId
				}
			}
		}
		case ACTION_TYPES.LIST_PRINCIPAL_BUDGET_BY_SECTOR_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				principalBudgetListBySectorId: {
					...state.principalBudgetListBySectorId,
					isFetching: false,
					hasError: false,
					error: principalBudgetReducerInitialState.principalBudgetListBySectorId.error,
					data
				}
			}
		}
		case ACTION_TYPES.LIST_PRINCIPAL_BUDGET_BY_SECTOR_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				principalBudgetListBySectorId: {
					...state.principalBudgetListBySectorId,
					isFetching: true,
					hasError: false,
					sectorId: principalBudgetReducerInitialState.principalBudgetListBySectorId.sectorId,
					data: principalBudgetReducerInitialState.principalBudgetListBySectorId.data,
					error
				}
			}
		}

		case ACTION_TYPES.CLEAR_PRINCIPAL_BUDGET_LIST_BY_SECTOR_ID: {
			return {
				...state,
				principalBudgetListBySectorId: principalBudgetReducerInitialState.principalBudgetListBySectorId
			}
		}

		default: {
			return state;
		}
	}
};

export const getPrincipalBudgetReducer = state => state.principalBudgetReducer;

export const getPrincipalBudgetListBySectorId = state => getPrincipalBudgetReducer(state)?.principalBudgetListBySectorId;
export const getPrincipalBudgetListBySectorIdData = state => getPrincipalBudgetListBySectorId(state)?.data;
export const getPrincipalBudgetListBySectorIdIsFetching = state => getPrincipalBudgetListBySectorId(state)?.isFetching;
export const getPrincipalBudgetListBySectorIdHasError = state => getPrincipalBudgetListBySectorId(state)?.hasError;
export const getPrincipalBudgetListBySectorIdError = state => getPrincipalBudgetListBySectorId(state)?.error;