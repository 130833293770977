import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpPost, httpPut, httpDelete } from './httpServices';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../redux/login/loginReducer';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const DELIVERY_ORDER_URI = `${API_BASE_URI}/delivery-orders`;
const DELIVERY_ORDER_MASSIVE_URI = `${API_BASE_URI}/delivery-orders/massive`;

// delivery order last number
export const fetchLastNumber = async (accessToken) => {
    try {
        const response = await fetch(`${API_BASE_URI}/delivery-orders/lastNumber`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("error", error);
        throw error;
    }
};

export const listDeliveryOrder = async (accessToken, params = {}) => {
	const options = buildDefaultOptions(accessToken);

	const queryString = buildURLQuery(params);
	const URL = `${DELIVERY_ORDER_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


//New delivery order
export const newDeliveryOrder = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPost(DELIVERY_ORDER_URI, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//New delivery order massive
export const newDeliveryOrderMassive = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPost(DELIVERY_ORDER_MASSIVE_URI, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};


//Edit delivery order
export const editDeliveryOrder = async (accessToken, params) => {

	const idDeliveryOrder = params?.id
	const URL = `${DELIVERY_ORDER_URI}/${idDeliveryOrder}`
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Delete delivery order
export const deleteDeliveryOrder = async (accessToken, id) => {

	const options = buildDefaultOptions(accessToken);
	let response;
	const URL = `${DELIVERY_ORDER_URI}/${id}`;

	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

// Detail delivery order
export const getDeliveryOrderId = async (accessToken, administrativeServiceId) => {
	const URL = `${DELIVERY_ORDER_URI}/${administrativeServiceId}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}

export const getDeliveryOrderById = async (accessToken, deliveryOrderId) => {
	const URL = `${DELIVERY_ORDER_URI}/${deliveryOrderId}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}