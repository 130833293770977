import * as ACTION_TYPES from './sectorActionTypes';

export const sectorReducerInitialState = {
	sectorsListBySectionId: {
		sectionId: undefined,
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const sectorReducer = ( state=sectorReducerInitialState, action ) => {
	switch( action.type ) {		
		case ACTION_TYPES.LIST_SECTORS_BY_SECTION_ID_REQUEST: {
			const { sectionId } = action.payload;
			return {
				...state,
				sectorsListBySectionId: {
					...state.sectorsListBySectionId,
					isFetching: true,
					hasError: false,
					error: sectorReducerInitialState.sectorsListBySectionId.error,
					data: sectorReducerInitialState.sectorsListBySectionId.data,
					sectionId
				}
			};
		}

		case ACTION_TYPES.LIST_SECTORS_BY_SECTION_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				sectorsListBySectionId: {
					...state.sectorsListBySectionId,
					isFetching: false,
					hasError: false,
					error: sectorReducerInitialState.sectorsListBySectionId.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_SECTORS_BY_SECTION_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				sectorsListBySectionId: {
					...state.sectorsListBySectionId,
					isFetching: false,
					hasError: true,
					data: sectorReducerInitialState.sectorsListBySectionId.data,
					sectionId: sectorReducerInitialState.sectorsListBySectionId.sectionId,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_LIST_SECTORS_BY_SECTION_ID: {
			return {
				...state,
				sectorsListBySectionId: sectorReducerInitialState.sectorsListBySectionId
			};
		}

		default: {
			return state;
		}
	}
};

export const getSectorReducer = state => state.sectorReducer;

export const getSectorsListBySectionId = state => getSectorReducer(state)?.sectorsListBySectionId;
export const getSectorsListBySectionIdData = state => getSectorsListBySectionId(state)?.data;
export const getSectorsListBySectionIdIsFetching = state => getSectorsListBySectionId(state)?.isFetching;
export const getSectorsListBySectionIdHasError = state => getSectorsListBySectionId(state)?.hasError;
export const getSectorsListBySectionIdError = state => getSectorsListBySectionId(state)?.error;
