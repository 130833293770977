
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';

export const FORGOT_PASSWORD_SEND_EMAIL_REQUEST = 'FORGOT_PASSWORD_SEND_EMAIL_REQUEST';
export const FORGOT_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE = 'FORGOT_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE';

export const LOGOUT = 'LOGOUT';

export const SET_PASSWORD_PRE_REQUEST = 'SET_PASSWORD_PRE_REQUEST';


// Current Password
export const CURRENT_PASSWORD_REQUEST = 'CURRENT_PASSWORD_REQUEST';
export const CURRENT_PASSWORD_REQUEST_SUCCESS = 'CURRENT_PASSWORD_REQUEST_SUCCESS';
export const CURRENT_PASSWORD_REQUEST_FAILURE = 'CURRENT_PASSWORD_REQUEST_FAILURE';

// Restablecer contraseña por panel de usuario
export const RESTORE_PASSWORD_SEND_EMAIL_REQUEST = 'RESTORE_PASSWORD_SEND_EMAIL_REQUEST';
export const RESTORE_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE = 'RESTORE_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE';

// get status api
export const GET_STATUS_API_REQUEST = 'GET_STATUS_API_REQUEST';
export const GET_STATUS_API_REQUEST_SUCCESS = 'GET_STATUS_API_REQUEST_SUCCESS';
export const GET_STATUS_API_REQUEST_FAILURE = 'GET_STATUS_API_REQUEST_SUCCESS';
