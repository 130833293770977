import React from 'react';
import { Container, Card } from 'react-bootstrap';

import PageTitle from 'src/components/general/PageTitle';
import {impactsBudgetItems} from 'src/utils/label';

const CreditExecutionAffectationHistoryPage = props => {
	return <Container fluid >
		<Card>

			<PageTitle text={impactsBudgetItems}/>

			<Container fluid >
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Historial de afectaciones
					</Card.Header>
					<Card.Body>
						CreditExecutionAffectationHistoryPage
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default CreditExecutionAffectationHistoryPage;