import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.coreApi.URL}`;
const ADMINISTRATIVE_SERVICE_URI = `${API_BASE_URI}/services`;

export const listService = async ( accessToken, page, pageSize, filter ) => {
	const options = buildDefaultOptions(accessToken);
	const jsonFilter = filter ? JSON.stringify(filter) : undefined;
	const paramsToSend = {
		page: page?.page || 0,
		size: pageSize?.size || 1000,
		filter: jsonFilter
	};
	const queryString = buildURLQuery(paramsToSend);
	const URL = `${ADMINISTRATIVE_SERVICE_URI}?${queryString}`;
	let response;
	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}
	return response;
};


// User service, jurisdiction and organism
export const listServiceByJurisdictionOrganism = async (accessToken, params) => {
	let URL
	let response
	function concatValues(array) {
		const values = array.map(item => item.value)
		return "idsServices=" + values.join("&idsServices=")
	}
	const concatenatedValues = concatValues(params)
	const options = buildDefaultOptions(accessToken)
	URL = `${ADMINISTRATIVE_SERVICE_URI}/jurisdictions/organisms?${concatenatedValues}`
	try {
		response = await httpGet(URL, options)
	} catch (error) {
		return error
	}
	return response
};