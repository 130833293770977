import * as ACTION_TYPES from './administrativeServiceActionTypes';

export const administrativeServiceReducerInitialState = {
	listAdministrativeServices: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listAllAdministrativeServices: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listAdministrativeServiceTypes: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesOrganismAdd: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesOrganismRemove: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesUsers: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesUsersAdd: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesUsersRemove: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesUsersAddNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesToEdit: {},
	administrativeServicesAddAllUsers: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesAddAllUsers: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeServicesRemoveAllUsers: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listAllServiceAdministrativeByUser: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listServicesByUserAndExercise: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listIdsServiceAdministrativeByUser: [],
	serviceAdministrativeById: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	getActiveAdmnistrativeServiceFromExerciseAndUser: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};

export const administrativeServiceReducer = (state = administrativeServiceReducer, action) => {
	switch (action.type) {

		// List administrative services actions handlings
		case ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				listAdministrativeServices: {
					...state.listAdministrativeServices,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.listAdministrativeServices.error,
					data: administrativeServiceReducerInitialState.listAdministrativeServices.data
				}
			};
		}
		case ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listAdministrativeServices: {
					...state.listAdministrativeServices,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.listAdministrativeServices.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listAdministrativeServices: {
					...state.listAdministrativeServices,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.listAdministrativeServices.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ADMINISTRATIVE_SERVICES_DATA: {
			return {
				...state,
				listAdministrativeServices: administrativeServiceReducerInitialState.listAdministrativeServices
			}
		}
		// List administrative services actions handlings

		// List All administrative services actions handlings
		case ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				listAllAdministrativeServices: {
					...state.listAllAdministrativeServices,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.listAllAdministrativeServices.error,
					data: administrativeServiceReducerInitialState.listAllAdministrativeServices.data
				}
			};
		}
		case ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listAllAdministrativeServices: {
					...state.listAllAdministrativeServices,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.listAllAdministrativeServices.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listAllAdministrativeServices: {
					...state.listAllAdministrativeServices,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.listAllAdministrativeServices.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ALL_ADMINISTRATIVE_SERVICES_DATA: {
			return {
				...state,
				listAllAdministrativeServices: administrativeServiceReducerInitialState.listAllAdministrativeServices
			}
		}
		// List all administrative services actions handlings


		// List administrative service types actions handlings
		case ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST: {
			return {
				...state,
				listAdministrativeServiceTypes: {
					...state.listAdministrativeServiceTypes,
					isFetching: true
				}
			};
		}
		case ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listAdministrativeServiceTypes: {
					...state.listAdministrativeServiceTypes,
					isFetching: false,
					data
				}
			};
		}
		// Detail adminstrative services
		case ACTION_TYPES.DETAIL_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesData: {
					...state.administrativeServicesData,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesData.error,
					data: administrativeServiceReducerInitialState.administrativeServicesData.data
				}
			};
		}
		case ACTION_TYPES.DETAIL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesData: {
					...state.administrativeServicesData,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesData.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listAdministrativeServiceTypes: {
					...state.listAdministrativeServiceTypes,
					isFetching: false,
					error
				}
			};
		}
		case ACTION_TYPES.DETAIL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesData: {
					...state.administrativeServicesData,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesData.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ADMINISTRATIVE_SERVICE_TYPES_DATA: {
			return {
				...state,
				listAdministrativeServiceTypes: administrativeServiceReducerInitialState.listAdministrativeServiceTypes
			};
		}
		// END List administrative service types actions handlings

		//New Adminsitrative Service
		case ACTION_TYPES.CREATE_ADMINISTRATIVE_SERVICE_REQUEST: {
			return {
				...state,
				administrativeServicesNew: {
					...state.administrativeServicesNew,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesNew.error
				}
			};
		}

		case ACTION_TYPES.CREATE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				administrativeServicesNew: {
					...state.administrativeServicesNew,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesNew.error,
					data
				}
			};
		}

		case ACTION_TYPES.CLEAR_CREATE_ADMINISTRATIVE_SERVICE: {

			return {
				...state,
				administrativeServicesNew: administrativeServiceReducerInitialState.administrativeServicesNew
			}
		}
		//END

		//Edit Adminsitrative Service
		case ACTION_TYPES.EDIT_ADMINISTRATIVE_SERVICE_REQUEST: {
			return {
				...state,
				administrativeServicesEdit: {
					...state.administrativeServicesEdit,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				administrativeServicesEdit: {
					...state.administrativeServicesEdit,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesEdit: {
					...state.administrativeServicesEdit,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesEdit.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_EDIT_ADMINISTRATIVE_SERVICE: {

			return {
				...state,
				administrativeServicesEdit: administrativeServiceReducerInitialState.administrativeServicesEdit
			}
		}

		case ACTION_TYPES.SET_ADMINISTRATIVE_SERVICE_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesToEdit: data
			}
		}


		//END

		//Delete Adminsitrative Service
		case ACTION_TYPES.DELETE_ADMINISTRATIVE_SERVICE_REQUEST: {
			return {
				...state,
				administrativeServicesDelete: {
					...state.administrativeServicesDelete,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesDelete.error
				}
			};
		}

		case ACTION_TYPES.DELETE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				administrativeServicesDelete: {
					...state.administrativeServicesDelete,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesDelete.error,
					data
				}
			};
		}

		case ACTION_TYPES.DELETE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;

			return {
				...state,
				administrativeServicesDelete: {
					...state.administrativeServicesDelete,
					isFetching: false,
					hasError: false,
					data: administrativeServiceReducerInitialState.administrativeServicesDelete.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_DELETE_ADMINISTRATIVE_SERVICE: {

			return {
				...state,
				administrativeServicesDelete: administrativeServiceReducerInitialState.administrativeServicesDelete
			}
		}
		//END

		case ACTION_TYPES.CLEAR_DETAIL_ADMINISTRATIVE_SERVICES_DATA: {
			return {
				...state,
				administrativeServicesData: administrativeServiceReducerInitialState.administrativeServicesData
			}
		}
		case ACTION_TYPES.SET_DETAIL_ADMINISTRATIVE_SERVICES_DATA: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesData: data
			}
		}




		// END

		// Organism adminstrative services
		case ACTION_TYPES.ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesOrganism: {
					...state.administrativeServicesOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesOrganism.error,
					data: administrativeServiceReducerInitialState.administrativeServicesOrganism.data
				}
			};
		}
		case ACTION_TYPES.ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesOrganism: {
					...state.administrativeServicesOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesOrganism.error,
					data
				}
			};
		}

		case ACTION_TYPES.CREATE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesNew: {
					...state.administrativeServicesNew,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesNew.data,
					error
				}
			};
		}
		case ACTION_TYPES.ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesOrganism: {
					...state.administrativeServicesOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesOrganism.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_ORGANISM_ADMINISTRATIVE_SERVICES_DATA: {
			return {
				...state,
				administrativeServicesOrganism: administrativeServiceReducerInitialState.administrativeServicesOrganism
			}
		}
		case ACTION_TYPES.SET_ORGANISM_ADMINISTRATIVE_SERVICES_DATA: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesOrganism: data
			}
		}
		// END

		// Add Organism adminstrative services
		case ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesOrganismAdd: {
					...state.administrativeServicesOrganismAdd,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesOrganismAdd.error,
					data: administrativeServiceReducerInitialState.administrativeServicesOrganismAdd.data
				}
			};
		}
		case ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesOrganismAdd: {
					...state.administrativeServicesOrganismAdd,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesOrganismAdd.error,
					data
				}
			};
		}
		case ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesOrganismAdd: {
					...state.administrativeServicesOrganismAdd,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesOrganismAdd.data,
					error
				}
			};
		}
		// END

		// Remove Organism adminstrative services
		case ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesOrganismRemove: {
					...state.administrativeServicesOrganismRemove,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesOrganismRemove.error,
					data: administrativeServiceReducerInitialState.administrativeServicesOrganismRemove.data
				}
			};
		}
		case ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesOrganismRemove: {
					...state.administrativeServicesOrganismRemove,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesOrganismRemove.error,
					data
				}
			};
		}
		case ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesOrganismRemove: {
					...state.administrativeServicesOrganismRemove,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesOrganismRemove.data,
					error
				}
			};
		}
		// END

		// Users adminstrative services
		case ACTION_TYPES.USERS_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesUsers: {
					...state.administrativeServicesUsers,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsers.error,
					data: administrativeServiceReducerInitialState.administrativeServicesUsers.data
				}
			};
		}
		case ACTION_TYPES.USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesUsers: {
					...state.administrativeServicesUsers,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsers.error,
					data
				}
			};
		}
		case ACTION_TYPES.USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesUsers: {
					...state.administrativeServicesUsers,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesUsers.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_USERS_ADMINISTRATIVE_SERVICES_DATA: {
			return {
				...state,
				administrativeServicesUsers: administrativeServiceReducerInitialState.administrativeServicesUsers
			}
		}
		case ACTION_TYPES.SET_USERS_ADMINISTRATIVE_SERVICES_DATA: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesUsers: data
			}
		}
		// END

		// Add Users adminstrative services
		case ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesUsersAdd: {
					...state.administrativeServicesUsersAdd,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsersAdd.error,
					data: administrativeServiceReducerInitialState.administrativeServicesUsersAdd.data
				}
			};
		}
		case ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesUsersAdd: {
					...state.administrativeServicesUsersAdd,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsersAdd.error,
					data
				}
			};
		}
		case ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesUsersAdd: {
					...state.administrativeServicesUsersAdd,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesUsersAdd.data,
					error
				}
			};
		}
		// END

		// Remove Users adminstrative services
		case ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesUsersRemove: {
					...state.administrativeServicesUsersRemove,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsersRemove.error,
					data: administrativeServiceReducerInitialState.administrativeServicesUsersRemove.data
				}
			};
		}
		case ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesUsersRemove: {
					...state.administrativeServicesUsersRemove,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsersRemove.error,
					data
				}
			};
		}
		case ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesUsersRemove: {
					...state.administrativeServicesUsersRemove,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesUsersRemove.data,
					error
				}
			};
		}
		// END

		// Add all Users Adminsitrative Service
		case ACTION_TYPES.ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesAddAllUsers: {
					...state.administrativeServicesAddAllUsers,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesAddAllUsers.error
				}
			};
		}

		case ACTION_TYPES.ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				administrativeServicesAddAllUsers: {
					...state.administrativeServicesAddAllUsers,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesAddAllUsers.error,
					data
				}
			};
		}

		case ACTION_TYPES.ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesAddAllUsers: {
					...state.administrativeServicesAddAllUsers,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesAddAllUsers.data,
					error
				}
			};
		}
		//END

		// Remove all Users Adminsitrative Service
		case ACTION_TYPES.REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesRemoveAllUsers: {
					...state.administrativeServicesRemoveAllUsers,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesRemoveAllUsers.error
				}
			};
		}

		case ACTION_TYPES.REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				administrativeServicesRemoveAllUsers: {
					...state.administrativeServicesRemoveAllUsers,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesRemoveAllUsers.error,
					data
				}
			};
		}

		case ACTION_TYPES.REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesRemoveAllUsers: {
					...state.administrativeServicesRemoveAllUsers,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesRemoveAllUsers.data,
					error
				}
			};
		}
		//END


		// List all administrative services by User 
		case ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST: {
			return {
				...state,
				listAllServiceAdministrativeByUser: {
					...state.listAllServiceAdministrativeByUser,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.listAllServiceAdministrativeByUser.error
				}
			};
		}

		case ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				listAllServiceAdministrativeByUser: {
					...state.listAllServiceAdministrativeByUser,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.listAllServiceAdministrativeByUser.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listAllServiceAdministrativeByUser: {
					...state.listAllServiceAdministrativeByUser,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.listAllServiceAdministrativeByUser.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_LIST_ADMINISTRATIVE_SERVICES_BY_USER: {
			const { data } = action.payload;
			return {
				...state,
				listIdsServiceAdministrativeByUser: data
			}
		}

		case ACTION_TYPES.CLEAR_LIST_ALL_SERVICE_ADMINISTRATIVE_BY_USER: {
			return {
				...state,
				listAllServiceAdministrativeByUser: administrativeServiceReducerInitialState.listAllServiceAdministrativeByUser
			}
		}
		//END

		case ACTION_TYPES.LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST: {
			return {
				...state,
				listServicesByUserAndExercise: {
					...state.listServicesByUserAndExercise,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.listServicesByUserAndExercise.error
				}
			};
		}
		case ACTION_TYPES.LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				listServicesByUserAndExercise: {
					...state.listServicesByUserAndExercise,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.listServicesByUserAndExercise.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listServicesByUserAndExercise: {
					...state.listServicesByUserAndExercise,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.listServicesByUserAndExercise.data,
					error
				}
			};
		}

		// Add New Users adminstrative services
		case ACTION_TYPES.ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST: {
			return {
				...state,
				administrativeServicesUsersAddNew: {
					...state.administrativeServicesUsersAddNew,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsersAddNew.error,
					data: administrativeServiceReducerInitialState.administrativeServicesUsersAddNew.data
				}
			};
		}
		case ACTION_TYPES.ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeServicesUsersAddNew: {
					...state.administrativeServicesUsersAddNew,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.administrativeServicesUsersAddNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeServicesUsersAddNew: {
					...state.administrativeServicesUsersAddNew,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.administrativeServicesUsersAddNew.data,
					error
				}
			};
		}
		// END


		//search service administrative by id
		case ACTION_TYPES.SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_REQUEST: {
			return {
				...state,
				serviceAdministrativeById: {
					...state.serviceAdministrativeById,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.serviceAdministrativeById.error,
					data: administrativeServiceReducerInitialState.serviceAdministrativeById.data
				}
			};
		}
		case ACTION_TYPES.SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				serviceAdministrativeById: {
					...state.serviceAdministrativeById,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.serviceAdministrativeById.error,
					data
				}
			};
		}
		case ACTION_TYPES.SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				serviceAdministrativeById: {
					...state.serviceAdministrativeById,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.serviceAdministrativeById.data,
					error
				}
			};
		}
		//End search service administrative by id

		// Get Active administrative service from exercise actions handling
		case ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST: {
			return {
				...state,
				getActiveAdmnistrativeServiceFromExerciseAndUser: {
					...state.getActiveAdmnistrativeServiceFromExerciseAndUser,
					isFetching: true,
					hasError: false,
					error: administrativeServiceReducerInitialState.getActiveAdmnistrativeServiceFromExerciseAndUser.error,
					data: administrativeServiceReducerInitialState.getActiveAdmnistrativeServiceFromExerciseAndUser.data
				}
			};
		}
		case ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getActiveAdmnistrativeServiceFromExerciseAndUser: {
					...state.getActiveAdmnistrativeServiceFromExerciseAndUser,
					isFetching: false,
					hasError: false,
					error: administrativeServiceReducerInitialState.getActiveAdmnistrativeServiceFromExerciseAndUser.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getActiveAdmnistrativeServiceFromExerciseAndUser: {
					...state.getActiveAdmnistrativeServiceFromExerciseAndUser,
					isFetching: false,
					hasError: true,
					data: administrativeServiceReducerInitialState.getActiveAdmnistrativeServiceFromExerciseAndUser.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_DATA: {
			return {
				...state,
				getActiveAdmnistrativeServiceFromExerciseAndUser: administrativeServiceReducerInitialState.getActiveAdmnistrativeServiceFromExerciseAndUser
			};
		}
		// END Get Active administrative service from exercise actions handling


		default: {
			return state;
		}
	}
};

export const getAdministrativeServiceReducer = state => state.administrativeServiceReducer;

// List administrative service
export const getAdministrativeServiceList = state => getAdministrativeServiceReducer(state)?.listAdministrativeServices;
export const getAdministrativeServiceListData = state => getAdministrativeServiceList(state)?.data;
export const getAdministrativeServiceListIsFetching = state => getAdministrativeServiceList(state)?.isFetching;

// List all administrative service
export const getAdministrativeServiceListAll = state => getAdministrativeServiceReducer(state)?.listAllAdministrativeServices;
export const getAdministrativeServiceListAllData = state => getAdministrativeServiceListAll(state)?.data;
export const getAdministrativeServiceListAllIsFetching = state => getAdministrativeServiceListAll(state)?.isFetching;

// List administrative service Type
export const getAdministrativeServiceTypes = state => getAdministrativeServiceReducer(state)?.listAdministrativeServiceTypes;
export const getAdministrativeServiceTypesData = state => getAdministrativeServiceTypes(state)?.data;
export const getAdministrativeServiceTypesIsFetching = state => getAdministrativeServiceTypes(state)?.isFetching;
export const getAdministrativeServiceTypeByAdministrativeServiceId = (state, administrativeServiceTypeId) => getAdministrativeServiceTypesData(state)?.records?.find(item => item.id == administrativeServiceTypeId);

// New administrative service 
export const getAdministrativeServiceCreate = state => getAdministrativeServiceReducer(state)?.administrativeServicesNew;
export const getAdministrativeServiceCreateIsFetching = state => getAdministrativeServiceCreate(state)?.isFetching;
export const getAdministrativeServiceCreateIsError = state => getAdministrativeServiceCreate(state)?.hasError;

// Edit administrative service 
export const getAdministrativeServiceEdit = state => getAdministrativeServiceReducer(state)?.administrativeServicesEdit;
export const getAdministrativeServiceEditIsFetching = state => getAdministrativeServiceEdit(state)?.isFetching;
export const getAdministrativeServiceEditIsError = state => getAdministrativeServiceEdit(state)?.hasError;
export const getAdministrativeServiceToEdit = state => getAdministrativeServiceReducer(state)?.administrativeServicesToEdit;


// Delete administrative service 
export const getAdministrativeServiceDelete = state => getAdministrativeServiceReducer(state)?.administrativeServicesDelete;
export const getAdministrativeServiceDeleteIsFetching = state => getAdministrativeServiceDelete(state)?.isFetching;
export const getAdministrativeServiceDeleteIsError = state => getAdministrativeServiceDelete(state)?.hasError;

// Detail administrative service
export const getAdministrativeServiceDetail = state => getAdministrativeServiceReducer(state)?.administrativeServicesData;

// Organism administrative service
export const getAdministrativeServiceOrganism = state => getAdministrativeServiceReducer(state)?.administrativeServicesOrganism;
export const getAdministrativeServiceOrganismListData = state => getAdministrativeServiceOrganism(state)?.data;
export const getAdministrativeServiceOrganismListIsFetching = state => getAdministrativeServiceOrganism(state)?.isFetching;

// Users administrative service
export const getAdministrativeServiceUsers = state => getAdministrativeServiceReducer(state)?.administrativeServicesUsers;
export const getAdministrativeServiceUsersListData = state => getAdministrativeServiceUsers(state)?.data;
export const getAdministrativeServiceUsersListIsFetching = state => getAdministrativeServiceUsers(state)?.isFetching;

// Add all Users administrative service
export const getAdministrativeServiceAddAllUsers = state => getAdministrativeServiceReducer(state)?.administrativeServicesAddAllUsers;
export const getAdministrativeServiceAddAllUsersListData = state => getAdministrativeServiceAddAllUsers(state)?.data;
export const getAdministrativeServiceAddAllUsersListIsFetching = state => getAdministrativeServiceAddAllUsers(state)?.isFetching;

// Remove all Users administrative service
export const getAdministrativeServiceRemoveAllUsers = state => getAdministrativeServiceReducer(state)?.administrativeServicesRemoveAllUsers;
export const getAdministrativeServiceRemoveAllUsersListData = state => getAdministrativeServiceRemoveAllUsers(state)?.data;
export const getAdministrativeServiceRemoveAllUsersListIsFetching = state => getAdministrativeServiceRemoveAllUsers(state)?.isFetching;

// List all administrative services by User 
export const getListAllServiceAdministrativeByUser = state => getAdministrativeServiceReducer(state)?.listAllServiceAdministrativeByUser;
export const getListAllServiceAdministrativeByUserData = state => getListAllServiceAdministrativeByUser(state)?.data;
export const getListAllServiceAdministrativeByUserIsFetching = state => getListAllServiceAdministrativeByUser(state)?.isFetching;
export const getListAllServiceAdministrativeByUserId = (state, userId) => getListAllServiceAdministrativeByUserData(state)?.records?.find(item => item.id == userId);

export const getListServicesByUserAndExercise = state => getAdministrativeServiceReducer(state)?.listServicesByUserAndExercise;
export const getListServicesByUserAndExerciseData = state => getListServicesByUserAndExercise(state)?.data;
export const getListServicesByUserAndExerciseIsFetching = state => getListServicesByUserAndExercise(state)?.isFetching;

// List all administrative services by User
export const getListIdsServiceAdministrativeByUser = state => getAdministrativeServiceReducer(state)?.listIdsServiceAdministrativeByUser;

// Add New Users administrative services 
export const getAdministrativeServiceAddNewUsers = state => getAdministrativeServiceReducer(state)?.administrativeServicesUsersAddNew;
export const getAdministrativeServiceAddNewUsersListData = state => getAdministrativeServiceAddNewUsers(state)?.data;
export const getAdministrativeServiceAddNewUsersListIsFetching = state => getAdministrativeServiceAddNewUsers(state)?.isFetching;


// administrative services by id
export const getAdministrativeServiceById = state => getAdministrativeServiceReducer(state)?.serviceAdministrativeById;
export const getAdministrativeServiceByIdData = state => getAdministrativeServiceById(state)?.data;
export const getAdministrativeServiceByIdIsFetching = state => getAdministrativeServiceById(state)?.isFetching;

// Get active service from exercise data
export const getActiveAdministrativeServiceFromExerciseAndUser = state => getAdministrativeServiceReducer(state)?.getActiveAdmnistrativeServiceFromExerciseAndUser;
export const getActiveAdministrativeServiceFromExerciseAndUserData = state => getActiveAdministrativeServiceFromExerciseAndUser(state)?.data;
export const getActiveAdministrativeServiceFromExerciseAndUserIsFetching = state => getActiveAdministrativeServiceFromExerciseAndUser(state)?.isFetching;
