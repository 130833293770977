import React from 'react';
import {  Button, Table, Modal } from 'react-bootstrap';
import { SumCollectionAmounts, formatterPeso, getOrderPayTypeValueToShow, mFormat } from 'src/utils/utils';

const DetailModalLegalInstrument = (props) => {

const { showInsrumentDetailModal, setShowInsrumentDetailModal, activeLegalInsrument } = props;

  return (
        <Modal
            show={showInsrumentDetailModal}
            onHide={() => setShowInsrumentDetailModal(false)}
            dialogClassName="modal-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>DETALLE DEL INSTRUMENTO LEGAL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped borderless hover className="shadow-sm p-2 mb-1 bg-white rounded text-black-color" >
                    <tbody>
                            <tr>
                                <td className='font-weight-bold text-left' sm='4'>Instrumento Legal:</td>
                                <td className='text-left' sm='8'>
                                        {activeLegalInsrument?.administrativeDocument?.codeOrganism}-{activeLegalInsrument?.administrativeDocument?.number}/{activeLegalInsrument?.administrativeDocument?.year}
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold text-left' sm='4'>Tipo:</td>
                                <td className='text-left' sm='8'>
                                    {getOrderPayTypeValueToShow(activeLegalInsrument?.type)}
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold text-left' sm='4'>Organismo</td>
                                <td className='text-left' sm='8'>
                                    {activeLegalInsrument?.number}	-	{activeLegalInsrument?.year}
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold text-left' sm='4'>Fecha:</td>
                                <td className='text-left' sm='8'>
                                    {mFormat(activeLegalInsrument?.datePaymentOrder)}
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold text-left' sm='4'>Descripción:</td>
                                <td className='text-left' sm='8'>
                                    {activeLegalInsrument?.description}
                                </td>
                            </tr>
                            <tr>
                                <td className='font-weight-bold text-left' sm='4'>Importe:</td>
                                <td className='text-left' sm='8'>
                                    {formatterPeso.format(SumCollectionAmounts(activeLegalInsrument?.details))}
                                </td>
                            </tr>
                    </tbody>
                </Table>
                <hr />
                <p className='text-black-color h6'>Detalle del Instrumento Legal</p>
                <Table striped borderless hover>
                    <thead>
                        <tr>
                            <th className='text-center font-weight-bold'>Monto</th>
                            <th className='text-center font-weight-bold'>Descripción</th>
                            <th className='text-center font-weight-bold'>CUIT</th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        {
                            activeLegalInsrument?.details?.map(item => (
                                <tr key={item.id}>
                                    <td className='text-center py-1'>
                                        {formatterPeso.format(item?.amount)}
                                    </td>
                                    <td className='text-center py-1'>
                                        {item?.description}
                                    </td>
                                    <td className='text-center py-1'>
                                        {item?.cuit ? item?.cuit : 'S/D'}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowInsrumentDetailModal(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default DetailModalLegalInstrument