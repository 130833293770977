import React from 'react';
import InstitucionFondosForm from './OPForms/InstitucionFondosForm';
import RemesasForm from './OPForms/RemesasForm';
import NewHaberesForm from './OPForms/NewHaberesForm';
import NewHaberesLiquidationForm from './OPForms/NewHaberesLiquidationForm';
import PaymentOrderForm from './OPForms/PaymentOrderForm';
import IndebidoPaymentOrderForm from './OPForms/IndebidoPaymentOrderForm';
import ProvidersPaymentOrderForm from './OPForms/ProvidersPaymentOrderForm';

const OPFormFactory = props => {

	const { formName } = props;
	const namesMap = {
		'ORDEN_DE_PAGO': PaymentOrderForm,
		'ORDEN_DE_PAGO_INSTITUCION_DE_FONDOS_PERMANENTES': InstitucionFondosForm,
		'ORDEN_DE_PAGO_REMESAS': RemesasForm,
		//'ORDEN_DE_PAGO_GENERICA_DE_HABERES': NewHaberesForm,
		'ORDEN_DE_PAGO_DE_HABERES': NewHaberesLiquidationForm,
		'ORDEN_DE_CARGO': '',
		'ORDEN_DE_PAGO_CONTRA_ENTREGA': '',
		'ORDEN_DE_PAGO_DEPOSITO_INDEBIDO': IndebidoPaymentOrderForm,
		'ORDEN_DE_PAGO_DE_PROVEEDORES': ProvidersPaymentOrderForm
	};

	const calcTemplate= formName => {
		return namesMap[formName]?React.createElement(namesMap[formName]) : <></>;
	}

	return (
		calcTemplate(formName)
	);
}

export default OPFormFactory;