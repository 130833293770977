import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { getListAllServiceAdministrativeByUserData} from "src/redux/administrativeService/administrativeServiceReducer";
import Commons from './controls/Commons';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { tryListAllServiceAdministrativeByUser } from "src/redux/administrativeService/administrativeServiceActionCreator";
import { getAdministrativeDocumentYearsOptions, isValidateOrganismCode } from 'src/utils/utils';
import { tryListProveedores } from 'src/redux/proveedores/proveedoresActionCreator';
import { tryListPayOrderTypes } from 'src/redux/orderPay/orderPayActionCreator';
import ExpedienteControl from './controls/ExpedienteControl';
import { tryGetAdministrativeDocumentByOrderPay } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';

const IndebidoPaymentOrderForm = (props) => {
	const dispatch = useDispatch();
	const { fundRequest, orderPay } = useFormContext();
	const [administrativeDocument, setAdministrativeDocument] = useState([]);
	const [admServiceId, setAdmServiceId] = useState();
	const [expedientesSeleccionados, setExpedientesSeleccionados] = useState([]);
	const [importe, setImporte] = useState();

	//Para acceder a valores de inputs por fuera del submit
	const expOrganismCodeRef = useRef();
	const expNumberRef = useRef();
	const expYearRef = useRef();
	const expedienteRefs = { expOrganismCodeRef, expNumberRef, expYearRef };
    const showListBox = false;
    const [invalidRangeCode, setInvalidRangeCode] = useState(false);
    const yearsOptions = getAdministrativeDocumentYearsOptions();

	const applyFilterBankAccounts = (administrativeServiceId) => {
		setAdmServiceId(administrativeServiceId);
	}
	const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
	}) );

    const onBlurExpedienteControl = () => {
        const isValidCode = isValidateOrganismCode(expOrganismCodeRef?.current?.value);
        const code = expOrganismCodeRef?.current?.value;
        const number = expNumberRef?.current?.value;
        const year = expYearRef?.current?.value;
        if( code && number  && year && isValidCode){
            const params = {
                administrative_document_organism_code: parseInt(code),
                administrative_document_number: parseInt(number),
                administrative_document_year: parseInt(year)
            };
            dispatch(tryGetAdministrativeDocumentByOrderPay(1, 10, params));
        }
        setInvalidRangeCode(!isValidCode);
    };

	useEffect(() => {
		if (orderPay){
            setAdmServiceId(orderPay.administrativeServiceId);
		}
		if (fundRequest){
            setAdmServiceId(fundRequest.administrativeServiceId);
		}
		dispatch(clearListAllAdministrativeServicesData());
        dispatch(tryListPayOrderTypes());
        dispatch(clearListAllAdministrativeServicesData());
        dispatch(tryListAllServiceAdministrativeByUser(userFound?.id));
        //Mock
        // onClickSelectedProveedor(proveedoresList[2]);
        if (orderPay){
            // filter.cuit = orderPay.details[0].cuit;
            setAdmServiceId(orderPay.administrativeServiceId);
        }
    }, []);
    
	useEffect(() => {
		getOPFormData;
	}, [expedientesSeleccionados]);

	const getOPFormData = () => {
	let details = expedientesSeleccionados.map(item=> ({
		amount: item.amount,
		subCodeId: item.subCodeId,
	}));
	return {
		details
	}}
    
    const [pageNumber, setPageNumber] = useState(0);
    
    const handlerResponsePage = (page)=>{
        let promise = dispatch(tryListProveedores({pageId: page}));
        promise.then((response)=>{
            //setHasMoreProveedores(response.data.hasMore);
            setPageNumber(response.data.page);
        })
    }

	return <>
		<Commons
			administrativeServiceData={administrativeServiceData}
			admServiceId={admServiceId}
			setAdmServiceId={setAdmServiceId}
			applyFilterBankAccounts={applyFilterBankAccounts}
			valorImporte={importe}
			disabledImporte={false}
		/>

    

        

            <ExpedienteControl
                expedienteRefs={expedienteRefs} 
                onBlurExpedienteControl={showListBox? onBlurExpedienteControl: false}
                invalidRangeCode={invalidRangeCode}
                yearsOptions={yearsOptions}
            />
	
		<br></br>
		
	</>
};

export default IndebidoPaymentOrderForm;