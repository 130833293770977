import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Button, Table } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getProvisionOrderDetail } from 'src/redux/provisionOrder/provisionOrderReducer';
import { NumericFormat } from 'react-number-format';
import { PROVISION_ORDER_LIST } from 'src/utils/constants';
import { mFormat, parseFloatOrUndefined } from 'src/utils/utils';
import { administrativeService, AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const ProvisionOrderDetailPage = () => {
	const dispatch = useDispatch();
	const provisionOrderDetail = useSelector((state) => getProvisionOrderDetail(state));
	const administrativeServiceData = AdministrativeServiceData();
	const onClickBackButton = () => {
		dispatch(push(PROVISION_ORDER_LIST));
	};

	const [totalGeneral, setTotalGeneral] = useState(0);
	const calculateTotal = (list) => {
		setTotalGeneral(list?.map((item) => parseFloatOrUndefined(item?.totalAmount) || 0)
			.reduce((accumulator, currentValue) => {
				return accumulator + currentValue;
			}, 0)
		);
	};

	const cuitFormatter = (cuit) => {
		let formattedCuit = 'S/D';
		if (cuit != '') formattedCuit = cuit?.slice(0, 2) + '-' + cuit?.slice(2, 10) + '-' + cuit?.slice(cuit?.length - 1, cuit?.length);
		return formattedCuit;
	};

	useEffect(() => {
		calculateTotal(provisionOrderDetail?.provisionOrderRows);
	}, [provisionOrderDetail?.provisionOrderRows]);

	return <>
		<Container fluid>
			<Card className='mb-3'>
				<Card.Header className='h6'>
					Detalle de orden de provisión
				</Card.Header>
				<Card.Body>
					<Table className='text-black-color' striped borderless hover>
						<tbody>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Servicio:
								</td>
								<td className='text-center'>
									{administrativeService(provisionOrderDetail?.administrativeServiceId, administrativeServiceData)}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Numero de orden y año:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.number} / {provisionOrderDetail?.year}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Fecha de orden de provisión:
								</td>
								<td className='text-center'>
									{mFormat(provisionOrderDetail?.provisionOrderDate)}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Número expediente:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.administrativeDocument?.codeOrganism} - {provisionOrderDetail?.administrativeDocument?.number} / {provisionOrderDetail?.administrativeDocument?.year}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Iniciador:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.initiator}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Destino:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.destination}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Lugar de entrega:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.deliveryLocation}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Plazo de entrega:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.deadlineAmount} {provisionOrderDetail?.deadlineTimeType}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Contratación:
								</td>
								<td className='text-center'>
									{`${provisionOrderDetail?.acquisitionType?.replace(/_/g, ' ')} - ${provisionOrderDetail?.acquisitionNumber} - ${provisionOrderDetail?.acquisitionYear}`}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									CUIT:
								</td>
								<td className='text-center'>
									{cuitFormatter(provisionOrderDetail?.beneficiary?.persona?.cuit?.toString() || '')}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Proveedor:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.beneficiary?.type} - {provisionOrderDetail?.beneficiary?.persona?.companyName}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Instrumento legal:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.legalInstrument?.legalInstrumentType?.name} - {provisionOrderDetail?.legalInstrument?.number} - {provisionOrderDetail?.legalInstrument?.year}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Fecha de instrumento legal:
								</td>
								<td className='text-center'>
									{mFormat(provisionOrderDetail?.legalInstrument?.date)}
								</td>
							</tr>
							<tr>
								<td className='text-center font-weight-bold' width='40%' >
									Observación:
								</td>
								<td className='text-center'>
									{provisionOrderDetail?.observations || "-"}
								</td>
							</tr>
						</tbody>
					</Table>
					<Card className='mb-3'>
						<Card.Header className='h6'>
							Detalle de bienes y Servicios
						</Card.Header>
						<Card.Body>
							<Table striped hover size='sm'>
								<thead>
									<tr>
										<th className='text-center' style={{ "width": "10%" }}>Renglón</th>
										<th className='text-center' style={{ "width": "20%" }}>Descripción</th>
										<th className='text-center' style={{ "width": "20%" }}>Observación</th>
										<th className='text-center' style={{ "width": "10%" }}>Cantidad</th>
										<th className='text-center' style={{ "width": "10%" }}>U. Medida</th>
										<th className='text-center' style={{ "width": "15%" }}>$ Unitario</th>
										<th className='text-center' style={{ "width": "15%" }}>$ Total</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{provisionOrderDetail?.provisionOrderRows?.map(item => (
										<tr key={item?.id}>
											<td className='text-center'>
												{item?.provisionRowNumber}
											</td>
											<td className='text-center'>
												{item?.description}
											</td>
											<td className='text-center'>
												{item?.observations || '-'}
											</td>
											<td className='text-center'>
												<NumericFormat
													thousandSeparator={'.'}
													decimalSeparator={','}
													allowLeadingZeros={false}
													decimalScale={6}
													value={item?.quantity}
													className={'form-control text-right'}
													readOnly
												/>
											</td>
											<td className='text-center'>
												{item?.units}
											</td>
											<td className='text-center'>
												<NumericFormat
													prefix={'$'}
													thousandSeparator={'.'}
													decimalSeparator={','}
													allowLeadingZeros={false}
													decimalScale={6}
													value={item?.unitPrice}
													className={'form-control text-right'}
													readOnly
												/>
											</td>
											<td className='text-center'>
												<NumericFormat
													prefix={'$'}
													thousandSeparator={'.'}
													decimalSeparator={','}
													allowLeadingZeros={false}
													decimalScale={2}
													fixedDecimalScale={true}
													value={item?.totalAmount}
													className={'form-control text-right'}
													readOnly
												/>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
					<div className='contailer-fluid d-flex justify-content-around'>
						<span className='text-right d-flex mandatory-label' sm='4'>
							Total de la Orden de Provision:
						</span>
						<span >
							<NumericFormat
								prefix={'$'}
								thousandSeparator={'.'}
								decimalSeparator={','}
								decimalScale={2}
								value={totalGeneral}
								className={'form-control text-left'}
								readOnly
							/>
						</span>
					</div>
					<div className='text-center'>
						<Button variant='primary' size='lg' className='my-3' onClick={onClickBackButton}>
							<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
							Volver
						</Button>
					</div>
				</Card.Body>
			</Card>
		</Container>
	</>;
};

export default ProvisionOrderDetailPage;