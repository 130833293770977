import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Table, Container, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { faPlus, faAsterisk, faSearch, faChevronDown, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';
import { DropdownList } from 'react-widgets';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { config } from 'src/env.js';
import ActionIcon from 'src/components/general/ActionIcon';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { clearNullValuesFromArr, containsObject, getAdministrativeDocumentYearsOptions, parseIntOrUndefined, onKeyDownEnter } from 'src/utils/utils';
import { tryListAffectationsByOrderType, tryListOrderPay } from 'src/redux/orderPay/orderPayActionCreator'
import { getOrderType, getPayOrderLastListData, getPayOrderLastListIsFetching, getOrderPayListIsFetching } from 'src/redux/orderPay/orderPayReducer'
import FormFieldError from 'src/components/general/FormFieldError';
import { clearListAffectationsByOrderTypeData } from 'src/redux/orderPay/orderPayActions';
import { clearOneListAdministrativeDocumentByOrderPayData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { setSelectedDate } from 'src/redux/orderPay/orderPayActions';
import { getServicesByExerciseIdData } from 'src/redux/exercise/exerciseReducer.js';
import { administrativeServiceClose } from 'src/utils/administrativeServiceUtils.js';
import MessageComponent from 'src/components/common/MessageComponent';

const Commons = props => {
    const dispatch = useDispatch();
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const orderPayListIsFetching = useSelector(state => getOrderPayListIsFetching(state));
    const yearsOptions = getAdministrativeDocumentYearsOptions(globalSelectedPeriod?.year);
    const { administrativeServiceData, admServiceId, valorImporte, disabledImporte, disableAdmExpedientSearch, obligatoryLegalInstrument } = props;
    const { watch, setValue, register, orderPay, fundRequest, getValues, setAdmServiceId, errors, formValuesState, setFormValuesState, existingPOrderNumber, setExistingPOrderNumber, setAdmServiceCode } = useFormContext();
    const LEGAL_INSTRUMENT_TYPES = [].concat(config.appSettings.LEGAL_INSTRUMENTS_TYPES);
    const LEGAL_INSTRUMENT_TYPES_ORDERED = [LEGAL_INSTRUMENT_TYPES?.[2], LEGAL_INSTRUMENT_TYPES?.[0], LEGAL_INSTRUMENT_TYPES?.[4], LEGAL_INSTRUMENT_TYPES?.[1]];
    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
    const [paymentOrderNumber, setPaymentOrderNumber] = useState('');
    const [legalInstrumentNumberSt, setLegalInstrumentNumberSt] = useState('');
    const { legalInstruments } = formValuesState;
    const orderType = useSelector(state => getOrderType(state));
    const initialDate = moment(new Date(globalSelectedPeriod?.year, 0, 1)).format('YYYY-MM-DD');
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const finalDate = moment(new Date(globalSelectedPeriod?.year, 11, 31)).format('YYYY-MM-DD');
    let todayDate = (moment(new Date()).format('YYYY') == globalSelectedPeriod?.year) ? currentDate : finalDate;
    const ultimaordenPago = useSelector(state => getPayOrderLastListData(state));
    const IsFetching = useSelector(state => getPayOrderLastListIsFetching(state));
    const OPHaberes = orderType == "ORDEN_DE_PAGO_DE_HABERES";
    const OPProveedores = orderType == 'ORDEN_DE_PAGO_DE_PROVEEDORES';
    const showFieldObservations = OPHaberes || OPProveedores;
    let ultimaordendepago;
    if (ultimaordenPago) {
        let ordenPago = parseFloat(ultimaordenPago?.records[0]?.number);
        ultimaordendepago = ++ordenPago;
    }

    const servicesData = useSelector(state => getServicesByExerciseIdData(state));
    const removeInstrumentolegalList = i => {
        setFormValuesState({ ...formValuesState, legalInstruments: legalInstruments.filter((item, id) => id !== i) });
    };

    const addInstrument = () => {
        const item = {
            legalInstrumentType: { name: getValues().legalInstrumentTypeName },
            number: legalInstrumentNumberSt,
            year: getValues().legalInstrumentYear,
        };
        if (item.legalInstrumentType?.name &&
            item.number && item.year != "" &&
            !containsObject(legalInstruments, "number", legalInstrumentNumberSt)) {
            setLegalInstrumentNumberSt('');
            setValue('legalInstrumentYear', '');
            legalInstruments.push(item);
        }
    }

    const validateOrderPayNumber = (number) => {
        const params = {
            page: 0,
            size: 1,
            administrativeServiceId: admServiceId,
            number: number,
            year: globalSelectedPeriod?.year
        };
        if (parseIntOrUndefined(number) && admServiceId) {
            dispatch(tryListOrderPay(params)).then(response => {
                if (isNotEmptyArray(response?.data?.records)) {
                    setExistingPOrderNumber(true);
                } else setExistingPOrderNumber(false);
            })
        } else setExistingPOrderNumber(false);
    };

    const seleccionarServicio = (value) => {
        setAdmServiceId(value?.id);
        setAdmServiceCode(value?.code);
        setValue('number', '');
        setPaymentOrderNumber();
        setExistingPOrderNumber(false);
        delete errors?.admService;
        props.setAdmServiceId(value?.id)
        const paramsForSalaryOP = {
            paymentOrderType: orderType,
            servicio: value?.id,
        };

        disableAdmExpedientSearch && dispatch(tryListAffectationsByOrderType(paramsForSalaryOP));
    }

    // Validations
    const serviceRequiredMsg = 'Debe seleccionar un servicio';
    const dateRequiredMsg = 'Debe ingresar una fecha dentro del periodo';
    const descriptionRequiredMsg = 'Debe ingresar una descripción';
    const serviceClosedMsg = 'Servicio cerrado para el Ejercicio seleccionado';

    // Form data
    const serviceValidationObj = {
        required: serviceRequiredMsg,
        validate: () => { if (administrativeServiceClose(servicesData, admServiceId)) { return serviceClosedMsg } }
    };
    const numberValidationObj = { required: false };
    const dateValidationObj = { required: dateRequiredMsg };
    const descriptionValidationObj = { required: descriptionRequiredMsg };
    const observationsValidationObj = { required: false };

    const showNumberMessage = !orderPay?.number && !paymentOrderNumber && !existingPOrderNumber;

    const onSelectDate = v => {
        const params = getValues()?.date;;
        dispatch(setSelectedDate(params));
    };

    const onBlurSearchOrderPayByNumber = (number) => {
        validateOrderPayNumber(number);
    };

    useEffect(() => {
        dispatch(setSelectedDate(getValues()?.date));
        if (!orderPay?.id && administrativeServiceData?.length == 1) seleccionarServicio(administrativeServiceData[0]);
        if (disableAdmExpedientSearch && orderPay) {
            dispatch(clearListAffectationsByOrderTypeData());
            const paramsForSalaryOP = {
                paymentOrderType: orderPay.type,
                servicio: orderPay.administrativeServiceId,
            };
            dispatch(tryListAffectationsByOrderType(paramsForSalaryOP))
        }
        return () => {
            dispatch(clearListAffectationsByOrderTypeData());
            dispatch(clearOneListAdministrativeDocumentByOrderPayData());
        }
    }, []);

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Servicio:
            </Form.Label>
            <Col sm='4'>
                <DropdownList filter
                    placeholder='Debe seleccionar un servicio...'
                    data={administrativeServiceData}
                    allowCreate='onFilter'
                    name='admService'
                    textField='displayName'
                    dataKey='id'
                    value={admServiceId}
                    as='select'
                    defaultValue={fundRequest?.administrativeServiceId}
                    className='w-100 text-black-color'
                    onChange={value => seleccionarServicio(value)}
                    messages={messagesDropDown}
                    selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
                    /*searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}*/
                    disabled={!!orderPay || orderPayListIsFetching}
                >
                </DropdownList>
                <FormFieldError errors={errors?.admService} />
                <Form.Control hidden readOnly ref={register(serviceValidationObj)} name='admService' value={admServiceId} />
            </Col>
        </Form.Group>

        <Form.Group as={Row}>
            <Form.Label htmlFor='number' className='text-right d-flex mandatory-label' column sm='4'>
                Número:
            </Form.Label>
            <Col sm='2' className='paddingform'>
                {
                    IsFetching
                        ?
                        <Spinner animation='border' />
                        :
                        <Form.Control
                            type='number'
                            name='number'
                            placeholder='Número'
                            defaultValue={orderPay?.number}
                            ref={register(numberValidationObj)}
                            onChange={event => setPaymentOrderNumber(event.target.value)}
                            onBlur={event => onBlurSearchOrderPayByNumber(event?.target.value)}
                            readOnly={!!orderPay || orderPayListIsFetching || !admServiceId}
                            onKeyDown={event => onKeyDownEnter(event)}
                        />
                }
                <FormFieldError errors={errors?.number} />
            </Col>
            <Col sm='2'>
                <Form.Control
                    type='number'
                    ref={register}
                    name='fiscalYearId'
                    id='globalSelectedPeriod'
                    defaultValue={orderPay?.year || globalSelectedPeriod?.year}
                    readOnly
                />
            </Col>
            <Col sm='1' className='mx-0 px-0 pt-1 text-left align-middle'>
                {orderPayListIsFetching && <Spinner animation='border' size='sm' />}
            </Col>
        </Form.Group>
        {
            showNumberMessage &&
            <MessageComponent
                message='En caso de no indicar un número de OP, se asignará el próximo disponible.'
                alertType={'warning'}
            />
        }
        {
            existingPOrderNumber &&
            <MessageComponent
                message='Ya se encuentra registrado el número de OP para el servicio seleccionado.'
                alertType='danger'
            />
        }
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Fecha:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='date'
                    name='date'
                    defaultValue={orderPay?.datePaymentOrder ? orderPay?.datePaymentOrder : todayDate}
                    ref={register(dateValidationObj)}
                    max={todayDate}
                    min={initialDate}
                    onChange={onSelectDate}
                    onKeyDown={event => onKeyDownEnter(event)}
                />
                <FormFieldError errors={errors?.date} />
            </Col>
        </Form.Group>

        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Descripción:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    type='text'
                    name='description'
                    placeholder='Ingresar Descripción'
                    ref={register(descriptionValidationObj)}
                    defaultValue={orderPay?.description}
                    maxLength="255"
                    onKeyDown={event => onKeyDownEnter(event)}
                />
                <FormFieldError errors={errors?.description} />
            </Col>
        </Form.Group>
        {
            !disabledImporte &&
            <Form.Group as={Row}>
                <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                    Importe:
                </Form.Label>
                <Col sm='4'>
                    <Form.Control
                        type='number'
                        name='importe'
                        placeholder='Importe'
                        ref={register}
                        required
                        value={valorImporte}
                        onKeyDown={event => onKeyDownEnter(event)}
                    />
                </Col>
            </Form.Group>
        }

        <Form.Group as={Row} className='pb-3 mb-0'>
            <Form.Label htmlFor='number' className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon hidden={disableAdmExpedientSearch && obligatoryLegalInstrument} icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Instrumento Legal:
            </Form.Label>
            <Col sm='2' className='paddingform pr-1'>
                <Form.Control
                    as='select'
                    name='legalInstrumentTypeName'
                    ref={register}
                >
                    {
                        LEGAL_INSTRUMENT_TYPES_ORDERED?.map(item => (<option className='text-black-color' value={item.name} key={item.id}>
                            {item.name}
                        </option>))
                    }
                </Form.Control>
            </Col>
            <Col sm='1' className='paddingform px-1'>
                <NumericFormat
                    name='legalInstrumentNumber'
                    onChange={(event) => setLegalInstrumentNumberSt(event.target.value)}
                    className={'text-black-color form-control'}
                    placeholder={'Número'}
                    value={legalInstrumentNumberSt}
                    isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue > 0;
                    }}
                    onKeyDown={event => onKeyDownEnter(event)}
                />
            </Col>
            <Col sm='1' className='pl-1'>
                <Form.Control
                    as='select'
                    name='legalInstrumentYear'
                    ref={register}
                >
                    <option value={''}>Año</option>
                    {yearsOptions?.map(item => (<option value={item} key={item}>
                        {item}
                    </option>))
                    }
                </Form.Control>
            </Col>
            <ActionIcon size='lg' toolTipText="Agregar Instrumento" icon={faPlus}
                disabled={!legalInstrumentNumberSt || !watch('legalInstrumentYear')} 
                onClick={addInstrument}
                text='Añadir' 
            />
            {!disableAdmExpedientSearch &&
                <Col sm='4' className='offset-4'>
                    <Form.Control hidden ref={!disableAdmExpedientSearch && register({ required: 'Debe cargar un instrumento legal' })} name='hasInstruments' value={legalInstruments?.length ? 1 : ''} onChange={() => {}} />
                    {legalInstruments?.length < 1 &&
                        <FormFieldError errors={errors?.hasInstruments} />
                    }
                </Col>
            }
        </Form.Group>

        { isNotEmptyArray(clearNullValuesFromArr(legalInstruments)) ?
            <Col md={10} className="offset-md-1 px-0">
                <Table striped hover size="sm" className=''>
                    <thead>
                        <tr className='text-white-color'>
                            <th className='text-center' width="25%">Instrumento</th>
                            <th className='text-center' width="25%">Nro.</th>
                            <th className='text-center' width="25%">Año</th>
                            <th className='text-center' width="25%">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        {clearNullValuesFromArr(legalInstruments)?.map((item, i) => (
                            <tr key={i}>
                                <td className='text-center'>
                                    {item?.legalInstrumentType?.name}
                                </td>
                                <td className='text-center'>
                                    {item?.number}
                                </td>
                                <td className='text-center'>
                                    {item?.year}
                                </td>
                                <td className='text-center'>
                                    <ActionIcon size='lg' toolTipText='Remover Instrumento' 
                                        icon={faTimes} className='text-danger' 
                                        onClick={() => removeInstrumentolegalList(i)} 
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
            :
            <>
                {!obligatoryLegalInstrument &&
                    <Col sm={4} className='offset-md-4 mb-3' >
                        <Alert variant='warning' className='mb-0'>
                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                            Debe añadir un Instrumento legal.
                        </Alert>
                    </Col>
                }
            </>
        }

        { showFieldObservations &&
            <Form.Group as={Row} className={disableAdmExpedientSearch ? 'pt-4' : ''}>
                <Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
                    Observaciones:
                </Form.Label>
                <Col sm='4'>
                    <Form.Control
                        as='textarea'
                        name='observations'
                        placeholder='Ingresar Observaciones'
                        rows={2}
                        ref={register(observationsValidationObj)}
                        defaultValue={orderPay?.observations}
                        maxLength="255"
                    />
                    <FormFieldError errors={errors?.observations} />
                </Col>
            </Form.Group>
        }
    </>
};

export default Commons;