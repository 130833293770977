import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import PageTitle from '../../general/PageTitle';
import { Container, Card, Table, Button, Form, Row, Col, Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { dateNotification, subject, message, attachedFiles, selection, sendTo, lastNameName, userEmail, group, back, dowload, notificationDetail, notification, noAttachment, addressee } from 'src/utils/label';

import { getNotificationToDetail, getNotificationDownloadFileIsFetching } from 'src/redux/notification/notificationReducer'
import { tryDownloadFileOfNotification } from 'src/redux/notification/notificationActionCreator'; 
import { tryGetListUserProfile, } from 'src/redux/user/userActionCreator';

import { NOTIFICATION_LIST } from 'src/utils/constants';
import moment from 'moment';

import { isNotEmptyArray } from 'src/services/validationService';

import { getUserProfileListData } from 'src/redux/user/userReducer';

import AppLoading from 'src/components/common/AppLoading';

const NotificationDetailsPage = () => {

    const dispatch = useDispatch();

    // User detail
	const notificationData = useSelector(state => getNotificationToDetail(state));
    const downloadFileOfNotificationIsFetching = useSelector(state => getNotificationDownloadFileIsFetching(state));

    //Users
    const recipients = notificationData?.recipient?.recipients;
    const users = notificationData?.recipient?.recipients ? JSON.parse(recipients) : [];
    const hasListUsers= isNotEmptyArray(users);

    //profile
    const listProfileData = useSelector( state => getUserProfileListData(state) )?.sort((a, b) => a.name > b.name ? 1 : -1);

    const profileById = listProfileData?.find(profile => profile?.id == notificationData?.profileId);

    // Back button
	const onClickBackButton = () => {
		dispatch(push(NOTIFICATION_LIST));
	};

    const onClickDownloadFile = (notification) => {
        dispatch(tryDownloadFileOfNotification(notification?.id));
    };

    useEffect( () => {
		dispatch( tryGetListUserProfile() );
	}, [] );
    
    return <Container fluid className='mb-5'>
        <Card>
            <PageTitle text={notificationDetail} />
            <Container fluid>

				<Card className='mb-3'>
                    <PageTitle text={notification} />
					<Card.Body>
                        <Container fluid>							
                                <Row>
                                    <Col xs={12} className='text-right'>
                                        <Form.Group className='mb-0' as={Row} controlId='subjectId'>
                                            <Form.Label className='text-black-color' column sm='3'>
                                            {subject}:
                                        </Form.Label>
                                            <Col sm='6'>
                                                <Form.Control plaintext readOnly className='mx-3' title={notificationData?.subject || ''} defaultValue={notificationData?.subject || ''} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='text-right'>
                                        <Form.Group className='mb-0' as={Row} controlId='messageId'>
                                            <Form.Label className='text-black-color' column sm='3'>
                                            {message}:
                                        </Form.Label>
                                            <Col sm='6'>
                                            <Form.Control
                                                as='textarea'
                                                className='mx-3 pl-1'
                                                defaultValue={notificationData?.body + '\n\n' + notificationData?.signature}
                                                disabled={true}
                                                rows={6}
                                            />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='text-right'>
                                        <Form.Group className='mb-0' as={Row} controlId='fileId'>
                                            <Form.Label className='text-black-color' column sm='3'>
                                            {attachedFiles}:
                                        </Form.Label>
                                            <Col sm='6'className='text-left ml-3 mt-2 text-black-color'>
                                                {
                                                    notificationData?.attachments?.length > 0
                                                        ?
                                                            <a className='font-italic text-info cursor-pointer' onClick={() => onClickDownloadFile(notificationData)}>
                                                                {dowload}
                                                            </a>
                                                        :
                                                            'No posee.'
                                                }
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='text-right'>
                                        <Form.Group className='mb-0' as={Row} controlId='dateId'>
                                            <Form.Label className='text-black-color' column sm='3'>
                                            {dateNotification}:
                                        </Form.Label>
                                            <Col sm='6'>
                                                <Form.Control plaintext readOnly className='mx-3' defaultValue={moment(notificationData?.createdDate).format('DD/MM/YYYY hh:mm')} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className='text-right'>
                                        <Form.Group className='mb-0' as={Row} controlId='dateId'>
                                            <Form.Label className='text-black-color' column sm='3'>
                                            Fecha hasta:
                                        </Form.Label>
                                            <Col sm='6'>
                                                <Form.Control plaintext readOnly className='mx-3' defaultValue={notificationData?.notificationEnd ? moment(notificationData?.notificationEnd)?.format('DD/MM/YYYY') : "-"} />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                        </Container>
                    </Card.Body>
                </Card>

                <Card className='mb-3'>
                    <PageTitle text={addressee} />
					<Card.Body>
                        <Container fluid>
                            <Row>
                                <Col xs={12} className='text-right'>
                                    <Form.Group className='mb-0' as={Row} controlId='profileId'>
                                        <Form.Label className='text-black-color' column sm='3'>
                                            {selection}:
                                        </Form.Label>
                                        <Col sm='6'>
                                            <Form.Control plaintext readOnly className='mx-3' defaultValue={profileById?.name || 'Todos'} />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className='text-right'>
                                    <Form.Group className='mb-0' as={Row} controlId='sendToId'>
                                        <Form.Label className='text-black-color' column sm='3'>
                                            {sendTo}:
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center mt-3">
                                <Col xs={8} className='text-right'>
                                    <Table striped bordered hover responsive size='sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' width='20%'>{lastNameName}</th>
                                                <th className='text-center' width='50%' >{userEmail}</th>
                                                <th className='text-center' width='30%'>{group}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='text-black-color'>
                                            {
                                                hasListUsers ?
                                                    users.map(user => <tr key={user?.id}>
                                                        <td className='text-center'>
                                                            {user?.name}
                                                        </td>
                                                        <td className='text-center'>
                                                            {user?.email}
                                                        </td>
                                                        <td className='text-center'>
                                                            {user?.profileName}
                                                        </td>
                                                    </tr>)
                                                :
                                                <tr>
                                                    <td colSpan='10' className='text-center'>
                                                        <Alert variant='info' className='mb-0'>
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                              {'No hay usuarios'}
                                                        </Alert>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>

					<div className='text-center'>
						<Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
							<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								{back}
						</Button>
					</div>
                    
                </Card>
                <AppLoading isLoading={downloadFileOfNotificationIsFetching} />                         
                
            </Container>

        </Card>
    </Container>
};

export default NotificationDetailsPage;