import React from "react";
import { Button, Modal, Table } from "react-bootstrap";

const ConceptDetailModal = (props) => {
  const { show, handleClose, dataModal } = props;
  
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Detalle de Concepto</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="text-black-color" style={{ "fontSize": "0.9rem" }}>
          <p className="p-0 m-0">
            <b>Concepto:</b> {dataModal?.description}
          </p>
          <hr></hr>
        </div>

        <Table striped borderless hover>
          <thead className="font-weight-bold">
            <tr>
              <th className="text-center align-middle">SubConceptos</th>
            </tr>
          </thead>

          <tbody className="text-black-color">
            {dataModal?.subConcept?.map((subconceptItem) => {
              return (
                <tr key={subconceptItem?.id}>
                  <td className="text-center align-middle py-1">
                    {`${subconceptItem?.description}`}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConceptDetailModal;
