import React, { useState } from 'react';
import { Button, Modal, Row, Alert } from 'react-bootstrap';
import Container from '../../../../node_modules/react-bootstrap/esm/Container';
import Table from '../../../../node_modules/react-bootstrap/esm/Table';

import ActionIcon from 'src/components/general/ActionIcon';
import { faAngleDown, faAngleUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { isNotEmptyArray } from '../../../services/validationService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RPConversionDetailModal = (props) => {

    const { show, handleClose, dataModal } = props;

    const includeSubcodesForRpByService = dataModal?.includeSubcodesForRpByService || [];
    const excludeSubcodesForRpByService = dataModal?.excludeSubcodesForRpByService || [];

    const numberOfIncludeSubcodesForRpByService = includeSubcodesForRpByService?.length;
    const numberOfExcludeSubcodesForRpByService = excludeSubcodesForRpByService?.length;

    const numberOfItemsProcessed = (data) => {
        let number = 0;
        data?.map((item, index) => {
            number += item?.subcodes?.length || 0;
        });
        return number;
    };

    const [flag, setFlag] = useState({
        idActive: null
    });

    const handleChangeFlag = (id) => {
        flag.idActive === id ? setFlag({ idActive: null }) : setFlag({ idActive: id });
    };

    const TableComponent = (data) => {

        const registers = data?.data;
        const hasSubcodesForRpByService = isNotEmptyArray(registers);

        return (
            <Table bordered hover size='sm'>
                <thead className='text-white-color'>
                    <tr>
                        <th className='text-center align-middle' width='5%'>Nro. de Expediente</th>
                        <th className='text-center align-middle' width='91%'>Nro. Partida</th>
                        <th className='text-center align-middle' width='91%'>Acciones</th>
                    </tr>
                </thead>
                <tbody className='text-black-color'>
                    {
                        hasSubcodesForRpByService ?
                            registers?.map((item, index) => {
                                return <>
                                    <tr key={index}>
                                        <td className='text-center align-middle'>{item?.administrativeDocument}</td>
                                        <td className='text-center align-middle'></td>
                                        <td className='text-center align-middle'>
                                            <ActionIcon
                                                size="lg"
                                                className="text-black-color"
                                                toolTipText={flag.idActive == index ? "Contraer expediente" : "Expandir expediente"}
                                                icon={flag?.idActive == item?.expedient ? faAngleDown : faAngleUp}
                                                onClick={() => handleChangeFlag(item?.administrativeDocument)}
                                            />
                                        </td>
                                    </tr>
                                    {flag.idActive === item?.administrativeDocument &&
                                        item?.subcodes?.map((subCodeItem, indexM) =>
                                            <tr key={indexM}>
                                                <td className='text-center align-middle'></td>
                                                <td className='text-center'>{subCodeItem}</td>
                                                <td className='text-center align-middle'></td>
                                            </tr>
                                        )
                                    }

                                </>
                            })
                            :
                            <tr>
                                <td colSpan='8'>
                                    <Row className='d-flex justify-content-center'>
                                        <Alert variant='info' className='d-flex w-100 justify-content-center mb-0 mx-3'>
                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                            Sin expedientes para mostrar
                                        </Alert>
                                    </Row>
                                </td>
                            </tr>
                    }
                </tbody>
            </Table>
        )
    };

    return <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">

        <Modal.Header closeButton>
            <Modal.Title>Detalle de conversión a Residuos Pasivos por servicio</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Container fluid className='text-black-color'>
                <p className='pb-0 mb-0'>Expedientes procesados: {numberOfIncludeSubcodesForRpByService}</p>
                <p>Número de Partidas convertidas a RP: {numberOfItemsProcessed(includeSubcodesForRpByService)}</p>
                <TableComponent data={includeSubcodesForRpByService} />
                <br></br>
                <p className='pb-0 mb-0'>Expedientes no procesados: {numberOfExcludeSubcodesForRpByService}</p>
                <p>Número de Partidas no convertidas a RP: {numberOfItemsProcessed(excludeSubcodesForRpByService)}</p>
                <TableComponent data={excludeSubcodesForRpByService} />
            </Container>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cerrar
            </Button>
        </Modal.Footer>

    </Modal>
}

export default RPConversionDetailModal