import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery } from './httpServices';

// audit Presupuesto API
const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const AUDIT_ENDPOINT_URI = '/expenses/audit';

// audit AuthAPI
const API_AUTHAPI_BASE_URI_V2 = `${config.apis.authApi.URL}/v2`;
const AUDIT_USERS_ENDPOINT_URL = '/usersaudit/filtered';

export const listAudits = async ( accessToken, params ) => {

	const jsonFilter = params?.filter ? JSON.stringify(params?.filter) : undefined;
	const setbuildURLQuery = {
		page: params?.page || 1,
		pageSize: params?.size || 10,
		filter: jsonFilter
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${AUDIT_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

export const listSectorsByAuditId = async ( accessToken, sectionId ) => {
	const URL = `${API_BASE_URI}${AUDIT_ENDPOINT_URI}/${sectionId}/sectors`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

// list audits users
export const listAuditsUsers = async ( accessToken, params ) => {

	const StartDate = params.StartDate;
	const EndDate = params.EndDate;
	const setbuildURLQuery = {
		Page: params?.page || 1,
		PageSize: params?.size || 10,
		FilterText: `${params?.filter?.actionId || '' }%${params?.filter?.email || '' }`,
		...(StartDate && {StartDate}),
		...(EndDate && {EndDate})
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_AUTHAPI_BASE_URI_V2}${AUDIT_USERS_ENDPOINT_URL}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};