import React, { useEffect } from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import FormFieldError from 'src/components/general/FormFieldError';
import StepIndicator from 'src/components/common/StepIndicator';
import { showSuccess } from 'src/redux/globalData/globalDataActionCreator';

import { getExerciseEditSelectedLegalInstrument } from 'src/redux/exercise/exerciseReducer';
import { setSelectedLegalInstrument } from 'src/redux/exercise/exerciseActions';

import { tryCreateLegalInstrument, tryListLegalInstrument, tryDownloadFileOfLegalInstrument } from 'src/redux/legalInstrument/legalInstrumentActionCreator';
import { getLegalInstrumentCreateIsFetching, getLegalInstrumentListData, getLegalInstrumentListIsFetching } from 'src/redux/legalInstrument/legalInstrumentReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { dateToStringFormatedToShowUSA } from 'src/utils/utils';
import { EXERCISES_LIST, EXERCISE_EDIT_STEP_TWO } from 'src/utils/constants';

const ExerciseEditStepOnePage = props => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues, reset } = useForm();

	const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;

	// Validations
	const numberRequiredMsg = 'Debe ingresar el Nro. del instrumento legal.';
	const fileMaxSizeMsg = 'El archivo no puede superar los 5MB.';
	const dateRequiredMsg = 'Debe seleccionar la fecha del instrumento legal.';
	const descriptionRequiredMsg = 'Debe ingresar una descripción.';
	const numberValidationObj = { required: numberRequiredMsg };
	const descriptionValidationObj = { required: descriptionRequiredMsg };
	const fileValidationObj = {
		validate: file => {
			const sizeInMB = file[0]?.size/1024/1024;
			return ( sizeInMB>=5 || sizeInMB==undefined ) ? fileMaxSizeMsg : undefined;
		}
	};
	const dateValidationObj = { required: dateRequiredMsg };
	const todayDate = dateToStringFormatedToShowUSA( new Date() );

	// Form data
	const selectedLegalInstrument = useSelector( state => getExerciseEditSelectedLegalInstrument(state) );
	const createLegalInstrumentIsFetching = useSelector( state => getLegalInstrumentCreateIsFetching(state) );
	// const creditProcessInProgress = useSelector( state => getCreditProcessInProgress(state) );

	// Legal instrument search
	const legalInstrumentDataSearch = useSelector( state => getLegalInstrumentListData(state) )?.records;
	const legalInstrumentSearchIsFetching = useSelector( state => getLegalInstrumentListIsFetching(state) );

	const getTypeObjById = typeId => LEGAL_INSTRUMENT_TYPES?.find( item => item.id == typeId );

	const onFormSubmit = data => {
		data.legalInstrumentTypeName = getTypeObjById( data?.legalInstrumentTypeId )?.name;
		dispatch( tryCreateLegalInstrument(data) )
			.then( response => {
				if( response?.status == 200 ) {
					dispatch( setSelectedLegalInstrument(response?.data) );
					dispatch( push(EXERCISE_EDIT_STEP_TWO) );
				}
			});
	};

	const searchLegalInstrument = () => {

		const yearSelected = new Date(getValues('date'))?.getFullYear();

		const searchParam = {
			number: getValues('number'),
			year: yearSelected?.toString(),
			idLegalInstrumentType: getValues('legalInstrumentTypeId')
		};

		if( searchParam.number && !isNaN(yearSelected) && searchParam.idLegalInstrumentType ) {
			dispatch( tryListLegalInstrument(searchParam) ).then( data => {
				const records = data?.records;

				if( Array.isArray(records) && isNotEmptyArray(records) ) {
					showSuccess('Se encontró un instrumento legal con los datos ingresados.' );
					dispatch( setSelectedLegalInstrument(records[0]) );
				}
			});
		}
	};

	const onClickRemoveSelectedLegalInstrument = () => {
		reset();
		dispatch( setSelectedLegalInstrument(undefined) );
	};

	const onClickDownloadLegalInstrumentFile = () => {
		dispatch( tryDownloadFileOfLegalInstrument(selectedLegalInstrument) );
	};

	// useEffect( () => {
	// 	dispatch( setSelectedLegalInstrument(undefined) );
	// }, [] );

	return <Container className='mb-5'>
		<Card>
			<PageTitle text='Partidas presupuestarias'/>

			<Container fluid>

				<div>
					<StepIndicator steps={config.appSettings.steps.exerciseEdition} current={0} className='mt-2 mb-3'/>
				</div>

				<Card className='mb-3'>
					<Card.Header className='h6'>
						Editar período complementario
					</Card.Header>
					<Card.Body >
						<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>
									Datos del Instrumento Legal Asociado
								</h6>
							</Col>
							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Obligatorio
								</span>
							</Col>
						</Row>

						{
							selectedLegalInstrument
							?
								<>
									<div className='d-flex justify-content-center'>
										<Card className='text-black-color shadow bg-white rounded mt-3 mb-3 p-3' style={{ width: '20rem' }}>
											<FontAwesomeIcon icon={faTimes} className='text-black-color cursor-pointer close-button' onClick={onClickRemoveSelectedLegalInstrument}/>
											<Row>
												<Col className='text-right'>
													Nro.:
												</Col>
												<Col>
													{selectedLegalInstrument.number}
												</Col>
											</Row>
											<Row>
												<Col className='text-right'>
													Tipo:
												</Col>
												<Col>
													{selectedLegalInstrument.legalInstrumentType?.name}
												</Col>
											</Row>
											<Row>
												<Col className='text-right'>
													Fecha:
												</Col>
												<Col>
													{selectedLegalInstrument.date}
												</Col>
											</Row>
											<Row>
												<Col className='text-right'>
													Descripción:
												</Col>
												<Col>
													{selectedLegalInstrument.description}
												</Col>
											</Row>
											{
												selectedLegalInstrument?.fileName
												?
													<Row>
														<Col className='text-right'>
															Archivo adjunto:
														</Col>
														<Col>
															<a className='font-italic text-info cursor-pointer' onClick={onClickDownloadLegalInstrumentFile}>
																Descargar
															</a>
														</Col>
													</Row>
												:
												null
											}
										</Card>
									</div>
									<hr/>
								</>
							:
								null
						}


						<Form className='mt-4' onSubmit={handleSubmit(onFormSubmit)}>

							<Form.Group as={Row}>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Fecha del inst. legal:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										type='date'
										name='date'
										disabled={!!selectedLegalInstrument||legalInstrumentSearchIsFetching}
										ref={register(dateValidationObj)}
										max={todayDate}
										// value={todayDate}
										className='text-center'
										onChange={searchLegalInstrument}/>
									<FormFieldError errors={errors?.date}/>
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Tipo:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										as='select'
										name='legalInstrumentTypeId'
										disabled={!!selectedLegalInstrument||legalInstrumentSearchIsFetching}
										ref={register}
										onChange={searchLegalInstrument}>
										{
											LEGAL_INSTRUMENT_TYPES?.map( (item, idx) => (<option className='text-black-color' value={item.id} key={idx}>
												{item.name}
											</option>))
										}
									</Form.Control>
								</Col>
							</Form.Group>

							<Form.Group as={Row} controlId='formHorizontalEmail'>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Nro. Instrumento Legal:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										type='text'
										name='number'
										placeholder='Nro. Instrumento Legal'
										disabled={!!selectedLegalInstrument||legalInstrumentSearchIsFetching}
										ref={register(numberValidationObj)}
										onBlur={searchLegalInstrument}/>
									<FormFieldError errors={errors?.number}/>
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Descripción:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										as='textarea'
										name='description'
										disabled={!!selectedLegalInstrument||legalInstrumentSearchIsFetching}
										ref={register(descriptionValidationObj)}/>
									<FormFieldError errors={errors?.description}/>
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									Archivo:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										type='file'
										name='file'
										accept='.pdf,.jpg,.png'
										disabled={!!selectedLegalInstrument||legalInstrumentSearchIsFetching}
										ref={register(fileValidationObj)}
										className='text-black-color'/>
									<FormFieldError errors={errors?.file}/>
								</Col>
							</Form.Group>

							<div className='d-flex justify-content-around mt-4 mb-3'>
								<Button type='button' variant='danger' size='lg' disabled={createLegalInstrumentIsFetching} onClick={() => dispatch( push(EXERCISES_LIST) )}>
									Cancelar
								</Button>


								<span className={createLegalInstrumentIsFetching ? '' : 'hidden'}>
									<Spinner animation='border'/>
								</span>

								{
									selectedLegalInstrument
									?
										<Button
											className='text-white-color'
											type='button'
											variant='success'
											size='lg'
											disabled={legalInstrumentSearchIsFetching}
											onClick={() => dispatch( push(EXERCISE_EDIT_STEP_TWO) )}>
											Siguiente
											<FontAwesomeIcon icon={faArrowRight} className='ml-2'/>
										</Button>
									:
										<Button
											className='text-white-color'
											type='submit'
											variant='success'
											size='lg'
											disabled={createLegalInstrumentIsFetching||legalInstrumentSearchIsFetching}>
											Siguiente
											<FontAwesomeIcon icon={faArrowRight} className='ml-2'/>
										</Button>
								}

							</div>
						</Form>	
					</Card.Body>
				</Card>
			</Container>
		</Card>

	</Container>
};

export default ExerciseEditStepOnePage;