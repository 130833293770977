import * as ACTION_TYPES from './sectionActionTypes';

// Actions list actions
export const getSectionListRequest = () => ({
	type: ACTION_TYPES.LIST_SECTION_REQUEST
});

export const getSectionListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_SECTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getSectionListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SECTION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearSectionList = () => ({
	type: ACTION_TYPES.CLEAR_SECTION_LIST
});
// END Actions list actions