import { useDispatch, useSelector } from 'react-redux';
import { getAccessToken } from 'src/redux/login/loginReducer';
import * as ACTIONS from './globalDataActions';
import * as PERIOD_SERVICES from 'src/services/periodServices';
import { logout } from 'src/redux/login/loginActions';
import { push } from 'connected-react-router';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { toast } from 'react-toastify';

const notification = {
	title: 'Generic Title',
	message: 'Generic Message',
	position: 'tr',
	autoDismiss: 10
};

const dispathNotification = (dispatch, typeFunc, message, title) => dispatch( typeFunc( {
	...notification,
	title,
	message
}) );

export const showSuccess = (message, title = '¡Éxito!') => toast.success(`${title}: ${message}`);
export const showError = (message, title = 'Error') => toast.error(`${title}: ${message}`);
export const showWarning = (message, title = 'Atención') => toast.warn(`${title}: ${message}`);

export const tryGetPeriodsList = () => {
	return  async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getPeriodsList() );

		if(accessToken){

			const response = await PERIOD_SERVICES.listPeriods( accessToken );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getPeriodsListSuccess(response?.data) );
			} else if (response?.status === 401) {
				dispatch( ACTIONS.getPeriodsListFailure(response?.data) );
				showError(`Error al cargar los periodos. Detalle: Token inválido` );
				dispatch( logout() );
				dispatch(push(ROUTES.LOGIN_URI));
			}
			else {
				dispatch( ACTIONS.getPeriodsListFailure(response?.data) );
				showError(`Error al cargar los periodos. Detalle: ${response?.data?.message}` );
			}
		}

	};
};