import * as ACTIONS from './administrativeOrganismActions';
import * as SERVICES from 'src/services/administrativeOrganismServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';


// Try list administrative Organism
export const tryListAdministrativeOrganism = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAdministrativeOrganismListRequest());

		if(accessToken){

			const response = await SERVICES.listAdministrativeOrganism(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.getAdministrativeOrganismListRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getAdministrativeOrganismListRequestFailure(response?.response?.data));
				showError(`Error al obtener los organismos. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
}

// Try list One administrative Organism
export const tryListOneAdministrativeOrganism = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAdministrativeOrganismListOneRequest());

		if(accessToken){

			const response = await SERVICES.listOneAdministrativeOrganism(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.getAdministrativeOrganismListOneRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getAdministrativeOrganismListOneRequestFailure(response?.response?.data));
				showError(`Error al obtener el organismos. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
}

//Create
export const tryPostAdministrativeOrganism = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.postAdministrativeOrganismRequest());

		if(accessToken){
			
			const response = await SERVICES.newAdministrativeOrganism(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.postAdministrativeOrganismRequestSuccess(response?.data));
				showSuccess('Organismo administrativo registrado correctamente.');
			}
			else {
				dispatch(ACTIONS.postAdministrativeOrganismRequestFailure(response?.data?.error));
				showError(`Error al crear el organismo administrativo. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};

//Edit
export const tryPutAdministrativeOrganism = (organismId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.putAdministrativeOrganismRequest());

		if(accessToken){

			const response = await SERVICES.editAdministrativeOrganism(accessToken, organismId, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.putAdministrativeOrganismRequestSuccess(response?.data));
				showSuccess('Organismo administrativo editado correctamente.');
			}
			else {
				dispatch(ACTIONS.putAdministrativeOrganismRequestFailure(response?.data?.error));
				showError(`Error al editar el organismo administrativo. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};

//Delete
export const tryDeleteAdministrativeOrganism = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.deleteAdministrativeOrganismRequest());

		if(accessToken){
			
			const response = await SERVICES.deleteAdministrativeOrganism(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.deleteAdministrativeOrganismRequestSuccess(response?.data));
				showSuccess('Organismo administrativo eliminado correctamente.');
			}
			else {
				dispatch(ACTIONS.deleteAdministrativeOrganismRequestFailure(response?.data?.error));
				showError(`Error al eliminar el organismo administrativo. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};


// Try Classfier administrative Organism
export const tryClassifierAdministrativeOrganism = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.classifierAdministrativeOrganismRequest());

		if(accessToken){

			const response = await SERVICES.classifierAdministrativeOrganism(accessToken, params);
	
			if (response?.status == 200) {
				dispatch(ACTIONS.classifierAdministrativeOrganismRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.classifierAdministrativeOrganismRequestFailure(response?.response?.data));
				showError(`Error al obtener los clasificadores de organismos. Detalle: ${response?.response?.data?.message || response}`);
			}
		}

	};
}


// Service Administrative manager Organism  
export const tryServiceAdministrativeManagerOrganism = (administrativeOrganismId, exerciseId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.serviceAdministrativeManagerOrganismRequest());

		if(accessToken){

			const response = await SERVICES.getServiceAdministrativeManagerOrganism(accessToken, administrativeOrganismId, exerciseId);
			if (response?.status == 200) {
				dispatch(ACTIONS.serviceAdministrativeManagerOrganismRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.serviceAdministrativeManagerOrganismRequestFailure(response?.error || response));
				showError(`Error al cargar los servicios que administran el organismo. Detalle: ${response?.response?.data?.message || response?.error || response}`);
				return response;
			}
		}

	};
};