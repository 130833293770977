import * as ACTION_TYPES from './organizationActionTypes';



// Organization by jurisdiction ID list actions
export const getOrganizationListByJurisdictionIdRequest = jurisdictionId => ({
	type: ACTION_TYPES.LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST,
	payload: {
		jurisdictionId
	}
});

export const getOrganizationListByJurisdictionIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getOrganizationListByJurisdictionIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearOrganizationListByJurisdictionId = () => ({
	type: ACTION_TYPES.CLEAR_ORGANIZATIONS_BY_JURISDICTION_ID_LIST
});
// END Organization by jurisdiction ID list actions



// Organization type list actions
export const getOrganizationTypesRequest = () => ({
	type: ACTION_TYPES.LIST_ORGANIZATION_TYPES_REQUEST
});

export const getOrganizationTypesRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ORGANIZATION_TYPES_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getOrganizationTypesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ORGANIZATION_TYPES_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearOrganizationTypes = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ORGANIZATION_TYPES
});
// END Organization type list actions


// Organization list actions
export const getOrganizationListRequest = () => ({
	type: ACTION_TYPES.LIST_ORGANIZATION_REQUEST
});

export const getOrganizationListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ORGANIZATION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getOrganizationListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ORGANIZATION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearOrganizationList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ORGANIZATION
});
// END Organization list actions


// Organisms By Jurisdictions, Services and Exercise
export const getOrganismsByJurisdictionsIdByServicesIdAndExerciseIdRequest = () => ({
	type: ACTION_TYPES.ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST
});

export const getOrganismsByJurisdictionsIdByServicesIdAndExerciseIdRequestSuccess = (data) => ({
	type: ACTION_TYPES.ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getOrganismsByJurisdictionsIdByServicesIdAndExerciseIdRequestFailure = error => ({
	type: ACTION_TYPES.ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearOrganismsByJurisdictionsIdByServicesIdAndExerciseId = () => ({
	type: ACTION_TYPES.CLEAR_ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID
});
// END  List all administrative services by User 