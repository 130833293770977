import * as ACTION_TYPES from './serviceActionTypes';

export const serviceReducerInitialState = {
	serviceList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	serviceJurisdictionOrganismList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const serviceReducer = ( state=serviceReducerInitialState, action ) => {
	switch( action.type ) {
		case ACTION_TYPES.LIST_SERVICE_REQUEST: {
			return {
				...state,
				serviceList: {
					...state.serviceList,
					isFetching: true,
					hasError: false,
					data: serviceReducerInitialState.serviceList.data,
					error: serviceReducerInitialState.serviceList.error
				}
			};
		}
		case ACTION_TYPES.LIST_SERVICE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				serviceList: {
					...state.serviceList,
					isFetching: false,
					hasError: false,
					data,
					error: serviceReducerInitialState.serviceList.error
				}
			};
		}
		case ACTION_TYPES.LIST_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				serviceList: {
					...state.serviceList,
					isFetching: false,
					hasError: true,
					data: serviceReducerInitialState.serviceList.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_SERVICES: {
			return {
				...state,
				serviceList: {
					...serviceReducerInitialState.serviceList
				}
			};
		}

		case ACTION_TYPES.LIST_SERVICE_JURISDICTION_ORGANISM_REQUEST: {
			return {
				...state,
				serviceJurisdictionOrganismList: {
					...state.serviceJurisdictionOrganismList,
					isFetching: true,
					hasError: false,
					data: serviceReducerInitialState.serviceJurisdictionOrganismList.data,
					error: serviceReducerInitialState.serviceJurisdictionOrganismList.error
				}
			}
		}
		case ACTION_TYPES.LIST_SERVICE_JURISDICTION_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				serviceJurisdictionOrganismList: {
					...state.serviceJurisdictionOrganismList,
					isFetching: false,
					hasError: false,
					data,
					error: serviceReducerInitialState.serviceJurisdictionOrganismList.error
				}
			}
		}
		case ACTION_TYPES.LIST_SERVICE_JURISDICTION_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				serviceJurisdictionOrganismList: {
					...state.serviceJurisdictionOrganismList,
					isFetching: false,
					hasError: true,
					data: serviceReducerInitialState.serviceJurisdictionOrganismList.data,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_LIST_SERVICES_JURISDICTION_ORGANISM: {
			return {
				...state,
				serviceJurisdictionOrganismList: {
					...serviceReducerInitialState.serviceJurisdictionOrganismList
				}
			}
		}

		default: {
			return state;
		}
	}
};

export const getServiceReducer = state => state.serviceReducer;

export const getServiceList = state => getServiceReducer(state).serviceList;
export const getServiceListData = state => getServiceList(state).data;
export const getServiceListIsFetching = state => getServiceList(state).isFetching;
export const getServiceListHasError = state => getServiceList(state).hasError;
export const getServiceListError = state => getServiceList(state).error;

export const getServiceJurisdictionOrganismList = state => getServiceReducer(state).serviceJurisdictionOrganismList;
export const getServiceJurisdictionOrganismListData = state => getServiceJurisdictionOrganismList(state).data;