import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExclamationTriangle, faSearch, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

import { isNotEmptyArray } from 'src/services/validationService';
import PageTitle from 'src/components/general/PageTitle';
import ActionIcon from 'src/components/general/ActionIcon';
import * as  LABELS from 'src/utils/label';

import { getUserPermissionsOrganisms } from 'src/redux/login/loginReducer';

import { ADMINISTRATIVE_ORGANISM_NEW, ADMINISTRATIVE_ORGANISM_EDIT, ADMINISTRATIVE_ORGANISM_DETAILS } from 'src/utils/constants';
import { tryListAdministrativeOrganism, tryDeleteAdministrativeOrganism } from 'src/redux/AdministrativeOrganism/administrativeOrganismActionCreator';
import {setAdministrativeOrganismToEdit, setAdministrativeOrganismToDetail, clearAdministrativeOrganismList} from 'src/redux/AdministrativeOrganism/administrativeOrganismActions'
import { getAdministrativeOrganismListData, getAdministrativeOrganismListIsFetching } from 'src/redux/AdministrativeOrganism/administrativeOrganismReducer';

const AdministrativeOrganismListPage = () => {

    const dispatch = useDispatch();

    // Permissions
	const organismsPermissions = useSelector( state => getUserPermissionsOrganisms(state) );

    // Organism
    const organismList = useSelector(state => getAdministrativeOrganismListData (state)?.records);
	const organismListIsFetching = useSelector(state => getAdministrativeOrganismListIsFetching(state));
    const hasOrganismList = isNotEmptyArray(organismList);

    const organismListPaginate = useSelector(state => getAdministrativeOrganismListData (state));

	const organismListMetadata = organismListPaginate?.metadata;
	const totalListRecords = organismListMetadata?.total;
    const hasMoreorganism = ( organismListMetadata?.total > ( (organismListMetadata?.page+1) * organismListMetadata?.pageSize ) );
    
    const loadAllData = () => {
        dispatch( clearAdministrativeOrganismList() );
        const params = {
			page: organismListMetadata?.page,
			size: 10
		};
		dispatch( tryListAdministrativeOrganism(params) );
	}

	useEffect(loadAllData, []);
    
    const onClickLoadMore = () => {
		const params = {
			page: organismListMetadata?.page,
			size: totalListRecords
		};
		dispatch( tryListAdministrativeOrganism(params) );
    };

    const onClickLoadLess = () => {
		const params = {
			page: organismListMetadata?.page,
			size: 10
		};
		dispatch( tryListAdministrativeOrganism(params) );
	};
    
    const onClickDetail = organism => {
		dispatch(setAdministrativeOrganismToDetail(organism));
		dispatch(push(ADMINISTRATIVE_ORGANISM_DETAILS));
	};

    const onClickEdit = organism => {
		dispatch(setAdministrativeOrganismToEdit(organism));
		dispatch(push(ADMINISTRATIVE_ORGANISM_EDIT));
	};

    const onClickNew = () => {
        dispatch(push(ADMINISTRATIVE_ORGANISM_NEW));
    };

    const onClickDelete = organism => {
        let nameAdministrativeOrganism = organism.name;
		swal({
			title: 'Confirmación',
			text: '¿' +  LABELS.questionDeleteConfirm  + nameAdministrativeOrganism  + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		})
			.then((willDelete) => {
				if (willDelete) {
                    dispatch(tryDeleteAdministrativeOrganism(organism?.id)).then(
                        response => {
                            if(response?.status == 200){

                                dispatch( clearAdministrativeOrganismList() );
                                loadAllData();
                            }
                        }

                    );
				} else {
					/*   swal('El usuario esta a salvo!'); */
				}
			});
    };
  
    return <Container fluid>
        <Card>

            <PageTitle text='Organismos' />

            <Container fluid>
                <Card className='mb-3'>
                    <Card.Header className='h6'>
                        Gestión de organismos
                </Card.Header>
                    <Card.Body>
                    {
						organismsPermissions?.canCreate
						?
                        <Button size='sm' className='mb-3' variant='success' onClick={onClickNew}>
                            <FontAwesomeIcon icon={faPlus} className='mr-1' />
                            Nuevo organismo
                        </Button>
                        :
						null
					}

                        <Table striped bordered hover size='sm' >
                            <thead>
                                <tr>
                                    <th className='text-center align-middle'>{LABELS.budgetCode}</th>
                                    <th className='text-center align-middle'>{LABELS.administrativeCode}</th>
                                    <th className='text-center align-middle'>{LABELS.organismClassifier}</th>
                                    <th className='text-center align-middle'>{LABELS.name}</th>
                                    <th className='text-center align-middle'>{LABELS.actions}</th>
                                </tr>

                            </thead>
                            <tbody className='text-black-color'>
                                {
                                    hasOrganismList
                                        ?
                                        <>
                                            {
                                                organismList?.map(item => (
                                                    <tr key={item?.id}>
                                                        
                                                        <td className='text-center align-middle'>
                                                        {item?.jurisdiction?.code} - {item?.codeBudget}
                                                        </td>
                                                        <td className='text-center align-middle'>
                                                            {item?.codeAdministrative}
                                                        </td>
                                                        <td className='text-center align-middle'>
                                                            {item?.organismClassifier?.name}
                                                        </td>
                                                        <td className='text-center align-middle'>
                                                            { `${item?.name} (${item?.jurisdiction?.name}) (${item?.organismClassifier?.shortName})` }
                                                        </td>

                                                        <td className='text-center align-middle p-0 m-0 table-responsive btn-group' >
                                                        {
                                                            organismsPermissions?.canView
                                                            ?
                                                            <ActionIcon size='lg' id='credit-query' toolTipText='Detalle del Organismo' icon={faSearch} onClick={() => onClickDetail(item)} />
                                                            :
                                                            null
                                                        }
                                                        {
                                                            organismsPermissions?.canUpdate
                                                            ?
                                                            <ActionIcon size='lg' id='credit-query' toolTipText='Editar Organismo' icon={faEdit} onClick={() => onClickEdit(item)} />
                                                            :
                                                            null
                                                        }
                                                        {
                                                            organismsPermissions?.canDelete
                                                            ?
                                                            <ActionIcon size='lg' id='delete' toolTipText='Borrar Organismo' icon={faTrash} onClick={ () => onClickDelete(item)} />
                                                            :
                                                            null
                                                        }

                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </>
                                        :
                                        <tr>
                                            <td colSpan='13' className='text-center'>
                                                {
                                                    organismListIsFetching
                                                        ?
                                                        <Spinner animation='border' variant='success' className='my-3' />
                                                        :
                                                        <Alert variant='info' className='mb-0'>
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                            {LABELS.noRecords}
                                                        </Alert>
                                                }
                                            </td>
                                        </tr>
                                }
                            </tbody>

                        </Table>

                        {
							hasMoreorganism ?
							<Row>
								<Col className='text-center'>
											<Button size='sm' className='px-5' disable={organismListIsFetching} onClick={onClickLoadMore}>
												{
													organismListIsFetching ?
														<Spinner animation='border' size='sm'/>
														:
														LABELS.seeMore
												}
											</Button>
								</Col>
							</Row>
								:
                            <Row>
								<Col className='text-center'>
											<Button size='sm' variant="danger" className='px-5' disable={organismListIsFetching} onClick={onClickLoadLess}>
												{
													organismListIsFetching ?
														<Spinner animation='border' size='sm'/>
														:
														LABELS.seeLess
												}
											</Button>
								</Col>
							</Row>
						}

                    </Card.Body>
                </Card>
            </Container>
        </Card>
    </Container>;

}
export default AdministrativeOrganismListPage; 