import React from 'react';
import CertificadoServiciosTable from 'src/components/pages/OrderPay/tables/CertificadoServiciosTable';
import ReceptionActTable from 'src/components/pages/OrderPay/tables/ReceptionActTable';
import { formatterPeso } from 'src/utils/utils';
import { isNotEmptyArray } from 'src/services/validationService.js';
import WorkCertificateTable from './tables/WorkCertificateTable';

const TableReceiptsGenerator = (props) => {

	const totalPenaltyFee = props?.receipts?.length < 2 ? props?.receipts?.[0]?.penaltyFee : props?.receipts?.map((item) => item?.penaltyFee)?.flat()?.reduce((prev, next) => Number(prev) + Number(next));
	const totalAmountReceipts = isNotEmptyArray(props?.receipts) &&
		formatterPeso.format(props?.receipts?.map((item) => item?.tickets?.map((v) => v.amount))
			.flat()?.reduce((prev, next) => Number(prev) + Number(next)) - totalPenaltyFee);

	const renderSwitch = (tipoRecibo) => {
		switch (tipoRecibo){
			case 'ACTA_DE_RECEPCIÓN':
				return <ReceptionActTable {...props} />

			case 'CERTIFICADO_DE_SERVICIOS':
				return <CertificadoServiciosTable {...props} />

			case 'CERTIFICADO_DE_OBRAS_PUBLICAS':
				return <WorkCertificateTable {...props} />

			default:
				return <></>
		}
	};

    return <>
		{ renderSwitch(props.tipo) }
		<h6 className="text-right">Importe Total de Recibos: {totalAmountReceipts}</h6>
	</>
};

export default TableReceiptsGenerator;