import { useSelector } from 'react-redux';
import { userIdDataByEmail } from 'src/utils/usersProfiles';
import { 
    getProfileName,
    getUserPermissionsSecurity,
    getUserPermissionsControlBudget,
    getUserPermissionsReports,
    getUserPermissionsExecutionBudget,
    getUserPermissionsPeriods,
    getUserPermissionsServices,
    getUserPermissionsBudgetItemControl,
    getUserPermissionsOrganisms,
    getUserPermissionsFundRequests,
    getUserPermissionsPaymentOrders,
    getUserPermissionsDeliveryOrders,
    getUserPermissionsAudit,
    getUserPermissionsBankAccounts,
    getUserPermissionsRegions
} from 'src/redux/login/loginReducer';

export const usePermissions = () => {
    // Perfil del Usuario
    const profileName = useSelector(state => getProfileName(state));
    
    // Permisos por modulos - Usar useSelector para todos los permisos necesarios
    const securityPermissions = useSelector(state => getUserPermissionsSecurity(state));
    const controlBudgetPermissions = useSelector(state => getUserPermissionsControlBudget(state));
    const reportsPermissions = useSelector(state => getUserPermissionsReports(state));
    const executionBudgetPermissions = useSelector(state => getUserPermissionsExecutionBudget(state));
    const periodsPermissions = useSelector(state => getUserPermissionsPeriods(state));
    const servicesPermissions = useSelector(state => getUserPermissionsServices(state));
    const budgetItemControlPermissions = useSelector(state => getUserPermissionsBudgetItemControl(state));
    const organismsPermissions = useSelector(state => getUserPermissionsOrganisms(state));
    const fundRequestsPermissions = useSelector(state => getUserPermissionsFundRequests(state));
    const paymentOrdersPermissions = useSelector(state => getUserPermissionsPaymentOrders(state));
    const deliveryOrdersPermissions = useSelector(state => getUserPermissionsDeliveryOrders(state));
    const auditsPermissions = useSelector(state => getUserPermissionsAudit(state));
    const bankAccountsPermissions = useSelector(state => getUserPermissionsBankAccounts(state));
    const regionsPermissions = useSelector(state => getUserPermissionsRegions(state));

    const paramsToAlerts= {
		profile: profileName,
		userId: userIdDataByEmail()?.id
	};

    return {
        // Retornar todos los permisos
        profileName,
        securityPermissions,
        controlBudgetPermissions,
        reportsPermissions,
        executionBudgetPermissions,
        periodsPermissions,
        servicesPermissions,
        budgetItemControlPermissions,
        organismsPermissions,
        fundRequestsPermissions,
        paymentOrdersPermissions,
        deliveryOrdersPermissions,
        auditsPermissions,
        bankAccountsPermissions,
        regionsPermissions,
        paramsToAlerts
    };
};