import * as ACTION_TYPES from './partialBudgetActionTypes';

// Partial budget list by principal budget id actions
export const getPartialBudgetListByPrincipalBudgetIdRequest = principalBudgetId => ({
	type: ACTION_TYPES.LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST,
	payload: {
		principalBudgetId
	}
});

export const getPartialBudgetListByPrincipalBudgetIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getPartialBudgetListByPrincipalBudgetIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearPartialBudgetListByPrincipalBudgetId = () => ({
	type: ACTION_TYPES.CLEAR_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_LIST
});
// END Partial budget list by principal budget id actions



// Subpartial budget list actions
export const getSubPartialBudgetListRequest = () => ({
	type: ACTION_TYPES.LIST_SUB_PARTIAL_BUDGET_REQUEST
});
export const getSubPartialBudgetListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_SUB_PARTIAL_BUDGET_REQUEST_SUCCESS,
	payload: data
});
export const getSubPartialBudgetListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SUB_PARTIAL_BUDGET_REQUEST_FAILURE,
	payload: error
});
export const clearSubPartialBudgetList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_SUB_PARTIAL_BUDGET
});
// END Subpartial budget list actions



//
export const getSubpartialBudgetOfSelectedCreditsRequest = () => ({
	type: ACTION_TYPES.GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS
});
export const getSubpartialBudgetOfSelectedCreditsRequestSuccess = () => ({
	type: ACTION_TYPES.GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_SUCCESS
});
export const getSubpartialBudgetOfSelectedCreditsRequestFailure = () => ({
	type: ACTION_TYPES.GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_FAILURE
});
//