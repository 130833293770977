export const LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST = 'LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST';
export const LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_SUCCESS = 'LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_SUCCESS';
export const LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_FAILURE = 'LIST_ORGANIZATIONS_BY_JURISDICTION_ID_REQUEST_FAILURE';
export const CLEAR_ORGANIZATIONS_BY_JURISDICTION_ID_LIST = 'CLEAR_ORGANIZATIONS_BY_JURISDICTION_ID_LIST';

export const LIST_ORGANIZATION_TYPES_REQUEST = 'LIST_ORGANIZATION_TYPES_REQUEST';
export const LIST_ORGANIZATION_TYPES_REQUEST_SUCCESS = 'LIST_ORGANIZATION_TYPES_REQUEST_SUCCESS';
export const LIST_ORGANIZATION_TYPES_REQUEST_FAILURE = 'LIST_ORGANIZATION_TYPES_REQUEST_FAILURE';
export const CLEAR_LIST_ORGANIZATION_TYPES = 'CLEAR_LIST_ORGANIZATION_TYPES';

//Organizations
export const LIST_ORGANIZATION_REQUEST = 'LIST_ORGANIZATION_REQUEST';
export const LIST_ORGANIZATION_REQUEST_SUCCESS = 'LIST_ORGANIZATION_REQUEST_SUCCESS';
export const LIST_ORGANIZATION_REQUEST_FAILURE = 'LIST_ORGANIZATION_REQUEST_FAILURE';
export const CLEAR_LIST_ORGANIZATION = 'CLEAR_LIST_ORGANIZATION';

// Organisms By Jurisdictions, Services and Exercise
export const ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST = 'ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST';
export const ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS = 'ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS';
export const ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE = 'ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE';
export const CLEAR_ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID = 'CLEAR_ORGANISMS_BY_JURISDICTIONSID_BY_SERVICESID_AND_EXERCISEID';
