import * as ACTION_TYPES from './bankAccountsFundRequestActionTypes';

// Get budget item control request actions
export const getListBankAccountsFundRequestRequest = params => ({
    type: ACTION_TYPES.GET_LIST_BANK_ACCOUNT_FUND_REQUEST_REQUEST,
    payload: {
        params
    }
});
export const getListBankAccountsFundRequestSuccess = data => ({
    type: ACTION_TYPES.GET_LIST_BANK_ACCOUNT_FUND_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const getListBankAccountsFundRequestFailure = error => ({
    type: ACTION_TYPES.GET_LIST_BANK_ACCOUNT_FUND_REQUEST_FAILURE,
    payload: {
        error
    }
});

export const clearBankAccountFundRequestListData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_BANK_ACCOUNT_FUND_REQUEST_DATA
})
// END Get budget item control request actions

// Add bank account actions
export const addBankAccountsRequest = params => ({
    type: ACTION_TYPES.ADD_NEW_BANK_ACCOUNT_REQUEST_REQUEST,
    payload: {
        params
    }
});
export const addBankAccountsRequestSuccess = data => ({
    type: ACTION_TYPES.ADD_NEW_BANK_ACCOUNT_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const addBankAccountsRequestFailure = error => ({
    type: ACTION_TYPES.ADD_NEW_BANK_ACCOUNT_REQUEST_FAILURE,
    payload: {
        error
    }
});

// END Add bank account actions

//delete bank account
export const getBankAccountDeleteRequest = () => ({
    type: ACTION_TYPES.BANK_ACCOUNT_DELETE_REQUEST
});

export const getBankAccountDeleteRequestSuccess = data => ({
    type: ACTION_TYPES.BANK_ACCOUNT_DELETE_REQUEST_SUCCESS,
    payload: {
        data
    }
});

export const getBankAccountDeleteRequestFailure = error => ({
    type: ACTION_TYPES.BANK_ACCOUNT_DELETE_REQUEST_FAILURE,
    payload: {
        error
    }
});
export const clearBankAccountListData = () => ({
    type: ACTION_TYPES.CLEAR_BANK_DELETE_LIST_DATA
})


// Bank Account Detail by id
export const getBankAccountDetailRequestbyid = () => ({
	type: ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUESTBYID
});

export const getBankAccountDetailRequestbyidSuccess = data => ({
	type: ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUESTBYID_SUCCESS,
	payload: {
		data
	}
});

export const getBankAccountDetailRequestbyidFailure = error => ({
	type: ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUESTBYID_FAILURE,
	payload: {
		error
	}
});
export const setBankAccountToDetailsbyid = data => ({
	type: ACTION_TYPES.SET_DETAIL_BANK_ACCOUNTBYID,
	payload: {
		data
	}
});

// Bank Account Detail
export const getBankAccountDetailRequest = () => ({
	type: ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUEST
});

export const getBankAccountDetailRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getBankAccountDetailRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setBankAccountToDetails = data => ({
	type: ACTION_TYPES.SET_DETAIL_BANK_ACCOUNT,
	payload: {
		data
	}
});

//bank account Edit
export const editBankAccountRequest = () => ({
	type: ACTION_TYPES.EDIT_BANK_ACCOUNT_REQUEST
});

export const editBankAccountRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_BANK_ACCOUNT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editBankAccountRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_BANK_ACCOUNT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setBankAccountToEdit = data => ({
	type: ACTION_TYPES.SET_EDIT_BANK_ACCOUNT,
	payload: {
		data
	}
});


