import * as ACTION_TYPES from './exerciseActionTypes';

// List exercises request actions
export const listExercisesRequest = () => ({
	type: ACTION_TYPES.LIST_EXERCISES_REQUEST
});

export const listExercisesRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_EXERCISES_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listExercisesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_EXERCISES_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListExercisesData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_EXERCISES_DATA
}); 
// END List exercises request actions



// Create exercise request actions
export const createExerciseRequest = () => ({
	type: ACTION_TYPES.CREATE_EXERCISE_REQUEST
});

export const createExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.CREATE_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const createExerciseRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_CREATE_EXERCISE_DATA
}); 
// END Create exercise request actions



// Edit exercise request actions
export const setSelectedLegalInstrument = data => ({
	type: ACTION_TYPES.SET_SELECTED_LEGAL_INSTRUMENT,
	payload: {
		data
	}
});
export const setExerciseToEditData = data => ({
	type: ACTION_TYPES.SET_EXERCISE_TO_EDIT_DATA,
	payload: {
		data
	}
});
export const editExerciseRequest = () => ({
	type: ACTION_TYPES.EDIT_EXERCISE_REQUEST
});
export const editExerciseRequestSuccess = () => ({
	type: ACTION_TYPES.EDIT_EXERCISE_REQUEST_SUCCESS
});
export const editExerciseRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearEditExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_EXERCISE_DATA
})
// END Edit exercise request actions



// Set exercise details data action
export const setExerciseDetailsData = data => ({
	type: ACTION_TYPES.SET_EXERCISE_DETAILS_DATA,
	payload: {
		data
	}
});
// END Set exercise details data action



// Get services by exercise ID
export const getServicesByExerciseIdRequest = exerciseId => ({
	type: ACTION_TYPES.GET_SERVICES_BY_EXERCISE_ID_REQUEST,
	payload: {
		exerciseId
	}
});
export const getServicesByExerciseIdRequestSuccess = data => ({
	type: ACTION_TYPES.GET_SERVICES_BY_EXERCISE_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getServicesByExerciseIdRequestFailure = error => ({
	type: ACTION_TYPES.GET_SERVICES_BY_EXERCISE_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearServicesByExerciseIdData = () => ({
	type: ACTION_TYPES.CLEAR_SERVICES_BY_EXERCISE_ID_DATA
});
// END Get services by exercise ID



// Services management actions
export const setServicesManagementSelectedService = data => ({
	type: ACTION_TYPES.SET_SERVICE_MANAGEMENT_SELECTED_EXERCISE,
	payload: {
		data
	}
});
// END Services management actions



// Add service to exercise actions
export const addServiceToExerciseRequest = () => ({
	type: ACTION_TYPES.ADD_SERVICE_TO_EXERCISE_REQUEST
});
export const addServiceToExerciseRequestSuccess = () => ({
	type: ACTION_TYPES.ADD_SERVICE_TO_EXERCISE_REQUEST_SUCCESS
});
export const addServiceToExerciseRequestFailure = error => ({
	type: ACTION_TYPES.ADD_SERVICE_TO_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearAddServiceToExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_ADD_SERVICE_TO_EXERCISE_DATA
});
// END Add service to exercise actions



// Delete service from exercise actions
export const deleteServiceFromExerciseRequest = () => ({
	type: ACTION_TYPES.DELETE_SERVICE_FROM_EXERCISE_REQUEST
});
export const deleteServiceFromExerciseRequestSuccess = () => ({
	type: ACTION_TYPES.DELETE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS
});
export const deleteServiceFromExerciseRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearDeleteServiceToExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_DELETE_SERVICE_FROM_EXERCISE_DATA
});
// END Delete service from exercise actions

// Active service from exercise actions
export const activeServiceFromExerciseRequest = () => ({
	type: ACTION_TYPES.ACTIVE_SERVICE_FROM_EXERCISE_REQUEST
});
export const activeServiceFromExerciseRequestSuccess = () => ({
	type: ACTION_TYPES.ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS
});
export const activeServiceFromExerciseRequestFailure = error => ({
	type: ACTION_TYPES.ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearActiveServiceToExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_ACTIVE_SERVICE_FROM_EXERCISE_DATA
});
// END Active service from exercise actions

// Closed exercise request actions
export const closedExerciseRequest = () => ({
	type: ACTION_TYPES.CLOSED_EXERCISE_REQUEST
});

export const closedExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.CLOSED_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const closedExerciseRequestFailure = error => ({
	type: ACTION_TYPES.CLOSED_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Closed exercise actions


// List legal instrument exercises request actions
export const listLegalInstrumentExerciseRequest = () => ({
	type: ACTION_TYPES.LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST
});

export const listLegalInstrumentExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listLegalInstrumentExercisRequestFailure = error => ({
	type: ACTION_TYPES.LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearLegalInstrumentExercisData = () => ({
	type: ACTION_TYPES.CLEAR_LEGAL_INSTRUMENT_EXERCISE_DATA
}); 
// END List exercises request actions


// Edit Legal Instrument Exercise types actions
export const editLegalInstrumentExerciseRequest = () => ({
	type: ACTION_TYPES.EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST
});

export const editLegalInstrumentExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editLegalInstrumentExerciseRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const cleareditLegalInstrumentExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_LEGAL_INSTRUMENT_EXERCISE_DATA
}); 
// END Edit Legal Instrument Exercise types actions

// Enable exercise request actions
export const enableExerciseRequest = () => ({
	type: ACTION_TYPES.ENABLE_EXERCISE_REQUEST
});

export const enableExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.ENABLE_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const enableExerciseRequestFailure = error => ({
	type: ACTION_TYPES.ENABLE_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Enable exercise actions

//List exercise by ID action types
export const listExercisesByIdRequest = () => ({
	type: ACTION_TYPES.LIST_EXERCISES_BY_ID_REQUEST
});

export const listExercisesByIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_EXERCISES_BY_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listExercisesByIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_EXERCISES_BY_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListExercisesByIdData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_EXERCISES_BY_ID_DATA
}); 
// END List exercises by ID request actions

//List Periods Monthly action types
export const listPeriodsMonthlyRequest = () => ({
	type: ACTION_TYPES.LIST_PERIODS_MONTHLY_REQUEST
});

export const listPeriodsMonthlyRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PERIODS_MONTHLY_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listPeriodsMonthlyRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PERIODS_MONTHLY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListPeriodsMonthlyData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_PERIODS_MONTHLY_DATA
}); 
// END List Periods Monthly request actions

// Report enabled date monthly period - Report Credit Execution  actions
export const getReportEnableDateMonthlyPeriodCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST
});
export const getReportEnableDateMonthlyPeriodCreditExecutionRequestSuccess = data => ({
	type: ACTION_TYPES.REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getReportEnableDateMonthlyPeriodCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportEnableDateMonthlyPeriodCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION
});
// END enabled date monthly period - Report Credit Execution  actions

// Get Active administrative service from exercise
export const getActiveAdminsitrativeServiceFromExerciseRequest = () => ({
	type: ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST
});
export const getActiveAdminsitrativeServiceFromExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getActiveAdminsitrativeServiceFromExerciseRequestFailure = error => ({
	type: ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearGetActiveAdminsitrativeServiceFromExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_DATA
});
// END Get Active administrative service from exercise