export const LIST_PERIODS_REQUEST = 'LIST_PERIODS_REQUEST';
export const LIST_PERIODS_REQUEST_SUCCESS = 'LIST_PERIODS_REQUEST_SUCCESS';
export const LIST_PERIODS_REQUEST_FAILURE = 'LIST_PERIODS_REQUEST_FAILURE';

export const CLEAR_PERIODS_DATA = 'CLEAR_PERIODS_DATA';
export const SET_SELECTED_PERIOD = 'SET_SELECTED_PERIOD';

export const SET_REPORT_DATES = 'SET_REPORT_DATES';

export const GET_FILTER_OBJECT = 'GET_FILTER_OBJECT';
export const SET_FILTER_OBJECT = 'SET_FILTER_OBJECT';
export const CLEAR_FILTER_OBJECT = 'CLEAR_FILTER_OBJECT';

export const GET_SELECTED_CREDITS = 'GET_SELECTED_CREDITS';
export const SET_SELECTED_CREDITS = 'SET_SELECTED_CREDITS';
export const CLEAR_SELECTED_CREDITS = 'CLEAR_SELECTED_CREDITS';

export const GET_BANK_ACCOUNT_FILTERS = 'GET_BANK_ACCOUNT_FILTERS';
export const SET_BANK_ACCOUNT_FILTERS = 'SET_BANK_ACCOUNT_FILTERS';
export const CLEAR_BANK_ACCOUNT_FILTERS = 'CLEAR_BANK_ACCOUNT_FILTERS';