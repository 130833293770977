import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { tryListExercises } from 'src/redux/exercise/exerciseActionCreator';
import MenuSecurity from './MenuSecurity';
import MenuBudgetExecution from './MenuBudgetExecution';
import MenuManuals from './MenuManuals';
import MenuFinancialExecution from './MenuFinancialExecution';
import MenuSupplyDepartment from './MenuSupplyDepartment';
import MenuAudit from './MenuAudit';
import MenuNotification from './MenuNotification';
import MenuConfig from './MenuConfig';
import MenuReport from './MenuReport';

const Menu = props => {
	const { user } = props;
	const dispatch = useDispatch();
	
	useEffect(() => {
		dispatch(tryListExercises());
	}, []);

	return (
		<Nav className="me-auto d-flex flex-wrap">
			<MenuSecurity/>
			<MenuBudgetExecution/>
			<MenuFinancialExecution/>
			<MenuSupplyDepartment/>
			<MenuReport/>
			<MenuAudit/>
			<MenuConfig/>
			<MenuNotification/>
			<MenuManuals/>
		</Nav>
	);
};

export default Menu;