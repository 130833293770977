import React from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { auditDetail, previousValue, currentValue } from 'src/utils/label';

const UserAuditDetailModal = (props) => {

    const {show, handleClose, dataModal } = props;

    const getDetailFields = data => {

        if(data){

            const parsedData = JSON.parse(data);

            return Object.keys(parsedData)?.map(key => 
                <div style={{ "display": "flex", "justify-content": "left" }}><div>{key}: {parseValue(parsedData[key]) || '-'}</div></div>
            );
        } else {
            return <div className='text-center align-middle'>-</div>
        }
        
    };
    
    const parseValue = value => {

        let res;

        switch (typeof value){
            case 'boolean':
                res = value? 'true': 'false'
                break;
            default:
                res = value;
        }        

        return res;
    };

    return <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
        
        <Modal.Header closeButton>
			<Modal.Title>{auditDetail}</Modal.Title>
		</Modal.Header>

        <Modal.Body>
            <Table striped bordered hover>
				<thead>
					<tr>
						<th className='text-center' style={{"width":"50%"}}>{previousValue.toUpperCase()}</th>
						<th className='text-center' style={{"width":"50%"}}>{currentValue.toUpperCase()}</th>
					</tr>
				</thead>
                <tbody className='text-black-color'>
                    <tr>
                        <th><div>{getDetailFields(dataModal?.oldDataModified)}</div></th>
                        <th><div>{getDetailFields(dataModal?.newDataModified)}</div></th>
                    </tr>
                </tbody>
            </Table>
        </Modal.Body>

        <Modal.Footer>
			<Button variant="secondary" onClick={handleClose}>
				Cerrar
            </Button>
		</Modal.Footer>
        
    </Modal>
}

export default UserAuditDetailModal;