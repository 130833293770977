import * as ACTION_TYPES from './reportsActionTypes';

export const getReportConsolidated = () => ({
	type: ACTION_TYPES.REPORT_CONSOLIDATED_REQUEST
});
export const getReportConsolidatedSuccess = () => ({
	type: ACTION_TYPES.REPORT_CONSOLIDATED_REQUEST_SUCCESS
});
export const getReportConsolidatedFailure = error => ({
	type: ACTION_TYPES.REPORT_CONSOLIDATED_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportConsolidatedData = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_CONSOLIDATED_DATA
});

//Report Jurisdiction Analytic
export const getReportJurisdictionAnalytic = () => ({
	type: ACTION_TYPES.REPORT_JURISDICTION_ANALYTIC_REQUEST
});
export const getReportJurisdictionAnalyticSuccess = () => ({
	type: ACTION_TYPES.REPORT_JURISDICTION_ANALYTIC_REQUEST_SUCCESS
});
export const getReportJurisdictionAnalyticFailure = error => ({
	type: ACTION_TYPES.REPORT_JURISDICTION_ANALYTIC_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportJurisdictionAnalyticData = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_JURISDICTION_ANALYTIC_DATA
});

//Report Organism Analytic
export const getReportOrganismAnalytic = () => ({
	type: ACTION_TYPES.REPORT_ORGANISM_ANALYTIC_REQUEST
});
export const getReportOrganismAnalyticSuccess = () => ({
	type: ACTION_TYPES.REPORT_ORGANISM_ANALYTIC_REQUEST_SUCCESS
});
export const getReportOrganismAnalyticFailure = error => ({
	type: ACTION_TYPES.REPORT_ORGANISM_ANALYTIC_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportOrganismAnalyticData = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_ORGANISM_ANALYTIC_DATA
});

//Report Jurisdiction Total
export const getReportJurisdictionTotal = () => ({
	type: ACTION_TYPES.REPORT_JURISDICTION_TOTAL_REQUEST
});
export const getReportJurisdictionTotalSuccess = () => ({
	type: ACTION_TYPES.REPORT_JURISDICTION_TOTAL_REQUEST_SUCCESS
});
export const getReportJurisdictionTotalFailure = error => ({
	type: ACTION_TYPES.REPORT_JURISDICTION_TOTAL_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportJurisdictionTotalData = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_JURISDICTION_TOTAL_DATA
});



// Report Organization Total
export const getReportOrganizationTotal = () => ({
	type: ACTION_TYPES.REPORT_ORGANIZATION_TOTAL_REQUEST
});
export const getReportOrganizationTotalSuccess = () => ({
	type: ACTION_TYPES.REPORT_ORGANIZATION_TOTAL_REQUEST_SUCCESS
});
export const getReportOrganizationTotalFailure = error => ({
	type: ACTION_TYPES.REPORT_ORGANIZATION_TOTAL_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportOrganizationTotalData = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_ORGANIZATION_TOTAL_DATA
});



// Report crossed actions
export const getReportCrossedRequest = () => ({
	type: ACTION_TYPES.REPORT_CROSSED_REQUEST
});
export const getReportCrossedRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_CROSSED_REQUEST_SUCCESS
});
export const getReportCrossedRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_CROSSED_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportCrossed = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_CROSSED_DATA
});


// Report Instrument Legal Modify
export const getReportInstrumentLegalModifyRequest = () => ({
	type: ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST
});
export const getReportInstrumentLegalModifyRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_SUCCESS
});
export const getReportInstrumentLegalModifyRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Report crossed actions

// Report Instrument Legal Modify Direct Download 
export const getReportInstrumentLegalModifyDirectDownloadRequest = () => ({
	type: ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST
});
export const getReportInstrumentLegalModifyDirectDownloadRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_SUCCESS
});
export const getReportInstrumentLegalModifyDirectDownloadRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END 

// Report affectations by administrative document
export const getReportAffectationsByAdministrativeDocumentRequest = () => ({
	type: ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST
});
export const getReportAffectationsByAdministrativeDocumentRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS
});
export const getReportAffectationsByAdministrativeDocumentRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportAffectationsByAdministrativeDocument = () => ({
	type: ACTION_TYPES.CLEAR_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT
});
// END Report affectations by administrative document

// Report affectations by administrative document direct download
export const getReportAffectationsByAdministrativeDocumentDownloadRequest = () => ({
	type: ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST
});
export const getReportAffectationsByAdministrativeDocumentDownloadRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_SUCCESS
});
export const getReportAffectationsByAdministrativeDocumentDownloadRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Report affectations by administrative document direct download

// Report movement actions
export const getReportMovementRequest = () => ({
	type: ACTION_TYPES.REPORT_MOVEMENT_REQUEST
});
export const getReportMovementRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_MOVEMENT_REQUEST_SUCCESS
});
export const getReportMovementRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_MOVEMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportInstrumentLegalModify = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_INSTRUMENT_LEGAL_MODIFY_DATA
});
export const clearReportMovement = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_MOVEMENT
});
// END Report movement actions

// Report Credit Modify - Report Credit Execution  actions
export const getReportCreditModifyCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST
});
export const getReportCreditModifyCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportCreditModifyCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportCreditModifyCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_CREDIT_MODIFY_CREDIT_EXECUTION
});
// END Report Credit Modify - Report Credit Execution  actions

// Report Table Totals - Report Credit Execution  actions
export const getReportTableTotalsCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST
});
export const getReportTableTotalsCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportTableTotalsCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportTableTotalsCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_TABLE_TOTALS_CREDIT_EXECUTION
});
// END Report Table Totals - Report Credit Execution  actions

// Report Budget status - Report Credit Execution  actions
export const getReportBudgetStatusCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST
});
export const getReportBudgetStatusCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportBudgetStatusCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportBudgetStatusCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION
});
// END Report Budget status - Report Credit Execution  actions

// Report Works Budget Analytical Record - Report Credit Execution  actions
export const getReportWorksBudgetAnalyticalRecordCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST
});
export const getReportWorksBudgetAnalyticalRecordCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportWorksBudgetAnalyticalRecordCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportWorksBudgetAnalyticalRecordCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION
});
// END Report Works Budget Analytical Record - Report Credit Execution  actions

// Report  Movements Status - Report Credit Execution  actions
export const getReportMovementsStatusCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST
});
export const getReportMovementsStatusCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportMovementsStatusCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearGetReportMovementsStatusCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION
});
// END Report  Movements Status - Report Credit Execution  actions

// Report Delayed Files - Report Credit Execution  actions
export const getReportDelayedFilesCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST
});
export const getReportDelayedFilesCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportDelayedFilesCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportDelayedFilesCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_DELAYED_FILES_CREDIT_EXECUTION
});
// END Report Delayed Files - Report Credit Execution  actions

//  Report balance status payment list - Report Credit Execution actions
export const getReportBalanceStatusPaymentListCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST
});
export const getReportBalanceStatusPaymentListCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportBalanceStatusPaymentListCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportBalanceStatusPaymentListCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION
});
// END  Report balance status payment list - Report Credit Execution actions

// Report Budget Analytical Accounting - Report Credit Execution  actions
export const getReportBudgetAnalyticalAccountingCreditExecutionRequest = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST
});
export const getReportBudgetAnalyticalAccountingCreditExecutionRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_SUCCESS
});
export const getReportBudgetAnalyticalAccountingCreditExecutionRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportBudgetAnalyticalAccountingCreditExecution = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION
});
// END Report Budget Analytical Accounting - Report Credit Execution  actions

// Report Balance status  actions
export const getReportBalanceStatusRequest = () => ({
	type: ACTION_TYPES.REPORT_BALANCE_STATUS_REQUEST
});
export const getReportBalanceStatusSuccess = () => ({
	type: ACTION_TYPES.REPORT_BALANCE_STATUS_REQUEST_SUCCESS
});
export const getReportBalanceStatusFailure = error => ({
	type: ACTION_TYPES.REPORT_BALANCE_STATUS_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportBalanceStatus = () => ({
	type: ACTION_TYPES.CLEAR_BALANCE_STATUS
});
// END Report Budget Analytical Accounting - Report Credit Execution  actions

// Report Budget status generated - Report Credit Execution  actions
export const getReportBudgetStatusCreditExecutionGeneratedRequest = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST
});
export const getReportBudgetStatusCreditExecutionGeneratedRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_SUCCESS
});
export const getReportBudgetStatusCreditExecutionGeneratedRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportBudgetStatusCreditExecutionGenerated = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED
});
// END Report Budget status generated- Report Credit Execution  actions

//Listar reporte estado de ejecucion de presupuesto
export const getListReportBudgetGeneratedListRequest = () => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST
});
export const getListReportBudgetGeneratedListRequestSucces = (data) => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getListReportBudgetGeneratedListRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListReportBudgetGeneratedList = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_LIST_GENERATED
});
// END Listar reporte estado de ejecucion de presupuesto

// Report delivery orders - Report Request Fund
export const getReportDeliveryOrdersFundRequestRequest = () => ({
	type: ACTION_TYPES.REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST
});
export const getReportDeliveryOrdersFundRequestRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportDeliveryOrdersFundRequestRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportDeliveryOrdersFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_DELIVERY_ORDERS_FUND_REQUEST
});
// END Report delivery orders - Report Request Fund

// Report payment order administrative service fund request - Report Request Fund
export const getReportPaymentOrderAdministrativeServiceFundRequestRequest = () => ({
	type: ACTION_TYPES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST
});
export const getReportPaymentOrderAdministrativeServiceFundRequestRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportPaymentOrderAdministrativeServiceFundRequestRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportPaymentOrderAdministrativeServiceFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST
});
// END Report payment order administrative service fund request - Report Request Fund

// Report service direction fund request - Report Request Fund
export const getReportServiceDirectionFundRequest = () => ({
	type: ACTION_TYPES.REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST
});
export const getReportServiceDirectionFundRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportServiceDirectionFundRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportServiceDirectionFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST
});
// END Report service direction fund request - Report Request Fund

// Report detail service direction fund request - Report Request Fund
export const getReportDetailServiceDirectionFundRequest = () => ({
	type: ACTION_TYPES.REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST
});
export const getReportDetailServiceDirectionFundRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportDetailServiceDirectionFundRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Report detail service direction fund request - Report Request Fund

// Report fund request - Report Request Fund
export const getReportFundRequest = () => ({
	type: ACTION_TYPES.REPORT_FUND_REQUEST_REQUEST
});
export const getReportFundRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportFundRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_FUND_REQUEST_REQUEST
});
// END Report fund request - Report Request Fund

// Report provider fund request - Report Request Fund
export const getReportProviderFundRequest = () => ({
	type: ACTION_TYPES.REPORT_PROVIDER_FUND_REQUEST_REQUEST
});
export const getReportProviderFundRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_PROVIDER_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportProviderFundRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_PROVIDER_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportProviderFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_PROVIDER_FUND_REQUEST_REQUEST
});
// END Report provider fund request - Report Request Fund

// Report fund request bank account
export const getReportFundRequestBankAccountRequest = () => ({
	type: ACTION_TYPES.REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST
});
export const getReportFundRequestBankAccountSuccess = () => ({
	type: ACTION_TYPES.REPORT_FUND_REQUEST_BANK_ACCOUNT_SUCCESS
});
export const getReportFundRequestBankAccountFailure = error => ({
	type: ACTION_TYPES.REPORT_FUND_REQUEST_BANK_ACCOUNT_FAILURE,
	payload: {
		error
	}
});
export const clearReportFundRequestBankAccount = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST
});
// Report fund request bank account

// Report delete
export const getReportDeleteRequest = () => ({
	type: ACTION_TYPES.REPORT_DELETE_REQUEST
});
export const getReportDeleteSuccess = () => ({
	type: ACTION_TYPES.REPORT_DELETE_SUCCESS
});
export const getReportDeleteFailure = error => ({
	type: ACTION_TYPES.REPORT_DELETE_FAILURE,
	payload: {
		error
	}
});
export const clearReportDelete = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_DELETE_REQUEST
});
// Report delete

// Report new fund request - Report Request Fund
export const getReportNewFundRequest = () => ({
	type: ACTION_TYPES.REPORT_NEW_FUND_REQUEST_REQUEST
});
export const getReportNewFundRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_NEW_FUND_REQUEST_REQUEST_SUCCESS
});
export const getReportNewFundRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_NEW_FUND_REQUEST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearReportNewFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_NEW_FUND_REQUEST_REQUEST
});

export const startLoading = () => ({
	type: ACTION_TYPES.START_LOADING
});
export const stopLoading = () => ({
	type: ACTION_TYPES.STOP_LOADING
});
// END Report new fund request - Report Request Fund

// Connectivity CCPM status

export const connectivityCCPMStatusRequest = () => ({
	type: ACTION_TYPES.GET_CONNECTIVITY_STATUS_REQUEST
});

export const connectivityCCPMStatusRequestSuccess = responseData => ({
	type: ACTION_TYPES.GET_CONNECTIVITY_STATUS_REQUEST_SUCCESS,
	payload: {
		responseData
	}
});

export const connectivityCCPMStatusRequestFailure = error => ({
	type: ACTION_TYPES.GET_CONNECTIVITY_STATUS_REQUEST_FAILURE,
	payload: {
		error
	}
});

// END Connectivity CCPM status

// Report Determination Passive Residues actions
export const getReportDeterminationPassiveResiduesRequest = () => ({
	type: ACTION_TYPES.REPORT_DETERMINATION_PASSIVE_RESIDUES
});
export const getReportDeterminationPassiveResiduesRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_DETERMINATION_PASSIVE_RESIDUES_SUCCESS
});
export const getReportDeterminationPassiveResiduesRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_DETERMINATION_PASSIVE_RESIDUES_FAILURE,
	payload: {
		error
	}
});
export const clearReportDeterminationPassiveResidues = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_DETERMINATION_PASSIVE_RESIDUES
});
// END Report Determination Passive Residues

// Report Pending Resolution Passive Residues actions
export const getReportPendingResolutionPassiveResiduesRequest = () => ({
	type: ACTION_TYPES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES
});
export const getReportPendingResolutionPassiveResiduesRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_SUCCESS
});
export const getReportPendingResolutionPassiveResiduesRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_FAILURE,
	payload: {
		error
	}
});
export const clearReportPendingResolutionPassiveResidues = () => ({
	type: ACTION_TYPES.CLEAR_REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES
});
// END Pending Resolution Passive Residues

// Accrual Basis Report
export const getAccrualBasisReportRequest = () => ({
	type: ACTION_TYPES.GET_ACCRUAL_BASIS_REPORT_REQUEST
});
export const getAccrualBasisReportRequestSuccess = () => ({
	type: ACTION_TYPES.GET_ACCRUAL_BASIS_REPORT_REQUEST_SUCCESS
});
export const getAccrualBasisReportRequestFailure = error => ({
	type: ACTION_TYPES.GET_ACCRUAL_BASIS_REPORT_REQUEST_FAILURE,
	payload: {
		error
	}
});
// End accrual basis report

// Report Compensation Direct Download 
export const getReportCompensationDirectDownloadRequest = () => ({
	type: ACTION_TYPES.REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST
});
export const getReportCompensationDirectDownloadRequestSuccess = () => ({
	type: ACTION_TYPES.REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_SUCCESS
});
export const getReportCompensationDirectDownloadRequestFailure = error => ({
	type: ACTION_TYPES.REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Report Compensation

// Provision order report
export const getReportProvisionOrderRequest = () => ({
	type: ACTION_TYPES.GET_REPORT_PROVISION_ORDER_REQUEST
});
export const getReportProvisionOrderRequestSuccess = () => ({
	type: ACTION_TYPES.GET_REPORT_PROVISION_ORDER_REQUEST_SUCCESS
});
export const getReportProvisionOrderRequestFailure = error => ({
	type: ACTION_TYPES.GET_REPORT_PROVISION_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});