import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpPut } from './httpServices';

const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const ADMINISTRATIVE_DOCUMENT_URI = `${API_BASE_URI}/expenses/administrative-documents`;
const PRELOAD_ENDPOINT_URL = `${API_BASE_URI}/expenses/sub-codes-preload`;
const PRELOAD_REPORT_URL = `${API_BASE_URI}/expenses/reports`;

//Get Preload data
export const getPreloadData = async (accessToken, data) => {

	const options = buildDefaultOptions(accessToken);
	const jsonFilter = data?.filter ? JSON.stringify(data?.filter) : undefined;

	const setbuildURLQuery = {
		pageSize: data?.pageSize,
		page: data?.page,
		filter: jsonFilter
	}

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${ADMINISTRATIVE_DOCUMENT_URI}?${queryString}`;

	let response;
	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

//Delete preload
export const deletePreload = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${PRELOAD_ENDPOINT_URL}/${id}/delete`;

	let response;

	try {
		response = await httpPut(URL, null, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Edit preload
export const editPreload = async (accessToken, data) => {

	const subCodePreloadId = data?.preloadId;
	const URL = `${PRELOAD_ENDPOINT_URL}/${subCodePreloadId}/update-amount`;
	const options = buildDefaultOptions(accessToken);

	const params = {
		amount: data?.amount
	};

	let response;

	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

//Attach legal instrument to preload
export const attachLegalInstrumentToPreload = async (accessToken, params) => {
	const URL = `${PRELOAD_ENDPOINT_URL}/update-with-legal-instrument`;
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Get Preload XLS File
export const getFilePreloadServ = async (accessToken, data) => {

	const options = buildDefaultOptions(accessToken);

	const queryString = buildURLQuery(data);
	const URL = `${PRELOAD_REPORT_URL}/preloads-edition?${queryString}`;

	let response;
	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

//Get Preload report
export const getPreloadReport = async (accessToken, params) => {

	const options = buildDefaultOptions(accessToken);

	const queryString = buildURLQuery(params);
	const url = `${PRELOAD_REPORT_URL}/preload?${queryString}`;

	let response;
	try {
		response = await fetch(url, options);
		if (response?.status == 200) {
			response.blob().then(blob => {
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = 'precarga.pdf';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
				.catch(error => console.error('Error fetching PDF:', error));
		}
	}
	catch (error) {
		return error;
	}

	return response;
};

// GET Descarga PDF de precarga por expediente
export const getFilePreloadPDF = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	const queryString = buildURLQuery(params);
	const url = `${PRELOAD_REPORT_URL}/preload?${queryString}`;
	fetch(url, options)
		.then(resp => resp.blob())
		.then(blob => {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'precarga.pdf';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
		.catch(error => console.error('Error fetching PDF:', error));
};