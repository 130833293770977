import React from 'react';
import {useSelector} from 'react-redux';
import { Switch } from "react-router-dom";
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { PublicLayout } from './PublicLayout';
import { PrivateLayout } from './PrivateLayout';

import { getExpiresDate } from '../redux/login/loginReducer';
import { isTokenExpired } from '../utils/utils';
import { ROUTE_PATHS as PATHS } from '../utils/constants';

/**
 En caso de modificar la capa de routing o sus subcomponentes, se deben respetar los sig. casos de uso:
- login/logout
- primer login
- resetear contraseña del usuario desde el panel del admin(recibe email con link a reset-password/$hash)
- pedido de contraseña olvidada(forgot-password, luego recibe email con link reset-password/$hash)
- cambiar mi contraseña(edit-password, esto te desloguea y envia al login para ingresar la contraseña que se modificó)
- redirect a pagina de mantenimiento
- Si el token expiró, al ingrear a SAFI en una pestaña nueva, no debe mostrar error de token expirado de la API, sino que debe ir al login
- Si el usuario no esta logueado no deberia acceder a ninguna parte de SAFI por url, debe ir a login
 */
export const Routing = () => {
	// console.log('Routing');
	const expDate = useSelector(state => getExpiresDate(state));
	const routes = [
		PATHS.LOGIN_URI,
		PATHS.SET_PASSWORD_URI,	//seteo de nuevo password en firstLogin
		PATHS.FORGOT_PASSWORD_URI,//pantalla pedido recuperacion de password
		PATHS.RESET_PASSWORD_URI + '/:id',	//reseteo de password por admin o usuario mismo
	];
	return <Switch>
			<PublicRoute exact path={routes} isAuthenticated={!isTokenExpired(expDate)} component={PublicLayout} />
			<PrivateRoute  path="/" isAuthenticated={!isTokenExpired(expDate)} component={PrivateLayout} />
	</Switch>
}