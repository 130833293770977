import * as ACTION_TYPES from './captionActionTypes';

export const getCaptionListRequest = () => ({
	type: ACTION_TYPES.LIST_CAPTION_REQUEST
});

export const getCaptionListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_CAPTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getCaptionListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_CAPTION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCaptionList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_CAPTION
});

// Add caption actions
export const addCaptionRequest = params => ({
    type: ACTION_TYPES.NEW_CAPTION_REQUEST,
    payload: {
        params
    }
});
export const addCaptionRequestSuccess = data => ({
    type: ACTION_TYPES.NEW_CAPTION_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const addCaptionRequestFailure = error => ({
    type: ACTION_TYPES.NEW_CAPTION_REQUEST_FAILURE,
    payload: {
        error
    }
});

//Delete Caption
export const deleteCaptionRequest = () => ({
	type: ACTION_TYPES.DELETE_CAPTION_REQUEST
});

export const deleteCaptionRequestSuccess = data => ({
	type: ACTION_TYPES.DELETE_CAPTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const deleteCaptionRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_CAPTION_REQUEST_FAILURE,
	payload: {
		error
	}
});

// Detail Caption
export const getCaptionDetailRequest = () => ({
	type: ACTION_TYPES.DETAIL_CAPTION_REQUEST
});

export const getCaptionDetailRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_CAPTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getCaptionDetailRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_CAPTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setCaptionToDetails = data => ({
	type: ACTION_TYPES.SET_CAPTION,
	payload: {
		data
	}
});

//Edit Caption
export const editCaptionRequest = () => ({
	type: ACTION_TYPES.EDIT_CAPTION_REQUEST
});

export const editCaptionRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_CAPTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editCaptionRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_CAPTION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setCaptionToEdit = data => ({
	type: ACTION_TYPES.SET_CAPTION_TO_EDIT,
	payload: {
		data
	}
});
