import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpDelete } from './httpServices';

const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const API_BASE_URI_V2 = `${config.apis.presupuestoApi.URL_V2}`;
const REQUEST_FUND_API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const REPORTS_ENDPOINT_URI = '/expenses/reports/';
const REPORTS_ENDPOINT_REQUEST_FUND_URI = '/reports/';
const REPORT_CONSOLIDATED_PURPOSE_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}consolidated-purpose`;
const REPORT_CONSOLIDATED_ECONOMIC_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}consolidated-economic`;
const REPORT_IMPORT = `${API_BASE_URI}/expenses/import`;
const REPORT_REVERT = `${API_BASE_URI}/expenses/revert`;
const REPORT_PAYMENT_ORDER = `${REQUEST_FUND_API_BASE_URI}/reports/payment-order`;
const REPORT_PROVISION_ORDER = `${REQUEST_FUND_API_BASE_URI}/reports/provision-order`;


// Report consolidated by purpose
export const getReportConsolidatedByPurpose = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_CONSOLIDATED_PURPOSE_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


// Report consolidated by economic
export const getReportConsolidatedEconomic = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_CONSOLIDATED_ECONOMIC_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



//Jurisdiction Analytic
const REPORT_JURISDICTION_ANALYTIC_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}analytic-jurisdiction`;

export const getReportJurisdictionAnalytic = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_JURISDICTION_ANALYTIC_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



//Organism Analytic
const REPORT_ORGANISM_ANALYTIC_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}analytic-organization`;

export const getReportOrganismAnalytic = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_ORGANISM_ANALYTIC_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



//Jurisdiction Total
const REPORT_JURISDICTION_TOTAL_PURPOSE_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}jurisdiction-purpose`;
const REPORT_JURISDICTION_TOTAL_ECONOMIC_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}jurisdiction-economic`;
//Jurisdiction Total By Purpose
export const getReportJurisdictionTotalByPurpose = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_JURISDICTION_TOTAL_PURPOSE_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



//Jurisdiction Total By Economic
export const getReportJurisdictionTotalEconomic = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_JURISDICTION_TOTAL_ECONOMIC_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



// Report Organization Total by Purpose
const REPORT_ORGANIZATION_TOTAL_PURPOSE_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}organization-purpose`;
export const getReportOrganizationTotalByPurpose = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_ORGANIZATION_TOTAL_PURPOSE_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



// Report Organization Total by Economic
const REPORT_ORGANIZATION_TOTAL_ECONOMIC_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}organization-economic`;
export const getReportOrganizationTotalByEconomic = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_ORGANIZATION_TOTAL_ECONOMIC_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



// Report Crossed
const REPORT_CROSSED_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}crossed`;
export const getReportCrossed = async (accessToken, data) => {
	const queryString = buildURLQuery(data);
	const URL = `${API_BASE_URI}${REPORT_CROSSED_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


// Report Instrument Legal Modify
const REPORT_INSTRUMENT_LEGAL_MODIFY_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}legal-instrument`;
export const getReportInstrumentLegalModify = async (accessToken, data) => {
	const queryString = buildURLQuery(data);

	const URL = `${API_BASE_URI}${REPORT_INSTRUMENT_LEGAL_MODIFY_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Direct download Report Instrument Legal Modify
const REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}direct-download-legal-instrument`;
export const getReportInstrumentLegalModifyDirectDownload = async (accessToken, data) => {
	const queryString = buildURLQuery(data);

	const URL = `${API_BASE_URI}${REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report affectations by administrative document
const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_URI = `${REPORTS_ENDPOINT_URI}affectations-document-administrative-transaction`;
export const getReportAffectationsByAdministrativeDocument = async (accessToken, params) => {

	const paramsToSend = {
		affectation_transaction_id: params?.affectationTransactionId,
		outputFormat: params?.outputFormat,
		period_id: params?.periodId
	};

	const queryString = buildURLQuery(paramsToSend);
	const URL = `${API_BASE_URI}${REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report affectations by administrative document direct download
const REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DIRECT_DOWNLOAD_URI = `${REPORTS_ENDPOINT_URI}direct-download-affectations-document-administrative-transaction`;
export const getReportAffectationsByAdministrativeDocumentDownload = async (accessToken, params) => {

	const paramsToSend = {
		affectation_transaction_id: params?.affectationTransactionId,
		outputFormat: params?.outputFormat,
		period_id: params?.periodId
	};

	const queryString = buildURLQuery(paramsToSend);
	const URL = `${API_BASE_URI}${REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DIRECT_DOWNLOAD_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


// Report movement
const REPORT_MOVEMENT_URI = `${REPORTS_ENDPOINT_URI}movements`;
export const getReportMovement = async (accessToken, movementId, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_MOVEMENT_URI}/${movementId}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Download report by URL
export const getReportByURL = async (accessToken, reportUrl) => {
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(reportUrl, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Credit Modify - Report Credit Execution 
const REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}credit-modification-status`;
export const getReportCreditModifyCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Credit Modify - Report Credit Execution V2
export const getReportCreditModifyCreditExecutionV2 = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI_V2}${REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Table Totals - Report Credit Execution 
const REPORT_TABLE_TOTALS_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}totals`;
export const getReportTableTotalsCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_TABLE_TOTALS_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Budget status - Report Credit Execution 
const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}budget-execution-status`;
export const getReportBudgetStatusCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI_V2}${REPORT_BUDGET_STATUS_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Budget status - Report Credit Execution v2
const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_URI_V2 = `${REPORTS_ENDPOINT_URI}budget-execution-status-report`;
export const getReportBudgetStatusCreditExecutionV2 = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI_V2}${REPORT_BUDGET_STATUS_CREDIT_EXECUTION_URI_V2}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Works Budget Analytical Record - Report Credit Execution 
const REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}analytic-budget-accounting`;
export const getReportWorksBudgetAnalyticalRecordCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Movements Status - Report Credit Execution 
const REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}credit-movements-status`;
export const getReportMovementsStatusCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Delayed Files - Report Credit Execution 
const REPORT_DELAYED_FILES_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}pending-administrative-documents`;
export const getReportDelayedFilesCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_DELAYED_FILES_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report balance status payment list - Report Credit Execution 
const REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}payments-list`;
export const getReportBalanceStatusPaymentListCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report balance status payment list - Report Credit Execution V2
export const getReportBalanceStatusPaymentListCreditExecutionV2 = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI_V2}${REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Budget Analytical Accounting - Report Credit Execution 
const REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}analytic-budget-accounting`;
export const getReportBudgetAnalyticalAccountingCreditExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Budget Analytical Accounting - Report Credit Execution V2
export const getReportBudgetAnalyticalAccountingCreditExecutionV2 = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI_V2}${REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Balance Status Execution - Report Credit Execution 
const REPORT_BALANCE_STATUS_EXECUTION_URI = `${REPORTS_ENDPOINT_URI}balance-status`;
export const getReportBalanceStatusExecution = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_BALANCE_STATUS_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Balance Status Execution - Report Credit Execution V2
export const getReportBalanceStatusExecutionV2 = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI_V2}${REPORT_BALANCE_STATUS_EXECUTION_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};
// Report Budget status generated- Report Credit Execution 
const REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_URI = `${REPORTS_ENDPOINT_URI}report`;
export const getReportBudgetStatusCreditExecutionGenerated = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;
	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	} 

	return response;
};

// Report Crossed

const REPORT_BUDGETGENERATEDLIST_URI = `${REPORTS_ENDPOINT_URI}stored-reports`;
export const getReportBudgetGenerateList = async (accessToken, params) => {

	const setbuildURLQuery = {
		page: params?.page,
		pageSize: params?.size,
		period_id: params?.period_id
	};
	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${API_BASE_URI_V2}${REPORT_BUDGETGENERATEDLIST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report delivery orders - Report Request Fund
const REPORT_DELIVERY_ORDERS_FUND_REQUEST_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}delivery-orders`;
export const getReportDeliveryOrdersFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params, "");
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_DELIVERY_ORDERS_FUND_REQUEST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report payment order administrative service fund request - Report Request Fund
export const getReportPaymentOrderAdministrativeServiceFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params, "");
	const URL = `${REPORT_PAYMENT_ORDER}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report service direction - Report Request Fund
const REPORT_SERVICE_DIRECTION_FUND_REQUEST_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}fund-request-service-direction`;
export const getReportReportServiceDirectionFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params, "");
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_SERVICE_DIRECTION_FUND_REQUEST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report detail service direction - Report Request Fund
const REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}fund-request-detail-service-direction`;
export const getReportDetailServiceDirectionFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params, "");
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report service direction - Report Request Fund
const REPORT_FUND_REQUEST_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}fund-request`;
export const getReportReportFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_FUND_REQUEST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;
	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report service direction - Report Request Fund
const REPORT_PROVIDER_FUND_REQUEST_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}fund-request-provider`;
export const getReportProviderFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_PROVIDER_FUND_REQUEST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report service fund request bank - Report Request bank
const REPORT_FUND_REQUEST_BANK_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}fund-request-bank-account`;
export const getReportFundRequestBankAccount = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_FUND_REQUEST_BANK_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};



// Delete service from exercise
const REPORT_DELETE_URI = `${'/expenses/reports'}`;
export const deleteReport = async (accessToken, reportId) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${REPORT_DELETE_URI}?ids[]=${reportId}`;

	let response;

	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}

// Report New Request Fund - Report Request Fund
const REPORT_NEW_FUND_REQUEST_URI = `${REPORTS_ENDPOINT_REQUEST_FUND_URI}new-fund-request`;
export const getReportReportNewFundRequest = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_NEW_FUND_REQUEST_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;
	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

export const reportImport = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${REPORT_IMPORT}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	try {
		return await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}
}

export const reportRevert = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${REPORT_REVERT}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	try {
		return await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}
}

export const getConnectivityCCPMStatusService = async (accessToken) => {

	const URL = `${API_BASE_URI}/expenses/ccpm-status`;
	const options = buildDefaultOptions(accessToken);

	let response;
	
	try {
		response = await httpGet(URL, options);
	}
	catch( error ) {
		response = error?.response;
	}
	
	return response;
}

// Report payment order
export const getReportPaymentOrderById = async (accessToken, id) => {
	const URL = `${REPORT_PAYMENT_ORDER}?id=${id}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report fundRequest
export const getReportFundRequestById = async (accessToken, id) => {
    const URL = `${REQUEST_FUND_API_BASE_URI}${REPORT_FUND_REQUEST_URI}?id=${id}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report Determination Passive Residues
const REPORT_DETERMINATION_PASSIVE_RESIDUES_URI = `${REPORTS_ENDPOINT_URI}determination-passive-remaining`;
export const getReportDeterminationPassiveResidues = async (accessToken, params) => {
	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORT_DETERMINATION_PASSIVE_RESIDUES_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;
	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Get accrual basis report 
export const tryGetAccrualBasisReport = async (accessToken, params) => {

	const queryString = buildURLQuery(params);
	const URL = `${API_BASE_URI}${REPORTS_ENDPOINT_URI}incurred?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Direct download Report Compensation
const REPORT_COMPENSATION_DIRECT_DOWNLOAD_ENDPOINT_URI = `${REPORTS_ENDPOINT_URI}direct-download-credit-compensation`;
export const getReportCompensationDirectDownload = async (accessToken, data) => {
	const queryString = buildURLQuery(data);

	const URL = `${API_BASE_URI}${REPORT_COMPENSATION_DIRECT_DOWNLOAD_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Report provision order
export const getReportProvisionOrderById = async (accessToken, id) => {

	const URL = `${REPORT_PROVISION_ORDER}?id=${id}`;
	const options = buildDefaultOptions(accessToken, 'blob');

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};