import * as ACTION_TYPES from './principalBudgetActionTypes';



// Principal budget list by sector id actions
export const getPrincipalBudgetListBySectorIdRequest = sectorId => ({
	type: ACTION_TYPES.LIST_PRINCIPAL_BUDGET_BY_SECTOR_ID_REQUEST,
	payload: {
		sectorId
	}
})
export const getPrincipalBudgetListBySectorIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PRINCIPAL_BUDGET_BY_SECTOR_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
})
export const getPrincipalBudgetListBySectorIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PRINCIPAL_BUDGET_BY_SECTOR_ID_REQUEST_FAILURE,
	payload: {
		error
	}
})

export const clearPrincipalBudgetListBySectorId = () => ({
	type: ACTION_TYPES.CLEAR_PRINCIPAL_BUDGET_LIST_BY_SECTOR_ID
})
// END Principal budget list by sector id actions