import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button, Table } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { showError } from 'src/redux/globalData/globalDataActionCreator';
import FormFieldError from 'src/components/general/FormFieldError';
import { tryListLegalInstrument } from 'src/redux/legalInstrument/legalInstrumentActionCreator';
import { isNotEmptyArray } from 'src/services/validationService';
import { tryGetReportInstrumentLegalModifyDirectDownload } from 'src/redux/reports/reportsActionCreactor';
import { getReportInstrumentLegalModifyDirectDownloadIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportInstrumentLegalModify } from 'src/redux/reports/reportsActions';
import { getLegalInstrumentListIsFetching } from 'src/redux/legalInstrument/legalInstrumentReducer';
import { getClearObject, downloadStreamFile } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { config } from 'src/env.js';
import moment from 'moment';
import LegalInstrumentDownloadModal from './LegalInstrumentDownloadModal';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const ReportInstrumentLegalModifyPage = props => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues } = useForm();
	const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;

	//Period
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const periodId = periodSelected?.id;

	// Validations
	const numberValidationObj = { required: false };
	const yearValidationObj = { required: false };
	const yearValidation = (year) => {
		let validYear = false;
		if (year == '' || (year >= 1 && year <= 9999)) {
			validYear = true
		}
		return validYear;
	};

	// Reports redux
	const reportIsFetching = useSelector(state => getReportInstrumentLegalModifyDirectDownloadIsFetching(state));
	const legalInstrumentListIsFetching = useSelector(state => getLegalInstrumentListIsFetching(state));

	//movements table
	const [movementsFoundByFilter, setMovementsFoundByFilter] = useState([]);

	const searchLegalInstrument = (outputFormat) => {
		const yearSelected = getValues('year');
		const numberInstrumentLegal = getValues('number');

		let searchParam = ({
			size: 500,
			number: numberInstrumentLegal?.trim(),
			year: yearSelected,
			idLegalInstrumentType: getValues('legalInstrumentTypeId')
		});
		searchParam = getClearObject(searchParam);

		if (searchParam.idLegalInstrumentType || yearValidation(searchParam?.year) || searchParam?.number) {
			dispatch(tryListLegalInstrument(searchParam)).then(data => {
				const records = data?.records;
				if (records?.length === 0) {
					setMovementsFoundByFilter([]);
					showError('No se encontraron coincidencias con los datos ingresados.');
				}
				if (isNotEmptyArray(records)) {
					setMovementsFoundByFilter(records);
				}
			});
		}
	};

	const dowloadLegalInstrument = (outputFormat, value) => {
		const params = {
			outputFormat,
			legalInstrumentId: value?.id,
			orderBy: value?.orderBy,
			period_id: periodId
		};
		dispatch(tryGetReportInstrumentLegalModifyDirectDownload(params)).then(response => {
			if (response?.status == 200) {
				downloadStreamFile(response, 'reporte_de_modificaciones_por_instrumento_legal');
			}
		});
	};

	//PDF modal
	const [dataModal, setDataModal] = useState();
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	const onClickDowloadModal = (data) => {
		setDataModal(data);
		handleShow(true);
	};

	const onClickPDF = (item) => dowloadLegalInstrument(1, item);
	const onClickXLS = (item) => dowloadLegalInstrument(2, item);

	useEffect(() => {
		dispatch(clearReportInstrumentLegalModify());
	}, []);

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Reporte de modificaciones por instrumento legal</h1>
                    </Card.Header> 
					<Card.Body>
						<Form className='mt-4' onSubmit={handleSubmit(searchLegalInstrument)}>
							<Form.Group as={Row}>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									Tipo:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										as='select'
										name='legalInstrumentTypeId'
										ref={register}
									>
										{
											LEGAL_INSTRUMENT_TYPES?.map(item => (<option className='text-black-color' key={item?.id} value={item?.id}>
												{item?.name}
											</option>))
										}
									</Form.Control>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId='formHorizontalEmail'>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									Nro. Instrumento legal:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										type='text'
										name='number'
										placeholder='Nro. Instrumento legal'
										ref={register(numberValidationObj)}
									/>
									<FormFieldError errors={errors?.number} />
								</Col>
							</Form.Group>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									Año:
								</Form.Label>
								<Col sm={4}>
									<Form.Control
										type='number'
										name='year'
										ref={register(yearValidationObj)}
										max={9999}
										min={1}
										className='text-left'
									/>
								</Col>
							</Form.Group>
							<Form.Group className='d-flex justify-content-center mt-4 mb-4'>
								<Button className='m-1' type='submit' size='lg' disabled={legalInstrumentListIsFetching || reportIsFetching}>
									Buscar
								</Button>
								{
									reportIsFetching || legalInstrumentListIsFetching ?
										<Spinner className='m-4 spinner-border text-danger ' animation='border' />
										: null
								}
							</Form.Group>
						</Form>
						<div className='table-responsive'>
							<Table bordered hover size='sm' hidden={movementsFoundByFilter?.length == 0}>
								<thead>
									<tr>
										<th className='text-center'>Tipo</th>
										<th className='text-center'>Nro.</th>
										<th className='text-center'>Fecha</th>
										<th className='text-center'>Descripción</th>
										<th className='text-center'>Acciones</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										movementsFoundByFilter.map(item => (
											<tr key={item?.id}>
												<td className='text-center'>
													{item?.legalInstrumentType?.name}
												</td>
												<td className='text-center'>
													{item?.number}
												</td>
												<td className='text-center'>
													{moment(item?.date).format('DD/MM/YYYY')}
												</td>
												<td className='text-center'>
													{item?.description || '-'}
												</td>
												<td className='text-center align-middle'>
													<ActionIcon className='pr-1' size='lg' id='download' toolTipText='Descargar reporte' icon={faDownload} onClick={() => onClickDowloadModal(item)} />
												</td>
											</tr>
										))
									}
								</tbody>
							</Table>
						</div>
					</Card.Body>
				</Card>
			</Container>
			<LegalInstrumentDownloadModal
				show={show}
				handleClose={handleClose}
				dataModal={dataModal}
				onClickPDF={onClickPDF}
				onClickXLS={onClickXLS}
			/>
		</>
};

export default ReportInstrumentLegalModifyPage;