import React from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { auditDetail } from 'src/utils/label';
import { isNotEmptyArray } from 'src/services/validationService';
import moment from 'moment';

const AuditDetailModal = (props) => {

    const { show, handleClose, dataModal } = props;

    // Action Types
    const actionTypes = [
        "Creación de afectaciones",
        "Modificación de crédito",
        "Creación de afectaciones de residuo pasivo",
        "Creación de crédito original de residuo pasivo",
        "Creación de residuos pasivos por servicio",
        "Inicio de reversión de residuos pasivos por servicio",
        "Fin de reversión de residuos pasivos por servicio",
        "Generación de reporte",
        "Creación de crédito original",
        "Alta de Pre-Carga de Expediente de Modificación de Crédito",
        "Modificación de asunto del expediente",
        "Edicion de Importe de Pre-Carga de Expediente de Modificación de Crédito",
        "Eliminación de Pre-Carga de Expediente de Modificación de Crédito",
        "Conversión de Pre-Carga de Expediente a una nueva Modificación de Crédito",
        "Generación de reporte Excel de Precarga",
        "Reporte de comprobantes por expediente",
        "Reporte de modificaciones por instrumento legal"
    ];

    // Creation of affectation audit
    const creationOfAffectationsAudit = (data, action) => {

        const list = data?.data;
        const listValidation = list?.length > 0 ? true : false;

        let formatData = () => {
            let array = [];

            if (listValidation) {
                list?.map((item) => {

                    const spendingStages = item?.['Etapas del gasto'].toString();
                    const period = item?.Periodo;
                    const expedient = JSON.stringify(item?.Expediente)?.replace(/(")/gm, "")?.replace(/({)/gm, "").replace(/(})/gm, "");
                    const affectationDate = moment(item?.['Fecha de afectación']).format('DD/MM/YY');
                    const amount = item?.Importe?.toLocaleString();
                    const number = item?.['Nro. de partida'];

                    const element = {
                        spendingStages,
                        expedient,
                        affectationDate,
                        period,
                        amount,
                        number
                    };

                    array.push(element);
                });
            };

            return array;
        };

        const formatedData = formatData();

        if (listValidation) {
            return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
                <p className='p-0 m-0'><b>Accion:  </b>{action}</p>
                <hr></hr>
                {formatedData?.map((element, index) =>
                    <div>
                        <p className='p-0 m-0'><b>Etapas del gasto:</b> {element?.spendingStages}</p>
                        <p className='p-0 m-0'><b>Periodo:</b>  {element?.period}</p>
                        <p className='p-0 m-0'><b>Expediente:</b>  {element?.expedient}</p>
                        <p className='p-0 m-0'><b>Fecha de afectación:</b>  {element?.affectationDate}</p>
                        <p className='p-0 m-0'><b>Importe:</b>  ${element?.amount}</p>
                        <p className='p-0 m-0'><b>Nro. de partida:</b>  {element?.number}</p>
                        <hr></hr>
                    </div>
                )}
            </div>
        };

    };

    //Credit modification audit
    const creditModificactionAudit = (data, action) => {
        const legalInstrument = data?.['Instrumento legal'];
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Nro. de partida:</b>  {data?.['Número de partida']}</p>
                <p className='p-0 m-0'><b>Periodo:</b>  {data?.Periodo}</p>
                <p className='p-0 m-0'><b>Instrumento legal:</b> Tipo: {legalInstrument?.['Tipo']} - Fecha: {legalInstrument?.['Fecha']} - Numero: {legalInstrument?.['Número']} - Descripción: {legalInstrument?.['Descripción']}</p>
                <p className='p-0 m-0'><b>Monto:</b>  $ {data?.Monto?.toLocaleString()}</p>
                <p className='p-0 m-0'><b>Observación:</b>  {data?.['Observación']}</p>
            </div>
        </div>
    };

    //Creation of passive remaining affectations
    const passiveRemainingAffectationsAudit = (data, action) => {
        return creationOfAffectationsAudit(data, action);
    };

    //Original credit creation of passive remaining
    const originalCreditOfPassiveRemainingAudit = (data, action) => {
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Nro. de partida:</b>  {data?.['Número de partida']}</p>
                <p className='p-0 m-0'><b>Periodo:</b>  {data?.Periodo}</p>
                <p className='p-0 m-0'><b>Monto:</b>  $ {data?.Monto?.toLocaleString()}</p>
                <p className='p-0 m-0'><b>Observación:</b>  {data?.['Observación']}</p>
            </div>
        </div>
    };

    //Creation of passive remaining by service
    const passiveRemainingByServiceAudit = (data, action) => {
        const formatedData = data?.[0];
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Cantidad de expedientes procesados:</b>  {formatedData?.['Cantidad de expedientes procesados']}</p>
                <p className='p-0 m-0'><b>Nombre de Servicio:</b>  {formatedData?.['Nombre de Servicio']}</p>
                <p className='p-0 m-0'><b>Número de Servicio:</b>  {formatedData?.['Número de Servicio']}</p>
            </div>
        </div>
    };

    //Start of reversal of passive remaining by service
    const reversalOfPassiveRemainingByServiceAudit = (data, action) => {
        const formatedData = data?.[0];
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Número de Servicio:</b>  {formatedData?.['Número de Servicio']}</p>
                <p className='p-0 m-0'><b>Nombre de Servicio:</b>  {formatedData?.['Nombre de Servicio']}</p>
                <p className='p-0 m-0'><b>Cantidad de expedientes a procesar:</b>  {formatedData?.['Cantidad de expedientes a procesar']}</p>
                <p className='p-0 m-0'><b>Intervalo a procesar:</b>  {formatedData?.['Intervalo a procesar']}</p>
            </div>
        </div>
    };

    //End of reversal of passive waste by service
    const endReversalOfPassiveRemainingByServiceAudit = (data, action) => {
        const formatedData = data?.[0];
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Número de Servicio:</b>  {formatedData?.['Número de Servicio']}</p>
                <p className='p-0 m-0'><b>Nombre de Servicio:</b>  {formatedData?.['Nombre de Servicio']}</p>
                <p className='p-0 m-0'><b>Cantidad de expedientes procesados:</b>  {formatedData?.['Cantidad de expedientes procesados']}</p>
                <p className='p-0 m-0'><b>Cantidad de partidas procesadas:</b>  {formatedData?.['Cantidad de partidas procesadas']}</p>
            </div>
        </div>
    };

    // Report generation
    const reportGenerationAudit = (data, action) => {

        const services = data?.['Servicios'];
        const hasServices = isNotEmptyArray(services);

        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Año:</b>  {data?.['Año']}</p>
                <p className='p-0 m-0'><b>Fecha desde:</b>  {data?.['Fecha desde']}</p>
                <p className='p-0 m-0'><b>Fecha hasta:</b>  {data?.['Fecha hasta']}</p>
                <p className='p-0 m-0'><b>Reporte:</b>  {data?.Reporte}</p>
                <br></br>
                {
                    hasServices &&
                    <div>
                        <p className='p-0 m-0'><b>Servicios: </b></p>
                        <Table striped bordered hover size='sm' className='table-responsive'>
                            <thead className='text-white-color'>
                                <tr>
                                    <th className='text-center align-middle' style={{ "width": "10%" }}>Número</th>
                                    <th className='text-center align-middle' style={{ "width": "90%" }}>Nombre</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    services?.map((item) => (
                                        <tr key={item?.['Numero']}>
                                            <td className='text-center align-middle tipeTableFormat tbTipo'>
                                                {item?.['Número']}
                                            </td>
                                            <td className='text-center align-middle actionTableFormat tbAction'>
                                                {item?.['Nombre']}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                }
            </div >
        </div>
    };

    // Original credit creation
    const originalCreditCreationAudit = (data, action) => {
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Periodo:</b>  {data?.['Periodo']}</p>
                <p className='p-0 m-0'><b>Número de partida:</b>  {data?.['Número de partida']}</p>
                <p className='p-0 m-0'><b>Monto:</b> $ {data?.['Monto']?.toLocaleString()}</p>
                <p className='p-0 m-0'><b>Observación:</b>  {data?.['Observación']}</p>
            </div>
        </div>
    };

    // prelaod 
    const preloadAudit = (data, action) => {

        const res = Object.keys(data)?.map(key => {
            return <p className='p-0 m-0'><b>{key}:</b> {data[key]}</p>;
        });

        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                {res}
            </div>
        </div>
    };

    //Modification of expedient subject
    const modificationOfExpedientSubjectAudit = (data, action) => {
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Asunto anterior:</b>  {data?.['Asunto anterior']}</p>
                <p className='p-0 m-0'><b>Asunto nuevo:</b>  {data?.['Asunto nuevo']}</p>
                <p className='p-0 m-0'><b>Año:</b> {data?.['Año']}</p>
                <p className='p-0 m-0'><b>Código Organismo:</b>  {data?.['Código Organismo']}</p>
                <p className='p-0 m-0'><b>Número:</b>  {data?.['Número']}</p>
                <p className='p-0 m-0'><b>Id:</b>  {data?.['Id']}</p>
            </div>
        </div>
    };

    const preloadExcelReportGenerationAudit = (data, action) => {
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Fecha de generación:</b>  {data?.['Fecha de generación']}</p>
                <p className='p-0 m-0'><b>Reporte:</b>  {data?.['Reporte']}</p>
                <p className='p-0 m-0'><b>Parametros:</b> {JSON.stringify(data?.['parameters'])}</p>
            </div>
        </div>
    };

    //Report of receipts by expedient
    const reportOfReceiptsByExpedient = (data, action) => {

        const formatedData = data?.[0];
        const budgetItems = formatedData?.['Partidas Presupuestarias'];
        const hasBudgetItems = isNotEmptyArray(budgetItems);

        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Fecha de Transacción:</b>  {formatedData?.['Fecha de Transacción']}</p>
                <p className='p-0 m-0'><b>Número de Expediente:</b>  {formatedData?.['Número de Expediente']}</p>
                <p className='p-0 m-0'><b>Partidas Presupuestarias:</b></p>
                {
                    hasBudgetItems &&
                        budgetItems?.map((item) => {
                            return <p className='p-0 m-0'>{item}</p> 
                        })
                }
            </div>
        </div>
    };

     //modification report by legal instrument
     const modificationReportByLegalInstrument = (data, action) => {
        return <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
            <p className='p-0 m-0'><b>Acción:</b>  {action}</p>
            <hr></hr>
            <div>
                <p className='p-0 m-0'><b>Número de instrumento legal:</b>  {data?.['Número de instrumento legal']}</p>
                <p className='p-0 m-0'><b>Tipo de instrumento legal:</b>  {data?.['Tipo de instrumento legal']}</p>
                <p className='p-0 m-0'><b>Importe total de modificaciones:</b> $ {data?.['Importe total de modificaciones']?.toLocaleString()}</p>
            </div>
        </div>
    };

    //default
    const defaultAudit = (dataModal) => {
        const data = dataModal?.references;
        return (
            <div className='text-black-color' style={{ "font-size": "0.9rem" }}>
                <p className='p-0 m-0'><b>{data}</b></p>
            </div>
        )
    };

    const getTableComponent = () => {

        let component;
        const actionType = dataModal?.action;
        const formatData = dataModal != undefined ? JSON.parse(dataModal?.references) : null;

        switch (actionType) {
            case actionTypes[0]: {
                component = creationOfAffectationsAudit(formatData, actionType);
                break;
            }
            case actionTypes[1]: {
                component = creditModificactionAudit(formatData, actionType);
                break;
            }
            case actionTypes[2]: {
                component = passiveRemainingAffectationsAudit(formatData, actionType);
                break;
            }
            case actionTypes[3]: {
                component = originalCreditOfPassiveRemainingAudit(formatData, actionType);
                break;
            }
            case actionTypes[4]: {
                component = passiveRemainingByServiceAudit(formatData, actionType);
                break;
            }
            case actionTypes[5]: {
                component = reversalOfPassiveRemainingByServiceAudit(formatData, actionType);
                break;
            }
            case actionTypes[6]: {
                component = endReversalOfPassiveRemainingByServiceAudit(formatData, actionType);
                break;
            }
            case actionTypes[7]: {
                component = reportGenerationAudit(formatData, actionType);
                break;
            }
            case actionTypes[8]: {
                component = originalCreditCreationAudit(formatData, actionType);
                break;
            }
            case actionTypes[9]: {
                component = preloadAudit(formatData, actionType);
                break;
            }
            case actionTypes[10]: {
                component = modificationOfExpedientSubjectAudit(formatData, actionType);
                break;
            }
            case actionTypes[11]: {
                component = preloadAudit(formatData, actionType);
                break;
            }
            case actionTypes[12]: {
                component = preloadAudit(formatData, actionType);
                break;
            }
            case actionTypes[13]: {
                component = preloadAudit(formatData, actionType);
                break;
            }
            case actionTypes[14]: {
                component = preloadExcelReportGenerationAudit(formatData, actionType);
                break;
            }
            case actionTypes[15]: {
                component = reportOfReceiptsByExpedient(formatData, actionType);
                break;
            }
            case actionTypes[16]: {
                component = modificationReportByLegalInstrument(formatData, actionType);
                break;
            }
            default: {
                component = defaultAudit(dataModal);
            }
        }

        return component;

    }
    return <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">

        <Modal.Header closeButton>
            <Modal.Title>{auditDetail}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {getTableComponent()}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cerrar
            </Button>
        </Modal.Footer>

    </Modal>
}

export default AuditDetailModal;