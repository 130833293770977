import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const AlertMessageComponent = props => {
	const { hasListAlerts, listAlertData, setAlertActiveValue } = props;

	return (
		<table>
			<tbody>
				{hasListAlerts ?
					listAlertData?.map(item => 
						<tr key={item.id}>
							<td className={`alert alert-${item?.level?.toLowerCase()} m-1`}  role='alert'>
								<FontAwesomeIcon className={`text-${item?.level?.toLowerCase()} mr-1`} icon={faBell} />
								{item?.message}
							</td>
						</tr>)
					: setAlertActiveValue?.records?.map(item => 
						<tr key={item.id}>
							<td className={`alert alert-${item?.level?.toLowerCase()} m-1`}  role='alert'>
								<FontAwesomeIcon className={`text-${item?.level?.toLowerCase()} mr-1`} icon={faBell} />
								{item?.message}
							</td>
						</tr>)
				}
			</tbody>
		</table>
	);
};

export default AlertMessageComponent;