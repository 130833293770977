
// List exercise action types
export const LIST_EXERCISES_REQUEST = 'LIST_EXERCISES_REQUEST';
export const LIST_EXERCISES_REQUEST_SUCCESS = 'LIST_EXERCISES_REQUEST_SUCCESS';
export const LIST_EXERCISES_REQUEST_FAILURE = 'LIST_EXERCISES_REQUEST_FAILURE';
export const CLEAR_LIST_EXERCISES_DATA = 'CLEAR_LIST_EXERCISES_DATA';

// Create exercise action types
export const CREATE_EXERCISE_REQUEST = 'CREATE_EXERCISE_REQUEST';
export const CREATE_EXERCISE_REQUEST_SUCCESS = 'CREATE_EXERCISE_REQUEST_SUCCESS';
export const CREATE_EXERCISE_REQUEST_FAILURE = 'CREATE_EXERCISE_REQUEST_FAILURE';
export const CLEAR_CREATE_EXERCISE_DATA = 'CLEAR_CREATE_EXERCISE_DATA';

// Edit exercise action types
export const SET_SELECTED_LEGAL_INSTRUMENT = 'SET_SELECTED_LEGAL_INSTRUMENT';
export const SET_EXERCISE_TO_EDIT_DATA = 'SET_EXERCISE_TO_EDIT_DATA';
export const EDIT_EXERCISE_REQUEST = 'EDIT_EXERCISE_REQUEST';
export const EDIT_EXERCISE_REQUEST_SUCCESS = 'EDIT_EXERCISE_REQUEST_SUCCESS';
export const EDIT_EXERCISE_REQUEST_FAILURE = 'EDIT_EXERCISE_REQUEST_FAILURE';
export const CLEAR_EDIT_EXERCISE_DATA = 'CLEAR_CREATE_EXERCISE_DATA';


// Exercises detail data action types
export const SET_EXERCISE_DETAILS_DATA = 'SET_EXERCISE_DETAILS_DATA';

// Services by exercise ID
export const GET_SERVICES_BY_EXERCISE_ID_REQUEST = 'GET_SERVICES_BY_EXERCISE_ID_REQUEST';
export const GET_SERVICES_BY_EXERCISE_ID_REQUEST_SUCCESS = 'GET_SERVICES_BY_EXERCISE_ID_REQUEST_SUCCESS';
export const GET_SERVICES_BY_EXERCISE_ID_REQUEST_FAILURE = 'GET_SERVICES_BY_EXERCISE_ID_REQUEST_FAILURE';
export const CLEAR_SERVICES_BY_EXERCISE_ID_DATA = 'CLEAR_SERVICES_BY_EXERCISE_ID_DATA';

// Services management action types
export const SET_SERVICE_MANAGEMENT_SELECTED_EXERCISE = 'SET_SERVICE_MANAGEMENT_SELECTED_EXERCISE';

// Add service to exercise action types
export const ADD_SERVICE_TO_EXERCISE_REQUEST = 'ADD_SERVICE_TO_EXERCISE_REQUEST';
export const ADD_SERVICE_TO_EXERCISE_REQUEST_SUCCESS = 'ADD_SERVICE_TO_EXERCISE_REQUEST_SUCCESS';
export const ADD_SERVICE_TO_EXERCISE_REQUEST_FAILURE = 'ADD_SERVICE_TO_EXERCISE_REQUEST_FAILURE';
export const CLEAR_ADD_SERVICE_TO_EXERCISE_DATA = 'CLEAR_ADD_SERVICE_TO_EXERCISE_DATA';

// Delete service from exercise action types
export const DELETE_SERVICE_FROM_EXERCISE_REQUEST = 'DELETE_SERVICE_FROM_EXERCISE_REQUEST';
export const DELETE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS = 'DELETE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS';
export const DELETE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE = 'DELETE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE';
export const CLEAR_DELETE_SERVICE_FROM_EXERCISE_DATA = 'CLEAR_DELETE_SERVICE_FROM_EXERCISE_DATA';

// Active service from exercise action types
export const ACTIVE_SERVICE_FROM_EXERCISE_REQUEST = 'ACTIVE_SERVICE_FROM_EXERCISE_REQUEST';
export const ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS = 'ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS';
export const ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE = 'ACTIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE';
export const CLEAR_ACTIVE_SERVICE_FROM_EXERCISE_DATA = 'CLEAR_ACTIVE_SERVICE_FROM_EXERCISE_DATA';

// Closed exercise action types
export const CLOSED_EXERCISE_REQUEST = 'CLOSED_EXERCISE_REQUEST';
export const CLOSED_EXERCISE_REQUEST_SUCCESS = 'CLOSED_EXERCISE_REQUEST_SUCCESS';
export const CLOSED_EXERCISE_REQUEST_FAILURE = 'CLOSED_EXERCISE_REQUEST_FAILURE';
export const CLEAR_CLOSED_EXERCISE_DATA = 'CLEAR_CLOSED_EXERCISE_DATA';

// Search Legal Instrument Exercise types
export const LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST = 'LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST';
export const LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS = 'LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS';
export const LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE = 'LIST_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE';
export const CLEAR_LEGAL_INSTRUMENT_EXERCISE_DATA = 'CLEAR_LEGAL_INSTRUMENT_EXERCISE_DATA';

// Edit Legal Instrument Exercise types
export const EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST = 'EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST';
export const EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS = 'EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_SUCCESS';
export const EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE = 'EDIT_LEGAL_INSTRUMENT_EXERCISE_REQUEST_FAILURE';
export const CLEAR_EDIT_LEGAL_INSTRUMENT_EXERCISE_DATA = 'CLEAR_EDIT_LEGAL_INSTRUMENT_EXERCISE_DATA';

// Enable exercise action types
export const ENABLE_EXERCISE_REQUEST = 'ENABLE_EXERCISE_REQUEST';
export const ENABLE_EXERCISE_REQUEST_SUCCESS = 'ENABLE_EXERCISE_REQUEST_SUCCESS';
export const ENABLE_EXERCISE_REQUEST_FAILURE = 'ENABLE_EXERCISE_REQUEST_FAILURE';
export const CLEAR_ENABLE_EXERCISE_DATA = 'CLEAR_ENABLE_EXERCISE_DATA';

// List exercise by ID action types
export const LIST_EXERCISES_BY_ID_REQUEST = 'LIST_EXERCISES_BY_ID_REQUEST';
export const LIST_EXERCISES_BY_ID_REQUEST_SUCCESS = 'LIST_EXERCISES_BY_ID_REQUEST_SUCCESS';
export const LIST_EXERCISES_BY_ID_REQUEST_FAILURE = 'LIST_EXERCISES_BY_ID_REQUEST_FAILURE';
export const CLEAR_LIST_EXERCISES_BY_ID_DATA = 'CLEAR_LIST_EXERCISES_BY_ID_DATA';

// List Periods Monthly action types
export const LIST_PERIODS_MONTHLY_REQUEST = 'LIST_PERIODS_MONTHLY_REQUEST';
export const LIST_PERIODS_MONTHLY_REQUEST_SUCCESS = 'LIST_PERIODS_MONTHLY_REQUEST_SUCCESS';
export const LIST_PERIODS_MONTHLY_REQUEST_FAILURE = 'LIST_PERIODS_MONTHLY_REQUEST_FAILURE';
export const CLEAR_LIST_PERIODS_MONTHLY_DATA = 'CLEAR_LIST_PERIODS_MONTHLY_DATA';

//Report enabled date monthly period - Report Credit Execution 
export const REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST = 'REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST';
export const REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_SUCCESS = 'REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_SUCCESS';
export const REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_FAILURE = 'REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION_REQUEST_FAILURE';
export const CLEAR_REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION = 'CLEAR_REPORT_ENABLE_DATE_MONTHLY_PERIOD_CREDIT_EXECUTION';

// Get Active administrative service from exercise
export const GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST = 'GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST';
export const GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS = 'GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_SUCCESS';
export const GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE = 'GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_REQUEST_FAILURE';
export const CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_DATA = 'CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_DATA';