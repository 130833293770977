export const LIST_NOTIFICATION_REQUEST = 'LIST_NOTIFICATION_REQUEST';
export const LIST_NOTIFICATION_REQUEST_SUCCESS = 'LIST_NOTIFICATION_REQUEST_SUCCESS';
export const LIST_NOTIFICATION_REQUEST_FAILURE = 'LIST_NOTIFICATION_REQUEST_FAILURE';

// Notification by user
export const LIST_NOTIFICATION_BY_USER_REQUEST = 'LIST_NOTIFICATION_BY_USER_REQUEST'
export const LIST_NOTIFICATION_BY_USER_REQUEST_SUCCESS = 'LIST_NOTIFICATION_BY_USER_REQUEST_SUCCESS'
export const CLEAR_LIST_NOTIFICATION_BY_USER = 'CLEAR_LIST_NOTIFICATION_BY_USER'
export const DELETE_NOTIFICATION_BY_USER_REQUEST = 'DELETE_NOTIFICATION_BY_USER_REQUEST'

export const CLEAR_NOTIFICATION_LIST = 'CLEAR_NOTIFICATION_LIST';

//Notification New
export const NEW_NOTIFICATION_REQUEST = 'NEW_NOTIFICATION_REQUEST';
export const NEW_NOTIFICATION_REQUEST_SUCCESS = 'NEW_NOTIFICATION_REQUEST_SUCCESS';
export const NEW_NOTIFICATION_REQUEST_FAILURE = 'NEW_NOTIFICATION_REQUEST_FAILURE';

// Notification Detail
export const DETAIL_NOTIFICATION_REQUEST = 'DETAIL_NOTIFICATION_REQUEST';
export const DETAIL_NOTIFICATION_REQUEST_SUCCESS = 'DETAIL_NOTIFICATION_REQUEST_SUCCESS';
export const DETAIL_NOTIFICATION_REQUEST_FAILURE = 'DETAIL_NOTIFICATION_REQUEST_FAILURE';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

// Download file of notification
export const DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST = 'DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST';
export const DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_SUCCESS = 'DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_SUCCESS';
export const DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_FAILURE = 'DOWNLOAD_FILE_OF_NOTIFICATION_REQUEST_FAILURE';