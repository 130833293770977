import { usePermissions } from './usePermissions';

const PROFILE_NAMES = {
    // Añadir más perfiles según sea necesario
    SUPER_ADMIN: 'Super Admin',
    DIRECCION_ANALISIS_INFORMATICO: 'Dirección de Análisis e Informática',
    BUDGET_SERVICE_OPERATOR: 'Operador de Servicio de Presupuesto',
    DIRECTOR_SA: 'Director de SA',
    CHIEF_ACCOUNTANT_SA: 'Jefe de Contabilidad de SA',
    BUDGET_DIRECTION: 'Dirección de Presupuesto',
    BUDGET_EMPLOYEE: 'Empleado de Presupuesto',
    FISCAL_AUDITOR: 'Fiscalizador',
    TREASURY_OPERATOR: 'Operador de Tesoreria',
    FUNDS_TRANSFER_MANAGER: 'Responsable de transferencia de fondos',
    CONSULT_BUDGET: 'Consulta Presupuesto',
    BUDGET_ADMINISTRATOR: 'Administrador de Presupuesto',
    SUPPLY_OPERATOR: 'Operador de Suministros',
  };

export const useProfileChecks = () => {
  const { profileName } = usePermissions();

  return {
    // Añadir más comprobaciones según sea necesario
    isProfileNameSA: profileName === PROFILE_NAMES.SUPER_ADMIN,
    isProfileNameAI: profileName === PROFILE_NAMES.DIRECCION_ANALISIS_INFORMATICO,
    isProfileBudgetServiceOperator: profileName === PROFILE_NAMES.BUDGET_SERVICE_OPERATOR,
    isProfileDirectorSa: profileName === PROFILE_NAMES.DIRECTOR_SA,
    isProfileChiefAccountantSA: profileName === PROFILE_NAMES.CHIEF_ACCOUNTANT_SA,
    isProfileBudgetDirection: profileName === PROFILE_NAMES.BUDGET_DIRECTION,
    isProfileBudgetEmployee: profileName === PROFILE_NAMES.BUDGET_EMPLOYEE,
    isProfileNameHTC: profileName === PROFILE_NAMES.FISCAL_AUDITOR,
    isProfileTreasuryOperator: profileName === PROFILE_NAMES.TREASURY_OPERATOR,
    isProfileFundsTransferManager: profileName === PROFILE_NAMES.FUNDS_TRANSFER_MANAGER,
    isProfileConsultBudget: profileName === PROFILE_NAMES.CONSULT_BUDGET,
    isProfileBudgetAdministrator: profileName === PROFILE_NAMES.BUDGET_ADMINISTRATOR,
    isSupplyOperator: profileName === PROFILE_NAMES.SUPPLY_OPERATOR,
  };
};