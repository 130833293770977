import { push } from 'connected-react-router';

import * as ACTIONS from './maintenanceSystemActions';
import * as SERVICES from 'src/services/maintenanceSystemServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryPostMaintenanceSystem = (params) => {
	return async( dispatch, getState ) => {

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.postMaintenanceSystemRequest(params) );

		if(accessToken){

			const response = await SERVICES.postMaintenanceSystem( accessToken, params );
	
			if( response?.status === 200 ) {
				dispatch( ACTIONS.postMaintenanceSystemRequestSuccess(response?.data) );
				return response;
			}
			else {
				dispatch( ACTIONS.postMaintenanceSystemRequestFailure(response?.error||response) );
				showError(`Error al intentar cambiar el estado de mantenimiento del sistema. Detalle: ${response?.response?.data?.message||response}` );
			}
		}

	};
};