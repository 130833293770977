import * as ACTION_TYPES from "./conceptRequestActionTypes";

// List
export const listConceptRequest = () => ({
  type: ACTION_TYPES.LIST_CONCEPT_REQUEST,
});
export const listConceptSuccess = (data) => ({
  type: ACTION_TYPES.LIST_CONCEPT_SUCCESS,
  payload: {
    data,
  },
});
export const listConceptFailure = (error) => ({
  type: ACTION_TYPES.LIST_CONCEPT_FAILURE,
  payload: {
    error,
  },
});
export const clearListConcept = () => ({
  type: ACTION_TYPES.CLEAR_LIST_CONCEPT
})

//List Select
export const listConceptSelectRequest = () => ({
  type: ACTION_TYPES.LIST_SELECT_CONCEPT_REQUEST,
});
export const listConceptSelectSuccess = (data) => ({
  type: ACTION_TYPES.LIST_SELECT_CONCEPT_SUCCESS,
  payload: {
    data,
  },
});
export const listConceptSelectFailure = (error) => ({
  type: ACTION_TYPES.LIST_SELECT_CONCEPT_FAILURE,
  payload: {
    error,
  },
});

//add
export const addConceptRequest = params => ({
  type: ACTION_TYPES.ADD_NEW_CONCEPT_REQUEST_REQUEST,
  payload: {
      params
  }
});
export const addConceptRequestSuccess = data => ({
  type: ACTION_TYPES.ADD_NEW_CONCEPT_REQUEST_SUCCESS,
  payload: {
      data
  }
});
export const addConceptRequestFailure = error => ({
  type: ACTION_TYPES.ADD_NEW_CONCEPT_REQUEST_FAILURE,
  payload: {
      error
  }
});

export const addConceptSubconceptRequest = params => ({
  type: ACTION_TYPES.ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_REQUEST,
  payload: {
      params
  }
});
export const addConceptSubconceptRequestSuccess = data => ({
  type: ACTION_TYPES.ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_SUCCESS,
  payload: {
      data
  }
});
export const addConceptSubconceptRequestFailure = error => ({
  type: ACTION_TYPES.ADD_NEW_CONCEPT_SUBCONCEPT_REQUEST_FAILURE,
  payload: {
      error
  }
});

//delete
export const getConceptDeleteRequest = params => ({
  type: ACTION_TYPES.DELETE_CONCEPT_REQUEST,
  payload: {
      params
  }
});
export const getConceptDeleteSuccess = data => ({
  type: ACTION_TYPES.DELETE_CONCEPT_SUCCESS,
  payload: {
      data
  }
});

export const getConceptDeleteFailure = error => ({
  type: ACTION_TYPES.DELETE_CONCEPT_FAILURE,
  payload: {
      error
  }
});

//update
export const updateConceptRequest = params => ({
  type: ACTION_TYPES.UPDATE_CONCEPT_REQUEST,
  payload: {
      params
  }
});
export const updateConceptSuccess = data => ({
  type: ACTION_TYPES.UPDATE_CONCEPT_SUCCESS,
  payload: {
      data
  }
});

export const updateConceptFailure = error => ({
  type: ACTION_TYPES.UPDATE_CONCEPT_FAILURE,
  payload: {
      error
  }
});

export const setConceptToEdit = data => ({
  type: ACTION_TYPES.SET_CONCEPT_TO_EDIT,
  payload: {
      data
  }
});
