import React, {  useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button,FormControl,Spinner ,Alert} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';

import PageTitle from 'src/components/general/PageTitle';
import { SERVICE_ADMINISTRATIVE_LIST } from 'src/utils/constants';
import { isNotEmptyArray } from 'src/services/validationService';
import { listUsersByProfile } from 'src/utils/usersProfiles';

import { getAdministrativeServiceDetail, getAdministrativeServiceOrganismListData, getAdministrativeServiceUsersListData, getAdministrativeServiceUsersListIsFetching, getAdministrativeServiceOrganismListIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryGetOrganismAdministrativeServices, tryGetUsersAdministrativeServices } from 'src/redux/administrativeService/administrativeServiceActionCreator';

import { getUserListData, getUserListIsFetching } from 'src/redux/user/userReducer';
import { tryGetUserList, tryGetListUserProfile, tryGetListUserXApplicationXProfile } from 'src/redux/user/userActionCreator';
import { clearUserList } from 'src/redux/user/userActions';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const ServiceAdministrativeDetailsPage = props => {

	const dispatch = useDispatch();

	//List Users by Profiles
	const listUsersByProfilesData = listUsersByProfile();
	
	// Exercise
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	useEffect(() => {
		dispatch( clearUserList() );
		dispatch( tryGetOrganismAdministrativeServices (servicesList?.id, globalSelectedPeriod?.id) );
		dispatch( tryGetUsersAdministrativeServices (servicesList?.id) );
		//listUsersByProfile
        dispatch(tryGetListUserXApplicationXProfile());
		dispatch(tryGetListUserProfile());

		dispatch(tryGetUserList()).then(
			response => {
				if (response?.status == 200) {
					dispatch(clearUserList());
					dispatch(tryGetUserList());
				}
			}
		);
	}, []);

	// Services
	const servicesList = useSelector( state => getAdministrativeServiceDetail (state) );
 
 	// Organism
	 const servicesOrganismList = useSelector( state => getAdministrativeServiceOrganismListData (state) )?.sort( (a,b) => a.codeBudget>b.codeBudget ? 1 : -1  );
	 const hasServicesOrganismList = isNotEmptyArray(servicesOrganismList);
	 const servicesOrganismListIsFetching = useSelector(state => getAdministrativeServiceOrganismListIsFetching(state));

	// Users
	//List Users
	const listUserData = useSelector(state => getUserListData(state));

	//Asignated Users
	const servicesUsersList = useSelector( state => getAdministrativeServiceUsersListData (state) );
	const hasServicesUsersList = isNotEmptyArray(servicesUsersList);
	const servicesUsersListIsFetching = useSelector(state => getAdministrativeServiceUsersListIsFetching(state));
	
	// Find List Users Total Not Asignated
	const listUserRecords = listUsersByProfilesData?.filter( value => { 
		return !servicesUsersList?.find( value2 => value.id == value2.id );
	});

	const hasListUser = isNotEmptyArray(listUserRecords);
	const hasMoreUsers = listUserRecords?.metadata?.hasMore;
	const listUserCurrentPage = listUserData?.metadata.page;
	const listUserIsFetching = useSelector(state => getUserListIsFetching(state));

	// Find List Users Asignated
	const serviceUserListRecords = listUsersByProfilesData?.filter( value => { 
		return servicesUsersList?.find( value2 => value.id == value2.id );
	})?.sort( (a,b) => a.name>b.name ? 1 : -1 );
	const hasServiceUserList = isNotEmptyArray(serviceUserListRecords);


	const onClickBackButton = () => {
		dispatch(push(SERVICE_ADMINISTRATIVE_LIST));
	};

	return <Container className='mb-5'>
		<Card>
			<PageTitle text='Servicios' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='h6'>
						Detalle del servicio
					</Card.Header>
					<Card.Body>
					
						<Table className='text-black-color' striped borderless hover>
							<tbody>
								<tr>
									<td className='text-center font-weight-bold'>
										ID:
									</td>
									<td className='text-center'>
										{servicesList?.id}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Código:
									</td>
									<td className='text-center'>
									{servicesList?.code}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Descripción:
									</td>
									<td className='text-center'>
										{servicesList?.name}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Abreviatura:
									</td>
									<td className='text-center'>
										{servicesList?.shortName}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Tipo de servicio:
									</td>
									<td className='text-center'>
										{servicesList?.administrativeServiceType?.name}
									</td>
								</tr>
								<tr>
									<td className='text-center font-weight-bold'>
										Código de Expediente:
									</td>
									<td className='text-center'>
										{servicesList?.administrativeServiceOrganismCode ?? "-"}
									</td>
								</tr>
							</tbody>
						</Table>

						<hr />

						<Card className='mb-3'>
						<Card.Header className='h6'>
							Organismos:
						</Card.Header>
						<Table className='mb-0' striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Código</th>
									<th className='text-center'>Nombre</th>
								</tr>
							</thead>
							<tbody className='text-black-color'>
							{
									hasServicesOrganismList ?
									servicesOrganismList?.map( (item, idx) =>
									<tr key={idx}>
										<td className='text-center'>{item.codeBudget}</td>
										<td className='text-center'>{ `${item?.name} - (${item?.jurisdiction?.name}) - (${item?.organismClassifier?.shortName})` }</td>
									</tr>)
								:	
								<tr>
									<td colSpan='6' className='text-center'>
									{
										servicesOrganismListIsFetching ?
										<Spinner animation='border' variant='success' className='my-3' />
										:
										<Alert variant='info' className='mb-0'>
											<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
												No hay registros
											</Alert>
										}
									</td>
								</tr>
							}
							</tbody>
						</Table>
						</Card>

						<hr />

						<Card className='mb-3'>
						<Card.Header className='h6'>
							Usuarios asignados:
						</Card.Header>
                        <Table className='mb-0' striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Apellido y nombre</th>
									<th className='text-center'>Usuario</th>
								</tr>
								
							</thead>
							<tbody className='text-black-color'>
							{
									hasServiceUserList ?
									serviceUserListRecords?.map( (item, idx) =>
									<tr key={idx}>
										<td className='text-center'>{item.name}</td>
                                        <td className='text-center'>{item.email}</td>
									</tr>)
								:
								<tr>
									<td colSpan='6' className='text-center'>
									{
										servicesUsersListIsFetching ?
										<Spinner animation='border' variant='success' className='my-3' />
										:
										<Alert variant='info' className='mb-0'>
											<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
												No hay registros
											</Alert>
									}
									</td>
								</tr>
							}
							</tbody>
						</Table>
						</Card>

						<div className='text-center'>
							<Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
						</div>

					</Card.Body>
				</Card>

			</Container>
		</Card>
	</Container>;
};

export default ServiceAdministrativeDetailsPage;