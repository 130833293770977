import React from 'react';
import { MultiSelect } from "react-multi-select-component";

const MultiselectComponent = ({ options, selected, setSelected, isLoading }) => {
	const hasValueCustomValueRenderer = (selected.length === 0);
	const idiom = {
        selectSomeItems: "Seleccionar una o más opciones...",
        allItemsAreSelected: "Todos los elementos están seleccionados.",
        selectAll: "Seleccionar todo",
        search: "Buscar",
        clearSearch: "Limpiar búsqueda."
    }
    // Load data 	
	const customValueRenderer = (selected) => {
		return ( selected.length
			? selected.map(({ label }) => " ✔️" + label)
			: "Seleccione una Opción..."
        );
	};

    return (
        <>
            <MultiSelect
                className='text-black-color'
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy={"Select"}
                overrideStrings={idiom}
                valueRenderer={customValueRenderer}
                hasSelectAll={true}
                isLoading={isLoading}
                ClearSelectedIcon={"🧹Limpiar"}
            />
            {hasValueCustomValueRenderer &&
                <p className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
                    Debe seleccionar al menos un ítem.
                </p>
            }
        </>
    );
};

export default MultiselectComponent;