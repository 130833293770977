import { push } from 'connected-react-router';

import * as SERVICES from 'src/services/bankAccountServices';
import * as ACTIONS from './bankAccountActions';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { config } from 'src/env.js';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

export const tryBankAccountGetAll = ( params ) => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		// dispatch( ACTIONS.getUserListRequest() );
		let _params = {
			size: 100,
			year: getGlobalDataSelectedPeriod( state )?.year,
			...params
		}
		if(accessToken){
			const response = await SERVICES.getAll( accessToken, _params );

			if( response?.status == 200 ) {
				dispatch( ACTIONS.getBankAccountListRequestSuccess( response?.data ) );
			}
			else {
				// dispatch( ACTIONS.getUserListRequestFailure( response?.error ) );
				// showError(`Error al cargar los usuarios. Comuníquese con el Área de Sistemas. Detalle: ${response.error}` );
			}
	
			return response;
		}
	};
};

//New User
// export const tryPostUser = ( name='', alias='', password='', email='', description='description', profileId ) => {
// 	return async ( dispatch, getState ) => {
// 		const state = getState();
// 		const accessToken = getAccessToken( state );

// 		dispatch( ACTIONS.getNewUserRequest() );

// 		if(accessToken){
			
// 			const response = await SERVICES.newUser( accessToken, name, alias, password, email, description );
	
// 			if( response?.status == 200 ) {
// 				dispatch( ACTIONS.getNewUserRequestSuccess( response?.data ) );
// 				showSuccess('Usuario registrado correctamente, asignado a los servicios seleccionados.' );
// 				const userToProfileParam = {
// 					userId: response?.data?.id,
// 					applicationId: config.apis.authApi.APPLICATION_ID,
// 					profileId
// 				};
// 				dispatch( tryAddUserToProfile(userToProfileParam) );
// 			}
// 			else {
// 				dispatch( ACTIONS.getNewUserRequestFailure( response?.data?.error ) );
// 				showError(`Error al crear un nuevo usuario. Detalle: ${response?.data?.error?.message}` );
// 			}
// 			return response;
// 		}

// 	};
// };

// //Edit User
// export const tryPutUser = userData => {
// 	return async ( dispatch, getState ) => {
// 		const state = getState();
// 		const accessToken = getAccessToken( state );

// 		dispatch( ACTIONS.editUserRequest() );

// 		if(accessToken){
			
// 			const response = await SERVICES.editUser( accessToken, userData );
// 			if( response?.status == 200 ) {
// 				dispatch( ACTIONS.editUserRequestSuccess( response?.data ) );
// 				showSuccess('Usuario editado correctamente.' );
// 				dispatch( tryPutUserXApplicationXProfile(userData?.userXApplicationXProfile) );
// 			}
// 			else {
// 				dispatch( ACTIONS.editUserRequestFailure( response?.error||response ) );
// 				showError(`Error al editar el usuario. Detalle: ${response?.error||response}` );
// 			}
// 			return response;
// 		}

// 	};

// };

// //Delete User
// export const tryDeleteUser = ( id=0) => {
// 	return async ( dispatch, getState ) => {
// 		const state = getState();
// 		const accessToken = getAccessToken( state );

// 		dispatch( ACTIONS.deleteUserRequest() );

// 		if(accessToken){
			
// 			const response = await SERVICES.deleteUser( accessToken, id );
			
// 			if( response?.status == 200 ) {
// 				dispatch( ACTIONS.deleteUserRequestSuccess( response?.data ) );
// 				showSuccess('Usuario eliminado correctamente.' );
// 				dispatch( push(ROUTES.USER_LIST) );
// 			}
// 			else {
// 				dispatch( ACTIONS.deleteUserRequestFailure( response?.error || response ) );
// 				showError(`Error al eliminar el usuario. Detalle: ${response?.error || response}`  );
// 			}
// 		}

// 	};
// };

// // User Data
// export const tryGetUserDetail = idUser => {
// 	return async( dispatch, getState ) => {
// 		const state = getState();
// 		const accessToken = getAccessToken( state );

// 		dispatch( ACTIONS.getUserDetailRequest() );
		
// 		if(accessToken){
			
// 			const response = await SERVICES.getUserData( accessToken, idUser );
	
// 			if( response?.status == 200 ) {
// 				dispatch( ACTIONS.getUserDetailRequestSuccess(response?.data) );
// 			}
// 			else {
// 				dispatch( ACTIONS.getUserDetailRequestFailure(response?.error||response) );
// 				showError(`Error al obtener el detalle de usuario. Detalle: ${response?.error||response}`  );
// 			}
// 		}

// 	};
// };

