import * as ACTION_TYPES from './auditActionTypes';

export const auditReducerInitialState = {
	auditList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	auditsUsersList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const auditReducer = ( state=auditReducerInitialState, action ) => {
	switch( action.type ) {

		case ACTION_TYPES.LIST_AUDIT_REQUEST: {
			return {
				...state,
				auditList: {
					...state.auditList,
					isFetching: true,
					hasError: false,
					data: auditReducerInitialState.auditList.data,
					error: auditReducerInitialState.auditList.error
				}
			};
		}

		case ACTION_TYPES.LIST_AUDIT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				auditList: {
					...state.auditList,
					isFetching: false,
					hasError: false,
					error: auditReducerInitialState.auditList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_AUDIT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				auditList: {
					...state.auditList,
					isFetching: false,
					hasError: true,
					data: auditReducerInitialState.auditList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_AUDIT_LIST: {
			return {
				...state,
				auditList: auditReducerInitialState.auditList
			};
		}

		// list audits users
		case ACTION_TYPES.LIST_AUDITS_USERS_REQUEST: {
			return {
				...state,
				auditsUsersList: {
					...state.auditsUsersList,
					isFetching: true,
					hasError: false,
					data: auditReducerInitialState.auditsUsersList.data,
					error: auditReducerInitialState.auditsUsersList.error
				}
			};
		}

		case ACTION_TYPES.LIST_AUDITS_USERS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				auditsUsersList: {
					...state.auditsUsersList,
					isFetching: false,
					hasError: false,
					error: auditReducerInitialState.auditsUsersList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_AUDITS_USERS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				auditsUsersList: {
					...state.auditsUsersList,
					isFetching: false,
					hasError: true,
					data: auditReducerInitialState.auditsUsersList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_AUDITS_USERS_LIST: {
			return {
				...state,
				auditsUsersList: auditReducerInitialState.auditsUsersList
			};
		}
		// END list audits users

		default: {
			return state;
		}
	}
};

export const getAuditReducer = state => state.auditReducer;

export const getAuditList = state => getAuditReducer(state)?.auditList;
export const getAuditListData = state => getAuditList(state)?.data;
export const getAuditListIsFetching = state => getAuditList(state)?.isFetching;
export const getAuditListHasError = state => getAuditList(state)?.hasError;
export const getAuditListError = state => getAuditList(state)?.error;

// Getter list audits users
export const getAuditsUsersList = state => getAuditReducer(state)?.auditsUsersList;
export const getAuditsUsersListData = state => getAuditsUsersList(state)?.data;
export const getAuditsUsersListMetadata = state => getAuditsUsersListData(state)?.metadata;
export const getAuditsUsersListIsFetching = state => getAuditsUsersList(state)?.isFetching;
export const getAuditsUsersListHasError = state => getAuditsUsersList(state)?.hasError;
export const getAuditsUsersListError = state => getAuditsUsersList(state)?.error;