import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col, Button, Spinner, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSearch  } from '@fortawesome/free-solid-svg-icons';
import { getYearsOptionsByParam, parseIntOrUndefined } from 'src/utils/utils';
import FormFieldError from 'src/components/general/FormFieldError';
import { organismCodeRequiredMsg, numberRequiredMsg, yearRequiredMsg, organismCodeNumericLimitsMsg } from 'src/utils/label';
import { useFormContext } from 'react-hook-form';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';

const administrativeDocumentForm = props => {


    const { type, administrativeDocumentHistoryIsFetching } = props;

	// Period
	const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

    // Year field options
	const minimumYear = 2000;
	const yearsOptions = getYearsOptionsByParam(minimumYear, selectedPeriod?.year);

     //Validations
	 const { register, errors } = useFormContext();

	const organismCodeValidationObj  = {
		required: organismCodeRequiredMsg,
		validate: value => ((parseIntOrUndefined(value) >= 1000 && parseIntOrUndefined(value) <= 9999) || value == '0000') ? undefined : organismCodeNumericLimitsMsg
	};

	const numberValidationObj = {
		required: numberRequiredMsg
	}

	const yearValidationObj = {
		validate: value => (parseIntOrUndefined(value)) ? undefined : yearRequiredMsg
	}
	
    //Form data
	const [codeOrganismHasValue, setCodeOrganismHasValue] = useState(false);
	const [correlativeHasValue, setCorrelativeHasValue] = useState(false);
	const [yearHasValue, setYearHasValue] = useState(false);

	const onChangeCodeOrganismHasValue = event => {
		if(event){
			setCodeOrganismHasValue(true)
		}else{
			setCodeOrganismHasValue(false)
		}
	};

	const onChangeCorrelativeHasValue = event => {
		if(event){
			setCorrelativeHasValue(true)
		}else{
			setCorrelativeHasValue(false)
		}
	};

	const onChangeYearHasValue = event => {
		if(event){
			setYearHasValue(true)
		}else{
			setYearHasValue(false)
		}
	};

	const allowButtonSearch = (codeOrganismHasValue && correlativeHasValue && yearHasValue );

    useEffect(() => {
		
	}, []);

    return<>
		<Container class="row justify-content-md-center">
			<Form.Group className='text-black-color mt-5 mb-3 justify-content-md-center' as={Row}>

				<Col sm={ type == 'reportAdministrativeDocument' ? '0' : '9'} className='mx-0 px-0  d-flex align-items-center justify-content-center'>

					<Form.Label htmlFor='expediente-data' className='mb-0 pr-3 d-flex align-items-center justify-content-end'>
						<FontAwesomeIcon icon={faAsterisk} size='xs' className={ type == 'reportAdministrativeDocument' ? 'hidden' : 'mr-1 text-danger mandatory-asterisk'}/>
							Expediente:
					</Form.Label>

					<Form.Control
						type='number'
						id='expediente-data'
						name= 'organismCode'
						className='correlative-width'
						placeholder='Código'
						ref={register(organismCodeValidationObj)} 
						disabled={administrativeDocumentHistoryIsFetching}
						onChange={event => onChangeCodeOrganismHasValue(event?.target?.value)}
					/>

					<span className='px-2'>
						-
					</span>

					<Form.Control
						type='number'
						placeholder='Correlativo'
						className='number-width'
						name = 'number'
						ref={register(numberValidationObj)}
						min='1'
						disabled={administrativeDocumentHistoryIsFetching}
						onChange={event => onChangeCorrelativeHasValue(event?.target?.value)}
					/>

					<span className='px-2'>
						/
					</span>

					<Form.Control
						as='select'
						name = 'year'
						className='select-min-width'
						ref={register(yearValidationObj)}
						disabled={administrativeDocumentHistoryIsFetching}
						onChange={event => onChangeYearHasValue(event?.target?.value)}
					>
						<option value={undefined} selected>Año</option>
							{
								yearsOptions?.map(item => (<option value={item}>
									{item}
								</option>))
						}
					</Form.Control>

					<Button 
						type='submit' 
						className='ml-3 text-white-color'
						disabled={!allowButtonSearch || administrativeDocumentHistoryIsFetching}
					>
						<FontAwesomeIcon icon={faSearch} />
					</Button>
				</Col>
			
			</Form.Group>
		</Container>

		<div class="row justify-content-md-center mt-1">
			<Col sm='7'>
			{/*mensajes de error*/}
			<Form.Group className='text-black-color'>
					<FormFieldError errors={errors.organismCode} />
					<FormFieldError errors={errors.number} />
					<FormFieldError errors={errors.year} />
			</Form.Group>
			</Col>
		</div>
		
		<div className='text-center'>
			<Spinner
				animation='border'
				className={administrativeDocumentHistoryIsFetching ? 'spinner-border text-danger' : 'hidden'} 
			/>
		</div>
    </>
}

export default administrativeDocumentForm;