export const LIST_AUDIT_REQUEST = 'LIST_AUDIT_REQUEST';
export const LIST_AUDIT_REQUEST_SUCCESS = 'LIST_AUDIT_REQUEST_SUCCESS';
export const LIST_AUDIT_REQUEST_FAILURE = 'LIST_AUDIT_REQUEST_FAILURE';

export const CLEAR_AUDIT_LIST = 'CLEAR_AUDIT_LIST';

// list audits users
export const LIST_AUDITS_USERS_REQUEST = 'LIST_AUDITS_USERS_REQUEST';
export const LIST_AUDITS_USERS_REQUEST_SUCCESS = 'LIST_AUDITS_USERS_REQUEST_SUCCESS';
export const LIST_AUDITS_USERS_REQUEST_FAILURE = 'LIST_AUDITS_USERS_REQUEST_FAILURE';

export const CLEAR_AUDITS_USERS_LIST = 'CLEAR_AUDITS_USERS_LIST';