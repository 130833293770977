// Adminstrative Organism List 
export const LIST_ADMINISTRATIVE_ORGANISM_REQUEST = 'LIST_ADMINISTRATIVE_ORGANISM_REQUEST';
export const LIST_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS = 'LIST_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS';
export const LIST_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE = 'LIST_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE';

export const SET_ADMINISTRATIVE_ORGANISM_TO_DETAIL = 'SET_ADMINISTRATIVE_ORGANISM_TO_DETAIL';
export const CLEAR_LIST_ADMINISTRATIVE_ORGANISM = 'CLEAR_LIST_ADMINISTRATIVE_ORGANISM';

// Adminstrative Organism List One
export const LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST = 'LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST';
export const LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS = 'LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS';
export const LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE = 'LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE';

// ADMINISTRATIVE_ORGANISM New
export const NEW_ADMINISTRATIVE_ORGANISM_REQUEST = 'NEW_ADMINISTRATIVE_ORGANISM_REQUEST';
export const NEW_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS = 'NEW_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS';
export const NEW_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE = 'NEW_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE';

// ADMINISTRATIVE_ORGANISM Edit
export const EDIT_ADMINISTRATIVE_ORGANISM_REQUEST = 'EDIT_ADMINISTRATIVE_ORGANISM_REQUEST';
export const EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS = 'EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS';
export const EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE = 'EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE';
export const SET_ADMINISTRATIVE_ORGANISM_TO_EDIT = 'SET_ADMINISTRATIVE_ORGANISM_TO_EDIT';

// ADMINISTRATIVE_ORGANISM Delete
export const DELETE_ADMINISTRATIVE_ORGANISM_REQUEST = 'DELETE_ADMINISTRATIVE_ORGANISM_REQUEST';
export const DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS = 'DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS';
export const DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE = 'DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE';

// ADMINISTRATIVE_ORGANISM Classifier
export const CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST = 'CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST';
export const CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS = 'CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS';
export const CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE = 'DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE';

// Service Administrative manager Organism   
export const GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST = 'GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST';
export const GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_SUCCESS = 'GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_SUCCESS';
export const GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_FAILURE = 'GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_FAILURE';
export const CLEAR_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_DATA = 'CLEAR_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_DATA';
