import * as SERVICES from 'src/services/captionServices';
import * as ACTIONS from './captionActions';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

import { push } from 'connected-react-router';

//Caption
export const tryGetCaptionList = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );
		if(accessToken){
			const page = params?.page || 0;
			const pageSize = params?.size || 10;
			
			let paramsToSend = {
				...params,
				page,
				pageSize
			};
			
			dispatch(ACTIONS.getCaptionListRequest());
			const response = await SERVICES.listCaption( accessToken, paramsToSend );
			
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getCaptionListRequestSuccess(response?.data) );
				return response?.data;
			}
			else if ( response?.status == 204 ) {
				dispatch( ACTIONS.getCaptionListRequestFailure(response?.response?.data || response) );
				showWarning(`No se encontraron leyendas.` );
			}
			else {
				dispatch( ACTIONS.getCaptionListRequestFailure(response?.response?.data || response) );
				showError(`Error al cargar las leyendas. Detalle: ${response?.response?.data?.message || response}` );
			}
		}

	};
};

//Add New Caption
export const tryAddNewCaption = (params) => {
	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.addCaptionRequest());
		
		if (accessToken) {

			const response = await SERVICES.createCaption(accessToken, params);
			
			if (response?.status == 201) {
				dispatch(ACTIONS.addCaptionRequestSuccess(response?.data));
				showSuccess('Se agrego correctamente la leyenda');
				dispatch(push(ROUTES.CAPTION_LIST));
			}
			else {
				dispatch(ACTIONS.addCaptionRequestFailure(response?.data?.error));
				showError(`Error al crear la leyenda. Detalles: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};

//Delete Caption
export const tryDeleteCaption = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.deleteCaptionRequest());

		if (accessToken) {

			const response = await SERVICES.deleteCaption(accessToken, id);

			if (response?.status == 204) {
				dispatch(ACTIONS.deleteCaptionRequestSuccess(response?.data));
				showSuccess('Leyenda eliminada correctamente.');
			}
			else {
				dispatch(ACTIONS.deleteCaptionRequestFailure(response?.error || response));
				showError(`Error al eliminar la leyenda. Detalle: ${response?.data?.message || response}`);
			}
			return response;
		}

	};
};

//Edit Caption
export const tryEditCaption = captionData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editCaptionRequest());

		if (accessToken) {

			const response = await SERVICES.editCaption(accessToken, captionData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editCaptionRequestSuccess(response?.data));
				showSuccess('Leyenda editada correctamente.');
				dispatch(push(ROUTES.CAPTION_LIST));
			}
			else {
				dispatch(ACTIONS.editCaptionRequestFailure(response?.error || response));
				showError(`Error al editar la leyenda. Detalle: ${response?.response?.data?.message || response?.error}`);
			}
			return response;
		}

	};

};

// Try Get caption by ID
export const tryGetCaptionById = (captionId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getCaptionDetailRequest());

		if (accessToken) {

			const response = await SERVICES.getCaptionById(accessToken, captionId);

			if (response?.status === 200) {
				dispatch(ACTIONS.getCaptionDetailRequestSuccess(response?.data));
				return response;
			}
			else {
				dispatch(ACTIONS.getCaptionDetailRequestFailure(response?.error || response));
				showError(`Error al cargar la leyenda por ID. Detalle: ${response?.error || response}`);
			}
		}

	};
};
