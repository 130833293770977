import * as ACTION_TYPES from './wageTemplateTypes';


// GET Wage Template list actions
export const getWageTemplateListRequest = () => ({
	type: ACTION_TYPES.GET_WAGE_TEMPLATE_REQUEST
});
export const getWagesTemplateListRequestSuccess = data => ({
	type: ACTION_TYPES.GET_WAGE_TEMPLATE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getWagesTemplateListRequestFailure = error => ({
	type: ACTION_TYPES.GET_WAGE_TEMPLATE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearWagesTemplateListData = () => ({
	type: ACTION_TYPES.CLEAR_WAGE_TEMPLATE_REQUEST
})

// POST Wage Template New actions
export const postWagesTemplateRequest = () => ({
	type: ACTION_TYPES.POST_WAGE_TEMPLATE_REQUEST
});
export const postWagesTemplateRequestSuccess = data => ({
	type: ACTION_TYPES.POST_WAGE_TEMPLATE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const postWageTemplateRequestFailure = error => ({
	type: ACTION_TYPES.POST_WAGE_TEMPLATE_REQUEST_FAILURE,
	payload: {
		error
	}
});

// PUT Wage Template New actions
export const putWagesTemplateRequest = () => ({
	type: ACTION_TYPES.PUT_WAGE_TEMPLATE_REQUEST
});
export const putWagesTemplateRequestSuccess = data => ({
	type: ACTION_TYPES.PUT_WAGE_TEMPLATE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const putWageTemplateRequestFailure = error => ({
	type: ACTION_TYPES.PUT_WAGE_TEMPLATE_REQUEST_FAILURE,
	payload: {
		error
	}
});

// DELETE Wage Template
export const deleteWagesTemplateRequest = () => ({
    type: ACTION_TYPES.DELETE_WAGE_TEMPLATE_REQUEST
});
export const deleteWagesTemplateRequestSuccess = data => ({
    type: ACTION_TYPES.DELETE_WAGE_TEMPLATE_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const deleteWagesTemplateRequestFailure = error => ({
    type: ACTION_TYPES.DELETE_WAGE_TEMPLATE_REQUEST_FAILURE,
    payload: {
        error
    }
});
export const clearWagesTemplateRequestListData = () => ({
    type: ACTION_TYPES.CLEAR_DELETE_WAGE_TEMPLATE_REQUEST
});


// SET Wage Template Items
export const setWageTemplateItems = data => ({
	type: ACTION_TYPES.SELECTED_WAGE_TEMPLATE,
	payload: {
		data
	}
});
export const deletedWageTemplateItems = data => ({
	type: ACTION_TYPES.DELETED_WAGE_TEMPLATE,
	payload: {
		data
	}
});
export const clearWageTemplateItems = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_WAGE_TEMPLATE
});

// TO POST Wage Template
export const setWageTemplateToEdit = data => ({
	type: ACTION_TYPES.SET_WAGE_TEMPLATE_TO_EDIT,
	payload: {
		data
	}
});
export const clearWageTemplateToEdit = () => ({
	type: ACTION_TYPES.CLEAR_WAGE_TEMPLATE_TO_EDIT
});

// Wage Template to Detail
export const setWageTemplateDetail = data => ({
	type: ACTION_TYPES.SET_WAGE_TEMPLATE_DETAIL,
	payload: {
		data
	}
});
export const clearWageTemplateDetail = () => ({
	type: ACTION_TYPES.CLEAR_WAGE_TEMPLATE_DETAIL
});

//WageTemplate list Recruitment method
export const getRecruitmentMethod = () => ({
	type: ACTION_TYPES.LIST_RECRUITMENT_METHOD_REQUESTS
});

export const getRecruitmentMethodSuccess = data => ({
	type: ACTION_TYPES.LIST_RECRUITMENT_METHOD_REQUESTS_SUCCESS,
	payload: {
		data
	}
});

export const getRecruitmentMethodFailure = error => ({
	type: ACTION_TYPES.LIST_RECRUITMENT_METHOD_REQUESTS_FAILURE,
	payload: {
		error
	}
});

export const setRecruitmentMethod = data => ({
	type: ACTION_TYPES.SET_LIST_RECRUITMENT_METHOD_REQUEST,
	payload: {
		data
	}
});

export const clearStateListFundRequests = () => ({
	type: ACTION_TYPES.CLEAR_STATES_LIST_RECRUITMENT_METHOD_REQUESTS,
});
//END WageTemplate list Recruitment method
