import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { push } from 'connected-react-router';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import { Table, Button, Form, Row, Alert, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEdit, faTrash, faExclamationTriangle, faFilter, faBroom , faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import PreLoadExecutionModal from 'src/components/pages/CreditExecution/PreLoadExecutionModal';
import { formatterPeso, mFormat, clearWhiteSpaces } from 'src/utils/utils';
import RemovePreloadModal from 'src/components/pages/CreditExecution/RemovePreloadModal';
import PreLoadEditModal from 'src/components/pages/CreditExecution/PreLoadEditModal';
import { tryGetFilePreload, tryGetPreloadData, tryGetPreloadPDF } from 'src/redux/preload/preloadActionCreator';
import { getPreloadData, getPreloadIsFetching, getPreloadXLSIsFetching, getPreloadXLSData } from 'src/redux/preload/preloadReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { clearPreloadData, clearPreloadXLS } from 'src/redux/preload/preloadActions';
import { getClearObject } from 'src/utils/utils';
import AppLoading from 'src/components/common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';
import { isNotEmptyArray } from 'src/services/validationService.js';

const PreLoadExecutionTable = (props) => {

	const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useFormContext();
    const [ expedientSelected, setExpedientSelected ] = useState('');
    const [affectationsSelect, setAffectationsSelect] = useState({
        expedient: {},
        subCodePreload: []
    });

    const preloadMetaData = useSelector(state => getPreloadData(state));
    const preloadData = useSelector(state => getPreloadData(state))?.data;
    const preloadIsFetching = useSelector(state => getPreloadIsFetching(state));

    const preloadXLSData = useSelector(state => getPreloadXLSData(state));
    const preloadDownloadIsFetching = useSelector(state => getPreloadXLSIsFetching(state));

    const totalItemsCount = preloadMetaData?.totalCount ? preloadMetaData?.totalCount : 1;
    const totalItems = preloadMetaData?.totalCount ? preloadMetaData?.totalCount : '0';

    //Selected period
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const periodId = selectedPeriod?.id;

    //Comportamiento de tabla
    const [flag, setFlag] = useState({
        idActive: null
    });

    const handleChangeFlag = (index) => {
        flag.idActive === index ?  setFlag({ idActive: null }) : setFlag({ idActive: index });
    };

    const [flagAllSelected, setFlagAllSelected] = useState({
        idActive: null
    });

    const handleItemsCheckBox = (subCodeItem, expedient) => {

        setFlagAllSelected({
            idActive: null
        });
        setExpedientSelected('');

        if(affectationsSelect?.subCodePreload?.some(obj => obj.id === subCodeItem.id)){
            setAffectationsSelect({
                expedient,
                subCodePreload: affectationsSelect?.subCodePreload?.filter(obj => obj.id !== subCodeItem.id)
            });
        }else{
            setAffectationsSelect({
                expedient,
                subCodePreload: expedient?.completeLabel !== affectationsSelect?.expedient?.completeLabel ?
                    [subCodeItem] : [...affectationsSelect.subCodePreload, subCodeItem]
            });
        }
    };

    const selectAllByExpedient = (expedient) => {

        const expedientId = expedient?.id;

        if(expedientSelected && expedientId == expedientSelected){
            setExpedientSelected('');
            setAffectationsSelect({
                expedient: {},
                subCodePreload: []
            });
            setFlagAllSelected({
                idActive: null
            })
        } else {
            setExpedientSelected(expedientId);
            setAffectationsSelect({
                expedient,
                subCodePreload: expedient?.subCodePreload
            });
            setFlagAllSelected({
                idActive: expedientId
            })
        };
    };

    const disableSelectAllPreloads = (item) => {    
        const preloads = item?.subCodePreload;
        return preloads?.some(item => item?.legalInstrumentNumber || item?.subCodeDetail?.accountNumber);
    };

    const handleCheckCondition = (item) => {
        return affectationsSelect?.subCodePreload?.some(obj => obj.id === item.id);
    };

    //Modal
    const [ dataModal, setDataModal ] = useState();
    const [ show, setShow ] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const onClickModalCredit = () => {
        setDataModal(affectationsSelect);
		handleShow(true);
	};

    // Remove preload modal
    const [ dataRemovePreload, setDataRemovePreload] = useState('');
    const [ showRemovePreloadModal, setShowRemovePreloadModal ] = useState(false);
    const handleShowRemovePreloadModal = () => setShowRemovePreloadModal(true);
    const handleCloseRemovePreloadModal = () => setShowRemovePreloadModal(false);

    const onClickRemovePreloadModal = (subCodeData, expedientData) => {
        setDataRemovePreload({
            subCodeData,
            expedientData
        });
		handleShowRemovePreloadModal();
	};

    // Preload edit modal
    const [ dataPreloadEditModal, setDataPreloadEditModal] = useState('');
    const [ showPreloadEditModal, setShowPreloadEditModal ] = useState(false);
    const handleShowPreloadEditModal = () => setShowPreloadEditModal(true);
    const handleClosePreloadEditModal = () => setShowPreloadEditModal(false);

    const onClickPreloadEditModal = (subCodeData, expedientData) => {
        setDataPreloadEditModal({
            subCodeData,
            expedientData
        });
        handleShowPreloadEditModal(true);
    }
    // End preload edit modal

    const toolTipTextMessage = (intrLegalId, accountNumber, type) =>{
        return intrLegalId || accountNumber ? `Instrumento legal asociado Nro ${intrLegalId ? intrLegalId : ""}` : `${type} Precarga`;
    };

    const defaultParams = {
        page: 1,
        pageSize: 10,
        filter: { 
            "subCodePreloadWithoutConversion" : 1,
            "period_id": periodId
        }
    };

     const fetchList = (params) => {
        dispatch(clearPreloadData());
        dispatch(clearPreloadXLS());
        dispatch(tryGetPreloadData(params));
        setFlag({ idActive: null });
    };

    // Funcion para descargar PDF de precarga por expediente
    const downloadPDF = (item) => {
        const params = {
            number: item?.number,
            organismCode: item?.organizationCode,
            year: item?.year,
            periodId: periodId
        }
        dispatch(tryGetPreloadPDF(params));
    };

    const loadDefaultData = () => {
        reset();
        props.setFilterObject();
		fetchList({
            ...defaultParams,
            filter: {
                [attributeFilter]: 1,
                "period_id": periodId,
                includeRPTarget: false
            }
        });
    };

    const attributeFilter = props.checkedPreload ? "hasSubCodePreload" : "subCodePreloadWithoutConversion";

    const onSubmitForm = (data) => {
        const allowToClearWhiteSpaces = ['completeLabel', 'subCodeCompleteLabel']
        const paramsToSend = clearWhiteSpaces(getClearObject(data), allowToClearWhiteSpaces);
        const clearParamsToSend = getClearObject(paramsToSend);
        props.setFilterObject(clearParamsToSend);
        fetchList({
            ...defaultParams,
            filter: {
                ...clearParamsToSend, 
                [attributeFilter]: 1,
                "period_id": periodId
            }
        });

    };

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            filter: {
                ...props.filterObject,
                [attributeFilter]: 1,
                "period_id": periodId
            },
            page: pNumber,
        }

        fetchList(params);
    };

    const exportXLSHandler = () =>{
        const params = {
            ...props.filterObject,
            [attributeFilter]: 1,
            outputFormat: 2,
            periodId: periodId
        }
       dispatch(tryGetFilePreload(params));
    };

    useEffect(() => {
        loadDefaultData();
    }, [])

    return <>
            <Button
                className='text-white-color float-right mb-3'
                type='button'
                variant='secondary'
                size='sm'
                disabled={!isNotEmptyArray(preloadData) || preloadDownloadIsFetching}
                onClick={() =>exportXLSHandler()}
            >
                <FontAwesomeIcon icon={faFileExcel} className='mr-2' />
                Exportar resultado a XLS
            </Button>
        <div className='mt-4'>
            <Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
                <Table bordered hover size='sm'>
                    <thead>
                        <tr>
                            <th className='text-center align-middle' width='10%'>Nro de Expediente</th>
                            <th className='text-center align-middle' width='20%'>Nro de Partida</th>
                            <th className='text-center align-middle' width='10%'>Fecha Precarga</th>
                            <th className='text-center align-middle' width='20%'>Asunto</th>
                            <th className='text-center align-middle' width='10%'>Importe Modificado</th>
                            <th className='text-center align-middle' width='10%'>Nro Asiento</th>
                            <th className='text-center align-middle' width='10%'>Nro Instr. Legal</th>
                            <th className='text-center align-middle' width='10%'>Acciones</th>
                        </tr>
                        <tr className='secondary'>
                            <th className='text-center'>
                                <Form.Control
                                    className='text-center text-black-color'
                                    name='completeLabel'
                                    size='sm'
                                    ref={register}
                                />
                            </th>
                            <th className='text-center'>
                                <Form.Control
                                    className='text-center text-black-color'
                                    name='subCodeCompleteLabel'
                                    size='sm'
                                    ref={register}
                                />
                            </th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'></th>
                            <th className='text-center align-middle'>
                                <ActionIcon
                                    size="lg"
                                    id="search-button"
                                    // className="btn-primary search-button text-white-color"
                                    className="mr-2 text-white-color"
                                    toolTipText="Filtrar"
                                    icon={faFilter}
                                    type='submit'
                                />
                                <ActionIcon
                                    size="lg"
                                    id="clean-filter"
                                    // className="btn-primary clean-filter text-white-color"
                                    // className="btn-primary clean-filter text-white-color"
                                    className="text-white-color"
                                    toolTipText="Limpiar filtros"
                                    icon={faBroom}
                                    type='reset'
                                    onClick={()=> loadDefaultData()}
                                />
                                {/* <div className='d-flex justify-content-around'>
                                </div> */}
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-black-color'>
                        { preloadData?.length ?
                            preloadData?.map( (item, index) => {
                                return <>
                                    <tr key={index}>
                                        <td className='text-center align-middle'>{ item?.completeLabel }</td>
                                        <td className='text-center align-middle'></td>
                                        <td className='text-center align-middle'></td>
                                        <td className='text-center align-middle'>{item?.title}</td>
                                        <td className='text-right align-middle'>{formatterPeso.format(item?.totalPreLoads)}</td>
                                        <td className='text-center align-middle'></td>
                                        <td className='text-center align-middle'></td>
                                        <td className='text-center align-middle mx-0 px-0'>
                                            <Row>
                                                <Col className='d-flex justify-content-around align-middle'>
                                                    <Form.Check
                                                        className ='text-center align-self-center expedient-table-check'
                                                        name="selectedExpedient"
                                                        title='Seleccionar expediente'
                                                        toolTipText='Seleccionar expediente'
                                                        onClick={() => selectAllByExpedient(item)}
                                                        checked={(flagAllSelected?.idActive == item?.id && !disableSelectAllPreloads(item)) ? true : false}
                                                        disabled={disableSelectAllPreloads(item)}
                                                    />                       
                                                </Col>
                                                <Col className='d-flex justify-content-around align-middle'>
                                                    <ActionIcon
                                                        size="lg"
                                                        className="text-black-color"
                                                        toolTipText="Descargar PDF"
                                                        icon={faFilePdf}
                                                        onClick={() => downloadPDF(item)}
                                                    />  
                                                </Col>                                                
                                                <Col className='d-flex justify-content-around align-middle'>
                                                    <ActionIcon
                                                        size="lg"
                                                        className="text-black-color"
                                                        toolTipText={flag.idActive == index ? "Contraer expediente" : "Expandir expediente" }
                                                        icon={flag.idActive == index ? faAngleUp : faAngleDown }
                                                        onClick={ ()=> handleChangeFlag(index) }
                                                    />
                                                </Col>
                                            </Row>
                                        </td>
                                    </tr>
                                    {flag.idActive === index &&
                                        item?.subCodePreload?.map( (subCodeItem, indexM) =>
                                            <tr key={ indexM }>
                                                <td className='text-center align-middle'></td>
                                                <td className='text-center'>{subCodeItem?.subCode?.completeNumber}</td>
                                                <td className='text-center align-middle'>{mFormat(subCodeItem?.created_at)}</td>
                                                <td className='text-center align-middle'></td>
                                                <td className='text-right align-middle'>{formatterPeso.format(subCodeItem?.amount)}</td>
                                                <td className='text-center align-middle'>{subCodeItem?.subCodeDetail?.accountNumber}</td>
                                                <td className='text-center align-middle'>{subCodeItem?.legalInstrumentNumber}</td>
                                                <td className='text-center align-middle'>
                                                    <div className='d-flex justify-content-around align-middle'>
                                                        <Form.Check
                                                            className ='text-center align-self-center expedient-table-check'
                                                            name="selectedExpedient"
                                                            disabled={subCodeItem?.legalInstrumentNumber || subCodeItem?.subCodeDetail?.accountNumber}
                                                            onClick={() => handleItemsCheckBox(subCodeItem, item)}
                                                            checked={handleCheckCondition(subCodeItem)}
                                                        />
                                                        <ActionIcon
                                                            size='lg'
                                                            className ={`pb-1 ${(subCodeItem?.legalInstrumentNumber || subCodeItem?.subCodeDetail?.accountNumber) && 'not-allowed'}`}
                                                            toolTipText={toolTipTextMessage(subCodeItem?.legalInstrumentNumber, subCodeItem?.subCodeDetail?.accountNumber,'Editar')}
                                                            icon={faEdit}
                                                            onClick={ () => (!subCodeItem?.legalInstrumentNumber || !subCodeItem?.subCodeDetail?.accountNumber) && onClickPreloadEditModal(subCodeItem, item)}
                                                        />
                                                        <ActionIcon
                                                            size='lg'
                                                            className ={`pb-1 ${(subCodeItem?.legalInstrumentNumber || subCodeItem?.subCodeDetail?.accountNumber) && 'not-allowed'}`}
                                                            toolTipText={toolTipTextMessage(subCodeItem?.legalInstrumentNumber, subCodeItem?.subCodeDetail?.accountNumber,'Eliminar')}
                                                            icon={faTrash} 
                                                            onClick={ () => (!subCodeItem?.legalInstrumentNumber || !subCodeItem?.subCodeDetail?.accountNumber) && onClickRemovePreloadModal(subCodeItem, item)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </>
                            }):
                            <tr hidden={preloadIsFetching}>
                                <td colSpan='8'>
                                    <Row className='d-flex justify-content-center'>
                                        <Alert variant='info' className='d-flex w-100 justify-content-center mb-0 mx-3'>
                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                            Sin precargas para mostrar
                                        </Alert>
                                    </Row>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <RCPagination
                    activePage={preloadMetaData?.page}
                    itemsCountPerPage={preloadMetaData?.perPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={7}
                    thePage={preloadMetaData?.page}
                    totalItems={totalItems}
                    innerClass="justify-content-center"
                    onChange={onPageChange}
                />
                <div className='d-flex justify-content-end'>
                    <Button
                        variant='success'
                        size='lg'
                        onClick={ () => onClickModalCredit() }
                        disabled={ !affectationsSelect?.subCodePreload?.length }
                    >
                        Cargar Instrumento Legal
                    </Button>
                </div>
            </Form>
            {
				preloadXLSData && !preloadDownloadIsFetching &&
                    <Alert key={'1'} variant={'primary'} className='mt-4'>
                    <b className='text-black-color' >Aviso:</b> El reporte se esta generando en segundo plano y estará disponible en el módulo de <a
                        className='font-italic text-info cursor-pointer'
                        onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
                        reportes generados.
                    </a>
                    </Alert>
			}
            <PreLoadExecutionModal
                show = {show}
                handleClose = {handleClose}
                dataModal = {dataModal}
            />
            <RemovePreloadModal 
                show = { showRemovePreloadModal }
                handleClose = { handleCloseRemovePreloadModal }
                dataModal = { dataRemovePreload }
                filterObject = { props.filterObject }
                defaultParams = {defaultParams}
                affectationsSelect = {affectationsSelect}
                setAffectationsSelect = {setAffectationsSelect}
            />
            <PreLoadEditModal 
                show={showPreloadEditModal}
                handleClose = {handleClosePreloadEditModal}
                dataModal = {dataPreloadEditModal}
                filterObject = { props.filterObject }
                defaultParams = {defaultParams}
                affectationsSelect = {affectationsSelect}
                setAffectationsSelect = {setAffectationsSelect}
            />
            <AppLoading isLoading={preloadIsFetching || preloadDownloadIsFetching} />
        </div>
    </>
}

export default PreLoadExecutionTable;
