import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Row, Image, Card, Form, Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { config } from 'src/env.js';

import logo from 'src/assets/images/logo_gobierno_misiones.png';
import logoContaduria from 'src/assets/images/logo_contaduria.png';
import LoggedOutCard from 'src/components/general/LoggedOutCard';
import FormFieldError from 'src/components/general/FormFieldError';
import { trySendResetPassword } from 'src/redux/login/loginActionCreator';
import { getResetPasswordIsFetching } from 'src/redux/login/loginReducer';
import { validatePasswordFormat, PASSWORD_INVALID_MSG } from 'src/services/validationService';
import { RESET_PASSWORD_URI, LOGIN_URI } from 'src/utils/constants';
import { restartLog, send, confirNewPassword, newPassword, createNewPassword, passwordConfirmSameMsg, passwordConfirmRequiredMsg } from 'src/utils/label';

const ResetPasswordPage = props => {

	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues } = useForm();

	const isFetching = useSelector(state => getResetPasswordIsFetching(state));

	const samePasswordValidation = value => (value === getValues('password') ? undefined : passwordConfirmSameMsg);
	const passwordConfirmValidation = { required: passwordConfirmRequiredMsg, validate: samePasswordValidation };
	const passwordValidation = { validate: value => (validatePasswordFormat(value) ? undefined : PASSWORD_INVALID_MSG) };

	const onClickGoToLoginPage = event => {
		event.preventDefault();
		dispatch(push(LOGIN_URI));
	};

	const onFormSubmit = data => {
		const token = window.location.pathname.replace(`${RESET_PASSWORD_URI}/`, '');
		dispatch(trySendResetPassword(data.password, token));
	};

	const cardBody = <>
		<Row className='h-100 justify-content-center align-items-center my-1'>
			<div className ='col-md-6 my-2'>
				<Image className='px-5' src={logo} fluid />
			</div>
			<div className ='col-md-6 px-5 my-2'>
				<Image className='px-5' src={logoContaduria} fluid />
			</div>
			
		</Row>

		<Card.Title className='text-center mb-4 mt-1 text-main-color texto-login'>
			<span className='h4 text-main-color'>
				{config.appSettings.name}
			</span>
		</Card.Title>

		<hr />

		<h3 className='text-black-color text-center mb-3 texto-login-d'>
			{createNewPassword}
		</h3>

		<Form onSubmit={handleSubmit(onFormSubmit)}>
			<Form.Group className='mx-3' controlId='passwordControl'>
				<Form.Label size='lg' className='text-primary'>
					{newPassword}
				</Form.Label>
				<Form.Control ref={register(passwordValidation)} size='lg' type='password' placeholder='•••••••' name='password' id='password' className='placeholderOpacity' />
				<FormFieldError errors={errors.password} />
			</Form.Group>
			<Form.Group className='mx-3' controlId='passwordControl'>
				<Form.Label size='lg' className='text-primary'>
					{confirNewPassword}
				</Form.Label>
				<Form.Control ref={register(passwordConfirmValidation)} size='lg' type='password' placeholder='•••••••' name='passwordConfirm' id='passwordConfirm' className='placeholderOpacity' />
				<FormFieldError errors={errors.passwordConfirm} />
			</Form.Group>
			<Form.Group className='mx-3'>
				<Button size='lg' className='w-100 mt-3 btn-primary' type='submit' disabled={isFetching}>
					{
						isFetching ?
							<Spinner animation='border' />
							:
							send
					}
				</Button>
			</Form.Group>
		</Form>

		<div className='text-center'>
			<a href='#' onClick={onClickGoToLoginPage}>
				{restartLog}
			</a>
		</div>
	</>;

	return <LoggedOutCard cardContent={cardBody} />
};

export default ResetPasswordPage;