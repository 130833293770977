import React from 'react';
import { Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals } from '@fortawesome/free-solid-svg-icons';

import { formatterPeso, numberNegativeRed } from 'src/utils/utils';

const CreditExecutionBalanceDetailComponent = props => {

	// Credit details
	const subcodeData = props?.data;

	const currentCredit = subcodeData?.credit?.currentCredit;
	const accumulatorPreventive = subcodeData?.balance?.totals?.preventive;
	const accumulatorDefinitive = subcodeData?.balance?.totals?.definitive;
	const accumulatorPaymentObligation = subcodeData?.balance?.totals?.payment_obligation;
	const accumulatorPayment = subcodeData?.balance?.totals?.payment;

	// Calculator
	//Crédito actual - Acumulador Preventiva = Saldo Preventiva
	const balancePreventive = currentCredit - accumulatorPreventive;
	const balancePreventiveFormatter = formatterPeso.format(balancePreventive);

	// Acumulador Preventiva - Acumulador Definitiva = Saldo Definitiva
	const balanceDefinitive = accumulatorPreventive - accumulatorDefinitive;
	const balanceDefinitiveFormatter = formatterPeso.format(balanceDefinitive);

	//Acumulador Definitiva - Acumulador obl. de pago = Saldo obl. de pago
	const balancePaymentObligation = accumulatorDefinitive - accumulatorPaymentObligation;
	const balancePaymentObligationFormatter = formatterPeso.format(balancePaymentObligation);

	//Acumulador obl. de pago - Acumulador pago = Saldo pago
	const balancePayment = accumulatorPaymentObligation - accumulatorPayment;
	const balancePaymentFormatter = formatterPeso.format(balancePayment);
	// End Calculator

	// Amount Credit
	const currentCreditAmountFormatter = formatterPeso.format(currentCredit);

	const totalsPreventiveCreditAmountFormatter = formatterPeso.format(accumulatorPreventive);
	const totalsDefinitiveCreditAmountFormatter = formatterPeso.format(accumulatorDefinitive);
	const totalsPaymentObligationCreditAmountFormatter = formatterPeso.format(accumulatorPaymentObligation);
	const totalsPaymentCreditAmountFormatter = formatterPeso.format(accumulatorPayment);

	return <Container fluid>
		<Card>
			<Card.Header>
				<h5 className='text-center mb-0'>{props?.title}</h5>
			</Card.Header>
			<Card.Body>
				<Table striped borderless hover >
					<tbody>
						<tr>
							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
									<Form.Label className="text-black-color" column sm="6">
										Crédito actual:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(currentCredit)} text-right px-0 py-1`}>
											{currentCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faMinus} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumulatorPreventive">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador Preventiva:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorPreventive)} text-right px-0 py-1`}>
											{totalsPreventiveCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faEquals} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="preventiveBalance">
									<Form.Label className="text-black-color" column sm="6">
										Saldo Preventiva:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(balancePreventive)} text-right pr-2 py-1`}>
											{balancePreventiveFormatter}
										</span>
									</div>
								</Form.Group>
							</td>
						</tr>

						<tr>
							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumaltorPreventive2">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador Preventiva:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorPreventive)} text-right px-0 py-1`}>
											{totalsPreventiveCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faMinus} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador Definitiva:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorDefinitive)} text-right px-0 py-1`}>
											{totalsDefinitiveCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faEquals} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="definitiveBalance">
									<Form.Label className="text-black-color" column sm="6">
										Saldo Definitiva:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(balanceDefinitive)} text-right pr-2 py-1`}>
											{balanceDefinitiveFormatter}
										</span>
									</div>
								</Form.Group>
							</td>
						</tr>

						<tr>
							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive2">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador Definitiva:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorDefinitive)} text-right px-0 py-1`}>
											{totalsDefinitiveCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faMinus} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador Obl. Pago:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorPaymentObligation)} text-right px-0 py-1`}>
											{totalsPaymentObligationCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faEquals} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="balancePaymentObligation">
									<Form.Label className="text-black-color" column sm="6">
										Saldo Obl. Pago:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(balancePaymentObligation)} text-right pr-2 py-1`}>
											{balancePaymentObligationFormatter}
										</span>
									</div>
								</Form.Group>
							</td>
						</tr>

						<tr>
							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation3">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador Obl. Pago:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorPaymentObligation)} text-right px-0 py-1`}>
											{totalsPaymentObligationCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faMinus} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="accumulatorPayment">
									<Form.Label className="text-black-color" column sm="6">
										Acumulador pago:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(accumulatorPayment)} text-right px-0 py-1`}>
											{totalsPaymentCreditAmountFormatter}
										</span>
									</div>
								</Form.Group>
							</td>

							<td colSpan="1" className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center py-1'>
								<FontAwesomeIcon icon={faEquals} size='xl' />
							</td>

							<td colSpan="3" className='text-right text-black-color py-1 pl-1'>
								<Form.Group className='mb-0' as={Row} controlId="paymentBalance">
									<Form.Label className="text-black-color" column sm="6">
										Saldo pago:
									</Form.Label>
									<div className='ml-auto py-1'>
										<span className={`${numberNegativeRed(balancePayment)} text-right pr-2 py-1`}>
											{balancePaymentFormatter}
										</span>
									</div>
								</Form.Group>
							</td>
						</tr>
					</tbody>
				</Table>
			</Card.Body>
		</Card>
	</Container>;
};

export default CreditExecutionBalanceDetailComponent;