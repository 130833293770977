import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { Form, Button, Table, Row, Col, Alert, Modal, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faExclamationTriangle, faPlus, faTimes, faBell, faBan, faBroom, faFilter, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import { tryGetAdministrativeDocumentByOrderPay } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { PartidaPresupuestariaRequired, selectOption } from 'src/utils/label';
import ActionIcon from 'src/components/general/ActionIcon';
import { buildBudgetNumber, buildAmount, formatterPeso, getClearObjectAlt } from 'src/utils/utils';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { tryListAffectationsByOrderType } from 'src/redux/orderPay/orderPayActionCreator';
import { getGlobalDataSelectedPeriod, getGlobalDataPeriodsData } from 'src/redux/globalData/globalDataReducer';
import { dataPeriodsContructor } from 'src/utils/utils';
import { useForm } from 'react-hook-form';
import { DropdownList } from 'react-widgets';

const ExpedienteListBox = props => {
	const dispatch = useDispatch();

	const { expedientesAsociados, setExpedientesAsociados, expedienteListParams, responseData, disableAdmExpedientSearch, showSubpartItemSelector } = props;

	//Period
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const periodsData = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );

	const { errors, orderPay, expedientesSeleccionados, setExpedientesSeleccionados, setImporte, orderType, admServiceId } = useFormContext();
	const { register } = useForm();
	const [activePayOrder, setActivePayOrder] = useState();
    const [showPayOrderDetailModal, setShowPayOrderDetailModal] = useState(false);
	const [expedienteList, setExpedienteList] = useState(expedienteListParams);
	const [budgetType, setBudgetType] = useState({});
	const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
	const {selectedOrderPay} = useSelector(state => state.orderPayReducer);

	const OPHType = orderType == 'ORDEN_DE_PAGO_DE_HABERES';

	const budgetRP = budgetType?.period_rp_id || budgetType?.isPassiveRemaining;

	const [hasMoreItems, setHasMoreItems] = useState(true);

	const [jurisdiccionSeleccionada, setJurisdiccionSeleccionada] = useState();
	const [servicioSeleccionado, setServicioSeleccionado] = useState();
	const [caracterSeleccionado, setcaracterSeleccionado] = useState();

	const [pageNumber, setPageNumber] = useState(1);
	const inputRef = React.createRef();
	const [isFetching, setIsFetching] = useState(false);
	const payOrderValidationObj = { required: PartidaPresupuestariaRequired };
	const cols = ["Serv.", "Jurisd.", "U. Org.", "Carácter", "Cuenta", "Finalidad", "Función", "Sección", "Sector", "P. Princ.", "P. Parc.", "Código", "Subcódigo"];

	const formDataInitialState = {
		jurisdiccion: '',
		unidadDeOrganizacion: '',
		caracter: '',
		cuenta: '',
		finalidad: '',
		funcion: '',
		seccion: '',
		sector: '',
		partidaPrincipal: '',
		partidaParcial: '',
		codigo: '',
		subCodigo: '',
		visible_name: ''
	};

	const [formData, setFormData] = useState(formDataInitialState);

	// Messages
	const dropdownPlaceholder = selectOption;
	const msgDifferentRelationships = 'No puede afectar partidas con servicio, jurisdicción o carácter diferentes';
	const msgAssociatedBudgetItems = 'Partida ya asociada, ingrese un monto en la tabla de Partidas Presupuestarias Asociadas';
	const msgChosenValidation = 'Solo se pueden seleccionar partidas del mismo servicio, jurisdicción y carácter.'
	const subPartialBudgetValidationMessage = 'Seleccione una opción.'

	//validations objects
    const subPartialBudgetValidationObj = { required: subPartialBudgetValidationMessage };

	let total = expedientesSeleccionados.length;

	const selectedExpedientAssociated = expedientesAsociados?.filter(i => i.selected);
	const selectedAssociatedService = expedientesAsociados?.[0]?.presupuestoResponse?.subCodeCompleteNumber?.split("-")[0];
	const selectedAssociatedJurisdiction = expedientesAsociados?.[0]?.presupuestoResponse?.subCodeCompleteNumber?.split("-")[1];
	const selectedAssociatedOrgUnity = expedientesAsociados?.[0]?.presupuestoResponse?.subCodeCompleteNumber?.split("-")[2];
	const selectedAssociatedCharacter = expedientesAsociados?.[0]?.presupuestoResponse?.subCodeCompleteNumber?.split("-")[3];
	const selectedAssociatedAccount = expedientesAsociados?.[0]?.presupuestoResponse?.subCodeCompleteNumber?.split("-")[4];

	const hasDifferentCharacter = !expedienteList?.every((item) => item?.partidaData?.[3] === expedienteList?.[0]?.partidaData?.[3]);
	const disableAssociatedCheckbox = expedientesSeleccionados?.some((item) => (item?.partidaData?.[0] !== selectedAssociatedService || item?.partidaData?.[1] !== selectedAssociatedJurisdiction || item?.partidaData?.[3] !== selectedAssociatedCharacter));
	const subcodeId = disableAdmExpedientSearch ? "id" : "subCodeId";

	const disableConditional = (item) =>{
		if (OPHType && item?.amountInitial <= 0 && budgetRP) return true;
		if (isRpValidation && (item?.periodRP == null)) return true;
		if (expedientesSeleccionados?.length > 0 && !isRpValidation && (item?.periodRP != null)) return true;
		
		if((!disableAdmExpedientSearch && item?.amount <= 0) || expedientesAsociados?.some((x)=> x?.presupuestoResponse?.subCodeId === item?.[subcodeId])) return true;
		if(caracterSeleccionado && (servicioSeleccionado != item?.partidaData?.[0] || jurisdiccionSeleccionada != item?.partidaData?.[1] || caracterSeleccionado != item?.partidaData?.[3])) return true;
		if(orderPay && isNotEmptyArray(selectedExpedientAssociated) && (selectedAssociatedService != item?.partidaData?.[0] || selectedAssociatedJurisdiction != item?.partidaData?.[1] || selectedAssociatedCharacter != item?.partidaData?.[3])) return true;
		return false;
	};

	const toolTipTextCondition = (item) =>{
		if (OPHType && item?.amountInitial <= 0 && budgetRP) return "No puede afectar partidas de RP con saldo menor o igual a cero.";
		if (isRpValidation && (item?.periodRP == null)) return "No se pueden combinar distintos tipos de partidas";
		if (expedientesSeleccionados?.length > 0 && !isRpValidation && (item?.periodRP != null)) return "No se pueden combinar distintos tipos de partidas";
		if(!disableAdmExpedientSearch && item?.amount <= 0) return 'Saldo en Obligación de Pago';
		if(caracterSeleccionado && (servicioSeleccionado != item?.partidaData?.[0] || jurisdiccionSeleccionada != item?.partidaData?.[1] || caracterSeleccionado != item?.partidaData?.[3])) return msgDifferentRelationships;
		if(orderPay && isNotEmptyArray(selectedExpedientAssociated) && (selectedAssociatedService != item?.partidaData?.[0] || selectedAssociatedJurisdiction != item?.partidaData?.[1] || selectedAssociatedCharacter != item?.partidaData?.[3])) return msgDifferentRelationships;
		if(expedientesAsociados?.some((x)=> x?.presupuestoResponse?.subCodeId == item?.[subcodeId])) return msgAssociatedBudgetItems;
		return 'Seleccionar partida';
	};

	const handleModalDetallePayOrder = (payOrder) => {
		setShowPayOrderDetailModal(true);
		setActivePayOrder(payOrder);
	}

	const [isRpValidation, setIsRpValidation] = useState(false)

	// Add pay order to selection list
	const searchSubcodeSelected = subCodeToSearch => expedientesSeleccionados?.find(item => (item?.id == subCodeToSearch));
	const subcodeRecords = expedienteList?.map(subcode => { subcode.selected = searchSubcodeSelected(subcode?.id);
		return subcode;
	});

	const addPayOrderToSelectionList = payOrderParam => {
		const payOrder = JSON.parse(JSON.stringify(payOrderParam));
		if (payOrder?.periodRP != null) setIsRpValidation(true);
		if (expedientesSeleccionados.length < 1) {
			setServicioSeleccionado(payOrder?.partidaData[0])
			setJurisdiccionSeleccionada(payOrder?.partidaData[1]);
			setcaracterSeleccionado(payOrder?.partidaData[3]);
		};
		payOrder.selected = true;
		setExpedientesSeleccionados(prevArray => [...prevArray, payOrder]);		
	};

	const removePayOrderFromSelectionList = paymentOrderDto => {
		if(expedienteList.filter(item => item?.periodRP != null).length == 0) setIsRpValidation(false);
		if(isRpValidation && expedienteList.filter(item => item?.periodRP == null).length != 0) setIsRpValidation(false);

		expedienteList.forEach( i =>{
			// i.selected = false;
			if (paymentOrderDto.id == i.id){
				i.selected = false;
			}
		});
		if (expedientesSeleccionados?.length < 2) {
			setServicioSeleccionado()
			setJurisdiccionSeleccionada();
			setcaracterSeleccionado();
		}
		setExpedientesSeleccionados(expedientesSeleccionados.filter(item => item.id !== paymentOrderDto.id));
	};

	const loadMoreItems = () => {
		handlerResponsePage(pageNumber + 1);
	};
	const loadLessItems = () => {
		handlerResponsePage(pageNumber - 1);
	};

	const handlerResponsePage = (coun)=>{
		setIsFetching(true);
		let promise = dispatch(tryGetAdministrativeDocumentByOrderPay(coun, 10, administrativeDocument));
		promise.then((response)=>{
			const selectedIds = expedientesSeleccionados.map( i => i.id);
			response.data.forEach( i => {
				if ( selectedIds.includes(i.id)){
					i.selected = true;
				}
			});
			setIsFetching(false);
			setExpedienteList(calculateExp(response.data));
			setPageNumber(coun);
			setHasMoreItems(response?.hasMore);
		})
	}

	const handlerImporteChange = (inputObj, item,obj) => {
		// const totalAmounts = expedientesSeleccionados.concat(selectedExpedientAssociated)?.filter(item=> item !== undefined);
		// obj.amount = inputObj.floatValue;
		setExpedientesSeleccionados(expedientesSeleccionados.map(i => {
			if (i.id == item.id){
				i.amount = inputObj.floatValue;
			}
			return i;
		}));
	}

	const handlerAssociatedItems = (inputObj, item,obj) => {
		// handlerImporteChange(inputObj, item, obj);
		setExpedientesAsociados(expedientesAsociados.map(i => {
			if (i.id == item.id){
				i.presupuestoResponse.amount = inputObj.floatValue;
			}
			return i;
		}));
	};

	const handleAssociatedItemSubPartialBudget = (item, supartialBudget) => {
		setExpedientesAsociados(expedientesAsociados.map(i => {
			if (i.id == item.id){
				i.presupuestoResponse.subPartialBudget = supartialBudget;
			}
			return i;
		}));
	};

	const handleChangeBudgetType = (value) => {
		setFormData(formDataInitialState);
		const objToSend = (dataPeriodsContructor(periodSelected, periodsData).filter( (item) => item?.value === value))?.[0] || {};
		const cleanObject = getClearObjectAlt(objToSend);
		setBudgetType(cleanObject);
		const filter = {
			periodRpIdB: cleanObject?.period_rp_id,
			isPassiveRemaining: cleanObject?.isPassiveRemaining,
			paymentOrderType: orderType,
            servicio: admServiceId || orderPay?.administrativeServiceId,
		};
		const filterToSend = getClearObjectAlt(filter);
		dispatch(tryListAffectationsByOrderType(filterToSend));
	};

	// Subpartial budget
	const onChangeSubpartialType = (item, supartialBudget) => {
		setExpedientesSeleccionados(expedientesSeleccionados?.map(i => {
			if (i?.id == item?.id){
				i.subPartialBudgetSelected = supartialBudget;
			}
			return i;
		}));
	};

	const onKeyDownEnter = event => {
		var keycode = event.keyCode;
		if (keycode == 13) event.preventDefault();
	};

	const withValueLimit = (inputObj, amountInitial) => {
		const { value } = inputObj;
		if(!disableAdmExpedientSearch){
			if(value <= amountInitial) return inputObj
		}else{
			if(value <= 99999999999) return inputObj
		}
	};

	const associatedBudgetWithValueLimit = (inputObj, amountInitial, originalAmount) => {
		const { value } = inputObj;
		const limitValue = amountInitial + originalAmount;
		if(!disableAdmExpedientSearch){
			if(value <= limitValue) return inputObj
		}else{
			if(value <= 99999999999) return inputObj
		}
	};
	const calculateExp = list => {
		return buildAmount(buildBudgetNumber(list));
	};

	const updateAsociados = (expedienteAsociado ,isChecked) => {
		setExpedientesAsociados(expedientesAsociados.map(item => {
			if (item.id == expedienteAsociado.id){
				item.selected = isChecked;
			}
			return item;
		}));
	}

	const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    }
	const handleJurisdictionNumber = value => handleChange('jurisdiccion', value)
	const handleOrganizationNumber = value => handleChange('unidadDeOrganizacion', value)
	const handleCharacterNumber = value => handleChange('caracter', value)
	const handleAccountNumber = value => handleChange('cuenta', value)
	const handlePurposeNumber = value => handleChange('finalidad', value)
	const handleFunctionalityNumber = value => handleChange('funcion', value)
	const handleSectionNumber = value => handleChange('seccion', value)
	const handleSectorNumber = value => handleChange('sector', value)
	const handlePrincipalBudgetNumber = value => handleChange('partidaPrincipal', value)
	const handlePartialBudgetNumber = value => handleChange('partidaParcial', value)
	const handleCodeNumber = value => handleChange('codigo', value)
	const handleSubCodeNumber = value => handleChange('subCodigo', value)
	const handleVisibleName = value => handleChange('visible_name', value)

	const onSubmitExpedient = () => {
		const filterToSend = {
			paymentOrderType: "ORDEN_DE_PAGO_DE_HABERES",
			periodRpIdB: budgetType?.period_rp_id,
			isPassiveRemaining: budgetType?.isPassiveRemaining,
			servicio: admServiceId,
			...formData
		}
		const jsonFilter = getClearObjectAlt(filterToSend);
		dispatch(tryListAffectationsByOrderType(jsonFilter));
	}
	const cleanFilters = () => {
		setFormData(formDataInitialState);
		const filterToSend = {
			paymentOrderType: "ORDEN_DE_PAGO_DE_HABERES",
			periodRpIdB: budgetType?.period_rp_id,
			isPassiveRemaining: budgetType?.isPassiveRemaining,
			servicio: admServiceId
		}
		const jsonFilter = getClearObjectAlt(filterToSend);
		dispatch(tryListAffectationsByOrderType(jsonFilter));
	}

	useEffect(() => {
		if(expedienteListParams) {
			setExpedienteList(calculateExp(expedienteListParams));
		} else {
			setExpedienteList([]);
		};
	}, [expedienteListParams]);

	useEffect(() => {
		if (orderPay?.details){
			setExpedientesAsociados(orderPay.details.map(i=>{
				i.selected = true;
				i.presupuestoResponse.originalAmount = i?.presupuestoResponse?.amount;
				return i;
			}));
		};
	}, []);

	useEffect(() => {
		if(orderType != 'ORDEN_DE_PAGO_DE_HABERES'){
			setExpedientesSeleccionados([]);
		};
    }, [expedienteList]);

	useEffect(()=>{
		if(responseData) {
			setPageNumber(responseData?.page);
			setHasMoreItems(responseData?.hasMore);
		}
	}, [responseData]);
	
	useEffect(()=>{
		const totalS = expedientesSeleccionados?.reduce((acc,i)=> acc + i.amount,0);
		const totalA = expedientesAsociados?.reduce((acc,i)=> {
			return i.selected? acc + i.presupuestoResponse.amount: acc;
		},0) || 0;
		setImporte(totalS+totalA);
	}, [expedientesSeleccionados, expedientesAsociados]);
	
	useEffect(() => {
		setFormData(formDataInitialState);
		setExpedientesSeleccionados([]);
		setServicioSeleccionado()
		setJurisdiccionSeleccionada();
		setcaracterSeleccionado();
		setBudgetType({
			value: 'Partidas del ejercicio'
		});
	}, [admServiceId]);

	return <>
		{  (!!subcodeRecords?.length || orderType == 'ORDEN_DE_PAGO_DE_HABERES') && <>
			<Col className='mt-5' >
				<h6 className='mb-3'>Afectaciones definitivas del expediente</h6>
			</Col>
			{
				orderType == 'ORDEN_DE_PAGO_DE_HABERES' && 
				<Col className='col-4 px-0 d-flex align-items-center justify-content-end'>
					<Form.Control
						as="select"
						name='tipoDeEjercicio'
						className="w-100 text-black-color mb-3 mt-2"
						value={budgetType?.value}
						onChange={(event)=>handleChangeBudgetType(event.target.value)}
					>
						{dataPeriodsContructor(periodSelected, periodsData ).map( (item, i) =>
							<option key={i} value={item?.value}>
								{item?.visibleName}
							</option>
						)}
					</Form.Control>
				</Col>
			}

			<Table striped hover size='sm' responsive className='my-0'>
				<thead>
					<tr>
						<th className='text-center text-white'>Servicio</th>
						<th className='text-center text-white'>Jurisd.</th>
						<th className='text-center text-white'>U. Org.</th>
						<th className='text-center text-white'>Carácter</th>
						<th className='text-center text-white'>Cuenta</th>
						<th className='text-center text-white'>Finalidad</th>
						<th className='text-center text-white'>Función</th>
						<th className='text-center text-white'>Sección</th>
						<th className='text-center text-white'>Sector</th>
						<th className='text-center text-white'>P. Princ.</th>
						<th className='text-center text-white'>P. Parc.</th>
						<th className='text-center text-white'>Código</th>
						<th className='text-center text-white'>Subcódigo</th>
						<th className='text-center text-white'>Denominación</th>
						<th className='text-center text-white'>{selectedOrderPay == "ORDEN_DE_PAGO_DE_HABERES" ? "Saldo de la Partida":"Saldo Obl. Pago"}</th>
						<th className='text-center text-white'>Acciones</th>
					</tr>
					{ selectedOrderPay === "ORDEN_DE_PAGO_DE_HABERES" &&
						<tr className='secondary'> 
							<th width="5%"></th>
							<th width="5%"><Form.Control type='number' value={formData?.jurisdiccion} onChange={e => handleJurisdictionNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.unidadDeOrganizacion} onChange={e => handleOrganizationNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.caracter} onChange={e => handleCharacterNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.cuenta} onChange={e => handleAccountNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.finalidad} onChange={e => handlePurposeNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.funcion} onChange={e => handleFunctionalityNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.seccion} onChange={e => handleSectionNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.sector} onChange={e => handleSectorNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.partidaPrincipal} onChange={e => handlePrincipalBudgetNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.partidaParcial} onChange={e => handlePartialBudgetNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.codigo} onChange={e => handleCodeNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="5%"><Form.Control type='number' value={formData?.subCodigo} onChange={e => handleSubCodeNumber(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="16%"><Form.Control type='text' value={formData?.visible_name} onChange={e => handleVisibleName(e.target.value)} onKeyDown={event => onKeyDownEnter(event)}/></th>
							<th width="12%"></th>
							<th className='text-center align-middle'>
								<div className='d-flex justify-content-around'>
									<ActionIcon
										size="lg"
										id="search-button"
										className="btn-primary search-button text-white-color"
										toolTipText="Filtrar"
										icon={faFilter}
										type='button'
										onClick={() => onSubmitExpedient()}
									/>
									<ActionIcon
										size="lg"
										id="clean-filter"
										className="btn-primary clean-filter text-white-color"
										toolTipText="Limpiar filtros"
										icon={faBroom}
										onClick={()=> cleanFilters()}
									/>
								</div>
							</th>
						</tr>	
					}
				</thead>
				<tbody>
					{ subcodeRecords?.length ?
					<>
						{ subcodeRecords?.map((item, index) => (	
							<tr key={item?.id} className={`text-center align-middle ${disableConditional(item) && 'text-secondary'}`}>
								{[...Array(13)].map((x, idx)=>(
									<td key={idx}>
										{item.partidaData?.[idx]}
									</td>
								))}
								<td>
									{item?.visibleName || item?.subCodeVisibleName}
								</td>
								<td>
									<NumericFormat
										key={item.id}
										displayType={'text'}
										isAllowed={(inputObj)=> withValueLimit(inputObj, item?.amountInitial)}
										defaultValue={formatterPeso.format(item?.amountInitial)}
										getInputRef= {inputRef}
										thousandSeparator={'.'}
										decimalSeparator={','} 
										decimalScale={2}
										prefix={'$'}
										className="some"
										inputMode="numeric"
									/>
								</td>
								<td>
								{
									<>
										{
											!item.selected ?
												<ActionIcon
													className={`ml-2 ${disableConditional(item) && "not-allowed"}`}
													size='lg'
													id={'add-orden' + index}
													toolTipText={toolTipTextCondition(item)}
													icon={disableConditional(item) ? faBan : faPlus}
													onClick={() => !disableConditional(item) && addPayOrderToSelectionList(item)}
												/>
												:
												<ActionIcon
													size='lg'
													id={'remove-orden-pago' + index}
													toolTipText='Quitar de la selección'
													icon={faTimes}
													className='text-danger ml-2'
													onClick={() => removePayOrderFromSelectionList(item)}
												/>
										}
									</>
								}
								</td>
								{item?.periodRP && <td className='align-middle'>
									<div className={`alert alert-warning mb-0 mt-1 py-0`}  role='alert'><b>RP {item?.periodRP}</b></div>
								</td>}
							</tr>
						))}
					</>
					:
					<tr>
						<td colSpan='16' className='text-center'>
							<Alert variant='info' className='mb-0'>
								<FontAwesomeIcon icon={faExclamationTriangle} className='mr-3' />
								No hay registros
							</Alert>
						</td>
					</tr>
				}
				</tbody>
			</Table>
			<Row>
				<Col className={'text-right ' + (disableAdmExpedientSearch || pageNumber == 1 ? 'hidden':'')}>
					<Button size='sm' variant="danger" className='px-5' disabled={isFetching || (pageNumber == 1)} onClick={loadLessItems}>
							Ver menos
					</Button>
				</Col>
				<Col className={'text-left ' + (!disableAdmExpedientSearch && hasMoreItems ?'':'hidden')}>
					<Button size='sm' variant="danger" className='px-5' disabled={!hasMoreItems} onClick={loadMoreItems}>
							Ver más
					</Button>
				</Col>
			</Row>
			{
				hasDifferentCharacter && (isNotEmptyArray(selectedExpedientAssociated) || isNotEmptyArray(expedientesSeleccionados)) &&
				<>
					<hr />
					<div className={`alert alert-warning m-1`}  role='alert'>
						<FontAwesomeIcon className={`text-black mr-1`} icon={faBell} />
						{msgChosenValidation}
						<br/> &nbsp;&nbsp;&nbsp;&nbsp;Servicio elegido: <strong>{servicioSeleccionado ? servicioSeleccionado : selectedAssociatedService}</strong>
						<br/> &nbsp;&nbsp;&nbsp;&nbsp;Jurisdicción elegida: <strong>{jurisdiccionSeleccionada ? jurisdiccionSeleccionada : selectedAssociatedJurisdiction}</strong>
						<br/> &nbsp;&nbsp;&nbsp;&nbsp;Carácter elegido: <strong>{caracterSeleccionado ? caracterSeleccionado : selectedAssociatedCharacter}</strong>
					</div>
				</>
			}
				
			{ expedientesSeleccionados.length > 0 ? <>
				<Form.Group style={{"display":"inline-block"}}>
					<Form.Label className='pr-0 my-0 d-flex' >
						<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger' />
						<h6>Partidas Presupuestarias Seleccionadas:</h6>
					</Form.Label>
					<Form.Control value={(total || expedientesAsociados?.length)?'true': ''} type='hidden' name='email' ref={register (payOrderValidationObj)} />
					{!(total) && <>
						<FormFieldError errors={errors.email} />
					</>}
				</Form.Group>
				<Table striped size='sm' className='mb-5'>
					<thead>
						{
							showSubpartItemSelector ?
								<tr>
									<th className='text-center text-white' width="50%">N° de Partida</th>
									<th className='text-center text-white' width="20%">Partida Subparcial</th>
									<th className='text-center text-white' width="20%">Importe</th>
									<th className='text-center text-white' width="10%">Acciones</th>
								</tr>
							:
								<tr>
									<th className='text-center text-white'>Serv.</th>
									<th className='text-center text-white'>Jurisd.</th>
									<th className='text-center text-white'>U. Org.</th>
									<th className='text-center text-white'>Carácter</th>
									<th className='text-center text-white'>Cuenta</th>
									<th className='text-center text-white'>Finalidad</th>
									<th className='text-center text-white'>Función</th>
									<th className='text-center text-white'>Sección</th>
									<th className='text-center text-white'>Sector</th>
									<th className='text-center text-white'>P. Princ.</th>
									<th className='text-center text-white'>P. Parc.</th>
									<th className='text-center text-white'>Código</th>
									<th className='text-center text-white'>Subcódigo</th>
									<th className='text-center text-white'>Denominación</th>
									<th className='text-center text-white'>Importe</th>
									<th className='text-center text-white'>Acciones</th>
								</tr>
						}
					</thead>
					<tbody>
					{
							showSubpartItemSelector ?
								expedientesSeleccionados.map((item, i) => (
									<tr key={item?.id}>
										<td className='text-center align-middle'>
											{item?.completeNumber}
										</td>
										<td className='text-center align-middle'>
											{
												isNotEmptyArray(item?.subPartialBudgets) &&
												<Col>
													<DropdownList
															data={item?.subPartialBudgets}
															value={item?.subPartialBudgetSelected?.id}
															onChange={data => onChangeSubpartialType(item, data)}
															textField={data => (`${data?.number} - ${data?.name}`)}
															valueField='id'
															name={item?.id}
															placeholder={dropdownPlaceholder}
															allowCreate='false'
															required
															selectIcon={<FontAwesomeIcon   icon={faChevronDown} />}
															searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}
														>
														</DropdownList>
													<FormFieldError errors={errors?.[item?.id]}  />
													<Form.Control hidden ref={register(subPartialBudgetValidationObj)} name={item?.id} value={item?.subPartialBudgetSelected?.id} />
												</Col>
													
											}
										</td>
										<td className='text-center align-middle'>
											<div className="input-group mb-2">
												<div className="input-group-prepend">
													<div className="input-group-text">$</div>
												</div>
												<NumericFormat
													allowNegative={false}
													required
													key={item?.id}
													defaultValue={formatterPeso.format(item?.amount)}
													isAllowed={(inputObj)=> withValueLimit(inputObj, item?.amountInitial)}
													onValueChange={(inputObj) => handlerImporteChange(inputObj, item, item)}
													getInputRef= {inputRef} thousandSeparator={'.'} decimalSeparator={','} 
													decimalScale={2}
													className="form-control text-right"
													inputMode="numeric"
													onKeyDown={event => onKeyDownEnter(event)}
												/>
											</div>
										</td>
										<td className='text-center align-middle'>
											<ActionIcon
												size='lg'
												id={'remove-order' + i}
												toolTipText='Quitar de la selección'
												icon={faTimes} className='text-danger'
												onClick={() => removePayOrderFromSelectionList(item)}
											/>
										</td>
									</tr>
								))
							:
							expedientesSeleccionados.map((item, i) => (
								<tr key={item?.id} className='text-center align-middle'>
									{[...Array(13)].map((x, idx)=>(
										<td key={idx}>
											{item.partidaData[idx]}
										</td>
									))}
									<td>
										{item?.visibleName || item?.subCodeVisibleName}
									</td>
									<td>
										<div className="input-group mb-2">
											<div className="input-group-prepend">
												<div className="input-group-text">$</div>
											</div>
											<NumericFormat
												allowNegative={false}
												required
												key={item?.id}
												defaultValue={formatterPeso.format(item?.amount)}
												isAllowed={(inputObj)=> withValueLimit(inputObj, item?.amountInitial)}
												onValueChange={(inputObj) => handlerImporteChange(inputObj, item, item)}
												getInputRef= {inputRef} thousandSeparator={'.'} decimalSeparator={','} 
												decimalScale={2}
												className="form-control text-right"
												inputMode="numeric"
												onKeyDown={event => onKeyDownEnter(event)}
											/>
										</div>
									</td>
									<td className='text-center align-middle'>
										<ActionIcon
											size='lg'
											id={'remove-order' + i}
											toolTipText='Quitar de la selección'
											icon={faTimes} className='text-danger'
											onClick={() => removePayOrderFromSelectionList(item)}
										/>
									</td>
								</tr>
							))
						}
					</tbody>
				</Table>
				</>
				:
				<Alert variant='warning' className='mb-5'>
					<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
					Debe Seleccionar una Partida Presupuestaria.
				</Alert>
			}
		</>
	}

	{	isNotEmptyArray(expedientesAsociados) && <>
		<hr />
		<Form.Group style={{"display":"inline-block"}}>
			<Form.Label className='text-black-color pr-0 d-flex' >
				<h6 className='mb-3'>Partidas Presupuestarias Asociadas:</h6>
			</Form.Label>
		</Form.Group>
		<Table striped bordered hover size='sm'>
			<thead>
				{
					showSubpartItemSelector ? 
					<tr>
						<th className='text-center text-white' width='30%'>Afectación</th>
						<th className='text-center text-white' width='30%'>Descripción</th>
						<th className='text-center text-white' width='20%'>Partida Subparcial</th>
						<th className='text-center text-white' width='20%'>Importe</th>
						<th width='5%'>&nbsp;</th>
					</tr>
					:
					<tr>
						<th className='text-center text-white' width='35%'>Afectación</th>
						<th className='text-center text-white' width='40%'>Descripción</th>
						<th className='text-center text-white' width='20%'>Importe</th>
						<th width='5%'>&nbsp;</th>
					</tr>
				}
				
			</thead>
			<tbody className='text-black-color'>{
					expedientesAsociados?.map((item) => (
						<tr key={item?.id}>
							<td className='text-center align-middle'>
							{item?.presupuestoResponse?.subCodeCompleteNumber}
							</td>
							<td className='text-center align-middle'>
								{item?.presupuestoResponse?.description}
							</td>
							{
								showSubpartItemSelector &&
								<td className='text-center align-middle'>
									{
										(item?.presupuestoResponse?.subPartialBudget?.id) &&
											<Col>
												<DropdownList
													data={item?.presupuestoResponse?.subpartialList}
													value={item?.presupuestoResponse?.subPartialBudget?.id}
													onChange={data => handleAssociatedItemSubPartialBudget(item, data)}
													textField={data => (`${data?.number} - ${data?.name}`)}
													valueField='id'
													name={item?.id}
													placeholder={dropdownPlaceholder}
													allowCreate='false'
													required
													selectIcon={<FontAwesomeIcon   icon={faChevronDown} />}
													searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}
												>
												</DropdownList>
												<FormFieldError errors={errors?.[item?.id]}  />
												<Form.Control hidden ref={register(subPartialBudgetValidationObj)} name={item?.id} value={item?.presupuestoResponse?.subPartialBudget?.id} />
										</Col>
									}
								</td>
							}
							<td className='text-center align-middle'>
								<Container fluid>
									<div className="row">
										<div className={`input-group mb-2 ${!item.selected ? 'number-format-opacity' : ''}`}>
											<div className="input-group-prepend ">
												<div className="input-group-text">$</div>
											</div>
											<NumericFormat
												allowNegative={false}
												required
												key={item.id}
												disabled={!item.selected}
												defaultValue={formatterPeso.format(item?.presupuestoResponse?.amount)}
												isAllowed={(inputObj)=> associatedBudgetWithValueLimit(inputObj, expedienteList?.find(x => x?.subCodeId === item?.presupuestoResponse?.subCodeId)?.amountInitial, item?.presupuestoResponse?.originalAmount)}
												onValueChange={(inputObj) => handlerAssociatedItems(inputObj, item, item.presupuestoResponse)}
												getInputRef= {inputRef} thousandSeparator={'.'} decimalSeparator={','}
												decimalScale={2}
												className="form-control text-right"
												inputMode="numeric"
												onKeyDown={event => onKeyDownEnter(event)}
											/>
										</div>
									</div>
								</Container>
							</td>
							<td className='text-center align-middle'>
								<Form.Check
									className='text-black-color'
									defaultChecked={true}
									disabled={disableAssociatedCheckbox}
									onChange={event => updateAsociados(item, event.target.checked)}
								/>
							</td>
						</tr>
					))
				}
			</tbody>
		</Table>
	</>}


	<Modal show={showPayOrderDetailModal} onHide={() => setShowPayOrderDetailModal(false)}>
		<Modal.Header closeButton>
			<Modal.Title>DETALLE DE LA ORDEN DE PAGO</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Table striped borderless hover className="shadow-sm p-2 mb-1 bg-white rounded text-black-color" >
				<tbody>
						<tr>
							<td className='font-weight-bold text-right' sm={4}>{'Expediente'}:</td>
							<td className='text-left' sm={8}>
									{activePayOrder?.administrativeDocument?.organizationCode}-{activePayOrder?.administrativeDocument?.number}/{activePayOrder?.administrativeDocument?.year}
							</td>
						</tr>
						<tr>
							<td className='font-weight-bold text-right' sm={4}>{'Subcódigo'}:</td>
							<td className='text-left' sm={8}>
								{activePayOrder?.subCodeId}
							</td>
						</tr>
						<tr>
							<td className='font-weight-bold text-right' sm={4}>{'Importe'}:</td>
							<td className='text-left' sm={8}>
								<input required className="freeze-input text-center col-md-7 shadow p-2 rounded"
											type="text"
											disabled
											value={formatterPeso.format(activePayOrder?.amountInitial)} />
							</td>
						</tr>
						{activePayOrder?.partidaData.map((part, index)=>(
						<tr key={part?.id}>
							<td className='font-weight-bold text-right'>
								{cols[index]}
							</td>
							<td>
								{part}
							</td>
						</tr>))}
				</tbody>
			</Table>
			<hr />
		
		
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={() => setShowPayOrderDetailModal(false)}>
				Cerrar
			</Button>
		</Modal.Footer>
	</Modal>

	</>;
};

export default ExpedienteListBox;