import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Table, Button, Alert, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlus, faSearch, faEdit, faCopy, faTrash, faBroom, faChevronDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import RCPagination from 'src/components/common/RCPagination';
import ActionIcon from 'src/components/general/ActionIcon';
import axios from 'axios';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getBankAccountFundRequestReducerData, getBankAccountFundRequestReducerIsFetching } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer'
import { getAdministrativeServiceListData, getListServicesByUserAndExerciseData, getListServicesByUserAndExerciseIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer'
import { tryDeleteBankAccount, tryGetListBankAccountFundRequest } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestActionCreator'
import { tryListAdministrativeServices, tryListAllServiceAdministrativeByUser, tryListServicesByUserAndExercise } from 'src/redux/administrativeService/administrativeServiceActionCreator'
import { setBankAccountToEdit, setBankAccountToDetails, clearBankAccountFundRequestListData } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestActions'
import { BANK_ACCOUNT_NEW, BANK_ACCOUNT_EDIT, BANK_ACCOUNT_DETAILS } from 'src/utils/constants';
import { push } from 'connected-react-router';
import { clearBankAccountListData } from '../../../redux/bankAccountsFundRequest/bankAccountsFundRequestActions';
import { useForm } from 'react-hook-form';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { getUserListData } from 'src/redux/user/userReducer';
import { getEmail, getUserPermissionsBankAccounts } from 'src/redux/login/loginReducer';
import { DropdownList } from 'react-widgets';
import { setBankAccountFilters } from 'src/redux/globalData/globalDataActions';
import { getBankAccountFilters } from 'src/redux/globalData/globalDataReducer';
import CloneBankAccountModal from './CloneBankAccountModal';
import AppLoading from 'src/components/common/AppLoading';
import { AdministrativeServiceByUserAndExerciseData, administrativeService } from 'src/utils/administrativeServiceUtils';
import { isNotEmptyArray, isNumeric } from 'src/services/validationService';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const BankAccountListPage = () => {

    const { handleSubmit, register, reset } = useForm();
    const dispatch = useDispatch();

    const [totalCount, setTotalCount] = useState(0);
    const [records, setRecords] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [servicio, setServicio] = useState();
    const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };
    const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

    const creditLabelsRefContainer = useRef(null);
    const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;

    //User Id
    const email = useSelector(state => getEmail(state));
    const listUserData = useSelector(state => getUserListData(state));
    const userFound = listUserData?.records?.find(item => item.email == email);

    //Services by user and exercise
    const userServicesIds = useSelector(state => getListServicesByUserAndExerciseData(state))?.map((item) => item.id).toString();
    const servicesByUserAndExerciseIsFetching = useSelector(state => getListServicesByUserAndExerciseIsFetching(state));

    const [dataModal, setDataModal] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const bankAccountsPermissions = useSelector(state => getUserPermissionsBankAccounts(state));

    const onClickSeeDetails = item => {
        dispatch(setBankAccountToDetails(item));
        dispatch(push(BANK_ACCOUNT_DETAILS));
    };

    //Cancel Request
    const CancelToken = axios.CancelToken;
    let cancelSource = CancelToken.source();

    const onClickEdit = item => {
        dispatch(setBankAccountToEdit(item));
        dispatch(push(BANK_ACCOUNT_EDIT));
    };

    const onClickNew = () => {
        dispatch(push(BANK_ACCOUNT_NEW));
    };

    const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            ...filters,
            page: pNumber - 1
        }
        filters && filters.year && (params.year = filters.year)
        fetchList(params);
        dispatch(setBankAccountFilters(params))
    }

    const administrativeServiceData = AdministrativeServiceByUserAndExerciseData();

    const bankAccountListIsFetching = useSelector(state => getBankAccountFundRequestReducerIsFetching(state));
    const bankAccountListData = useSelector(state => getBankAccountFundRequestReducerData(state)?.records);
    const bankAccountListPaginate = useSelector(state => getBankAccountFundRequestReducerData(state)?.metadata);
    const listAdministrativeService = useSelector(state => getAdministrativeServiceListData(state));
    const filters = useSelector(state => getBankAccountFilters(state)?.data);

    const defaultParams = {
        page: 0,
        size: 10,
        year: globalSelectedPeriod?.year,
        sort: 'administrativeServiceId,number,year,asc',
        administrativeServiceId: userServicesIds
    };
    const [filterObject, setFilterObject] = useState({});

    const loadAllData = (params, booleanFlag) => {
        if (params?.administrativeServiceId) {
            if (filters) {
                if (isNumeric(filters?.administrativeServiceId)) {
                    setServicio(filters?.administrativeServiceId);
                };
                fetchList({ ...filters, administrativeServiceId: filters?.administrativeServiceId || params?.administrativeServiceId });
            } else {
                fetchList({ ...defaultParams, administrativeServiceId: params?.administrativeServiceId });
            }
        } else {
            if (booleanFlag) {
                fetchList(defaultParams);
            } else {
                fetchList(filters || defaultParams);
            };
        };
        const params2 = {
            size: 1000,
        };

        dispatch(tryListAdministrativeServices(params2));
    }

    const fetchList = (params) => {
        dispatch(tryGetListBankAccountFundRequest(params)).then((response) => {
            setPageNumber(params.page);
            setRecords(response.data.records);
            let metadata = response?.data?.metadata;
            setTotalCount(metadata?.filteredCount);
        })
    }

    const onClickLoad = (pageNumber) => {
        const params = {
            ...defaultParams,
            ...filterObject,
            page: pageNumber,
        };
        dispatch(tryGetListBankAccountFundRequest(params));
    };

    const onClickDelete = organism => {

        swal({
            title: 'Confirmación',
            text: '¿Realmente desea eliminar la cuenta bancaria?',
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })

            .then((willDelete) => {
                if (willDelete) {
                    dispatch(tryDeleteBankAccount(organism?.id)).then(
                        response => {
                            if (response?.status == 204) {
                                dispatch(clearBankAccountListData());
                                loadAllData();
                            }
                        }

                    );
                }
            });
    };

    const onClickClonar = item => {
        item.year++;
        setDataModal(item);
        setShow(true);
    };

    let newFilterObject;
    const clearFilters = () => {
        setFilterObject({});
        setServicio(null);
        reset();
        dispatch(setBankAccountFilters(defaultParams));
        loadAllData({}, true)
    };

    const onSubmitForm = (data) => {
        if (!bankAccountListIsFetching) {
            cancelSource.cancel();
            cancelSource = CancelToken.source();
            newFilterObject = {
                ...defaultParams,
                ...data,
            };
            servicio && (newFilterObject.administrativeServiceId = servicio)
            setFilterObject(newFilterObject);
            dispatch(setBankAccountFilters(newFilterObject));
            dispatch(clearBankAccountFundRequestListData());
            fetchList(newFilterObject)
        }
    };

    useEffect(() => {
        dispatch(clearListAllAdministrativeServicesData());
        dispatch(clearBankAccountListData());
        dispatch(tryListAllServiceAdministrativeByUser(userFound?.id));
        dispatch(tryListServicesByUserAndExercise(userFound?.id, globalSelectedPeriod?.id)).then(response => {
            loadAllData({ administrativeServiceId: response?.data?.map((item) => item.id)?.toString() });
        });
    }, [globalSelectedPeriod]);

    return <>
        <>
            <Container fluid>
                <Card className='my-3'>
                    <Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Cuentas Bancarias</h1>
                        <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-operador-tesoreria/#ejecuci%C3%B3n-financiera--cuentas-bancarias'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>                    
                    <Card.Body>
                        {bankAccountsPermissions?.canCreate &&
                            <Button size='sm' className='mb-3' variant='success' onClick={onClickNew}>
                                <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                Nueva Cuenta Bancaria
                            </Button>
                        }
                        <Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
                            <Table striped hover size='sm' responsive>
                                <thead>
                                    <tr ref={creditLabelsRefContainer}>
                                        <th className='text-center align-middle' width='43%'>Servicio</th>
                                        <th className='text-center align-middle' width='12%'>N° Cuenta Bancaria</th>
                                        <th className='text-center align-middle' width='30%'>Descripción</th>
                                        <th className='text-center align-middle th-minwidth' width='8%'>Acciones</th>
                                    </tr>
                                    <tr className='secondary'>
                                        <th className='text-center align-middle'>
                                            <Col className='px-0 d-flex align-items-center justify-content-end' id='administrativeServiceId'>
                                                <DropdownList
                                                    placeholder='Seleccione un servicio...'
                                                    data={administrativeServiceData}
                                                    allowCreate='onFilter'
                                                    name='administrativeServiceId'
                                                    textField='displayName'
                                                    dataKey='id'
                                                    filter='contains'
                                                    value={servicio}
                                                    className='w-100 text-black-color'
                                                    onChange={value => {
                                                        setServicio(value?.id)
                                                        defaultParams.administrativeServiceId = value?.id
                                                    }}
                                                    id='administrativeServiceId'
                                                    messages={messagesDropDown}
                                                    selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
                                                    /*searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />}*/
                                                >
                                                </DropdownList>
                                            </Col>
                                        </th>
                                        <th className='text-center align-middle'>
                                            <Form.Control
                                                className='text-center'
                                                defaultValue={filters?.number ? filters?.number : ""}
                                                size='sm' type='number'
                                                name='number'
                                                ref={register}
                                            />
                                        </th>
                                        <th className='text-center align-middle'>
                                            <Form.Control
                                                className='text-center'
                                                defaultValue={filters?.description ? filters?.description : ""}
                                                size='sm' type='text'
                                                name='description'
                                                ref={register}
                                            />
                                        </th>
                                        <th className='text-center align-middle'>
                                            <div className='d-flex justify-content-around'>
                                                <ActionIcon
                                                    size="lg"
                                                    id="search-button"
                                                    className="btn-primary search-button text-white-color"
                                                    toolTipText="Filtrar"
                                                    icon={faFilter}
                                                    type='submit'
                                                    onSubmit={onSubmitForm}
                                                />
                                                <ActionIcon
                                                    size="lg"
                                                    id="clean-filter"
                                                    className="btn-primary clean-filter text-white-color"
                                                    toolTipText="Limpiar filtros"
                                                    icon={faBroom}
                                                    type='reset'
                                                    onClick={() => clearFilters()}
                                                />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-black-color'>
                                    {
                                        (bankAccountListData && bankAccountListData != "" && !bankAccountListIsFetching && !servicesByUserAndExerciseIsFetching)
                                            ?
                                            <>
                                                {bankAccountListData?.map((item) => (

                                                    <tr key={item?.id}>
                                                        <td className='text-center align-middle maxTruncate'>
                                                            {administrativeService(item?.administrativeServiceId, administrativeServiceData)}
                                                        </td>
                                                        <td className='text-center align-middle maxTruncate'>
                                                            {item?.number}
                                                        </td>
                                                        <td className='text-center align-middle maxTruncate' title={item?.description}>
                                                            {item?.description}
                                                        </td>
                                                        <td className='text-center align-middle p-0 m-0 table-responsive btn-group' >
                                                            <ActionIcon size='lg' id='credit-query' toolTipText='Detalle de Cuenta Bancaria' icon={faSearch} onClick={() => onClickSeeDetails(item)} />
                                                            {bankAccountsPermissions?.canUpdate &&
                                                                <ActionIcon
                                                                    size='lg'
                                                                    toolTipText={'Editar Cuenta Bancaria'}
                                                                    icon={faEdit}
                                                                    onClick={() => onClickEdit(item)}
                                                                />
                                                            }
                                                            <ActionIcon size='lg' toolTipText='Clonar Cuenta Bancaria' icon={faCopy} onClick={() => onClickClonar(JSON.parse(JSON.stringify(item)))} />

                                                            {bankAccountsPermissions?.canDelete &&
                                                                <ActionIcon
                                                                    size='lg'
                                                                    className={item?.hasFundRequests ? 'not-allowed' : ''}
                                                                    toolTipText={item?.hasFundRequests ? 'Cuenta bancaria con Pedido de Fondo asociada' : 'Anular Cuenta Bancaria'}
                                                                    icon={faTrash}
                                                                    onClick={() => !item?.hasFundRequests && onClickDelete(item)}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={colSpanCellsNumber} className='text-center'>
                                                    {
                                                        (!bankAccountListIsFetching || !servicesByUserAndExerciseIsFetching) &&
                                                        <Alert variant='info' className='mb-0'>
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                            No hay registros
                                                        </Alert>
                                                    }
                                                </td>
                                            </tr>
                                    }
                                </tbody>

                            </Table>
                        </Form>
                        {
                            isNotEmptyArray(bankAccountListData) &&
                            <RCPagination
                                activePage={filters?.pageNumber || pageNumber + 1}
                                itemsCountPerPage={defaultParams.size}
                                totalItemsCount={totalCount}
                                totalItems={totalCount}
                                thePage={bankAccountListPaginate?.page + 1}
                                onChange={onPageChange}
                                innerClass="justify-content-center"
                            />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </>
        <CloneBankAccountModal
            show={show}
            handleClose={handleClose}
            dataModal={dataModal}
        />
        <AppLoading isLoading={bankAccountListIsFetching || servicesByUserAndExerciseIsFetching} />
    </>

}
export default BankAccountListPage;