import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faEquals } from '@fortawesome/free-solid-svg-icons';

import { formatterPeso, numberNegativeRed } from 'src/utils/utils';
import { characterLabel, amount, subcode, code, creditPartial, creditInitial, sector, section, functio, cuentaCuentaEspecial, uOrganizaciónOrganismo, jurisdiction, nroCredit, detailsCredit, serviceLabel } from 'src/utils/label';

import { getCreditQueryDataSelected } from 'src/redux/affectation/affectationReducer';


const CreditDetailPageDetail = props => {

	// Credit details
	const subcodeData = useSelector(state => getCreditQueryDataSelected(state));
	const { service, organization, character, functionality, partialBudget, account } = subcodeData?.code?.credit;
	const purpose = functionality?.purpose;

	// Amount
	const subcodeAmount = formatterPeso.format(subcodeData?.balance?.currentBalance);


	// Amount Credit
	const currentCreditAmount = formatterPeso.format(subcodeData?.credit?.currentCredit);
	// const getItemClassCurrentCredit = amount => {
	// 	return `px-3 text-left align-middle ${subcodeData?.credit?.currentCredit < 0 ? 'text-danger' : ''}`;
	// }
	
	// Amount Balance Totals
	const totalsPreventiveCreditAmount = formatterPeso.format(subcodeData?.balance?.totals?.preventive);
	const totalsDefinitiveCreditAmount = formatterPeso.format(subcodeData?.balance?.totals?.definitive);
	const totalsPaymentObligationCreditAmount = formatterPeso.format(subcodeData?.balance?.totals?.payment_obligation);
	const totalsPaymentCreditAmount = formatterPeso.format(subcodeData?.balance?.totals?.payment);

	//Crédito actual - Acumulador Preventiva = Saldo Preventiva
	const balancePreventive = subcodeData?.credit?.currentCredit - subcodeData?.balance?.totals?.preventive;
	const balancePreventiveFormatter = formatterPeso.format(balancePreventive);
	// Acumulador Preventiva - Acumulador Definitiva = Saldo Definitiva
	const balanceDefinitive = subcodeData?.balance?.totals?.preventive - subcodeData?.balance?.totals?.definitive;
	const balanceDefinitiveFormatter = formatterPeso.format(balanceDefinitive);
	//Acumulador Definitiva - Acumulador obl. de pago = Saldo obl. de pago
	const balancePaymentObligation = subcodeData?.balance?.totals?.definitive - subcodeData?.balance?.totals?.payment_obligation;
	const balancePaymentObligationFormatter = formatterPeso.format(balancePaymentObligation);
	//Acumulador obl. de pago - Acumulador pago = Saldo pago
	const balancePayment = subcodeData?.balance?.totals?.payment_obligation - subcodeData?.balance?.totals?.payment;
	const balancePaymentFormatter = formatterPeso.format(balancePayment);


	return <Container fluid>
		<div>
			<Card className='mb-3'>
				<Card.Header>
					<h6 className='mb-0'>
						{detailsCredit}
					</h6>
				</Card.Header>
				<Card.Body>
					<Container fluid>
						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='nroCredit'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{nroCredit}:
									</Form.Label>
									<Col sm='9'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={subcodeData?.completeNumber} />
									</Col>
								</Form.Group>
							</Col>
						</Row>
						<Row >
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='service'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{serviceLabel}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${service?.number} - ${service?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='jurisdiction'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{jurisdiction}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${organization?.jurisdiction?.number} - ${organization?.jurisdiction?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col xs={12} className='text-right'>

								<Form.Group className='mb-0' as={Row} controlId='organization'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{uOrganizaciónOrganismo}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${organization?.number} - ${organization?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='character'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{characterLabel}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${character?.code} - ${character?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row}>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{cuentaCuentaEspecial}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${account?.number} - ${account?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row}>
									<Form.Label className='text-black-color px-0' column sm='3'>
										Finalidad:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${purpose?.number} - ${purpose?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='functionality'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{functio}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${functionality?.number} - ${functionality?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row >
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='section'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{section}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${partialBudget?.principalBudget?.sector?.section?.number} - ${partialBudget?.principalBudget?.sector?.section?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>

						<Row >
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='sector'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{sector}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${partialBudget?.principalBudget?.sector?.number} - ${partialBudget?.principalBudget?.sector?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>
						{partialBudget?.principalBudget?.number !== '000' && <Row >
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='principalBudget'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{creditInitial}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${partialBudget?.principalBudget?.number} - ${partialBudget?.principalBudget?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>}
						

						{partialBudget?.number !== '00000' && <Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row} controlId='partialBudget'>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{creditPartial}:
									</Form.Label>
									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${partialBudget?.number} - ${partialBudget?.name}`} />
									</Col>
								</Form.Group>
							</Col>
						</Row>}
						<hr></hr>
						{!(subcodeData?.code?.number === '0' && subcodeData?.number === '00') && <div><Row className='mb-2'>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row}>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{code}:
									</Form.Label>

									<Col sm='8' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${subcodeData?.code?.number} - ${subcodeData?.code?.name}`} />
									</Col>

									<Col sm='1' className='pl-0 d-flex align-items-center'>
									</Col>
								</Form.Group>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row}>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{subcode}:
									</Form.Label>
									<Col sm='8' className='text-left'>
										<Form.Control plaintext readOnly className='px-0' defaultValue={`${subcodeData?.number} - ${subcodeData?.name}`} />
									</Col>

									<Col sm='1' className='pl-0 d-flex align-items-center'></Col>
								</Form.Group>
							</Col>
						</Row></div>}
						<hr></hr>
						<Row>
							<Col xs={12} className='text-right'>
								<Form.Group className='mb-0' as={Row}>
									<Form.Label className='text-black-color px-0' column sm='3'>
										{amount}:
									</Form.Label>

									<Col sm='9' className='text-left'>
										<Form.Control plaintext readOnly className={numberNegativeRed(subcodeData?.credit?.currentCredit)} defaultValue={subcodeAmount} />
									</Col>
								</Form.Group>
							</Col>
						</Row>


						<Card>
						<Card.Header>
							<h5 className='text-center mb-0'>Saldos</h5>
						</Card.Header>
						<Card.Body>
							<Table striped borderless hover >
								<tbody>
									<tr>
										<Row>
											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Form.Label className="text-black-color" column sm="5">
														Crédito actual:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.credit?.currentCredit)} text-right p-2`} defaultValue={currentCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

												<Form.Group as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
												</Form.Group>
											
											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="accumulatorPreventive">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador Preventiva:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.preventive)} text-right p-2`} defaultValue={totalsPreventiveCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
											</Form.Group>

											<Col xs={3} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="preventiveBalance">
													<Form.Label className="text-black-color" column sm="5">
														Saldo Preventiva:
												</Form.Label>
													<Col sm="7">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.preventive)} text-right p-2`} defaultValue={totalsPreventiveCreditAmount == '$ 0,00' ? '$ 0,00' : balancePreventiveFormatter} />
													</Col>
												</Form.Group>
											</Col>
										</Row>
									</tr>
									<tr>
										<Row>
											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="accumaltorPreventive2">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador Preventiva:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.preventive)} text-right p-2`} defaultValue={totalsPreventiveCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
											</Form.Group>

											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador Definitiva:
									</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.definitive)} text-right p-2`} defaultValue={totalsDefinitiveCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
											</Form.Group>

											<Col xs={3} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="definitiveBalance">
													<Form.Label className="text-black-color" column sm="5">
														Saldo Definitiva:
													</Form.Label>
													<Col sm="7">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(balanceDefinitive)} text-right p-2`} defaultValue={balanceDefinitiveFormatter} />
													</Col>
												</Form.Group>
											</Col>
										</Row>
									</tr>

									<tr>
										<Row>
											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="accumulatorDefinitive2">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador Definitiva:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.definitive)} text-right p-2`} defaultValue={totalsDefinitiveCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
												</Form.Group>

											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador Obl. Pago:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.payment_obligation)} text-right p-2`} defaultValue={totalsPaymentObligationCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
											</Form.Group>

											<Col xs={3} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="balancePaymentObligation">
													<Form.Label className="text-black-color" column sm="5">
														Saldo Obl. Pago:
													</Form.Label>
													<Col sm="7">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(balancePaymentObligation)} text-right p-2`} defaultValue={balancePaymentObligationFormatter} />
													</Col>
												</Form.Group>

											</Col>

										</Row>
									</tr>

									<tr>
										<Row>
											<Col xs={4} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="accumulatorPaymentObligation3">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador Obl. Pago:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.payment_obligation)} text-right p-2`} defaultValue={totalsPaymentObligationCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faMinus} size='lg' />
													</Col>
											</Form.Group>

											<Col xs={4} className='text-right'>

												<Form.Group className='mb-0' as={Row} controlId="accumulatorPayment">
													<Form.Label className="text-black-color" column sm="5">
														Acumulador pago:
													</Form.Label>
													<Col sm="6">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(subcodeData?.balance?.totals?.payment)} text-right p-2`} defaultValue={totalsPaymentCreditAmount} />
													</Col>
												</Form.Group>
											</Col>

											<Form.Group className='mb-0' as={Row} controlId="formPlaintextEmail">
													<Col sm='1' className='text-danger mandatory-label font-weight-bold font-italic d-flex align-items-center'>
														<FontAwesomeIcon icon={faEquals} size='lg' />
													</Col>
											</Form.Group>

											<Col xs={3} className='text-right'>
												<Form.Group className='mb-0' as={Row} controlId="paymentBalance">
													<Form.Label className="text-black-color" column sm="5">
														Saldo pago:
													</Form.Label>
													<Col sm="7">
														<Form.Control plaintext readOnly className={`${numberNegativeRed(balancePayment)} text-right p-2`} defaultValue={balancePaymentFormatter} />
													</Col>
												</Form.Group>

											</Col>

										</Row>
									</tr>
								</tbody>
							</Table>
						</Card.Body>
					</Card>



					</Container>
				</Card.Body>
			</Card>
		</div>

	</Container>

};

export default CreditDetailPageDetail;