import React from 'react'

import { Routing } from './routing/Routing';
import Notifications from 'src/components/general/GlobalNotifications';
import { ROUTE_PATHS as PATHS} from './utils/constants';
export const ROUTE_PATHS = PATHS;

const routes = (
	<div>
		<Routing />			
		<Notifications></Notifications>			
	</div>
)

export default routes;