import axios from 'axios';
import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpPost, httpPut, httpDelete } from './httpServices';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const FUND_REQUESTS_URI = `${API_BASE_URI}/fund-requests`;
const FUND_REQUEST_TYPES_URI = `${API_BASE_URI}/fund-requests/types`
const FUND_REQUEST_STATES_URI = `${API_BASE_URI}/fund-requests/states`
import { getClearObject } from 'src/utils/utils';

// List fund requests
export const listFundRequests = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);

	let paramsToSend = {
		page: params?.page || 0,
		size: params?.size || 10,
		sort: params?.sort || 'id,desc',
		name: params?.name,
		code: params?.code,
		state: params?.state?.toUpperCase(),
		number: params?.number,
		description: params?.description,
		descriptionBankAccount: params?.descriptionBankAccount,
		numberBankAccount: params?.numberBankAccount,
		fundRequestDate: params?.fundRequestDate,
		year:params?.year,
		fundRequestType:params?.fundRequestType,
		character: params?.character,
		administrativeServiceId: params?.administrativeServiceId || '',
		paymentOrderAdministrativeDocuments: params?.paymentOrderAdministrativeDocuments,
	};

	options.cancelToken = params?.token;
	paramsToSend = getClearObject(paramsToSend);
	const queryString = buildURLQuery(paramsToSend);
	const URL = `${FUND_REQUESTS_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		if (axios.isCancel(error)) {
			// Handle if request was cancelled
			// 499 Client Closed Request
			error.status = 499;
		}
		return error;
	}
	return response;
};


//New fund requests
export const newFundRequests = async (accessToken, params) => {

	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPost(FUND_REQUESTS_URI, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};


//Edit fund requests
export const editFundRequests = async (accessToken, id, params) => {
	const URL = `${FUND_REQUESTS_URI}/${id}`
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Delete fund requests
export const deleteFundRequests = async (accessToken, id) => {

	const options = buildDefaultOptions(accessToken);
	let response;
	const URL = `${FUND_REQUESTS_URI}/${id}`;

	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

// Detail fund requests
export const getFundRequestsId = async (accessToken, foundRequestId) => {
	const URL = `${FUND_REQUESTS_URI}/${foundRequestId}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}

// Detail fund requests
export const getFundRequestTypes = async (accessToken) => {
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(FUND_REQUEST_TYPES_URI, options);
	}
	catch (error) {
		return error;
	}

	return response;
}

export const getFundRequestStates = async (accessToken) => {
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(FUND_REQUEST_STATES_URI, options);
	}
	catch (error) {
		return error;
	}

	return response;
}