import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpPost } from './httpServices';

const API_CORE_BASE_URI = `${config.apis.coreApi.URL}`;
const POST_SYSTEM_STATUS_API = `${API_CORE_BASE_URI}/system-status-log`;

export const postMaintenanceSystem = async ( accessToken, params ) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${POST_SYSTEM_STATUS_API}?active=${params}`
	let response;

	try {
		response = await httpPost( URL, params, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};