import * as ACTION_TYPES from './provisionOrderActionTypes';


// GET Provision Order list actions
export const getProvisionOrderListRequest = () => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_REQUEST
});
export const getProvisionOrderListRequestSuccess = data => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getProvisionOrderListRequestFailure = error => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearProvisionOrderListData = () => ({
	type: ACTION_TYPES.CLEAR_PROVISION_ORDER_REQUEST
});


// Edit ProvisionOrder 
export const getEditProvisionOrderRequest = () => ({
	type: ACTION_TYPES.EDIT_PROVISION_ORDER_REQUEST
});
export const getEditProvisionOrderRequestSuccess = (data) => ({
	type: ACTION_TYPES.EDIT_PROVISION_ORDER_REQUEST_SUCCESS,
})


// POST Provision Order actions
export const postProvisionOrderRequest = () => ({
	type: ACTION_TYPES.POST_PROVISION_ORDER_REQUEST
});
export const postProvisionOrderRequestSuccess = data => ({
	type: ACTION_TYPES.POST_PROVISION_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getEditProvisionOrderRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_PROVISION_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setProvisionOrderToEdit = data => ({
	type: ACTION_TYPES.SET_PROVISION_ORDER_TO_EDIT,
	payload: {
		data
	}
});
export const clearEditProvisionOrder = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_PROVISION_ORDER
});
//get by id

export const getProvisionOrderByIdRequest = () => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_BYID_REQUEST
});
export const getProvisionOrderByIdRequestSuccess = data => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_BYID_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getProvisionOrderByIdRequestFailure = error => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_BYID_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const postProvisionOrderRequestFailure = error => ({
	type: ACTION_TYPES.POST_PROVISION_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});

//get provision order to clone by id

export const getProvisionOrderToCloneByIdRequest = () => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST
});
export const getProvisionOrderToCloneByIdRequestSuccess = data => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getProvisionOrderToCloneByIdRequestFailure = error => ({
	type: ACTION_TYPES.GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearProvisionOrderToClone = () => ({
	type: ACTION_TYPES.CLEAR_PROVISION_ORDER_TO_CLONE
})

// DETAIL Provision Order to Detail
export const setProvisionOrderDetail = data => ({
	type: ACTION_TYPES.SET_PROVISION_ORDER_DETAIL,
	payload: {
		data
	}
});
export const clearProvisionOrderDetail = () => ({
	type: ACTION_TYPES.CLEAR_PROVISION_ORDER_DETAIL
});

// DELETE Provision Order 
export const deleteProvisionOrderRequest = () => ({
    type: ACTION_TYPES.DELETE_PROVISION_ORDER_REQUEST
});
export const deleteProvisionOrderRequestSuccess = data => ({
    type: ACTION_TYPES.DELETE_PROVISION_ORDER_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const deleteProvisionOrderRequestFailure = error => ({
    type: ACTION_TYPES.DELETE_PROVISION_ORDER_REQUEST_FAILURE,
    payload: {
        error
    }
});
export const clearProvisionOrderRequestListData = () => ({
    type: ACTION_TYPES.CLEAR_DELETE_PROVISION_ORDER_REQUEST
});

// GET acquisition type list
export const getAcquisitionTypesListRequest = () => ({
	type: ACTION_TYPES.GET_ACQUISITION_TYPES_LIST_REQUEST
});
export const getAcquisitionTypesListRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ACQUISITION_TYPES_LIST_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAcquisitionTypesListRequestFailure = error => ({
	type: ACTION_TYPES.GET_ACQUISITION_TYPES_LIST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearAcquisitionTypesList = () => ({
	type: ACTION_TYPES.CLEAR_ACQUISITION_TYPES_LIST
});
