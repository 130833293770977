import * as ACTION_TYPES from './wageTemplateTypes';

export const wageTemplateReducerInitialState = {
	WageTemplateList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	WageTemplateNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	WageTemplatePut: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	WageTemplateItems: [],
	WageTemplateDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	WageTemplateToEdit: {},
	WageTemplateDetail: {},
	recruitmentMethods: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};

export const wageTemplateReducer = ( state = wageTemplateReducerInitialState, action ) => {
	switch( action.type ) {

		// Wage Template list actions
		case ACTION_TYPES.GET_WAGE_TEMPLATE_REQUEST: {
			return {
				...state,
				WageTemplateList: {
					...state.WageTemplateList,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_WAGE_TEMPLATE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			const previousRecords = state.WageTemplateList.data?.data;

			if( previousRecords ) {
				data.data = previousRecords.concat(data.data);
			}
			return {
				...state,
				WageTemplateList: {
					...state.WageTemplateList,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_WAGE_TEMPLATE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				WageTemplateList: {
					...state.WageTemplateList,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_WAGE_TEMPLATE_REQUEST: {
			return {
				...state,
				WageTemplateList: {
					...wageTemplateReducerInitialState.WageTemplateList
				}
			};
		}
		// END 

		// NEW Wage Template
		case ACTION_TYPES.POST_WAGE_TEMPLATE_REQUEST: {
			return {
				...state,
				WageTemplateNew: {
					...state.WageTemplateNew,
					isFetching: true,
					hasError: false,
					error: wageTemplateReducerInitialState.WageTemplateNew.error
				}
			};
		}
		case ACTION_TYPES.POST_WAGE_TEMPLATE_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				WageTemplateNew: {
					...state.WageTemplateNew,
					isFetching: false,
					hasError: false,
					error: wageTemplateReducerInitialState.WageTemplateNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.POST_WAGE_TEMPLATE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				WageTemplateNew: {
					...state.WageTemplateNew,
					isFetching: false,
					hasError: true,
					data: wageTemplateReducerInitialState.WageTemplateNew.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_WAGE_TEMPLATE_REQUEST: {

			return {
				...state,
				WageTemplateNew: wageTemplateReducerInitialState.WageTemplateNew
			}
		}
		// END


		case ACTION_TYPES.SELECTED_WAGE_TEMPLATE:{
			const { data } = action.payload;
			return {
				...state,
				WageTemplateItems: [...state.WageTemplateItems, data]
			}
		}
		case ACTION_TYPES.DELETED_WAGE_TEMPLATE:{
			const { data } = action.payload;
			return {
				...state,
				WageTemplateItems: state.WageTemplateItems.filter(
					items => items.code !== data
				)
			}
		}
		case ACTION_TYPES.CLEAR_SELECTED_WAGE_TEMPLATE:{
			return{
				...state,
				WageTemplateItems: []
			}
		}
		// END

		// DELETE Wages template
		case ACTION_TYPES.DELETE_WAGE_TEMPLATE_REQUEST: {
			return {
				...state,
				WageTemplateDelete: {
					...state.WageTemplateDelete,
					isFetching: true,
					hasError: false,
					error: wageTemplateReducerInitialState.WageTemplateDelete.error,
					data: wageTemplateReducerInitialState.WageTemplateDelete.data
				}
			};
		}
		case ACTION_TYPES.DELETE_WAGE_TEMPLATE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				WageTemplateDelete: {
					...state.WageTemplateDelete,
					isFetching: false,
					hasError: false,
					error: wageTemplateReducerInitialState.WageTemplateDelete.error,
					data
				}
			};
		}
		case ACTION_TYPES.DELETE_WAGE_TEMPLATE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				WageTemplateDelete: {
					...state.WageTemplateDelete,
					isFetching: false,
					hasError: true,
					data: wageTemplateReducerInitialState.WageTemplateDelete.data,
					error
				}
			};
		}
		// END

		/* BEGIN fund Request type list */
		case ACTION_TYPES.LIST_RECRUITMENT_METHOD_REQUESTS: {
			return {
				...state,
				recruitmentMethods: {
					...state.recruitmentMethods,
					isFetching: true,
					hasError: false,
					error: wageTemplateReducerInitialState.recruitmentMethods.error
				}
			};
		}

		case ACTION_TYPES.LIST_RECRUITMENT_METHOD_REQUESTS_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				recruitmentMethods: {
					...state.recruitmentMethods,
					isFetching: false,
					hasError: false,
					error: wageTemplateReducerInitialState.recruitmentMethods.error,
					data
				}
			};
		}

		// PUT wage template to edit
		case ACTION_TYPES.PUT_WAGE_TEMPLATE_REQUEST: {
			return {
				...state,
				WageTemplatePut: {
					...state.WageTemplatePut,
					isFetching: true,
					hasError: false,
					error: wageTemplateReducerInitialState.WageTemplatePut.error
				}
			};
		}
		case ACTION_TYPES.PUT_WAGE_TEMPLATE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				WageTemplatePut: {
					...state.WageTemplatePut,
					isFetching: false,
					hasError: false,
					error: wageTemplateReducerInitialState.WageTemplatePut.error,
					data
				}
			};
		}
		case ACTION_TYPES.PUT_WAGE_TEMPLATE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				WageTemplatePut: {
					...state.WageTemplatePut,
					isFetching: false,
					hasError: true,
					data: wageTemplateReducerInitialState.WageTemplatePut.data,
					error
				}
			};
		}
		// END

		// TO EDIT wage template
		case ACTION_TYPES.SET_WAGE_TEMPLATE_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				WageTemplateToEdit: data
			}
		}
		case ACTION_TYPES.CLEAR_WAGE_TEMPLATE_TO_EDIT:{
			const { data } = action.payload;

			return{
				...state,
				WageTemplateToEdit: data
			}
		}
		// END

		// DETAIL
		case ACTION_TYPES.SET_WAGE_TEMPLATE_DETAIL: {
			const { data } = action.payload;
			return {
				...state,
				WageTemplateDetail: data
			}
		}
		case ACTION_TYPES.CLEAR_WAGE_TEMPLATE_DETAIL: {
			const { data } = action.payload;
			return{
				...state,
				WageTemplateDetail: data
			}
		}
		// END
			
		case ACTION_TYPES.LIST_RECRUITMENT_METHOD_REQUESTS_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				recruitmentMethods: {
					...state.recruitmentMethods,
					isFetching: false,
					hasError: false,
					data: wageTemplateReducerInitialState.recruitmentMethods.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_LIST_RECRUITMENT_METHOD_REQUEST: {
			const { data } = action.payload;
			return {
				...state,
				recruitmentMethods: {
					...state.recruitmentMethods,
					type: data
				}
			}
		}
		case ACTION_TYPES.CLEAR_STATES_LIST_RECRUITMENT_METHOD_REQUESTS: {
			return {
				...state,
				recruitmentMethods: {
					...state.recruitmentMethods,
					type: undefined
				}
			}
		}
		/* END fund Request type list */

		default: {
			return state;
		}
	}
};

export const getWageTemplateReducer = state => state.wageTemplateReducer;

// GET Wage Template
export const getWageTemplateList = state => getWageTemplateReducer(state)?.WageTemplateList;
export const getWageTemplateListData = state => getWageTemplateList(state)?.data;
export const getWageTemplateListIsFetching = state => getWageTemplateList(state)?.isFetching;

// POST Wage Template
export const getWageTemplateCreate = state => getWageTemplateReducer(state)?.WageTemplateNew;
export const getWageTemplateCreateIsFetching = state => getWageTemplateCreate(state)?.isFetching;
export const getWageTemplateCreateIsError = state => getWageTemplateCreate(state)?.hasError;

// DELETE Wage Template
export const getWageTemplateDelete = state => getWageTemplateFundRequestReducer(state)?.WageTemplateDelete;
export const getWageTemplateDeleteData = state => getWageTemplateDelete(state)?.data;
export const getWageTemplateDeleteIsFetching = state => getWageTemplateDelete(state)?.isFetching;

export const getWageTemplateItems = state => getWageTemplateReducer(state)?.WageTemplateItems;

// EDIT Wage Template
export const getWageTemplateToEdit = state => getWageTemplateReducer(state)?.WageTemplateToEdit;

// DETAIL Wage Template 
export const getWageTemplateDetail = state => getWageTemplateReducer(state)?.WageTemplateDetail;

// Search Recruitment Methods
export const getRecruitmentMethods = state => getWageTemplateReducer(state)?.recruitmentMethods;
export const getRecruitmentMethodsData = state => getRecruitmentMethods(state)?.data;
export const getRecruitmentMethodsIsFetching = state => getRecruitmentMethods(state)?.isFetching;
export const getSelectedRecruitmentMethods = state => getRecruitmentMethods(state)?.type;
