import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import ActionIcon from 'src/components/general/ActionIcon';
import FormFieldError from 'src/components/general/FormFieldError';
import { NumericFormat } from 'react-number-format';
import { tryGetPersonaNameByCuit } from 'src/redux/user/userActionCreator';
import { getCuitDataIsFetching } from 'src/redux/user/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getMsjAFIP } from 'src/utils/utils';

const InputCuitForm = ({ inputName, provider }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const { orderPay, register, setValue, errors } = useFormContext()
    const [messajeValidacion, setMessajeValidacion] = useState()
    const [messajeValidacionColor, setMessajeValidacionColor] = useState('success')
    const [validarPersona, setValidarPersona] = useState(false)
    const [removeState, setRemoveState] = useState(false)
    const [cuitValue, setCuitValue] = useState("");
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [validatedPerson, setValidatedPerson] = useState(false);

    //CUIT
	const cuitDataIsFetching = useSelector(state => getCuitDataIsFetching(state));

    // Validations
    const cuitRequiredMsg = 'Debe ingresar un CUIT válido'
    const denominationRequiredMsg = 'Debe ingresar una denominación'
    
    // Form data
    const cuitValidationObj = { required: cuitRequiredMsg }
    const denominationValidationObj = { required: denominationRequiredMsg }

    const handleInputProveedor = (value) => {
        setCompanyNameValue(value);
    };

    const inputProvider = (provider) => {
        setCuitValue(provider?.persona?.cuit || '');
        setCompanyNameValue(provider?.persona?.companyName || '');
    }

    const clearBeneficiario = () => {
        setCuitValue('');
        setCompanyNameValue('');
        setRemoveState(false);
        setValidarPersona(false);
        setValidatedPerson(false);
    } 

    const handleInputChange = (event) => {
        let cuit = event.target.value.replace(/-/g, "")
        let newCuit = cuit.replace(/X/g, "");
        setCuitValue(event?.target?.value);
        if (newCuit.length == 11 && !isLoading) {
            setIsLoading(true);
            dispatch(tryGetPersonaNameByCuit(newCuit)).then((response)=>{
                setIsLoading(false);
                const responseData = typeof response?.data == "string" ? JSON.parse(response?.data || '{}') : response?.data;
                const proveedor = {
                    cuit: newCuit,
                    id: responseData?.id,
                    name: responseData?.companyName,
                    beneficiaryValidator: responseData?.beneficiaryValidator,
                    providerNumber: responseData?.providerNumber
                };
                proveedor?.beneficiaryValidator ? setMessajeValidacionColor('success') : setMessajeValidacionColor('warning');
                proveedor?.name && delete errors?.acreedor_denominacion;
                proveedor ? setValidarPersona(true) : setValidarPersona(false);
                proveedor?.id ? setValidatedPerson(true) : setValidatedPerson(false);
                setMessajeValidacion(getMsjAFIP(proveedor?.beneficiaryValidator, proveedor?.providerNumber));                
                setCompanyNameValue(proveedor?.name || '');
                if (proveedor) { setRemoveState(true) }           
            })   
        }   
    }

    useEffect(() => {
        if(provider?.id){
            inputProvider(provider);
            setMessajeValidacionColor('success');
            setRemoveState(true);
            setValidatedPerson(true);
            setValidarPersona(false);
            setMessajeValidacion(getMsjAFIP(provider?.persona?.beneficiaryValidator, provider?.persona?.providerNumber)); 
        } else if (provider) {
            setValidatedPerson(false);
            setValidarPersona(true);
            setMessajeValidacionColor('warning');
            setRemoveState(true);
        };        
    }, [provider])

    return <>
        <Form.Group as={Row}>
            <Form.Label className='text-right d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                {inputName}
            </Form.Label>
            <Col sm='4'>
                <div className="input-group" >
                    <NumericFormat
                        className="form-control mr-2"
                        autoComplete="off"
                        type='text'
                        name='cuit'
                        placeholder='CUIT'
                        value={cuitValue}
                        readOnly={validatedPerson || validarPersona || cuitDataIsFetching}
                        onChange={handleInputChange}
                        format="##-########-#"
                        mask="X"
                        pattern="\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]"
                        getInputRef={register(cuitValidationObj)}
                    />
                    <Form.Control
                        autoComplete="off"
                        type='text'
                        name='name'
                        value={companyNameValue}
                        readOnly={validatedPerson || cuitDataIsFetching}
                        placeholder='Denominación'
                        onChange={(event) => handleInputProveedor(event?.target?.value)}
                        ref={register(denominationValidationObj)}
                    />
                </div>
            </Col>
            <Col sm={1}>
                <ActionIcon 
                    size='lg' 
                    toolTipText='Quitar' 
                    icon={faTimes} 
                    className={'text-danger mt-1' + (removeState ? '' : ' d-none')} 
                    onClick={clearBeneficiario} 
                />
            </Col>
            <Col sm={4}></Col>
            <Col sm={4}>
                <FormFieldError errors={errors?.cuit} />
                <FormFieldError errors={errors?.companyName} />
                {
                    (validarPersona || validatedPerson) &&
                    <Alert variant={messajeValidacionColor} className='form-field-error mb-0 py-1 mt-1'>
                        {messajeValidacion}
                    </Alert>
                }
            </Col>
        </Form.Group>
    </>
}

export default InputCuitForm;