import * as ACTION_TYPES from './accountActionTypes';



// Account list actions
export const getAccountListRequest = () => ({
	type: ACTION_TYPES.LIST_ACCOUNT_REQUEST
});

export const getAccountListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ACCOUNT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAccountListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ACCOUNT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearAccountList = () => ({
	type: ACTION_TYPES.CLEAR_ACCOUNT_LIST
});
// END Account list actions