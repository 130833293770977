import * as ACTION_TYPES from './purposeActionTypes';

export const purposeReducerInitialState = {
	purposeList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	}
};

export const purposeReducer = ( state=purposeReducerInitialState, action ) => {
	switch( action.type ) {

		// Purpose list actions
		case ACTION_TYPES.LIST_PURPOSE_REQUEST: {
			return {
				...state,
				purposeList: {
					...state.purposeList,
					isFetching: true,
					hasError: false,
					data: purposeReducerInitialState.purposeList.data,
					error: purposeReducerInitialState.purposeList.error
				}
			};
		}

		case ACTION_TYPES.LIST_PURPOSE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				purposeList: {
					...state.purposeList,
					isFetching: false,
					hasError: false,
					error: purposeReducerInitialState.purposeList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_PURPOSE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				purposeList: {
					...state.purposeList,
					isFetching: false,
					hasError: true,
					data: purposeReducerInitialState.purposeList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_PURPOSE_LIST: {
			return {
				...state,
				purposeList: purposeReducerInitialState.purposeList
			};
		}
		// END Purpose list actions

		default: {
			return state;
		}
	}
};

export const getPurposeReducer = state => state.purposeReducer;

export const getPurposeList = state => getPurposeReducer(state)?.purposeList;
export const getPurposeListData = state => getPurposeList(state)?.data;
export const getPurposeListIsFetching = state => getPurposeList(state)?.isFetching;
export const getPurposeListHasError = state => getPurposeList(state)?.hasError;
export const getPurposeListError = state => getPurposeList(state)?.error;