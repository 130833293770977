import axios from 'axios';
import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, buildURLQuery, httpPost, httpPut, httpDelete } from './httpServices';

import { getClearObject } from 'src/utils/utils';

const API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const PROVISION_ORDER_URI = `${API_BASE_URI}/provision-orders`;

// GET Provision Order
export const getProvisionOrder = async (accessToken, params) => {
	let queryString = "";
	let URL = "";
	const options = buildDefaultOptions(accessToken);
	let response;

	if (params != undefined) {
		let setBuildURLQuery = {
			page: params?.page || 0,
			size: params?.size || 10,
			sort: params?.sort || 'number,desc',
			administrativeServiceId: params?.administrativeServiceId,
			'ad.codeOrganism': params?.['ad.codeOrganism'],
			'ad.number': params?.['ad.number'],
			'ad.year': params?.['ad.year'],
			number: params?.number,
			year: params?.year,
			initiator: params?.initiator,
			name: params?.name,
			legalInstrumentType: params?.legalInstrumentType,
			legalInstrumentNumber: params?.legalInstrumentNumber,
			legalInstrumentYear: params?.legalInstrumentYear,
			acquisitionType: params?.acquisitionType,
			beneficiaryName: params?.beneficiaryName
		};
		setBuildURLQuery = getClearObject(setBuildURLQuery);
		queryString = buildURLQuery(setBuildURLQuery);
		URL = `${PROVISION_ORDER_URI}?${queryString}`;
	} else {
		URL = `${PROVISION_ORDER_URI}`;
	}

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
};

// EDIT Provision Order
export const editProvisionOrder = async (accessToken, id, params) => {
	const URL = `${PROVISION_ORDER_URI}/${id}`
	const options = buildDefaultOptions(accessToken);
	let response;
	try {
		response = await httpPut(URL, params, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

//GET BY ID
export const getProvisionOrderId = async (accessToken, id) => {
	const URL = `${PROVISION_ORDER_URI}/${id}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
}

//GET Provision order to clone by ID
export const getProvisionOrderToCloneById = async (accessToken, id) => {
	const URL = `${PROVISION_ORDER_URI}/${id}/duplicateData`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
};

// POST Provision Order
export const postProvisionOrder = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPost(PROVISION_ORDER_URI, params, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

// DELETE Provision Order
export const deleteProvisionOrder = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${PROVISION_ORDER_URI}/${id}`;
	let response;
	try {
		response = await httpDelete(URL, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

//GET ACQUISITON TYPES LIST
export const getAcquisitionTypesList = async (accessToken) => {

	const URL = `${PROVISION_ORDER_URI}/acquisitiontypes`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
}
