import React, { useEffect } from 'react';
import { useDispatch,  useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExclamationTriangle, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';

import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';
import { SERVICE_ADMINISTRATIVE_LIST } from 'src/utils/constants';
import { listUsersByProfile } from 'src/utils/usersProfiles';
import { buildFullName } from 'src/utils/utils';

import { tryGetUsersAdministrativeServices, tryAddUserAdministrativeService, tryRemoveUserAdministrativeService} from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceUsers, getAdministrativeServiceUsersListData } from 'src/redux/administrativeService/administrativeServiceReducer';

import { getUserListData, getUserListIsFetching } from 'src/redux/user/userReducer';
import { clearUserList } from 'src/redux/user/userActions';
import { tryGetUserList, tryGetListUserProfile, tryGetListUserXApplicationXProfile } from 'src/redux/user/userActionCreator';

const ServiceAdministrativeUserPage = props => {

    const dispatch = useDispatch();

    //List Users by Profiles
    const listUsersByProfilesData = listUsersByProfile();
    
    //Asignated Users
	const servicesUsersList = useSelector( state => getAdministrativeServiceUsersListData (state) );
    
    // Find List Users Total Not Asignated
    const listUserRecords = listUsersByProfilesData?.filter( value => { 
        return !servicesUsersList?.find( value2 => value.id == value2.id );
    })?.sort( (a,b) => a.name>b.name ? 1 : -1);

    const hasListUser = isNotEmptyArray(listUserRecords);
    const listUserIsFetching = useSelector(state => getUserListIsFetching(state));

     // Find List Users Asignated
    const serviceUserListRecords = listUsersByProfilesData?.filter( value => { 
        return servicesUsersList?.find( value2 => value.id == value2.id );
    })?.sort( (a,b) => a.name>b.name ? 1 : -1);
    const hasServiceUserList = isNotEmptyArray(serviceUserListRecords);

    const loadAllData = () => {
        //listUsersByProfile
        dispatch(tryGetListUserXApplicationXProfile());
		dispatch(tryGetListUserProfile());
        dispatch( clearUserList() );
        dispatch( tryGetUsersAdministrativeServices (serviceAdministrativeUser.id)).then(
            response => { dispatch(tryGetUserList()); }
        );
        
    }

    useEffect(loadAllData, []);

    // UsersAdminstrativeServices
    const serviceAdministrativeUser = useSelector( state => getAdministrativeServiceUsers (state) );

    const onClickBackButton = () => {
        dispatch(push(SERVICE_ADMINISTRATIVE_LIST));
    };

    const onClickAddUserAdministrativeService = value => {
        const params = [value.id]
            dispatch( tryAddUserAdministrativeService (serviceAdministrativeUser.id, params)).then( response => {
                loadAllData();
            } );
    };

    const onClickRemoveUserAdministrativeService = item => {
        const params = [item.id]
            dispatch( tryRemoveUserAdministrativeService (serviceAdministrativeUser.id, params)).then( response => {
                loadAllData();
            } );
    };

    return <Container className='mb-5'>
        <Card>
            <PageTitle text='Servicios' />

            <Container fluid>
                <Card className='mb-3'>
                    <Card.Header className='h6'>
                        Gestión de usuarios de: {serviceAdministrativeUser.name}
					</Card.Header>
                    <Card.Body>
                        <p className='text-black-color h6' >
                            Usuarios disponibles:
						</p>
                        <Table className='mb-0' striped bordered hover size='sm'>
                            <thead>
                                <tr>
                                    <th className='text-center'>Apellido y nombre</th>
                                    <th className='text-center'>Usuario</th>
                                    <th className='text-center'>Acciones</th>
                                </tr>

                            </thead>
                            <tbody className='text-black-color'>
                            {
                                 hasListUser
                                ?
                                listUserRecords?.map((user, idx) => 
                               
                                    <tr key={idx}>
                                        <td className='text-center'>{buildFullName(user.name)}</td>
                                        <td className='text-center'>{user.email}</td>
                                        <td className='text-center'> <ActionIcon  size='lg' icon={faPlusCircle} id='adduser' toolTipText='Asignar usuario' className='mr-2' onClick={ () => onClickAddUserAdministrativeService(user) } /></td>
                                    </tr>
                              
                               )
                            
                            :
                                <tr>
                                    <td colSpan='6' className='text-center'>
                                    {
                                       listUserIsFetching
                                       ? 
                                       <Spinner animation='border' variant='success' className='my-3' />
                                       :
                                        <Alert variant='info' className='mb-0'>
                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
												No hay registros
											</Alert>
                                        }
                                    </td>
                                </tr>
                            }

                            </tbody>
                        </Table>

                        <hr />

                        <p className='text-black-color h6' >
                            Usuarios asignados:
						</p>
                        <Table className='mb-0' striped bordered hover size='sm'>
							<thead>
								<tr>
									<th className='text-center'>Apellido y nombre</th>
									<th className='text-center'>Usuario</th>
                                    <th className='text-center'>Acciones</th>
								</tr>
								
							</thead>
							<tbody className='text-black-color'>
							{
									hasServiceUserList ?
									serviceUserListRecords?.map( (item, idx) =>
                                        <tr key={idx}>
                                            <td className='text-center'>{buildFullName(item.name)}</td>
                                            <td className='text-center'>{item.email}</td>
                                            <td className='text-center'> <ActionIcon  size='lg' icon={faTimesCircle} id='removeuser' toolTipText='Quitar usuario' className='mr-2 text-danger' onClick={ () => onClickRemoveUserAdministrativeService(item) } /></td>
                                        </tr>)
								:
								<tr>
									<td colSpan='6' className='text-center'>
									{
										listUserIsFetching ?
										<Spinner animation='border' variant='success' className='my-3' />
										:
										<Alert variant='info' className='mb-0'>
											<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
												No hay registros
											</Alert>
									}
									</td>
								</tr>
							}
							</tbody>
						</Table>
                        <div className='text-center'>
                            <Button variant='danger' size='lg' className='my-3' onClick={onClickBackButton}>
                                <FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
								Volver
							</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    </Container>;
};

export default ServiceAdministrativeUserPage;