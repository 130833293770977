import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card } from 'react-bootstrap';
import PageTitle from 'src/components/general/PageTitle';
import { tryAddNewBankAccount } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestActionCreator';
import { push } from 'connected-react-router';
import { BANK_ACCOUNT_LIST, BANK_ACCOUNT_NEW } from 'src/utils/constants';
import BankAccountFormAM from './BankAccountFormAM';
import { getBankAccountAddNewIsFetching } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer';

const BankAccountNewPage = () => {
    const dispatch = useDispatch();
    const redirectToBankAccountList = () => { dispatch(push(BANK_ACCOUNT_LIST)) };
    const redirectToBankAccountNewPage = () => { dispatch(push(BANK_ACCOUNT_NEW)) };

    const isFetchingCreateBankAccount = useSelector(state => getBankAccountAddNewIsFetching(state));

    const onFormSubmit = (data, createAgain) => {
        dispatch(tryAddNewBankAccount(data)).then(
            () => createAgain ? redirectToBankAccountNewPage() : redirectToBankAccountList());
    };

    return <>
        <Container fluid>
            <Card className='mb-3'>
                <Card.Header className='h6'>
                    Nueva Cuenta Bancaria
                </Card.Header>
                <Card.Body>
                    <BankAccountFormAM
                        onFormSubmit={onFormSubmit}
                        isFetching={isFetchingCreateBankAccount}
                    />
                </Card.Body>
            </Card>
        </Container>
    </>
};

export default BankAccountNewPage;