import * as ACTION_TYPES from './expedientActionTypes';

export const expedientReducerInitialState = {
	expedientList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	expedientEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	expedientToEdit: {}
};

export const expedientReducer = ( state=expedientReducerInitialState, action ) => {
	switch( action.type ) {

		// Expedient List 
		case ACTION_TYPES.LIST_EXPEDIENT_REQUEST: {
			return {
				...state,
				expedientList: {
					...state.expedientList,
					isFetching: true,
					hasError: false,
					error: expedientReducerInitialState.expedientList.error,
					data: expedientReducerInitialState.expedientList.data,
				}
			};
		}

		case ACTION_TYPES.LIST_EXPEDIENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				expedientList: {
					...state.expedientList,
					isFetching: false,
					hasError: false,
					error: expedientReducerInitialState.expedientList.error,
					data
				}
			};
		}
		
		case ACTION_TYPES.LIST_EXPEDIENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				expedientList: {
					...state.expedientList,
					isFetching: false,
					hasError: true,
					data: expedientReducerInitialState.expedientList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_EXPEDIENT_LIST: {
			return {
				...state,
				expedientList: expedientReducerInitialState.expedientList
			};
		}
		// END // Expedient List 

		//Edit Expedient
		case ACTION_TYPES.EDIT_EXPEDIENT_REQUEST: {
			return {
				...state,
				expedientEdit: {
					...state.expedientEdit,
					isFetching: true,
					hasError: false,
					error: expedientReducerInitialState.expedientEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_EXPEDIENT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				expedientEdit: {
					...state.expedientEdit,
					isFetching: false,
					hasError: false,
					error: expedientReducerInitialState.expedientEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_EXPEDIENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				expedientEdit: {
					...state.expedientEdit,
					isFetching: false,
					hasError: true,
					data: expedientReducerInitialState.expedientEdit.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_EXPEDIENT_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				expedientToEdit: data
			}
		}


		default: {
			return state;
		}
	}
};

export const getExpedientReducer = state => state.expedientReducer;

export const getExpedientList = state => getExpedientReducer(state)?.expedientList;
export const getExpedientListData = state => getExpedientList(state)?.data;
export const getExpedientListError = state => getExpedientList(state)?.error;
export const getExpedientListIsFetching = state => getExpedientList(state)?.isFetching;
export const getExpedientListHasError = state => getExpedientList(state)?.hasError;

// Expedient to edit
export const getExpedientToEdit = state => getExpedientReducer(state)?.expedientToEdit;
export const getExpedientEdit = state => getExpedientReducer(state)?.expedientEdit;
export const getExpedientEditIsFetching = state => getExpedientEdit(state)?.isFetching;