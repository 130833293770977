import React, { useEffect, useRef, useState } from 'react';



const RemesasForm = () => {

    return (
        <div>Orden de Remesas</div> 
    )
       

}

export default RemesasForm;