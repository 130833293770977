import * as ACTION_TYPES from './globalDataActionTypes';


export const getPeriodsList = () => ({
	type: ACTION_TYPES.LIST_PERIODS_REQUEST
});

export const getPeriodsListSuccess = data => ({
	type: ACTION_TYPES.LIST_PERIODS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getPeriodsListFailure = error => ({
	type: ACTION_TYPES.LIST_PERIODS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setSelectedPeriod = period => ({
	type: ACTION_TYPES.SET_SELECTED_PERIOD,
	payload: {
		period
	}
});

export const clearPeriodsData = () => ({
	type: ACTION_TYPES.CLEAR_PERIODS_DATA
});

export const setReportDates = reportDates => ({
	type: ACTION_TYPES.SET_REPORT_DATES,
	payload: {
		reportDates
	}
});

export const getFilterObject = () => ({
	type: ACTION_TYPES.GET_FILTER_OBJECT
});

export const setFilterObject = data => ({
	type: ACTION_TYPES.SET_FILTER_OBJECT,
	payload: {
		data
	}
});

export const clearFilterObject = () => ({
	type: ACTION_TYPES.CLEAR_FILTER_OBJECT
});

export const getSelectedCredits = () => ({
	type: ACTION_TYPES.GET_SELECTED_CREDITS
});

export const setSelectedCredits = data => ({
	type: ACTION_TYPES.SET_SELECTED_CREDITS,
	payload: {
		data
	}
});

export const clearSelectedCredits = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_CREDITS
});

export const getBankAccountFilters = () => ({
	type: ACTION_TYPES.GET_BANK_ACCOUNT_FILTERS
});

export const setBankAccountFilters = data => ({
	type: ACTION_TYPES.SET_BANK_ACCOUNT_FILTERS,
	payload: {
		data
	}
});

export const clearBankAccountFilters = () => ({
	type: ACTION_TYPES.CLEAR_BANK_ACCOUNT_FILTERS
});