// User
export const nameUser = 'Nombre de usuario'
export const currentCredit = 'Crédito Actual'
export const newUser = 'Nuevo usuario'
export const name = 'Nombre'
export const nameShort = 'Nombre corto'
export const description = 'Descripción'
export const user = 'Usuario'
export const users = 'Usuarios'
export const required = 'Obligatorio'
export const lastName = 'Apellido'
export const lastNameName = 'Apellido y nombre'
export const password = 'Contraseña'
export const currentPassword = 'Contraseña actual'
export const newPassword = 'Nueva contraseña'
export const confirmPassword = 'Confirmar contraseña'
export const confirmNewPassword = 'Confirmar nueva contraseña'
export const changePassword = 'Cambiar contraseña'
export const email = 'Correo electrónico'
export const officialEmail = 'Correo electrónico oficial'
export const group = 'Grupo'
export const groups = 'Grupos'
export const newGroup = 'Nuevo grupo'
export const cancel = 'Cancelar'
export const save = 'Guardar'
export const userEmail = 'Usuario/Email'
export const actions = 'Acciones'
export const seeMore = 'Ver más'
export const seeLess = 'Ver menos'
export const noUser = 'No hay usuarios'
export const alert = 'Alerta'
export const questionDeleteUser = 'Realmente deseas eliminar al usuario '
export const filterUser = 'Filtrar usuario'
export const editUser = 'Editar usuario'
export const editGroup = 'Editar usuario'
export const createNewPassword = 'Crear nueva contraseña'
export const confirNewPassword = 'Confirmación de nueva contraseña'
export const send = 'Guardar cambios'
export const restartLog = 'Volver al Inicio de Sesión'
export const acces = 'Acceder'
export const forgetPassword = 'Olvidó su contraseña'
export const emailExample = 'example@example.com'
export const login = 'Ingrese a su cuenta'
export const next = 'Siguiente'
export const numberCredit = 'Nº de partida'
export const detail = 'Extracto'
export const saveLoadAnother = 'Guardar y cargar otro'
export const saveAndFinish = 'Guardar y finalizar'
export const accountPlaceholder = 'No hay cuentas especiales seleccionables';
export const unOrgAbbreviation = 'U. Org.'
export const totalCurrentCredit = 'Total crédito actual'
export const balanceOfFilteredItems = 'Total de Saldo'
export const creditDetail = 'Detalles de la partida'
export const creditBalance = 'Saldo de la partida'
export const detailImpact = 'Detalles de la afectación'
export const typeImpact = 'Tipo de afectación'
export const amountAffectation = 'Monto de afectación'
export const quotaNumber = 'N° de cuota'
export const finalize = 'Finalizar'
export const dateImpact='Fecha de afectación'
export const preventiveDefinitive="Preventiva / Definitiva"
export const preventiveDefinitiveObligPayment='Preventiva / Definitiva / Oblig. Pago'
export const definitiveObligPayment='Definitiva / Oblig. Pago'
export const preventiveAccumulator = 'Acumulador Preventiva'
export const preventiveBalance = 'Saldo Preventiva'
export const ultimateAccumulator = 'Acumulador Definitiva'
export const finalBalance = 'Saldo Definitiva'
export const saldoOblPago = 'Saldo obl. Pago'
export const saldoObligaciondePago = 'Saldo Obligación de pago'
export const acumuladorOblPago = 'Acumulador obl. de pago'
export const paidAccumulator = 'Acumulador pago'
export const paymentBalance = 'Saldo Pago'
export const preventive = 'Preventivo'
export const balancePartial = 'Saldo de Partida'
export const definitive = 'Definitivo'
export const obligationPay = 'Obligación de pago'
export const pay = 'Pago'
export const modifications = 'Modificaciones'
export const balances = 'Saldos'
export const nroCredit = 'Nro. de partida'
export const uOrganizaciónOrganismo = 'Unidad de Organización/Organismo'
export const cuentaCuentaEspecial = 'Cuenta / Cuenta especial'
export const creditModification = 'Modificaciones a la partida'
export const notModifications = 'No hay modificaciones'
export const organismFather='Organismo padre'
export const organismClassifier='Clasificador de organismo'
export const phone='Teléfono'
export const direction='Dirección'
export const organismCode='Código de organismo'
export const organismAdministrativeCode='Código de Organismo Administrativo'
export const administrativeCode='Código administrativo'
export const upToDay='Al día'
export const accountValidated = 'Cuenta validada'
export const profile = 'Perfil'
export const active = 'Activo'
export const testEnvironment = "Entorno de pruebas"
export const noRecords = 'No hay registros'
export const ID = 'ID'

//Caption
export const caption = 'Leyenda'
export const captions= 'Leyendas'
export const newCaption = 'Nueva Leyenda'
export const editCaption = 'Editar Leyenda'
export const filterCaption = 'Filtrar leyenda'
export const filterByYear = 'Filtrar por año'
export const noCaption = 'No hay leyendas.'
export const questionDeleteCaption = 'Realmente deseas eliminar la leyenda '

//Alert
export const alerts= 'Alertas'
export const newAlert = 'Nueva Alerta'
export const editAlert = 'Editar Alerta'
export const filterAlert = 'Filtrar alerta'
export const noAlert = 'No hay alertas.'
export const questionDeleteAlert = 'Realmente deseas eliminar la alerta '
export const level = 'Nivel '
export const messageAlert = 'Mensaje de la Alerta'

//Credit
export const emptyCredit = 'No hay partidas'
export const budgetCredits = 'Partidas Presupuestarias'
export const back = 'Volver'
export const newCredit = 'Nueva modificación de crédito'
export const detailsCredit = 'Detalles de partida presupuestaria';
export const total = 'Total'
export const serviceLabel = 'Servicio';
export const servicesLabel = 'Servicios';
export const periods = 'Periodos';
export const serviceAbbreviation = 'Serv.'
export const jurisdiction = 'Jurisdicción'
export const jurisdictionAbbreviation = 'Jur.'
export const characterLabel = 'Carácter'
export const modifAmount='Importe modificación'
export const characterAbbreviation = 'Car.'
export const account = 'Cuenta'
export const shortAccountAbbreviation = 'Cta.'
export const accountAbbreviation = 'Cta./Cta. E.'
export const purpose = 'Finalidad'
export const purposeAbbreviation = 'Fin.'
export const functio = 'Función'
export const functionAbbreviation = 'Fun.'
export const creditInitial = 'Partida Principal'
export const creditInitialAbbreviation = 'P. Princ.'
export const creditPartial = 'Partida Parcial'
export const creditPartialAbbreviation = 'P. Parc.'
export const code = 'Código'
export const administrativeServiceOrganismCode = 'Código de Expediente'
export const codeAbbreviation = 'Cód.'
export const subcode = 'Subcódigo'
export const subcodeAbbreviation = 'S.Cód.'
export const creditOrigin = 'Crédito original'
export const creditOriginAbbreviation = 'Cred. Orig.'
export const creditCurrent = 'Crédito actual'
export const creditCurrentAbbreviation = 'Cred. Act.'
export const creditModify = 'Modificaciones'
export const creditModifyAbbreviation = 'Modif.'
export const tipe = 'Tipo'
export const date = 'Fecha'
export const attachedFile = 'Archivo adjunto'
export const attachedFiles='Adjunto/s'
export const dowload = 'Descargar'
export const generate = 'Generar'
export const dateLegalInstrument = 'Fecha del inst. legal'
export const numberAbbreviation = 'Nro.'
export const numberlegalInstrument = 'Nro. Instrumento Legal'
export const file = 'Archivo'
export const notFound = 'Sin resultados'
export const amountRequiredMsg = 'Debe ingresar un importe.'
export const loadingPlaceholder = 'Cargando...';
export const amount = 'Crédito actual'
export const servicePlaceholder = 'No hay servicios seleccionables';
export const jurisdictionPlaceholder = 'No hay jurisdicciones seleccionables';
export const organizationPlaceholder = 'No hay unidades de organización seleccionables';
export const characterPlaceholder = 'No hay caracteres seleccionables'
export const purposePlaceholder = 'No hay finalidades seleccionables'
export const functionalityPlaceholder = 'No hay funciones seleccionables'
export const sectionPlaceholder = 'No hay secciones seleccionables'
export const sectorPlaceholder = 'No hay sectores seleccionables'
export const principalBudgetPlaceholder = 'No hay partidas principales seleccionables'
export const partialBudgetPlaceholder = 'No hay partidas parciales seleccionables'
export const codePlaceholder = 'No hay códigos seleccionables';
export const subcodePlaceholder = 'No hay subcódigos seleccionables'
export const noResult = 'No hay resultados'
export const section = 'Sección'
export const sector = 'Sector'
export const sectionAbbreviation = 'Secc.'
export const sectorAbbreviation = 'Sect.'
export const unidOrgAbbreviation = 'U. Org.'
export const specialAccounts = 'Cuenta / Cuenta especial'
export const selectOption = 'Seleccione una opción'
export const confirmationNewInvolvement = 'Confirmación nueva afectación'
export const answersSendNewAffectation = '¿Seguro desea enviar la nueva afectación?'
export const impactsBudgetItems = 'Partidas Presupuestarias'
export const organizationUnit = 'Unidad de Organización/Organismo'
export const newInvolvement='Nueva afectación'
export const accept='Aceptar'
export const creditPar = 'Créditos de la Partida'
export const totalMonth = 'Totales Mensuales'
export const typeLegalInstrument = 'Tipo instrumento legal'
export const associatedVouchers='Comprobantes asociados'
export const allExcel='Ver todos Excel'
export const allPdf='Ver todos PDF'
export const year='Año'
export const reportExped='Reporte comprobantes por expediente'
export const dowloadExcelmovement= 'Descargar Excel adjunto del movimiento'
export const dowloadPdfmovement='Descargar PDF adjunto del movimiento'
export const emptyMovementExp='No se encontraron movimientos para ese número de expediente.'
export const amountNumber ='Importe'
export const seatNumber ='Nro. asiento'
export const dateOfOperation ='Fecha de operación'
export const typesOfAffectation ='Tipo/s de afectación'
export const subpartialDeparture ='Partida subparcial'
export const numberCreditAbbreviation = 'Nro. de partida';
export const creditInquiry = "Consulta de créditos";
export const affectationsHistory = 'Historial de afectaciones de la partida';
export const reverse = 'Revertir';
export const noHistory = 'No hay historial';
export const listBudgetCredit = 'Listado de Partidas Prespuestarias';
export const budgetIncorporation = 'Incorporación de partida';
export const Xls = 'XLS';
export const Pdf = 'PDF';

//required
export const nameRequiredMsg = 'Debe ingresar nombre y apellido.'
export const userRequiredMsg = 'Debe ingresar un usuario.'
export const passwordConfirmRequiredMsg = 'Debe ingresar un confirmación de contraseña.'
export const passwordConfirmSameMsg = 'Las contraseñas ingresadas no coinciden.'
export const emailRequiredMsg = 'Debe ingresar un correo electrónico.'
export const passwordRequiredMsg = 'Debe ingresar una contraseña.'
export const numberLegalInstrumentRequiredMsg = 'Debe ingresar el Nro. del instrumento legal.';
export const fileMaxSizeMsg = 'El archivo no puede superar los 5MB.';
export const maxFiles = 'Se permite un máximo de 5 archivos.'
export const dateRequiredMsg = 'Debe seleccionar la fecha del instrumento legal.';
export const descriptionRequiredMsg = 'Debe ingresar una descripción.';
export const associatedLegalInstrument = 'Datos del Instrumento Legal Asociado'
export const legalInstrumetnFound = 'Se encontró un instrumento legal con los datos ingresados'
export const xFile='Expediente'
export const abbreviation='Abreviatura'
export const visibleName='Denominación de Partida'
export const visibleNameAbbrevation='Denom.'
export const selectBudgetItem = 'Seleccione las partidas presupuestarias: '
export const serviceRequiredMsg = 'Debe seleccionar un servicio';
export const organismCodeDropDownRequiredMsg = 'Debe seleccionar un código de organismo.'
export const numberRequiredOrderPayMsg = 'Debe ingresar un número.';
export const dateRequiredOrderPayMsg = 'Debe seleccionar una fecha.';
export const ticketRequiredOrderPayMsg = 'Debe ingresar una factura.';
export const penaltyFeeRequiredOrderPayMsg = 'Debe ingresar una multa.';

//Fund request

export const payOrderRequired='Debe seleccionar al menos una orden de pago.'
export const instrumentRequired='Debe seleccionar al menos un instrumento legal.'
export const PartidaPresupuestariaRequired='Debe seleccionar al menos una partida.'
export const figurativeRegistrationMissing = 'Falta registrar los importes de figurativa en las partidas de la Orden de Pago';
export const figurativeRegistrationCompleted = 'Se ha registrado para la Orden de Pago los importes en figurativa';

//Report
export const downloadTransactionFile='Descargar adjunto de la transacción.'
export  const yearRequiredMsg = 'Debe seleccionar un año.';
export 	const numberNumericMsg = 'El número correlativo debe ser númerico.';
export  const numberRequiredMsg = 'Debe ingresar un número correlativo.';
export const numberValueMsg = 'El número correlativo debe ser mayor a cero y menor o igual a 2147483647.';
export const  organismCodeRequiredMsg = 'Debe ingresar un código de organismo.'
export  const organismCodeNumericMsg = 'El código de organismo debe ser númerico.'
export const organismCodeNumericLimitsMsg = 'El código debe estar contenido entre 1000 y 9999.';

//Adminstrative Services
export const questionDeleteAdministrativeService = 'Realmente deseas eliminar: '

//Delete
export const questionDeleteConfirm = 'Realmente deseas eliminar: '

//Anular
export const questionAnnulConfirm = 'Realmente deseas anular: '

//Fecha desde
export const dateSince = 'Fecha desde '
//Fecha hasta
export const dateUntil = 'Fecha hasta '

//Expedient
export const associatedExpedient = "Datos del expediente asociado";
export const expedient = "Expediente";
export const expedients = "Expedientes";
export const correlative = "Correlativo";
export const business = "Asunto";
export const noExpedient = "No hay expedientes";
export const editExpedient = "Editar Expediente";
export const filterByCorrelative = "Filtrar por Correlativo";
export const filterByOrganismCode = "Filtrar por Código Organismo";
export const filterByBusiness = "Filtrar por Asunto";

//Imports
export const verifyConnectivity = "Verificar conexión CCPM";
export const expedientNumberAbbreviation = 'Nro. expediente';

//Organism Adminstrative
export const organismNew = "Nuevo organismo";
export const detailsOfTheNeworganism = 'Datos del nuevo organismo';
export const detailsOfTheEditorganism = 'Datos del organismo para editar';
export const organismEdit = 'Editar organismo';
export const organisms = 'Organismos';
export const budgetCode = 'Código presupuestario'
export const nameOrganism = 'Nombre del organismo'
export const nameShortOrganism = 'Nombre corto del organismo'
export const detailsOrganism = 'Detalle del organismo'

//Notifications
export const notifications = 'Notificaciones';
export const notification = 'Notificación'
export const newNotification = 'Nueva notificación';
export const notificationDetail = 'Detalle de la notificación';
export const dateNotification = 'Fecha envío';
export const subject = 'Asunto';
export const message = "Mensaje";
export const addressee = "Destinatario";
export const signature = 'Firma';
export const sendNotification = 'Enviar';
export const noNotification = 'No hay notificaciones.';
export const selection = 'Selección';
export const sendTo = "Enviado a";

export const filter = 'Filtrar';
export const cleanFiltersLabel = 'Limpiar filtros';
export const downloadAttachment = 'Descargar archivo adjunto';
export const noAttachment = 'Sin archivo adjunto'
//compensation
export const newCompensation = 'Nueva compensación de partidas';
export const compensationBudgetItems = "Compensación de partidas";
export const selectedBudgetCredits = 'Partidas Presupuestarias seleccionadas';
export const denomination ='Denominación';
export const resultingCredit = 'Crédito Actual Resultante';
export const confirmationNewCompensation = 'Confirmación nueva compensación de partidas'
export const answersSendNewACompensation = '¿Seguro que desea compensar las partidas?'

//incorporation
export const newIncorporation = 'Nueva incorporación de partida';
export const incorporationBudgetItems = "Incorporación de partida";

//Audit
export const auditDetail = 'Detalle de auditoría';
export const previousValue = 'Valor anterior';
export const currentValue = 'Valor actual';


//Maintenance System
export const maintenanceSystem= 'Sistema en Mantenimiento';
export const questionMaintenanceSystem = 'Realmente quiere cambiar la configuración del sistema al estado de mantenimiento: ';
export const activatedWord = 'Activado';
export const disabledWord = 'Desactivado';
export const controlPanel = 'Panel de Control';
export const notice = 'Aviso';
export const cautionSystemMaintenanceOnOff = '¡Precaución al activar/desactivar el mantenimiento del sistema!';
export const messageSystemMaintenanceOff = 'El sistema no está en mantenimiento.';
export const messageSystemMaintenanceOn = 'El sistema se encuentra en mantenimiento.';

// FigurativeExpeditures
export const figurativeExpedituresLabel = 'Erogación figurativa';
export const figurativeExpeditureService = 'Servicio';
export const figurativeExpeditureJursdiction = 'Jurisdicción';
export const figurativeExpeditureOrganism = 'U. Organizativa';
export const figurativeExpeditureCharacter = 'Caracter';
export const figurativeExpeditureAccount = 'Cuenta';
export const figurativeExpeditureSection = 'Sección';
export const figurativeExpeditureSector = 'Sector';
export const figurativeExpeditureMain = 'Partida principal';
export const figurativeExpeditureCode = 'Código';
export const figurativeExpeditureSubCode = 'Subcódigo';
export const figurativeExpeditureDescription = 'Descripción';
export const figurativeExpeditureYear = 'Año';
export const newFigurativeExpeditures = 'Nueva erogación figurativa';
export const detailOfFigurativeExpeditures = 'Datos de la nueva erogación figurativa';

// Provision order
export const provisionOrder = 'Orden de Provisión';
export const provisionOrders = 'Ordenes de Provisión';
export const newProvisionOrder = 'Nueva Orden de Provisión';
export const provisionOrderData = 'Datos de la Orden de Provisión';
export const number = 'Número';