import React, { useEffect, useRef, useState } from "react";
import { Button, Table, Alert, Modal, Card } from "react-bootstrap";
import { faExclamationTriangle, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatterPeso, removeDuplicateElements } from "src/utils/utils";
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from "react-redux";
import { tryListFigurativesByOPId } from "src/redux/orderPay/orderPayActionCreator";
import { getBudgetFigurativesData, getBudgetFigurativesIsFetching } from "src/redux/fundRequests/fundRequestsReducer";
import { clearBudgetFigurativeFundRequest } from "src/redux/fundRequests/fundRequestsActions";
import AppLoading from 'src/components/common/AppLoading';
import { showWarning } from 'src/redux/globalData/globalDataActionCreator';

const FigurativeModal = (props) => {

    const {
        setShowFigurativeModal,
        showFigurativeModal,
        setSelectedItems,
        selectedItems,
        setActiveFundRequestDetailDto,
        activeFundRequestDetailDto,
        setOrdenesAsociadas,
        ordenesAsociadas
    } = props;

    const dispatch = useDispatch();

    const onSaveFigurativeModalData = () => {
        if (!validAmounts()) {
            showWarning(`Debe cargar todos los importes correctamente.` );
        } else if (figurativeBalance?.asociated != true) {
            setActiveFundRequestDetailDto({ ...activeFundRequestDetailDto, mappedData });
            setSelectedItems(selectedItems?.map(_op => {
                if (_op?.paymentOrder?.id == activeFundRequestDetailDto?.id) {
                    _op.figurativeExpenditures = figurativeBalance.details.map( i => i.figurativeExpenditures).flat();
                    _op.paymentOrder.details = figurativeBalance.details;
                }
                return _op;
            }));
            handleCloseFigurativeModal();
        } else {
            setActiveFundRequestDetailDto({ ...activeFundRequestDetailDto, mappedData });
            setOrdenesAsociadas(ordenesAsociadas?.map(_op => {
                if (_op?.paymentOrder?.id == activeFundRequestDetailDto?.id) {
                    _op.figurativeExpenditures = figurativeBalance.details.map( i => i.figurativeExpenditures).flat();
                    _op.paymentOrder.details = figurativeBalance.details;
                }
                return _op;
            }));
            handleCloseFigurativeModal();
        }
    };

    const figurativeData = useSelector((state) => getBudgetFigurativesData(state));
    const figurativeDataIsFetching = useSelector((state) => getBudgetFigurativesIsFetching(state));
    const hasData = figurativeData?.length > 0;
    const inputRef = React.createRef();

    const handleCloseFigurativeModal = () => setShowFigurativeModal(false);
    const [mappedData, setMappedData] = useState([]);
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(false);

    const [figurativeBalance, setFigurativeBalance] = useState({});
      const previousValues = useRef({ figurativeBalance });
    const [hasError, setHasError] = useState(false);

    const principalTableValidationCondition = (item) => {
        return item?.balance?.currentBalance < sumCalculationMatcher(item);
    };

    const secondaryTableValidationCondition = detail => {
        const amountBudgetItem = detail?.presupuestoResponse.amount
        const amountToSpend = sumItemsCalculation(detail.figurativeExpenditures)
        return amountToSpend > amountBudgetItem
    };

    const withValueLimit = (inputObj) => {
        const { value } = inputObj;
        if (value <= 9999999999999) return true;
    };

    const sumCalculationMatcher = (item) => {
        let total = 0; 
        figurativeBalance.details.forEach( detail => {
            detail.figurativeExpenditures.forEach(f =>{
                if (f.filterFigurativeExpenditure.budgetTarget == item.completeNumber){
                    total += (f.newAmount || 0);
                }
            });
        });

        return total;
    };

    const sumItemsCalculation = (figList) => {
        return figList.reduce((total, current) => total + (current.newAmount || 0), 0);
    };

    const handlerImporteChange = (inputObj, item) => {
        item.amount = item.newAmount = inputObj.floatValue;
        setFlag(!flag);
    };

    useEffect(() => {
        if (figurativeBalance.details?.length){
            var res = figurativeBalance.details.every( d => !secondaryTableValidationCondition(d));
            setHasError(!res);
        }
    }, [flag])

    const validAmounts = () => {
		let valid = true;
		figurativeBalance?.details?.map?.(item => item?.figurativeExpenditures?.map(i => {
			if (isNaN(i?.amount)) {
				valid = false;
			}
		}));

		return valid;
	};

    useEffect(() => {
        const detailFigurativasMap = activeFundRequestDetailDto.details.reduce( (acc,d) =>({
                        ...acc,
                        [d.id]: {
                            figurativas: d.figurativeExpenditures.reduce( (ac, f) =>({
                                ...ac,
                                [f.id]: f
                            })
                            ,{})
                        }
                    }),{});
        setLoading(true);
        dispatch(tryListFigurativesByOPId({id: activeFundRequestDetailDto.id})).then(res =>{
            setLoading(false);
            res.data.asociated = activeFundRequestDetailDto?.asociated;
            res.data.details.forEach(resDetail =>{
                resDetail.figurativeExpenditures.forEach(f => {
                    if (detailFigurativasMap[resDetail.id]?.figurativas[f.id]){
                        f.amount = f.newAmount = detailFigurativasMap[resDetail.id]?.figurativas[f.id].amount || 0;
                    }
                });
            });
            setFigurativeBalance(res.data);
        });

        return () => {
            dispatch(clearBudgetFigurativeFundRequest());
        }
    }, [])

    return <>
        <Modal.Dialog>
            <Modal
                scrollable={true}
                dialogClassName="modal-xl"
                show={showFigurativeModal}
                onHide={handleCloseFigurativeModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5>{`Figurativas de Orden de Pago Nro. ${activeFundRequestDetailDto?.number} - ${activeFundRequestDetailDto?.year} `}</h5>
                </Modal.Header>
                <Modal.Body className="px-5">
                    <h6 className="text-left text-black-color">
                        Listado de saldos de partidas figurativas alcanzadas por la Orden
                        de Pago
                    </h6>
                    <Table striped bordered hover size="sm" responsive>
                        <thead>
                            <tr>
                                <th className="text-center align-middle" width="30%">
                                    Figurativa
                                </th>
                                <th className="text-center align-middle" width="30%">
                                    Nombre
                                </th>
                                <th className="text-center align-middle" width="20%">
                                    Saldo
                                </th>
                                <th className="text-center align-middle" width="20%">
                                    Importe Total Asignado
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-black-color">
                            <>
                                {figurativeBalance?.data ? (
                                    figurativeBalance.data?.map( item => (
                                            <>
                                                <tr key={item?.id}>
                                                    <td className="text-center align-middle">
                                                        {item?.completeNumber}
                                                    </td>
                                                    <td className="text-center align-middle">
                                                        {item?.code.name}
                                                    </td>
                                                    <td className="text-right align-middle">
                                                        {formatterPeso.format(item?.balance?.currentBalance)}
                                                    </td>
                                                    <td className={`text-right align-middle ${principalTableValidationCondition(item) ? 'font-weight-bold text-danger' : ''}`}>
                                                        {formatterPeso.format(sumCalculationMatcher(item))}
                                                    </td>
                                                </tr>
                                                {principalTableValidationCondition(item) && (
                                                    <tr key={item?.id} className="bg-white">
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="text-left align-middle">
                                                            <Alert
                                                                variant="danger"
                                                                className="form-field-error mb-0 py-1 mt-1"
                                                            >
                                                                El importe total financiado por la partida figurativa no debe ser superior al saldo
                                                            </Alert>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )
                                    )
                                ) : (
                                    <tr hidden={loading} >
                                        <td colSpan="13" className="text-center">
                                            <Alert variant="info" className="mb-0">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    className="text-black-color mr-3"
                                                />
                                                No hay registros
                                            </Alert>
                                        </td>
                                    </tr>
                                )}
                            </>
                        </tbody>
                    </Table>
                    <Card variant="success" className="mt-4 mb-3">
                        <Card.Header>
                            <h6 className="mb-0 text-left text-white">
                                Registro de Financiamiento de Partidas Figurativas
                            </h6>
                        </Card.Header>
                    </Card>
                    {figurativeBalance?.details?.map((item, idx) => (
                        <>
                            <div className="d-flex" key={item.id}>
                                <h6 className="text-left text-black-color mr-5">
                                    Partida :  {item?.presupuestoResponse.subCodeCompleteNumber}
                                </h6>
                                <h6 className="text-left text-black-color">
                                    Importe: &nbsp;<strong>{formatterPeso.format(item?.presupuestoResponse.amount)}</strong>
                                </h6>
                            </div>
                            <div className="d-flex" key={item.id}>
                                <h6 className="text-left text-black-color mr-5">
                                    Filtro : {item?.figurativeExpenditures?.map(i=> i?.filterFigurativeExpenditure?.budgetFilters?.[0]?.code || '-')}
                                </h6>
                            </div>
                            <Table striped bordered hover size="sm" responsive>
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle" width="30%">
                                            Figurativa
                                        </th>
                                        <th className="text-center align-middle" width="30%">
                                            Nombre
                                        </th>
                                        <th className="text-center align-middle" width="20%">
                                            Importe
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-black-color">
                                    {item?.figurativeExpenditures?.map( budg =>
                                        <>
                                            <tr>
                                                <td className="text-center align-middle">
                                                    {budg?.filterFigurativeExpenditure.budgetTarget}
                                                </td>
                                                <td className="text-center align-middle">
                                                    {budg?.filterFigurativeExpenditure.budgetTargetVisibleName}
                                                </td>
                                                <td className="text-right align-middle">
                                                    <NumericFormat
                                                        allowNegative={false}
                                                        required
                                                        defaultValue={0}
                                                        isAllowed={(inputObj) => withValueLimit(inputObj)}
                                                        onValueChange={(inputObj) => handlerImporteChange(inputObj, budg)}
                                                        getInputRef={inputRef}
                                                        thousandSeparator={'.'}
                                                        value={budg?.newAmount}
                                                        decimalSeparator={','}
                                                        decimalScale={2}
                                                        className="form-control text-right"
                                                        inputMode="numeric"
                                                        prefix="$"
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    <tr className='mt-1'>
                                        <td></td>
                                        <td className='text-right align-middle' colSpan="1">
                                            <h6 className="text-right text-black-color mb-0">Importe Financiado por Figurativa</h6>
                                        </td>
                                        <td className='align-middle'>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <NumericFormat
                                                    value={formatterPeso.format(sumItemsCalculation(item?.figurativeExpenditures))}
                                                    displayType='text'
                                                    getInputRef={inputRef} thousandSeparator={'.'} decimalSeparator={','}
                                                    decimalScale={2}
                                                    className={`form-control text-right readonly ${secondaryTableValidationCondition(item) ? 'font-weight-bold text-danger' : ''}`}
                                                    inputMode="numeric"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    {secondaryTableValidationCondition(item) && (
                                        <tr key={item?.id} className="bg-white">
                                            <td></td><td></td>
                                            <td className="text-left align-middle">
                                                <Alert
                                                    variant="danger"
                                                    className="form-field-error mb-0 py-1 mt-1"
                                                >
                                                    El importe financiado por figurativa debe ser menor o igual al consignado en la Orden de Pago
                                                </Alert>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </>
                    ))}
                </Modal.Body>
                <div className='d-flex justify-content-around mt-4 mb-3'>
                    <Button variant='danger' size='lg' onClick={handleCloseFigurativeModal}>
                        Cancelar
                    </Button>
                    <Button type='submit' variant='success' disabled={hasError} onClick={onSaveFigurativeModalData} size='lg' >
                        Guardar
                        <FontAwesomeIcon icon={faSave} className='ml-2' />
                    </Button>
                </div>
                <AppLoading isLoading={loading} />
            </Modal>
        </Modal.Dialog>
    </>
};

export default FigurativeModal;