import * as ACTION_TYPES from './purposeActionTypes';

// Purposes list actions
export const getPurposeListRequest = () => ({
	type: ACTION_TYPES.LIST_PURPOSE_REQUEST
});

export const getPurposeListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PURPOSE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getPurposeListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PURPOSE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearPurposeList = () => ({
	type: ACTION_TYPES.CLEAR_PURPOSE_LIST
});
// Purposes list actions