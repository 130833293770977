import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card } from 'react-bootstrap';
import PageTitle from 'src/components/general/PageTitle';
import { tryEditBankAccount } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestActionCreator';
import { push } from 'connected-react-router';
import { BANK_ACCOUNT_LIST } from 'src/utils/constants';
import { getBankAccountToEdit } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer';
import { getBankAccouontIsFetching } from 'src/redux/bankAccountsFundRequest/bankAccountsFundRequestReducer';
import BankAccountFormAM from './BankAccountFormAM';

const BankAccountEditPage = () => {
    const dispatch = useDispatch();
    const bankAccountToEdit = useSelector(state => getBankAccountToEdit(state));
    const redirectToBankAccountList = () => { dispatch(push(BANK_ACCOUNT_LIST)) };

    const isFetchingEditBankAccount = useSelector(state => getBankAccouontIsFetching(state));

    const onFormSubmit = data => {
        dispatch(tryEditBankAccount(data)).then(
            () => redirectToBankAccountList());
    };

    return <>
        <Container fluid>
            <Card className='mb-3'>
                <Card.Header className='h6'>
                    Editar Cuenta Bancaria
                </Card.Header>
                <Card.Body>
                    <BankAccountFormAM
                        bankAccountToEdit={bankAccountToEdit}
                        onFormSubmit={onFormSubmit}
                        isFetching={isFetchingEditBankAccount}
                    />
                </Card.Body>
            </Card>
        </Container>
    </>
};

export default BankAccountEditPage;