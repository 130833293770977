
// List Fund Requests
export const LIST_FUND_REQUESTS_REQUEST = 'LIST_FUND_REQUESTS_REQUEST';
export const LIST_FUND_REQUESTS_REQUEST_SUCCESS = 'LIST_FUND_REQUESTS_REQUEST_SUCCESS';
export const LIST_FUND_REQUESTS_REQUEST_FAILURE = 'LIST_FUND_REQUESTS_REQUEST_FAILURE';
export const CLEAR_LIST_FUND_REQUESTS_DATA = 'CLEAR_LIST_FUND_REQUESTS_DATA';

// Set Fund Requests
export const SET_DETAIL_FUND_REQUESTS_DATA = 'SET_DETAIL_FUND_REQUESTS_DATA';
export const CLEAR_DETAIL_FUND_REQUESTS_DATA = 'CLEAR_DETAIL_FUND_REQUESTS_DATA';
export const DETAIL_FUND_REQUESTS_REQUEST = 'DETAIL_FUND_REQUESTS_REQUEST';
export const DETAIL_FUND_REQUESTS_REQUEST_SUCCESS = 'DETAIL_FUND_REQUESTS_REQUEST_SUCCESS';
export const DETAIL_FUND_REQUESTS_REQUEST_FAILURE = 'DETAIL_FUND_REQUESTS_REQUEST_FAILURE';

//New
export const CREATE_FUND_REQUESTS_REQUEST = 'CREATE_FUND_REQUESTS_REQUEST'
export const CREATE_FUND_REQUESTS_REQUEST_SUCCESS = 'CREATE_FUND_REQUESTS_REQUEST_SUCCESS'
export const CREATE_FUND_REQUESTS_REQUEST_FAILURE = 'CREATE_FUND_REQUESTS_REQUEST_FAILURE'
export const CLEAR_CREATE_FUND_REQUESTS = 'CLEAR_CREATE_FUND_REQUESTS'

//Edit
export const EDIT_FUND_REQUESTS_REQUEST = 'EDIT_FUND_REQUESTS_REQUEST'
export const EDIT_FUND_REQUESTS_REQUEST_SUCCESS = 'EDIT_FUND_REQUESTS_REQUEST_SUCCESS'
export const EDIT_FUND_REQUESTS_REQUEST_FAILURE = 'EDIT_FUND_REQUESTS_REQUEST_FAILURE'
export const CLEAR_EDIT_FUND_REQUESTS = 'CLEAR_EDIT_FUND_REQUESTS'
export const SET_FUND_REQUESTS_TO_EDIT = 'SET_FUND_REQUESTS_TO_EDIT';

//Delete
export const DELETE_FUND_REQUESTS_REQUEST = 'DELETE_FUND_REQUESTS_REQUEST'
export const DELETE_FUND_REQUESTS_REQUEST_SUCCESS = 'DELETE_FUND_REQUESTS_REQUEST_SUCCESS'
export const DELETE_FUND_REQUESTS_REQUEST_FAILURE = 'DELETE_FUND_REQUESTS_REQUEST_FAILURE'
export const CLEAR_DELETE_FUND_REQUESTS = 'CLEAR_DELETE_FUND_REQUESTS'

//Request by id
export const LIST_FUND_REQUESTS_REQUEST_ID = 'LIST_FUND_REQUESTS_REQUEST_ID'
export const LIST_FUND_REQUESTS_ID_REQUEST_SUCCESS = 'LIST_FUND_REQUESTS_ID_REQUEST_SUCCESS'
export const LIST_FUND_REQUESTS_ID_REQUEST_FAILURE = 'LIST_FUND_REQUESTS_ID_REQUEST_FAILURE'

//Request list types
export const LIST_TYPES_FUND_REQUESTS_REQUEST = 'LIST_TYPES_FUND_REQUESTS_REQUEST'
export const LIST_TYPES_FUND_REQUESTS_SUCCESS = 'LIST_TYPES_FUND_REQUESTS_SUCCESS'
export const LIST_TYPES_FUND_REQUESTS_FAILURE = 'LIST_TYPES_FUND_REQUESTS_FAILURE'
export const SET_TYPE_LIST_FUND_REQUEST = 'SET_TYPE_LIST_FUND_REQUEST'
export const CLEAR_TYPE_LIST_FUND_REQUEST = 'CLEAR_TYPE_LIST_FUND_REQUEST'

//Request list types
export const LIST_STATES_FUND_REQUESTS_REQUEST = 'LIST_STATES_FUND_REQUESTS_REQUEST'
export const LIST_STATES_FUND_REQUESTS_SUCCESS = 'LIST_STATES_FUND_REQUESTS_SUCCESS'
export const LIST_STATES_FUND_REQUESTS_FAILURE = 'LIST_STATES_FUND_REQUESTS_FAILURE'
export const SET_STATES_LIST_FUND_REQUEST = 'SET_STATES_LIST_FUND_REQUEST'
export const CLEAR_STATES_LIST_FUND_REQUEST = 'CLEAR_STATES_LIST_FUND_REQUEST'

//Request Budget Figurative request
export const BUDGET_FIGURATIVE_REQUESTS_REQUEST = 'BUDGET_FIGURATIVE_REQUESTS_REQUEST'
export const BUDGET_FIGURATIVE_REQUESTS_SUCCESS = 'BUDGET_FIGURATIVE_REQUESTS_SUCCESS'
export const BUDGET_FIGURATIVE_REQUESTS_FAILURE = 'BUDGET_FIGURATIVE_REQUESTS_FAILURE'
export const CLEAR_BUDGET_FIGURATIVE_REQUEST= 'CLEAR_BUDGET_FIGURATIVE_REQUEST'

// Fund Request Manual Items
export const SELECTED_FUND_REQUEST_MANUAL_ITEM = 'SELECTED_FUND_REQUEST_MANUAL_ITEM'
export const DELETED_FUND_REQUEST_MANUAL_ITEM = 'DELETED_FUND_REQUEST_MANUAL_ITEM';
export const CLEAR_FUND_REQUEST_MANUAL_ITEM = 'CLEAR_FUND_REQUEST_MANUAL_ITEM'
