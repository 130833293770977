import * as ACTION_TYPES from './userActionTypes';

export const getUserListRequest = () => ({
	type: ACTION_TYPES.LIST_USER_REQUEST
});

export const getUserListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getUserListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearUserList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_USER
});

// User Detail
export const getUserDetailRequest = () => ({
	type: ACTION_TYPES.DETAIL_USER_REQUEST
});

export const getUserDetailRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getUserDetailRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setUserToDetails = data => ({
	type: ACTION_TYPES.SET_USER,
	payload: {
		data
	}
});

// User paginated list
export const getUserPaginatedListRequest = () => ({
	type: ACTION_TYPES.USER_PAGINATED_LIST_REQUEST
});

export const getUserPaginatedListRequestSuccess = data => ({
	type: ACTION_TYPES.USER_PAGINATED_LIST_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getUserPaginatedListRequestFailure = error => ({
	type: ACTION_TYPES.USER_PAGINATED_LIST_REQUEST_FAILURE,
	payload: {
		error
	}
});

//Profile
export const getUserProfileRequest = () => ({
	type: ACTION_TYPES.LIST_PROFILE_REQUEST
});

export const getUserProfileRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PROFILE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getUserProfileRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PROFILE_REQUEST_FAILURE,
	payload: {
		error
	}
});


// User New
export const getNewUserRequest = () => ({
	type: ACTION_TYPES.NEW_USER_REQUEST
});

export const getNewUserRequestSuccess = data => ({
	type: ACTION_TYPES.NEW_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getNewUserRequestFailure = error => ({
	type: ACTION_TYPES.NEW_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});

//User Edit
export const editUserRequest = () => ({
	type: ACTION_TYPES.EDIT_USER_REQUEST
});

export const editUserRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editUserRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setUserToEdit = data => ({
	type: ACTION_TYPES.SET_USER_TO_EDIT,
	payload: {
		data
	}
});

//Delete User
export const deleteUserRequest = () => ({
	type: ACTION_TYPES.DELETE_USER_REQUEST
});

export const deleteUserRequestSuccess = data => ({
	type: ACTION_TYPES.DELETE_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const deleteUserRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearProfileToEdit = () => ({
	type: ACTION_TYPES.CLEAR_PROFILE_TO_EDIT
});

// Add User to Profile
export const addUserToProfileRequest = () => ({
	type: ACTION_TYPES.CREATE_USER_X_APPLICATION_PROFILE_REQUEST
});

export const addUserToProfileRequestSucces = () => ({
	type: ACTION_TYPES.CREATE_USER_X_APPLICATION_PROFILE_REQUEST_SUCCESS
});

export const addUserToProfileRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_USER_X_APPLICATION_PROFILE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Add User to Profile



// Edit User X Application X Profile
export const editUserXApplicationXProfileRequest = () => ({
	type: ACTION_TYPES.EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST
});

export const editUserXApplicationXProfileRequestSuccess = () => ({
	type: ACTION_TYPES.EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST_SUCCESS
});

export const editUserXApplicationXProfileRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_USER_X_APPLICATION_X_PROFILE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Edit User X Application X Profile



// Get Users X Application X Profile
export const listUsersXApplicationXProfile = () => ({
	type: ACTION_TYPES.LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST
});

export const listUsersXApplicationXProfileSucces = data => ({
	type: ACTION_TYPES.LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listUsersXApplicationXProfileFailure = error => ({
	type: ACTION_TYPES.LIST_USERS_X_APPLICATION_X_PROFILE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Get Users X Application X Profile

//User Edit Change Password
export const editUserChangePasswordRequest = () => ({
	type: ACTION_TYPES.EDIT_USER_CHANGE_PASSWORD_REQUEST
});

export const editUserChangePasswordRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_USER_CHANGE_PASSWORD_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editUserChangePasswordRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_USER_CHANGE_PASSWORD_REQUEST_FAILURE,
	payload: {
		error
	}
});

//send validation email
export const sendValidationEmailRequest = () => ({
	type: ACTION_TYPES.SEND_VALIDATION_EMAIL_REQUEST
});

export const sendValidationEmailRequestSuccess = data => ({
	type: ACTION_TYPES.SEND_VALIDATION_EMAIL_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const sendValidationEmailRequestFailure = error => ({
	type: ACTION_TYPES.SEND_VALIDATION_EMAIL_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const getPersonaNameByCuit = () => ({
	type: ACTION_TYPES.GET_PERSONA_BY_CUIT_REQUEST
});

export const getPersonaNameByCuitSuccess = data => ({
	type: ACTION_TYPES.GET_PERSONA_BY_CUIT_SUCCESS,
	payload: {
		data
	}
});

export const getPersonaNameByCuitFailure = error => ({
	type: ACTION_TYPES.GET_PERSONA_BY_CUIT_FAILURE,
	payload: {
		error
	}
});

export const clearPernalNameByCuit = () => ({
	type: ACTION_TYPES.CLEAR_PERSONAL_NAME_BY_CUIT
});

// Set User Global
export const setUserGlobal = data => ({
	type: ACTION_TYPES.SET_USER_GLOBAL,
	payload: {
		data
	}
});

// Set User Global
export const tryUserNewJurisdiction = data => ({
	type: ACTION_TYPES.SET_USER_NEW_JURISDICTION,
	payload: {
		data
	}
});