import React from 'react'
import { isNotEmptyArray } from 'src/services/validationService'
import { formatterPeso } from 'src/utils/utils'
import { Row, Col } from 'react-bootstrap';

const TotalAmountReceiptValidationForm = ({ setAllowSaveAmountValidation, expedientesSeleccionados, selectedExpedientAssociated, totalAmountReceipts, importe, receipts }) => {

    const amountsShowValidation = (totalReceiptAmount, importe) => {
        if (totalReceiptAmount) {
            const resultCondition = totalReceiptAmount?.toFixed(2) === importe?.toFixed(2)
            setAllowSaveAmountValidation(resultCondition);
            return resultCondition;
        }
        return false;
    };
    const isValidTotalAmountReceipts = amountsShowValidation(totalAmountReceipts, importe);

    return (
        <>
            {(isNotEmptyArray(expedientesSeleccionados) || isNotEmptyArray(selectedExpedientAssociated)) &&
                <Row className='align-right mt-5 border border-1 rounded px-2 pt-4 my-5 mx-2' >
                    <Col className='col-md-8 text-right mx-0 mb-2 py-2'>
                        Importe total de Orden de Pago 
                    </Col>
                    <Col className={`col-md-4 text-right border border-1 rounded mx-0 mb-2 py-2 ${isValidTotalAmountReceipts ? 'bg-success-light' : ''} `}>
                        {isNaN(importe) ? '' : formatterPeso.format(importe)}
                    </Col>
                    {receipts !== undefined && 
                        <>
                            <Col className='col-md-8 text-right mx-0 mb-1 py-2'>
                                Importe Total de Recibos Seleccionados
                            </Col>
                            <Col className={`col-md-4 text-right border border-1 rounded mx-0 mb-1 py-2 ${isValidTotalAmountReceipts ? 'bg-success-light' : ''} `}>
                                {formatterPeso.format(totalAmountReceipts)}
                            </Col>
                        </>
                    }
                    {isNotEmptyArray(receipts) && (!isValidTotalAmountReceipts &&
                        <Col className='offset-md-8 md-4 text-right alert alert-danger my-2'>
                            El total de la orden de pago y los recibos debe coincidir
                        </Col>
                    )}
                </Row>
            }
        </>
    )
}

export default TotalAmountReceiptValidationForm;