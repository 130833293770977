import * as ACTION_TYPES from './administrativeOrganismActionTypes';

export const administrativeOrganismReducerInitialState = {
	listAdministrativeOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	listOneAdministrativeOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	newAdministrativeOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	editAdministrativeOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	deleteAdministrativeOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	setAdministrativeOrganismToEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	setAdministrativeOrganismToDetail: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	classifierAdministrativeOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	serviceAdministrativeManagerOrganism: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};
    
export const administrativeOrganismReducer = (state = administrativeOrganismReducer, action) => {
    switch (action.type) {

        // List administrative Organims actions handlings
		case ACTION_TYPES.LIST_ADMINISTRATIVE_ORGANISM_REQUEST: {
			return {
				...state,
				listAdministrativeOrganism: {
					...state.listAdministrativeOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.listAdministrativeOrganism.error,
					data: administrativeOrganismReducerInitialState.listAdministrativeOrganism.data
				}
			};
		}
		case ACTION_TYPES.LIST_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;

			const previousRecords = state.listAdministrativeOrganism?.data?.records ;

			if( previousRecords ) {
				data.records = previousRecords.concat( data?.records );
			}

			return {
				...state,
				listAdministrativeOrganism: {
					...state.listAdministrativeOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeOrganismReducerInitialState.listAdministrativeOrganism.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listAdministrativeOrganism: {
					...state.listAdministrativeOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeOrganismReducerInitialState.listAdministrativeOrganism.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_ADMINISTRATIVE_ORGANISM_TO_DETAIL: {
			const { data } = action.payload;
			return {
				...state,
				setAdministrativeOrganismToDetail: data
			}
		}
		case ACTION_TYPES.CLEAR_LIST_ADMINISTRATIVE_ORGANISM: {
			return {
				...state,
				listAdministrativeOrganism: administrativeOrganismReducerInitialState.listAdministrativeOrganism
			}
		}
		// List administrative organism actions handlings

		// List One administrative Organims actions handlings
		case ACTION_TYPES.LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST: {
			return {
				...state,
				listOneAdministrativeOrganism: {
					...state.listOneAdministrativeOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.listOneAdministrativeOrganism.error,
					data: administrativeOrganismReducerInitialState.listOneAdministrativeOrganism.data
				}
			};
		}
		case ACTION_TYPES.LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;

			const previousRecords = state.listOneAdministrativeOrganism?.data?.records ;

			if( previousRecords ) {
				data.records = previousRecords.concat( data?.records );
			}

			return {
				...state,
				listOneAdministrativeOrganism: {
					...state.listOneAdministrativeOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeOrganismReducerInitialState.listOneAdministrativeOrganism.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listOneAdministrativeOrganism: {
					...state.listOneAdministrativeOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeOrganismReducerInitialState.listOneAdministrativeOrganism.data,
					error
				}
			};
		}
		//End
		
		 // New administrative Organims actions
		 case ACTION_TYPES.NEW_ADMINISTRATIVE_ORGANISM_REQUEST: {
			return {
				...state,
				newAdministrativeOrganism: {
					...state.newAdministrativeOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.newAdministrativeOrganism.error,
					data: administrativeOrganismReducerInitialState.newAdministrativeOrganism.data
				}
			};
		}
		case ACTION_TYPES.NEW_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				newAdministrativeOrganism: {
					...state.newAdministrativeOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeOrganismReducerInitialState.newAdministrativeOrganism.error,
					data
				}
			};
		}
		case ACTION_TYPES.NEW_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				newAdministrativeOrganism: {
					...state.newAdministrativeOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeOrganismReducerInitialState.newAdministrativeOrganism.data,
					error
				}
			};
		}
		// New administrative organism actions
		
		//Edit administrative organism actions
		case ACTION_TYPES.EDIT_ADMINISTRATIVE_ORGANISM_REQUEST: {
			return {
				...state,
				editAdministrativeOrganism: {
					...state.administrativeServicesEdit,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.editAdministrativeOrganism.error
				}
			};
		}

		case ACTION_TYPES.EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				editAdministrativeOrganism: {
					...state.editAdministrativeOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeOrganismReducerInitialState.editAdministrativeOrganism.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				editAdministrativeOrganism: {
					...state.editAdministrativeOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeOrganismReducerInitialState.editAdministrativeOrganism.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_ADMINISTRATIVE_ORGANISM_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				setAdministrativeOrganismToEdit: data
			}
		}
		//END New administrative organism actions
		
		 // Delete administrative Organims actions
		 case ACTION_TYPES.DELETE_ADMINISTRATIVE_ORGANISM_REQUEST: {
			return {
				...state,
				deleteAdministrativeOrganism: {
					...state.deleteAdministrativeOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.deleteAdministrativeOrganism.error,
					data: administrativeOrganismReducerInitialState.deleteAdministrativeOrganism.data
				}
			};
		}
		case ACTION_TYPES.DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				deleteAdministrativeOrganism: {
					...state.deleteAdministrativeOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeOrganismReducerInitialState.deleteAdministrativeOrganism.error,
					data
				}
			};
		}
		case ACTION_TYPES.DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				deleteAdministrativeOrganism: {
					...state.deleteAdministrativeOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeOrganismReducerInitialState.deleteAdministrativeOrganism.data,
					error
				}
			};
		}
		// Delete administrative organism actions
		

		// Classifier administrative Organims actions
		case ACTION_TYPES.CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST: {
			return {
				...state,
				classifierAdministrativeOrganism: {
					...state.classifierAdministrativeOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.classifierAdministrativeOrganism.error,
					data: administrativeOrganismReducerInitialState.classifierAdministrativeOrganism.data
				}
			};
		}
		case ACTION_TYPES.CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				classifierAdministrativeOrganism: {
					...state.classifierAdministrativeOrganism,
					isFetching: false,
					hasError: false,
					error: administrativeOrganismReducerInitialState.classifierAdministrativeOrganism.error,
					data
				}
			};
		}
		case ACTION_TYPES.CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				classifierAdministrativeOrganism: {
					...state.classifierAdministrativeOrganism,
					isFetching: false,
					hasError: true,
					data: administrativeOrganismReducerInitialState.classifierAdministrativeOrganism.data,
					error
				}
			};
		}
		// Classifier administrative organism actions
		
		
		// Service Administrative manager Organism  actions
		case ACTION_TYPES.GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST: {
			return {
				...state,
				serviceAdministrativeManagerOrganism: {
					...state.serviceAdministrativeManagerOrganism,
					isFetching: true,
					hasError: false,
					error: administrativeOrganismReducerInitialState.serviceAdministrativeManagerOrganism.error,
					data: administrativeOrganismReducerInitialState.serviceAdministrativeManagerOrganism.data
				}
			}
		}
		case ACTION_TYPES.GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				serviceAdministrativeManagerOrganism: {
					...state.serviceAdministrativeManagerOrganism,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				serviceAdministrativeManagerOrganism: {
					...state.serviceAdministrativeManagerOrganism,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_DATA: {
			return {
				...state,
				serviceAdministrativeManagerOrganism: administrativeOrganismReducerInitialState.serviceAdministrativeManagerOrganism
			}
		}
		// END Service Administrative manager Organism  actions
		

        default: {
			return state;
		}
	}
};


export const getAdministrativeOrganismReducer = state => state.administrativeOrganismReducer;

// List administrative Organism
export const getAdministrativeOrganismList = state => getAdministrativeOrganismReducer(state)?.listAdministrativeOrganism;
export const getAdministrativeOrganismListData = state => getAdministrativeOrganismList(state)?.data;
export const getAdministrativeOrganismListIsFetching = state => getAdministrativeOrganismList(state)?.isFetching;

// Detail administrative Organism
export const getAdministrativeOrganismDetail = state => getAdministrativeOrganismReducer(state)?.setAdministrativeOrganismToDetail;

// List administrative Organism
export const getAdministrativeOrganismListOne = state => getAdministrativeOrganismReducer(state)?.listOneAdministrativeOrganism;
export const getAdministrativeOrganismListOneData = state => getAdministrativeOrganismListOne(state)?.data;
export const getAdministrativeOrganismListOneIsFetching = state => getAdministrativeOrganismListOne(state)?.isFetching;

// New administrative Organism
export const getAdministrativeOrganismNew = state => getAdministrativeOrganismReducer(state)?.newAdministrativeOrganism;
export const getAdministrativeOrganismNewData = state => getAdministrativeOrganismNew(state)?.data;
export const getAdministrativeOrganismNewIsFetching = state => getAdministrativeOrganismNew(state)?.isFetching;

// Edit administrative Organism
export const getAdministrativeOrganismEdit = state => getAdministrativeOrganismReducer(state)?.editAdministrativeOrganism;
export const getAdministrativeOrganismEditData = state => getAdministrativeOrganismEdit(state)?.data;
export const getAdministrativeOrganismEditIsFetching = state => getAdministrativeOrganismEdit(state)?.isFetching;

export const getAdministrativeOrganismToEdit = state => getAdministrativeOrganismReducer(state)?.setAdministrativeOrganismToEdit;

// Delete administrative Organism
export const getAdministrativeOrganismDelete = state => getAdministrativeOrganismReducer(state)?.deleteAdministrativeOrganism;
export const getAdministrativeOrganismDeleteData = state => getAdministrativeOrganismDelete(state)?.data;
export const getAdministrativeOrganismDeleteIsFetching = state => getAdministrativeOrganismDelete(state)?.isFetching;

// Classifier administrative Organism
export const getAdministrativeOrganismClassifier = state => getAdministrativeOrganismReducer(state)?.classifierAdministrativeOrganism;
export const getAdministrativeOrganismClassifierData = state => getAdministrativeOrganismClassifier(state)?.data;
export const getAdministrativeOrganismClassifierIsFetching = state => getAdministrativeOrganismClassifier(state)?.isFetching;


// Service Administrative manager Organism
export const getServiceAdministrativeManagerOrganism = state => getAdministrativeOrganismReducer(state)?.serviceAdministrativeManagerOrganism;
export const getServiceAdministrativeManagerOrganismData = state => getServiceAdministrativeManagerOrganism(state)?.data;
export const getServiceAdministrativeManagerOrganismIsFetching = state => getServiceAdministrativeManagerOrganism(state)?.isFetching;