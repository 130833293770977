import * as ACTION_TYPES from './serviceActionTypes';

export const getServiceListListRequest = () => ({
	type: ACTION_TYPES.LIST_SERVICE_REQUEST
});
export const getServiceListListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_SERVICE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getServiceListListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SERVICE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearServiceList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_SERVICES
});

export const getListServiceByJurisdictionOrganismRequest = () => ({
	type: ACTION_TYPES.LIST_SERVICE_JURISDICTION_ORGANISM_REQUEST
})
export const getListServiceByJurisdictionOrganismRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_SERVICE_JURISDICTION_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
})
export const getListServiceByJurisdictionOrganismRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SERVICE_JURISDICTION_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
})
export const clearListServiceByJurisdictionOrganism = () => ({
	type: ACTION_TYPES.CLEAR_LIST_SERVICES_JURISDICTION_ORGANISM
})