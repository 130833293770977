import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Table, Alert, FormControl } from 'react-bootstrap';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faBroom, faPlusCircle, faCheck, faMinusCircle, faExclamationTriangle, faSave, faFilter, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import axios from "axios";
import { useForm } from 'react-hook-form';
import PageTitle from 'src/components/general/PageTitle';
import { DELIVERY_ORDER_LIST } from 'src/utils/constants';
import FormFieldError from 'src/components/general/FormFieldError';
import { showError } from 'src/redux/globalData/globalDataActionCreator';
import { getFundRequestsListData, getFundRequestsListIsFetching } from 'src/redux/fundRequests/fundRequestsReducer'
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { tryListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator'
import { formatterPeso, getCurrentYear, getClearObject } from 'src/utils/utils'
import { clearListFundRequestsData } from 'src/redux/fundRequests/fundRequestsActions';
import { tryPutDeliveryOrder } from 'src/redux/deliveryOrder/deliveryOrderActionCreator'
import { getDeliveryOrderCreateIsFetching, getDeliveryOrderToEdit } from 'src/redux/deliveryOrder/deliveryOrderReducer'
import AppLoading from 'src/components/common/AppLoading';
import { DropdownList } from 'react-widgets';
import { mFormat } from 'src/utils/utils';
import RCPagination from '../../common/RCPagination';
import { AdministrativeServiceData, administrativeService } from 'src/utils/administrativeServiceUtils';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const DeliveryOrderNewPage = () => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm({ shouldFocusError: true });

	//load page
	const deliveryOrderDataEdit = useSelector(state => getDeliveryOrderToEdit(state));
	const [foundRequestList, setFoundRequestList] = useState([]);
	const foundRequestListIsFitching = useSelector(state => getFundRequestsListIsFetching(state))
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const deliveryOrderCreateIsFetching = useSelector(state => getDeliveryOrderCreateIsFetching(state));
	const administrativeServiceData = AdministrativeServiceData();

	//Pagination data
    const fundRequestData = useSelector(state => getFundRequestsListData(state));
    const totalCount = fundRequestData?.metadata?.filteredCount;
	const pageNumber = fundRequestData?.metadata?.page;
	const pageSize = fundRequestData?.metadata?.pageSize;

	const todayDate = moment(new Date()).format('YYYY-MM-DD');
	const [servicio, setServicio] = useState();
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	const onFormSubmit = data => {
		const params = {
			id: deliveryOrderDataEdit?.id,
			number: data?.numberDeliveryOrder,
			year: data?.fiscalYearId,
			deliveryOrderDate: data?.numberDeliveryOrderDate,
			description: data?.description,
			fundRequest: { id: fundRequestSelected?.id },
			administrativeDocument:
			{
				year: selectedYear,
				codeOrganism: data?.code,
				number: data?.correlative,
				id: deliveryOrderDataEdit.administrativeDocument.id
			}

		}
		if (fundRequestSelected != undefined) {
			const clearParams = getClearObject(params);
			dispatch(tryPutDeliveryOrder(clearParams)).then(
				response => {
					if (response?.status == 200) {
						dispatch(push(DELIVERY_ORDER_LIST));
					}
				}
			);
		} else {
			showError('Debe seleccionar al menos un pedido de fondo');
		}
	};

	// Validations
	const numberDeliveryOrderRequiredMsg = 'Debe ingresar el número de la orden de entrega';
	const dateDeliveryOrderRequiredMsg = 'Debe ingresar una fecha válida';
	const codeRequiredMsg = 'Debe ingresar el código';
	const correlativeRequiredMsg = 'Debe ingresar el número correlativo del expediente';

	// Form data
	const numberDeliveryOrderValidationObj = { required: numberDeliveryOrderRequiredMsg };
	const dateDeliveryOrderValidationObj = { 
		required: dateDeliveryOrderRequiredMsg,
		validate: val => {
			return fundRequestSelected && fundRequestSelected.fundRequestDate > val?'La fecha es anterior al pedido de fondo seleccionado':null;
		} 
	};
	const codeValidationObj = { required: codeRequiredMsg };
	const correlativeValidationObj = { required: correlativeRequiredMsg };

	//fundRequest selected
	const [fundRequestSelected, setFundRequestSelected] = useState(deliveryOrderDataEdit?.fundRequest);

	const onClickDeliveryOrderSelected = item => {
		setFundRequestSelected(item)
	}

	const onClickRemoveDeliveryOrderSelected = item => {
		setFundRequestSelected(null)
	}

	//Filtro Número
	const onChangeFilterNumber = event => {
		const value = event?.target?.value;
		setNumberFilterQuery(value);
	};
	//Filtro Año
	const onChangeFilterYear = event => {
		const value = event?.target?.value;
		setYearFilterQuery(value);
	};

	const [numberFilterQuery, setNumberFilterQuery] = useState("");
	const [yearFilterQuery, setYearFilterQuery] = useState("");
	const filterNumberRef = useRef(null);

	//Filtro Texto
	const onChangeFilterText = event => {
		const value = event?.target?.value;
		setTextFilterQuery(value);
	};
	const [textFilterQuery, setTextFilterQuery] = useState("");
	const filterTextRef = useRef(null);

	let defaultParams = {
		state: "ACTIVO_PENDIENTE_DE_AUTORIZACION",
		number: "",
		year: "",
		description: "",
		token: token
	}

	const fetchList = (params) => {
		dispatch(tryListFundRequests(params))
	}

	const onPageChange = (pNumber) => {
		const params = {
			...defaultParams,
			page: pNumber - 1 
		}
		fetchList(params);
	}

	const clearFilters = () => {
		setNumberFilterQuery('');
		setYearFilterQuery('');
		setTextFilterQuery('');
		setServicio('');
		loadAllData(defaultParams)
	};

	const onClickCancelButton = () => {
		redirectToBankAccountList();
	};
	const redirectToBankAccountList = () => { dispatch(push(DELIVERY_ORDER_LIST)) };

	//dropdow yeard
	const yearsOptions = Array.from(new Array(20), (val, index) => getCurrentYear() - index);
	const [selectedYear, setSelectedYear] = useState(deliveryOrderDataEdit?.administrativeDocument?.year);
	const onChangeYearDropdown = event => {
		const value = event.target.value;
		setSelectedYear(value);

	};

	const { cancel, token } = axios.CancelToken.source();

	//filter Submit
	const onSubmitFormFilter = () => {
		if (!foundRequestListIsFitching) {
			const filterParams = {
				state: "ACTIVO_PENDIENTE_DE_AUTORIZACION",
				number: numberFilterQuery,
				year: yearFilterQuery,
				description: textFilterQuery,
				administrativeServiceId: servicio,
				token: token
			}
			dispatch(clearListFundRequestsData());
			dispatch(tryListFundRequests(filterParams));
		}
	}

	const checkKeyDownFilter = (e) =>{
		if (e.nativeEvent.key === 'Enter' && e.nativeEvent.path.length > 17){
			onSubmitFormFilter();
			e.nativeEvent.preventDefault();
		}

	};

	const loadAllData = (params) => {
		dispatch(clearListFundRequestsData());
		dispatch(tryListFundRequests(params)).then((response)=>setFoundRequestList([...response?.data?.records]));
	}

	useEffect(() => {
		loadAllData(defaultParams);
		return () => cancel("No longer latest query")
	}, []);

	return <Container  fluid>
		<Card>
			<PageTitle text='Orden de Entrega' />
			<Container fluid>
				<Card className='mb-3'>
                    <Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Editar Orden de Entrega</h1>
                        <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-responsable-entrega-fondos/#ejecuci%C3%B3n-financiera--%C3%B3rdenes-de-entrega'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>
					<Card.Body><h6 className='text-black-color mb-3'>Datos de la Orden de Entrega</h6>
						<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
							<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
						Obligatorio
						</span>
						
						<Form onSubmit={handleSubmit(onFormSubmit)} onKeyDown={(e) => checkKeyDownFilter(e)} className='text-black-color mt-5 mb-5'>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Nro de Orden de Entrega
								</Form.Label>
								<Col sm='3'>
									<Form.Control
										defaultValue={deliveryOrderDataEdit?.number}
										type="number"
										disabled="true"
										placeholder="Número"
										name="numberDeliveryOrder"
										ref={register(numberDeliveryOrderValidationObj)}
										id="numberDeliveryOrderId" />
									<FormFieldError errors={errors?.numberDeliveryOrder} />
								</Col>
								<Col sm='3'>
									<Form.Control
										defaultValue={deliveryOrderDataEdit?.fiscalYearId}
										type='number'
										ref={register}
										name='fiscalYearId'
										id='globalSelectedPeriod'
										value={globalSelectedPeriod?.year}
										readOnly
									/>
								</Col>
								{/* <Form.Label className='text-left d-flex mandatory-label' column sm='1'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='text-danger mandatory-asterisk' />
									Fecha
								</Form.Label>
								<Col sm='3'>
									<Form.Control
										defaultValue={dateToStringFormatedToShowUSA(deliveryOrderDataEdit?.deliveryOrderDate)}
										name='numberDeliveryOrderDate'
										type='date'
										max={todayDate}
										onChange={event => setDateTo(event.target.value)}
										onBlur={event => event.target.value} />
								</Col> */}
							</Form.Group>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Expediente
								</Form.Label>
								<Col sm='2'>
									<Form.Control
										defaultValue={deliveryOrderDataEdit?.administrativeDocument?.codeOrganism}
										type="number"
										placeholder="Código"
										ref={register(codeValidationObj)}
										name='code' />
									<FormFieldError errors={errors?.code} />
								</Col>
								<Col sm='2'>
									<Form.Control
										defaultValue={deliveryOrderDataEdit?.administrativeDocument?.number}
										type="number"
										placeholder="Correlativo"
										ref={register(correlativeValidationObj)}
										name='correlative' />
									<FormFieldError errors={errors?.correlative} />
								</Col>
								<Col sm='2'>
										<Form.Control as='select' onChange={onChangeYearDropdown} value={selectedYear} defaultValue={deliveryOrderDataEdit?.administrativeDocument?.year} >
											<option disabled value={-1} key={-1}>Seleccione año...</option>
											{
												yearsOptions.map((year, index) => {
													return <option key={`year${index}`} value={year}>{year}</option>
												})
											}
										</Form.Control>

								</Col>
							</Form.Group>
							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-left d-flex mandatory-label' column sm='3'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='text-danger mandatory-asterisk' />
										Fecha
									</Form.Label>
									<Col sm='6'>
										<Form.Control
											defaultValue={(deliveryOrderDataEdit?.deliveryOrderDate)}
											name='numberDeliveryOrderDate'
											type='date'
											ref={register(dateDeliveryOrderValidationObj)}
											max={todayDate}
										/>
										<FormFieldError errors={errors?.numberDeliveryOrderDate} />
									</Col>
							</Form.Group>
							{/* <Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='3'>
									Descripción
								</Form.Label>
								<Col sm='6'>
									<Form.Control
										defaultValue={deliveryOrderDataEdit?.description}
										as="textarea" rows={2}
										ref={register}
										name="description"
										id="description"
										maxLength='255'
									/>
								</Col>
							</Form.Group> */}
							<br />
							
							<br />
							<h6 className='text-black-color mb-3'>Pedido de Fondos Disponibles</h6>
							<Table striped bordered hover size='sm' responsive>
								<thead>
								<tr size='lg'>
										<th className='text-center align-middle text-white' width="15%">Servicio Administrativo</th>
										<th className='text-center align-middle text-white' width="15%">Fecha</th>
										<th className='text-center align-middle text-white' width="15%">Pedido de fondo Nro</th>
										<th className='text-center align-middle text-white' width="10%">Año</th>
										<th className='text-center align-middle text-white' width="35%">Descripción</th>
										<th className='text-center align-middle text-white' width="10%">Importe</th>
										<th className='text-center align-middle text-white th-minwidth' width="10%">Acciones</th>
									</tr>
									<tr className='secondary'>
										<th>
											<DropdownList
												placeholder='Seleccione un servicio...'
												data={administrativeServiceData}
												allowCreate='onFilter'
												name='administrativeServiceId'
												textField='displayName'
												valueField='id'
												filter='contains'
												value={servicio}
												className='w-100 text-black-color'
												onChange={value => setServicio(value.id)}
												id='administrativeServiceId'
												messages={messagesDropDown}
												selectIcon={<FontAwesomeIcon   icon={faChevronDown} className='text-black-color' />}
												searchIcon={<FontAwesomeIcon   icon={faSearch} className='text-black-color' />} >
											</DropdownList>
										</th>
										<th></th>
										<th>
											<FormControl
												type='number'
												minlength='2'
												id='searchNumber'
												placeholder="Número"
												onChange={onChangeFilterNumber}
												value={numberFilterQuery}
												ref={filterNumberRef}
											/>
										</th>
										<th>
											<FormControl
												type='year'
												minlength='2'
												id='searchYear'
												placeholder="Año"
												onChange={onChangeFilterYear}
												value={yearFilterQuery}
												ref={filterNumberRef}
											/>
										</th>
										<th>
											<FormControl
												type='text'
												minlength='2'
												id='searchText'
												placeholder="Descripción"
												onChange={onChangeFilterText}
												value={textFilterQuery}
												ref={filterTextRef}
											/>
										</th>
										<th></th>
										<th className='text-center align-middle'>
											<div className='d-flex justify-content-around'>
												<ActionIcon
													size="lg"
													id="search-button"
													className="btn-primary search-button text-white-color"
													toolTipText="Filtrar"
													icon={faFilter}
													onClick={onSubmitFormFilter}
												/>
												<ActionIcon
													size="lg"
													id="clean-filter"
													className="btn-primary clean-filter text-white-color"
													toolTipText="Limpiar filtros"
													icon={faBroom}
													onClick={()=> clearFilters()}
												/>
											</div>
										</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										fundRequestData ?
											fundRequestData?.records?.map(item => <tr key={item.id}>
												<td className='td-maxwidth'>
													{administrativeService(item?.administrativeServiceId, administrativeServiceData)}
												</td>
												<td className='align-middle text-center'>
													{mFormat(item.fundRequestDate)}
												</td>
												<td className='align-middle text-center'>
													{item.number} / {item.year}
												</td>
												<td className='align-middle text-center'>
													{item.year}
												</td>
												<td className='align-middle text-center'>
													{item.description}
												</td>
												<td className='text-right'>
													{formatterPeso.format(item?.amount)}
												</td>

												<td className='d-flex justify-content-around'>
													{fundRequestSelected == item ?
														<ActionIcon size='lg' icon={faCheck} id='adduser' toolTipText='Pedido de fondo seleccionado' className='mr-2' onClick={() => onClickDeliveryOrderSelected(item)} />
														:
														<ActionIcon size='lg' icon={faPlusCircle} id='adduser' toolTipText='Asignar pedido fondo' className='mr-2' onClick={() => onClickDeliveryOrderSelected(item)} />
													}

												</td>
											</tr>)
											:
											<tr>
												<td colSpan='6' className='text-center'>
													{
														!foundRequestListIsFitching &&
															<Alert variant='info' className='mb-0'>
																<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																No hay registros
															</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>

							<RCPagination
								activePage={pageNumber + 1}
								itemsCountPerPage={pageSize}
								totalItemsCount={totalCount}
								totalItems={totalCount}
								thePage={pageNumber + 1}
								onChange={onPageChange}
								innerClass="justify-content-center"
							/>

							<br />
							<Row>
								<Col xs={12}>
								<Form.Label className='text-black-color pr-0 d-flex' >
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger' />
									<h6>Pedido de Fondo seleccionado</h6>
								</Form.Label>
								</Col>
							</Row>
							<Table striped bordered hover size='sm' responsive>
								<thead>
								<tr>
										<th className='text-center align-middle text-white'>Fecha</th>
										<th className='text-center text-white align-middle' width="43%">Pedido de <br /> fondo Nro</th>
										<th className='text-center text-white align-middle' width="14%">Año</th>
										<th className='text-center text-white align-middle' width="43%">Descripción</th>
										<th className='text-center text-white align-middle' width="14%">Importe</th>
										<th className='text-center text-white align-middle' width="14%">Acciones</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										fundRequestSelected ?
											<tr>
												<td className='text-center'>
													{mFormat(fundRequestSelected?.fundRequestDate)}
												</td>
												<td className='text-center'>
													{fundRequestSelected?.number} / {fundRequestSelected?.year}
												</td>
												<td className='text-center'>
													{fundRequestSelected?.year}
												</td>
												<td className='text-center'>
													{fundRequestSelected?.description}
												</td>
												<td className='text-right'>
													{formatterPeso.format(fundRequestSelected?.amount)}
												</td>
												<td className='d-flex justify-content-around'>
													<ActionIcon size='lg' icon={faMinusCircle} id='adduser' toolTipText='Remover pedido fondo' className='text-danger mr-2' onClick={() => onClickRemoveDeliveryOrderSelected(fundRequestSelected)} />
												</td>
											</tr>
											:
											<tr>
												<td colSpan='6' className='text-center'>
													{
														!foundRequestListIsFitching &&
															<Alert variant='info' className='mb-0'>
																<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																No se ha seleccionado ningún pedido de fondo
															</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>

							<br />
							<Form.Group className='d-inline'>
								<Form.Label className='text-black-color pr-0 d-flex' >
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger' />
									<h6>Pedido de fondo asociado</h6>
								</Form.Label>
							</Form.Group>
							<Table striped bordered hover size='sm'>
								<thead>
									<tr>
										<th className='text-center align-middle text-white' width="15%">Servicio Administrativo</th>
										<th className='text-center align-middle text-white' width="15%">Fecha</th>
										<th className='text-center align-middle text-white' width="15%">Pedido de fondo Nro</th>
										<th className='text-center align-middle text-white' width="10%">Año</th>
										<th className='text-center align-middle text-white' width="35%">Descripción</th>
										<th className='text-center align-middle text-white' width="10%">Importe</th>
										<th className='text-center align-middle text-white th-minwidth' width="10%">Acciones</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									<tr>
										<td className='text-center align-middle'>
											{administrativeService(deliveryOrderDataEdit?.fundRequest?.administrativeServiceId, administrativeServiceData)}
										</td>
										<td className='text-center align-middle'>
											{mFormat(deliveryOrderDataEdit?.fundRequest?.fundRequestDate)}
										</td>
										<td className='text-center align-middle'>
											{deliveryOrderDataEdit?.fundRequest?.number} / {deliveryOrderDataEdit?.fundRequest?.year}
										</td>
										<td className='text-right align-middle'>
											{deliveryOrderDataEdit?.fundRequest?.year}
										</td>
										<td className='text-right align-middle'>
											{deliveryOrderDataEdit?.fundRequest?.description}
										</td>
										<td className='text-right align-middle'>
											{formatterPeso.format(deliveryOrderDataEdit?.fundRequest?.amount)}
										</td>
										<td className='text-center align-middle'>
											{fundRequestSelected == deliveryOrderDataEdit?.fundRequest ?
												<ActionIcon
													size='lg'
													icon={faCheck}
													id='adduser'
													toolTipText='Pedido de fondo seleccionado'
													className='mr-2'
													onClick={() => onClickRemoveDeliveryOrderSelected(fundRequestSelected)}
												/>
												:
												<ActionIcon
													size='lg'
													icon={faPlusCircle}
													id='adduser'
													toolTipText='Asignar pedido fondo'
													className='mr-2'
													onClick={() => onClickDeliveryOrderSelected(deliveryOrderDataEdit?.fundRequest)}
												/>
											}
										</td>
									</tr>
								</tbody>
							</Table>

							<br />
							<Form.Group className='mt-4 d-flex justify-content-around text-white-color'>
												<Button className='text-white-color' variant='danger' size='lg' onClick={onClickCancelButton}>
													Cancelar
												</Button>{' '}
												
												{
										!deliveryOrderCreateIsFetching &&
											<Button className='text-white-color' type='submit' variant='success' size='lg'  >
												Guardar
												<FontAwesomeIcon className='ml-3' icon={faSave} />
									</Button>
									}
											</Form.Group>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
		<AppLoading isLoading={deliveryOrderCreateIsFetching || foundRequestListIsFitching} />
	</Container>;
};

export default DeliveryOrderNewPage;