import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, httpDelete, httpPut, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.coreApi.URL}`;
const ALERT_ENDPOINT_URI = '/service-events';

export const listAlert = async ( accessToken, params) => {

	const setbuildURLQuery = {
		profile: params?.profile || null,
		active: params?.active,
		applicationId: config.apis.authApi.APPLICATION_ID,
		sort: params?.sort || 'messageStart,desc',
		page: params?.page || 0,
		size: params?.size || 10,
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${ALERT_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

// Create alert
export const createAlert = async (accessToken, data) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${ALERT_ENDPOINT_URI}`;

	const params = {
		active: data?.active,
		level: data?.level || null,
		message: data?.message || null,
		messageEnd: data?.messageEnd || null,
		messageStart: data?.messageStart || null,
		profile: data?.profile || null,
		userId: data?.userId || null,
		applicationId: config.apis.authApi.APPLICATION_ID || null,
	};

	let response;

	try {
		response = await httpPost(URL, params, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

//Delete alert
export const deleteAlert = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${ALERT_ENDPOINT_URI}/${id}`;

	let response;
	
	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Edit alert
export const editAlert = async (accessToken, data) => {

	const URL = `${API_BASE_URI}${ALERT_ENDPOINT_URI}/${data?.id}`;
	const options = buildDefaultOptions(accessToken);

	const params = {
		active: data?.active,
		level: data?.level || null,
		message: data?.message || null,
		messageEnd: data?.messageEnd || null,
		messageStart: data?.messageStart || null,
		profile: data?.profile || null,
		userId: data?.userId || null,
		applicationId: config.apis.authApi.APPLICATION_ID || null,
	};

	let response;
	
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Detail alert
export const getAlertById = async (accessToken, alertId) => {
	const URL = `${API_BASE_URI}${ALERT_ENDPOINT_URI}/${alertId}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}

export const activeAlert = async ( accessToken, params) => {

	const setbuildURLQuery = {
		profile: params?.profile || null,
		applicationId: config.apis.authApi.APPLICATION_ID,
		sort: params?.sort || 'messageStart,desc',
		page: params?.page || 0,
		size: params?.size || 10,
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${ALERT_ENDPOINT_URI}/findActive?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};