import * as ACTION_TYPES from './bankAccountsFundRequestActionTypes';

export const bankAccountInitialState = {

	bankAccountFundRequestState: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined

	},
	bankAccountAddNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	bankAccountDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	bankAccountDetail: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	bankAccountDetailbyId: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	bankAccountToDetails: {},
	bankAccountToDetailsById: {},

	bankAccountToEdit: {},

	bankAccountEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const bankAccountsFundRequestReducer = (state = bankAccountsFundRequestReducer, action) => {
	switch (action.type) {
		case ACTION_TYPES.GET_LIST_BANK_ACCOUNT_FUND_REQUEST_REQUEST: {
			return {
				...state,
				bankAccountFundRequestState: {
					...state.bankAccountFundRequestState,
					isFetching: true,
					hasError: false,
					error: bankAccountInitialState.bankAccountFundRequestState.error,
					data: bankAccountInitialState.bankAccountFundRequestState.data
				}
			};
		}
		case ACTION_TYPES.GET_LIST_BANK_ACCOUNT_FUND_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountFundRequestState: {
					...state.bankAccountFundRequestState,
					isFetching: false,
					hasError: false,
					error: bankAccountInitialState.bankAccountFundRequestState.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_LIST_BANK_ACCOUNT_FUND_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				bankAccountFundRequestState: {
					...state.bankAccountFundRequestState,
					isFetching: false,
					hasError: true,
					data: bankAccountInitialState.bankAccountFundRequestState.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_LIST_BANK_ACCOUNT_FUND_REQUEST_DATA: {
			return {
				...state,
				bankAccountFundRequestState: {
					...bankAccountInitialState.bankAccountFundRequestState
				}
			};
		}



		case ACTION_TYPES.ADD_NEW_BANK_ACCOUNT_REQUEST_REQUEST: {
			return {
				...state,
				bankAccountAddNew: {
					...state.bankAccountAddNew,
					isFetching: true,
					hasError: false,
					error: bankAccountInitialState.bankAccountAddNew.error,
					data: bankAccountInitialState.bankAccountAddNew.data
				}
			};
		}
		case ACTION_TYPES.ADD_NEW_BANK_ACCOUNT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountAddNew: {
					...state.bankAccountAddNew,
					isFetching: false,
					hasError: false,
					error: bankAccountInitialState.bankAccountAddNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.ADD_NEW_BANK_ACCOUNT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				bankAccountAddNew: {
					...state.bankAccountAddNew,
					isFetching: false,
					hasError: true,
					data: bankAccountInitialState.bankAccountAddNew.data,
					error
				}
			};
		}
		// Delete bank Account
		case ACTION_TYPES.BANK_ACCOUNT_DELETE_REQUEST: {
			return {
				...state,
				bankAccountDelete: {
					...state.bankAccountDelete,
					isFetching: true,
					hasError: false,
					error: bankAccountInitialState.bankAccountDelete.error,
					data: bankAccountInitialState.bankAccountDelete.data
				}
			};
		}
		case ACTION_TYPES.BANK_ACCOUNT_DELETE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountDelete: {
					...state.bankAccountDelete,
					isFetching: false,
					hasError: false,
					error: bankAccountInitialState.bankAccountDelete.error,
					data
				}
			};
		}
		case ACTION_TYPES.BANK_ACCOUNT_DELETE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				bankAccountDelete: {
					...state.bankAccountDelete,
					isFetching: false,
					hasError: true,
					data: bankAccountInitialState.bankAccountDelete.data,
					error
				}
			};
		}

		//Detail bank Account
		case ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUEST: {
			return {
				...state,
				bankAccountDetail: {
					...state.bankAccountDetail,
					isFetching: true,
					hasError: false,
					error: bankAccountInitialState.bankAccountDetail.error
				}
			};
		}
		case ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				bankAccountDetail: {
					...state.bankAccountDetail,
					isFetching: false,
					hasError: false,
					error: bankAccountInitialState.bankAccountDetail.error,
					data
				}
			};
		}
		case ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				bankAccountDetail: {
					...state.bankAccountDetail,
					isFetching: false,
					hasError: true,
					data: bankAccountInitialState.bankAccountDetail.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_DETAIL_BANK_ACCOUNT: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountDetail: data
			}
		}

		//Detail bank Account By ID
		case ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUESTBYID: {
			return {
				...state,
				bankAccountDetailbyId: {
					...state.bankAccountDetailbyId,
					isFetching: true,
					hasError: false,
					error: bankAccountInitialState.bankAccountDetailbyId.error
				}
			};
		}
		case ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUESTBYID_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				bankAccountDetailbyId: {
					...state.bankAccountDetailbyId,
					isFetching: false,
					hasError: false,
					error: bankAccountInitialState.bankAccountDetailbyId.error,
					data
				}
			};
		}
		case ACTION_TYPES.DETAIL_BANK_ACCOUNT_REQUESTBYID_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				bankAccountDetailbyId: {
					...state.bankAccountDetailbyId,
					isFetching: false,
					hasError: true,
					data: bankAccountInitialState.bankAccountDetailbyId.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_DETAIL_BANK_ACCOUNTBYID: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountDetailbyId: data
			}
		}

		//bank Account Edit
		case ACTION_TYPES.EDIT_BANK_ACCOUNT_REQUEST: {
			return {
				...state,
				bankAccountEdit: {
					...state.bankAccountEdit,
					isFetching: true,
					hasError: false,
					error: bankAccountInitialState.bankAccountEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_BANK_ACCOUNT_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				bankAccountEdit: {
					...state.bankAccountEdit,
					isFetching: false,
					hasError: false,
					error: bankAccountInitialState.bankAccountEdit.error,
					data
				}
			};
		}
		case ACTION_TYPES.EDIT_BANK_ACCOUNT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				bankAccountEdit: {
					...state.bankAccountEdit,
					isFetching: false,
					hasError: true,
					data: bankAccountInitialState.bankAccountEdit.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_EDIT_BANK_ACCOUNT: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountEdit: data
			}
		}





		default: {
			return state;
		}
	}
};

export const getBankAccountFundRequestReducer = state => state?.bankAccountsFundRequestReducer;

export const getBankAccountFundRequestReducerParams = state => getBankAccountFundRequestReducer(state)?.bankAccountFundRequestState;
export const getBankAccountFundRequestReducerData = state => getBankAccountFundRequestReducerParams(state)?.data;
export const getBankAccountFundRequestReducerIsFetching = state => getBankAccountFundRequestReducerParams(state)?.isFetching;
export const getBankAccountFundRequestReducerClear = state => getBankAccountFundRequestReducerParams(state)?.data;

export const getBankAccountAddNew = state => getBankAccountFundRequestReducer(state)?.bankAccountAddNew;
export const getBankAccountAddNewData = state => getBankAccountAddNew(state)?.data;
export const getBankAccountAddNewIsFetching = state => getBankAccountAddNew(state)?.isFetching;

// Delete bank account
export const getBankAccountDelete = state => getBankAccountFundRequestReducer(state)?.bankAccountDelete;
export const getAdministrativeOrganismDeleteData = state => getBankAccountDelete(state)?.data;
export const getAdministrativeOrganismDeleteIsFetching = state => getBankAccountDelete(state)?.isFetching;


// bank account  to edit
// Edit administrative service 
export const getBankAccountToEdit = state => getBankAccountFundRequestReducer(state)?.bankAccountEdit;
export const getBankAccouontIsFetching = state => getBankAccountToEdit(state)?.isFetching;
export const getBankAccouontIsError = state => getBankAccountToEdit(state)?.hasError;
export const getBankAccouontToEdit = state => getBankAccountToEdit(state)?.bankAccountToEdit;


export const getAdministrativeOrganismToEdit = state => getAdministrativeOrganismReducer(state)?.setAdministrativeOrganismToEdit;


// bank account to details
export const getBankAccount = state => getBankAccountFundRequestReducer(state)?.bankAccountDetail;
export const getBankAccountDetailData = state => getBankAccount(state)?.data;
export const getBankAccountIsFetching = state => getBankAccount(state)?.isFetching;

export const getBankAccountToDetail = state => getUserReducer(state)?.bankAccountToDetails;

// bank account to details By id
export const getBankAccountById = state => getBankAccountFundRequestReducer(state)?.bankAccountDetailbyId;
export const getBankAccountByIdDetailData = state => getBankAccountById(state)?.data;
export const getBankAccountByIdIsFetching = state => getBankAccountById(state)?.isFetching;

export const getBankAccountByIdToDetail = state => getUserReducer(state)?.bankAccountToDetailsById;