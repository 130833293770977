// Subcode process in progress
export const SET_SUBCODE_PROCESS_IN_PROGRESS = 'SET_SUBCODE_PROCESS_IN_PROGRESS';

// Subcode list actions
export const LIST_SUBCODE_REQUEST = 'LIST_SUBCODE_REQUEST';
export const LIST_SUBCODE_REQUEST_SUCCESS = 'LIST_SUBCODE_REQUEST_SUCCESS';
export const LIST_SUBCODE_REQUEST_FAILURE = 'LIST_SUBCODE_REQUEST_FAILURE';
export const CLEAR_SUBCODE_LIST_DATA = 'CLEAR_SUBCODE_LIST_DATA';

// Subcode to edit actions
export const SET_SUBCODE_TO_EDIT = 'SET_SUBCODE_TO_EDIT';
export const CLEAR_SUBCODE_TO_EDIT = 'CLEAR_SUBCODE_TO_EDIT';

// Subcode details page actions
export const SET_SUBCODE_DETAILS_PAGE_DATA = 'SET_SUBCODE_DETAILS_PAGE_DATA';
export const CLEAR_SUBCODE_DETAILS_PAGE_DATA = 'CLEAR_SUBCODE_DETAILS_PAGE_DATA';

// Selected legal instrument actions
export const SET_SELECTED_LEGAL_INSTRUMENT = 'SET_SELECTED_LEGAL_INSTRUMENT';
export const CLEAR_SELECTED_LEGAL_INSTRUMENT = 'CLEAR_SELECTED_LEGAL_INSTRUMENT';

// Subcode details request actions
export const GET_SUBCODE_DETAILS_REQUEST = 'GET_SUBCODE_DETAILS_REQUEST';
export const GET_SUBCODE_DETAILS_REQUEST_SUCCESS = 'GET_SUBCODE_DETAILS_REQUEST_SUCCESS';
export const GET_SUBCODE_DETAILS_REQUEST_FAILURE = 'GET_SUBCODE_DETAILS_REQUEST_FAILURE';
export const CLEAR_SUBCODE_DETAILS = 'CLEAR_SUBCODE_DETAILS';

// Subcode data request actions
export const GET_SUBCODE_DATA_REQUEST = 'GET_SUBCODE_DATA_REQUEST';
export const GET_SUBCODE_DATA_REQUEST_SUCCESS = 'GET_SUBCODE_DATA_REQUEST_SUCCESS';
export const GET_SUBCODE_DATA_REQUEST_FAILURE = 'GET_SUBCODE_DATA_REQUEST_FAILURE';
export const CLEAR_SUBCODE_DATA = 'CLEAR_SUBCODE_DATA';

// Subcode post request actions
export const POST_SUBCODE_REQUEST = 'POST_SUBCODE_REQUEST';
export const POST_SUBCODE_REQUEST_SUCCESS = 'POST_SUBCODE_REQUEST_SUCCESS';
export const POST_SUBCODE_REQUEST_FAILURE = 'POST_SUBCODE_REQUEST_FAILURE';

// Subcode get file request actions
export const GET_FILE_SUBCODE_REQUEST = 'GET_FILE_SUBCODE_REQUEST';
export const GET_FILE_SUBCODE_REQUEST_SUCCESS = 'GET_FILE_SUBCODE_REQUEST_SUCCESS';
export const GET_FILE_SUBCODE_REQUEST_FAILURE = 'GET_FILE_SUBCODE_REQUEST_FAILURE';

// Subcode affectations request actions
export const GET_SUBCODE_AFFECTATIONS_REQUEST = 'GET_SUBCODE_AFFECTATIONS_REQUEST';
export const GET_SUBCODE_AFFECTATIONS_REQUEST_SUCCESS = 'GET_SUBCODE_AFFECTATIONS_REQUEST_SUCCESS';
export const GET_SUBCODE_AFFECTATIONS_REQUEST_FAILURE = 'GET_SUBCODE_AFFECTATIONS_REQUEST_FAILURE';
export const CLEAR_SUBCODE_AFFECTATIONS = 'CLEAR_SUBCODE_AFFECTATIONS';

// Subcode affectations history request actions
export const GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST = 'GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST';
export const GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_SUCCESS = 'GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_SUCCESS';
export const GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_FAILURE = 'GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_FAILURE';
export const CLEAR_SUBCODE_AFFECTATIONS_HISTORY = 'CLEAR_SUBCODE_AFFECTATIONS_HISTORY';


//Compensation New
export const POST_COMPENSATION_REQUEST = 'POST_COMPENSATION_REQUEST';
export const POST_COMPENSATION_REQUEST_SUCCESS = 'POST_COMPENSATION_REQUEST_SUCCESS';
export const POST_COMPENSATION_REQUEST_FAILURE = 'POST_COMPENSATION_REQUEST_FAILURE';

//Preload State
export const SET_PRELOAD_STATE = 'SET_PRELOAD_STATE';
export const CLEAR_PRELOAD_STATE = 'CLEAR_PRELOAD_STATE';