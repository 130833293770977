import * as ACTIONS from './subconceptActions';
import * as SERVICES from 'src/services/conceptsServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

// Try list
export const tryListSubconcepts = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listSubconceptRequest());

		if (accessToken) {

			const response = await SERVICES.getSubconcepts(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.listSubconceptSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listSubconceptFailure(response?.response?.data));
				showError(`Error al obtener los subconceptos. Detalle: ${response?.response?.data?.message || response}`);
			}
			return response;
		}

	};
}