export const LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST = 'LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST';
export const LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_SUCCESS = 'LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_SUCCESS';
export const LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_FAILURE = 'LIST_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_REQUEST_FAILURE';
export const CLEAR_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_LIST = 'CLEAR_PARTIAL_BUDGET_BY_PRINCIPAL_BUDGET_ID_LIST';

export const LIST_SUB_PARTIAL_BUDGET_REQUEST = 'LIST_SUB_PARTIAL_BUDGET_REQUEST';
export const LIST_SUB_PARTIAL_BUDGET_REQUEST_SUCCESS = 'LIST_SUB_PARTIAL_BUDGET_REQUEST_SUCCESS';
export const LIST_SUB_PARTIAL_BUDGET_REQUEST_FAILURE = 'LIST_SUB_PARTIAL_BUDGET_REQUEST_FAILURE';
export const CLEAR_LIST_SUB_PARTIAL_BUDGET = 'CLEAR_LIST_SUB_PARTIAL_BUDGET';

export const GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS = 'GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS';
export const GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_SUCCESS = 'GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_SUCCESS';
export const GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_FAILURE = 'GET_SUBPARTIAL_BUDGET_OF_SELECTED_CREDITS_REQUESTS_FAILURE';