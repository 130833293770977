import React, { useEffect, useState} from 'react';
import { push } from 'connected-react-router';
import { Container,  Card, Table, Button, Spinner, Alert, Form, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionIcon from 'src/components/general/ActionIcon';
import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';
import { faPlus, faExclamationTriangle, faBroom,  faFilter, faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { notifications, newNotification, dateNotification, subject, message, actions, attachedFiles, noNotification, filter, cleanFiltersLabel, downloadAttachment, notificationDetail } from 'src/utils/label';
import { getClearObjectAlt} from 'src/utils/utils';
import { setAssignProfiles } from 'src/utils/usersProfiles';
import { NOTIFICATION_NEW, NOTIFICATION_DETAILS } from 'src/utils/constants';
import { tryGetNotificationList, tryDownloadFileOfNotification } from 'src/redux/notification/notificationActionCreator'; 
import { setNotificationToDetails, clearNotificationList } from 'src/redux/notification/notificationActions'
import { getNotificationListData, getNotificationListIsFetching, getNotificationDownloadFileIsFetching } from 'src/redux/notification/notificationReducer';
import { getUserProfileListData, getUserProfileListIsFetching } from 'src/redux/user/userReducer';
import { tryGetListUserProfile } from 'src/redux/user/userActionCreator';
import RCPagination from 'src/components/common/RCPagination';
import AppLoading from 'src/components/common/AppLoading';
import { MultiSelect } from "react-multi-select-component";
import { getProfileName } from 'src/redux/login/loginReducer';

const NotificationListingPage = () => {

    const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useForm();

    const notificationListData = useSelector(state => getNotificationListData(state));
	const notificationListIsFetching = useSelector(state => getNotificationListIsFetching(state));
	const notificationListArrayRecords = notificationListData?.records;
	const hasNotificationList= isNotEmptyArray(notificationListArrayRecords);

    //Profile user
    const profileName = useSelector(state => getProfileName(state));
	const userProfileId = useSelector(state => getUserProfileListData(state))?.find(item => item?.name == profileName)?.id;

    const profileNameAI = profileName == 'Dirección de Análisis e Informática';
    const profileNameSA = profileName == 'Super Admin';

    const showNotificationButton = profileNameAI || profileNameSA;

    // Set config Users By Profile
	const profileNameArray = setAssignProfiles();

    // Filter for type profile
    const listProfileData = useSelector(state => getUserProfileListData(state))?.sort((profileValue1, profileValue2) => {
		return (profileValue1.name?.toUpperCase() < profileValue2.name?.toUpperCase()) ? -1 : 1;
	});
	const profileNameArrayRecords = listProfileData?.filter(item => profileNameArray?.includes(item?.name));
    const listProfileIsFetching = useSelector(state => getUserProfileListIsFetching(state));
    
	const downloadFileOfNotificationIsFetching = useSelector(state => getNotificationDownloadFileIsFetching(state));

    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const maxCharacters = 100;

    const defaultParams = {
        page: 0,
        size: 10,
        profileID: showNotificationButton ? '' : userProfileId
    };
    
    const [filterObject, setFilterObject] = useState({});

    //Pagination
    const notificationListPaginate = useSelector(state => getNotificationListData(state)?.metadata);

    const [pageNumber, setPageNumber] = useState(0);
    const totalCount = notificationListPaginate?.filteredCount;
	const totalItems = notificationListPaginate?.filteredCount;

	const onPageChange = (pNumber) => {
		const params = {
			...defaultParams,
            ...filterObject,
			page: pNumber - 1
		}
		fetchList(params);
	}

    //Actions

    const onClickSeeDetails = (notification) => {
        dispatch(setNotificationToDetails(notification));;
        dispatch(push(NOTIFICATION_DETAILS));
    };

    const onClickDownloadFile = (notification) => {
        dispatch(tryDownloadFileOfNotification(notification?.id));
    };

    const onSubmitForm = (data) => {

        cancelSource.cancel();
		cancelSource = CancelToken.source();

        const profileIdArray = [];

        selected?.map(profile => {
            profileIdArray.push(profile?.value);
        });

        const profileIdString = profileIdArray.toString();

        const params = {
            ...data,
            profileID: profileIdString
        }

		const paramsToSend = getClearObjectAlt(params);

        setFilterObject(paramsToSend);
		fetchList(paramsToSend);
    };

    //// Multiselect
	// View items selector
	let options = [];
    let i = 0;
	for (i in profileNameArrayRecords) {
		options.push({  label: profileNameArrayRecords[i]?.name, value: profileNameArrayRecords[i]?.id })
	}

    // Add options
	const [selected, setSelected] = useState([]);

	// Idiom Spanish
	const idiom = {
		"selectSomeItems": "Filtrar por grupo ...",
		"allItemsAreSelected": "Todos los elementos están seleccionados.",
		"selectAll": "Seleccionar todo",
		"search": "Buscar",
		"clearSearch": "Limpiar búsqueda."
	}

	// Load data 	
	const customValueRenderer = (selected, _options) => {
		return selected.length
			? selected.map(({ label }) => label)
			: "Filtrar por grupo ...";
	};

	//// END Multiselect	

    const attachedOptions = [ {id: '0', name: 'Seleccionar...', value: ''}, { id: '1', name: 'No', value: false}, { id: '2', name: 'Si', value: true}];

    const cleanFilters = () => {
        setSelected([]);
		reset();
        setFilterObject({});
        loadNotifications();
    };

    const fetchList = (params) => {
		dispatch(clearNotificationList());
        if (!isNaN(userProfileId) || showNotificationButton) {
            dispatch(tryGetNotificationList({...defaultParams, ...params})).then((response) => {
                setPageNumber(response?.metadata?.page);
            });
        }
	};

    const loadNotifications = () => {
		fetchList(defaultParams);
	};

    //Cancel Request
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();

    // useEffects
	useEffect(() => {
		loadNotifications();
        dispatch(tryGetListUserProfile());
	}, [userProfileId]);

    return <Container fluid className='mb-5'>
        <Card>
			<PageTitle text={notifications} />
			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
                        <div className='d-flex align-items-center mb-3'>
                            {
								showNotificationButton
									&&
                                    <Button size='sm' className='mb-3 btn-success' variant='secondary' onClick={() => dispatch(push(NOTIFICATION_NEW))}>
				    					 <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                        {newNotification}
                                    </Button>
							}
                            <Col sm='2' className={showNotificationButton ? 'ml-3 mb-3' : 'ml-0 pl-0'}>
                                <MultiSelect
                                    className='text-black-color'
                                    options={options}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy={"Select"}
                                    overrideStrings={idiom}
                                    valueRenderer={customValueRenderer}
                                    hasSelectAll={true}
                                    isLoading={listProfileIsFetching}
                                    ClearSelectedIcon={"🧹Limpiar"}
                                />
                            </Col>
                        </div>

                        <Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>

                            <Table striped hover className='table-responsive' size='sm'>
                                <thead>
                                    <tr>
                                        <th className='text-center' width='12%'>{dateNotification}</th>
                                        <th className='text-center' width='27%'>{subject}</th>
                                        <th className='text-center' width='45%'>{message}</th>
                                        <th className='text-center' width='7%'>{attachedFiles}</th>
                                        <th className='text-center' width='10%'>{actions}</th>
                                    </tr>
                                    <tr className='secondary'>
										<th className='text-center align-middle'>
										    <Form.Control type='date' max={todayDate} className='text-center' name='createdAt' ref={register}  size='sm' />
										</th>
                                        <th className='text-center align-middle'>
										    <Form.Control className='text-center' name='subject' placeholder="Asunto" ref={register}  size='sm' maxLength={maxCharacters}/>
										</th>
                                        <th className='text-center align-middle'>
										    <Form.Control className='text-center' name='body' placeholder="Mensaje" ref={register}  size='sm' maxLength={maxCharacters}/>
										</th>
                                        <th className='text-center align-middle'>
                                            <Form.Control className='text-black-color' as='select' name='attachments' ref={register} size='sm'>
                                                    {
                                                        attachedOptions?.map(item => (
                                                        <option key={item?.id} value={item?.value}>
                                                            {item?.name}
                                                        </option>))
                                                    }
                                            </Form.Control>
										</th>
                                        <>
											<th className='text-center justify-content-around custom-button border-0'>
												<>
												    <ActionIcon
														size="lg"
														id="search-button"
														className="text-white-color"
														toolTipText={filter}
														icon={faFilter}
														type='submit'
                                                        disabled={notificationListIsFetching}										
													/>
												</>
												<>
													<ActionIcon
														size="lg"
								    					id="clean-filter"
														className="text-white-color"
														toolTipText={cleanFiltersLabel}
														icon={faBroom}
														type='reset'
														onClick={()=> cleanFilters()}
                                                        disabled={notificationListIsFetching}	
													/>
												</>
											</th>
										</>
                                    </tr>
                                </thead>

                                <tbody className='text-black-color'>
                                    {
                                        hasNotificationList ?
                                            notificationListArrayRecords?.map(notification => 
                                                <tr key={notification?.id}>
                                                    <td className='text-center'>
                                                        {moment(notification?.createdDate).format('DD/MM/YYYY hh:mm') || '-'}
                                                    </td>
                                                    <td className='text-center truncate maxTruncate' title={notification?.subject}>
                                                        {notification?.subject || '-'}
                                                    </td>
                                                    <td className='text-center truncate maxTruncate' title={notification?.body}>
                                                        {notification?.body || '-'}
                                                    </td>
                                                    <td className='text-center'>
                                                       {
                                                           notification?.attachments?.length > 0
                                                            ?
                                                                <ActionIcon  size='lg' id='download' toolTipText={downloadAttachment} icon={faDownload} onClick={() => onClickDownloadFile(notification)}/>
                                                            :
                                                                'No'
                                                        }
                                                    </td>
                                                    <td className='text-center'>
                                                        <ActionIcon   toolTipText={notificationDetail} icon={faSearch} onClick={() => onClickSeeDetails(notification)} />
                                                    </td>
                                                </tr>
                                            )
                                        :
                                        <tr>
                                            <td colSpan='10' className='text-center'>
                                                {
                                                    notificationListIsFetching 
                                                        ?
                                                            <Spinner animation='border' variant='success' className='my-3' />
                                                        :
                                                            <Alert variant='info' className='mb-0'>
                                                                <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                                {noNotification}
                                                            </Alert>
                                                }
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>

                        </Form>
                        
                        {
                            hasNotificationList &&
                                <RCPagination
                                    activePage={pageNumber + 1}
                                    itemsCountPerPage={defaultParams?.size}
                                    totalItemsCount={totalCount}
                                    totalItems={totalItems}
                                    thePage={pageNumber + 1}
                                    onChange={onPageChange}
                                    innerClass="justify-content-center"
                                />
                        }
                        
                    </Card.Body>
                </Card>
            </Container>
            <AppLoading isLoading={downloadFileOfNotificationIsFetching} />
        </Card>
    </Container>
};

export default NotificationListingPage;