import * as ACTION_TYPES from './versionActionTypes';

// Version Funds Requests Api
export const getVersionFundsRequestsApiRequest = () => ({
	type: ACTION_TYPES.GET_VERSION_FUNDS_REQUESTS_API_REQUEST
});

export const getVersionFundsRequestsApiRequestSuccess = data => ({
	type: ACTION_TYPES.GET_VERSION_FUNDS_REQUESTS_API_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getVersionFundsRequestsApiRequestFailure = error => ({
	type: ACTION_TYPES.GET_VERSION_FUNDS_REQUESTS_API_REQUEST_FAILURE,
	payload: {
		error
	}
});

// Version Core Api
export const getVersionCoreApiRequest = () => ({
	type: ACTION_TYPES.GET_VERSION_CORE_API_REQUEST
});

export const getVersionCoreApiRequestSuccess = data => ({
	type: ACTION_TYPES.GET_VERSION_CORE_API_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getVersionCoreApiRequestFailure = error => ({
	type: ACTION_TYPES.GET_VERSION_CORE_API_REQUEST_FAILURE,
	payload: {
		error
	}
});

// Version Presupuesto Api
export const getVersionPresupuestoApiRequest = () => ({
	type: ACTION_TYPES.GET_VERSION_PRESUPUESTO_API_REQUEST
});

export const getVersionPresupuestoApiRequestSuccess = data => ({
	type: ACTION_TYPES.GET_VERSION_PRESUPUESTO_API_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getVersionPresupuestoApiRequestFailure = error => ({
	type: ACTION_TYPES.GET_VERSION_PRESUPUESTO_API_REQUEST_FAILURE,
	payload: {
		error
	}
});

// Version Auth Api
export const getVersionAuthApiRequest = () => ({
	type: ACTION_TYPES.GET_VERSION_AUTH_API_REQUEST
});

export const getVersionAuthApiRequestSuccess = data => ({
	type: ACTION_TYPES.GET_VERSION_AUTH_API_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getVersionAuthApiRequestFailure = error => ({
	type: ACTION_TYPES.GET_VERSION_AUTH_API_REQUEST_FAILURE,
	payload: {
		error
	}
});