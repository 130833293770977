import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import 'react-widgets/styles.css';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import { isNotEmptyArray } from 'src/services/validationService';
import { push } from 'connected-react-router';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod, getReportDatesData } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportBudgetAnalyticalAccountingCreditExecution } from 'src/redux/reports/reportsActionCreactor';
import { getReportBudgetAnalyticalAccountingCreditExecutionIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportBudgetAnalyticalAccountingCreditExecution } from 'src/redux/reports/reportsActions';
import { getListAllServiceAdministrativeByUserData, getListAllServiceAdministrativeByUserIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import { clearListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActions';
import RangeDates from 'src/components/common/RangeDates';
import { shortlistedService } from 'src/utils/utils';
import AppLoading from 'src/components/common/AppLoading';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';
import MultiselectComponent from '../../common/MultiselectComponent';

const ReportBudgetAnalyticalAccountingCreditExecutionPage = () => {
	const dispatch = useDispatch();
	const methods = useForm();
	const [checkPR, setCheckPR] = useState(false);
	const clickPassiveRemainingHandler = () => {
		setCheckPR(!checkPR);
	};
 
	//User Id
	const email = useSelector( state => getEmail(state) );
	const listUserData = useSelector(state => getUserListData (state));
	const userId = listUserData?.records?.find(item => item.email == email);
	const [showMsgReportInProcess, setShowMsgReportInProcess] = useState(false);
	const reportDates = useSelector( state => getReportDatesData(state) );

	// Periods
	const periodList = useSelector( state => getGlobalDataPeriodsData(state) )?.data?.sort( (a,b) => a.year<b.year ? 1 : -1 );
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	//Services Administrative
	const administrativeService = useSelector(state => getListAllServiceAdministrativeByUserData(state))?.sort( (a,b) => a.code>b.code ? 1 : -1 );
	const administrativeServiceIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

	// Reports redux
	const reportIsFetching = useSelector(state => getReportBudgetAnalyticalAccountingCreditExecutionIsFetching(state));
	
	const [dateFrom, setDateFrom] = useState(reportDates?.dateFrom);
	const [dateTo, setDateTo] = useState(reportDates?.dateTo);
	const [dateHasErrors, setDateHasErrors] = useState(false);

	useEffect(() => {
		dispatch(tryGetUserList());
		dispatch(clearReportBudgetAnalyticalAccountingCreditExecution());
		dispatch(clearListAllServiceAdministrativeByUser());
		dispatch(tryListAllServiceAdministrativeByUser(userId?.id));
	}, [globalSelectedPeriod]);

	// Multiselect items del selector
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		let optionsArray = [];
		let i = 0;
		for (i in administrativeService){
			optionsArray.push({label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value:  administrativeService[i]?.id} )
		}
		setOptions(optionsArray);
	}, [administrativeService]);

	useEffect(() => {
		const uniqueService = shortlistedService(options);
		if(uniqueService){
			setSelected(options);
		}
	}, [options]);
	// END Multiselect	

	const getReport = outputFormat => {
		if (globalSelectedPeriod) {
			setShowMsgReportInProcess(false);
			const params = {
				outputFormat,
				exerciseId: globalSelectedPeriod?.id,
				serviceIds: selected?.map(administrativeService => administrativeService?.value),
				dateFrom,
				dateTo,
				passiveRemaining: checkPR
			};
			dispatch(tryGetReportBudgetAnalyticalAccountingCreditExecution(params)).then( response => {
				if (response?.status == 200) {
					setShowMsgReportInProcess(true);
				}
			})
		}
	};

	const onClickPDF = () => getReport(1);
	const onClickXLS = () => getReport(2);

	const allowGetReport = (hasPeriodList && !reportIsFetching && dateFrom && dateTo && !dateHasErrors && selected.length > 0);	

	return <Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Reporte de analítico de contabilidad de presupuesto</h1>
                        <a 	className='text-white'
							target="_blank"
							href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#anal%C3%ADtico-de-contabilidad-de-presupuesto'
						>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>  
					<Card.Body>
						<Form>
							<Row>
								<Col className="offset-sm-2 col-sm-8">
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>			
										<Form.Control 
											type='number' 
											name='globalSelectedPeriod' 
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
										/>
									</Form.Group>
									<Form.Group>
										<Form.Label className='text-black-color'>
											Servicio
										</Form.Label>
										<MultiselectComponent
											options={options}
											selected={selected}
											setSelected={setSelected}
											isLoading={administrativeServiceIsFetching}
										/>
									</Form.Group> 
									<FormProvider {...methods}>
										<RangeDates
											{...{dateFrom, setDateFrom, dateTo, setDateTo, setDateHasErrors}}
										/>
									</FormProvider>
									<Form.Group>																										
										<Form.Check 
											type="checkbox"
											id="passiveRemaining"
											label="Residuos Pasivos"
											className="text-black-color"
											onChange={clickPassiveRemainingHandler}
											checked={checkPR}
										/>
									</Form.Group>
									{ showMsgReportInProcess &&
										<Alert key={'1'} variant={'primary'} className='mt-4'>
											<b className='text-black-color' >Aviso:</b> El reporte se esta generando en segundo plano y estará disponible en el módulo de 
											<a  className='font-italic text-info cursor-pointer' 
												onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
												&nbsp;reportes generados.
											</a>
										</Alert>
									}
									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Generar PDF
										</Button>
										{ reportIsFetching &&
											<Spinner animation='border' />
										}
										<AppLoading isLoading={reportIsFetching} />
										<Button size='lg' onClick={onClickXLS} disabled={!allowGetReport}>
											<FontAwesomeIcon icon={faFileExcel} className='mr-2' />
											Generar XLS
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Card.Body>
				</Card>
			</Container>
};

export default ReportBudgetAnalyticalAccountingCreditExecutionPage;