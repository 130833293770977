import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal, Container, Table, Card } from 'react-bootstrap';
import { formatterPeso } from 'src/utils/utils';
import PreloadLegalInstrumentForm from 'src/components/pages/CreditExecution/PreloadLegalInstrumentForm';
import AppLoading from 'src/components/common/AppLoading';
import { getAttachLegalInstrumentPreloadIsFetching } from 'src/redux/preload/preloadReducer';
import { getLegalInstrumentCreateIsFetching, getLegalInstrumentListIsFetching } from 'src/redux/legalInstrument/legalInstrumentReducer';
import { getSelectedLegalInstrument } from 'src/redux/credit/creditReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { tryAttachLegalInstrumentToPreload } from 'src/redux/preload/preloadActionCreator';
import swal from 'sweetalert';
import { PRE_LOAD } from 'src/utils/constants';

const PreLoadExecutionModal = ({show, handleClose, dataModal}) => {

    const dispatch = useDispatch();
	const totals = dataModal && Object.values(dataModal['subCodePreload'])?.map((item)=>item?.amount).reduce((a, b)=> a + b);
    const legalInstrumentSearchIsFetching = useSelector(state => getLegalInstrumentListIsFetching(state));
    const legalInstrumentCreateIsFetching = useSelector(state => getLegalInstrumentCreateIsFetching(state));
    const attachlegalInstrumentPreloadIsFetching = useSelector(state => getAttachLegalInstrumentPreloadIsFetching(state));
    const selectedLegalInstrument = useSelector(state => getSelectedLegalInstrument(state));
    const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));

	const handlerSubmitPreload = () => {
        swal({
            title: 'Confirmación',
            text: "¿Esta seguro de Guardar los cambios?" ,
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willUpdate) => {
                if (willUpdate) {
                    const preloadDto = { subcodes: dataModal?.subCodePreload?.map((item)=> (
                        {
                            sub_code_preload_id: item?.id,
                            sub_code_id: item?.subCode?.id,
                            amount: item?.amount,
                            period_id: periodSelected?.id,
                            legal_instrument_id: selectedLegalInstrument?.id,
                            sub_code_detail_type_id: 2,
                            observation: selectedLegalInstrument?.description || null,
                            number: dataModal?.expedient?.number,
                            organizationCode: parseInt(dataModal?.expedient?.organizationCode),
                            title: dataModal?.expedient?.title,
                            year: dataModal?.expedient?.year,
                        }
                    ))
                };

                dispatch(tryAttachLegalInstrumentToPreload(preloadDto)).then(
                    () => dispatch(push(PRE_LOAD)));
                }
            });
    };

    return (
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" className='mt-2 mb-4'>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Instrumento Legal</Modal.Title>
                </Modal.Header>
                <Container fluid className='mt-3 px-5'>
                    <Card variant='success' className='mt-3'>
                        <Card.Header>
                             <h5 className='mb-0'>
                                Expediente
                            </h5>
                        </Card.Header>
                    </Card>
                    <Table striped bordered hover>
                        <tbody className='text-black-color'>
                            <tr>
                                <td>{dataModal?.expedient?.completeLabel}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Container fluid className='mt-3 px-5'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center align-middle'>Partida</th>
                                    <th className='text-center align-middle'>Importe</th>
                                </tr>
                            </thead>
                            <tbody className='text-black-color'>
                                {dataModal?.subCodePreload?.map((item, idx) => 
                                    <tr key={idx}>
                                        <td className='text-center align-middle'>{item?.subCode?.completeNumber}</td>
                                        <td className='text-right'>{formatterPeso.format(item?.amount)}</td>
                                    </tr>
                                )}
                                <tr className='text-black-color font-weight-bold'>
                                    <td>Total</td>
                                    <td className='text-right'>{formatterPeso.format(totals)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                    <PreloadLegalInstrumentForm
                        handlerSubmitPreload={handlerSubmitPreload}
                    />
                </Container>
                <AppLoading isLoading={legalInstrumentSearchIsFetching || legalInstrumentCreateIsFetching || attachlegalInstrumentPreloadIsFetching} />
            </Modal>
        );
    };
export default PreLoadExecutionModal;