import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Button, FormGroup, Row, Col, FormLabel, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSave } from '@fortawesome/free-solid-svg-icons';
import { getClearObject, getFundRequestTypeValueToShow } from 'src/utils/utils';
import { FUND_REQUESTS_LIST } from 'src/utils/constants';
import PDFormFactory from 'src/components/forms/fundRequest/PDFormFactory';
import AppLoading from 'src/components/common/AppLoading';
import { tryGetTypeListFundRequests, tryPutFundRequests, tryGetFundRequestsById } from 'src/redux/fundRequests/fundRequestsActionCreator';
import { clearFundRequestManualItems, clearTypeListFundRequests, setFundRequestsToEdit } from 'src/redux/fundRequests/fundRequestsActions';
import { getFundRequestsToEdit, getFundRequestsEditIsFetching, getFundRequestManualItems } from 'src/redux/fundRequests/fundRequestsReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { figurativeRegistrationMissing } from 'src/utils/label';
import { showError } from '../../../redux/globalData/globalDataActionCreator';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';

const FundRequestsEditPage = () => {
	const dispatch = useDispatch();
    const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;
	const fundRequestEditSelected = useSelector(state => getFundRequestsToEdit(state));
    const fundRequestManualItems = useSelector(state => getFundRequestManualItems(state));
    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ ordenesAsociadas, setOrdenesAsociadas ] = useState(fundRequestEditSelected?.details? fundRequestEditSelected.details: []);
	const [ itemsRelated, setItemsRelated ] = useState([]);
	const [ admServiceId, setAdmServiceId ] = useState();
	const [ bankAccountId, setBankAccountId ] = useState();

	Object.assign(hookFormMethods, {
		admServiceId,
		setAdmServiceId,
		bankAccountId,
		setBankAccountId,
		selectedItems,
		setSelectedItems,
		ordenesAsociadas,
		setOrdenesAsociadas,
		itemsRelated,
		setItemsRelated,
	});

	const isEditing = useSelector(state => getFundRequestsEditIsFetching(state));
	const hasFigurativeExpenditureSelected = isNotEmptyArray(selectedItems?.filter((item) => item?.paymentOrder?.hasFigurativeExpenditure));

	const disabledSaveWithoutFigurativeRegister = !selectedItems?.filter((item) => item?.paymentOrder?.hasFigurativeExpenditure)
												.every((b) => b?.figurativeExpenditures)

	const onClickCancelButton = () => {
		dispatch(push(FUND_REQUESTS_LIST));
	};

	const cleanUp = () => {
		dispatch(clearTypeListFundRequests());
		dispatch(setFundRequestsToEdit());
		setAdmServiceId();
		setSelectedItems([]);
		setItemsRelated([]);
	};

	useEffect(() => {
		dispatch(tryGetTypeListFundRequests());
		(fundRequestEditSelected.fundRequestType !== 'PEDIDO_DE_FONDOS_MANUAL') && dispatch(clearFundRequestManualItems());
		dispatch(tryGetFundRequestsById(fundRequestEditSelected?.id)).then(
			(response) => {
				dispatch(setFundRequestsToEdit(response));
			}
		);

		return () => {
			cleanUp();
		}
	}, []);

	const onSubmitForm = (data) => {
		// let details = selectedItems.map(item=> ({ paymentOrder: { id:item?.paymentOrder?.id, fundRequestFigurativeExpenditures:item?.paymentOrder?.fundRequestFigurativeExpenditures} }));
		let paymentsOrders = selectedItems.map(i=> ({id:i?.paymentOrder?.id}));
		const pickedItems = selectedItems.concat(ordenesAsociadas.filter(i=>i.selected))
		const fundRequestDto = {
			administrativeServiceId: admServiceId,
			bankAccount: {
				id: parseInt(data?.bankAccount)
			},
			details: pickedItems,
			expiredDate: data?.expire_date,
			fundRequestDate: data?.date,
			description: data?.asunto,
			number: data?.number,
			year: data?.fiscalYearId,
			state: fundRequestEditSelected?.state,
			fundRequestType: fundRequestEditSelected?.fundRequestType,
			manualInputs: fundRequestManualItems,
			administrativeDocument: {
				id: fundRequestEditSelected?.administrativeDocument?.id,
				year: data?.organismYear,
				number: data?.organismNumber,
				codeOrganism: data?.organismCode,
				title: "Orden de pago"
			},
		};

		const filterFundRequestDto = getClearObject(fundRequestDto);		
		if(pickedItems.length>=6) {
			showError('No puede añadir mas de 5 Ordenes de Pago por Pedido de Fondos')
		} else {
			dispatch(tryPutFundRequests(fundRequestEditSelected.id, filterFundRequestDto)).then(response => {
			    if (!(response?.data?.hasError)) {
					dispatch(push(FUND_REQUESTS_LIST));
			    }
			});
		}
	};

	return <>
		<>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Editar Pedido de Fondos</h1>
                        <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-operador-tesoreria/#ejecuci%C3%B3n-financiera--pedido-de-fondos'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>
					<Card.Body>
						<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic'>
							<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
							Obligatorio
						</span>
						<FormProvider {...hookFormMethods} >
							<Form onSubmit={handleSubmit(onSubmitForm)} className='text-black-color mt-5'>
								<FormGroup as={Row}>
									<Form.Label htmlFor='asunto' className='text-right d-flex mandatory-label' column sm='4'>
										<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
										Tipo Pedido de Fondo:
									</Form.Label>
									<Col sm='4'>
										<Form.Control
											as="select"
											disabled
											name="fundRequestType"
											required >
											<option value={fundRequestEditSelected?.fundRequestType}>{getFundRequestTypeValueToShow(fundRequestEditSelected?.fundRequestType)}</option>
										</Form.Control>
									</Col>
								</FormGroup>
								<PDFormFactory formName={fundRequestEditSelected?.fundRequestType} />
										<Alert className='mb-0 text-center' variant='danger' show={hasFigurativeExpenditureSelected && disabledSaveWithoutFigurativeRegister}>
											{figurativeRegistrationMissing}
										</Alert>
								<Form.Group className='my-5 d-flex justify-content-around text-white-color'>
									<Button variant='primary' size='lg' onClick={onClickCancelButton}>
										Cancelar
									</Button>
									<Button type='submit' variant='success' size='lg' disabled={hasFigurativeExpenditureSelected && disabledSaveWithoutFigurativeRegister}>
										Guardar
										<FontAwesomeIcon className='ml-3' icon={faSave} />
									</Button>
								</Form.Group>
							</Form>
						</FormProvider>

					</Card.Body>
				</Card>
			</Container>
		</>
		<AppLoading isLoading={ isEditing } />
	</>
};

export default FundRequestsEditPage;