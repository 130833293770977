import { useSelector } from "react-redux";
import { getListAllServiceAdministrativeByUserData, getListServicesByUserAndExerciseData } from "src/redux/administrativeService/administrativeServiceReducer";

export const administrativeServiceClose = ( servicesData, serviceInPaymentOrder ) => {
    let listOfClosedServices = [];
    servicesData?.map(service => {service?.dateClose && listOfClosedServices.push(service?.administrativeService?.id)});        
    const validationOfClosedService = listOfClosedServices.some(service => service == serviceInPaymentOrder);
    return validationOfClosedService;
}

export const VALIDATE_ACTION_TYPE = {
    DELETE: 'DELETE',
    UPDATE: 'UPDATE'
};

export const AdministrativeServiceData = () => {
  return useSelector(state => getListAllServiceAdministrativeByUserData(state)
    ?.sort((a, b) => a.code > b.code ? 1 : -1)
    ?.map(item => ({
      displayName: `${item?.code} - ${item?.name} `,
      id: item?.id,
      name: item?.name,
      shortName: item?.shortName,
      code: item?.code,
      administrativeServiceOrganismCode: item?.administrativeServiceOrganismCode
    }))
  );
};

export const administrativeService = (service, administrativeServiceData) => {
  const foundService = administrativeServiceData.find(item => item?.id == service);
  return foundService ? `${foundService.code} - ${foundService.name}` : null;
};

export const AdministrativeServiceByUserAndExerciseData = () => {
  return useSelector(state => getListServicesByUserAndExerciseData(state)
    ?.sort((a, b) => a?.code > b?.code ? 1 : -1)
    ?.map(item => ({
      displayName: `${item?.code} - ${item?.name} `,
      id: item?.id,
      name: item?.name,
      shortName: item?.shortName,
      code: item?.code
    }))
  );
};