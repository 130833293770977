import * as ACTIONS from './alertActions';
import * as SERVICES from 'src/services/alertsServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showError, showSuccess } from 'src/redux/globalData/globalDataActionCreator';
import { push } from 'connected-react-router';
import { ALERT_LIST } from 'src/utils/constants';

// alert api system
export const tryGetAlertList = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getAlertListRequest() );

		if(accessToken){

			const response = await SERVICES.listAlert( accessToken, params );

			if( response?.status == 200 ) {
				dispatch( ACTIONS.getAlertListRequestSuccess(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getAlertListRequestFailure(response?.error || response) );
				showError(`Error al cargar los mensajes de alertas. Detalle: ${response?.response?.data?.message || response}` );
			}
		}

	};
};

//Add New Alert
export const tryAddNewAlert = (params) => {
	return async (dispatch, getState) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.addAlertRequest());
		
		if (accessToken) {

			const response = await SERVICES.createAlert(accessToken, params);
			
			if (response?.status == 201) {
				dispatch(ACTIONS.addAlertRequestSuccess(response?.data));
				showSuccess('Se agregó correctamente la alerta.');
				dispatch(push( ALERT_LIST ));
			}
			else {
				dispatch(ACTIONS.addAlertRequestFailure(response?.data?.error));
				showError(`Error al crear la alerta. Detalles: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};

//Delete Alert
export const tryDeleteAlert = (id) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.deleteAlertRequest());

		if (accessToken) {

			const response = await SERVICES.deleteAlert(accessToken, id);

			if (response?.status == 204) {
				dispatch(ACTIONS.deleteAlertRequestSuccess(response?.data));
				showSuccess('Alerta eliminada correctamente.');
			}
			else {
				dispatch(ACTIONS.deleteAlertRequestFailure(response?.error || response));
				showError(`Error al eliminar la alerta. Detalle: ${response?.data?.message || response}`);
			}
			return response;
		}

	};
};

//Edit Alert
export const tryEditAlert = captionData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editAlertRequest());

		if (accessToken) {

			const response = await SERVICES.editAlert(accessToken, captionData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editAlertRequestSuccess(response?.data));
				showSuccess('Alerta editada correctamente.');
				dispatch(push( ALERT_LIST ));
			}
			else {
				dispatch(ACTIONS.editAlertRequestFailure(response?.error || response));
				showError(`Error al editar la alerta. Detalle: ${response?.response?.data?.message || response?.error}`);
			}
			return response;
		}

	};

};

// Try Get caption by ID
export const tryGetAlertById = (captionId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAlertDetailRequest());

		if (accessToken) {

			const response = await SERVICES.getAlertById(accessToken, captionId);

			if (response?.status === 200) {
				dispatch(ACTIONS.getAlertDetailRequestSuccess(response?.data));
				return response;
			}
			else {
				dispatch(ACTIONS.getAlertDetailRequestFailure(response?.error || response));
				showError(`Error al cargar la alerta por ID. Detalle: ${response?.error || response}`);
			}
		}

	};
};

// alert api system
export const tryGetActiveAlert = (params) => {

	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getActiveAlertRequest() );

		if(accessToken){

			const response = await SERVICES.activeAlert( accessToken, params );

			if( response?.status == 200 ) {
				dispatch( ACTIONS.getActiveAlertRequestSuccess(response?.data) );
				dispatch( ACTIONS.setAlertActive(response?.data) );
				return response?.data?.data;
			}
			else {
				dispatch( ACTIONS.getActiveAlertRequestFailure(response?.error || response) );
				showError(`Error al cargar los mensajes de alertas activos. Detalle: ${response?.response?.data?.message || response}` );
			}
		}

	};
};