import * as ACTION_TYPES from './figurativeExpedituresTypes';


// GET Figurative expeditures list actions
export const getFigurativeExpedituresListRequest = () => ({
	type: ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_REQUEST
});
export const getFigurativeExpedituresListRequestSuccess = data => ({
	type: ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getFigurativeExpedituresListRequestFailure = error => ({
	type: ACTION_TYPES.GET_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearFigurativeExpedituresListData = () => ({
	type: ACTION_TYPES.CLEAR_FIGURATIVE_EXPEDITURES_REQUEST
})


// POST Figurative expeditures New actions
export const postFigurativeExpedituresRequest = () => ({
	type: ACTION_TYPES.POST_FIGURATIVE_EXPEDITURES_REQUEST
});
export const postFigurativeExpedituresRequestSuccess = data => ({
	type: ACTION_TYPES.POST_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const postFigurativeExpedituresRequestFailure = error => ({
	type: ACTION_TYPES.POST_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE,
	payload: {
		error
	}
});


// PUT Figurative expeditures New actions
export const putFigurativeExpedituresRequest = () => ({
	type: ACTION_TYPES.PUT_FIGURATIVE_EXPEDITURES_REQUEST
});
export const putFigurativeExpedituresRequestSuccess = data => ({
	type: ACTION_TYPES.PUT_FIGURATIVE_EXPEDITURES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const putFigurativeExpedituresRequestFailure = error => ({
	type: ACTION_TYPES.PUT_FIGURATIVE_EXPEDITURES_REQUEST_FAILURE,
	payload: {
		error
	}
});


// set figurative expeditures completeNumber
export const setFigurativeExpedituresCompleteNumber = data => ({
	type: ACTION_TYPES.SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER,
	payload: {
		data
	}
});
export const clearFigurativeExpedituresCompleteNumber = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_COMPLETE_NUMBER
});

export const setFigurativeExpedituresAperturaDescription = data => ({
	type: ACTION_TYPES.SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION,
	payload: {
		data
	}
});
export const clearFigurativeExpedituresAperturaDescription = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_APERTURA_DESCRIPTION
});

export const setFigurativeExpedituresVinculation = data => ({
	type: ACTION_TYPES.SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION,
	payload: {
		data
	}
});
export const deletedFigurativeExpedituresVinculation = data => ({
	type: ACTION_TYPES.DELETED_FIGURATIVE_EXPEDITURES_VINCULATION,
	payload: {
		data
	}
});
export const clearFigurativeExpedituresVinculation = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_FIGURATIVE_EXPEDITURES_VINCULATION
});

// set figurative expeditures to edit
export const setFigurativeExpedituresToEdit = data => ({
	type: ACTION_TYPES.SET_FIGURATIVE_EXPEDITURES_TO_EDIT,
	payload: {
		data
	}
});
export const clearFigurativeExpedituresToEdit = () => ({
	type: ACTION_TYPES.CLEAR_FIGURATIVE_EXPEDITURES_TO_EDIT
});