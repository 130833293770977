import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Table, Spinner, Alert, Form, Button } from 'react-bootstrap';
import { noExpedient, organismCode, correlative, business, year, expedients, actions, filterByYear, filterByCorrelative, filterByOrganismCode, filterByBusiness } from 'src/utils/label';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { faEdit, faExclamationTriangle, faBroom, faFilter} from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';
import ActionIcon from 'src/components/general/ActionIcon';
import PageTitle from 'src/components/general/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RCPagination from 'src/components/common/RCPagination';
import { getClearObject, getClearObjectAlt} from 'src/utils/utils';
import { setFilterObject} from 'src/redux/globalData/globalDataActions';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import { getExpedientListData, getExpedientListIsFetching } from 'src/redux/expedient/expedientReducer';
import { tryGetExpedientList } from 'src/redux/expedient/expedientActionCreator';
import { setExpedientToEdit } from 'src/redux/expedient/expedientActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { push } from 'connected-react-router';

import { EXPEDIENT_EDIT } from 'src/utils/constants';

const ExpedientListPage = () => {

	const dispatch = useDispatch();
	const { handleSubmit, register, reset, getValues, control } = useForm();

	const expedientListingData = useSelector(state => getExpedientListData(state));
	const expedientIsFetching = useSelector(state => getExpedientListIsFetching(state));
	const expedientListArrayRecords = expedientListingData?.data;
	const hasListExpedient = isNotEmptyArray(expedientListArrayRecords);

	const [hasMore, setHasMore] = useState(true);
	let [page, setPage] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [totalItems, setTotalItems] = useState(0);
	const [records, setRecords] = useState([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [filterObject, setFilterObject] = useState({});

	const expedientListPaginate = useSelector(state => getExpedientListData(state));

	const onPageChange = (pNumber) => {
		const params = {
			...defaultParams,
			...filterObject,
			page: pNumber
		}
		fetchList(params);
	}

	let newFilterObject;

	const defaultParams = {
		page: 0,
		pageSize: 10
	}

	const onSubmitForm = (data) => {
		cancelSource.cancel();
		cancelSource = CancelToken.source();
		newFilterObject = { 
			...defaultParams,           
			...data,
		};
		newFilterObject = getClearObject(newFilterObject);
		setFilterObject(newFilterObject);
		fetchList(newFilterObject);
	}

	const loadExpedients = () => {
		fetchList(defaultParams);
	};

	//Cancel Request
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();


	const fetchList = (params) => {
		setIsLoading(true);

		dispatch(tryGetExpedientList(params)).then((response) => {
			setIsLoading(false);
			setRecords(response?.data);
			let metadata = response?.data;
			setPageNumber(metadata?.page);
			setTotalCount(metadata?.pageCount);
			setTotalItems(metadata?.totalCount);
			setHasMore(metadata?.hasMore);
			setPerPage(Number(metadata?.perPage));
		});
	};

	// Edit expedient
	const onClickEdit = expedient => {
		dispatch(setExpedientToEdit(expedient));
		dispatch(push(EXPEDIENT_EDIT));
	};

	// useEffects
	useEffect(() => {
		loadExpedients()
	}, []);

	const clearFilters = () => {
		setFilterObject({})
		reset();
		loadExpedients();
		dispatch(push(ROUTES.EXPEDIENT_LIST))
	};

	const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		const maxValue = 9999;
		if (value <= maxValue)
			return inputObj;
	}

	return <>
			<Container fluid>
				<Card className='mb-3' >
					<Card.Header className="h6">
						Expedientes
					</Card.Header>
					<Card.Body>
						<div className='table-responsive'>
						<Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
							<Table striped bordered hover size='sm' >
								<thead>
									<tr>
										<th className='text-center' style={{ "width": "10%" }}>{organismCode}</th>
										<th className='text-center' style={{ "width": "10%" }}>{correlative}</th>
										<th className='text-center' style={{ "width": "10%" }}>{year}</th>
										<th className='text-center' style={{ "width": "60%" }}>{business}</th>
										<th className='text-center' style={{ "width": "10%" }}>{actions}</th>
									</tr>
									<tr>
										<th className='text-center' style={{ "width": "10%" }}>
											<Controller
												name='codeOrganism'
												control={control}
												defaultValue=""
												as={
													<NumericFormat
														decimalScale={0}
														inputMode='numeric'
														className="form-control form-control-sm"
														placeholder={filterByOrganismCode}
														title={filterByOrganismCode}
														allowNegative={false}
														allowLeadingZeros={false}
														isAllowed={(inputObj) => withValueLimit(inputObj)}
														ref={register}
														disabled={expedientIsFetching}
													/>
												}
											/>
										</th>
										<th className='text-center' style={{ "width": "10%" }}>
											<Controller
												name='number'
												control={control}
												defaultValue=""
												as={
													<NumericFormat
														decimalScale={0}
														inputMode='numeric'
														className="form-control form-control-sm"
														placeholder={filterByCorrelative}
														title={filterByCorrelative}
														allowNegative={false}
														allowLeadingZeros={false}
														maxLength={10}
														ref={register}
														disabled={expedientIsFetching}
													/>
												}
											/>
										</th>
										<th className='text-center' style={{ "width": "10%" }}>
											<Controller
												name='year'
												control={control}
												defaultValue=""
												as={
													<NumericFormat
														decimalScale={0}
														inputMode='numeric'
														className="form-control form-control-sm"
														placeholder={filterByYear}
														title={filterByYear}
														allowNegative={false}
														allowLeadingZeros={false}
														isAllowed={(inputObj) => withValueLimit(inputObj)}
														ref={register}
														disabled={expedientIsFetching}
													/>
												}
											/>
										</th>
										<th className='text-center' style={{ "width": "60%" }}>
											<Form.Control
												name="title"
												placeholder={filterByBusiness}
												title={filterByBusiness}
												className='text-center'
												size='sm'
												type='text'
												maxLength={255}
												ref={register}
												disabled={expedientIsFetching}
											/>
										</th>
										<th className='text-center align-middle custom-button'>
											{
												 expedientIsFetching 
												 ?
												 	<Spinner animation='border' variant='success' />
												 :
													<ActionIcon
														size="lg"
														id="search-button"
														className="text-white-color"
														toolTipText="Filtrar"
														icon={faFilter}
														type='submit'
														onSubmit={onSubmitForm}
													/>
											}
											{
												 expedientIsFetching 
												 ?
												 	<Spinner animation='border' variant='success' />
												 :
													<ActionIcon
														size="lg"
														id="clean-filter"
														className="text-white-color"
														toolTipText="Limpiar filtro"
														icon={faBroom}
														onClick={() => clearFilters()}
													/>
											}
										</th>
									</tr>
								</thead>
								<tbody className='text-black-color'>
									{
										hasListExpedient ?
											expedientListArrayRecords?.map(item =>
												<tr key={item?.id}>
													<td className='text-center'>
														{item?.organizationCode}
													</td>
													<td className='text-center'>
														{item?.number}
													</td>
													<td className='text-center'>
														{item?.year}
													</td>
													<td className='text-center'>
														{item?.title}
													</td>
													<td className='d-flex justify-content-around'>
														<div className='col-4'>
															{
																<ActionIcon
																	size='lg'
																	id='search'
																	toolTipText='Editar'
																	icon={faEdit}
																	onClick={() => onClickEdit(item)}
																/>
															}
														</div>
													</td>
												</tr>)
											:
											<tr>
												<td colSpan='10' className='text-center'>
													{
														expedientIsFetching ?
															<Spinner animation='border' variant='success' className='my-3' />
															:
															<Alert variant='info' className='mb-0'>
																<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
																{noExpedient}
															</Alert>
													}
												</td>
											</tr>
									}
								</tbody>
							</Table>
						</Form>
						</div>
						<RCPagination
							activePage={pageNumber}
							itemsCountPerPage={perPage}
							totalItemsCount={totalItems}
							totalItems={totalItems}
							thePage={pageNumber}
							onChange={onPageChange}
							innerClass="justify-content-center"
						/>

					</Card.Body>
				</Card>
			</Container>
		</>;
};

export default ExpedientListPage;