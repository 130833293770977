import React,{ useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { getAdministrativeDocumentYearsOptions } from 'src/utils/utils';
import { clearBankAccountListData } from 'src/redux/bankAccounts/bankAccountActions';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { getListAllServiceAdministrativeByUserData } from "src/redux/administrativeService/administrativeServiceReducer";
import { getSelectedFundRequestType } from 'src/redux/fundRequests/fundRequestsReducer';
import { tryBankAccountGetAll } from 'src/redux/bankAccounts/bankAccountActionCreator';
import { getBankAccountListData } from 'src/redux/bankAccounts/bankAccountReducer';
import { getOrderPayListIsFetching, getOrderPayListData } from 'src/redux/orderPay/orderPayReducer';
import { tryListOrderPay } from 'src/redux/orderPay/orderPayActionCreator';
import { clearListOrderPayData } from 'src/redux/orderPay/orderPayActions'
import PayOrderListBox from 'src/components/forms/fundRequest/payOrderListBox';
import PFCommons from 'src/components/forms/fundRequest/PDForms/controls/PFCommons';
import AppLoading from 'src/components/common/AppLoading';

const FundRequestGeneralTreasuryForm = () => {
	const dispatch = useDispatch();

	//Parent Form Controller
    const { admServiceId, setAdmServiceId } = useFormContext();

	//Data getter to send by props
	const dataRecords = useSelector(state => getOrderPayListData(state))?.records;
	const metaData = useSelector(state => getOrderPayListData(state))?.metadata;

	const selectedFundRequestType = useSelector(state => getSelectedFundRequestType(state));
	const checkToVisualize = selectedFundRequestType === 'PEDIDO_DE_FONDOS_DE_REINTEGRO_TESORERIA_GENERAL';

	const bankAccountListData = useSelector( state => getBankAccountListData (state) )?.records || [];
	const yearsOptions = getAdministrativeDocumentYearsOptions();
	const [bankAccountListDataFiltered, setBankAccountListDataFiltered] = useState([]);

	const isFetching = useSelector( state => getOrderPayListIsFetching(state) );

	const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
		}) );

	useEffect(() => {
		dispatch(clearListAllAdministrativeServicesData());
		dispatch(clearBankAccountListData());
		dispatch(tryBankAccountGetAll());
	}, [admServiceId]);

	const applyFilterBankAccounts = (administrativeServiceId) => {
		setAdmServiceId(administrativeServiceId);
		setBankAccountListDataFiltered(bankAccountListData.filter(item => item.administrativeServiceId == administrativeServiceId));
	};

	return <>
		<PFCommons
			yearsOptions={yearsOptions}
			administrativeServiceData={administrativeServiceData}
			applyFilterBankAccounts={applyFilterBankAccounts}
			bankAccountListDataFiltered={bankAccountListDataFiltered}
			admServiceId={admServiceId}
			checkToVisualize={checkToVisualize}
		/>

		<PayOrderListBox
			selectedFundRequestType={selectedFundRequestType}
			isFetching={isFetching}
			tryMethod={tryListOrderPay}
			clearMethod={clearListOrderPayData}
			metaData={metaData}
			dataRecords={dataRecords}
		/>

		<AppLoading isLoading={isFetching} />
	</>

};

export default FundRequestGeneralTreasuryForm;