import React, { useState } from 'react';
import { config } from 'src/env.js';
import { Container, Card } from 'react-bootstrap';
import AppLoading from 'src/components/common/AppLoading';

import PageTitle from 'src/components/general/PageTitle';

import StepIndicator from 'src/components/common/StepIndicator';

import { INCORPORATION_CREDIT_STEP_TWO } from 'src/utils/constants';
import { budgetCredits, newIncorporation } from 'src/utils/label';

import LegalInstrumentForm  from 'src/components/forms/subcode/LegalInstrumentForm';

const CreditIncorporationStepOnePage = () => {

	const nextPage = INCORPORATION_CREDIT_STEP_TWO;

	const [isLoading, setIsLoading] = useState(false);

	return <Container  fluid className='mb-5'>
		<Card>
			<PageTitle text={budgetCredits} />

			<Container fluid >

				<div>
					<StepIndicator steps={config.appSettings.steps.incorporation} current={0} className='mt-2 mb-3' />
				</div>

				<Card className='mb-3'>
					<Card.Header>
						<h6 className='mb-0'>
							{newIncorporation}
						</h6>
					</Card.Header>
					<Card.Body >
						<LegalInstrumentForm
							nextPage = {nextPage}
							isLoading = {isLoading}
							setIsLoading = {setIsLoading}
						/>
					</Card.Body>
				</Card>
			</Container>
		</Card>

		<AppLoading isLoading={isLoading} />

	</Container>

};

export default CreditIncorporationStepOnePage;