import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import PageTitle from 'src/components/general/PageTitle';
import { parseIntOrUndefined } from 'src/utils/utils';
import {clearCreateServiceAdministrative} from 'src/redux/administrativeService/administrativeServiceActions'
import { tryListAdministrativeserviceTypes, tryPostAdministrativeService } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getAdministrativeServiceTypesData, getAdministrativeServiceCreateIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';
import FormFieldError from 'src/components/general/FormFieldError';
import { SERVICE_ADMINISTRATIVE_LIST } from 'src/utils/constants';
import { abbreviation, code, description, administrativeServiceOrganismCode } from 'src/utils/label';
import ServiceAdminOrgCodeBox from 'src/components/forms/serviceAdministrative/ServiceAdminOrgCodeBox';

const ServiceAdministrativeNewPage = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit, errors, reset } = useForm();

    //ServiceCode
    const [serviceOrganismCodeSelected, setServiceOrganismCodeSelected] = useState([]);

    // Validations
    const codeRequiredMsg = 'Debe ingresar el código del servicio';
    const descriptionRequiredMsg = 'Debe ingresar una descripción';
    const abbreviationRequiredMsg = 'Debe ingresar una abreviatura';
    const administrativeServiceTypeRequiredMsg = 'Debe seleccionar un tipo de servicio';

    // Form data
    const codeValidationObj = { required: codeRequiredMsg };
    const descriptionValidationObj = { required: descriptionRequiredMsg };
    const abbreviationValidationObj = { required: abbreviationRequiredMsg };
    const administrativeServiceTypeRequiredObj = { required: administrativeServiceTypeRequiredMsg };

    const listTypeServiceData = useSelector(state => getAdministrativeServiceTypesData(state))?.records;
    const administrativeServiCreateIsFetching = useSelector(state => getAdministrativeServiceCreateIsFetching(state));

    // Test empty
    const [ descriptionEmpty, setDescriptionEmpty ] = useState(false);
    const [ shortNameEmpty, setshortNameEmpty ] = useState(false);

    const onChangeDescriptionInput = () => {
        setDescriptionEmpty(false);
    };
    
    const onChangeShortNameInput = () => {
        setshortNameEmpty(false);
    };

    //Disabled Botton Save
    const [ codeBlurEmpty, setCodeBlurEmpty ] = useState(false);
    const [ descriptionBlurEmpty, setDescriptionBlurEmpty ] = useState(false);
    const [ shortNameBlurEmpty, setShortNameBlurEmpty ] = useState(false);

    const onBlurCodeInput = data => {
        if(data){
          setCodeBlurEmpty(true)
        }else{
            setCodeBlurEmpty(false)
        }
      };

    const onBlurDescriptionInput = data => {
        if(data){
            setDescriptionBlurEmpty(true)
        }else{
            setDescriptionBlurEmpty(false)
        }
    };

    const onBlurShortNameInput = data => {
        if(data){
            setShortNameBlurEmpty(true)
          }else{
            setShortNameBlurEmpty(false)
          }
    };

    const allowSave = (codeBlurEmpty && descriptionBlurEmpty && shortNameBlurEmpty);

    const onFormSubmit = data => {
        if(allowSave && !administrativeServiCreateIsFetching){
            let code = parseIntOrUndefined(data?.code);
            let administrativeServiceOrganismCode = data?.administrativeServiceOrganismCode;
            let administrativeServiceType = parseIntOrUndefined(data?.administrativeServiceType);

            //Trim data
            let name = data?.name?.replace(/^\s+|\s+$/g,'');
            let shortName = data?.shortName?.replace(/^\s+|\s+$/g,'');

            if (name?.length === 0){
                setDescriptionEmpty(true)
                return 0;
            }

            if (shortName?.length === 0){
                setshortNameEmpty(true)
                return 0;
            }

            const paramsToSend = {
                administrativeServiceType: {
                    id: administrativeServiceType,
                },
                code,
                administrativeServiceOrganismCode,
                name,
                shortName,
                organismCodes:serviceOrganismCodeSelected
            }
            dispatch(tryPostAdministrativeService(paramsToSend)).then(response => {
                if (response?.status == 200) {
                    reset();
                    dispatch( push(SERVICE_ADMINISTRATIVE_LIST) )
                }

            })
        }
    };
    useEffect(() => {

        dispatch(clearCreateServiceAdministrative())
        dispatch(tryListAdministrativeserviceTypes());
    }, []);
    return <Container className='mb-5'>
        <Card>
            <PageTitle text='Servicios' />
            <Container fluid>
                <Card className='mb-3'>
                    <Card.Header>
                        <h6 className='mb-0'>
                            Nuevo servicio
						</h6>
                    </Card.Header>
                    <Card.Body >
                        <Row>
                            <Col xs={8}>
                                <h6 className='mb-4 text-black-color'>
                                    Datos del nuevo servicio
								</h6>
                            </Col>
                            <Col xs={4} className='text-right font-weight-bold font-italic'>
                                <span className='text-danger d-flex mandatory-label'>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Obligatorio
								</span>
                            </Col>
                        </Row>
                        <Form className='mt-4' onSubmit={handleSubmit(onFormSubmit)}>
                            <Form.Group as={Row} >
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Código:
								</Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register(codeValidationObj)} 
                                        type='number' 
                                        placeholder={code} 
                                        name='code' 
                                        id='code' 
                                        disabled={administrativeServiCreateIsFetching} 
                                        onBlur={event => onBlurCodeInput(event.target.value)}
                                        min='1'
                                        max='9999'
                                        required
                                    />
                                    <FormFieldError errors={errors?.code} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} >
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Descripción:
								</Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register(descriptionValidationObj)} 
                                        type='text' 
                                        placeholder={description} 
                                        name='name' 
                                        id='name' 
                                        disabled={administrativeServiCreateIsFetching}
                                        maxLength='255'
                                        onChange={onChangeDescriptionInput}
                                        onBlur={event => onBlurDescriptionInput(event.target.value)}
                                        required
                                    />
                                    <FormFieldError errors={errors?.name} />
                                    {
										descriptionEmpty
										?
											<>
											<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
											{'El campo no debe estar vacío.'}
											</div>
											</>
										:
										null
									}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} >
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                                    {abbreviation}:
                                </Form.Label>
                                <Col sm={4}>
                                    <Form.Control 
                                        ref={register(abbreviationValidationObj)} 
                                        type='text' 
                                        placeholder={abbreviation} 
                                        name='shortName' 
                                        id='shortName'
                                        maxLength='255'
                                        onChange={onChangeShortNameInput}
                                        onBlur={event => onBlurShortNameInput(event.target.value)}
                                        required 
                                    />
                                    <FormFieldError errors={errors?.shortName} />
                                    {
										shortNameEmpty && !errors?.shortName
										?
											<>
											<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
											{'El campo no debe estar vacío.'}
											</div>
											</>
										:
										null
									}
                                </Col>
                            </Form.Group>--
                            <Form.Group as={Row}>
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									Tipo de Servicio:
								</Form.Label>
                                <Col sm='4'>
                                    <Form.Control 
                                        as='select' 
                                        name='administrativeServiceType' 
                                        ref={register(administrativeServiceTypeRequiredObj)}
                                        >
                                        <option className='text-black-color' value={ '' } >
                                            Seleccionar Tipo de Servicio...
                                        </option>)
                                        {
                                            listTypeServiceData?.map(item =>
                                                <option key={item?.id} className='text-black-color' value={item?.id} >
                                                    {`${item?.name || ''}`}
                                                </option>)
                                        }
                                    </Form.Control>
                                    <FormFieldError errors={errors?.administrativeServiceType} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} >
                                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
									Código de Expediente:
								</Form.Label>
                                <Col sm={4}>
                                    <Form.Control
                                        ref={register}
                                        type='number' 
                                        placeholder={administrativeServiceOrganismCode} 
                                        name='administrativeServiceOrganismCode' 
                                        id='administrativeServiceOrganismCode' 
                                        disabled={administrativeServiCreateIsFetching} 
                                        min='1000'
                                        max='9999'
                                    />
                                    <FormFieldError errors={errors?.administrativeServiceOrganismCode} />
                                </Col>
                            </Form.Group>
                                <ServiceAdminOrgCodeBox 
                                    serviceOrganismCodeSelected={serviceOrganismCodeSelected}
                                    setServiceOrganismCodeSelected={setServiceOrganismCodeSelected}
                                />
                            <div className='d-flex justify-content-around mt-4 mb-3'>
                                <Button type='button' variant='danger' size='lg' disabled={false} onClick={() => dispatch(push(SERVICE_ADMINISTRATIVE_LIST))}>
                                    Cancelar
								</Button>
                                <span className={administrativeServiCreateIsFetching ? '' : 'hidden'}>
                                    <Spinner animation='border' />
                                </span>
                                <Button
                                    type='submit'
                                    variant='success'
                                    size='lg'
                                    disabled={!allowSave || administrativeServiCreateIsFetching}>
                                    Guardar
								<FontAwesomeIcon icon={faSave} className='ml-2' />
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    </Container>
};
export default ServiceAdministrativeNewPage;