import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Spinner, Alert, Form } from 'react-bootstrap';
import { maintenanceSystem, alert, questionMaintenanceSystem, activatedWord, disabledWord, controlPanel, notice, cautionSystemMaintenanceOnOff, messageSystemMaintenanceOff, messageSystemMaintenanceOn } from 'src/utils/label';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { tryPostMaintenanceSystem } from 'src/redux/maintenanceSystem/maintenanceSystemActionCreator';
import { postMaintenanceSystemIsFetching } from 'src/redux/maintenanceSystem/maintenanceSystemReducer';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { getProfileName } from 'src/redux/login/loginReducer';

const MaintenanceSystemListPage = () => {

	const dispatch = useDispatch();

	//Profile User
	const profileName = useSelector(state => getProfileName(state));

	const maintenanceSystemIsFetching = useSelector(state => postMaintenanceSystemIsFetching(state));

	const [checkMaintenanceSystem, setCheckMaintenanceSystem] = useState();

	const getStatusMaintenanceSystem = () => {
		dispatch(getStatusApi(profileName)).then(
			response => {
				setCheckMaintenanceSystem(response);
			}
		);
	};

	// Message Alert
	const messageBoxModal = (messageAlert, statusMaintenanceSystem) => swal({
		title: alert,
		text: '¿' + questionMaintenanceSystem + messageAlert + '?',
		icon: 'warning',
		buttons: ["Cancelar", "Aceptar"],
		dangerMode: true,
	})
		.then((willDelete) => {
			if (willDelete) {
				dispatch(tryPostMaintenanceSystem(statusMaintenanceSystem)).then().finally(() => getStatusMaintenanceSystem());
			}
		});

	const onClickSwitch = statusMaintenanceSystem => {
		const messageAlert = statusMaintenanceSystem ? activatedWord : disabledWord;
		if (statusMaintenanceSystem) {
			messageBoxModal(messageAlert, false);
		} else {
			messageBoxModal(messageAlert, true);
		}
	};

	// Card Alert
	const messageCard = (typeAlert, messageValue) => <Alert variant={typeAlert} className='mb-0'>
		<b className='text-black-color'>{notice}:</b> {messageValue}
	</Alert>;

	// useEffects
	useEffect(() => {
		getStatusMaintenanceSystem();
	}, []);

	return <Container fluid className='mb-5'>
		<Card>
			<PageTitle text={maintenanceSystem} />
			<Container fluid className='mb-3'>
				<Card className='h-100 justify-content-center align-items-center' >
					<Card.Body>
						{
							(checkMaintenanceSystem == undefined || maintenanceSystemIsFetching)
								?
								<Spinner animation='border' variant='success' />
								:
								<Card className="text-center">
									<Card.Header>
										{controlPanel}
									</Card.Header>
									<Card.Body>
										<Card.Title className='text-black-color'><FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />{cautionSystemMaintenanceOnOff}</Card.Title>
										{
											checkMaintenanceSystem
												?
												messageCard('info', messageSystemMaintenanceOff)
												:
												messageCard('warning', messageSystemMaintenanceOn)
										}
									</Card.Body>
									<Card.Footer className="text-black-color">
										<Form.Check
											type="switch"
											id="maintenanceSystem"
											label={!checkMaintenanceSystem ? activatedWord : disabledWord}
											onChange={() => onClickSwitch(checkMaintenanceSystem)}
											checked={!checkMaintenanceSystem}
										/>
									</Card.Footer>
								</Card>
						}
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default MaintenanceSystemListPage;