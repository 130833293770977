import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { getAuditAttributeName } from 'src/utils/utils';
import { questionDeleteAdministrativeService } from "src/utils/label";

import swal from "sweetalert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTrash, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Table, Spinner, Alert} from 'react-bootstrap';
import ActionIcon from 'src/components/general/ActionIcon';
import 'react-widgets/styles.css';
import { tryGetReportBudgetStatusCreditExecutionGenerated, tryGetReportDelete } from 'src/redux/reports/reportsActionCreactor'

const GeneratedReportsTable = (props) => {

    const dispatch = useDispatch();

    const { subcodeDetailData, hasSubcodeDetailData, isFeching, reportIsFeching, loadData } = props;

    const [itemDelete, setItemDelete] = useState("");

    const generateReport = value => {
        setItemDelete(value)
        dispatch(tryGetReportBudgetStatusCreditExecutionGenerated({ fileName: value?.fileName }));
    };

    const setExtension = (value) => {
        const header = value.fileName;
        if (header.includes(".xlsx")) {
            return faFileExcel;
        }
        if (header.includes(".pdf")) {
            return faFilePdf;
        }
    }

    const deleteReport = (value) => {
        setItemDelete(value)
        let name = [value?.reportName];

        swal({
            title: "Confirmación",
            text:
                "¿" +
                questionDeleteAdministrativeService +
                name +
                "?",
            icon: "warning",
            buttons: ["Cancelar", "Aceptar"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(tryGetReportDelete(value?.id)).then( (response) => {
                    if(response?.status == 200){
                        loadData();
                    };
                });
            };
        });
    };

    return (
        <Table striped bordered hover size='sm'>
            <thead>
                <tr>
                    <th className='text-center'>Nombre del Reporte</th>
                    <th className='text-center'>Fecha y Hora</th>
                    <th className='text-center'>Estado</th>
                    <th className='text-center w15'>Adjunto</th>
                </tr>
            </thead>
            <tbody className='text-black-color'>
                {
                    hasSubcodeDetailData
                        ?
                        <>
                            {
                                subcodeDetailData?.map((item) => {
                                    return <>
                                        <tr>
                                            <td className='text-center p-2' >{item?.reportName}</td>
                                            <td className='text-center p-2' >{(item?.status == 'pending' || item?.status == 'processing') ? item?.generatedAt : item?.finishedAt}</td>
                                            <td className='text-center p-2' >{getAuditAttributeName(item?.status)}</td>
                                            <td className='text-center'>
                                                {reportIsFeching && item == itemDelete ?
                                                    <Spinner animation='border' size='sm' />
                                                    :
                                                    getAuditAttributeName(item?.status) == 'Procesando'
                                                        ?
                                                        <Spinner animation='border' size='sm' />
                                                        :
                                                        null

                                                }
                                                {
                                                    getAuditAttributeName(item?.status) == 'Generado' && !reportIsFeching
                                                        ?
                                                        <ActionIcon
                                                            size='lg'
                                                            id='download'
                                                            toolTipText='Descargar reporte'
                                                            icon={setExtension(item)}
                                                            onClick={() => generateReport(item)}
                                                        />
                                                        :
                                                        null
                                                }

                                                {
                                                    !reportIsFeching && getAuditAttributeName(item?.status) == 'Generado' || getAuditAttributeName(item?.status) == 'Error'
                                                        ?
                                                        <ActionIcon
                                                            size='lg'
                                                            id='delete'
                                                            toolTipText='Eliminar reporte'
                                                            icon={faTrash}
                                                            disabled={reportIsFeching}
                                                            onClick={() => deleteReport(item)}
                                                        />
                                                        :
                                                        null
                                                }
                                            </td>
                                        </tr>
                                    </>
                                })
                            }
                        </>
                        : <tr>
                            <td colSpan='4' className='text-center'>
                                {
                                    isFeching
                                        ?
                                        <Spinner animation='border' size='sm' />
                                        :
                                        <Alert variant='info' className='mb-0'>
                                            <FontAwesomeIcon icon={''} className='text-black-color mr-3' />
                                            No hay reportes generados
                                        </Alert>
                                }
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )

};

export default GeneratedReportsTable;