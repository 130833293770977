import * as ACTION_TYPES from './administrativeServiceActionTypes';

// List administrative services actions
export const listAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICES_REQUEST
});
export const listAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListAdministrativeServicesData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ADMINISTRATIVE_SERVICES_DATA
});
// END List administrative services actions

// List all administrative services actions
export const listAllAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST
});
export const listAllAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listAllAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListAllAdministrativeServicesData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ALL_ADMINISTRATIVE_SERVICES_DATA
});
// END List all administrative services actions


// List administrative service type actions
export const listAdministrativeServiceTypeRequest = () => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST
});
export const listAdministrativeServiceTypeRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listAdministrativeServiceTypeRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_SERVICE_TYPES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListAdministrativeServiceTypeData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ADMINISTRATIVE_SERVICE_TYPES_DATA
});
// END List administrative service type actions



export const setAdministrativeServicesDetailData = data => ({
	type: ACTION_TYPES.SET_DETAIL_ADMINISTRATIVE_SERVICES_DATA,
	payload: {
		data
	}
});



// Add Administrative Use 
export const getAddServiceAdministrativeRequest = () => ({
	type: ACTION_TYPES.CREATE_ADMINISTRATIVE_SERVICE_REQUEST
});

export const getAddServiceAdministrativeRequestSuccess = (data) => ({
	type: ACTION_TYPES.CREATE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAddServiceAdministrativeRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateServiceAdministrative = () => ({
	type: ACTION_TYPES.CLEAR_CREATE_ADMINISTRATIVE_SERVICE
})
// END Add Administrative Use

// Edit Administrative Use 
export const getEditServiceAdministrativeRequest = () => ({
	type: ACTION_TYPES.EDIT_ADMINISTRATIVE_SERVICE_REQUEST
});

export const getEditServiceAdministrativeRequestSucces = (data) => ({
	type: ACTION_TYPES.EDIT_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getEditServiceAdministrativeRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setAdministrativeServiceToEdit = data => ({
	type: ACTION_TYPES.SET_ADMINISTRATIVE_SERVICE_TO_EDIT,
	payload: {
		data
	}
});

export const clearEditServiceAdministrative = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_ADMINISTRATIVE_SERVICE
})
// END Edit Administrative Use

// Delete Administrative Use 
export const getDeleteServiceAdministrativeRequest = () => ({
	type: ACTION_TYPES.DELETE_ADMINISTRATIVE_SERVICE_REQUEST
});

export const getDeleteServiceAdministrativeRequestSuccess = (data) => ({
	type: ACTION_TYPES.DELETE_ADMINISTRATIVE_SERVICE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getDeleteServiceAdministrativeRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_ADMINISTRATIVE_SERVICE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearDeleteServiceAdministrative = () => ({
	type: ACTION_TYPES.CLEAR_DELETE_ADMINISTRATIVE_SERVICE
})
// END Edit Administrative Use



// Set Organism administrative services actions
export const getOrganismAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST
});
export const getOrganismAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getOrganismAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setAdministrativeServicesOrganismData = data => ({
	type: ACTION_TYPES.SET_ORGANISM_ADMINISTRATIVE_SERVICES_DATA,
	payload: {
		data
	}
});
// END Organism administrative services actions

// List Organism administrative services actions
export const listOrganismAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST
});
export const listOrganismAdministrativeServicesRequesttSuccess = data => ({
	type: ACTION_TYPES.LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listOrganismAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearlistOrganismAdministrativeServicesData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ORGANISM_ADMINISTRATIVE_SERVICES_DATA
});
// END List Organism administrative services actions

// Add Organism administrative services actions
export const addOrganismAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST
});
export const addOrganismAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const addOrganismAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.ADD_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Add Organism administrative services actions

// Remove Organism administrative services actions
export const removeOrganismAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST
});
export const removeOrganismAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const removeOrganismAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.REMOVE_ORGANISM_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Remove Organism administrative services actions


// Set Users administrative services actions
export const getUsersAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.USERS_ADMINISTRATIVE_SERVICES_REQUEST
});
export const getUsersAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getUsersAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setAdministrativeServicesUsersData = data => ({
	type: ACTION_TYPES.SET_USERS_ADMINISTRATIVE_SERVICES_DATA,
	payload: {
		data
	}
});
export const clearAdministrativeServicesUsers = () => ({
	type: ACTION_TYPES.CLEAR_USERS_ADMINISTRATIVE_SERVICES_DATA
})
// END Users administrative services actions


// Add Users administrative services actions
export const addUsersAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST
});
export const addUsersAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const addUsersAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.ADD_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Add Users administrative services actions

// Remove Users administrative services actions
export const removeUsersAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST
});
export const removeUsersAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const removeUsersAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.REMOVE_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Remove Users administrative services actions

// Add all User Administrative Service
export const getAddAllUsersServiceAdministrativeRequest = () => ({
	type: ACTION_TYPES.ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST
});

export const getAddAllUsersServiceAdministrativeRequestSuccess = (data) => ({
	type: ACTION_TYPES.ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAddAllUsersServiceAdministrativeRequestFailure = error => ({
	type: ACTION_TYPES.ADD_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END  Add all User Administrative Service

// Remove all User Administrative Service
export const getRemoveAllUsersServiceAdministrativeRequest = () => ({
	type: ACTION_TYPES.REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST
});

export const getRemoveAllUsersServiceAdministrativeRequestSuccess = (data) => ({
	type: ACTION_TYPES.REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getRemoveAllUsersServiceAdministrativeRequestFailure = error => ({
	type: ACTION_TYPES.REMOVE_ALL_USERS_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Remove all User Administrative Service

// List all administrative services by User 
export const getListAllServiceAdministrativeByUserRequest = () => ({
	type: ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST
});

export const getListAllServiceAdministrativeByUserRequestSuccess = (data) => ({
	type: ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getListAllServiceAdministrativeByUserRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ALL_ADMINISTRATIVE_SERVICES_BY_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const getListServicesByUserAndExerciseRequest = () => ({
	type: ACTION_TYPES.LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST
});
export const getListServicesByUserAndExerciseRequestSuccess = (data) => ({
	type: ACTION_TYPES.LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getListServicesByUserAndExerciseRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SERVICES_BY_USER_AND_EXERCISE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setIdListServiceAdministrativeByUser = (data) => ({
	type: ACTION_TYPES.SET_LIST_ADMINISTRATIVE_SERVICES_BY_USER,
	payload: {
		data
	}
});

export const clearListAllServiceAdministrativeByUser = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ALL_SERVICE_ADMINISTRATIVE_BY_USER
});
// END  List all administrative services by User 

// Add New Users administrative services actions
export const addNewUsersAdministrativeServicesRequest = () => ({
	type: ACTION_TYPES.ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST
});
export const addNewUsersAdministrativeServicesRequestSuccess = data => ({
	type: ACTION_TYPES.ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const addNewUsersAdministrativeServicesRequestFailure = error => ({
	type: ACTION_TYPES.ADD_NEW_USER_ADMINISTRATIVE_SERVICES_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Add New Users administrative services actions

// Search service administrative by id
export const getServiceAdministrativeByIdRequest = () => ({
	type: ACTION_TYPES.SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_REQUEST
});

export const getServiceAdministrativeByIdRequestSuccess = (data) => ({
	type: ACTION_TYPES.SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_SUCCESS,
	payload: {
		data
	}
});

export const getServiceAdministrativeByIdRequestFailure = error => ({
	type: ACTION_TYPES.SEARCH_SERVICE_ADMINISTRATIVE_BY_ID_FAILURE,
	payload: {
		error
	}
});

// END serch service administrative by id

// Get Active administrative service from exercise and user
export const getActiveAdminsitrativeServiceFromExerciseAndUserRequest = () => ({
	type: ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST
});
export const getActiveAdminsitrativeServiceFromExerciseAndUserRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getActiveAdminsitrativeServiceFromExerciseAndUserRequestFailure = error => ({
	type: ACTION_TYPES.GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearGetActiveAdminsitrativeServiceFromExerciseData = () => ({
	type: ACTION_TYPES.CLEAR_GET_ACTIVE_ADMINISTRATIVE_SERVICE_FROM_EXERCISE_AND_USER_DATA
});
// END Get Active administrative service from exercise and user