import * as ACTION_TYPES from './subcodeActionTypes';


// Subcode process in progress
export const setSubcodeProcessInProgress = value => ({
	type: ACTION_TYPES.SET_SUBCODE_PROCESS_IN_PROGRESS,
	payload: {
		value
	}
});
// END Subcode process in progress


// Subcode list actions
export const getSubcodeListRequest = () => ({
	type: ACTION_TYPES.LIST_SUBCODE_REQUEST
});
export const getSubcodeListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_SUBCODE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getSubcodeListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_SUBCODE_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearSubcodeListData = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_LIST_DATA
})
// END Subcode list actions



// Subcode to edit actions
export const setSubcodeToEdit = data => ({
	type: ACTION_TYPES.SET_SUBCODE_TO_EDIT,
	payload: {
		data
	}
});
export const clearSubcodeToEdit = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_TO_EDIT
});
// END Subcode to edit actions



// Subcode details page data action
export const setSubcodeDetailsPageData = data => ({
	type: ACTION_TYPES.SET_SUBCODE_DETAILS_PAGE_DATA,
	payload: {
		data
	}
});
export const clearSubcodeDetailsPageData = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_DETAILS_PAGE_DATA
});
// END Subcode details page data action



// Selected legal instrument actions
export const setSelectedLegalInstrument = data => ({
	type: ACTION_TYPES.SET_SELECTED_LEGAL_INSTRUMENT,
	payload: {
		data
	}
});
export const clearSelectedLegalInstrument = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_LEGAL_INSTRUMENT
});
// END Selected legal instrument actions



// Subcode details actions
export const getSubcodeDetailsRequest = subcodeId => ({
	type: ACTION_TYPES.GET_SUBCODE_DETAILS_REQUEST,
	payload: {
		subcodeId
	}
});

export const getSubcodeDetailsRequestSuccess = data => ({
	type: ACTION_TYPES.GET_SUBCODE_DETAILS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getSubcodeDetailsRequestFailure = error => ({
	type: ACTION_TYPES.GET_SUBCODE_DETAILS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearSubcodeDetails = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_DETAILS
});
// END Subcode details actions



// Subcode data actions
export const getSubcodeDataRequest = subcodeId => ({
	type: ACTION_TYPES.GET_SUBCODE_DATA_REQUEST,
	payload: {
		subcodeId
	}
});

export const getSubcodeDataRequestSuccess = data => ({
	type: ACTION_TYPES.GET_SUBCODE_DATA_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getSubcodeDataRequestFailure = error => ({
	type: ACTION_TYPES.GET_SUBCODE_DATA_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearSubcodeData = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_DATA
});
// END Subcode data actions



// Subcode post request actions
export const postSubcodeRequest = data => ({
	type: ACTION_TYPES.POST_SUBCODE_REQUEST,
	payload: {
		data
	}
});
export const postSubcodeRequestSuccess = response => ({
	type: ACTION_TYPES.POST_SUBCODE_REQUEST_SUCCESS,
	payload: {
		response
	}
});
export const postSubcodeRequestFailure = error => ({
	type: ACTION_TYPES.POST_SUBCODE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Subcode post request actions

// Subcode get file request actions
export const getFileSubcodeRequest = subcodeData => ({
	type: ACTION_TYPES.GET_FILE_SUBCODE_REQUEST,
	payload: {
		subcodeData
	}
});
export const getFileSubcodeRequestSuccess = data => ({
	type: ACTION_TYPES.GET_FILE_SUBCODE_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getFileSubcodeRequestFailure = error => ({
	type: ACTION_TYPES.GET_FILE_SUBCODE_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Subcode get file request actions


// Subcode affectations actions
export const getSubcodeAffectationsRequest = subcodeId => ({
	type: ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_REQUEST,
	payload: {
		subcodeId
	}
});

export const getSubcodeAffectationsRequestSuccess = data => ({
	type: ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getSubcodeAffectationsRequestFailure = error => ({
	type: ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearSubcodeAffectations = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_AFFECTATIONS
});
// END Subcode affectations actions

// Subcode affectations history actions
export const getSubcodeAffectationsHistoryRequest = subcodeId => ({
	type: ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST,
	payload: {
		subcodeId
	}
});

export const getSubcodeAffectationsHistoryRequestSuccess = data => ({
	type: ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getSubcodeAffectationsHistoryRequestFailure = error => ({
	type: ACTION_TYPES.GET_SUBCODE_AFFECTATIONS_HISTORY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearSubcodeAffectationsHistory = () => ({
	type: ACTION_TYPES.CLEAR_SUBCODE_AFFECTATIONS_HISTORY
});
// END Subcode affectations history actions

// Add compensation actions
export const postCompensationRequest = params => ({
    type: ACTION_TYPES.POST_COMPENSATION_REQUEST,
    payload: {
        params
    }
});
export const postCompensationRequestSuccess = data => ({
    type: ACTION_TYPES.POST_COMPENSATION_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const postCompensationRequestFailure = error => ({
    type: ACTION_TYPES.POST_COMPENSATION_REQUEST_FAILURE,
    payload: {
        error
    }
});

//Prelaod

export const setPreloadState = preload => ({
	type: ACTION_TYPES.SET_PRELOAD_STATE,
	payload: {
		preload
	}
});

export const clearPreloadState = () => ({
	type: ACTION_TYPES.CLEAR_PRELOAD_STATE
})