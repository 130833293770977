export const LIST_ALERT_REQUEST = 'LIST_ALERT_REQUEST';
export const LIST_ALERT_REQUEST_SUCCESS = 'LIST_ALERT_REQUEST_SUCCESS';
export const LIST_ALERT_REQUEST_FAILURE = 'LIST_ALERT_REQUEST_FAILURE';

export const CLEAR_ALERT_LIST = 'CLEAR_ALERT_LIST';

// Alert Detail
export const DETAIL_ALERT_REQUEST = 'DETAIL_ALERT_REQUEST';
export const DETAIL_ALERT_REQUEST_SUCCESS = 'DETAIL_ALERT_REQUEST_SUCCESS';
export const DETAIL_ALERT_REQUEST_FAILURE = 'DETAIL_ALERT_REQUEST_FAILURE';
export const SET_ALERT = 'SET_ALERT';

//Alert New
export const NEW_ALERT_REQUEST = 'NEW_ALERT_REQUEST';
export const NEW_ALERT_REQUEST_SUCCESS = 'NEW_ALERT_REQUEST_SUCCESS';
export const NEW_ALERT_REQUEST_FAILURE = 'NEW_ALERT_REQUEST_FAILURE';

//Alert Edit
export const EDIT_ALERT_REQUEST = 'EDIT_ALERT_REQUEST';
export const EDIT_ALERT_REQUEST_SUCCESS = 'EDIT_ALERT_REQUEST_SUCCESS';
export const EDIT_ALERT_REQUEST_FAILURE = 'EDIT_ALERT_REQUEST_FAILURE';
export const SET_ALERT_TO_EDIT = 'SET_ALERT_TO_EDIT';

//Alert delete
export const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST';
export const DELETE_ALERT_REQUEST_SUCCESS = 'DELETE_ALERT_REQUEST_SUCCESS';
export const DELETE_ALERT_REQUEST_FAILURE = 'DELETE_ALERT_REQUEST_FAILURE';

//Alert Active
export const ACTIVE_ALERT_REQUEST = 'ACTIVE_ALERT_REQUEST';
export const ACTIVE_ALERT_REQUEST_SUCCESS = 'ACTIVE_ALERT_REQUEST_SUCCESS';
export const ACTIVE_ALERT_REQUEST_FAILURE = 'ACTIVE_ALERT_REQUEST_FAILURE';

export const SET_ALERT_ACTIVE = 'SET_ALERT_ACTIVE';
