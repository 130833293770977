import * as ACTION_TYPES from './functionalityActionTypes';

// Functionalities list by purposeId actions
export const getFunctionalitiesListByPurposeIdRequest = purposeId => ({
	type: ACTION_TYPES.LIST_FUNCTIONALITIES_BY_PURPOSE_ID_REQUEST,
	payload: {
		purposeId
	}
});

export const getFunctionalitiesListByPurposeIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_FUNCTIONALITIES_BY_PURPOSE_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getFunctionalitiesListByPurposeIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_FUNCTIONALITIES_BY_PURPOSE_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearFunctionalitiesListByPurposeId = () => ({
	type: ACTION_TYPES.CLEAR_FUNCTIONALITIES_LIST_BY_PURPOSE_ID
});
// END Functionalities list by purposeId actions