import * as ACTION_TYPES from './provisionOrderActionTypes';

export const provisionOrderReducerInitialState = {
	ProvisionOrderList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	provisionOrderData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
    },
	ProvisionOrderDetail: {},
	ProvisionOrderNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
    },
	provisionOrderToEdit: {},
	provisionOrderEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
    },
	ProvisionOrderDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
    },
	acquisitionTypesList:
	{
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	ProvisionOrderToClone: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const provisionOrderReducer = (state = provisionOrderReducer, action) => {
	switch (action.type) {

		// GET Provision Order
		case ACTION_TYPES.GET_PROVISION_ORDER_REQUEST: {
			return {
				...state,
				ProvisionOrderList: {
					...state.ProvisionOrderList,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_PROVISION_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			const previousRecords = state.ProvisionOrderList.data?.data;
			if( previousRecords ) {
				data.data = previousRecords.concat(data.data);
			}
			return {
				...state,
				ProvisionOrderList: {
					...state.ProvisionOrderList,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_PROVISION_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				ProvisionOrderList: {
					...state.ProvisionOrderList,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_PROVISION_ORDER_REQUEST: {
			return {
				...state,
				ProvisionOrderList: {
					...provisionOrderReducerInitialState.ProvisionOrderList
				}
			};
		}
		// END 

		// GET Acquisition types list
		case ACTION_TYPES.GET_ACQUISITION_TYPES_LIST_REQUEST: {
			return {
				...state,
				acquisitionTypesList: {
					...state.acquisitionTypesList,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_ACQUISITION_TYPES_LIST_REQUEST_SUCCESS: {
			const { data } = action.payload;
			const previousRecords = state.acquisitionTypesList.data?.data;
			if( previousRecords ) {
				data.data = previousRecords.concat(data.data);
			}
			return {
				...state,
				acquisitionTypesList: {
					...state.acquisitionTypesList,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_ACQUISITION_TYPES_LIST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				acquisitionTypesList: {
					...state.acquisitionTypesList,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_ACQUISITION_TYPES_LIST: {
			return {
				...state,
				acquisitionTypesList: {
					...provisionOrderReducerInitialState.acquisitionTypesList
				}
			};
		}
		// END 

		// EDIT Provision Order
		case ACTION_TYPES.EDIT_PROVISION_ORDER_REQUEST: {
			return {
				...state,
				provisionOrderEdit: {
					...state.provisionOrderEdit,
					isFetching: true,
					hasError: false,
					error: provisionOrderReducerInitialState.provisionOrderEdit.error
				}
			};
		}
		case ACTION_TYPES.EDIT_PROVISION_ORDER_REQUEST_SUCCESS: {
			return {
				...state,
				provisionOrderEdit: {
					...state.provisionOrderEdit,
					isFetching: false,
					hasError: false,
					error: provisionOrderReducerInitialState.provisionOrderEdit.error
				}
			};
		}
		case ACTION_TYPES.EDIT_PROVISION_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				provisionOrderEdit: {
					...state.provisionOrderEdit,
					isFetching: false,
					hasError: true,
					data: provisionOrderReducerInitialState.provisionOrderEdit.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_EDIT_PROVISION_ORDER: {

			return {
				...state,
				provisionOrderEdit: provisionOrderReducerInitialState.provisionOrderEdit
			}
		}
		// END
		
		// POST Provision Order
		case ACTION_TYPES.POST_PROVISION_ORDER_REQUEST: {
			return {
				...state,
				ProvisionOrderNew: {
					...state.ProvisionOrderNew,
					isFetching: true,
					hasError: false,
					error: provisionOrderReducerInitialState.ProvisionOrderNew.error
				}
			};
		}
		case ACTION_TYPES.POST_PROVISION_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				ProvisionOrderNew: {
					...state.ProvisionOrderNew,
					isFetching: false,
					hasError: false,
					error: provisionOrderReducerInitialState.ProvisionOrderNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.POST_PROVISION_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				ProvisionOrderNew: {
					...state.ProvisionOrderNew,
					isFetching: false,
					hasError: true,
					data: provisionOrderReducerInitialState.ProvisionOrderNew.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_PROVISION_ORDER_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				provisionOrderEdit: data
			}
		}
		//END

		// DETAIL Provision Order
		case ACTION_TYPES.SET_PROVISION_ORDER_DETAIL: {
			const { data } = action.payload;
			return {
				...state,
				ProvisionOrderDetail: data
			}
		}
		case ACTION_TYPES.CLEAR_PROVISION_ORDER_DETAIL: {
			const { data } = action.payload;
			return {
				...state,
				ProvisionOrderDetail: {}
			}
		}
		// END

		// DELETE Provision Order
		case ACTION_TYPES.DELETE_PROVISION_ORDER_REQUEST: {
			return {
				...state,
				ProvisionOrderDelete: {
					...state.ProvisionOrderDelete,
					isFetching: true,
					hasError: false,
					error: provisionOrderReducerInitialState.ProvisionOrderDelete.error,
					data: provisionOrderReducerInitialState.ProvisionOrderDelete.data
				}
			};
		}
		case ACTION_TYPES.DELETE_PROVISION_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				ProvisionOrderDelete: {
					...state.ProvisionOrderDelete,
					isFetching: false,
					hasError: false,
					error: provisionOrderReducerInitialState.ProvisionOrderDelete.error,
					data
				}
			};
		}
		case ACTION_TYPES.DELETE_PROVISION_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				ProvisionOrderDelete: {
					...state.ProvisionOrderDelete,
					isFetching: false,
					hasError: true,
					data: provisionOrderReducerInitialState.ProvisionOrderDelete.data,
					error
				}
			};
		}
		// END

		// GET Provision Orderto clone by id
		case ACTION_TYPES.GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST: {
			return {
				...state,
				ProvisionOrderToClone: {
					...state.ProvisionOrderToClone,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				ProvisionOrderToClone: {
					...state.ProvisionOrderToClone,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				ProvisionOrderToClone: {
					...state.ProvisionOrderToClone,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_PROVISION_ORDER_TO_CLONE: {
			return {
				...state,
				ProvisionOrderToClone: {
					...provisionOrderReducerInitialState.ProvisionOrderToClone
				}
			};
		}

		default: {
			return state;
		}
	}
};


export const getProvisionOrderReducer = state => state.provisionOrderReducer;

// GET Provision Order
export const getProvisionOrderList = state => getProvisionOrderReducer(state)?.ProvisionOrderList;
export const getProvisionOrderListData = state => getProvisionOrderList(state)?.data;
export const getProvisionOrderListIsFetching = state => getProvisionOrderList(state)?.isFetching;

// EDIT Provision Order
export const getProvisionOrderEdit = state => getProvisionOrderReducer(state)?.provisionOrderEdit;
export const getProvisionOrderEditIsFetching = state => getProvisionOrderEdit(state)?.isFetching;
export const getProvisionOrderEditIsError = state => getProvisionOrderEdit(state)?.hasError;
export const getProvisionOrderToEdit = state => getProvisionOrderReducer(state)?.provisionOrderToEdit;

// SEARCH Provision Order for ID
export const getProvisionOrderId = state => getProvisionOrderReducer(state)?.searchFundRequetsId;
export const getIsFetchingProvisionOrderId = state => getFundRequestId(state)?.isFetching
export const getProvisionOrderToDetail = state => getProvisionOrderReducer(state)?.provisionOrderData

// POST Provision Order
export const getProvisionOrderCreate = state => getProvisionOrderReducer(state)?.ProvisionOrderNew;
export const getProvisionOrderCreateIsFetching = state => getProvisionOrderCreate(state)?.isFetching;
export const getProvisionOrderCreateIsError = state => getProvisionOrderCreate(state)?.hasError;

// DETAIL Provision Order
export const getProvisionOrderDetail = state => getProvisionOrderReducer(state)?.ProvisionOrderDetail;

// DELETE Provision Order
export const getProvisionOrder = state => getProvisionOrderReducer(state)?.ProvisionOrderDelete;
export const getProvisionOrderData = state => getProvisionOrder(state)?.data;
export const getProvisionOrderIsFetching = state => getProvisionOrder(state)?.isFetching;

// GET Acquisition types list
export const getAcquisitionTypesList = state => getProvisionOrderReducer(state)?.acquisitionTypesList;
export const getAcquisitionTypesListData = state => getAcquisitionTypesList(state)?.data;
export const getAcquisitionTypesListIsFetching = state => getProvisionOrderList(state)?.isFetching;

// GET Provision Order
export const getProvisionOrderToClone = state => getProvisionOrderReducer(state)?.ProvisionOrderToClone;
export const getProvisionOrderToCloneData = state => getProvisionOrderToClone(state)?.data;
export const getProvisionOrderToCloneIsFetching = state => getProvisionOrderToClone(state)?.isFetching;