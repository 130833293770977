export const LIST_CAPTION_REQUEST = 'LIST_CAPTION_REQUEST';
export const LIST_CAPTION_REQUEST_SUCCESS = 'LIST_CAPTION_REQUEST_SUCCESS';
export const LIST_CAPTION_REQUEST_FAILURE = 'LIST_CAPTION_REQUEST_FAILURE';

export const CLEAR_LIST_CAPTION = 'CLEAR_LIST_CAPTION';

// Caption Detail
export const DETAIL_CAPTION_REQUEST = 'DETAIL_CAPTION_REQUEST';
export const DETAIL_CAPTION_REQUEST_SUCCESS = 'DETAIL_CAPTION_REQUEST_SUCCESS';
export const DETAIL_CAPTION_REQUEST_FAILURE = 'DETAIL_CAPTION_REQUEST_FAILURE';
export const SET_CAPTION = 'SET_CAPTION';

//Caption New
export const NEW_CAPTION_REQUEST = 'NEW_CAPTION_REQUEST';
export const NEW_CAPTION_REQUEST_SUCCESS = 'NEW_CAPTION_REQUEST_SUCCESS';
export const NEW_CAPTION_REQUEST_FAILURE = 'NEW_CAPTION_REQUEST_FAILURE';

//Caption Edit
export const EDIT_CAPTION_REQUEST = 'EDIT_CAPTION_REQUEST';
export const EDIT_CAPTION_REQUEST_SUCCESS = 'EDIT_CAPTION_REQUEST_SUCCESS';
export const EDIT_CAPTION_REQUEST_FAILURE = 'EDIT_CAPTION_REQUEST_FAILURE';
export const SET_CAPTION_TO_EDIT = 'SET_CAPTION_TO_EDIT';

//Caption delete
export const DELETE_CAPTION_REQUEST = 'DELETE_CAPTION_REQUEST';
export const DELETE_CAPTION_REQUEST_SUCCESS = 'DELETE_CAPTION_REQUEST_SUCCESS';
export const DELETE_CAPTION_REQUEST_FAILURE = 'DELETE_CAPTION_REQUEST_FAILURE';