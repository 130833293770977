export const LIST_EXPEDIENT_REQUEST = 'LIST_EXPEDIENT_REQUEST';
export const LIST_EXPEDIENT_REQUEST_SUCCESS = 'LIST_EXPEDIENT_REQUEST_SUCCESS';
export const LIST_EXPEDIENT_REQUEST_FAILURE = 'LIST_EXPEDIENT_REQUEST_FAILURE';

export const CLEAR_EXPEDIENT_LIST = 'CLEAR_EXPEDIENT_LIST';

//Alert Edit
export const EDIT_EXPEDIENT_REQUEST = 'EDIT_EXPEDIENT_REQUEST';
export const EDIT_EXPEDIENT_REQUEST_SUCCESS = 'EDIT_EXPEDIENT_REQUEST_SUCCESS';
export const EDIT_EXPEDIENT_REQUEST_FAILURE = 'EDIT_EXPEDIENT_REQUEST_FAILURE';
export const SET_EXPEDIENT_TO_EDIT = 'SET_EXPEDIENT_TO_EDIT';