import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from 'src/components/general/FormFieldError';
import moment from 'moment';

import { serviceLabel, addressee, message, subject, file, signature, fileMaxSizeMsg, group } from 'src/utils/label';

import { tryListAllAdministrativeServices } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { tryGetListUserProfile } from 'src/redux/user/userActionCreator';

import { getUserProfileListData, getUserProfileListIsFetching } from 'src/redux/user/userReducer';
import { getAdministrativeServiceListAllData, getAdministrativeServiceListAllIsFetching } from 'src/redux/administrativeService/administrativeServiceReducer';

import { MultiSelect } from "react-multi-select-component";

const NotificationForm = props => {

    const { selectedService, setSelectedService, hasValueCustomValueRenderer, selectedProfile, setSelectedProfile } = props;

    const dispatch = useDispatch();

    const { register, errors } = useFormContext();

    //Default values
    const defaultSignature = 'Dirección de Análisis e Informática\nContaduría General de Misiones\nAv. Polonia Nº1223 - CP 3300\nTeléfono: (0376) 4447518';
    const currentDate = moment(new Date()).format('YYYY-MM-DD');

    //validations messages
    const subjectNotificationValidationMessage = 'Debe ingresar el asunto.';
    const messageValidationMessage = 'Debe ingresar el mensaje.';
    const signatureValidationMessage = 'Debe ingresar la firma.';

    //validations objects
    const subjectNotificationValidationObj = { required: subjectNotificationValidationMessage };
    const messageValidationObj = { required: messageValidationMessage };
    const signatureValidationObj = { required: signatureValidationMessage };
    const dateToValidationObj = { required: false };

    const fileValidationObj = {
        validate: file => {
            const sizeInMB = file[0]?.size / 1024 / 1024;
            /*
             const numberOfFiles = file?.length;
            if(numberOfFiles > 5){
                return maxFiles;s
            }
            */

            return (sizeInMB >= 5 || sizeInMB == undefined) ? fileMaxSizeMsg : undefined;
        }
    };

    // Profile
    const listProfileData = useSelector(state => getUserProfileListData(state))?.sort((a, b) => a.name > b.name ? 1 : -1);
    const listProfileIsFetching = useSelector(state => getUserProfileListIsFetching(state));

    //ProfileNameServices
    const idProfileOperadorServicioPresupuesto = 'Operador de Servicio de Presupuesto';  //ID 22 Operador de Servicio de Prespuesto
    const idProfileFiscalizador = 'Fiscalizador';  //ID 46 Fiscalizador

    const allowMultiselectService = (selectedProfile == idProfileOperadorServicioPresupuesto || selectedProfile == idProfileFiscalizador);

    ///Services Administrative
    const administrativeService = useSelector(state => getAdministrativeServiceListAllData(state))?.records?.sort((a, b) => a.code > b.code ? 1 : -1);
    const administrativeServiceIsFetching = useSelector(state => getAdministrativeServiceListAllIsFetching(state));

    //// Multiselect

    // View items selector
    let options = [];
    let i = 0;
    for (i in administrativeService) {
        options.push({ label: administrativeService[i]?.code + " - " + administrativeService[i]?.shortName, value: administrativeService[i]?.id })
    }

    // Idiom Spanish
    const idiom = {
        "selectSomeItems": "Seleccionar una o más opciones...",
        "allItemsAreSelected": "Todos los elementos están seleccionados.",
        "selectAll": "Seleccionar todo",
        "search": "Buscar",
        "clearSearch": "Limpiar búsqueda."
    }

    // Load data
    const customValueRenderer = (selectedService, _options) => {
        return selectedService?.length
            ? selectedService?.map(({ label }) => " ✔️" + label)
            : "No hay ítems seleccionados...";
    };
    //End Multiselect

    //UseEffect
    useEffect(() => {
        dispatch(tryGetListUserProfile());
        dispatch(tryListAllAdministrativeServices());
    }, []);

    useEffect(() => {
        setSelectedService([]);
    }, [selectedProfile]);

    return <>

        <h6 className='text-black-color mb-3'>{addressee}</h6>

        <Form.Group as={Row} controlId='alias'>
            <Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
                {group}:
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    as='select'
                    name='profile'
                    title={group}
                    ref={register()}
                    onChange={event => setSelectedProfile(event.target.options[event.target.selectedIndex].text)}
                >
                    <option value={0}>Todos</option>
                    {
                        listProfileData?.map(profile => (
                            <option key={profile?.id} value={profile?.id}>
                                {profile?.name}
                            </option>))
                    }
                </Form.Control>
                <FormFieldError errors={errors?.profile} />
            </Col>
            <Col sm='3' className='py-1'>
                {
                    listProfileIsFetching
                        ?
                        <Spinner animation='border' size='sm' className='my-2 spinner-border text-danger' />
                        :
                        null
                }
            </Col>
        </Form.Group>

        {
            allowMultiselectService
            &&
            <Form.Group as={Row} controlId='alias'>
                <Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
                    <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                    {serviceLabel}:
                </Form.Label>
                <Col sm={5}>
                    <MultiSelect
                        className='text-black-color'
                        options={options}
                        value={selectedService}
                        onChange={setSelectedService}
                        labelledBy={"Select"}
                        overrideStrings={idiom}
                        valueRenderer={customValueRenderer}
                        hasSelectAll={true}
                        isLoading={administrativeServiceIsFetching}
                        ClearSelectedIcon={"🧹Limpiar"}
                    />
                    {
                        hasValueCustomValueRenderer
                            ?
                            <div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
                                {`${'Debe seleccionar al menos un ítem.'}`}
                            </div>
                            :
                            null
                    }
                </Col>
                <FormFieldError errors={errors?.service} />
            </Form.Group>
        }


        <hr color="black" className='' />
        <h6 className='text-black-color mb-3'>{message}</h6>

        <Form.Group as={Row} controlId='alias'>
            <Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                {subject}:
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    ref={register(subjectNotificationValidationObj)}
                    type='text'
                    placeholder={subject}
                    name='subject'
                    maxLength={100}
                />
                <FormFieldError errors={errors?.subject} />
            </Col>
        </Form.Group>

        <Form.Group as={Row} >
            <Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
                Fecha hasta:
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    ref={register(dateToValidationObj)}
                    name='dateTo'
                    type='date'
                    min={currentDate}
                />
                <FormFieldError errors={errors?.dateTo} />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId='alias'>
            <Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                {message}:
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    ref={register(messageValidationObj)}
                    as='textarea'
                    placeholder={message}
                    name='message'
                    disabled={false}
                    maxLength={10000}
                    rows={5}
                />
                <FormFieldError errors={errors?.message} />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId='alias'>
            <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
                {file} (.pdf, .png, .jpg):
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    type='file'
                    name='file'
                    accept='.pdf,.jpg,.png'
                    ref={register(fileValidationObj)}
                    className='text-black-color'
                //multiple
                />
                <FormFieldError errors={errors?.file} />
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId='alias'>
            <Form.Label className='text-right text-black-color pr-0 pl-0 d-flex mandatory-label' column sm={4}>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                {signature}:
            </Form.Label>
            <Col sm={5}>
                <Form.Control
                    ref={register(signatureValidationObj)}
                    as='textarea'
                    placeholder={signature}
                    name='signature'
                    disabled={false}
                    maxLength={255}
                    defaultValue={defaultSignature}
                    rows={5}
                />
                <FormFieldError errors={errors?.signature} />
            </Col>
        </Form.Group>

    </>
};

export default NotificationForm;