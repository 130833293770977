import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import LoginPage from 'src/components/pages/LoginPage';
import SetPasswordPage from 'src/components/pages/SetPasswordPage';
import ForgotPasswordPage from 'src/components/pages/ForgotPasswordPage';
import ResetPasswordPage from 'src/components/pages/ResetPasswordPage';
import { ROUTE_PATHS as PATHS } from '../utils/constants';

export const PublicLayout = () => {
	// console.log('PublicLayout');
	return (
			<>
				<Switch>
					<Route path={PATHS.LOGIN_URI} component={LoginPage} />
					<Route path={PATHS.SET_PASSWORD_URI} component={SetPasswordPage} />
					<Route path={PATHS.FORGOT_PASSWORD_URI} component={ForgotPasswordPage} />
					<Route path={`${PATHS.RESET_PASSWORD_URI}/:id`} component={ResetPasswordPage} />

					<Route render={() => (<div>Miss</div>)} />

				</Switch>
			</>
	)	
}