import React, { useEffect, useState }from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { required, cancel, notifications, newNotification, sendNotification } from 'src/utils/label';
import { getClearObject } from 'src/utils/utils';

import { NOTIFICATION_LIST } from 'src/utils/constants';
import { tryAddNewNotification } from 'src/redux/notification/notificationActionCreator';
import { getNotificationCreateIsFetching } from 'src/redux/notification/notificationReducer';

import NotificationForm  from 'src/components/forms/notification/NotificationForm';
import { getProfileName } from 'src/redux/login/loginReducer';

const NotificationNewPage = props => {

	const dispatch = useDispatch();

	const hookFormMethods = useForm();
    const { handleSubmit } = hookFormMethods;

	const notificationCreateIsFetching = useSelector(state => getNotificationCreateIsFetching(state));

	//Profile user
    const profileName = useSelector(state => getProfileName(state));

    const profileNameAI = profileName == 'Dirección de Análisis e Informática';
    const profileNameSA = profileName == 'Super Admin';

    const allowNewNotification = profileNameAI || profileNameSA;

	// Selected service
	const [selectedService, setSelectedService] = useState([]);
    
	// selected profile
	const [selectedProfile, setSelectedProfile] = useState();

	const hasValueCustomValueRenderer = (selectedService?.length === 0);
	const allowGet = ( notificationCreateIsFetching || ((selectedProfile == 'Fiscalizador' || selectedProfile == 'Operador de Servicio de Presupuesto') && hasValueCustomValueRenderer) );
	
	const onFormSubmit = data => {

		let selectedAdministrativeService =  selectedService?.map( item => item.value );
		
		const params = {
			profile: data?.profile,
			administrativeService: selectedAdministrativeService,
			subject: data?.subject,
			message: data?.message,
			files: data?.file,
			signature: data?.signature,
			dateTo: data?.dateTo
		}

		const paramsToSend = getClearObject(params);

		dispatch(tryAddNewNotification(paramsToSend));
	};
    
	useEffect( () => {
       !allowNewNotification && dispatch(push(NOTIFICATION_LIST));
    }, []);

	return <Container fluid className='mb-5'>
		<Card>

			<PageTitle text={notifications} />

			<Container fluid>
				<Card className='mb-3'>

					<Card.Header>
						<h6 className='mb-0'>{newNotification}</h6>
					</Card.Header>

					<Card.Body >

						<Row>
							<Col xs={8}>
								<h6 className='mb-4 text-black-color'>
								</h6>
							</Col>

							<Col xs={4} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
						</Row>

						<FormProvider {...hookFormMethods}>
							<Form onSubmit={handleSubmit(onFormSubmit)} autoComplete='off'  className='text-black-color mt-2 mb-5'>
								
								<NotificationForm 
									selectedService = {selectedService}
									setSelectedService = {setSelectedService}
									hasValueCustomValueRenderer = {hasValueCustomValueRenderer}
									selectedProfile = {selectedProfile} 
									setSelectedProfile = {setSelectedProfile}
								/>

								<div className='d-flex justify-content-around mt-4 mb-3'>
									<Button 
										variant='danger' 
										size='lg'
										disabled={notificationCreateIsFetching}
										onClick={() => dispatch(push(NOTIFICATION_LIST))}
									>
										{cancel}
									</Button>

									<span 
                                        className={(notificationCreateIsFetching ? '' : 'hidden')}
                                    >
										<Spinner className='spinner-border text-danger' animation='border' />
									</span>

									<Button 
										type='submit' 
										variant='success' 
										size='lg'
										className='text-white-color'
										disabled={allowGet}
									>
										{sendNotification}
										<FontAwesomeIcon className='ml-3' icon={faPaperPlane}/>
									</Button>
								</div>

							</Form>
						</FormProvider>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default NotificationNewPage;