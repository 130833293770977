import * as ACTION_TYPES from './reportsActionTypes';

export const reportsReducerInitialState = {
	reportConsolidated: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportJurisdictionAnalytic: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportOrganismAnalytic: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportJurisdictionTotal: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportOrganizationTotal: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportCrossed: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportInstrumentLegalModify: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportInstrumentLegalModifyDirectDownload: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportAffectationsByAdministrativeDocument: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportAffectationsByAdministrativeDocumentDownload: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportMovement: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportCreditModifyCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportTableTotalsCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportBudgetStatusCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportWorksBudgetAnalyticalRecordCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportMovementsStatusCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportBalanceStatusPaymentListCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportDelayedFilesCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportBudgetAnalyticalAccountingCreditExecution: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportBalanceStatus: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportCreditExecutionGenerated: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	creditExecutonGeneratedList: {
		isFetching: false,
		hasError: false,
		error: undefined,
		data: undefined
	},
	reportDeliveryOrdersFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportPaymentOrderAdministrativeServiceFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportReportServiceDirectionFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportDetailServiceDirectionFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportReportProviderFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportFundRequestBankAccount: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportNewFundRequest: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	passiveWaste: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	ccpmConnectivityStatus: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportPendingResolutionPassiveResidues: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportDeterminationPassiveResidues: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportAccrualBasis: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportCompensationDirectDownload: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportProvisionOrder: {
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const reportsReducer = (state = reportsReducerInitialState, action) => {
	switch (action.type) {

		case ACTION_TYPES.REPORT_CONSOLIDATED_REQUEST: {
			return {
				...state,
				reportConsolidated: {
					...state.reportConsolidated,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportConsolidated.error
				}
			};
		}
		case ACTION_TYPES.REPORT_CONSOLIDATED_REQUEST_SUCCESS: {
			return {
				...state,
				reportConsolidated: {
					...state.reportConsolidated,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportConsolidated.error
				}
			};
		}
		case ACTION_TYPES.REPORT_CONSOLIDATED_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportConsolidated: {
					...state.reportConsolidated,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_CONSOLIDATED_DATA: {
			return {
				...state,
				reportConsolidated: reportsReducerInitialState.reportConsolidated
			};
		}

		//Jurisdiction Analytic
		case ACTION_TYPES.REPORT_JURISDICTION_ANALYTIC_REQUEST: {
			return {
				...state,
				reportJurisdictionAnalytic: {
					...state.reportJurisdictionAnalytic,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportJurisdictionAnalytic.error
				}
			};
		}
		case ACTION_TYPES.REPORT_JURISDICTION_ANALYTIC_REQUEST_SUCCESS: {
			return {
				...state,
				reportJurisdictionAnalytic: {
					...state.reportJurisdictionAnalytic,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportJurisdictionAnalytic.error
				}
			};
		}
		case ACTION_TYPES.REPORT_JURISDICTION_ANALYTIC_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportJurisdictionAnalytic: {
					...state.reportJurisdictionAnalytic,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_JURISDICTION_ANALYTIC_DATA: {
			return {
				...state,
				reportJurisdictionAnalytic: reportsReducerInitialState.reportJurisdictionAnalytic
			};
		}

		//Organism Analytic
		case ACTION_TYPES.REPORT_ORGANISM_ANALYTIC_REQUEST: {
			return {
				...state,
				reportOrganismAnalytic: {
					...state.reportOrganismAnalytic,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportOrganismAnalytic.error
				}
			};
		}
		case ACTION_TYPES.REPORT_ORGANISM_ANALYTIC_REQUEST_SUCCESS: {
			return {
				...state,
				reportOrganismAnalytic: {
					...state.reportOrganismAnalytic,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportOrganismAnalytic.error
				}
			};
		}
		case ACTION_TYPES.REPORT_ORGANISM_ANALYTIC_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportOrganismAnalytic: {
					...state.reportOrganismAnalytic,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_ORGANISM_ANALYTIC_DATA: {
			return {
				...state,
				reportOrganismAnalytic: reportsReducerInitialState.reportOrganismAnalytic
			};
		}

		//Jurisdiction Total
		case ACTION_TYPES.REPORT_JURISDICTION_TOTAL_REQUEST: {
			return {
				...state,
				reportJurisdictionTotal: {
					...state.reportJurisdictionTotal,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportJurisdictionTotal.error
				}
			};
		}
		case ACTION_TYPES.REPORT_JURISDICTION_TOTAL_REQUEST_SUCCESS: {
			return {
				...state,
				reportJurisdictionTotal: {
					...state.reportJurisdictionTotal,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportJurisdictionTotal.error
				}
			};
		}
		case ACTION_TYPES.REPORT_JURISDICTION_TOTAL_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportJurisdictionTotal: {
					...state.reportJurisdictionTotal,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_JURISDICTION_TOTAL_DATA: {
			return {
				...state,
				reportJurisdictionTotal: reportsReducerInitialState.reportJurisdictionTotal
			};
		}



		// Report Organization Total
		case ACTION_TYPES.REPORT_ORGANIZATION_TOTAL_REQUEST: {
			return {
				...state,
				reportOrganizationTotal: {
					...state.reportOrganizationTotal,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportOrganizationTotal.error
				}
			};
		}
		case ACTION_TYPES.REPORT_ORGANIZATION_TOTAL_REQUEST_SUCCESS: {
			return {
				...state,
				reportOrganizationTotal: {
					...state.reportOrganizationTotal,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportOrganizationTotal.error
				}
			};
		}
		case ACTION_TYPES.REPORT_ORGANIZATION_TOTAL_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportOrganizationTotal: {
					...state.reportOrganizationTotal,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}



		// Report crossed
		case ACTION_TYPES.REPORT_CROSSED_REQUEST: {
			return {
				...state,
				reportCrossed: {
					...state.reportCrossed,
					isFetching: true,
					hasError: false,
					error: false
				}
			};
		}
		case ACTION_TYPES.REPORT_CROSSED_REQUEST_SUCCESS: {
			return {
				...state,
				reportCrossed: {
					...state.reportCrossed,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportCrossed.error
				}
			};
		}
		case ACTION_TYPES.REPORT_CROSSED_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportCrossed: {
					...state.reportCrossed,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_CROSSED_DATA: {
			return {
				...state,
				reportCrossed: reportsReducerInitialState.reportCrossed
			};
		}
		// END Report crossed

		// Report Instrument Legal Modify
		case ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST: {
			return {
				...state,
				reportInstrumentLegalModify: {
					...state.reportInstrumentLegalModify,
					isFetching: true,
					hasError: false,
					error: false
				}
			};
		}
		case ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_SUCCESS: {
			return {
				...state,
				reportInstrumentLegalModify: {
					...state.reportInstrumentLegalModify,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportInstrumentLegalModify.error
				}
			};
		}
		case ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportInstrumentLegalModify: {
					...state.reportInstrumentLegalModify,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_INSTRUMENT_LEGAL_MODIFY_DATA: {
			return {
				...state,
				reportInstrumentLegalModify: reportsReducerInitialState.reportInstrumentLegalModify
			};
		}
		// END Report Instrument Legal Modify

		// Report Instrument Legal Modify Direct download
		case ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST: {
			return {
				...state,
				reportInstrumentLegalModifyDirectDownload: {
					...state.reportInstrumentLegalModifyDirectDownload,
					isFetching: true,
					hasError: false,
					error: false
				}
			};
		}
		case ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_SUCCESS: {
			return {
				...state,
				reportInstrumentLegalModifyDirectDownload: {
					...state.reportInstrumentLegalModifyDirectDownload,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportInstrumentLegalModifyDirectDownload.error
				}
			};
		}
		case ACTION_TYPES.REPORT_INSTRUMENT_LEGAL_MODIFY_DIRECT_DOWNLOAD_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportInstrumentLegalModifyDirectDownload: {
					...state.reportInstrumentLegalModifyDirectDownload,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Report Instrument Legal Modify Direct download

		// Report affectations by administrative documents
		case ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST: {
			return {
				...state,
				reportAffectationsByAdministrativeDocument: {
					...state.reportAffectationsByAdministrativeDocument,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportAffectationsByAdministrativeDocument.error
				}
			};
		}
		case ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS: {
			return {
				...state,
				reportAffectationsByAdministrativeDocument: {
					...state.reportAffectationsByAdministrativeDocument,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportAffectationsByAdministrativeDocument.error
				}
			};
		}
		case ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportAffectationsByAdministrativeDocument: {
					...state.reportAffectationsByAdministrativeDocument,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT: {
			return {
				...state,
				reportAffectationsByAdministrativeDocument: reportsReducerInitialState.reportAffectationsByAdministrativeDocument
			};
		}
		// END Report affectations by administrative documents

		// Report affectations by administrative documents direct download
		case ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST: {
			return {
				...state,
				reportAffectationsByAdministrativeDocumentDownload: {
					...state.reportAffectationsByAdministrativeDocumentDownload,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportAffectationsByAdministrativeDocumentDownload.error
				}
			};
		}
		case ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_SUCCESS: {
			return {
				...state,
				reportAffectationsByAdministrativeDocumentDownload: {
					...state.reportAffectationsByAdministrativeDocumentDownload,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportAffectationsByAdministrativeDocumentDownload.error
				}
			};
		}
		case ACTION_TYPES.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT_DOWNLOAD_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportAffectationsByAdministrativeDocumentDownload: {
					...state.reportAffectationsByAdministrativeDocumentDownload,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Report affectations by administrative documents direct download

		// Report movement actions
		case ACTION_TYPES.REPORT_MOVEMENT_REQUEST: {
			return {
				...state,
				reportMovement: {
					...state.reportMovement,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportMovement.error
				}
			};
		}
		case ACTION_TYPES.REPORT_MOVEMENT_REQUEST_SUCCESS: {
			return {
				...state,
				reportMovement: {
					...state.reportMovement,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportMovement.error
				}
			};
		}
		case ACTION_TYPES.REPORT_MOVEMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportMovement: {
					...state.reportMovement,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_MOVEMENT: {
			return {
				...state,
				reportMovement: reportsReducerInitialState.reportMovement
			};
		}
		// END Report movement actions

		// Report Credit Modify - Report Credit Execution
		case ACTION_TYPES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportCreditModifyCreditExecution: {
					...state.reportCreditModifyCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportCreditModifyCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportCreditModifyCreditExecution: {
					...state.reportCreditModifyCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportCreditModifyCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportCreditModifyCreditExecution: {
					...state.reportCreditModifyCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_CREDIT_MODIFY_CREDIT_EXECUTION: {
			return {
				...state,
				reportCreditModifyCreditExecution: reportsReducerInitialState.reportCreditModifyCreditExecution
			};
		}
		// END Report Credit Modify - Report Credit Execution


		// Report Table Totals - Report Credit Execution
		case ACTION_TYPES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportTableTotalsCreditExecution: {
					...state.reportTableTotalsCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportTableTotalsCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportTableTotalsCreditExecution: {
					...state.reportTableTotalsCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportTableTotalsCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_TABLE_TOTALS_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportTableTotalsCreditExecution: {
					...state.reportTableTotalsCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_TABLE_TOTALS_CREDIT_EXECUTION: {
			return {
				...state,
				reportTableTotalsCreditExecution: reportsReducerInitialState.reportTableTotalsCreditExecution
			};
		}
		// END Report Credit Modify - Report Credit Execution

		// Report Budget status - Report Credit Execution
		case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportBudgetStatusCreditExecution: {
					...state.reportBudgetStatusCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportBudgetStatusCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportBudgetStatusCreditExecution: {
					...state.reportBudgetStatusCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportBudgetStatusCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportBudgetStatusCreditExecution: {
					...state.reportBudgetStatusCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION: {
			return {
				...state,
				reportBudgetStatusCreditExecution: reportsReducerInitialState.reportBudgetStatusCreditExecution
			};
		}
		// END ReportBudget status - Report Credit Execution

		// Report  Works Budget Analytical Record - Report Credit Execution
		case ACTION_TYPES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportWorksBudgetAnalyticalRecordCreditExecution: {
					...state.reportWorksBudgetAnalyticalRecordCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportWorksBudgetAnalyticalRecordCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportWorksBudgetAnalyticalRecordCreditExecution: {
					...state.reportWorksBudgetAnalyticalRecordCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportWorksBudgetAnalyticalRecordCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportWorksBudgetAnalyticalRecordCreditExecution: {
					...state.reportWorksBudgetAnalyticalRecordCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION: {
			return {
				...state,
				reportWorksBudgetAnalyticalRecordCreditExecution: reportsReducerInitialState.reportWorksBudgetAnalyticalRecordCreditExecution
			};
		}
		// END Report Works Budget Analytical Record - Report Credit Execution


		// Report  Movements Status - Report Credit Execution
		case ACTION_TYPES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportMovementsStatusCreditExecution: {
					...state.reportMovementsStatusCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportMovementsStatusCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportMovementsStatusCreditExecution: {
					...state.reportMovementsStatusCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportMovementsStatusCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportMovementsStatusCreditExecution: {
					...state.reportMovementsStatusCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION: {
			return {
				...state,
				reportMovementsStatusCreditExecution: reportsReducerInitialState.reportMovementsStatusCreditExecution
			};
		}
		// END Report Movements Status - Report Credit Execution

		// Report balance status payment list - Report Credit Execution
		case ACTION_TYPES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportBalanceStatusPaymentListCreditExecution: {
					...state.reportBalanceStatusPaymentListCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportBalanceStatusPaymentListCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportBalanceStatusPaymentListCreditExecution: {
					...state.reportBalanceStatusPaymentListCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportBalanceStatusPaymentListCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportBalanceStatusPaymentListCreditExecution: {
					...state.reportBalanceStatusPaymentListCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION: {
			return {
				...state,
				reportBalanceStatusPaymentListCreditExecution: reportsReducerInitialState.reportBalanceStatusPaymentListCreditExecution
			};
		}
		// END Report balance status payment list - Report Credit Execution


		// Report  Budget Analytical Accounting - Report Credit Execution
		case ACTION_TYPES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportBudgetAnalyticalAccountingCreditExecution: {
					...state.reportBudgetAnalyticalAccountingCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportBudgetAnalyticalAccountingCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportBudgetAnalyticalAccountingCreditExecution: {
					...state.reportBudgetAnalyticalAccountingCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportBudgetAnalyticalAccountingCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportBudgetAnalyticalAccountingCreditExecution: {
					...state.reportBudgetAnalyticalAccountingCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION: {
			return {
				...state,
				reportBudgetAnalyticalAccountingCreditExecution: reportsReducerInitialState.reportBudgetAnalyticalAccountingCreditExecution
			};
		}
		// END Report Budget Analytical Accounting - Report Credit Execution

		// Report Balance Status
		case ACTION_TYPES.REPORT_BALANCE_STATUS_REQUEST: {
			return {
				...state,
				reportBalanceStatus: {
					...state.reportBalanceStatus,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportBalanceStatus.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BALANCE_STATUS_REQUEST_SUCCESS: {
			return {
				...state,
				reportBalanceStatus: {
					...state.reportBalanceStatus,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportBalanceStatus.error
				}
			};
		}
		case ACTION_TYPES.REPORT_BALANCE_STATUS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportBalanceStatus: {
					...state.reportBalanceStatus,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_BALANCE_STATUS: {
			return {
				...state,
				reportBalanceStatus: reportsReducerInitialState.reportBalanceStatus
			};
		}
		// END Report Balance Status

		// Report  Report Delayed Files - Report Credit Execution
		case ACTION_TYPES.REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST: {
			return {
				...state,
				reportDelayedFilesCreditExecution: {
					...state.reportDelayedFilesCreditExecution,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportDelayedFilesCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_SUCCESS: {
			return {
				...state,
				reportDelayedFilesCreditExecution: {
					...state.reportDelayedFilesCreditExecution,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportDelayedFilesCreditExecution.error
				}
			};
		}
		case ACTION_TYPES.REPORT_DELAYED_FILES_CREDIT_EXECUTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportDelayedFilesCreditExecution: {
					...state.reportDelayedFilesCreditExecution,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_DELAYED_FILES_CREDIT_EXECUTION: {
			return {
				...state,
				reportDelayedFilesCreditExecution: reportsReducerInitialState.reportDelayedFilesCreditExecution
			};
		}
		// END Report Report Delayed Files - Report Credit Execution


		//Lista de Reporte ejecucion de presupuesto ya generado
		case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST: {
			return {
				...state,
				creditExecutonGeneratedList: {
					...state.creditExecutonGeneratedList,
					isFetching: true,
					hasError: false,
					data: reportsReducerInitialState.creditExecutonGeneratedList.data,
					error: reportsReducerInitialState.creditExecutonGeneratedList.error
				}
			};
		}

		case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				creditExecutonGeneratedList: {
					...state.creditExecutonGeneratedList,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.creditExecutonGeneratedList.error,
					data
				}
			};
		}

		case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_LIST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				creditExecutonGeneratedList: {
					...state.creditExecutonGeneratedList,
					isFetching: false,
					hasError: true,
					data: reportsReducerInitialState.creditExecutonGeneratedList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_LIST_GENERATED: {
			return {
				...state,
				creditExecutonGeneratedList: reportsReducerInitialState.creditExecutonGeneratedList
			};
		}
		//End Credit execution generated

		// Report delivery orders - Report Request Fund
		case ACTION_TYPES.REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportDeliveryOrdersFundRequest: {
					...state.reportDeliveryOrdersFundRequest,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportDeliveryOrdersFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_SUCCESS: {
			return {
				...state,
				reportDeliveryOrdersFundRequest: {
					...state.reportDeliveryOrdersFundRequest,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportDeliveryOrdersFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_DELIVERY_ORDERS_FUND_REQUEST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportDeliveryOrdersFundRequest: {
					...state.reportDeliveryOrdersFundRequest,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_DELIVERY_ORDERS_FUND_REQUEST: {
			return {
				...state,
				reportDeliveryOrdersFundRequest: reportsReducerInitialState.reportDeliveryOrdersFundRequest
			};
		}
		// END Report delivery orders - Report Request Fund

		// Report payment order administrative service fund request - Report Request Fund
		case ACTION_TYPES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportPaymentOrderAdministrativeServiceFundRequest: {
					...state.reportPaymentOrderAdministrativeServiceFundRequest,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportPaymentOrderAdministrativeServiceFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_SUCCESS: {
			return {
				...state,
				reportPaymentOrderAdministrativeServiceFundRequest: {
					...state.reportPaymentOrderAdministrativeServiceFundRequest,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportPaymentOrderAdministrativeServiceFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportPaymentOrderAdministrativeServiceFundRequest: {
					...state.reportPaymentOrderAdministrativeServiceFundRequest,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST: {
			return {
				...state,
				reportPaymentOrderAdministrativeServiceFundRequest: reportsReducerInitialState.reportPaymentOrderAdministrativeServiceFundRequest
			};
		}
		// END Report payment order administrative service fund request - Report Request Fund

		// Report service direction - Report Request Fund
		case ACTION_TYPES.REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportReportServiceDirectionFundRequest: {
					...state.reportReportServiceDirectionFundRequest,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportReportServiceDirectionFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS: {
			return {
				...state,
				reportReportServiceDirectionFundRequest: {
					...state.reportReportServiceDirectionFundRequest,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportReportServiceDirectionFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportReportServiceDirectionFundRequest: {
					...state.reportReportServiceDirectionFundRequest,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_SERVICE_DIRECTION_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportReportServiceDirectionFundRequest: reportsReducerInitialState.reportReportServiceDirectionFundRequest
			};
		}
		// END Report service direction - Report Request Fund

		// Report detail service direction - Report Request Fund
		case ACTION_TYPES.REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportDetailServiceDirectionFundRequest: {
					...state.reportDetailServiceDirectionFundRequest,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportDetailServiceDirectionFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_SUCCESS: {
			return {
				...state,
				reportDetailServiceDirectionFundRequest: {
					...state.reportDetailServiceDirectionFundRequest,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportDetailServiceDirectionFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_DETAIL_SERVICE_DIRECTION_FUND_REQUEST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportDetailServiceDirectionFundRequest: {
					...state.reportDetailServiceDirectionFundRequest,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Report detail service direction - Report Request Fund

		// Report provider - Report Request Fund
		case ACTION_TYPES.REPORT_PROVIDER_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportReportProviderFundRequest: {
					...state.reportReportProviderFundRequest,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportReportProviderFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_PROVIDER_FUND_REQUEST_REQUEST_SUCCESS: {
			return {
				...state,
				reportReportProviderFundRequest: {
					...state.reportReportProviderFundRequest,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportReportProviderFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_PROVIDER_FUND_REQUEST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportReportProviderFundRequest: {
					...state.reportReportProviderFundRequest,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_PROVIDER_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportReportProviderFundRequest: reportsReducerInitialState.reportReportProviderFundRequest
			};
		}
		// END Report provider - Report Request Fund


		// Report  Request Fund
		case ACTION_TYPES.REPORT_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportFundRequest: {
					...state.reportFundRequest,
					isFetching: true,
					hasError: false,
					error: reportsReducerInitialState.reportFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_FUND_REQUEST_REQUEST_SUCCESS: {
			return {
				...state,
				reportFundRequest: {
					...state.reportFundRequest,
					isFetching: false,
					hasError: false,
					error: reportsReducerInitialState.reportFundRequest.error
				}
			};
		}
		case ACTION_TYPES.REPORT_FUND_REQUEST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportFundRequest: {
					...state.reportFundRequest,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_FUND_REQUEST_REQUEST: {
			return {
				...state,
				reportFundRequest: reportsReducerInitialState.reportFundRequest
			};
		}
		// END  Report  Request Fund
		

// Report fund request bank account
case ACTION_TYPES.REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST: {
	return {
		...state,
		reportFundRequestBankAccount: {
			...state.reportFundRequestBankAccount,
			isFetching: true,
			hasError: false,
			error: reportsReducerInitialState.reportFundRequestBankAccount.error
		}
	};
}
case ACTION_TYPES.REPORT_FUND_REQUEST_BANK_ACCOUNT_SUCCESS: {
	return {
		...state,
		reportFundRequestBankAccount: {
			...state.reportFundRequestBankAccount,
			isFetching: false,
			hasError: false,
			error: reportsReducerInitialState.reportFundRequestBankAccount.error
		}
	};
}
case ACTION_TYPES.REPORT_FUND_REQUEST_BANK_ACCOUNT_FAILURE: {
	const { error } = action.payload;
	return {
		...state,
		reportFundRequestBankAccount: {
			...state.reportFundRequestBankAccount,
			isFetching: false,
			hasError: true,
			error
		}
	};
}
case ACTION_TYPES.CLEAR_REPORT_FUND_REQUEST_BANK_ACCOUNT_REQUEST: {
	return {
		...state,
		reportFundRequestBankAccount: reportsReducerInitialState.reportFundRequestBankAccount
	};
}
// END  Report  Request Fund

	// Report Budget status generated - Report Credit Execution
	case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST: {
		return {
			...state,
			reportCreditExecutionGenerated: {
				...state.reportCreditExecutionGenerated,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.reportCreditExecutionGenerated.error
			}
		};
	}
	case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_SUCCESS: {
		return {
			...state,
			reportCreditExecutionGenerated: {
				...state.reportCreditExecutionGenerated,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportCreditExecutionGenerated.error
			}
		};
	}
	case ACTION_TYPES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED_REQUEST_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportCreditExecutionGenerated: {
				...state.reportCreditExecutionGenerated,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	case ACTION_TYPES.CLEAR_REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED: {
		return {
			...state,
			reportCreditExecutionGenerated: reportsReducerInitialState.reportCreditExecutionGenerated
		};
	}
	// END  Report  Request Fund

	// Report  New Request Fund
	case ACTION_TYPES.REPORT_NEW_FUND_REQUEST_REQUEST: {
		return {
			...state,
			reportNewFundRequest: {
				...state.reportNewFundRequest,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.reportNewFundRequest.error
			}
		};
	}
	case ACTION_TYPES.REPORT_NEW_FUND_REQUEST_REQUEST_SUCCESS: {
		return {
			...state,
			reportNewFundRequest: {
				...state.reportNewFundRequest,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportNewFundRequest.error
			}
		};
	}
	case ACTION_TYPES.REPORT_NEW_FUND_REQUEST_REQUEST_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportNewFundRequest: {
				...state.reportNewFundRequest,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	case ACTION_TYPES.CLEAR_REPORT_NEW_FUND_REQUEST_REQUEST: {
		return {
			...state,
			reportNewFundRequest: reportsReducerInitialState.reportNewFundRequest
		};
	}
	case ACTION_TYPES.START_LOADING: {
		return {
			...state,
			passiveWaste: {
				isFetching: true,
			}
		}
	}
	case ACTION_TYPES.STOP_LOADING: {
		return {
			...state,
			passiveWaste: {
				isFetching: false,
			}
		};
	}
	
	// END  Report  New Request Fund

	// CCPM connectivity status

	case ACTION_TYPES.GET_CONNECTIVITY_STATUS_REQUEST: {

		return {
			...state,
			ccpmConnectivityStatus: {
				...state.ccpmConnectivityStatus,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.ccpmConnectivityStatus.error
			}
		};
	}

	case ACTION_TYPES.GET_CONNECTIVITY_STATUS_REQUEST_SUCCESS: {
		const { data } = action.payload;

		return {
			...state,
			ccpmConnectivityStatus: {
				...state.ccpmConnectivityStatus,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.ccpmConnectivityStatus.error,
				data
			}
		};
	}

	case ACTION_TYPES.GET_CONNECTIVITY_STATUS_REQUEST_FAILURE: {
		const { error } = action.payload;
		
		return {
			...state,
			ccpmConnectivityStatus: {
				...state.ccpmConnectivityStatus,
				isFetching: false,
				hasError: true,
				data: reportsReducerInitialState.ccpmConnectivityStatus.data,
				error
			}
		};
	}
	// END CCPM connectivity status

	// Report Determination Passive Residues
	case ACTION_TYPES.REPORT_DETERMINATION_PASSIVE_RESIDUES: {
		return {
			...state,
			reportDeterminationPassiveResidues: {
				...state.reportDeterminationPassiveResidues,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.reportDeterminationPassiveResidues.error
			}
		};
	}
	case ACTION_TYPES.REPORT_DETERMINATION_PASSIVE_RESIDUES_SUCCESS: {
		return {
			...state,
			reportDeterminationPassiveResidues: {
				...state.reportDeterminationPassiveResidues,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportDeterminationPassiveResidues.error
			}
		};
	}
	case ACTION_TYPES.REPORT_DETERMINATION_PASSIVE_RESIDUES_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportDeterminationPassiveResidues: {
				...state.reportDeterminationPassiveResidues,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	case ACTION_TYPES.CLEAR_REPORT_DETERMINATION_PASSIVE_RESIDUES: {
		return {
			...state,
			reportDeterminationPassiveResidues: reportsReducerInitialState.reportDeterminationPassiveResidues
		};
	}
	// END Report Determination Passive Residues

	// Report Pending Resolution Passive Residues 
	case ACTION_TYPES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES: {
		return {
			...state,
			reportPendingResolutionPassiveResidues: {
				...state.reportPendingResolutionPassiveResidues,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.reportPendingResolutionPassiveResidues.error
			}
		};
	}
	case ACTION_TYPES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_SUCCESS: {
		return {
			...state,
			reportPendingResolutionPassiveResidues: {
				...state.reportPendingResolutionPassiveResidues,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportPendingResolutionPassiveResidues.error
			}
		};
	}
	case ACTION_TYPES.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportPendingResolutionPassiveResidues: {
				...state.reportPendingResolutionPassiveResidues,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	case ACTION_TYPES.CLEAR_REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES: {
		return {
			...state,
			reportPendingResolutionPassiveResidues: reportsReducerInitialState.reportPendingResolutionPassiveResidues
		};
	}
	// END Report Pending Resolution Passive Residues 

	// Report accrual basis
	case ACTION_TYPES.GET_ACCRUAL_BASIS_REPORT_REQUEST: {
		return {
			...state,
			reportAccrualBasis: {
				...state.reportAccrualBasis,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.reportAccrualBasis.error
			}
		};
	}
	case ACTION_TYPES.GET_ACCRUAL_BASIS_REPORT_REQUEST_SUCCESS: {
		return {
			...state,
			reportAccrualBasis: {
				...state.reportAccrualBasis,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportAccrualBasis.error
			}
		};
	}
	case ACTION_TYPES.GET_ACCRUAL_BASIS_REPORT_REQUEST_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportAccrualBasis: {
				...state.reportAccrualBasis,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	// END Report accrual basis

	// Report Compensation Direct download
	case ACTION_TYPES.REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST: {
		return {
			...state,
			reportCompensationDirectDownload: {
				...state.reportCompensationDirectDownload,
				isFetching: true,
				hasError: false,
				error: false
			}
		};
	}
	case ACTION_TYPES.REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_SUCCESS: {
		return {
			...state,
			reportCompensationDirectDownload: {
				...state.reportCompensationDirectDownload,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportCompensationDirectDownload.error
			}
		};
	}
	case ACTION_TYPES.REPORT_COMPENSATION_DIRECT_DOWNLOAD_REQUEST_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportCompensationDirectDownload: {
				...state.reportCompensationDirectDownload,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	// END Report Compensation Direct download

	// Report provision order
	case ACTION_TYPES.GET_REPORT_PROVISION_ORDER_REQUEST: {
		return {
			...state,
			reportProvisionOrder: {
				...state.reportProvisionOrder,
				isFetching: true,
				hasError: false,
				error: reportsReducerInitialState.reportProvisionOrder.error
			}
		};
	}
	case ACTION_TYPES.GET_REPORT_PROVISION_ORDER_REQUEST_SUCCESS: {
		return {
			...state,
			reportProvisionOrder: {
				...state.reportProvisionOrder,
				isFetching: false,
				hasError: false,
				error: reportsReducerInitialState.reportProvisionOrder.error
			}
		};
	}
	case ACTION_TYPES.GET_REPORT_PROVISION_ORDER_REQUEST_FAILURE: {
		const { error } = action.payload;
		return {
			...state,
			reportProvisionOrder: {
				...state.reportProvisionOrder,
				isFetching: false,
				hasError: true,
				error
			}
		};
	}
	// END Report provision order

		default: {
			return state;
		}
	}
};

export const getReportsReducer = state => state.reportsReducer;

export const getReportConsolidated = state => getReportsReducer(state)?.reportConsolidated;
export const getReportConsolidatedData = state => getReportConsolidated(state)?.data;
export const getReportConsolidatedIsFetching = state => getReportConsolidated(state)?.isFetching;

//Jurisdiction Analytic
export const getReportJurisdictionAnalytic = state => getReportsReducer(state)?.reportJurisdictionAnalytic;
export const getReportJurisdictionAnalyticData = state => getReportJurisdictionAnalytic(state)?.data;
export const getReportJurisdictionAnalyticIsFetching = state => getReportJurisdictionAnalytic(state)?.isFetching;

//Organism Analytic
export const getReportOrganismAnalytic = state => getReportsReducer(state)?.reportOrganismAnalytic;
export const getReportOrganismAnalyticData = state => getReportOrganismAnalytic(state)?.data;
export const getReportOrganismAnalyticIsFetching = state => getReportOrganismAnalytic(state)?.isFetching;

//Jurisdiction Total
export const getReportJurisdictionTotal = state => getReportsReducer(state)?.reportJurisdictionTotal;
export const getReportJurisdictionTotalData = state => getReportJurisdictionTotal(state)?.data;
export const getReportJurisdictionTotalIsFetching = state => getReportJurisdictionTotal(state)?.isFetching;

// Report Organization Total
export const getReportOrganizationTotal = state => getReportsReducer(state)?.reportOrganizationTotal;
export const getReportOrganizationTotalIsFetching = state => getReportOrganizationTotal(state)?.isFetching;
export const getReportOrganizationTotalHasError = state => getReportOrganizationTotal(state)?.hasError;
export const getReportOrganizationTotalError = state => getReportOrganizationTotal(state)?.error;

// Report Crossed
export const getReportCrossed = state => getReportsReducer(state)?.reportCrossed;
export const getReportCrossedIsFetching = state => getReportCrossed(state)?.isFetching;
export const getReportCrossedHasError = state => getReportCrossed(state)?.hasError;
export const getReportCrossedError = state => getReportCrossed(state)?.error;

// Report Insturment Legal Modify
export const getReportInstrumentLegalModify = state => getReportsReducer(state)?.reportInstrumentLegalModify;
export const getReportInstrumentLegalModifyIsFetching = state => getReportInstrumentLegalModify(state)?.isFetching;
export const getReportInstrumentLegalModifyHasError = state => getReportInstrumentLegalModify(state)?.hasError;
export const getReportInstrumentLegalModifyError = state => getReportInstrumentLegalModify(state)?.error;

// Report Insturment Legal Modify direct download
export const getReportInstrumentLegalModifyDirectDownload = state => getReportsReducer(state)?.reportInstrumentLegalModifyDirectDownload;
export const getReportInstrumentLegalModifyDirectDownloadIsFetching = state => getReportInstrumentLegalModifyDirectDownload(state)?.isFetching;
export const getReportInstrumentLegalModifyDirectDownloadHasError = state => getReportInstrumentLegalModifyDirectDownload(state)?.hasError;
export const getReportInstrumentLegalModifyDirectDownloadError = state => getReportInstrumentLegalModifyDirectDownload(state)?.error;

// Report affectations by administrative document
export const getReportAffectationsByAdministrativeDocument = state => getReportsReducer(state)?.reportAffectationsByAdministrativeDocument;
export const getReportAffectationsByAdministrativeDocumentIsFetching = state => getReportAffectationsByAdministrativeDocument(state)?.isFetching;
export const getReportAffectationsByAdministrativeDocumentHasError = state => getReportAffectationsByAdministrativeDocument(state)?.hasError;
export const getReportAffectationsByAdministrativeDocumentError = state => getReportAffectationsByAdministrativeDocument(state)?.error;

// Report affectations by administrative document
export const getReportAffectationsByAdministrativeDocumentDownload = state => getReportsReducer(state)?.reportAffectationsByAdministrativeDocumentDownload;
export const getReportAffectationsByAdministrativeDocumentDownloadIsFetching = state => getReportAffectationsByAdministrativeDocumentDownload(state)?.isFetching;
export const getReportAffectationsByAdministrativeDocumentHasDownloadError = state => getReportAffectationsByAdministrativeDocumentDownload(state)?.hasError;

// Report affectations by administrative document
export const getReportMovement = state => getReportsReducer(state)?.reportMovement;
export const getReportMovementIsFetching = state => getReportMovement(state)?.isFetching;
export const getReportMovementHasError = state => getReportMovement(state)?.hasError;
export const getReportMovementError = state => getReportMovement(state)?.error;

// Report Credit Modify - Report Credit Execution
export const getReportCreditModifyCreditExecution = state => getReportsReducer(state)?.reportCreditModifyCreditExecution;
export const getReportCreditModifyCreditExecutionIsFetching = state => getReportCreditModifyCreditExecution(state)?.isFetching;
export const getReportCreditModifyCreditExecutionHasError = state => getReportCreditModifyCreditExecution(state)?.hasError;
export const getReportCreditModifyCreditExecutionError = state => getReportCreditModifyCreditExecution(state)?.error;

// Report Table Totals - Report Credit Execution
export const getReportTableTotalsCreditExecution = state => getReportsReducer(state)?.reportTableTotalsCreditExecution;
export const getReportTableTotalsCreditExecutionIsFetching = state => getReportTableTotalsCreditExecution(state)?.isFetching;
export const getReportTableTotalsCreditExecutionHasError = state => getReportTableTotalsCreditExecution(state)?.hasError;
export const getReportTableTotalsCreditExecutionError = state => getReportTableTotalsCreditExecution(state)?.error;

// Report Budget status - Report Credit Execution
export const getReportBudgetStatusCreditExecution = state => getReportsReducer(state)?.reportBudgetStatusCreditExecution;
export const getReportBudgetStatusCreditExecutionIsFetching = state => getReportBudgetStatusCreditExecution(state)?.isFetching;
export const getReportBudgetStatusCreditExecutionHasError = state => getReportBudgetStatusCreditExecution(state)?.hasError;
export const getReportBudgetStatusCreditExecutionError = state => getReportBudgetStatusCreditExecution(state)?.error;

// Report  Works Budget Analytical Record - Report Credit Execution
export const getReportWorksBudgetAnalyticalRecordCreditExecution = state => getReportsReducer(state)?.reportWorksBudgetAnalyticalRecordCreditExecution;
export const getReportWorksBudgetAnalyticalRecordCreditExecutionIsFetching = state => getReportWorksBudgetAnalyticalRecordCreditExecution(state)?.isFetching;
export const getReportWorksBudgetAnalyticalRecordCreditExecutionHasError = state => getReportWorksBudgetAnalyticalRecordCreditExecution(state)?.hasError;
export const getReportWorksBudgetAnalyticalRecordCreditExecutionError = state => getReportWorksBudgetAnalyticalRecordCreditExecution(state)?.error;

// Report Movements Status - Report Credit Execution
export const getReportMovementsStatusCreditExecution = state => getReportsReducer(state)?.reportMovementsStatusCreditExecution;
export const getReportMovementsStatusCreditExecutionIsFetching = state => getReportMovementsStatusCreditExecution(state)?.isFetching;
export const getReportMovementsStatusCreditExecutionHasError = state => getReportMovementsStatusCreditExecution(state)?.hasError;
export const getReportMovementsStatusCreditExecutionError = state => getReportMovementsStatusCreditExecution(state)?.error;

// Report balance status payment list - Report Credit Execution
export const getReportBalanceStatusPaymentListCreditExecution = state => getReportsReducer(state)?.reportBalanceStatusPaymentListCreditExecution;
export const getReportBalanceStatusPaymentListCreditExecutionIsFetching = state => getReportBalanceStatusPaymentListCreditExecution(state)?.isFetching;
export const getReportBalanceStatusPaymentListCreditExecutionHasError = state => getReportBalanceStatusPaymentListCreditExecution(state)?.hasError;
export const getReportBalanceStatusPaymentListCreditExecutionError = state => getReportBalanceStatusPaymentListCreditExecution(state)?.error;

// Report Budget Analytical Accounting - Report Credit Execution
export const getReportBudgetAnalyticalAccountingCreditExecution = state => getReportsReducer(state)?.reportBudgetAnalyticalAccountingCreditExecution;
export const getReportBudgetAnalyticalAccountingCreditExecutionIsFetching = state => getReportBudgetAnalyticalAccountingCreditExecution(state)?.isFetching;
export const getReportBudgetAnalyticalAccountingCreditExecutionHasError = state => getReportBudgetAnalyticalAccountingCreditExecution(state)?.hasError;
export const getReportBudgetAnalyticalAccountingCreditExecutionError = state => getReportBudgetAnalyticalAccountingCreditExecution(state)?.error;


// Report Balance status
export const getReportBalanceStatusExecution = state => getReportsReducer(state)?.reportBalanceStatus;
export const getReportBalanceStatusExecutionIsFetching = state => getReportBalanceStatusExecution(state)?.isFetching;


// Report Delayed Files - Report Credit Execution
export const getReportDelayedFilesCreditExecution = state => getReportsReducer(state)?.reportDelayedFilesCreditExecution;
export const getReportDelayedFilesCreditExecutionIsFetching = state => getReportDelayedFilesCreditExecution(state)?.isFetching;
export const getReportDelayedFilesCreditExecutionHasError = state => getReportDelayedFilesCreditExecution(state)?.hasError;
export const getReportDelayedFilesCreditExecutionError = state => getReportDelayedFilesCreditExecution(state)?.error;

//Report 

// Report Budget status generated - Report Credit Execution
export const getReportBudgetStatusCreditExecutionGenerated = state => getReportsReducer(state)?.reportCreditExecutionGenerated;
export const getReportBudgetStatusCreditExecutionGeneratedData = state => getReportBudgetStatusCreditExecutionGenerated(state)?.data;
export const getReportBudgetStatusCreditExecutionGeneratedIsFetching = state => getReportBudgetStatusCreditExecutionGenerated(state)?.isFetching;
export const getReportBudgetStatusCreditExecutionGeneratedHasError = state => getReportBudgetStatusCreditExecutionGenerated(state)?.hasError;
export const getReportBudgetStatusCreditExecutionGeneratedError = state => getReportBudgetStatusCreditExecutionGenerated(state)?.error;


// Report Budget status generated List - Report Credit Execution
export const getReportBudgetStatusCreditExecutionGeneratedList = state => getReportsReducer(state)?.creditExecutonGeneratedList;
export const getReportBudgetStatusCreditExecutionGeneratedListData = state => getReportBudgetStatusCreditExecutionGeneratedList(state)?.data;
export const getReportBudgetStatusCreditExecutionGeneratedListIsFetching = state => getReportBudgetStatusCreditExecutionGeneratedList(state)?.isFetching;
export const getReportBudgetStatusCreditExecutionGeneratedListHasError = state => getReportBudgetStatusCreditExecutionGeneratedList(state)?.hasError;
export const getReportBudgetStatusCreditExecutionGeneratedListError = state => getReportBudgetStatusCreditExecutionGeneratedList(state)?.error;

// Report delivery orders - Report Request Fund
export const getReportDeliveryOrdersFundRequest = state => getReportsReducer(state)?.reportDeliveryOrdersFundRequest;
export const getReportDeliveryOrdersFundRequestIsFetching = state => getReportDeliveryOrdersFundRequest(state)?.isFetching;
export const getReportDeliveryOrdersFundRequestHasError = state => getReportDeliveryOrdersFundRequest(state)?.hasError;
export const getReportDeliveryOrdersFundRequestError = state => getReportDeliveryOrdersFundRequest(state)?.error;

// Report delivery orders - Report Request Fund
export const getReportPaymentOrderAdministrativeServiceFundRequest = state => getReportsReducer(state)?.reportPaymentOrderAdministrativeServiceFundRequest;
export const getReportPaymentOrderAdministrativeServiceFundRequestIsFetching = state => getReportPaymentOrderAdministrativeServiceFundRequest(state)?.isFetching;
export const getReportPaymentOrderAdministrativeServiceFundRequestHasError = state => getReportPaymentOrderAdministrativeServiceFundRequest(state)?.hasError;
export const getReportPaymentOrderAdministrativeServiceFundRequestError = state => getReportPaymentOrderAdministrativeServiceFundRequest(state)?.error;

// Report service direction - Report Request Fund
export const getReportServiceDirectionFundRequest = state => getReportsReducer(state)?.reportReportServiceDirectionFundRequest;
export const getReportServiceDirectionFundRequestIsFetching = state => getReportServiceDirectionFundRequest(state)?.isFetching;
export const getReportServiceDirectionFundRequestHasError = state => getReportServiceDirectionFundRequest(state)?.hasError;
export const getReportServiceDirectionFundRequestError = state => getReportServiceDirectionFundRequest(state)?.error;

// Report detail service direction - Report Request Fund
export const getReportDetailServiceDirectionFundRequest = state => getReportsReducer(state)?.reportDetailServiceDirectionFundRequest;
export const getReportDetailServiceDirectionFundRequestIsFetching = state => getReportDetailServiceDirectionFundRequest(state)?.isFetching;
export const getReportDetailServiceDirectionFundRequestHasError = state => getReportDetailServiceDirectionFundRequest(state)?.hasError;
export const getReportDetailServiceDirectionFundRequestError = state => getReportDetailServiceDirectionFundRequest(state)?.error;

// Report service direction - Report Request Fund
export const getReportFundRequest = state => getReportsReducer(state)?.reportFundRequest;
export const getReportFundRequestIsFetching = state => getReportFundRequest(state)?.isFetching;
export const getReportFundRequestHasError = state => getReportFundRequest(state)?.hasError;
export const getReportFundRequestError = state => getReportFundRequest(state)?.error;

// Report provider - Report Request Fund
export const getReportProviderFundRequest = state => getReportsReducer(state)?.reportReportProviderFundRequest;
export const getReportProviderFundRequestIsFetching = state => getReportProviderFundRequest(state)?.isFetching;
export const getReportProviderFundRequestHasError = state => getReportProviderFundRequest(state)?.hasError;
export const getReportProviderFundRequestError = state => getReportProviderFundRequest(state)?.error;

// Report provider - Report Request Fund
export const getReportBankAccountFundRequest = state => getReportsReducer(state)?.reportFundRequestBankAccount;
export const getReportBankAccountFundRequestIsFetching = state => getReportBankAccountFundRequest(state)?.isFetching;
export const getReportBankAccountFundRequestHasError = state => getReportBankAccountFundRequest(state)?.hasError;
export const getReportBankAccountFundRequestError = state => getReportBankAccountFundRequest(state)?.error;

// Report New Request Fund - Report Request Fund
export const getReportNewFundRequest = state => getReportsReducer(state)?.reportNewFundRequest;
export const getReportNewFundRequestIsFetching = state => getReportNewFundRequest(state)?.isFetching;
export const getReportNewFundRequestHasError = state => getReportNewFundRequest(state)?.hasError;
export const getReportNewFundRequestError = state => getReportNewFundRequest(state)?.error;

export const getpassiveWasteRequest = state => getReportsReducer(state)?.passiveWaste;
export const getReportpassiveWasteIsFetching = state => getpassiveWasteRequest(state)?.isFetching;

// CCPM connectivity status
export const getCCPMConnectivityStatus = state => getReportsReducer(state)?.ccpmConnectivityStatus;
export const getCCPMConnectivityStatusData = state => getCCPMConnectivityStatus(state)?.data;
export const getCCPMConnectivityStatusIsFetching = state => getCCPMConnectivityStatus(state)?.isFetching;

// Report Determination Passive Residues
export const getReportDeterminationPassiveResidues = state => getReportsReducer(state)?.reportDeterminationPassiveResidues;
export const getReportDeterminationPassiveResiduesData = state => getReportDeterminationPassiveResidues(state)?.data;
export const getReportDeterminationPassiveResiduesIsFetching = state => getReportDeterminationPassiveResidues(state)?.isFetching;
export const getReportDeterminationPassiveResiduesHasError = state => getReportDeterminationPassiveResidues(state)?.hasError;
export const getReportDeterminationPassiveResiduesError = state => getReportDeterminationPassiveResidues(state)?.error;

// Report Pending Resolution Passive Residues 
export const getReportPendingResolutionPassiveResidues = state => getReportsReducer(state)?.reportPendingResolutionPassiveResidues;
export const getReportPendingResolutionPassiveResiduesData = state => getReportPendingResolutionPassiveResidues(state)?.data;
export const getReportPendingResolutionPassiveResiduesIsFetching = state => getReportPendingResolutionPassiveResidues(state)?.isFetching;
export const getReportPendingResolutionPassiveResiduesHasError = state => getReportPendingResolutionPassiveResidues(state)?.hasError;
export const getReportPendingResolutionPassiveResiduesError = state => getReportPendingResolutionPassiveResidues(state)?.error;

// Report Monthly Accrual Basis
export const getReportAccrualBasis = state => getReportsReducer(state)?.reportAccrualBasis;
export const getReportAccrualBasisIsFetching = state => getReportAccrualBasis(state)?.isFetching;
export const getReportAccrualBasisHasError = state => getReportAccrualBasis(state)?.hasError;
export const getReportAccrualBasisError = state => getReportAccrualBasis(state)?.error;

// Report Compensation direct download
export const getReportCompensationDirectDownload = state => getReportsReducer(state)?.reportCompensationDirectDownload;
export const getReportCompensationDirectDownloadIsFetching = state => getReportCompensationDirectDownload(state)?.isFetching;
export const getReportCompensationDirectDownloadHasError = state => getReportCompensationDirectDownload(state)?.hasError;
export const getReportCompensationDirectDownloadError = state => getReportCompensationDirectDownload(state)?.error;

export const getReportProvisionOrder = state => getReportsReducer(state)?.reportProvisionOrder;
export const getReportProvisionOrderIsFetching = state => getReportProvisionOrder(state)?.isFetching;
export const getReportProvisionOrderHasError = state => getReportProvisionOrder(state)?.hasError;
export const getReportProvisionOrderError = state => getReportProvisionOrder(state)?.error;
