import * as ACTIONS from './sectorActions';
import * as SECTION_SERVICES from 'src/services/sectionServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetSectorsListBySectionId = sectionId => {
	return  async ( dispatch, getState ) => {
		if( !sectionId ) {
			return;
		}

		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getSectorsListBySectionIdRequest( sectionId ) );

		if(accessToken){
			
			const response = await SECTION_SERVICES.listSectorsBySectionId( accessToken, sectionId );
			
			if( response?.status === 200 ) {
				dispatch( ACTIONS.getSectorsListBySectionIdRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getSectorsListBySectionIdRequestFailure(response?.error) );
				showError(`Error al cargar los sectores. Detalle: ${response?.error}` );
			}
		}

	};
};