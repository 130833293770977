import * as ACTION_TYPES from './legalInstrumentActionTypes';

export const legalInstrumentReducerInitialState = {
	listLegalInstrument: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	createLegalInstrument: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	downloadFileOfLegalInstrument: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	uploadFileToLegalInstrument: {
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const legalInstrumentReducer = ( state=legalInstrumentReducerInitialState, action ) => {
	switch( action.type ) {

		// List legal instrument
		case ACTION_TYPES.LIST_LEGAL_INSTRUMENT_REQUEST: {
			return {
				...state,
				listLegalInstrument: {
					...state.listLegalInstrument,
					isFetching: true,
					hasError: false,
					error: legalInstrumentReducerInitialState.listLegalInstrument.error,
					data: legalInstrumentReducerInitialState.listLegalInstrument.data
				}
			};
		}

		case ACTION_TYPES.LIST_LEGAL_INSTRUMENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listLegalInstrument: {
					...state.listLegalInstrument,
					isFetching: false,
					hasError: false,
					error: legalInstrumentReducerInitialState.listLegalInstrument.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_LEGAL_INSTRUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listLegalInstrument: {
					...state.listLegalInstrument,
					isFetching: false,
					hasError: true,
					data: legalInstrumentReducerInitialState.listLegalInstrument.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_LEGAL_INSTRUMENT: {
			return {
				...state,
				listLegalInstrument: legalInstrumentReducerInitialState.listLegalInstrument
			};
		}
		// END List legal instrument



		// Create legal instrument
		case ACTION_TYPES.CREATE_LEGAL_INSTRUMENT_REQUEST: {
			return {
				...state,
				createLegalInstrument: {
					...state.createLegalInstrument,
					isFetching: true,
					hasError: false,
					error: legalInstrumentReducerInitialState.createLegalInstrument.error
				}
			};
		}

		case ACTION_TYPES.CREATE_LEGAL_INSTRUMENT_REQUEST_SUCCESS: {
			return {
				...state,
				createLegalInstrument: {
					...state.createLegalInstrument,
					isFetching: false,
					hasError: false,
					error: legalInstrumentReducerInitialState.createLegalInstrument.error,
				}
			};
		}

		case ACTION_TYPES.CREATE_LEGAL_INSTRUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				createLegalInstrument: {
					...state.createLegalInstrument,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Create legal instrument



		// Download file of legal instrument
		case ACTION_TYPES.DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST: {
			return {
				...state,
				downloadFileOfLegalInstrument: {
					...state.downloadFileOfLegalInstrument,
					isFetching: true,
					hasError: false,
					error: legalInstrumentReducerInitialState.downloadFileOfLegalInstrument.error
				}
			};
		}

		case ACTION_TYPES.DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_SUCCESS: {
			return {
				...state,
				downloadFileOfLegalInstrument: {
					...state.downloadFileOfLegalInstrument,
					isFetching: false,
					hasError: false,
					error: legalInstrumentReducerInitialState.downloadFileOfLegalInstrument.error,
				}
			};
		}

		case ACTION_TYPES.DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				downloadFileOfLegalInstrument: {
					...state.downloadFileOfLegalInstrument,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Download file of legal instrument



		// Upload file to legal instrument
		case ACTION_TYPES.UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST: {
			return {
				...state,
				uploadFileToLegalInstrument: {
					...state.uploadFileToLegalInstrument,
					isFetching: true,
					hasError: false,
					error: legalInstrumentReducerInitialState.uploadFileToLegalInstrument.error
				}
			};
		}

		case ACTION_TYPES.UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_SUCCESS: {
			return {
				...state,
				uploadFileToLegalInstrument: {
					...state.uploadFileToLegalInstrument,
					isFetching: false,
					hasError: false,
					error: legalInstrumentReducerInitialState.uploadFileToLegalInstrument.error,
				}
			};
		}

		case ACTION_TYPES.UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				uploadFileToLegalInstrument: {
					...state.uploadFileToLegalInstrument,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Upload file to legal instrument



		default: {
			return state;
		}
	}
};

export const getLegalInstrumentReducer = state => state.legalInstrumentReducer;

// List legal instrument getters
export const getLegalInstrumentList = state => getLegalInstrumentReducer(state)?.listLegalInstrument;
export const getLegalInstrumentListData = state => getLegalInstrumentList(state)?.data;
export const getLegalInstrumentListIsFetching = state => getLegalInstrumentList(state)?.isFetching;
export const getLegalInstrumentListHasError = state => getLegalInstrumentList(state)?.hasError;
export const getLegalInstrumentListError = state => getLegalInstrumentList(state)?.error;

// Create legal instrument getters
export const getLegalInstrumentCreate = state => getLegalInstrumentReducer(state)?.createLegalInstrument;
export const getLegalInstrumentCreateIsFetching = state => getLegalInstrumentCreate(state)?.isFetching;
export const getLegalInstrumentCreateHasError = state => getLegalInstrumentCreate(state)?.hasError;
export const getLegalInstrumentCreateError = state => getLegalInstrumentCreate(state)?.error;

// Download file of legal instrument getters
export const getLegalInstrumentDownloadFile = state => getLegalInstrumentReducer(state)?.downloadFileOfLegalInstrument;
export const getLegalInstrumentDownloadFileIsFetching = state => getLegalInstrumentDownloadFile(state)?.isFetching;
export const getLegalInstrumentDownloadFileHasError = state => getLegalInstrumentDownloadFile(state)?.hasError;
export const getLegalInstrumentDownloadFileError = state => getLegalInstrumentDownloadFile(state)?.error;

// Upload file to legal instrument getters
export const getLegalInstrumentUploadFile = state => getLegalInstrumentReducer(state)?.uploadFileToLegalInstrument;
export const getLegalInstrumentUploadFileIsFetching = state => getLegalInstrumentUploadFile(state)?.isFetching;
export const getLegalInstrumentUploadFileHasError = state => getLegalInstrumentUploadFile(state)?.hasError;
export const getLegalInstrumentUploadFileError = state => getLegalInstrumentUploadFile(state)?.error;