import * as ACTION_TYPES from './maintenanceSystemActionTypes';

// Post maintenanceSystem
export const postMaintenanceSystemRequest = data => ({
	type: ACTION_TYPES.POST_MAINTENANCE_SYSTEM_REQUEST,
	payload: {
		data
	}
});

export const postMaintenanceSystemRequestSuccess = response => ({
	type: ACTION_TYPES.POST_MAINTENANCE_SYSTEM_REQUEST_SUCCESS,
	payload: {
		response
	}
});

export const postMaintenanceSystemRequestFailure = error => ({
	type: ACTION_TYPES.POST_MAINTENANCE_SYSTEM_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Post maintenanceSystem