import React, { useState } from 'react';
import { Pagination, Form, Button, Row, Col, InputGroup, Container } from 'react-bootstrap';

const RCPagination = ({ totalItemsCount, itemsCountPerPage, thePage, onChange }) => {

    const [numberPage, setNumberPage] = useState('');

    const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);
    const paginas = `Página: ${thePage} de ${totalPages}`;
    const TotalContenido = `Total Ítems: ${totalItemsCount}`;

    const onChangeNumberPage = (e) => {
        const pageNum = parseInt(e.target.value, 10);
        if (pageNum >= 1 && pageNum <= totalPages) {
            setNumberPage(pageNum);
        } else if (e.target.value == ''){
            setNumberPage('');
        }
    };

    const onClickNumberPage = () => {
        onChange(numberPage);
    };

    // Determinar el inicio y fin del rango de páginas a mostrar
    const pageNeighbours = 2; // Cantidad de páginas vecinas a mostrar alrededor de la página actual
    let startPage = Math.max(1, thePage - pageNeighbours);
    let endPage = Math.min(totalPages, thePage + pageNeighbours);

    // Ajustar el inicio y el fin si cerca del inicio o del final
    const totalNumbers = pageNeighbours * 2 + 1; // Total de números a mostrar
    if (thePage <= 3) {
        endPage = Math.min(totalPages, startPage + totalNumbers - 1);
    }
    if (thePage > totalPages - 3) {
        startPage = Math.max(1, totalPages - totalNumbers + 1);
    }

    let items = [];
    
    // Antes del bucle que genera los botones, agrego los de las páginas "anterior" y "primera"
    items.push(
        <Pagination.First key="first" onClick={() => onChange(1)} disabled={thePage === 1} />,
        <Pagination.Prev key="prev" onClick={() => onChange(Math.max(1, thePage - 1))} disabled={thePage === 1} />
    );

    // Generar los botones de paginación para el rango de 5 calculado
    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number === thePage} onClick={() => onChange(number)}>
                {number}
            </Pagination.Item>,
        );
    };

    // Después del bucle que genera los botones, agrego los de las páginas "siguiente" y "última"
    items.push(
        <Pagination.Next key="next" onClick={() => onChange(Math.min(totalPages, thePage + 1))} disabled={thePage === totalPages} />,
        <Pagination.Last key="last" onClick={() => onChange(totalPages)} disabled={thePage === totalPages} />
    );

    return (
        <Container className='mt-3'>
            <Row>
                <Col xs={12} md={6}>
                    <Pagination className="justify-content-center">{items}</Pagination>
                </Col>
                <Col xs={12} md={2}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Pág.</InputGroup.Text>
                        <Form.Control
                            type="number"
                            value={numberPage}
                            onChange={onChangeNumberPage}
                        />
                        <Button variant="outline-secondary" onClick={onClickNumberPage}>Ir</Button>
                    </InputGroup>
                </Col>
                <Col xs={12} md={4}>
                    <InputGroup.Text>{paginas} - {TotalContenido}</InputGroup.Text>
                </Col>
            </Row>
        </Container>
    );
};

export default RCPagination;
