import * as ACTION_TYPES from './accountActionTypes';

export const accountReducerInitialState = {
	accountList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const accountReducer = ( state=accountReducerInitialState, action ) => {
	switch( action.type ) {

		case ACTION_TYPES.LIST_ACCOUNT_REQUEST: {
			return {
				...state,
				accountList: {
					...state.accountList,
					isFetching: true,
					hasError: false,
					error: accountReducerInitialState.accountList.error,
					data: accountReducerInitialState.accountList.data
				}
			};
		}
		case ACTION_TYPES.LIST_ACCOUNT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				accountList: {
					...state.accountList,
					isFetching: false,
					hasError: false,
					error: accountReducerInitialState.accountList.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ACCOUNT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				accountList: {
					...state.accountList,
					isFetching: false,
					hasError: true,
					data: accountReducerInitialState.accountList.data,
					error
				}
			};
		}

		case ACTION_TYPES.CLEAR_ACCOUNT_LIST: {
			return {
				...state,
				accountList: accountReducerInitialState.accountList
			};
		}

		default: {
			return state;
		}
	}
};

export const getAccountReducer = state => state.accountReducer;

export const getAccountList = state => getAccountReducer(state)?.accountList;
export const getAccountListData = state => getAccountList(state)?.data;
export const getAccountListIsFetching = state => getAccountList(state)?.isFetching;
export const getAccountListHasError = state => getAccountList(state)?.hasError;
export const getAccountListError = state => getAccountList(state)?.error;