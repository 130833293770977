import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import { useProfileChecks } from './hooks/useProfileChecks';

const MenuFinancialExecution = () => {

    const dispatch = useDispatch();

    const {
        profileName,
        bankAccountsPermissions,
        fundRequestsPermissions,
        deliveryOrdersPermissions,
        paramsToAlerts
    } = usePermissions();

    const {
        isProfileNameSA,
        isProfileNameAI,
        isProfileBudgetDirection,
    } = useProfileChecks();

    if (!bankAccountsPermissions?.canView || !fundRequestsPermissions?.canView || !deliveryOrdersPermissions?.canView) return null;

    return (
        <NavDropdown
            className='active'
            title={<><FontAwesomeIcon icon={faFileInvoiceDollar} className='mr-1' />Ejecución financiera</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {bankAccountsPermissions?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.BANK_ACCOUNT_LIST} title="Cuentas Bancarias" />
            }
            {fundRequestsPermissions?.canView &&
                <NavDropdownItem route={ROUTE_PATHS.FUND_REQUESTS_LIST} title="Pedido fondo" />
            }
            {deliveryOrdersPermissions?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.DELIVERY_ORDER_LIST} title="Órdenes entrega" />
            }
            {(isProfileNameSA || isProfileBudgetDirection || isProfileNameAI) &&
                <DropdownSubmenu href="" title="Configuración Financiera">
                    {(isProfileNameSA || isProfileBudgetDirection || isProfileNameAI) &&
                        <NavDropdownItem route={ROUTE_PATHS.FIGURATIVE_EXPEDITURES} title="• Erogaciones figurativas" />
                    }
                </DropdownSubmenu>
            }
        </NavDropdown>
    );
};

export default MenuFinancialExecution;