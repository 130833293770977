import * as ACTION_TYPES from './preloadActionTypes';

//Delete Preload
export const deletePreloadRequest = () => ({
	type: ACTION_TYPES.DELETE_PRELOAD_REQUEST
});

export const deletePreloadRequestSuccess = data => ({
	type: ACTION_TYPES.DELETE_PRELOAD_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const deletePreloadRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_PRELOAD_REQUEST_FAILURE,
	payload: {
		error
	}
});

// Get Preload actions
export const getPreloadDataRequest = () => ({
	type: ACTION_TYPES.GET_PRELOAD_DATA_REQUEST
});

export const getPreloadDataRequestSuccess = data => ({
	type: ACTION_TYPES.GET_PRELOAD_DATA_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getPreloadDataRequestFailure = error => ({
	type: ACTION_TYPES.GET_PRELOAD_DATA_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearPreloadData = () => ({
	type: ACTION_TYPES.CLEAR_PRELOAD_DATA
});
// END Get Preload actions

//Edit Preload actions
export const editPreloadRequest = () => ({
	type: ACTION_TYPES.EDIT_PRELOAD_REQUEST
});

export const editPreloadRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_PRELOAD_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editPreloadRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_PRELOAD_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Edit Preload actions

// Attach legalInstrument to Preload actions
export const getAttachLegalInstrumentToPreloadRequest = () => ({
	type: ACTION_TYPES.ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST
});

export const getAttachLegalInstrumentToPreloadRequestSuccess = (data) => ({
	type: ACTION_TYPES.ATTACH_LEGAL_INSTRUMENT_REQUESTS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAttachLegalInstrumentToPreloadRequestFailure = error => ({
	type: ACTION_TYPES.ATTACH_LEGAL_INSTRUMENT_REQUESTS_FAILURE,
	payload: {
		error
	}
});

export const clearAttachLegalInstrumentToPreloadData = () => ({
	type: ACTION_TYPES.CLEAR_ATTACH_LEGAL_INSTRUMENT_DATA
});
// END Add legalInstrument to Preload actions

// Get preload XLS file actions
export const getFileSubcodeRequest = () => ({
	type: ACTION_TYPES.GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST
});

export const getFilePreloadRequestSuccess = (data) => ({
	type: ACTION_TYPES.GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getFilePreloadRequestFailure = (error) => ({
	type: ACTION_TYPES.GET_PRELOAD_DOWNLOAD_XLS_FILE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearPreloadXLS = () => ({
	type: ACTION_TYPES.CLEAR_PRELOAD_DOWNLOAD_XLS_FILE_DATA
});
// END Get preload XLS file actions

// Get preload report
export const getPreloadReportRequest = () => ({
	type: ACTION_TYPES.GET_PRELOAD_REPORT_REQUEST
});

export const getPreloadReportRequestSuccess = (data) => ({
	type: ACTION_TYPES.GET_PRELOAD_REPORT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getPreloadReportRequestFailure = (error) => ({
	type: ACTION_TYPES.GET_PRELOAD_REPORT_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Get preload report