import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { Button, Modal, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';

import { Xls, Pdf, generate } from 'src/utils/label';

import { getReportInstrumentLegalModifyDirectDownloadIsFetching } from 'src/redux/reports/reportsReducer';

import {ROUTE_PATHS as ROUTES}  from 'src/routes';

const LegalInstrumentDownloadModal = (props) => {

    const dispatch = useDispatch();

    const { register, getValues } = useForm();

    const { show, handleClose, dataModal, onClickPDF, onClickXLS } = props;

    const optionByFilter = [{ "id": "1", "name": "Número de partida", "value": "budget" }, { "id": "2", "name": "Fecha", "value": "createAt" }]

    const reportInstrumentLegalModifyIsFetching = useSelector(state => getReportInstrumentLegalModifyDirectDownloadIsFetching(state));

    const downloadPDF = () => {

        const value = getValues('optionSelectedName');

        const paramsToSend = {
            ...dataModal,
            orderBy: value
        };

        onClickPDF(paramsToSend);
    };

    const downloadXLS = () => {

        const value = getValues('optionSelectedName');

        const paramsToSend = {
            ...dataModal,
            orderBy: value
        };

        onClickXLS(paramsToSend);
    };

    const handleCloseModal = () => {
        handleClose();
    };

    return <Modal show={show} onHide={handleCloseModal} dialogClassName="modal-lg">

        <Modal.Header closeButton>
            <Modal.Title>Descargar</Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <Form className='mt-2'>
                <Form.Group as={Row}>
                    <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                        Ordenar por:
                    </Form.Label>
                    <Col sm='4'>
                        <Form.Control
                            as="select"
                            name='optionSelectedName'
                            ref={register}
                            className="text-black-color"
                        >
                            {optionByFilter.map(item =>
                                <option value={item?.value} key={item?.id}>
                                    {item?.name}
                                </option>
                            )}
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group className='d-flex justify-content-center mt-2 mb-2'>
                    {reportInstrumentLegalModifyIsFetching ?
                        <Spinner
                            animation='border'
                            className={'spinner-border text-danger'}
                        />
                        :
                        <>
                            <Button className='m-1' onClick={downloadPDF} size='lg'>
                                <FontAwesomeIcon icon={faFilePdf} className='mr-2' />
                                Descargar {Pdf}
                            </Button>
                            <Button className='m-1' onClick={downloadXLS} size='lg'>
                                <FontAwesomeIcon icon={faFileExcel} className='mr-2' />
                                Descargar {Xls}
                            </Button>

                        </>
                    }
                </Form.Group>
            </Form>

        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
                Cerrar
            </Button>
        </Modal.Footer>

    </Modal>
}

export default LegalInstrumentDownloadModal;