import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from './app'
import configureStore, { history } from "src/redux/store";
import './styles/Custom.scss';

if (process.env.NODE_ENV !== 'production') {
	console.log('DEVELOPMENT MODE');
} else {
	console.log('PRODUCTION MODE');
}

const store = configureStore();

const render = () => {
	ReactDOM.render(
		<Provider store={store}>
			<App history={history} />
		</Provider>,
		document.getElementById("root")
	)
}

render();