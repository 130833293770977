// List legal instrument
export const LIST_LEGAL_INSTRUMENT_REQUEST = 'LIST_LEGAL_INSTRUMENT_REQUEST';
export const LIST_LEGAL_INSTRUMENT_REQUEST_SUCCESS = 'LIST_LEGAL_INSTRUMENT_REQUEST_SUCCESS';
export const LIST_LEGAL_INSTRUMENT_REQUEST_FAILURE = 'LIST_LEGAL_INSTRUMENT_REQUEST_FAILURE';
export const CLEAR_LIST_LEGAL_INSTRUMENT = 'CLEAR_LIST_LEGAL_INSTRUMENT';

// Create legal instrument
export const CREATE_LEGAL_INSTRUMENT_REQUEST = 'CREATE_LEGAL_INSTRUMENT_REQUEST';
export const CREATE_LEGAL_INSTRUMENT_REQUEST_SUCCESS = 'CREATE_LEGAL_INSTRUMENT_REQUEST_SUCCESS';
export const CREATE_LEGAL_INSTRUMENT_REQUEST_FAILURE = 'CREATE_LEGAL_INSTRUMENT_REQUEST_FAILURE';

// Download file of legal instrument
export const DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST = 'DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST';
export const DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_SUCCESS = 'DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_SUCCESS';
export const DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_FAILURE = 'DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_FAILURE';

// Upload file to legal instrument
export const UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST = 'UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST';
export const UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_SUCCESS = 'UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_SUCCESS';
export const UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_FAILURE = 'UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_FAILURE';