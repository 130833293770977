import React from 'react';
import { config } from 'src/env.js';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Form, Button, Spinner } from 'react-bootstrap';

import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isNotEmptyArray, isNotEmptyObject } from 'src/services/validationService';
import PageTitle from 'src/components/general/PageTitle';
import { impactsBudgetItems } from 'src/utils/label';
import StepIndicator from 'src/components/common/StepIndicator';

import { BALANCE_TRANSFER_STEP_TWO } from '../../../utils/constants';

const BalanceTransferStepThree = () => {

    const dispatch = useDispatch();
	const hookFormMethods = useForm();

    // Administrative document data
    const administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state)); console.log("Expediente: ", administrativeDocumentData)

    // Credits
    const selectedSubcodes = useSelector(state => getNewAffectationSelectedCredits(state))?.map(item => {
        if (!item?.hasOwnProperty('affectationData')) {
            item.affectationData = {
                affectationType: undefined,
                subpartialBudget: undefined,
                date: undefined,
                amount: undefined,
                feeNumber: undefined
            };
        }
        return item;
    }); console.log("Partidas seleccionadas en el paso 2: ", selectedSubcodes);
    const hasSelectedSubcodes = isNotEmptyArray(selectedSubcodes);
    const numberOfSelectedSubcodes = selectedSubcodes?.length;

    const onClickBackButton = () => { dispatch(push(BALANCE_TRANSFER_STEP_TWO)) };

    const onClickSubmitButton = () => {
        console.log("finalizar");
    };

    return (
        <Card className='mb-5'>
            <PageTitle text={impactsBudgetItems} />
            <Container fluid >
                <StepIndicator steps={config.appSettings.steps.balanceTransfer} current={2} className='mt-2 mb-3' />
                <Card className='mb-3'>
                    <Card.Header className='h6'>
                        Traspaso de Saldos
                    </Card.Header>
                    <Card.Body>
                        <FormProvider {...hookFormMethods} >
                            <Form>
                                <Form.Group className='mt-4 d-flex justify-content-around'>
                                    <Button variant='danger' size='lg' onClick={onClickBackButton}>
                                        <FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
                                        Volver
                                    </Button>
                                    <Button variant='success' size='lg' onClick={onClickSubmitButton} >
                                        Siguiente
                                        <FontAwesomeIcon className='ml-3' icon={faArrowRight} />
                                    </Button>
                                </Form.Group>
                            </Form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            </Container>
        </Card>
    )
};

export default BalanceTransferStepThree;