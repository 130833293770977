import { config } from 'src/env.js';

import { httpGet } from './httpServices'

// GET VersionFundsRequestsApi
const FUNDS_REQUESTS_API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const GET_VERSION_FUNDS_REQUESTS_API = `${FUNDS_REQUESTS_API_BASE_URI}/actuator/info`;

export const getVersionFundsRequestsApiService = async() => {
	let response;
	
	try {
		response = await httpGet(GET_VERSION_FUNDS_REQUESTS_API);
	}
	catch( error ) {
		response = error?.response;
	}
	
	return response;
};

// GET VersionCoreApi
const CORE_API_BASE_URI = `${config.apis.coreApi.URL}`;
const GET_VERSION_CORE_API = `${CORE_API_BASE_URI}/actuator/info`;

export const getVersionCoreApiService = async() => {
	let response;
	
	try {
		response = await httpGet(GET_VERSION_CORE_API);
	}
	catch( error ) {
		response = error?.response;
	}
	
	return response;
};

// GET VersionPresupuestoApi
const PRESUPUESTO_API_BASE_URI = `${config.apis.presupuestoApi.VERSION}`;

export const getVersionPresupuestoApiService = async() => {
	let response;
	
	try {
		response = await httpGet(PRESUPUESTO_API_BASE_URI);
	}
	catch( error ) {
		response = error?.response;
	}
	
	return response;
};

// GET VersionAuthApi
const AUTH_API_BASE_URI = `${config.apis.authApi.URL}`;
const GET_VERSION_AUTH_API = `${AUTH_API_BASE_URI}/v2/assemblyinfo`;

export const getVersionAuthApiService = async() => {
	let response;
	
	try {
		response = await httpGet(GET_VERSION_AUTH_API);
	}
	catch( error ) {
		response = error?.response;
	}
	
	return response;
};
