import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

import { tryGetSubcodeList } from 'src/redux/subcode/subcodeActionCreator';
import { clearSubcodeListData } from 'src/redux/subcode/subcodeActions';

const BudgetSubCodePage = props => {

	const dispatch = useDispatch();
	const {
		isEditionMode,
		selectedPeriod,
		budgetType,
		selectedService,
		selectedJurisdiction,
		selectedOrganization,
		selectedCharacter,
		selectedAccount,
		selectedPurpose,
		selectedFunctionality,
		selectedSection,
		selectedSector,
		selectedPrincipalBudget,
		selectedPartialBudget,
		selectedCode,
		selectedSubcode,
		amountCurrentCredit,
		codeListIsFetching
	} = props;

	const amountCurrentCreditParseFloat = parseFloat(amountCurrentCredit);

	useEffect(() => {
		dispatch(clearSubcodeListData());
		getSubCodes();
	}, [selectedSubcode, budgetType]);

	const getSubCodes = () => {
		//Filter subcodes number
		const filter_budget_type = budgetType;
		const filter_period_id = selectedPeriod?.id;
		const filter_service_number = selectedService?.code;
		const filter_jurisdiction_number = selectedJurisdiction?.code;
		const filter_organization_number = selectedOrganization?.codeBudget;
		const filter_character_number = selectedCharacter?.code;
		const filter_account_number = selectedAccount?.number;
		const filter_pourpose_number = selectedPurpose?.number;
		const filter_functionality_number = selectedFunctionality?.number;
		const filter_section_number = selectedSection?.number;
		const filter_sector_number = selectedSector?.number;
		const filter_principal_budget_number = selectedPrincipalBudget?.number;
		const filter_partial_budget_number = selectedPartialBudget?.number;
		const filter_code_number = selectedCode?.number;
		const filter_subcode_number = selectedSubcode?.number;

		const filterToApplySubCode = {
			period_id: filter_period_id,
			period_rp_id: filter_budget_type,
			service_number: filter_service_number,
			jurisdiction_number: filter_jurisdiction_number,
			organization_number: filter_organization_number,
			character_number: filter_character_number,
			account_number: filter_account_number,
			purpose_number: filter_pourpose_number,
			functionality_number: filter_functionality_number,
			section_number: filter_section_number,
			sector_number: filter_sector_number,
			principal_budget_number: filter_principal_budget_number,
			partial_budget_number: filter_partial_budget_number,
			code_number: filter_code_number,
			sub_code_number: filter_subcode_number,
		};

		if( filter_service_number != undefined &&
			filter_service_number != undefined &&
			filter_jurisdiction_number != undefined &&
			filter_organization_number != undefined &&
			filter_character_number != undefined &&
			filter_account_number != undefined &&
			filter_pourpose_number != undefined &&
			filter_functionality_number != undefined &&
			filter_section_number != undefined &&
			filter_sector_number != undefined &&
			filter_principal_budget_number != undefined &&
			filter_partial_budget_number != undefined &&
			filter_code_number != undefined &&
			filter_subcode_number  != undefined ) {

			dispatch(clearSubcodeListData());
			dispatch(tryGetSubcodeList({
				filter: filterToApplySubCode
			}));
		}

		if (isEditionMode) {
			dispatch(clearSubcodeListData());
			dispatch(tryGetSubcodeList({
				filter: filterToApplySubCode
			}));
		}
	};

	return  <NumericFormat 
				prefix={'$ '}
				thousandSeparator={'.'} 
				decimalSeparator={','} 
				decimalScale={2} 
				inputMode="numeric" 
				value={amountCurrentCreditParseFloat}
				className={'text-black-color form-control'} 
				placeholder={codeListIsFetching ? "Cargando..." : ''}
				readOnly 
			/>
};

export default BudgetSubCodePage;