import * as ACTION_TYPES from './administrativeOrganismActionTypes';

// Administrative Organism List actions
export const getAdministrativeOrganismListRequest = () => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_ORGANISM_REQUEST
});

export const getAdministrativeOrganismListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAdministrativeOrganismListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setAdministrativeOrganismToDetail = data => ({
	type: ACTION_TYPES.SET_ADMINISTRATIVE_ORGANISM_TO_DETAIL,
	payload: {
		data
	}
});
export const clearAdministrativeOrganismList = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ADMINISTRATIVE_ORGANISM
});
// END Administrative Organism List actions


// Administrative Organism List One actions
export const getAdministrativeOrganismListOneRequest = () => ({
	type: ACTION_TYPES.LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST
});

export const getAdministrativeOrganismListOneRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAdministrativeOrganismListOneRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ONE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
});


// Administrative Organism New actions
export const postAdministrativeOrganismRequest = () => ({
	type: ACTION_TYPES.NEW_ADMINISTRATIVE_ORGANISM_REQUEST
});

export const postAdministrativeOrganismRequestSuccess = data => ({
	type: ACTION_TYPES.NEW_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const postAdministrativeOrganismRequestFailure = error => ({
	type: ACTION_TYPES.NEW_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
});

// END Administrative Organism New actions

// Administrative Organism Edit actions
export const putAdministrativeOrganismRequest = () => ({
	type: ACTION_TYPES.EDIT_ADMINISTRATIVE_ORGANISM_REQUEST
});

export const putAdministrativeOrganismRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const putAdministrativeOrganismRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setAdministrativeOrganismToEdit = data => ({
	type: ACTION_TYPES.SET_ADMINISTRATIVE_ORGANISM_TO_EDIT,
	payload: {
		data
	}
});

// END Administrative Organism Edit actions

// Administrative Organism Delete actions
export const deleteAdministrativeOrganismRequest = () => ({
	type: ACTION_TYPES.DELETE_ADMINISTRATIVE_ORGANISM_REQUEST
});

export const deleteAdministrativeOrganismRequestSuccess = data => ({
	type: ACTION_TYPES.DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const deleteAdministrativeOrganismRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
});

// END Administrative Organism DELETE actions

// Administrative Organism Classifier actions
export const classifierAdministrativeOrganismRequest = () => ({
	type: ACTION_TYPES.CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST
});

export const classifierAdministrativeOrganismRequestSuccess = data => ({
	type: ACTION_TYPES.CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const classifierAdministrativeOrganismRequestFailure = error => ({
	type: ACTION_TYPES.CLASSIFIER_ADMINISTRATIVE_ORGANISM_REQUEST_FAILURE,
	payload: {
		error
	}
});

// END Administrative Organism Classifier actions

//Service Administrative manager Organism  actions
export const serviceAdministrativeManagerOrganismRequest = () => ({
	type: ACTION_TYPES.GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST
});
export const serviceAdministrativeManagerOrganismRequestSuccess = data => ({
	type: ACTION_TYPES.GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const serviceAdministrativeManagerOrganismRequestFailure = error => ({
	type: ACTION_TYPES.GET_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearserviceAdministrativeManagerOrganismData = () => ({
	type: ACTION_TYPES.CLEAR_SERVICE_ADMINISTRATIVE_MANAGER_ORGANIMS_DATA
});

// END Service Administrative manager Organism  actions