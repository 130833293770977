
// List one administrative document data
export const GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST = 'GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST';
export const GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS = 'GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS';
export const GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE = 'GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE';
export const CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_DATA = 'CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_DATA';


// List administrative document data
export const GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST = 'GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST';
export const GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS = 'GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS';
export const GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE = 'GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE';
export const CLEAR_LIST_ALL_ADMINISTRATIVE_DOCUMENT_DATA = 'CLEAR_LIST_ALL_ADMINISTRATIVE_DOCUMENT_DATA';

// List one administrative document by Order Pay data
export const GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST = 'GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST';
export const GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST_SUCCESS = 'GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST_SUCCESS';
export const GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST_FAILURE = 'GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST_FAILURE';
export const CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_DATA = 'CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_DATA';

// List administrative document history
export const GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST = 'GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST';
export const GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_SUCCESS = 'GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_SUCCESS';
export const GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_FAILURE = 'GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_FAILURE';
export const CLEAR_ADMINISTRATIVE_DOCUMENT_HISTORY_DATA = 'CLEAR_ADMINISTRATIVE_DOCUMENT_HISTORY_DATA';

// Get latest affectation transaction
export const GET_LATEST_AFFECTATION_TRANSACTION_REQUEST = 'GET_LATEST_AFFECTATION_TRANSACTION_REQUEST';
export const GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_SUCCESS = 'GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_SUCCESS';
export const GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_FAILURE = 'GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_FAILURE';
export const CLEAR_LATEST_AFFECTATION_TRANSACTION_DATA = 'CLEAR_LATEST_AFFECTATION_TRANSACTION_DATA';

// Get expedient by id budget credits

export const GET_EXPEDIENT_BY_ID_REQUEST = 'GET_EXPEDIENT_BY_ID_REQUEST';
export const GET_EXPEDIENT_BY_ID_SUCCESS = 'GET_EXPEDIENT_BY_ID_SUCCESS';
export const GET_EXPEDIENT_BY_ID_FAILURE = 'GET_EXPEDIENT_BY_ID_FAILURE';
export const CLEAR_EXPEDIENT_BY_ID = 'CLEAR_EXPEDIENT_BY_ID';

// Get administrative document subcodes related

export const GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_REQUEST = 'GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_REQUEST';
export const GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_SUCCESS = 'GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_SUCCESS';
export const GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_FAILURE = 'GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_FAILURE';
export const CLEAR_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_DATA = 'CLEAR_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_DATA';

// Getters and Setters RP administrative document selected

export const GET_ALL_RP_CONVERT_DATA_BY_SERVICE_REQUEST = 'GET_ALL_RP_CONVERT_DATA_BY_SERVICE_REQUEST';
export const GET_ALL_RP_CONVERT_DATA_BY_SERVICE_SUCCESS = 'GET_ALL_RP_CONVERT_DATA_BY_SERVICE_SUCCESS';
export const GET_ALL_RP_CONVERT_DATA_BY_SERVICE_FAILURE = 'GET_ALL_RP_CONVERT_DATA_BY_SERVICE_FAILURE';
export const SET_RP_CONVERT_DATA = 'SET_RP_CONVERT_DATA';
export const CLEAR_RP_CONVERT_DATA = 'CLEAR_RP_CONVERT_DATA';

// Get administrative documents modifications and preload details

export const GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_REQUEST = 'GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_REQUEST';
export const GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_SUCCESS = 'GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_SUCCESS';
export const GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_FAILURE = 'GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_FAILURE';
export const CLEAR_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_DATA = 'ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_DATA';

// Get administrative documents availables
export const GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST = 'GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST';
export const GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_SUCCESS = 'GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_SUCCESS';
export const GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_FAILURE = 'GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_FAILURE';
export const CLEAR_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_DATA = 'CLEAR_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_DATA';