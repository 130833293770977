import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { setNewAffectationAdministrativeDocumentData, clearExtractAffectation } from 'src/redux/affectation/affectationActions';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getYearsOptionsByParam, parseIntOrUndefined } from 'src/utils/utils';
import { isNumeric } from 'src/services/validationService';
import { clearLatestAffectationTransactionData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { getAdministrativeDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { tryGetAdministrativeDocumentByPeriodId } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { useFormContext } from 'react-hook-form';

const AssociatedExpedientComponent = (props) => {

    const dispatch = useDispatch();

    const { getValues, register, reset } = useFormContext();

    const { dataLengthCharacter, setDataLengthCharacter, dataYearSelected, setDataYearSelected, dataNumberSelected, setDataNumberSelected, isNewAdministrativeDocument, setIsNewAdministrativeDocument } = props;

    const minimumYear = 2000;
    const maxNumber = 2147483647;

    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
    const yearsOptions = getYearsOptionsByParam(minimumYear, selectedPeriod?.year);
    const selectedAdministrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
    const administrativeDocumentDataFromEndpointIsFetching = useSelector(state => getAdministrativeDocumentIsFetching(state));

    const onBlurDocumentDataInputs = () => {

        const { organismCode, number, year } = getValues();

        let lengthCharacter = (organismCode?.length > 4 || organismCode?.length < 4) || (parseIntOrUndefined(organismCode) < 1000 && parseIntOrUndefined(organismCode) != 0);
        setDataLengthCharacter(lengthCharacter);

        let lengthYearSelected = year?.length >= 4;
        setDataYearSelected(lengthYearSelected)

        let lengthNumberSelected = number?.length >= 1;
        setDataNumberSelected(lengthNumberSelected)

        if (organismCode && number && isNumeric(year) && !lengthCharacter) {
            const params = {
                year: parseIntOrUndefined(year),
                number: parseIntOrUndefined(number),
                organismCode: organismCode,
                periodId: selectedPeriod?.id
            };
            dispatch(tryGetAdministrativeDocumentByPeriodId(params))
                .then(administrativeDocumentLoaded);
        }
    };

    const clearForm = () => {
        reset();
    };

    const administrativeDocumentLoaded = responseData => {
        if (responseData) {
            if (responseData?.number) {
                clearForm();
                setIsNewAdministrativeDocument(false);
                dispatch(setNewAffectationAdministrativeDocumentData(responseData));
                dispatch(clearLatestAffectationTransactionData());
            }
            else {
                setIsNewAdministrativeDocument(true);
            }
        }
    };

    const onClickRemoveSelectedAdministrativeDocument = () => {
        dispatch(setNewAffectationAdministrativeDocumentData(undefined));
        dispatch(clearExtractAffectation());
    };

    return (
        <>
            {
                selectedAdministrativeDocument
                    ?
                    <>
                        <div className='d-flex justify-content-center'>
                            <Card className='text-black-color shadow bg-white rounded mt-3 mb-3 p-3' style={{ width: '20rem' }}>
                                <FontAwesomeIcon icon={faTimes} className='text-black-color cursor-pointer close-button' onClick={onClickRemoveSelectedAdministrativeDocument} />
                                <Row className='my-2'>
                                    <Col className='text-right' xs='5'>
                                        Expediente:
                                    </Col>
                                    <Col>
                                        {`${selectedAdministrativeDocument?.organizationCode}-${selectedAdministrativeDocument?.number}/${selectedAdministrativeDocument?.year}`}
                                    </Col>
                                </Row>
                                <Row className='my-2'>
                                    <Col className='text-right' xs='5'>
                                        Asunto:
                                    </Col>
                                    <Col>
                                        {selectedAdministrativeDocument?.title}
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                        <hr />
                    </>
                    :
                    undefined
            }
            <Form.Group as={Row}>
                <Col sm='3' className='mb-0 d-flex align-items-center justify-content-end'>
                    <Form.Label htmlFor='expediente-data' className='mb-0 d-flex align-items-center justify-content-end'>
                        <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                        Expediente:
                    </Form.Label>
                </Col>
                <Col sm='7' className='mx-0 px-0  d-flex align-items-center justify-content-end'>
                    <Form.Control
                        name='organismCode'
                        type='number'
                        id='expediente-data'
                        className='form-control w-100 d-flex align-items-center justify-content-end'
                        placeholder='Código'
                        disabled={selectedAdministrativeDocument || administrativeDocumentDataFromEndpointIsFetching}
                        ref={register}
                        onBlur={onBlurDocumentDataInputs}
                        tabIndex='1'
                        title='Código de Organismo'
                        min={'0'}
                        required
                    />
                    <span className='px-2'>
                        -
                    </span>
                    <Form.Control
                        name='number'
                        type='number'
                        placeholder='Correlativo'
                        className='form-control w-100 d-flex align-items-center justify-content-end'
                        disabled={selectedAdministrativeDocument || administrativeDocumentDataFromEndpointIsFetching}
                        ref={register}
                        onBlur={onBlurDocumentDataInputs}
                        tabIndex='2'
                        min='1'
                        max={maxNumber}
                        required
                    />
                    <span className='px-3'>
                        /
                    </span>
                    <Form.Control
                        name='year'
                        as='select'
                        disabled={selectedAdministrativeDocument || administrativeDocumentDataFromEndpointIsFetching}
                        onBlur={onBlurDocumentDataInputs}
                        ref={register}
                        tabIndex='3'
                        required >
                        <option value={''}>Año</option>
                        {
                            yearsOptions?.map((item, idx) => (<option value={item} key={idx}>
                                {item}
                            </option>))
                        }
                    </Form.Control>
                </Col>
            </Form.Group>
            {
                dataLengthCharacter
                    ?
                    <>
                        <div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
                            <strong>¡Importante!</strong> El código de organismo del expediente debe estar entre los rangos 1000 y 9999.
                        </div>
                    </>
                    :
                    null
            }
            {
                (!dataLengthCharacter && dataYearSelected && dataNumberSelected && isNewAdministrativeDocument & !administrativeDocumentDataFromEndpointIsFetching) ?
                    <>
                        <div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
                            <strong>¡Importante!</strong> El expediente ingresado no existe.
                        </div>
                    </>
                    : null
            }
        </>
    )
};

export default AssociatedExpedientComponent;