import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import 'react-widgets/styles.css';
import PageTitle from 'src/components/general/PageTitle';
import { isNotEmptyArray } from 'src/services/validationService';
import ColumnSearchBox from 'src/components/forms/fundRequest/ColumnSearchBox';
import { getFundRequestsListIsFetching } from 'src/redux/fundRequests/fundRequestsReducer';
import { getGlobalDataPeriodsData, getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { tryGetReportFundRequest, tryGetReportNewFundRequest } from 'src/redux/reports/reportsActionCreactor';
import { getReportFundRequestIsFetching, getReportNewFundRequestIsFetching } from 'src/redux/reports/reportsReducer';
import { clearReportCreditModifyCreditExecution } from 'src/redux/reports/reportsActions';
import { tryGetUserList } from 'src/redux/user/userActionCreator';
import AppLoading from 'src/components/common/AppLoading';

const ReportFundRequestPage = props => {
	const dispatch = useDispatch();

	//ColumnSearchBox filter status
    const isFetching = useSelector(state => getFundRequestsListIsFetching(state));

	// Periods
	const periodList = useSelector(state => getGlobalDataPeriodsData(state))?.data?.sort((a, b) => a.year < b.year ? 1 : -1);
	const hasPeriodList = isNotEmptyArray(periodList);
	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	const [selectedFundRequest, setselectedFundRequest] = useState();

	useEffect(() => {
		dispatch(tryGetUserList());
		dispatch(clearReportCreditModifyCreditExecution());
	}, [globalSelectedPeriod]);
	
	const getReport = outputFormat => {
		if (outputFormat == 1) {
			if (globalSelectedPeriod) {
				const params = {
					id: selectedFundRequest?.id,
				};
				dispatch(tryGetReportFundRequest(params));
			}

		} else if (outputFormat == 3) {
			if (globalSelectedPeriod) {
				const params = {
					id: selectedFundRequest?.id,
				};
				dispatch(tryGetReportNewFundRequest(params));

			}
		}


	};

	const onClickPDF = () => getReport(1);

	const onClickXLS = () => getReport(2);

	const onClickResumePDF = () => getReport(3);


	// Reports redux
	const reportFundRequestIsFetching = useSelector(state => getReportFundRequestIsFetching(state));
	const reportNewFundRequestIsFetching = useSelector(state => getReportNewFundRequestIsFetching(state));
	const reportIsFetching = (reportFundRequestIsFetching || reportNewFundRequestIsFetching);
	const allowGetReport = (hasPeriodList && selectedFundRequest);

	return <Container fluid>
		<Card className='mb-5'>
			<PageTitle text='Reporte de Pedido de Fondos' />

			<Container fluid>
				<Card className='mb-3'>
					<Card.Body>
							<Row>
								<Col sm={10} className='mx-auto'>
									<Form.Group>
										<Form.Label className='text-black-color'>
											Ejercicio
										</Form.Label>

										<Form.Control
											type='number'
											name='globalSelectedPeriod'
											id='globalSelectedPeriod'
											value={globalSelectedPeriod?.year}
											readOnly
										/>
									</Form.Group>

									<Form.Group>
										<ColumnSearchBox 
											setselectedFundRequest={setselectedFundRequest}
											isFetching={isFetching}
										/>
									</Form.Group>

									<br/>
									<Form.Group className='d-flex justify-content-between mt-4'>
										<Button size='lg' onClick={onClickPDF} disabled={!allowGetReport} className='mx-auto'>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Ver PDF
										</Button>
										<Button size='lg' onClick={onClickResumePDF} disabled={!allowGetReport} className='mx-auto'>
											<FontAwesomeIcon icon={faFilePdf} className='mr-2' />
											Reporte resumido PDF
										</Button>

									</Form.Group>
								</Col>
							</Row>
					</Card.Body>
				</Card>
			</Container>
		</Card>
		<AppLoading isLoading={reportIsFetching || isFetching} />
	</Container>;
};

export default ReportFundRequestPage;