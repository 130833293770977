import * as ACTION_TYPES from './administrativeDocumentActionTypes';

// Administrative document list actions
export const listOneAdministrativeDocumentRequest = () => ({
	type: ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST
});
export const listOneAdministrativeDocumentRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listOneAdministrativeDocumentRequestFailure = error => ({
	type: ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearOneListAdministrativeDocumentData = () => ({
	type: ACTION_TYPES.CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_DATA
});
// END Administrative document list actions


// Administrative document list all actions
export const listAllAdministrativeDocumentRequest = () => ({
	type: ACTION_TYPES.GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST
});
export const listAllAdministrativeDocumentRequestSuccess = data => ({
	type: ACTION_TYPES.GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listAllAdministrativeDocumentRequestFailure = error => ({
	type: ACTION_TYPES.GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearAllListAdministrativeDocumentData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ALL_ADMINISTRATIVE_DOCUMENT_DATA
});

// END Administrative document list actions

// Administrative document by Order Pay  list actions
export const listOneAdministrativeDocumentByOrderPayRequest = () => ({
	type: ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST
});
export const listOneAdministrativeDocumentByOrderPayRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listOneAdministrativeDocumentByOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearOneListAdministrativeDocumentByOrderPayData = () => ({
	type: ACTION_TYPES.CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_BY_ORDER_PAY_DATA
});
// END Administrative document by Order Pay  list actions

// Administrative document history actions
export const listAdministrativeDocumentHistoryRequest = () => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST
});
export const listAdministrativeDocumentHistoryRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listAdministrativeDocumentHistoryRequestFailure = error => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListAdministrativeDocumentHistoryData = () => ({
	type: ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_HISTORY_DATA
});
// END Administrative document history actions

// latest affectation transaction actions
export const listLatestAffectationTransactionRequest = () => ({
	type: ACTION_TYPES.GET_LATEST_AFFECTATION_TRANSACTION_REQUEST
});
export const listLatestAffectationTransactionRequestSuccess = data => ({
	type: ACTION_TYPES.GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listLatestAffectationTransactionRequestFailure = error => ({
	type: ACTION_TYPES.GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearLatestAffectationTransactionData = () => ({
	type: ACTION_TYPES.CLEAR_LATEST_AFFECTATION_TRANSACTION_DATA
});
// END latest affectation transaction actions

// expedient by id budget credits actions
export const expedientByFilterRequest  = () => ({
	type: ACTION_TYPES.GET_EXPEDIENT_BY_ID_REQUEST
});
export const expedientByFilterSuccess = data => ({
	type: ACTION_TYPES.GET_EXPEDIENT_BY_ID_SUCCESS,
	payload: {
		data
	}
});
export const expedientByIdSubCodeFailure = error => ({
	type: ACTION_TYPES.GET_EXPEDIENT_BY_ID_FAILURE,
	payload: {
		error
	}
});
export const clearexpedientByFilterData = () => ({
	type: ACTION_TYPES.CLEAR_EXPEDIENT_BY_ID
});
// END expedient by id budget credits actions

// administrative documents subcode related actions
export const administrativeDocumentSubcodeRelatedRequest  = () => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_REQUEST
});
export const administrativeDocumentSubcodeRelatedSuccess = data => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_SUCCESS,
	payload: {
		data
	}
});
export const administrativeDocumentSubcodeRelatedFailure = error => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_FAILURE,
	payload: {
		error
	}
});
export const clearAdministrativeDocumentSubcodeRelatedData = () => ({
	type: ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_DATA
});
// END administrative documents subcode related actions

// RP selected data actions
export const administrativeDocumentRPConvertSelectedData  = () => ({
	type: ACTION_TYPES.GET_ALL_RP_CONVERT_DATA_BY_SERVICE_REQUEST
});
export const administrativeRPConvertSelectedDataSuccess = data => ({
	type: ACTION_TYPES.GET_ALL_RP_CONVERT_DATA_BY_SERVICE_SUCCESS,
	payload: {
		data
	}
});
export const administrativeDocumentRPConvertSelectedDataFailure = error => ({
	type: ACTION_TYPES.GET_ALL_RP_CONVERT_DATA_BY_SERVICE_FAILURE,
	payload: {
		error
	}
});
export const setRPConvertSelectedData = data => ({
	type: ACTION_TYPES.SET_RP_CONVERT_DATA,
	payload: {
		data
	}
});
export const clearRPConvertSelectedData = () => ({
	type: ACTION_TYPES.CLEAR_RP_CONVERT_DATA
});
// END RP selected data actions

// administrative documents modifications and preload details actions
export const administrativeDocumentModifPreloadDetailsRequest  = () => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_REQUEST
});
export const administrativeDocumentModifPreloadDetailsSuccess = data => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_SUCCESS,
	payload: {
		data
	}
});
export const administrativeDocumentModifPreloadDetailsFailure = error => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_FAILURE,
	payload: {
		error
	}
});
export const clearAdministrativeDocumentModifPreloadDetailsData = () => ({
	type: ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_DATA
});
// END administrative documents modifications and preload details actions

// Administrative document availables
export const administrativeDocumentAvailableRequest = () => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST
});

export const administrativeDocumentAvailableRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const administrativeDocumentAvailableRequestFailure = error => ({
	type: ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearAdministrativeDocumentAvailablesData = () => ({
	type: ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_DATA
});
// END Administrative document availables actions