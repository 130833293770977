import React, { useState } from "react";

import { Container, Card, Tabs, Tab } from 'react-bootstrap';

import RecentlyGeneratedReports from "./RecentlyGeneratedReports";
import HistoryGeneratedReports from "./HistoryGeneratedReports";

const ReportTableTotalsCreditExecutionPageGenerated = (props) => {

    const [selectedReport, setSelectedReport] = useState("recently");

    const reportHandler = (param) => {
        setSelectedReport(param);
    };

    return (
        <Container fluid>
            <Card>

                <Card.Header>
                    <h6 className='mb-0'>Reportes Generados</h6>
                </Card.Header>

                <Card.Body>

                    <Tabs
                        defaultActiveKey="recently"
                        onSelect={(param) => reportHandler(param)}
                        className="mb-3"
                    >
                        <Tab eventKey="recently" title="Recientes">
                            <RecentlyGeneratedReports
                                selectedReport={selectedReport}
                            />
                        </Tab>

                        <Tab eventKey="historical" title="Histórico" >
                            <HistoryGeneratedReports
                                selectedReport={selectedReport}
                            />
                        </Tab>

                    </Tabs>

                </Card.Body>
            </Card>
        </Container>
    );
};

export default ReportTableTotalsCreditExecutionPageGenerated;
