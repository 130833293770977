import { useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { push } from "connected-react-router";
import { CONCEPT_LIST } from "src/utils/constants";
import { removeUnuselessObjPropertiesFromArray, removeUnuselessObjPropertiesFromArrayNestedObj } from "src/utils/utils";
import { showError } from "src/redux/globalData/globalDataActionCreator";
import { getConceptListData } from "src/redux/conceptRequest/conceptRequestReducer";
import { AdministrativeServiceByUserAndExerciseData } from 'src/utils/administrativeServiceUtils';

export const useConceptFormAM = (conceptToEdit, dispatch, props) => {
  const [concept, setConcept] = useState(conceptToEdit?.description);
  const [addNewconceptOpt, setAddNewconceptOpt] = useState(false);
  const [disableButtonAssociate, setDisableButtonAssociate] = useState(true);
  const [changeSelectState, setChangeSelectState] = useState(false);
  const [isListed, setIsListed] = useState(false);
  const [subConcepto, setSubConcepto] = useState();
  const [idSelectectedConcept, setIdSelectectedConcept] = useState(conceptToEdit?.id);
  const [conceptId, setConceptId] = useState(conceptToEdit?.id);
  const [conceptsList, setConceptsList] = useState(
    conceptToEdit?.subConcept ? conceptToEdit?.subConcept : []
  );
  const [conceptsListOrder, setConceptsListOrder] = useState(conceptsList);
  const hasConceptList = conceptsList?.length > 0;
  const principalDontAllowCond = !hasConceptList || !concept;
  const [admServiceId, setAdmServiceId] = useState(conceptToEdit?.administrativeServiceId);
  const administrativeServiceData = AdministrativeServiceByUserAndExerciseData();
  const messagesDropDown = {
    emptyFilter: "No hay resultados",
    emptyList: "No hay resultados",
  };
  const conceptListData = useSelector((state) => getConceptListData(state)?.records)?.map((item) => ({
    ...item,
    displayName: `${item?.description} `,
    name: item?.description,
  }))
  const standardDTOStructure = {
    id: idSelectectedConcept ?? null ,
    level:1,
    description: concept,
    subConcept: conceptsList,
    administrativeServiceId: admServiceId
  };
  const forbiddenConcepts = [];

  if(conceptListData != undefined){
    for (let value of conceptListData) {
      forbiddenConcepts.push(value.name);
    }
  }

  const formik = useFormik({
    initialValues: {
      ...conceptToEdit,
    },
    validationSchema: Yup.object({
      subConcepto: Yup.string()
        .test(
          "len",
          "Este campo no puede tener más  de 256 caracteres",
          (val) => val?.length <= 256
        ),
    }),
    onSubmit: (data) => {
      removeUnuselessObjPropertiesFromArray(
        standardDTOStructure?.conceptListed,
        ["unidadDeOrganizacionShortName"]
      );
      removeUnuselessObjPropertiesFromArrayNestedObj(
        standardDTOStructure?.conceptListed,
        ["id", "shortName"]
      );
      props.onFormSubmit(
        {
          ...data,
          ...standardDTOStructure,
        },
        false
      );
    },
  });

  const redirectToConceptList = () => {
    dispatch(push(CONCEPT_LIST));
  };

  const handleSaveConcept = () => {
    props.onFormSubmit(
      {
        ...formik.values,
        ...standardDTOStructure,
      },
      false
    );
  };
  const handleSaveConceptAndCreateOther = () => {
    removeUnuselessObjPropertiesFromArray(standardDTOStructure?.conceptListed, [
      "id",
    ]);
    removeUnuselessObjPropertiesFromArrayNestedObj(
      standardDTOStructure?.conceptListed,
      ["id", "shortName"]
    );
    props.onFormSubmit(
      {
        ...formik.values,
        ...standardDTOStructure,
      },
      true
    );
  };
  const handleAsociateButton = () => {
    if (!concept || !subConcepto)
      return setDisableButtonAssociate(true);
    return setDisableButtonAssociate(false);
  };
  const handleConceptListOnChange = (concept) => {
    setIdSelectectedConcept(concept?.id);
    setConcept(concept?.name);
    setConceptsList(concept?.subConcept);
  };
  const handleNewOnChange = (e) => {
    setConcept(e.target.value?.trim().toUpperCase());
    setIdSelectectedConcept(null);
    formik.handleChange(e);
  };

  const handleSubConceptoOnChange = (e) => {
    setSubConcepto(e.target.value?.trim().toUpperCase());
    formik.handleChange(e);
  };

  const cleanUpDropDowns = () => {};

  const handleAddConceptButton = () => {
    setAddNewconceptOpt(!addNewconceptOpt);
    setConceptsList([]);
  };

  const listConcept = {
    id:null,
    level:2,
    description:subConcepto,
  };

  const addConceptToList = () => {
    let idConcept = `${listConcept.concept}-${listConcept.description}`;
    let idListBudget = [];
    conceptsList?.map((item) =>
      idListBudget.push(`${item.concept}-${item.description}`)
    );

    idListBudget?.find((item) => item === idConcept)
      ? showError("El subconcepto ya se encuentra asociado")
      : setConceptsList((conceptsList) => [...conceptsList, listConcept]);
    cleanUpDropDowns();
    setSubConcepto();
    formik.values.subConcepto = "";
    setSubConcepto(undefined);
    setIsListed(true);
    setChangeSelectState(true);
  };

  const handleDeleteConcept = (item) => {
    let idItem = `${item.id}-${item.description}`;
    const filtered = conceptsList?.filter(
      (value) => `${value.id}-${value.description}` !== idItem
    );
    setConceptsList(filtered);
  };

  return {
    redirectToConceptList,
    disableButtonAssociate,
    isListed,
    conceptId,
    setConceptId,
    conceptsListOrder,
    setConceptsListOrder,
    principalDontAllowCond,
    messagesDropDown,
    handleSaveConceptAndCreateOther,
    handleSaveConcept,
    handleAsociateButton,
    handleConceptListOnChange,
    handleNewOnChange,
    handleSubConceptoOnChange,
    handleAddConceptButton,
    addConceptToList,
    handleDeleteConcept,
    conceptListData,
    conceptsList,
    concept,
    subConcepto,
    hasConceptList,
    changeSelectState,
    addNewconceptOpt,
    formik,
    forbiddenConcepts,
    administrativeServiceData,
    admServiceId,
    setAdmServiceId
  };
};
