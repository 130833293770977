import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Container, Button, Table, Form, Card } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { formatterPeso, focusSelectInputByElementID } from 'src/utils/utils';
import { cancel, xFile, amountNumber, save } from 'src/utils/label';
import { NumericFormat } from 'react-number-format';
import AppLoading from 'src/components/common/AppLoading';
import { tryEditPreload } from 'src/redux/preload/preloadActionCreator';
import { getPreloadEditIsFetching } from 'src/redux/preload/preloadReducer';
import { tryGetPreloadData } from 'src/redux/preload/preloadActionCreator';

const PreLoadEditModal = (props) => {

    const { show, handleClose, dataModal, filterObject, defaultParams, affectationsSelect, setAffectationsSelect } = props;
    
    const dispatch = useDispatch();

    const { subCodeData, expedientData } = dataModal;
    const preloadEditIsFetching = useSelector(state => getPreloadEditIsFetching(state));
    const hookFormMethods = useForm();
    const { handleSubmit, control } = hookFormMethods;

    const [ amount, setAmount ] = useState();

    let isEmpty = filterObject && Object.entries(filterObject).length === 0;

    const onChangeAmount = (inputObject) => {
        setAmount(inputObject?.floatValue);
    };

     //Validations
     const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		const maxValue = 999999999999.99;
		const minValue = -999999999999.99;
		if (value <= maxValue && value >= minValue || value == '-') return inputObj;
	};

    const updateAffectationsSelect = () => {

        const preloadId = subCodeData?.id;
        const expedient = affectationsSelect?.expedient;

        if(affectationsSelect?.subCodePreload?.some(obj => obj?.id === preloadId)){
            setAffectationsSelect({
                expedient,
                subCodePreload: affectationsSelect?.subCodePreload?.filter(obj => obj.id !== preloadId)
            });
        }

    };
    // Edit
	const onClickEdit = () => {

        const params = {
            preloadId: subCodeData?.id,
            amount: amount
        };

        dispatch(tryEditPreload(params)).then(
            response => {
                if (response?.status == 200) {
                    if(filterObject == undefined || isEmpty){
                        dispatch(tryGetPreloadData(defaultParams)).then(
                            () => {
                                updateAffectationsSelect();
                            }
                        );
                    } else {
                        dispatch(tryGetPreloadData({
                            ...defaultParams,
                            filter: { ...defaultParams.filter, ...filterObject }
                        })).then(
                            () => {
                                updateAffectationsSelect();
                            }
                        );
                    };
                    handleClose();
                }
            }
        );
	};

    useEffect(() => {
        setAmount();
		focusSelectInputByElementID('amount');
	}, [show]);

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" className='mt-2 mb-4'>
            <Modal.Header closeButton>
                <Modal.Title>Editar Importe de Pre-Carga</Modal.Title>
            </Modal.Header>
            <Container className=''>
                <Card className='mb-3'>
                    <Card.Body>
                        <Card className='mt-4'>
                            <Card.Header className='h6'>
                                <h5 className='mb-0'>
                                    {xFile}:
                                </h5>
                            </Card.Header>
                        </Card>
                        <Table striped bordered hover>
                            <tbody className='text-black-color'>
                                <tr>
                                    <td>{expedientData?.completeLabel}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Container fluid className='mt-3 px-0'>
                            <Form onSubmit={handleSubmit(onClickEdit)}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='text-center align-middle'>Partida</th>
                                            <th className='text-center align-middle'>{amountNumber}</th>
                                            <th className='text-center align-middle'>Nuevo importe</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-black-color'>
                                        <tr>
                                            <td className='text-center align-middle'>{subCodeData?.subCode?.completeNumber}</td>
                                            <td className='text-right'>{formatterPeso.format(subCodeData?.amount)}</td>
                                            <td  className='text-center'>
                                                <Controller
                                                    name='amount'
                                                    id='amount'
                                                    control={control}
                                                    as={
                                                        <NumericFormat
                                                            className='form-control text-right'
                                                            decimalScale={2}
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}
                                                            inputMode='numeric'
                                                            prefix={'$'}
                                                            allowLeadingZeros = {false}
                                                            onValueChange={ (inputObject) => onChangeAmount(inputObject)}
                                                            isAllowed={(inputObj)=> withValueLimit(inputObj)}
                                                            required
                                                        />
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>

                                <div className='d-flex justify-content-around mt-4 mb-3'>
                                    <Button variant='danger' size='lg' onClick={handleClose}>
                                        {cancel}
                                    </Button>


                                    <Button type='submit' variant='success' size='lg' disabled={amount == 0}>
                                        {save}
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </Card.Body>
                </Card>
                </Container>
                <AppLoading isLoading={preloadEditIsFetching} />
        </Modal>
    )
};

export default PreLoadEditModal;