import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { getListAllServiceAdministrativeByUserData} from "src/redux/administrativeService/administrativeServiceReducer";
import Commons from './controls/Commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Row, Col } from 'react-bootstrap';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import ExpedienteContainer from './controls/ExpedienteContainer';
import { clearNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import { clearListOrderPayLast } from 'src/redux/orderPay/orderPayActions';
import FormFieldError from 'src/components/general/FormFieldError';

const InstitucionFondosForm = () => {
    const dispatch = useDispatch();
	const { orderPay, register, setImporte, errors } = useFormContext();
    const [administrativeDocument, setAdministrativeDocument] = useState([]);
	const [admServiceId, setAdmServiceId] = useState();
	const [expedientesSeleccionados, setExpedientesSeleccionados] = useState([]);
	const [expedientesAsociados, setExpedientesAsociados] = useState([]);

	const applyFilterBankAccounts = (administrativeServiceId) => {
		setAdmServiceId(administrativeServiceId);
	};

	const administrativeServiceData = useSelector( state => getListAllServiceAdministrativeByUserData(state) )
		?.sort( (a,b) => a.code>b.code ? 1 : -1 )
		?.map( item => ({
			displayName: `${item?.code} - ${item?.name} `,
			id: item?.id,
			name: item?.name
	}) );

	useEffect(() => {
		if (orderPay){
            setAdmServiceId(orderPay.administrativeServiceId);
		}
        dispatch(clearListOrderPayLast());
		dispatch(clearListAllAdministrativeServicesData());
        dispatch(clearNewAffectationAdministrativeDocumentData());
    }, []);

    const organismOrInstitutionRequiredMsg = 'Debe ingresar un Organismo o Institución Beneficiaria';
    const subAgentRequiredMsg = 'Debe ingresar un Agente Subresponsable';

    const organismOrInstitutionValidationObj = { required: organismOrInstitutionRequiredMsg };
    const subAgentValidationObj = { required: subAgentRequiredMsg };

    return <>
        <Commons
            administrativeServiceData={administrativeServiceData}
            admServiceId={admServiceId}
            setAdmServiceId={setAdmServiceId}
            applyFilterBankAccounts={applyFilterBankAccounts}
            disabledImporte={true}
        />
        <Form.Group as={Row}>
            <Form.Label htmlFor='asunto' className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Organismo o Institución Beneficiaria:
            </Form.Label>
            <Col sm='4'>
                <Form.Control
                    autoComplete="off"
                    type='text'
                    name='organismOrInstitution'
                    ref={register(organismOrInstitutionValidationObj)}
                />
                <FormFieldError errors={errors?.organismOrInstitution} />
            </Col>
        </Form.Group>
        <Form.Group as={Row}>
        <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                <FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk' />
                Agente Sub-Responsable:
            </Form.Label>
            <Col sm='4' >
                <Form.Control
                    autoComplete="off"
                    type='text'
                    name='subAgent'
                    ref={register(subAgentValidationObj)}
                />
                <FormFieldError errors={errors?.subAgent} />
            </Col>
        </Form.Group>
        <br></br>
        { admServiceId &&
            <ExpedienteContainer
                expedientesSeleccionados={expedientesSeleccionados}
                setExpedientesSeleccionados={setExpedientesSeleccionados}
                expedientesAsociados={expedientesAsociados}
                setExpedientesAsociados={setExpedientesAsociados}
                administrativeDocument={administrativeDocument}
                setAdministrativeDocument={setAdministrativeDocument}
                setImporte={setImporte}
            />
        }
        <br></br>
    </>
}

export default InstitucionFondosForm;