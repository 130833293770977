// Get budget item control data request
export const GET_LIST_BANK_ACCOUNT_FUND_REQUEST_REQUEST = 'GET_LIST_BANK_ACCOUNT_FUND_REQUEST_REQUEST';
export const GET_LIST_BANK_ACCOUNT_FUND_REQUEST_SUCCESS = 'GET_LIST_BANK_ACCOUNT_FUND_REQUEST_SUCCESS';
export const GET_LIST_BANK_ACCOUNT_FUND_REQUEST_FAILURE = 'GET_LIST_BANK_ACCOUNT_FUND_REQUEST_FAILURE';
export const CLEAR_LIST_BANK_ACCOUNT_FUND_REQUEST_DATA = 'CLEAR_LIST_BANK_ACCOUNT_FUND_REQUEST_DATA';

//ADD New Bank Account
export const ADD_NEW_BANK_ACCOUNT_REQUEST_REQUEST = 'ADD_NEW_BANK_ACCOUNT_REQUEST_REQUEST';
export const ADD_NEW_BANK_ACCOUNT_REQUEST_SUCCESS = 'ADD_NEW_BANK_ACCOUNT_REQUEST_SUCCESS';
export const ADD_NEW_BANK_ACCOUNT_REQUEST_FAILURE = 'ADD_NEW_BANK_ACCOUNT_REQUEST_FAILURE';

//delete Bank Account
export const BANK_ACCOUNT_DELETE_REQUEST = 'BANK_ACCOUNT_DELETE_REQUEST';
export const BANK_ACCOUNT_DELETE_REQUEST_SUCCESS = 'BANK_ACCOUNT_DELETE_REQUEST_SUCCESS';
export const BANK_ACCOUNT_DELETE_REQUEST_FAILURE = 'BANK_ACCOUNT_DELETE_REQUEST_FAILURE';
export const CLEAR_BANK_DELETE_LIST_DATA = 'CLEAR_BANK_DELETE_LIST_DATA';


// Bank Account Detail
export const DETAIL_BANK_ACCOUNT_REQUEST = 'DETAIL_BANK_ACCOUNT_REQUEST';
export const DETAIL_BANK_ACCOUNT_REQUEST_SUCCESS = 'DETAIL_BANK_ACCOUNT_REQUEST_SUCCESS';
export const DETAIL_BANK_ACCOUNT_REQUEST_FAILURE = 'DETAIL_BANK_ACCOUNT_REQUEST_FAILURE';
export const SET_DETAIL_BANK_ACCOUNT = 'SET_DETAIL_BANK_ACCOUNT';

// Bank Account Detail By ID
export const DETAIL_BANK_ACCOUNT_REQUESTBYID = 'DETAIL_BANK_ACCOUNT_REQUESTBYID';
export const DETAIL_BANK_ACCOUNT_REQUESTBYID_SUCCESS = 'DETAIL_BANK_ACCOUNT_REQUESTBYID_SUCCESS';
export const DETAIL_BANK_ACCOUNT_REQUESTBYID_FAILURE = 'DETAIL_BANK_ACCOUNT_REQUESTBYID_FAILURE';
export const SET_DETAIL_BANK_ACCOUNTBYID = 'SET_DETAIL_BANK_ACCOUNTBYID';

// Bank Account Edit
export const EDIT_BANK_ACCOUNT_REQUEST = 'EDIT_BANK_ACCOUNT_REQUEST';
export const EDIT_BANK_ACCOUNT_REQUEST_SUCCESS = 'EDIT_BANK_ACCOUNT_REQUEST_SUCCESS';
export const EDIT_BANK_ACCOUNT_REQUEST_FAILURE = 'EDIT_BANK_ACCOUNT_REQUEST_FAILURE';
export const SET_EDIT_BANK_ACCOUNT = 'SET_EDIT_BANK_ACCOUNT';