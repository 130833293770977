import * as ACTION_TYPES from './fundRequestsActionTypes';

// List fund requests actions
export const listFundRequestsRequest = () => ({
	type: ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST
});
export const listFundRequestsRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listFundRequestsRequestFailure = error => ({
	type: ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListFundRequestsData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_FUND_REQUESTS_DATA
});
// END List fund requests actions

// Add FundRequests  
export const postAddFundRequestsRequest = () => ({
	type: ACTION_TYPES.CREATE_FUND_REQUESTS_REQUEST
});

export const postAddFundRequestsRequestSuccess = (data) => ({
	type: ACTION_TYPES.CREATE_FUND_REQUESTS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const postAddFundRequestsRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_FUND_REQUESTS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateFundRequests = () => ({
	type: ACTION_TYPES.CLEAR_CREATE_FUND_REQUESTS
})
// END Add FundRequests 


// Edit FundRequests 
export const getEditFundRequestsRequest = () => ({
	type: ACTION_TYPES.EDIT_FUND_REQUESTS_REQUEST
});

export const getEditFundRequestsRequestSucces = (data) => ({
	type: ACTION_TYPES.EDIT_FUND_REQUESTS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getEditFundRequestsRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_FUND_REQUESTS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setFundRequestsToEdit = data => ({
	type: ACTION_TYPES.SET_FUND_REQUESTS_TO_EDIT,
	payload: {
		data
	}
});

export const setFundRequestsToDetail = data => ({
	type: ACTION_TYPES.SET_DETAIL_FUND_REQUESTS_DATA,
	payload: {
		data
	}
});



export const clearEditFundRequests = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_FUND_REQUESTS
})
// END Edit FundRequests

// Delete FundRequests 
export const getDeleteFundRequestsRequest = () => ({
	type: ACTION_TYPES.DELETE_FUND_REQUESTS_REQUEST
});

export const getDeleteFundRequestsRequestSuccess = (data) => ({
	type: ACTION_TYPES.DELETE_FUND_REQUESTS_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getDeleteFundRequestsRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_FUND_REQUESTS_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearDeleteFundRequests = () => ({
	type: ACTION_TYPES.CLEAR_DELETE_FUND_REQUESTS
})


// END Delete FundRequests

//FundRequest by id
export const getFundRequestsRequestId = () => ({
	type: ACTION_TYPES.LIST_FUND_REQUESTS_REQUEST_ID
});


export const getFundRequestsIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_FUND_REQUESTS_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getFundRequestsIDRequestFailure = error => ({
	type: ACTION_TYPES.LIST_FUND_REQUESTS_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});
//END FundRequest by id

//FundRequest list types
export const getTypeListFundRequests = () => ({
	type: ACTION_TYPES.LIST_TYPES_FUND_REQUESTS_REQUEST
});

export const getTypeListFundRequestsSuccess = data => ({
	type: ACTION_TYPES.LIST_TYPES_FUND_REQUESTS_SUCCESS,
	payload: {
		data
	}
});

export const getTypeListFundRequestsFailure = error => ({
	type: ACTION_TYPES.LIST_TYPES_FUND_REQUESTS_FAILURE,
	payload: {
		error
	}
});

export const setTypeListFundRequest = data => ({
	type: ACTION_TYPES.SET_TYPE_LIST_FUND_REQUEST,
	payload: {
		data
	}
});

export const clearTypeListFundRequests = () => ({
	type: ACTION_TYPES.CLEAR_TYPE_LIST_FUND_REQUEST,
});
//END FundRequest list types

//FundRequest list state
export const getStateListFundRequests = () => ({
	type: ACTION_TYPES.LIST_STATES_FUND_REQUESTS_REQUEST
});

export const getStateListFundRequestsSuccess = data => ({
	type: ACTION_TYPES.LIST_STATES_FUND_REQUESTS_SUCCESS,
	payload: {
		data
	}
});

export const getStateListFundRequestsFailure = error => ({
	type: ACTION_TYPES.LIST_STATES_FUND_REQUESTS_FAILURE,
	payload: {
		error
	}
});

export const setStateListFundRequest = data => ({
	type: ACTION_TYPES.SET_STATES_LIST_FUND_REQUEST,
	payload: {
		data
	}
});

export const clearStateListFundRequests = () => ({
	type: ACTION_TYPES.CLEAR_STATES_LIST_FUND_REQUEST,
});
//END FundRequest list state

//Budget Figurative FundRequest
export const getBudgetFigurativeFundRequest = () => ({
	type: ACTION_TYPES.BUDGET_FIGURATIVE_REQUESTS_REQUEST
});

export const getBudgetFigurativeFundRequestSuccess = data => ({
	type: ACTION_TYPES.BUDGET_FIGURATIVE_REQUESTS_SUCCESS,
	payload: {
		data
	}
});

export const getBudgetFigurativeFundRequestFailure = error => ({
	type: ACTION_TYPES.BUDGET_FIGURATIVE_REQUESTS_FAILURE,
	payload: {
		error
	}
});

export const clearBudgetFigurativeFundRequest = () => ({
	type: ACTION_TYPES.CLEAR_BUDGET_FIGURATIVE_REQUEST,
});
//END Budget Figurative FundRequest

// Fund Request Manual Items
export const setFundRequestManualItems = data => ({
	type: ACTION_TYPES.SELECTED_FUND_REQUEST_MANUAL_ITEM,
	payload: {
		data
	}
});
export const deletedFundRequestManualItems = data => ({
	type: ACTION_TYPES.DELETED_FUND_REQUEST_MANUAL_ITEM,
	payload: {
		data
	}
});
export const clearFundRequestManualItems = data => ({
	type: ACTION_TYPES.CLEAR_FUND_REQUEST_MANUAL_ITEM
});
// END Fund Request Manual Items
