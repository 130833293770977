import * as ACTION_TYPES from './affectationActionTypes';

export const affectationReducerInitialState = {
	newAffectationData: {
		selectedCredits: [],
		administrativeDocument: undefined,
		responseData: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	newDisaffectionData: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	creditQueryData: {
		creditSelected: undefined
	},
	affectationHistoryData: {
		creditSelected: undefined
	},
	affectationTypes: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	affectationTransactions: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	getAffectationsStatus: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	getAffectationsStatusOfSelectCredits: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	affectationSubcode: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	getBalancesOfSelectedCredits: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	setDateAffectation: {
		data: undefined
	},
	getAffectationHistoryTransactionsByIdData: {
		data: undefined,
		error: undefined,
		hasError: false
	},
	extractAffectation: {
		data: undefined
	},
	setRevertAffectation: {
		data: undefined
	},
	convertToPassiveRemaining: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	convertToPassiveRemainingByService: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reversePassiveRemaining: {
		isFetching: false,
		hasError: false,
		error: undefined
	},
	revertPassiveRemainingByService: {
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const affectationReducer = ( state=affectationReducerInitialState, action ) => {
	switch( action.type ) {
		// New affectation data actions
		case ACTION_TYPES.SET_NEW_AFFECTATION_DATA_SELECTED_CREDITS: {
			const { data } = action.payload;
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					selectedCredits: data
				}
			};
		}
		case ACTION_TYPES.CLEAR_NEW_AFFECTATION_DATA_SELECTED_CREDITS: {
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					selectedCredits: affectationReducerInitialState.newAffectationData.creditSelected
				}
			}
		}
		// END New affectation data actions



		// New affectation administrative document data actions
		case ACTION_TYPES.SET_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA: {
			const { data } = action.payload;
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					administrativeDocument: data
				}
			};
		}
		case ACTION_TYPES.CLEAR_NEW_AFFECTATION_ADMINISTRATIVE_DOCUMENT_DATA: {
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					administrativeDocument: affectationReducerInitialState.newAffectationData.administrativeDocument
				}
			};
		}
		// END New affectation administrative document data actions



		// Credit query data actions
		case ACTION_TYPES.SET_CREDIT_QUERY_DATA_CREDIT_SELECTED: {
			const { data } = action.payload;
			return {
				...state,
				creditQueryData: {
					...state.creditQueryData,
					creditSelected: data
				}
			};
		}
		case ACTION_TYPES.CLEAR_CREDIT_QUERY_DATA_CREDIT_SELECTED: {
			return {
				...state,
				creditQueryData: {
					...state.creditQueryData,
					creditSelected: affectationReducerInitialState.creditQueryData.creditSelected
				}
			}
		}
		// END Credit query data actions



		// Affectation history data actions
		case ACTION_TYPES.SET_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED: {
			const { data } = action.payload;
			return {
				...state,
				affectationHistoryData: {
					...state.affectationHistoryData,
					creditSelected: data
				}
			};
		}
		case ACTION_TYPES.CLEAR_AFFECTATION_HISTORY_DATA_CREDIT_SELECTED: {
			return {
				...state,
				affectationHistoryData: {
					...state.affectationHistoryData,
					creditSelected: affectationReducerInitialState.affectationHistoryData.creditSelected
				}
			}
		}
		// END Affectation history data actions
		


		// Affectation types request actions
		case ACTION_TYPES.GET_AFFECTATION_TYPES_REQUEST: {
			return {
				...state,
				affectationTypes: {
					...state.affectationTypes,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.affectationTypes.error,
					data: affectationReducerInitialState.affectationTypes.data
				}
			};
		}
		case ACTION_TYPES.GET_AFFECTATION_TYPES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				affectationTypes: {
					...state.affectationTypes,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.affectationTypes.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_AFFECTATION_TYPES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				affectationTypes: {
					...state.affectationTypes,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Affectation types request actions



		// Create Affectation request actions
		case ACTION_TYPES.CREATE_AFFECTATION_REQUEST: {
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					isFetching: true,
					hasError: false,
					responseData: affectationReducerInitialState.newAffectationData.responseData,
					error: affectationReducerInitialState.newAffectationData.error
				}
			};
		}
		case ACTION_TYPES.CREATE_AFFECTATION_REQUEST_SUCCESS: {
			const { responseData } = action.payload;
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.newAffectationData.error,
					responseData
				}
			};
		}
		case ACTION_TYPES.CREATE_AFFECTATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				newAffectationData: {
					...state.newAffectationData,
					isFetching: false,
					hasError: true,
					responseData: affectationReducerInitialState.newAffectationData.responseData,
					error
				}
			};
		}
		// END Create Affectation request actions

		// Create disaffection request actions
		case ACTION_TYPES.CREATE_DISAFFECTION_REQUEST: {
			return {
				...state,
				newDisaffectionData: {
					...state.newDisaffectionData,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.newDisaffectionData.error
				}
			};
		}
		case ACTION_TYPES.CREATE_DISAFFECTION_REQUEST_SUCCESS: {
			const { responseData } = action.payload;
			return {
				...state,
				newDisaffectionData: {
					...state.newDisaffectionData,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.newDisaffectionData.error
				}
			};
		}
		case ACTION_TYPES.CREATE_DISAFFECTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				newDisaffectionData: {
					...state.newDisaffectionData,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Create Disaffection request actions

		// Affectation transaction request actions
		case ACTION_TYPES.GET_AFFECTATION_TRANSACTIONS_REQUEST: {
			return {
				...state,
				affectationTransactions: {
					...state.affectationTransactions,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.affectationTransactions.error,
					data: affectationReducerInitialState.affectationTransactions.data
				}
			};
		}
		case ACTION_TYPES.GET_AFFECTATION_TRANSACTIONS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				affectationTransactions: {
					...state.affectationTransactions,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.affectationTransactions.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_AFFECTATION_TRANSACTIONS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				affectationTransactions: {
					...state.affectationTransactions,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_AFFECTATION_TRANSACTIONS: {
			return {
				...state,
				affectationTransactions: affectationReducerInitialState.affectationTransactions
			}
		}
		// END Affectation transaction request actions



		// Get availables affectation status actions handling
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_REQUEST: {
			return {
				...state,
				getAffectationsStatus: {
					...state.getAffectationsStatus,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.getAffectationsStatus.error,
					data: affectationReducerInitialState.getAffectationsStatus.data
				}
			};	
		}
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getAffectationsStatus: {
					...state.getAffectationsStatus,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.getAffectationsStatus.error,
					data
				}
			};	
		}
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getAffectationsStatus: {
					...state.getAffectationsStatus,
					isFetching: false,
					hasError: true,
					data: affectationReducerInitialState.getAffectationsStatus.data,
					error
				}
			};	
		}
		// END Get availables affectation status actions handling



		// Get availables affectation status of selected credits actions handling
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST: {
			return {
				...state,
				getAffectationsStatusOfSelectCredits: {
					...state.getAffectationsStatusOfSelectCredits,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.getAffectationsStatusOfSelectCredits.error,
					data: affectationReducerInitialState.getAffectationsStatusOfSelectCredits.data
				}
			};	
		}
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getAffectationsStatusOfSelectCredits: {
					...state.getAffectationsStatusOfSelectCredits,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.getAffectationsStatusOfSelectCredits.error,
					data
				}
			};	
		}
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_STATUS_OF_SELECTED_CREDITS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getAffectationsStatusOfSelectCredits: {
					...state.getAffectationsStatusOfSelectCredits,
					isFetching: false,
					hasError: true,
					data: affectationReducerInitialState.getAffectationsStatusOfSelectCredits.data,
					error
				}
			};	
		}
		// END Get availables affectation status of selected credits actions handling


		
		// Get affectation subcode actions handling
		case ACTION_TYPES.GET_AFFECTATION_SUBCODE_REQUEST: {
			return {
				...state,
				affectationSubcode: {
					...state.affectationSubcode,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.affectationSubcode.error,
					data: affectationReducerInitialState.affectationSubcode.data
				}
			};	
		}
		case ACTION_TYPES.GET_AFFECTATION_SUBCODE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				affectationSubcode: {
					...state.affectationSubcode,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.affectationSubcode.error,
					data
				}
			};	
		}
		case ACTION_TYPES.GET_AFFECTATION_SUBCODE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				affectationSubcode: {
					...state.affectationSubcode,
					isFetching: false,
					hasError: true,
					data: affectationReducerInitialState.affectationSubcode.data,
					error
				}
			};	
		}
		// END Get affectation subcode status actions handling



		// Get availables affectation status of selected credits actions handling
		case ACTION_TYPES.GET_BALANCES_OF_SELECTED_CREDITS_REQUEST: {
			return {
				...state,
				getBalancesOfSelectedCredits: {
					...state.getBalancesOfSelectedCredits,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.getBalancesOfSelectedCredits.error,
					data: affectationReducerInitialState.getBalancesOfSelectedCredits.data
				}
			};	
		}
		case ACTION_TYPES.GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getBalancesOfSelectedCredits: {
					...state.getBalancesOfSelectedCredits,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.getBalancesOfSelectedCredits.error,
					data
				}
			};	
		}
		case ACTION_TYPES.GET_BALANCES_OF_SELECTED_CREDITS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getBalancesOfSelectedCredits: {
					...state.getBalancesOfSelectedCredits,
					isFetching: false,
					hasError: true,
					data: affectationReducerInitialState.getBalancesOfSelectedCredits.data,
					error
				}
			};	
		}
		// END Get availables affectation status of selected credits actions handling

		// Set date affectation actions
		case ACTION_TYPES.SET_DATE_AFFECTARION_DATA: {
			const { data } = action.payload;
			return {
				...state,
				setDateAffectation: data
			}
		}
		case ACTION_TYPES.CLEAR_DATE_AFFECTARION_DATA: {
			return {
					...state,
					setDateAffectation: affectationReducerInitialState.setDateAffectation
			};
		}
		// END New affectation administrative document data actions

		//Get available affectations history transaction by id actions
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST: {
			return {
				...state,
				getAffectationHistoryTransactionsByIdData: {
					...state.getAffectationHistoryTransactionsByIdData,
					data: affectationReducerInitialState.getAffectationHistoryTransactionsByIdData.data,
					hasError: false,
					error: undefined
				}
			};
		}
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getAffectationHistoryTransactionsByIdData: {
					...state.getAffectationHistoryTransactionsByIdData,
					hasError: false,
					error: affectationReducerInitialState.getAffectationHistoryTransactionsByIdData.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_AVAILABLES_AFFECTATION_HISTORY_TRANSACTION_BY_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getAffectationHistoryTransactionsByIdData: {
					...state.getAffectationHistoryTransactionsByIdData,
					hasError: true,
					data: affectationReducerInitialState.getAffectationHistoryTransactionsByIdData.data,
					error
				}
			};
		}
		//END available affectations history transaction by id

		// Set extract affectation actions
		case ACTION_TYPES.SET_EXTRACT_AFFECTATION_DATA: {
			const { data } = action.payload;
			return {
				...state,
				extractAffectation: data
			}
		}
		case ACTION_TYPES.CLEAR_EXTRACT_AFFECTATION_DATA: {
			return {
					...state,
					extractAffectation: affectationReducerInitialState.extractAffectation
			};
		}
		// END Set extract affectation actions

		// Set revert affectation actions
		case ACTION_TYPES.SET_REVERT_AFFECTATION: {
			const { data } = action.payload;
			return {
				...state,
				setRevertAffectation: data
			}
		}
		// END Set revert affectation actions

		// Conversion to passive remaining
		case ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST: {
			return {
				...state,
				convertToPassiveRemaining: {
					...state.convertToPassiveRemaining,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.convertToPassiveRemaining.error
				}
			};
		}
		case ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_SUCCESS: {
			
			return {
				...state,
				convertToPassiveRemaining: {
					...state.convertToPassiveRemaining,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.convertToPassiveRemaining.error
				}
			};
		}
		case ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				convertToPassiveRemaining: {
					...state.convertToPassiveRemaining,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Conversion to passive remaining

		// Conversion to passive remaining by service
		case ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST: {
			return {
				...state,
				convertToPassiveRemainingByService: {
					...state.convertToPassiveRemainingByService,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.convertToPassiveRemainingByService.error
				}
			};
		}
		case ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS: {
			
			return {
				...state,
				convertToPassiveRemainingByService: {
					...state.convertToPassiveRemainingByService,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.convertToPassiveRemainingByService.error
				}
			};
		}
		case ACTION_TYPES.CREATE_CONVERSION_TO_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				convertToPassiveRemainingByService: {
					...state.convertToPassiveRemainingByService,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END Conversion to passive remaining by service

		// reverse passive remaining
		case ACTION_TYPES.REVERSE_PASSIVE_REMAINING_REQUEST: {
			return {
				...state,
				reversePassiveRemaining: {
					...state.reversePassiveRemaining,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.reversePassiveRemaining.error
				}
			};
		}
		case ACTION_TYPES.REVERSE_PASSIVE_REMAINING_REQUEST_SUCCESS: {
			
			return {
				...state,
				reversePassiveRemaining: {
					...state.reversePassiveRemaining,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.reversePassiveRemaining.error
				}
			};
		}
		case ACTION_TYPES.REVERSE_PASSIVE_REMAINING_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reversePassiveRemaining: {
					...state.reversePassiveRemaining,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END reverse passive remaining

		// revert passive remaining by service
		case ACTION_TYPES.REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST: {
			return {
				...state,
				revertPassiveRemainingByService: {
					...state.revertPassiveRemainingByService,
					isFetching: true,
					hasError: false,
					error: affectationReducerInitialState.revertPassiveRemainingByService.error
				}
			};
		}
		case ACTION_TYPES.REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_SUCCESS: {
			
			return {
				...state,
				revertPassiveRemainingByService: {
					...state.revertPassiveRemainingByService,
					isFetching: false,
					hasError: false,
					error: affectationReducerInitialState.revertPassiveRemainingByService.error
				}
			};
		}
		case ACTION_TYPES.REVERT_PASSIVE_REMAINING_BY_SERVICE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				revertPassiveRemainingByService: {
					...state.revertPassiveRemainingByService,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}
		// END revert passive remaining by service

		default: {
			return state;
		}
	}
};

// Get affectation reducer
export const getAffectationReducer = state => state.affectationReducer;

// Get new affectation
export const getNewAffectationData = state => getAffectationReducer(state)?.newAffectationData;
export const gettNewAffectationIsFetching = state => getNewAffectationData(state)?.isFetching;
export const getNewAffectationSelectedCredits = state => getNewAffectationData(state)?.selectedCredits;
export const getNewAffectationAdministrativeDocumentData = state => getNewAffectationData(state)?.administrativeDocument;
export const getNewAffectationResponseData = state => getNewAffectationData(state)?.responseData;

// Get credit query data
export const getCreditQueryData = state => getAffectationReducer(state)?.creditQueryData;
export const getCreditQueryDataSelected = state => getCreditQueryData(state)?.creditSelected;

// Get affectation history data
export const getAffectationHistoryData = state => getAffectationReducer(state)?.affectationHistoryData;
export const getAffectationHistoryDataSelected = state => getAffectationHistoryData(state)?.creditSelected;

// Get affectation types
export const getAffectationTypes = state => getAffectationReducer(state)?.affectationTypes;
export const getAffectationTypesData = state => getAffectationTypes(state)?.data;

// Get affectation transactions
export const getAffectationTransactions = state => getAffectationReducer(state)?.affectationTransactions;
export const getAffectationTransactionsData = state => getAffectationTransactions(state)?.data;
export const getAffectationTransactionsisFetching = state => getAffectationTransactions(state)?.isFetching;

//Get affectation history transaction by id
export const getAffectationHistoryTransactionsByIdData = state => getAffectationReducer(state)?.getAffectationHistoryTransactionsByIdData;

// Get affectation subcode
export const getAffectationSubcode = state => getAffectationReducer(state)?.affectationSubcode;
export const getAffectationSubcodeData = state => getAffectationSubcode(state)?.data;
export const getAffectationSubcodeisFetching = state => getAffectationSubcode(state)?.isFetching;

// Set date affectation
export const setDateAffectationData = state => getAffectationReducer(state)?.setDateAffectation;

// Get Set extract affectation
export const getSetExtractAffectationData = state => getAffectationReducer(state)?.extractAffectation;

// Set revert affectation
export const setRevertAffectationData = state => getAffectationReducer(state)?.setRevertAffectation;

// Expedient to passive remaining
export const getExpedientToPassiveRemainingIsFetching = state => getAffectationReducer(state)?.convertToPassiveRemaining?.isFetching;

// Conversion to passive remaining by service
export const getExpedientToPassiveRemainingByServiceIsFetching = state => getAffectationReducer(state)?.convertToPassiveRemainingByService?.isFetching;

// reverse passive remaining
export const getReversePassiveRemainingIsFetching = state => getAffectationReducer(state)?.reversePassiveRemaining?.isFetching;

// revert passive remaining by servcie
export const getRevertPassiveRemainingByServcieIsFetching = state => getAffectationReducer(state)?.revertPassiveRemainingByService?.isFetching;