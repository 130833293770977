import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';

const MenuSecurity = () => {
	const dispatch = useDispatch();
    const { 
        profileName,
        securityPermissions,
        paramsToAlerts
    } = usePermissions();

    if (!securityPermissions?.canView) return null;

    return (
        <NavDropdown 
            className='active' 
            title={<><FontAwesomeIcon icon={faLock} className='mr-1' />Seguridad</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {securityPermissions?.users?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.USER_LIST} title="Usuarios" />
            }
            {securityPermissions?.profiles?.canView && 
                <NavDropdownItem route={ROUTE_PATHS.PROFILE_LIST} title="Grupos" />
            }
        </NavDropdown>
    );
};

export default MenuSecurity;