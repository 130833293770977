import * as ACTIONS from './administrativeServiceActions';
import * as SERVICES from 'src/services/administrativeServiceServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';


// Try list administrative services
export const tryListAdministrativeServices = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.listAdministrativeService(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listAdministrativeServicesRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listAdministrativeServicesRequestFailure(response?.response?.data));
				showError(`Error al obtener los servicios. Detalle: ${response?.response?.data?.message || response}`);
			}
		}

	};
}

// Try list all administrative services
export const tryListAllAdministrativeServices = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listAllAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.listAllAdministrativeService(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.listAllAdministrativeServicesRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listAllAdministrativeServicesRequestFailure(response?.response?.data));
				showError(`Error al obtener todos los servicios. Detalle: ${response?.response?.data?.message || response}`);
			}
		}

	};
}

//Create
export const tryPostAdministrativeService = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getAddServiceAdministrativeRequest());

		if (accessToken) {

			const response = await SERVICES.newAdministrativeService(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getAddServiceAdministrativeRequestSuccess(response?.data));
				showSuccess('Servicio administrativo registrado correctamente.');
			}
			else {
				dispatch(ACTIONS.getAddServiceAdministrativeRequestFailure(response?.data?.error));
				showError(`Error al crear el servicio administrativo. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Edit
export const tryPutAdministrativeService = (serviceId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getEditServiceAdministrativeRequest());

		if (accessToken) {

			const response = await SERVICES.editAdministrativeService(accessToken, serviceId, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getEditServiceAdministrativeRequestSucces(response?.data));
				showSuccess('Servicio administrativo editado correctamente.');
			}
			else {
				dispatch(ACTIONS.getEditServiceAdministrativeRequestFailure(response?.data?.error));
				showError(`Error al editar el servicio administrativo. Detalle: ${response?.data?.message}`);
			}
			return response
		}
	};
};

//Delete
export const tryDeleteAdministrativeService = (params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getDeleteServiceAdministrativeRequest());

		if (accessToken) {

			const response = await SERVICES.deleteAdministrativeService(accessToken, params);
			if (response?.status == 200) {
				dispatch(ACTIONS.getDeleteServiceAdministrativeRequestSuccess(response?.data));
				showSuccess('Servicio administrativo eliminado correctamente.');
			}
			else {
				dispatch(ACTIONS.getDeleteServiceAdministrativeRequestFailure(response?.data?.error));
				showError(`Error al eliminar el servicio administrativo. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

// Try list administrative service types
export const tryListAdministrativeserviceTypes = param => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listAdministrativeServiceTypeRequest());

		if (accessToken) {

			const response = await SERVICES.listAdministrativeServiceTypes(accessToken, param);

			if (response?.status == 200) {
				dispatch(ACTIONS.listAdministrativeServiceTypeRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.listAdministrativeServiceTypeRequestFailure(response?.response?.data || response));
				showError(`Error al obtener los tipos de servicio. Detalle: ${response?.response?.data?.message || response}`)
			}
		}

	};
};

// Try Set Organism administrative services
export const tryGetOrganismAdministrativeServices = (administrativeServicesId = '', exerciseId = '') => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getOrganismAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.getAdministrativeServiceOrganismId(accessToken, administrativeServicesId, exerciseId);

			if (response?.status === 200) {
				dispatch(ACTIONS.getOrganismAdministrativeServicesRequestSuccess(response?.data));
				return response?.data;
			}
			else {
				dispatch(ACTIONS.getOrganismAdministrativeServicesRequestFailure(response?.error || response));
				showError(`Error al cargar el organismo administrativo. Detalle: ${response?.error || response}`);
			}
		}

	};
};

//Add Organism Administrative Service
export const tryAddOrganismAdministrativeService = (administrativeServicesId, excerciseId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.addOrganismAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.addOrganismAdministrativeService(accessToken, administrativeServicesId, excerciseId, params);

			if (response?.status == 201) {
				dispatch(ACTIONS.addOrganismAdministrativeServicesRequestSuccess(response?.data));
				showSuccess('Organismo asignado correctamente.');
			}
			else {
				dispatch(ACTIONS.addOrganismAdministrativeServicesRequestFailure(response?.data?.error));
				showError(`Error al asignar el organismo. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};

//Remove Organism Administrative Service
export const tryRemoveOrganismAdministrativeService = (administrativeServicesId, excerciseId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.removeOrganismAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.removeOrganismAdministrativeService(accessToken, administrativeServicesId, excerciseId, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.removeOrganismAdministrativeServicesRequestSuccess(response?.data));
				showSuccess('Organismo quitado correctamente.');
			}
			else {
				dispatch(ACTIONS.removeOrganismAdministrativeServicesRequestFailure(response?.data?.error));
				showError(`Error al quitar el organismo. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};


// Try Set Users administrative services
export const tryGetUsersAdministrativeServices = administrativeServicesId => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getUsersAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.getAdministrativeServiceUserId(accessToken, administrativeServicesId);

			if (response?.status === 200) {
				dispatch(ACTIONS.getUsersAdministrativeServicesRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getUsersAdministrativeServicesRequestFailure(response?.error || response));
				showError(`Error al cargar los usuarios del servicio. Detalle: ${response?.error || response}`);
			}
			return response;
		}

	};
};

//Add Users Administrative Service
export const tryAddUserAdministrativeService = (administrativeServicesId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.addUsersAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.addUserAdministrativeService(accessToken, administrativeServicesId, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.addUsersAdministrativeServicesRequestSuccess(response?.data));
				showSuccess('Usuario asignado correctamente.');
			}
			else {
				dispatch(ACTIONS.addUsersAdministrativeServicesRequestFailure(response?.data?.error));
				showError(`Error al asignar el usuario. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Remove Users Administrative Service
export const tryRemoveUserAdministrativeService = (administrativeServicesId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.removeUsersAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.removeUserAdministrativeService(accessToken, administrativeServicesId, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.removeUsersAdministrativeServicesRequestSuccess(response?.data));
				showSuccess('Usuario quitado correctamente.');
			}
			else {
				dispatch(ACTIONS.removeUsersAdministrativeServicesRequestFailure(response?.data?.error));
				showError(`Error al quitar el usuario. Detalle: ${response?.data?.message}`);
			}
			return response
		}

	};
};

//Add all User Administrative Service
export const tryAddAllUsersAdministrativeService = (UserId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getAddAllUsersServiceAdministrativeRequest());

		if (accessToken) {

			const response = await SERVICES.addAllUsersServiceAdministrativeService(accessToken, UserId);

			if (response?.status == 200) {
				dispatch(ACTIONS.getAddAllUsersServiceAdministrativeRequestSuccess(response?.data));
				showSuccess('Usuario asignado a todos los servicios correctamente.');
			}
			else {
				dispatch(ACTIONS.getAddAllUsersServiceAdministrativeRequestFailure(response?.data?.error));
				showError(`Error al asignar el usuario a todos los servicios. Detalle: ${response?.responese?.data?.message}`);
			}
			return response
		}

	};
};

//Remove all User Administrative Service
export const tryRemoveAllUsersAdministrativeService = (UserId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getRemoveAllUsersServiceAdministrativeRequest());

		if (accessToken) {

			const response = await SERVICES.removeAllUsersServiceAdministrativeService(accessToken, UserId);

			if (response?.status == 200) {
				dispatch(ACTIONS.getRemoveAllUsersServiceAdministrativeRequestSuccess(response?.data));
				/* showSuccess('Usuario quitado de todos los servicios correctamente.'); */
			}
			else {
				dispatch(ACTIONS.getRemoveAllUsersServiceAdministrativeRequestFailure(response?.data?.error));
				showError(`Error al quitar el usuario de todos los servicios. Detalle: ${response?.response?.data?.message}`);
			}
			return response
		}

	};
};

//List all administrative services by User 
export const tryListAllServiceAdministrativeByUser = (UserId) => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getListAllServiceAdministrativeByUserRequest());

		if (accessToken) {
			const response = await SERVICES.listAllServiceAdministrativeByUser(accessToken, UserId);

			if (response?.status == 200) {
				dispatch(ACTIONS.setIdListServiceAdministrativeByUser(response?.data?.map((item)=>item.id).toString()));
				dispatch(ACTIONS.getListAllServiceAdministrativeByUserRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getListAllServiceAdministrativeByUserRequestFailure(response?.data?.error));
				showError(`Error al listar todos los servicios asignados a un usuario. Detalle: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};

export const tryListServicesByUserAndExercise = (UserId, excerciseId) => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getListServicesByUserAndExerciseRequest());

		if (accessToken) {
			const response = await SERVICES.listServicesByUserAndExercise(accessToken, UserId, excerciseId);

			if (response?.status == 200) {
				dispatch(ACTIONS.getListServicesByUserAndExerciseRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getListServicesByUserAndExerciseRequestFailure(response?.data?.error));
				showError(`Error al listar todos los servicios asignados a un usuario. Detalle: ${response?.response?.data?.message}`);
			}
			return response;
		}

	};
};

//Add New Users administrative services 
export const tryAddNewUserAdministrativeService = (administrativeServicesId, params) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.addNewUsersAdministrativeServicesRequest());

		if (accessToken) {

			const response = await SERVICES.addNewUserAdministrativeService(accessToken, administrativeServicesId, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.addNewUsersAdministrativeServicesRequestSuccess(response?.data));
				showSuccess('Usuario asignado al/los servicio/s correctamente.');
			}
			else {
				dispatch(ACTIONS.addNewUsersAdministrativeServicesRequestFailure(response?.data?.error));
				showError(`Error al asignar el usuario al servicio. Detalle: ${response?.data?.message}`);
			}
			return response;
		}

	};
};
//Add New Users administrative services 
export const trySearchAdministrativeServiceById = (administrativeServicesId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		dispatch(ACTIONS.getServiceAdministrativeByIdRequest());

		if (accessToken) {
			const response = await SERVICES.searchAdministrativeServiceById(accessToken, administrativeServicesId);
			if (response?.status == 200) {
				dispatch(ACTIONS.getServiceAdministrativeByIdRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getServiceAdministrativeByIdRequestFailure(response?.data?.error));
			}
			return response;
		}

	};
};

// Try Get Active administrative service from exercise and user
export const tryGetActiveAdminsitrativeServiceFromExerciseAndUser = (userId, exerciseId ) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		if(accessToken){

			dispatch(ACTIONS.getActiveAdminsitrativeServiceFromExerciseAndUserRequest(userId, exerciseId));
	
			const response = await SERVICES.getActiveAdministrativeServiceFromExerciseAndUser(accessToken, userId, exerciseId);

			if (response?.status == 200) {
				dispatch(ACTIONS.getActiveAdminsitrativeServiceFromExerciseAndUserRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getActiveAdminsitrativeServiceFromExerciseAndUserRequestFailure(response?.response));
				showError(`Error al buscar el estado del servicio administrativo del ejercicio según el usuario. Detalle: ${response?.response?.data?.message || response}`);
			}
	
			return response;
		}

	};
};