import * as ACTION_TYPES from './subconceptActionTypes';

// List
export const listSubconceptRequest = () => ({
	type: ACTION_TYPES.LIST_SUBCONCEPT_REQUEST
});
export const listSubconceptSuccess = data => ({
	type: ACTION_TYPES.LIST_SUBCONCEPT_SUCCESS,
	payload: {
		data
	}
});
export const listSubconceptFailure = error => ({
	type: ACTION_TYPES.LIST_SUBCONCEPT_FAILURE,
	payload: {
		error
	}
});