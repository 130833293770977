import * as ACTION_TYPES from './jurisdictionActionTypes';



// Jurisdiction list actions
export const getJurisdictionListRequest = () => ({
	type: ACTION_TYPES.LIST_JURISDICTION_REQUEST
});

export const getJurisdictionListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_JURISDICTION_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getJurisdictionListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_JURISDICTION_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearJurisdictionList = () => ({
	type: ACTION_TYPES.CLEAR_JURISDICTION_LIST
});
// END Jurisdiction list actions


// Jurisdictions by Services and Exercise actions
export const getJurisdictionsByServicesAndExerciseRequest = () => ({
	type: ACTION_TYPES.JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST
});

export const getJurisdictionsByServicesAndExerciseRequestSuccess = data => ({
	type: ACTION_TYPES.JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getJurisdictionsByServicesAndExerciseRequestFailure = error => ({
	type: ACTION_TYPES.JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearJurisdictionsByServicesAndExerciseRequest = () => ({
	type: ACTION_TYPES.CLEAR_JURISDICTIONS_BY_SERVICESID_AND_EXERCISEID
});
// END Jurisdictions by Services and Exercise actions