import { config } from 'src/env.js';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, httpPut, httpDelete, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.coreApi.URL}`;
const CAPTION_ENDPOINT_URL = '/captions';

//Caption list
export const listCaption = async ( accessToken, params  ) => {
	const options = buildDefaultOptions(accessToken);
	const jsonFilter = params?.filter ? JSON.stringify(params?.filter) : undefined;

	const captionURLQuery = {
		year: params?.year,
		caption: params?.caption,
        page: params?.page,
		size: params?.pageSize,
		sort: params?.sort
	}
	
	const queryString = buildURLQuery(captionURLQuery);
	const URL = `${API_BASE_URI}${CAPTION_ENDPOINT_URL}?${queryString}`;
	
	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

// Create caption
export const createCaption = async (accessToken, data) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${CAPTION_ENDPOINT_URL}`;

	let response;

	try {
		response = await httpPost(URL, data, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

//Delete caption
export const deleteCaption = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${API_BASE_URI}${CAPTION_ENDPOINT_URL}/${id}`;

	let response;
	
	try {
		response = await httpDelete(URL, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};

//Edit caption
export const editCaption = async (accessToken, data) => {

	const URL = `${API_BASE_URI}${CAPTION_ENDPOINT_URL}/${data?.year}`;
	const options = buildDefaultOptions(accessToken);

	const params = {
		year: data?.year,
		caption: data?.caption
	};

	let response;
	
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Detail caption
export const getCaptionById = async (accessToken, captionId) => {
	const URL = `${API_BASE_URI}${CAPTION_ENDPOINT_URL}/${captionId}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
}

