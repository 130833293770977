import React, { useEffect } from 'react';
import {  Button, Card, Table, Modal } from 'react-bootstrap';
import { formatterPeso } from 'src/utils/utils';
import { useDispatch } from 'react-redux';

const FigurativeDetailModal = props => {
    const dispatch = useDispatch();

	const { show, setShow, detail } = props;
	return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title className='text-uppercase'>{`Detalle de Figurativas`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
				<Card variant="success" className="mt-4 mb-3">
                    <Card.Header>
                        <h6 className="mb-0 text-left text-white">
                            Registro de Financiamiento de Partidas Figurativas
                        </h6>
                    </Card.Header>
                </Card>
                <div className="d-flex">
                    <h6 className="text-left text-black-color mr-5">
                        Partida :  {detail?.presupuestoResponse.subCodeCompleteNumber}
                    </h6>
                    <h6 className="text-left text-black-color">
                        Importe: &nbsp;<strong>{formatterPeso.format(detail?.presupuestoResponse.amount)}</strong>
                    </h6>
                </div>
                            <Table striped bordered hover size="sm" responsive>
                                <thead>
                                    <tr>
                                        <th className="text-center align-middle" width="30%">
                                            Figurativa
                                        </th>
                                        <th className="text-center align-middle" width="30%">
                                            Nombre
                                        </th>
                                        <th className="text-center align-middle" width="20%">
                                            Importe
                                        </th>
                                    </tr>
                                </thead>
                              <tbody className="text-black-color">
                                    {detail?.figurativeExpenditures?.map( budg =>
                                        <>
                                            <tr>
                                                <td className="text-center align-middle">
                                                    {budg?.filterFigurativeExpenditure.budgetTarget}
                                                </td>
                                                <td className="text-center align-middle">
                                                    {budg?.filterFigurativeExpenditure.budgetTargetVisibleName}
                                                </td>
                                                <td className="text-right align-middle">
                                                    {formatterPeso.format(budg?.amount)}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </Table>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )

}

export default FigurativeDetailModal;