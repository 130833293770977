import * as ACTIONS from './principalBudgetActions';
import * as SECTOR_SERVICES from 'src/services/sectorServices';

import { getAccessToken } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

export const tryGetPrincipalBudgetListBySectorId = sectorId => {
	return async ( dispatch, getState ) => {
		const state = getState();
		const accessToken = getAccessToken( state );

		dispatch( ACTIONS.getPrincipalBudgetListBySectorIdRequest(sectorId) );

		if(accessToken){

			const response = await SECTOR_SERVICES.listPrincipalBudgetBySectorId( accessToken, sectorId );
			
			if( response?.status == 200 ) {
				dispatch( ACTIONS.getPrincipalBudgetListBySectorIdRequestSuccess(response?.data) );
				return response?.data;
			}
			else {
				dispatch( ACTIONS.getPrincipalBudgetListBySectorIdRequestFailure(response?.error) );
				showError(`Error al cargar las partidas principales. Detalle: ${response?.error}` );
			}
		}

	};
};