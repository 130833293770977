import * as ACTION_TYPES from './legalInstrumentActionTypes';

// List legal instrument
export const listLegalInstrumentRequest = () => ({
	type: ACTION_TYPES.LIST_LEGAL_INSTRUMENT_REQUEST
});
export const listLegalInstrumentRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_LEGAL_INSTRUMENT_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listLegalInstrumentRequestFailure = error => ({
	type: ACTION_TYPES.LIST_LEGAL_INSTRUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListLegalInstrument = () => ({
	type: ACTION_TYPES.CLEAR_LIST_LEGAL_INSTRUMENT
});
// END List legal instrument



// Create legal instrument
export const createLegalInstrumentRequest = () => ({
	type: ACTION_TYPES.CREATE_LEGAL_INSTRUMENT_REQUEST
});
export const createLegalInstrumentRequestSuccess = () => ({
	type: ACTION_TYPES.CREATE_LEGAL_INSTRUMENT_REQUEST_SUCCESS
});
export const createLegalInstrumentRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_LEGAL_INSTRUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Create legal instrument



// Download file of legal instrument
export const downloadFileOfLegalInstrumentRequest = () => ({
	type: ACTION_TYPES.DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST
});
export const downloadFileOfLegalInstrumentRequestSuccess = () => ({
	type: ACTION_TYPES.DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_SUCCESS
});
export const downloadFileOfLegalInstrumentRequestFailure = error => ({
	type: ACTION_TYPES.DOWNLOAD_FILE_OF_LEGAL_INSTRUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Download file of legal instrument



// Upload file to legal instrument
export const uploadFileToLegalInstrumentRequest = () => ({
	type: ACTION_TYPES.UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST
});
export const uploadFileToLegalInstrumentRequestSuccess = () => ({
	type: ACTION_TYPES.UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_SUCCESS
});
export const uploadFileToLegalInstrumentRequestFailure = error => ({
	type: ACTION_TYPES.UPLOAD_FILE_TO_LEGAL_INSTRUMENT_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Upload file to legal instrument