import React from 'react';
import FundRequestForm from 'src/components/forms/fundRequest/PDForms/FundRequestForm';
import FundRequestGeneralTreasuryForm from 'src/components/forms/fundRequest/PDForms/FundRequestGeneralTreasuryForm';
import FundPermanentRequestForm from 'src/components/forms/fundRequest/PDForms/FundPermanentRequestForm';
import FundProvisionAgainstDeliveryForm from 'src/components/forms/fundRequest/PDForms/FundProvisionAgainstDeliveryForm';
import ProvidersFundRequestForm from 'src/components/forms/fundRequest/PDForms/ProvidersFundRequestForm';
import NewHaberesFundRequestForm from './PDForms/NewHaberesFundRequestForm';
import FundRequestManualForm from './PDForms/FundRequestManualForm';

const PDFormFactory = props => {

	const { formName } = props;

	const namesMap = {
		'PEDIDO_DE_FONDOS': FundRequestForm,
		'PEDIDO_DE_FONDOS_MANUAL': FundRequestManualForm,
		'PEDIDO_DE_FONDOS_DE_PROVEEDORES': ProvidersFundRequestForm,
		'PEDIDO_DE_FONDOS_DE_HABERES': NewHaberesFundRequestForm,
		'PEDIDO_DE_FONDOS_DE_EROGACIONES_FIGURATIVAS': '',
		'PEDIDO_DE_FONDOS_DE_INSTITUCION_DE_FONDOS_PERMANENTES': FundPermanentRequestForm,
		'PEDIDO_DE_FONDOS_DE_REINTEGRO_TESORERIA_GENERAL': FundRequestGeneralTreasuryForm,
		'PEDIDO_DE_FONDOS_DE_CONTADO_CONTRA_ENTREGA': FundProvisionAgainstDeliveryForm,
		'PEDIDO_DE_FONDOS_DE_ANTICIPO_DE_CUENTA_RENTAS_GENERALES': '',
		'PEDIDO_DE_FONDOS_DE_ANTICIPO_DE_CUENTA_RENTAS_GENERALES_HABERES': '',
	};

	const calcTemplate= formName => {
		return namesMap[formName] ? React.createElement( namesMap[formName] ) : <> Pedido de Fondo sin establecer </>;
	}

	return (
		calcTemplate(formName)
	);
}

export default PDFormFactory;