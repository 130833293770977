import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const NavDropdownItem = ({ route, title }) => {
  const dispatch = useDispatch();

  return (
    <NavDropdown.Item onClick={() => dispatch(push(route))}>
        {title}
    </NavDropdown.Item>
  );
};

export default NavDropdownItem;