import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const MessageComponent = props => {

    const { message, alertType } = props;

    return (
        <Row>
            <Form.Label htmlFor='number' column sm='4'></Form.Label>
            <Col sm='4' className='paddingform'>
                <div className={`d-flex alert alert-${alertType} mx-1`} role='alert'>
                    <p className='m-0'>{message}</p>
                </div>
            </Col>
        </Row>
    );
};

export default MessageComponent;