import * as ACTION_TYPES from './administrativeDocumentActionTypes';

export const administrativeDocumentReducerInitialState = {
	administrativeDocumentData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeListAllDocumentData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeDocumentHistory: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	latestAffectationTransaction: {
	data: undefined,
	isFetching: false,
	hasError: false,
	error: undefined
	},
	expedientCredit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeDocumentSubcodeRelated: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeDocumentModifPreload: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	selectedRPToConvert: {
		data: [],
		isFetching: false,
		hasError: false,
		error: undefined
	},
	administrativeDocumentAvailables: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};



export const administrativeDocumentReducer = (state = administrativeDocumentReducerInitialState, action) => {
	switch (action.type) {

		// Get administrative document actions
		case ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST: {
			return {
				...state,
				administrativeDocumentData: {
					...state.administrativeDocumentData,
					isFetching: true,
					hasError: false,
					error: administrativeDocumentReducerInitialState.administrativeDocumentData.error,
					data: administrativeDocumentReducerInitialState.administrativeDocumentData.data
				}
			}
		}
		case ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeDocumentData: {
					...state.administrativeDocumentData,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_ONE_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeDocumentData: {
					...state.administrativeDocumentData,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_ONE_ADMINISTRATIVE_DOCUMENT_DATA: {
			return {
				...state,
				administrativeDocumentData: administrativeDocumentReducerInitialState.administrativeDocumentData
			}
		}

		case ACTION_TYPES.GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST: {
			return {
				...state,
				administrativeListAllDocumentData: {
					...state.administrativeListAllDocumentData,
					isFetching: true,
					hasError: false,
					error: administrativeDocumentReducerInitialState.administrativeListAllDocumentData.error,
					data: administrativeDocumentReducerInitialState.administrativeListAllDocumentData.data
				}
			}
		}

		case ACTION_TYPES.GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeListAllDocumentData: {
					...state.administrativeListAllDocumentData,
					isFetching: false,
					hasError: false,
					error: administrativeDocumentReducerInitialState.administrativeListAllDocumentData.error,
					data
				}
			}
		}
		case ACTION_TYPES.GET_LIST_ALL_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeListAllDocumentData: {
					...state.administrativeListAllDocumentData,
					isFetching: false,
					hasError: true,
					data: administrativeDocumentReducerInitialState.administrativeListAllDocumentData.data,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_LIST_ALL_ADMINISTRATIVE_DOCUMENT_DATA: {
			return {
				...state,
				administrativeListAllDocumentData: administrativeDocumentReducerInitialState.administrativeListAllDocumentData
			}
		}
		// END Get administrative document actions

		// Get administrative document history actions
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST: {
			return {
				...state,
				administrativeDocumentHistory: {
					...state.administrativeDocumentHistory,
					isFetching: true,
					hasError: false,
					error: administrativeDocumentReducerInitialState.administrativeDocumentHistory.error,
					data: administrativeDocumentReducerInitialState.administrativeDocumentHistory.data
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeDocumentHistory: {
					...state.administrativeDocumentHistory,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_HISTORY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeDocumentHistory: {
					...state.administrativeDocumentHistory,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_HISTORY_DATA: {
			return {
				...state,
				administrativeDocumentHistory: administrativeDocumentReducerInitialState.administrativeDocumentHistory
			}
		}
		// END Get administrative document history actions

		// Get latest affectation transaction actions
		case ACTION_TYPES.GET_LATEST_AFFECTATION_TRANSACTION_REQUEST: {
			return {
				...state,
				latestAffectationTransaction: {
					...state.latestAffectationTransaction,
					isFetching: true,
					hasError: false,
					error: administrativeDocumentReducerInitialState.latestAffectationTransaction.error,
					data: administrativeDocumentReducerInitialState.latestAffectationTransaction.data
				}
			}
		}
		case ACTION_TYPES.GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				latestAffectationTransaction: {
					...state.latestAffectationTransaction,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_LATEST_AFFECTATION_TRANSACTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				latestAffectationTransaction: {
					...state.latestAffectationTransaction,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_LATEST_AFFECTATION_TRANSACTION_DATA: {
			return {
				...state,
				latestAffectationTransaction: administrativeDocumentReducerInitialState.latestAffectationTransaction
			}
		}
		// END Get latest affectation transaction actions

		// Get expedient by id budget credits
		case ACTION_TYPES.GET_EXPEDIENT_BY_ID_REQUEST: {
			return {
				...state,
				expedientCredit: {
					...state.expedientCredit,
					isFetching: true,
				}
			}
		}
		case ACTION_TYPES.GET_EXPEDIENT_BY_ID_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				expedientCredit: {
					...state.expedientCredit,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_EXPEDIENT_BY_ID_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				expedientCredit: {
					...state.expedientCredit,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_EXPEDIENT_BY_ID: {
			return {
				...state,
				expedientCredit: administrativeDocumentReducerInitialState.expedientCredit
			}
		}

		// END Get expedient by id budget credits

		// Get administrative document subcodes related
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_REQUEST: {
			return {
				...state,
				administrativeDocumentSubcodeRelated: {
					...state.administrativeDocumentSubcodeRelated,
					isFetching: true,
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeDocumentSubcodeRelated: {
					...state.administrativeDocumentSubcodeRelated,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeDocumentSubcodeRelated: {
					...state.administrativeDocumentSubcodeRelated,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_DATA: {
			return {
				...state,
				administrativeDocumentSubcodeRelated: administrativeDocumentReducerInitialState.administrativeDocumentSubcodeRelated
			}
		}
		// END Get administrative document subcodes related

		// RP selected data actions
		case ACTION_TYPES.GET_ALL_RP_CONVERT_DATA_BY_SERVICE_REQUEST: {
			return {
				...state,
				selectedRPToConvert: {
					...state.selectedRPToConvert,
					isFetching: true,
				}
			}
		}
		case ACTION_TYPES.GET_ALL_RP_CONVERT_DATA_BY_SERVICE_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				selectedRPToConvert: {
					...state.selectedRPToConvert,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_ALL_RP_CONVERT_DATA_BY_SERVICE_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				selectedRPToConvert: {
					...state.selectedRPToConvert,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.SET_RP_CONVERT_DATA: {
			const { data } = action.payload;
			return {
				...state,
				selectedRPToConvert: {
					...state.selectedRPToConvert,
					isFetching: false,
					hasError: false,
					data
				}
			};
		}
		case ACTION_TYPES.CLEAR_RP_CONVERT_DATA: {
			return {
				...state,
				selectedRPToConvert: {
					data: [],
					isFetching: false,
					hasError: false,
					error: undefined
				}
			}
		}
		// END RP selected data actions

		// Get administrative documents modifications and preload details
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_REQUEST: {
			return {
				...state,
				administrativeDocumentModifPreload: {
					...state.administrativeDocumentModifPreload,
					isFetching: true,
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeDocumentModifPreload: {
					...state.administrativeDocumentModifPreload,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeDocumentModifPreload: {
					...state.administrativeDocumentModifPreload,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_DATA: {
			return {
				...state,
				administrativeDocumentModifPreload: administrativeDocumentReducerInitialState.administrativeDocumentModifPreload
			}
		}
		// END Get administrative documents modifications and preload details

		// Get administrative documents availables
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST: {
			return {
				...state,
				administrativeDocumentAvailables: {
					...state.administrativeDocumentAvailables,
					isFetching: true,
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				administrativeDocumentAvailables: {
					...state.administrativeDocumentAvailables,
					isFetching: false,
					hasError: false,
					data
				}
			}
		}
		case ACTION_TYPES.GET_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				administrativeDocumentAvailables: {
					...state.administrativeDocumentAvailables,
					isFetching: false,
					hasError: true,
					error
				}
			}
		}
		case ACTION_TYPES.CLEAR_ADMINISTRATIVE_DOCUMENT_AVAILABLES_REQUEST_DATA: {
			return {
				...state,
				administrativeDocumentAvailables: administrativeDocumentReducerInitialState.administrativeDocumentAvailables
			}
		}
		// END Get administrative documents availables
		default: {
			return state;
		}
	}
};

// Reducer getter
export const getAdministrativeDocumentReducer = state => state?.administrativeDocumentReducer;

// Administrative document getters
export const getAdministrativeDocument = state => getAdministrativeDocumentReducer(state)?.administrativeDocumentData;
export const getAdministrativeDocumentData = state => getAdministrativeDocument(state)?.data;
export const getAdministrativeDocumentIsFetching = state => getAdministrativeDocument(state)?.isFetching;


// Administrative document list getters
export const getAdministrativeListDocument = state => getAdministrativeDocumentReducer(state)?.administrativeListAllDocumentData;
export const getAdministrativeListDocumentData = state => getAdministrativeListDocument(state)?.data;
export const getAdministrativeListDocumentIsFetching = state => getAdministrativeListDocument(state)?.isFetching;

// Administrative document history getters
export const getAdministrativeDocumentHistory = state => getAdministrativeDocumentReducer(state)?.administrativeDocumentHistory;
export const getAdministrativeDocumentHistoryData = state => getAdministrativeDocumentHistory(state)?.data;
export const getAdministrativeDocumentHistoryIsFetching = state => getAdministrativeDocumentHistory(state)?.isFetching;

// Latest affectation transaction getters
export const getLatestAffectationTransaction = state => getAdministrativeDocumentReducer(state)?.latestAffectationTransaction;
export const getLatestAffectationTransactionData = state => getLatestAffectationTransaction(state)?.data;
export const getLatestAffectationTransactionIsFetching = state => getLatestAffectationTransaction(state)?.isFetching;

// Expedient by id budget credits getters
export const getexpedientCreditByFilter = state => getAdministrativeDocumentReducer(state)?.expedientCredit;
export const getexpedientCreditByFilterData = state => getexpedientCreditByFilter(state)?.data;
export const getexpedientCreditByFilterIsFetching = state => getexpedientCreditByFilter(state)?.isFetching;

// Administrative document subcodes related getters
export const getAdministrativeDocumentSubcode= state => getAdministrativeDocumentReducer(state)?.administrativeDocumentSubcodeRelated;
export const getAdministrativeDocumentSubcodeData = state => getAdministrativeDocumentSubcode(state)?.data;
export const getAdministrativeDocumentSubcodeIsFetching = state => getAdministrativeDocumentSubcode(state)?.isFetching;

// RP data selected getter
export const getRPSelected= state => getAdministrativeDocumentReducer(state)?.selectedRPToConvert;
export const getRPSelectedData = state => getRPSelected(state)?.data;
export const getRPSelectedDataIsFetching = state => getRPSelected(state)?.isFetching;
// Administrative document modifications and preload getters
export const getAdministrativeDocumentModifPreload= state => getAdministrativeDocumentReducer(state)?.administrativeDocumentModifPreload;
export const getAdministrativeDocumentModifPreloadData = state => getAdministrativeDocumentModifPreload(state)?.data;
export const getAdministrativeDocumentModifPreloadIsFetching = state => getAdministrativeDocumentModifPreload(state)?.isFetching;

// Administrative document availables getters
export const getAdministrativeDocumentAvailables= state => getAdministrativeDocumentReducer(state)?.administrativeDocumentAvailables;
export const getAdministrativeDocumentAvailablesData = state => getAdministrativeDocumentAvailables(state)?.data;
export const getAdministrativeDocumentAvailablesIsFetching = state => getAdministrativeDocumentAvailables(state)?.isFetching;