// GET Provision Order request actions
export const GET_PROVISION_ORDER_REQUEST = 'GET_PROVISION_ORDER_REQUEST';
export const GET_PROVISION_ORDER_REQUEST_SUCCESS = 'GET_PROVISION_ORDER_REQUEST_SUCCESS';
export const GET_PROVISION_ORDER_REQUEST_FAILURE = 'GET_PROVISION_ORDER_REQUEST_FAILURE';
export const CLEAR_PROVISION_ORDER_REQUEST = 'CLEAR_PROVISION_ORDER_REQUEST';

// EDIT
export const EDIT_PROVISION_ORDER_REQUEST = 'EDIT_PROVISION_ORDER_REQUEST'
export const EDIT_PROVISION_ORDER_REQUEST_SUCCESS = 'EDIT_PROVISION_ORDER_REQUEST_SUCCESS'
export const EDIT_PROVISION_ORDER_REQUEST_FAILURE = 'EDIT_PROVISION_ORDER_REQUEST_FAILURE'
export const CLEAR_EDIT_PROVISION_ORDER = 'CLEAR_EDIT_PROVISION_ORDER'
export const SET_PROVISION_ORDER_TO_EDIT = 'SET_PROVISION_ORDER_TO_EDIT';

//GET BY ID
export const GET_PROVISION_ORDER_BYID_REQUEST = 'GET_PROVISION_ORDER_BYID_REQUEST';
export const GET_PROVISION_ORDER_BYID_REQUEST_SUCCESS = 'GET_PROVISION_ORDER_BYID_REQUEST_SUCCESS';
export const GET_PROVISION_ORDER_BYID_REQUEST_FAILURE = 'GET_PROVISION_ORDER_BYID_REQUEST_FAILURE';

//GET TO CLONE BY ID
export const GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST = 'GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST';
export const GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_SUCCESS = 'GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_SUCCESS';
export const GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_FAILURE = 'GET_PROVISION_ORDER_TO_CLONE_BY_ID_REQUEST_FAILURE';
export const CLEAR_PROVISION_ORDER_TO_CLONE = 'CLEAR_PROVISION_ORDER_TO_CLONE';

// POST Provision Order request actions
export const POST_PROVISION_ORDER_REQUEST = 'POST_PROVISION_ORDER_REQUEST';
export const POST_PROVISION_ORDER_REQUEST_SUCCESS = 'POST_PROVISION_ORDER_REQUEST_SUCCESS';
export const POST_PROVISION_ORDER_REQUEST_FAILURE = 'POST_PROVISION_ORDER_REQUEST_FAILURE';

// DETAIL Provision Order to detail
export const SET_PROVISION_ORDER_DETAIL = 'SET_PROVISION_ORDER_DETAIL';
export const CLEAR_PROVISION_ORDER_DETAIL = 'CLEAR_PROVISION_ORDER_DETAIL';

// DELETE Provision Order 
export const DELETE_PROVISION_ORDER_REQUEST = 'DELETE_PROVISION_ORDER_REQUEST';
export const DELETE_PROVISION_ORDER_REQUEST_SUCCESS = 'DELETE_PROVISION_ORDER_REQUEST_SUCCESS';
export const DELETE_PROVISION_ORDER_REQUEST_FAILURE = 'DELETE_PROVISION_ORDER_REQUEST_FAILURE';
export const CLEAR_DELETE_PROVISION_ORDER_REQUEST = 'CLEAR_DELETE_PROVISION_ORDER_REQUEST';


// GET Acquisition types list
export const GET_ACQUISITION_TYPES_LIST_REQUEST = 'GET_ACQUISITION_TYPES_LIST_REQUEST';
export const GET_ACQUISITION_TYPES_LIST_REQUEST_SUCCESS = 'GET_ACQUISITION_TYPES_LIST_REQUEST_SUCCESS';
export const GET_ACQUISITION_TYPES_LIST_REQUEST_FAILURE = 'GET_ACQUISITION_TYPES_LIST_REQUEST_FAILURE';
export const CLEAR_ACQUISITION_TYPES_LIST = 'CLEAR_ACQUISITION_TYPES_LIST';