import * as ACTION_TYPES from './captionActionTypes';

export const captionReducerInitialState = {
	//Caption
	captionList: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	captionNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	captionDetail: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	captionEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	captionDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	captionToEdit: {},
	captionToDetails: {},
};

export const captionReducer = (state=captionReducerInitialState, action) => {
	switch (action.type) {

		//Caption
		case ACTION_TYPES.LIST_CAPTION_REQUEST: {

			return {
				...state,
				captionList: {
					...state.captionList,
					isFetching: true,
					hasError: false,
					error: captionReducerInitialState.captionList.error
				}
			};
		}

		case ACTION_TYPES.LIST_CAPTION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			const previousRecords = state.captionList.data?.records;

			if (previousRecords) {
				data.records = previousRecords.concat(data.records);
			}

			return {
				...state,
				captionList: {
					...state.captionList,
					isFetching: false,
					hasError: false,
					error: captionReducerInitialState.captionList.error,
					data
				}
			};
		}

		case ACTION_TYPES.LIST_CAPTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				captionList: {
					...state.captionList,
					isFetching: false,
					hasError: true,
					data: captionReducerInitialState.captionList.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_CAPTION: {
			const { data } = action.payload;
			return {
				...state,
				captionToDetails: data
			}
		}

		case ACTION_TYPES.CLEAR_LIST_CAPTION: {
			return {
				...state,
				captionList: {
					...captionReducerInitialState.captionList
				}
			};
		}

		//Detail Caption
		case ACTION_TYPES.DETAIL_CAPTION_REQUEST: {
			return {
				...state,
				captionDetail: {
					...state.captionDetail,
					isFetching: true,
					hasError: false,
					error: captionReducerInitialState.captionDetail.error
				}
			};
		}

		case ACTION_TYPES.DETAIL_CAPTION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				captionDetail: {
					...state.captionDetail,
					isFetching: false,
					hasError: false,
					error: captionReducerInitialState.captionDetail.error,
					data
				}
			};
		}

		case ACTION_TYPES.DETAIL_CAPTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				captionDetail: {
					...state.captionDetail,
					isFetching: false,
					hasError: true,
					data: captionReducerInitialState.captionDetail.data,
					error
				}
			};
		}

		//New Caption
		case ACTION_TYPES.NEW_CAPTION_REQUEST: {
			return {
				...state,
				captionNew: {
					...state.captionNew,
					isFetching: true,
					hasError: false,
					error: captionReducerInitialState.captionNew.error
				}
			};
		}

		case ACTION_TYPES.NEW_CAPTION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				captionNew: {
					...state.captionNew,
					isFetching: false,
					hasError: false,
					error: captionReducerInitialState.captionNew.error,
					data
				}
			};
		}

		case ACTION_TYPES.NEW_CAPTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				captionNew: {
					...state.captionNew,
					isFetching: false,
					hasError: true,
					data: captionReducerInitialState.captionNew.data,
					error
				}
			};
		}

		//Edit Caption
		case ACTION_TYPES.EDIT_CAPTION_REQUEST: {
			return {
				...state,
				captionEdit: {
					...state.captionEdit,
					isFetching: true,
					hasError: false,
					error: captionReducerInitialState.captionEdit.error
				}
			};
		}

		case ACTION_TYPES.EDIT_CAPTION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				captionEdit: {
					...state.captionEdit,
					isFetching: false,
					hasError: false,
					error: captionReducerInitialState.captionEdit.error,
					data
				}
			};
		}

		case ACTION_TYPES.EDIT_CAPTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				captionEdit: {
					...state.captionEdit,
					isFetching: false,
					hasError: true,
					data: captionReducerInitialState.captionEdit.data,
					error
				}
			};
		}

		case ACTION_TYPES.SET_CAPTION_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				captionToEdit: data
			}
		}

		//Delete Caption
		case ACTION_TYPES.DELETE_CAPTION_REQUEST: {
			return {
				...state,
				captionDelete: {
					...state.captionDelete,
					isFetching: true,
					hasError: false,
					error: captionReducerInitialState.captionDelete.error
				}
			};
		}

		case ACTION_TYPES.DELETE_CAPTION_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				captionDelete: {
					...state.captionDelete,
					isFetching: false,
					hasError: false,
					error: captionReducerInitialState.captionDelete.error,
					data
				}
			};
		}

		case ACTION_TYPES.DELETE_CAPTION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				captionDelete: {
					...state.captionDelete,
					isFetching: false,
					hasError: true,
					data: captionReducerInitialState.captionDelete.data,
					error
				}
			};
		}

		default: {
			return state
		}
	}
};

export const getCaptionReducer = state => state.captionReducer;

// Caption list
export const getCaptionList = state => getCaptionReducer(state)?.captionList;
export const getCaptionListData = state => getCaptionList(state)?.data;
export const getCaptionListIsFetching = state => getCaptionList(state)?.isFetching;

// Caption Detail
export const getCaptionDetail = state => getCaptionReducer(state)?.captionDetail;
export const getCaptionDetailData = state => getCaptionDetail(state)?.data;
export const getCaptionDetailIsFetching = state => getCaptionDetail(state)?.isFetching;

// New Caption
export const getCaptionCreate = state => getCaptionReducer(state)?.captionNew;
export const getCaptionCreateIsFetching = state => getCaptionCreate(state)?.isFetching;

// Caption to edit
export const getCaptionToEdit = state => getCaptionReducer(state)?.captionToEdit;
export const getCaptionEdit = state => getCaptionReducer(state)?.captionEdit;
export const getCaptionEditIsFetching = state => getCaptionEdit(state)?.isFetching;

// Caption to details
export const getCaptionToDetail = state => getCaptionReducer(state)?.captionToDetails;