import React from 'react';
import { config } from 'src/env.js';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/common/StepIndicator';
import { tryEditExercise } from 'src/redux/exercise/exerciseActionCreator';
import { getExerciseToEdit, getExerciseEditIsFetching, getExerciseEditSelectedLegalInstrument } from 'src/redux/exercise/exerciseReducer';
import { setExerciseToEditData } from 'src/redux/exercise/exerciseActions.js';
import { getExerciseTypeValueToShow, dateNeutralFormatedToShowARG } from 'src/utils/utils';
import { EXERCISES_LIST, EXERCISE_EDIT_STEP_ONE } from 'src/utils/constants';

const periodStatusOptions = [
	'FORMULATION',
	'EXECUTION',
	'COMPLEMENTARY',
	'CLOSED'
];

const ExerciseEditPage = props => {

	const dispatch = useDispatch();

	// Selected legal instrument data
	const selectedLegalInstrument = useSelector( state => getExerciseEditSelectedLegalInstrument(state) );

	// Exercise to edit data
	const exerciseToEdit = useSelector( state => getExerciseToEdit(state) );
	const exerciseEditionIsFetching = useSelector( state => getExerciseEditIsFetching(state) );

	// Exercise status
	const onChangeStatus = value => {
		exerciseToEdit.exerciseState = value;
		dispatch( setExerciseToEditData(exerciseToEdit) );
	};

	// Complementary date
	const onChangeComplementaryDate = value => {
		exerciseToEdit.complementaryDate = value;
		dispatch( setExerciseToEditData(exerciseToEdit) );
	};

	// Back button
	const onClickBackButton = () => {
		dispatch( push(EXERCISE_EDIT_STEP_ONE) );
	};

	// Edit button
	const onClickEditButton = () => {
		const dataToSend = {
			complementaryDate: exerciseToEdit?.complementaryDate,
			exerciseState: exerciseToEdit?.exerciseState,
			year: exerciseToEdit?.year,
			legalInstrument: selectedLegalInstrument
		};
		dispatch( tryEditExercise( exerciseToEdit?.id, dataToSend ) )
			.then( response => {
				if( response?.status == 200 ) {
					dispatch( push(EXERCISES_LIST) );
				}
			});
	};

	return <Container className='mb-5'>
		<Card>
			<PageTitle text='Ejercicios'/>

			<Container fluid>

				<div>
					<StepIndicator steps={config.appSettings.steps.exerciseEdition} current={1} className='mt-2 mb-3'/>
				</div>

				<Card className='mb-3'>
					<Card.Header className='h6'>
						Editar período complementario
					</Card.Header>
					<Card.Body>

						<Row>
							<Col sm='8'></Col>
							<Col sm='4' className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Obligatorio
								</span>
							</Col>
						</Row>

						<Form>
							<Form.Group className='text-black-color font-weight-bold mb-1' as={Row}>
								<Form.Label className='text-right' column sm='4'>
									Año:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										value={exerciseToEdit?.year}
										plaintext
										readOnly
										className='font-weight-bold'/>
								</Col>
							</Form.Group>

							<Form.Group className='text-black-color mb-2' as={Row}>
								<Form.Label className='text-right' column sm='4'>
									Fecha inicio:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										value={dateNeutralFormatedToShowARG(exerciseToEdit?.startDate)}
										plaintext
										readOnly/>
								</Col>
							</Form.Group>

							<Form.Group className='text-black-color mb-3' as={Row}>
								<Form.Label className='text-right' column sm='4'>
									Fecha fin:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										value={dateNeutralFormatedToShowARG(exerciseToEdit?.endDate)}
										plaintext
										readOnly/>
								</Col>
							</Form.Group>

							{/* <Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Estado del ejercicio:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										as='select'
										disabled={exerciseEditionIsFetching}
										defaultValue={exerciseToEdit?.exerciseState}
										onChange={event => onChangeStatus(event.target.value)}>
										{
											periodStatusOptions.map( item => {
												return <option key={item} value={item}>
													{getExerciseTypeValueToShow(item)}
												</option>
											})
										}
									</Form.Control>
								</Col>
							</Form.Group> */}

							<Form.Group className='text-black-color' as={Row}>
								<Form.Label className='text-right d-flex mandatory-label' column sm='4'>
									<FontAwesomeIcon icon={faAsterisk} className='mb-2 mr-1 text-danger mandatory-asterisk'/>
									Fecha complementaria:
								</Form.Label>
								<Col sm='4'>
									<Form.Control
										disabled={exerciseEditionIsFetching}
										defaultValue={exerciseToEdit?.complementaryDate}
										onChange={event => onChangeComplementaryDate(event.target.value)}
										type='date'
										name='complementaryDate'/>
								</Col>
							</Form.Group>

							<div className='d-flex justify-content-around mt-4'>
								<Button type='button' variant='danger' size='lg' onClick={onClickBackButton}>
									<FontAwesomeIcon icon={faArrowLeft} className='mr-2'/>
									Volver
								</Button>

								<Spinner animation='border' className={exerciseEditionIsFetching ? '' : 'hidden'}/>

								<Button type='button' variant='success' size='lg' onClick={onClickEditButton} disabled={exerciseEditionIsFetching}>
									Guardar
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default ExerciseEditPage;