import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import CreditDetailPageDetail from 'src/components/forms/credit/detail/CreditDetailPageDetail';
import CreditDetailPageModifyDetail from 'src/components/forms/credit/detail/CreditDetailPageModifyDetail';
import { CREDIT } from 'src/utils/constants';
import { budgetCredits, back } from 'src/utils/label';

const CreditDetailPage = props => {

	const dispatch = useDispatch();

	const onClickBackButton = () => {
		dispatch(push(CREDIT));
	};

	return <Container fluid>
		<Card className='mb-5'>
			<PageTitle text={budgetCredits} />

			<CreditDetailPageDetail />

			<CreditDetailPageModifyDetail />

			<br></br>

			<div className='mx-auto mb-4'>
				<Button variant='success' size='lg' onClick={onClickBackButton}>
					<FontAwesomeIcon icon={faArrowLeft} className='mr-2' />
					{back}
				</Button>
			</div>

		</Card>
	</Container>;

};

export default CreditDetailPage;