
export const LIST_CREDIT_REQUEST = 'LIST_CREDIT_REQUEST';
export const LIST_CREDIT_REQUEST_SUCCESS = 'LIST_CREDIT_REQUEST_SUCCESS';
export const LIST_CREDIT_REQUEST_FAILURE = 'LIST_CREDIT_REQUEST_FAILURE';

export const CLEAR_LIST_CREDIT_DATA = 'CLEAR_LIST_CREDIT_DATA';

export const SET_CREDIT_DETAIL_DATA = 'SET_CREDIT_DETAIL_DATA';

export const SET_CREDIT_TO_EDIT = 'SET_CREDIT_TO_EDIT';
export const CLEAR_CREDIT_TO_EDIT = 'CLEAR_CREDIT_TO_EDIT';

export const GET_CODES_FROM_CREDIT_REQUEST = 'GET_CODES_FROM_CREDIT_REQUEST';
export const GET_CODES_FROM_CREDIT_REQUEST_SUCCESS = 'GET_CODES_FROM_CREDIT_REQUEST_SUCCESS';
export const GET_CODES_FROM_CREDIT_REQUEST_FAILURE = 'GET_CODES_FROM_CREDIT_REQUEST_FAILURE';
export const CLEAR_CODES_FROM_CREDIT_ID = 'CLEAR_CODES_FROM_CREDIT_ID';

export const POST_CREDIT_REQUEST = 'POST_CREDIT_REQUEST';
export const POST_CREDIT_REQUEST_SUCCESS = 'POST_CREDIT_REQUEST_SUCCESS';
export const POST_CREDIT_REQUEST_FAILURE = 'POST_CREDIT_REQUEST_FAILURE';

// Select legal instrument
export const SET_SELECTED_LEGAL_INSTRUMENT = 'SET_SELECTED_LEGAL_INSTRUMENT';
export const CLEAR_SELECTED_LEGAL_INSTRUMENT = 'CLEAR_SELECTED_LEGAL_INSTRUMENT';

// Credit process in progress
export const SET_CREDIT_PROCESS_IN_PROGRESS = 'SET_CREDIT_PROCESS_IN_PROGRESS';

// Get total amounts
export const GET_TOTAL_AMOUNTS_REQUEST = 'GET_TOTAL_AMOUNTS_REQUEST';
export const GET_TOTAL_AMOUNTS_REQUEST_SUCCESS = 'GET_TOTAL_AMOUNTS_REQUEST_SUCCESS';
export const GET_TOTAL_AMOUNTS_REQUEST_FAILURE = 'GET_TOTAL_AMOUNTS_REQUEST_FAILURE';
export const CLEAR_TOTAL_AMOUNTS_DATA = 'CLEAR_TOTAL_AMOUNTS_DATA';