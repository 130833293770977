import * as ACTION_TYPES from './deliveryOrderActionTypes';



// Create deliveryOrder
export const createDeliveryOrderRequest = () => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST
});

export const createDeliveryOrderRequestSuccess = data => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const createDeliveryOrderRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateDeliveryOrderData = () => ({
	type: ACTION_TYPES.CLEAR_CREATE_DELIVERY_ORDER
}); 
// END Create deliveryOrder

// Set delivery order details data action
export const setDeliveryOrderDetailsData = data => ({
	type: ACTION_TYPES.SET_DETAIL_DELIVERY_ORDER_DATA,
	payload: {
		data
	}
});

export const getDetailDeliveryOrderRequest = () => ({
	type: ACTION_TYPES.DETAIL_DELIVERY_ORDER_REQUEST
});
export const getDetailDeliveryOrderRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_DELIVERY_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getDetailDeliveryOrderRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_DELIVERY_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});
/* export const setDeliveryOrderToDetail = data => ({
	type: ACTION_TYPES.SET_DETAIL_DELIVERY_ORDER_DATA,
	payload: {
		data
	}
}); */
export const clearDetailDeliveryOrderData = () => ({
	type: ACTION_TYPES.CLEAR_DETAIL_DELIVERY_ORDER_DATA
});

////Detail
export const getDeliveryOrderRequestId = data => ({
	type: ACTION_TYPES.GET_DETAIL_DELIVERY_ORDER_REQUEST_ID,
	payload: {
		data
	}
});


export const getDeliveryOrderRequestIdSuccess = data => ({
	type: ACTION_TYPES.GET_DELIVERY_ORDER_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getDeliveryOrderRequestIdFailure = error => ({
	type: ACTION_TYPES.GET_DELIVERY_ORDER_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});








// END Set exercise details data action


// List delivery order actions
export const listDeliveryOrderRequest = () => ({
	type: ACTION_TYPES.LIST_DELIVERY_ORDER_REQUEST
});
export const listDeliveryOrderRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_DELIVERY_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listDeliveryOrderRequestFailure = error => ({
	type: ACTION_TYPES.LIST_DELIVERY_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListDeliveryOrderData = () => ({
	type: ACTION_TYPES.CLEAR_CREATE_DELIVERY_ORDER
});
// END List delivery order actions

// Add DeliveryOrder  
export const getAddDeliveryOrderRequest = () => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST
});

export const getAddDeliveryOrderRequestSuccess = (data) => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAddDeliveryOrderRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateDeliveryOrder=()=>({
	type:ACTION_TYPES.CLEAR_CREATE_DELIVERY_ORDER
})
// END Add DeliveryOrder 

// Add deliveryOrderMassive
export const getAddDeliveryOrderMassiveRequest = () => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_MASSIVE_REQUEST
});

export const getAddDeliveryOrderMassiveRequestSuccess = data => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAddDeliveryOrderMassiveRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_DELIVERY_ORDER_MASSIVE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateDeliveryOrderMassiveData = () => ({
	type: ACTION_TYPES.CLEAR_CREATE_DELIVERY_MASSIVE_ORDER
}); 
// END Add deliveryOrderMassive


// Edit DeliveryOrder 
export const getEditDeliveryOrderRequest = () => ({
	type: ACTION_TYPES.EDIT_DELIVERY_ORDER_REQUEST
});

export const getEditDeliveryOrderRequestSucces = (data) => ({
	type: ACTION_TYPES.EDIT_DELIVERY_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getEditDeliveryOrderRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_DELIVERY_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setDeliveryOrderToEdit = data => ({
	type: ACTION_TYPES.SET_DELIVERY_ORDER_TO_EDIT,
	payload: {
		data
	}
});

export const clearEditDeliveryOrder = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_DELIVERY_ORDER
})
// END Edit DeliveryOrder

// Delete DeliveryOrder 
export const getDeleteDeliveryOrderRequest = () => ({
	type: ACTION_TYPES.DELETE_DELIVERY_ORDER_REQUEST
});

export const getDeleteDeliveryOrderRequestSuccess = (data) => ({
	type: ACTION_TYPES.DELETE_DELIVERY_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getDeleteDeliveryOrderRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_DELIVERY_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearDeleteDeliveryOrder = () => ({
	type: ACTION_TYPES.CLEAR_DELETE_DELIVERY_ORDER
})
// END Delete DeliveryOrder











