import * as ACTION_TYPES from './conceptRequestActionTypes';

export const conceptsReducerInitialState = {
	listConcepts: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listSelectConcepts: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};

export const conceptsReducer = (state = conceptsReducerInitialState, action) => {
	switch (action.type) {

		// List order pay actions handlings
		case ACTION_TYPES.LIST_CONCEPT_REQUEST: {
			return {
				...state,
				listConcepts: {
					...state.listConcepts,
					isFetching: true,
					hasError: false,
					error: conceptsReducerInitialState.listConcepts.error,
					data: conceptsReducerInitialState.listConcepts.data
				}
			};
		}
		case ACTION_TYPES.LIST_CONCEPT_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listConcepts: {
					...state.listConcepts,
					isFetching: false,
					hasError: false,
					error: conceptsReducerInitialState.listConcepts.error,
					data: data
				}
			};
		}
		case ACTION_TYPES.LIST_CONCEPT_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listConcepts: {
					...state.listConcepts,
					isFetching: false,
					hasError: true,
					data: conceptsReducerInitialState.listConcepts.data,
					error: error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_CONCEPT: {
			return {
				...state,
				listConcepts: conceptsReducerInitialState.listConcepts
			}
		}
		
		// List Select Concept
		case ACTION_TYPES.LIST_SELECT_CONCEPT_REQUEST: {
			return {
				...state,
				listSelectConcepts: {
					...state.listSelectConcepts,
					isFetching: true,
					hasError: false,
					error: conceptsReducerInitialState.listSelectConcepts.error,
					data: conceptsReducerInitialState.listSelectConcepts.data
				}
			};
		}
		case ACTION_TYPES.LIST_SELECT_CONCEPT_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listSelectConcepts: {
					...state.listSelectConcepts,
					isFetching: false,
					hasError: false,
					error: conceptsReducerInitialState.listSelectConcepts.error,
					data: data
				}
			};
		}
		case ACTION_TYPES.LIST_SELECT_CONCEPT_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listSelectConcepts: {
					...state.listSelectConcepts,
					isFetching: false,
					hasError: true,
					data: conceptsReducerInitialState.listSelectConcepts.data,
					error: error
				}
			};
		}
		case ACTION_TYPES.SET_CONCEPT_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				conceptToEdit: data
			}
		}
		default: {
			return state;
		}
	}
};

export const getConceptReducer = state => state.conceptsReducer;

export const getConceptList = state => getConceptReducer(state).listConcepts;
export const getConceptListData = state => getConceptList(state).data;
export const getConceptListIsFetching = state => getConceptList(state).isFetching;
export const getConceptListHasError = state => getConceptList(state).hasError;
export const getConceptListError = state => getConceptList(state).error;

export const getConceptListSelect = state => getConceptReducer(state).listSelectConcepts;
export const getConceptListSelectData = state => getConceptListSelect(state).data;
export const getConceptListSelectIsFetching = state => getConceptListSelect(state).isFetching;
export const getConceptListSelectHasError = state => getConceptListSelect(state).hasError;
export const getConceptListSelectError = state => getConceptListSelect(state).error;

export const getConceptToEdit = state => getConceptReducer(state)?.conceptToEdit;