import * as ACTION_TYPES from './alertActionTypes';

// Alert list actions
export const getAlertListRequest = () => ({
	type: ACTION_TYPES.LIST_ALERT_REQUEST
});

export const getAlertListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ALERT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAlertListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ALERT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearAlertList = () => ({
	type: ACTION_TYPES.CLEAR_ALERT_LIST
});
// END Alert list actions

// Add alert actions
export const addAlertRequest = params => ({
    type: ACTION_TYPES.NEW_ALERT_REQUEST,
    payload: {
        params
    }
});
export const addAlertRequestSuccess = data => ({
    type: ACTION_TYPES.NEW_ALERT_REQUEST_SUCCESS,
    payload: {
        data
    }
});
export const addAlertRequestFailure = error => ({
    type: ACTION_TYPES.NEW_ALERT_REQUEST_FAILURE,
    payload: {
        error
    }
});

//Delete Alert
export const deleteAlertRequest = () => ({
	type: ACTION_TYPES.DELETE_ALERT_REQUEST
});

export const deleteAlertRequestSuccess = data => ({
	type: ACTION_TYPES.DELETE_ALERT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const deleteAlertRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_ALERT_REQUEST_FAILURE,
	payload: {
		error
	}
});

// Detail Alert
export const getAlertDetailRequest = () => ({
	type: ACTION_TYPES.DETAIL_ALERT_REQUEST
});

export const getAlertDetailRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_ALERT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAlertDetailRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_ALERT_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setAlertToDetails = data => ({
	type: ACTION_TYPES.SET_ALERT,
	payload: {
		data
	}
});

//Edit Alert
export const editAlertRequest = () => ({
	type: ACTION_TYPES.EDIT_ALERT_REQUEST
});

export const editAlertRequestSuccess = data => ({
	type: ACTION_TYPES.EDIT_ALERT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const editAlertRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_ALERT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setAlertToEdit = data => ({
	type: ACTION_TYPES.SET_ALERT_TO_EDIT,
	payload: {
		data
	}
});


// Alert list actions
export const getActiveAlertRequest = () => ({
	type: ACTION_TYPES.ACTIVE_ALERT_REQUEST
});

export const getActiveAlertRequestSuccess = data => ({
	type: ACTION_TYPES.ACTIVE_ALERT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getActiveAlertRequestFailure = error => ({
	type: ACTION_TYPES.ACTIVE_ALERT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setAlertActive = data => ({
	type: ACTION_TYPES.SET_ALERT_ACTIVE,
	payload: {
		data
	}
});
