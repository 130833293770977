import * as ACTION_TYPES from './creditActionTypes';


export const getCreditListRequest = () => ({
	type: ACTION_TYPES.LIST_CREDIT_REQUEST
});

export const getCreditListRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_CREDIT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getCreditListRequestFailure = error => ({
	type: ACTION_TYPES.LIST_CREDIT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListCreditData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_CREDIT_DATA
});



export const setCreditDetailData = data => ({
	type: ACTION_TYPES.SET_CREDIT_DETAIL_DATA,
	payload: {
		data
	}
});


export const setCreditToEdit = data => ({
	type: ACTION_TYPES.SET_CREDIT_TO_EDIT,
	payload: {
		data
	}
});

export const clearCreditToEdit = () => ({
	type: ACTION_TYPES.CLEAR_CREDIT_TO_EDIT
});


// Get codes from credit id
export const getCodesFromCreditRequest = () => ({
	type: ACTION_TYPES.GET_CODES_FROM_CREDIT_REQUEST
});

export const getCodesFromCreditRequestSuccess = data => ({
	type: ACTION_TYPES.GET_CODES_FROM_CREDIT_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getCodesFromCreditRequestFailure = error => ({
	type: ACTION_TYPES.GET_CODES_FROM_CREDIT_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCodesFromCreditId = () => ({
	type: ACTION_TYPES.CLEAR_CODES_FROM_CREDIT_ID
});
// END Get codes from credit id



// Post credit
export const postCreditRequest = data => ({
	type: ACTION_TYPES.POST_CREDIT_REQUEST,
	payload: {
		data
	}
});

export const postCreditRequestSuccess = response => ({
	type: ACTION_TYPES.POST_CREDIT_REQUEST_SUCCESS,
	payload: {
		response
	}
});

export const postCreditRequestFailure = error => ({
	type: ACTION_TYPES.POST_CREDIT_REQUEST_FAILURE,
	payload: {
		error
	}
});
// END Post credit



// Selected legal instrument
export const setSelectedLegalInstrument = data => ({
	type: ACTION_TYPES.SET_SELECTED_LEGAL_INSTRUMENT,
	payload: {
		data
	}
});
export const clearSelectedLegalInstrument = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_LEGAL_INSTRUMENT
});
// END Selected legal instrument



// Credit process in progress
export const setCreditProcessInProgress = value => ({
	type: ACTION_TYPES.SET_CREDIT_PROCESS_IN_PROGRESS,
	payload: {
		value
	}
})
// END Credit process in progress



// Total amounts actions
export const getTotalAmountsRequest = () => ({
	type: ACTION_TYPES.GET_TOTAL_AMOUNTS_REQUEST
});
export const getTotalAmountsRequestSuccess = data => ({
	type: ACTION_TYPES.GET_TOTAL_AMOUNTS_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getTotalAmountsRequestFailure = error => ({
	type: ACTION_TYPES.GET_TOTAL_AMOUNTS_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearTotalAmountsData = () => ({
	type: ACTION_TYPES.CLEAR_TOTAL_AMOUNTS_DATA
});
// END Total amounts actions