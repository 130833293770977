import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Menu from './menu/Menu';
import DropdownPeriods from './header/DropdownPeriods';
import DropdownUserControl from './header/DropdownUserControl';
import { useDispatch } from 'react-redux';
import { logout } from 'src/redux/login/loginActions';

const Navigation = props => {
	const dispatch = useDispatch();
	const onClickLogout = () => {
		dispatch(logout());
		dispatch(push(LOGIN_URI));
	};

	return (
		<Navbar expand="lg" className="bg-primary navbar-dark my-2 mx-3 rounded" >
			<Container fluid >
				<Navbar.Toggle aria-controls="basic-navbar-nav" className="border-secondary" />
				<div className='d-md-none d-flex justify-content-end'>
					<DropdownPeriods className="border border-secondary rounded mr-2" />
					<DropdownUserControl onClickLogout={onClickLogout}  className="border border-secondary rounded" />
				</div>
				<Navbar.Collapse id="basic-navbar-nav" >
					<Menu user={props.userProfile} />
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default Navigation;