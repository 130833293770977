import React from 'react';

const BalanceIncreaseStepThree = () => {

    return(
        <>
            Paso 3
        </>
    )
};

export default BalanceIncreaseStepThree;