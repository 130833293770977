import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Card, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { alerts, newAlert, alert, actions, noAlert, questionDeleteAlert, level, messageAlert, dateSince, dateUntil, profile, active} from 'src/utils/label';
import { faPlus, faEdit, faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import PageTitle from 'src/components/general/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import RCPagination from 'src/components/common/RCPagination';

import { getAlertListData, getAlertListIsFetching } from 'src/redux/alert/alertReducer';
import { tryGetAlertList, tryDeleteAlert } from 'src/redux/alert/alertActionCreator';
import { clearAlertList, setAlertToEdit } from 'src/redux/alert/alertActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { push } from 'connected-react-router';
import { getAlertTypeValueToShow} from 'src/utils/utils';

import { ALERT_NEW, ALERT_EDIT } from 'src/utils/constants';

const AlertListPage = () => {

	const dispatch = useDispatch();

    const alertListingData = useSelector(state => getAlertListData(state));
	const alertIsFetching = useSelector(state => getAlertListIsFetching(state));
	const alertListArrayRecords = alertListingData?.records;
	const hasListAlert= isNotEmptyArray(alertListArrayRecords);

    let pageNumber = 0;
	
    const alertListPaginate = useSelector(state => getAlertListData(state)?.metadata);

	const totalCount = alertListPaginate?.total;
    const totalItems = alertListPaginate?.filteredCount;

	const onPageChange = (pNumber) => {
        const params = {
            ...defaultParams,
            page: pNumber - 1 
        }
        fetchList(params);
    }

	const defaultParams = {
        page: 0,
        size: 10,
		sort: 'messageStart,desc'
    }

	const loadAlerts = () => {
		fetchList(defaultParams);
	};

	const fetchList = (params) => {
		dispatch(clearAlertList());
		dispatch(tryGetAlertList(params));
	};

	// Delete
	const onClickDelete = value => {

		const messageAlert = value?.message;

		swal({
			title: alert,
			text: '¿' + questionDeleteAlert + messageAlert + '?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"],
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					dispatch(tryDeleteAlert(value?.id)).then(
						response => {
							if (response?.status == 204) {
								loadAlerts();
							}
						}
					);
				}
			});
		
	};

	// Edit alert
	const onClickEdit = alert => {
		dispatch(setAlertToEdit(alert));
		dispatch(push(ALERT_EDIT));
	};

	// useEffects
	useEffect(() => {
		loadAlerts()
	}, [ ]);

	return <Container fluid className='mb-5'>
		<Card>
			<PageTitle text={alerts} />
			<Container fluid>
				<Card className='mb-3' >
					<Card.Body>
						<Button size='sm' className='mb-3 btn-success' variant='secondary' onClick={() => dispatch(push(ALERT_NEW))}>
							<FontAwesomeIcon icon={faPlus} className='mr-1' />
							{newAlert}
						</Button>
						<div className='table-responsive'>
						<Table  striped bordered hover size='sm' >

							<thead>
								<tr>
									<th className='text-center' style={{ "width": "30%" }}>{messageAlert}</th>
									<th className='text-center' style={{ "width": "10%" }}>{level}</th>
									<th className='text-center' style={{ "width": "10%" }}>{dateSince}</th>
									<th className='text-center' style={{ "width": "10%" }}>{dateUntil}</th>
									<th className='text-center' style={{ "width": "10%" }}>{profile}</th>
									<th className='text-center' style={{ "width": "10%" }}>{active}</th>
									<th className='text-center' style={{ "width": "10%" }}>{actions}</th>
								</tr>
							</thead>

							<tbody className='text-black-color'>
							{
								hasListAlert ?
									alertListArrayRecords?.map(item => 
										<tr key={item?.id}>
											<td className='text-center'>
												{item?.message}
											</td>
											<td className='text-center'>
												{getAlertTypeValueToShow(item?.level)}
											</td>
											<td className='text-center'>
												{ item?.messageStart == null ? "Sin fecha" : moment(item?.messageStart).format('DD/MM/YYYY hh:mm:ss') }
											</td>
											<td className='text-center'>
												{ item?.messageEnd == null ? "Sin fecha" : moment(item?.messageEnd).format('DD/MM/YYYY hh:mm:ss') }
											</td>
											<td className='text-center'>
												{item?.profile ? item?.profile : "Todos"}
											</td>
											<td className='text-center'>
												{item?.active ? "Si" : "No"}
											</td>
											<td className='d-flex justify-content-around'>
												<div className='col-4'>
													{
														<ActionIcon
															size='lg'
															id='search'
															toolTipText='Editar'
															icon={faEdit}
															onClick={() => onClickEdit(item)}
														/>
													}
												</div>	
												<div className='col-4'>
													{
														<ActionIcon
															size='lg'
															id='delete'
															toolTipText='Borrar'
															icon={faTrash}
															onClick={() => onClickDelete(item)}
														/>
													}
												</div>
											</td>
										</tr>)
										:
										<tr>
											<td colSpan='10' className='text-center'>
												{
													alertIsFetching ?
														<Spinner animation='border' variant='success' className='my-3' />
														:
														<Alert variant='info' className='mb-0'>
															<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
															{noAlert}
														</Alert>
												}
											</td>
										</tr>	
								}
							</tbody>
						</Table>
						</div>
						<RCPagination
                            activePage={pageNumber + 1}
                            itemsCountPerPage={defaultParams.size}
                            totalItemsCount={totalCount}
                            totalItems={totalItems}
                            thePage={alertListPaginate?.page + 1}
                            onChange={onPageChange}
                            innerClass="justify-content-center"
						/>  

					</Card.Body>
				</Card>
			</Container>
		</Card>
	</Container>;
};

export default AlertListPage;