import { config } from 'src/env.js';
import { httpGet, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.proveedoresApi.URL}`;

// List orden pay
export const listProveedores = async (params) => {
	const paramsToSend = {
		getListProvider: 'getListProvider',
		pageId: params?.pageId || 1,
		pageSize: params?.pageSize || 10,
		filter: (params?.filter && Object.keys(params.filter).length)? JSON.stringify(params.filter): undefined
	};

	const queryString = buildURLQuery(paramsToSend);
	const URL = `${API_BASE_URI}?${queryString}`;

	let response;
	
	try {
		response = await httpGet(URL);
	
	}
	catch (error) {
		return error;
	}

	return response;
};
