import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { clearListAllAdministrativeServicesData } from "src/redux/administrativeService/administrativeServiceActions";
import { getListAllServiceAdministrativeByUserData } from "src/redux/administrativeService/administrativeServiceReducer";
import { getFundRequestsToEdit, getSelectedFundRequestType } from 'src/redux/fundRequests/fundRequestsReducer';
import PFCommons from 'src/components/forms/fundRequest/PDForms/controls/PFCommons';
import AppLoading from 'src/components/common/AppLoading';
import FundRequestManualControl from 'src/components/forms/fundRequest/PDForms/controls/FundRequestManualControl';
import { getOrderPayListIsFetching } from 'src/redux/orderPay/orderPayReducer';
import { AdministrativeServiceData } from 'src/utils/administrativeServiceUtils';

const FundRequestManualForm = () => {
	const dispatch = useDispatch();

	//Parent Form Controller
    const { admServiceId } = useFormContext();
	const selectedFundRequestType = useSelector(state => getSelectedFundRequestType(state));
	const fundRequestEditSelected = useSelector(state => getFundRequestsToEdit(state));
	const checkToVisualize = fundRequestEditSelected?.fundRequestType === 'PEDIDO_DE_FONDOS_MANUAL' || selectedFundRequestType === 'PEDIDO_DE_FONDOS_MANUAL';
	const isFetching = useSelector(state => getOrderPayListIsFetching(state));
	const administrativeServiceData = AdministrativeServiceData();

	useEffect(() => {
		dispatch(clearListAllAdministrativeServicesData());
	}, [admServiceId]);

	return <>
		<PFCommons
			administrativeServiceData={administrativeServiceData}
			admServiceId={admServiceId}
			checkToVisualize={checkToVisualize}
			isFetching={isFetching}
		/>
		
		<FundRequestManualControl/>

		<AppLoading isLoading={isFetching} />
	</>
};

export default FundRequestManualForm;
