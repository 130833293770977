import * as ACTION_TYPES from './orderPayActionTypes';

export const orderPayReducerInitialState = {
	listOrderPay: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	orderPayData: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	orderPayNew: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	orderPayEdit: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	orderPayDelete: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	orderPayToEdit: {},
	setOrderPayToDetail: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	getAffectationsMovementsByIDOrderPay: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	listPayOrderTypes: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listPayOrderLast: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listProvisionOrder: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listAffectationByOrderType: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	orderPayById: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	getOrderPayListByAdministrativeDocument: {
		data: undefined,
		isFetching: false,
		hasError: false,
        error: undefined
	},
	listWorkCertificateTypes: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	}
}

export const orderPayReducer = (state = orderPayReducer, action) => {
	switch (action.type) {
		// List order pay actions handlings
		case ACTION_TYPES.LIST_ORDER_PAY_REQUEST: {
			return {
				...state,
				listOrderPay: {
					...state.listOrderPay,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.listOrderPay.error,
					data: orderPayReducerInitialState.listOrderPay.data
				}
			};
		}
		case ACTION_TYPES.LIST_ORDER_PAY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listOrderPay: {
					...state.listOrderPay,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.listOrderPay.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_ORDER_PAY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listOrderPay: {
					...state.listOrderPay,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.listOrderPay.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ORDER_PAY_DATA: {
			return {
				...state,
				listOrderPay: orderPayReducerInitialState.listOrderPay
			}
		}
		case ACTION_TYPES.DETAIL_ORDER_PAY_REQUEST: {
			return {
				...state,
				setOrderPayToDetail: {
					...state.setOrderPayToDetail,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.setOrderPayToDetail.error,
					data: orderPayReducerInitialState.setOrderPayToDetail.data
				}
			};
		}
		case ACTION_TYPES.DETAIL_ORDER_PAY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				setOrderPayToDetail: {
					...state.setOrderPayToDetail,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.setOrderPayToDetail.error,
					data
				}
			};
		}
		case ACTION_TYPES.DETAIL_ORDER_PAY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				setOrderPayToDetail: {
					...state.setOrderPayToDetail,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.setOrderPayToDetail.data,
					error
				}
			};
		}
		case ACTION_TYPES.SET_DETAIL_ORDER_PAY_DATA: {
			const { data } = action.payload;
			return {
				...state,
				setOrderPayToDetail: data
			};
		}
		case ACTION_TYPES.CREATE_ORDER_PAY_REQUEST: {
			return {
				...state,
				orderPayNew: {
					...state.orderPayNew,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.orderPayNew.error
				}
			};
		}
		case ACTION_TYPES.CREATE_ORDER_PAY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				orderPayNew: {
					...state.orderPayNew,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.orderPayNew.error,
					data
				}
			};
		}
		case ACTION_TYPES.CREATE_ORDER_PAY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				orderPayNew: {
					...state.orderPayNew,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.orderPayNew.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_CREATE_ORDER_PAY: {
			return {
				...state,
				orderPayNew: orderPayReducerInitialState.orderPayNew
			}
		}
		case ACTION_TYPES.EDIT_ORDER_PAY_REQUEST: {
			return {
				...state,
				orderPayEdit: {
					...state.orderPayEdit,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.orderPayEdit.error
				}
			};
		}
		case ACTION_TYPES.EDIT_ORDER_PAY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				orderPayEdit: {
					...state.orderPayEdit,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.orderPayEdit.error,
					data
				}
			};
		}
		case ACTION_TYPES.EDIT_ORDER_PAY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				orderPayEdit: {
					...state.orderPayEdit,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.orderPayEdit.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_EDIT_ORDER_PAY: {
			return {
				...state,
				orderPayEdit: orderPayReducerInitialState.orderPayEdit
			}
		}
		case ACTION_TYPES.SET_ORDER_PAY_TO_EDIT: {
			const { data } = action.payload;
			return {
				...state,
				orderPayToEdit: data
			}
		}
		case ACTION_TYPES.DELETE_ORDER_PAY_REQUEST: {
			return {
				...state,
				orderPayDelete: {
					...state.orderPayDelete,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.orderPayDelete.error
				}
			};
		}
		case ACTION_TYPES.DELETE_ORDER_PAY_REQUEST_SUCCESS: {
			const { data } = action.payload;

			return {
				...state,
				orderPayDelete: {
					...state.orderPayDelete,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.orderPayDelete.error,
					data
				}
			};
		}
		case ACTION_TYPES.DELETE_ORDER_PAY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				orderPayDelete: {
					...state.orderPayDelete,
					isFetching: false,
					hasError: false,
					data: orderPayReducerInitialState.orderPayDelete.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_DELETE_ORDER_PAY: {
			return {
				...state,
				orderPayDelete: orderPayReducerInitialState.orderPayDelete
			}
		}
		case ACTION_TYPES.CLEAR_DETAIL_ORDER_PAY_DATA: {
			return {
				...state,
				orderPayData: orderPayReducerInitialState.orderPayData
			}
		}
		case ACTION_TYPES.GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST: {
			return {
				...state,
				getAffectationsMovementsByIDOrderPay: {
					...state.getAffectationsMovementsByIDOrderPay,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.getAffectationsMovementsByIDOrderPay.error,
					data: orderPayReducerInitialState.getAffectationsMovementsByIDOrderPay.data
				}
			};
		}
		case ACTION_TYPES.GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getAffectationsMovementsByIDOrderPay: {
					...state.getAffectationsMovementsByIDOrderPay,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.getAffectationsMovementsByIDOrderPay.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getAffectationsMovementsByIDOrderPay: {
					...state.getAffectationsMovementsByIDOrderPay,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.getAffectationsMovementsByIDOrderPay.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY: {
			return {
				...state,
				getAffectationsMovementsByIDOrderPay: orderPayReducerInitialState.getAffectationsMovementsByIDOrderPay
			}
		}
		case ACTION_TYPES.LIST_PAY_ORDER_TYPES_REQUEST: {
			return {
				...state,
				listPayOrderTypes: {
					...state.listPayOrderTypes,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.listPayOrderTypes.error,
					data: orderPayReducerInitialState.listPayOrderTypes.data
				}
			};
		}
		case ACTION_TYPES.LIST_PAY_ORDER_TYPES_REQUEST_SUCCESS: {
			let {data} = action.payload;
			return {
				...state,
				listPayOrderTypes: {
					...state.listPayOrderTypes,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.listPayOrderTypes.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_PAY_ORDER_TYPES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listPayOrderTypes: {
					...state.listPayOrderTypes,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.listPayOrderTypes.data,
					error
				}
			};
		}
		case ACTION_TYPES.LIST_PAY_ORDER_LAST_REQUEST: {
			return {
				...state,
				listPayOrderLast: {
					...state.listPayOrderLast,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.listPayOrderLast.error,
					data: orderPayReducerInitialState.listPayOrderLast.data
				}
			};
		}
		case ACTION_TYPES.LIST_PAY_ORDER_LAST_REQUEST_SUCCESS: {
			let {data} = action.payload;
			return {
				...state,
				listPayOrderLast: {
					...state.listPayOrderLast,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.listPayOrderLast.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_PAY_ORDER_LAST_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listPayOrderLast: {
					...state.listPayOrderLast,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.listPayOrderLast.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_ORDER_PAY_LAST: {
			return {
				...state,
				listPayOrderLast: orderPayReducerInitialState.listPayOrderLast
			}
		}
		case ACTION_TYPES.SELECTED_PAYMENT_ORDER_TYPE:{
			const { data } = action.payload;
			return {
				...state,
				selectedOrderPay: data
			}
		}
		case ACTION_TYPES.CLEAR_SELECTED_PAYMENT_ORDER_TYPE:{
			return{
				...state,
				selectedOrderPay: undefined
			}
		}
		case ACTION_TYPES.SELECTED_PAYMENT_DATE:{
			const { data } = action.payload;
			return {
				...state,
				selectedOrderPayDate: data
			}
		}
		case ACTION_TYPES.CLEAR_SELECTED_PAYMENT_DATE:{
			return{
				...state,
				selectedOrderPayDate: undefined
			}
		}
		case ACTION_TYPES.LIST_PROVISION_ORDER_REQUEST: {
			return {
				...state,
				listProvisionOrder: {
					...state.listProvisionOrder,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.listProvisionOrder.error,
					data: orderPayReducerInitialState.listProvisionOrder.data
				}
			};
		}
		case ACTION_TYPES.LIST_PROVISION_ORDER_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listProvisionOrder: {
					...state.listProvisionOrder,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.listProvisionOrder.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_PROVISION_ORDER_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listProvisionOrder: {
					...state.listProvisionOrder,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.listProvisionOrder.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_PROVISION_ORDER_DATA: {
			return {
				...state,
				listProvisionOrder: orderPayReducerInitialState.listProvisionOrder
			}
		}
		case ACTION_TYPES.LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST: {
			return {
				...state,
				listAffectationByOrderType: {
					...state.listAffectationByOrderType,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.listAffectationByOrderType.error,
					data: orderPayReducerInitialState.listAffectationByOrderType.data
				}
			};
		}
		case ACTION_TYPES.LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listAffectationByOrderType: {
					...state.listAffectationByOrderType,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.listAffectationByOrderType.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listAffectationByOrderType: {
					...state.listAffectationByOrderType,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.listAffectationByOrderType.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LIST_AFFECTATION_BY_ORDER_TYPE_DATA: {
			return {
				...state,
				listAffectationByOrderType: orderPayReducerInitialState.listAffectationByOrderType
			}
		}
		case ACTION_TYPES.GET_ORDER_PAY_BY_ID_REQUEST: {
			return {
				...state,
				orderPayById: {
					...state.orderPayById,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.orderPayById.error,
					data: orderPayReducerInitialState.orderPayById.data
				}
			};
		}
		case ACTION_TYPES.GET_ORDER_PAY_BY_ID_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				orderPayById: {
					...state.orderPayById,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.orderPayById.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_ORDER_PAY_BY_ID_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				orderPayById: {
					...state.orderPayById,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.orderPayById.data,
					error
				}
			};
		}
		case ACTION_TYPES.GET_ORDER_PAY_LIST_BY_ADMINISTRATIVE_DOCUMENT_REQUEST: {
			return {
				...state,
				getOrderPayListByAdministrativeDocument: {
					...state.getOrderPayListByAdministrativeDocument,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.getOrderPayListByAdministrativeDocument.error,
					data: orderPayReducerInitialState.getOrderPayListByAdministrativeDocument.data
				}
			};
		}
		case ACTION_TYPES.GET_ORDER_PAY_LIST_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				getOrderPayListByAdministrativeDocument: {
					...state.getOrderPayListByAdministrativeDocument,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.getOrderPayListByAdministrativeDocument.error,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_WORK_CERTIFICATE_TYPES_REQUEST: {
			return {
				...state,
				listWorkCertificateTypes: {
					...state.listWorkCertificateTypes,
					isFetching: true,
					hasError: false,
					error: orderPayReducerInitialState.listWorkCertificateTypes.error,
					data: orderPayReducerInitialState.listWorkCertificateTypes.data
				}
			};
		}
		case ACTION_TYPES.LIST_WORK_CERTIFICATE_TYPES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listWorkCertificateTypes: {
					...state.listWorkCertificateTypes,
					isFetching: false,
					hasError: false,
					error: orderPayReducerInitialState.listWorkCertificateTypes.error,
					data
				}
			};
		}
		case ACTION_TYPES.GET_ORDER_PAY_LIST_BY_ADMINISTRATIVE_DOCUMENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				getOrderPayListByAdministrativeDocument: {
					...state.getOrderPayListByAdministrativeDocument,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.getOrderPayListByAdministrativeDocument.data,
					error
				}
			};
		}
		case ACTION_TYPES.LIST_WORK_CERTIFICATE_TYPES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listWorkCertificateTypes: {
					...state.listWorkCertificateTypes,
					isFetching: false,
					hasError: true,
					data: orderPayReducerInitialState.listWorkCertificateTypes.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_ORDER_PAY_LIST_BY_ADMINISTRATIVE_DOCUMENT: {
			return {
				...state,
				getOrderPayListByAdministrativeDocument: orderPayReducerInitialState.getOrderPayListByAdministrativeDocument
			}
		}
		case ACTION_TYPES.CLEAR_LIST_WORK_CERTIFICATE_TYPES_DATA: {
			return {
				...state,
				listWorkCertificateTypes: orderPayReducerInitialState.listWorkCertificateTypes
			}
		}
		default: {
			return state;
		}
	}
};

export const getOrderPayReducer = state => state.orderPayReducer;

// List order pay
export const getOrderPayList = state => getOrderPayReducer(state)?.listOrderPay;
export const getOrderPayListData = state => getOrderPayList(state)?.data;
export const getOrderPayListIsFetching = state => getOrderPayList(state)?.isFetching;

// New order pay 
export const getOrderPayCreate = state => getOrderPayReducer(state)?.orderPayNew;
export const getOrderPayCreateIsFetching = state => getOrderPayCreate(state)?.isFetching;
export const getOrderPayCreateIsError = state => getOrderPayCreate(state)?.hasError;
export const getOrderType = state => getOrderPayReducer(state)?.selectedOrderPay;
export const getOrderTypeDate = state => getOrderPayReducer(state)?.selectedOrderPayDate;


// Edit order pay 
export const getOrderPayEdit = state => getOrderPayReducer(state)?.orderPayEdit;
export const getOrderPayEditIsFetching = state => getOrderPayEdit(state)?.isFetching;
export const getOrderPayEditIsError = state => getOrderPayEdit(state)?.hasError;
export const getOrderPayToEdit = state => getOrderPayReducer(state)?.orderPayToEdit;

// Delete order pay 
export const getOrderPayDelete = state => getOrderPayReducer(state)?.orderPayDelete;
export const getOrderPayDeleteIsFetching = state => getOrderPayDelete(state)?.isFetching;
export const getOrderPayDeleteIsError = state => getOrderPayDelete(state)?.hasError;

// Detail order pay
export const getOrderPayDetail = state => getOrderPayReducer(state)?.setOrderPayToDetail;
export const getOrderPayDetailtData = state => getOrderPayDetail(state)?.data;
export const getOrderPayDetailIsFetching = state => getOrderPayDetail(state)?.isFetching;

export const getOrderPayDetailID = state => getOrderPayReducer(state)?.setOrderPayToDetail;

// Get affectations movements by ID order pay
export const getAffectationsMovementsByIDOrderPayList = state => getOrderPayReducer(state)?.getAffectationsMovementsByIDOrderPay;
export const getAffectationsMovementsByIDOrderPayListData = state => getAffectationsMovementsByIDOrderPayList(state)?.data;
export const getAffectationsMovementsByIDOrderPayListIsFetching = state => getAffectationsMovementsByIDOrderPayList(state)?.isFetching;

// List pay order types
export const getPayOrderTypeList = state => getOrderPayReducer(state)?.listPayOrderTypes;
export const getPayOrderTypeListData = state => getPayOrderTypeList(state)?.data;
export const getPayOrderTypeListIsFetching = state => getPayOrderTypeList(state)?.isFetching;

// List pay order last
export const getPayOrderLastList = state => getOrderPayReducer(state)?.listPayOrderLast;
export const getPayOrderLastListData = state => getPayOrderLastList(state)?.data;
export const getPayOrderLastListIsFetching = state => getPayOrderLastList(state)?.isFetching;

// List provision order
export const getProvisionOrderList = state => getOrderPayReducer(state)?.listProvisionOrder;
export const getProvisionOrderListData = state => getProvisionOrderList(state)?.data;
export const getProvisionOrderListIsFetching = state => getProvisionOrderList(state)?.isFetching;

// List affectations by order pay type
export const getlistAffectationByOrderType = state => getOrderPayReducer(state)?.listAffectationByOrderType;
export const getlistAffectationByOrderTypeData = state => getlistAffectationByOrderType(state)?.data;
export const getlistAffectationByOrderTypeIsFetching = state => getlistAffectationByOrderType(state)?.isFetching;

// Get order pay by id
export const getOrderPayById = state => getOrderPayReducer(state)?.orderPayById;
export const getOrderPayByIdData = state => getOrderPayById(state)?.data;
export const getOrderPayByIdIsFetching = state => getOrderPayById(state)?.isFetching;

// List pay order by administrative document
export const getOrderPayListByAdministrativeDocumentList = state => getOrderPayReducer(state)?.getOrderPayListByAdministrativeDocument;
export const getOrderPayListByAdministrativeDocumentListData = state => getOrderPayListByAdministrativeDocumentList(state)?.data;
export const getOrderPayListByAdministrativeDocumentListIsFetching = state => getOrderPayListByAdministrativeDocumentList(state)?.isFetching;

// Get list work certificate types
export const getWorkCertificateTypes = state => getOrderPayReducer(state)?.listWorkCertificateTypes;
export const getWorkCertificateTypesData = state => getWorkCertificateTypes(state)?.data;
export const getWorkCertificateTypesIsFetching = state => getWorkCertificateTypes(state)?.isFetching;
